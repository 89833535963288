<template>
    <div class="row w-100 mt-0">
      <div class="text-left mx-2 mt-0">
        <!-- Keyboard -->
        <div class="friKeyboard" :style="!layout.showKeyboard ? 'display:none': ''"></div>
        <template v-if="layout.deviceId">
          <div class="w-100">
              <div v-if="layout.device" class="px-3" style="min-height: 75vh !important;">
                <div class="row">
                  <div class="inline-block" style="width: 60%;">

                    <!-- Device -->
                    <div v-if="layout.section == 'device'">
                      <div class="text-left mt-3">
                        <div class="fs-6 mt-5">Nombre:</div>
                        <div class="fw-bold fs-2 mb-1 me-5">
                         <input id="friDeviceTitle" type="text" class="form-control friInputWithKeyboard" v-model="layout.device.title" @click="showKeyboard('friDeviceTitle')">
                        </div>
                        <!--
                        <div v-if="layout.device.media?.image" class="friw-100" style="background-color: #fff; padding: 10px 0;">
                          <img :src="layout.device.media.image" style="height: 80px; margin: 0 auto; display: block;">
                        </div>
                        -->
                        <div class="fs-6 mt-4">Equipo: <span class="fw-bold">{{ layout.device.brand }}</span></div>
                        <div class="fs-6 mb-1">Modelo: <span class="fw-bold">{{ layout.device.model }}</span></div>
                      </div>
                    </div>

                    <!-- Sondas -->  
                    <div v-if="layout.section == 'sondas'">
                      <template v-if="layout.sonda">
                        <div class="fs-6 mt-5">Nombre:</div>
                        <div class="fs-2 mb-1 me-5">
                         <input id="friSondaTitle" type="text" class="form-control friInputWithKeyboard" v-model="layout.sonda.name" @click="showKeyboard('friSondaTitle')">
                        </div>
                        <div class="mt-3 fs-6">
                          Id: {{ layout.sonda.w1Id }}
                        </div>

                        <div class="fs-6 mt-2">Equipo: <span class="fw-bold">{{ layout.device.title }}</span></div>
                        <div class="fs-6">Tipo: <span class="fw-bold"><span v-if="layout.sonda.input=='device'">Dispositivo</span><span v-else>Externa</span> ({{ layout.sonda.type }})</span></div>
                        <template v-if="layout.sonda.status">
                          <div class="fs-6">Temperatura actual: <span class="fw-bold">{{ layout.sonda.status.temperature }}<span class="align-top">&deg;</span></span></div>
                        </template>
                        <!--
                        <div class="fs-6">
                          Decimales: 
                          
                          <div class="input-group mt-2" style="width: 130px; margin: 0 auto;">
                            <div class="input-group-prepend friBackWhite" @click="decrement" v-if="layout.sonda.decimals > layout.minDecimals">
                              <button class="btn btn-outline-secondary friBtnNumeric">-</button>
                            </div>
                            <input
                              v-model="layout.sonda.decimals"
                              class="form-control no-spin-arrows text-center fs-2"
                              type="numeric"
                              :min="minDecimals"
                              :max="maxDecimals"
                            />
                            <div class="input-group-append friBackWhite" @click="increment" v-if="layout.sonda.decimals < layout.maxDecimals">
                              <button class="btn btn-outline-secondary friBtnNumeric">+</button>
                            </div>
                          </div>
                        </div>
                        -->
                      </template>
                      <template v-else>
                        <div class="friSelDeviceBox2">
                          <div class="px-2">
                            <div class="friCloseBtn" @click="menuAction('menu')">X</div>
                            <h1>Seleccione una sonda</h1>
                            <div class="fs-3 p-0 m-0 text-dark" v-if="layout.controller.devices.devices[layout.device.id].sondas?.count<5">{{ layout.device.title }}</div>
                            <div v-for="sonda in layout.controller.devices.devices[layout.device.id].sondas.sondas" :key="sonda.id" class="friSelDevBox" 
                              :style="layout.controller.devices.devices[layout.device.id].sondas?.count<5 ? 'margin-top: 20px !important' : ''"
                              @click="layout.sonda = sonda">
                              <div class="fs-5 fw-bold" style="line-height: 25px;">{{ sonda.name.toString().substring(0,10) }}<span v-if="sonda.name.length > 10">...</span></div>
                              <div class="fs-6" v-if="sonda.status">{{ sonda.status.temperature }}<span class="fs-6 align-top">&deg;</span></div>
                              <!--<div class="fs-6" v-if="sonda.input=='device'">Dispositivo</div><div class="fs-6" v-else>Externa</div>-->
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>

                    <!-- Sonda alarms -->
                    <div v-if="layout.section == 'sondalarms'">
                      <div class="fs-2 mb-1 mt-5 me-5">
                        <div class="fs-3 mb-3">{{ layout.sonda.name }}</div>
                        <!--
                        <div v-for="alarm in layout.sonda.alarms" :key="alarm.id">
                          <div class="fs-6 br-5 mb-2" style="width: 90%;padding: 8px 15px !important; background-color: #fff;">
                              <div class="inline-block text-left align-top w-100">
                                  <div class="fw-bold fs-5 mb-2">{{ alarm.name }}</div>
                                  <label for="max-slider" class="form-label">Máxima: <span class="float-right">{{ alarm.max }}<span class="fs-6 align-top">&deg;</span></span></label>
                                  <input type="range" v-model="alarm.max" class="form-range" id="max-slider" min="-40" max="40">
                                  <label for="min-slider" class="form-label">Mínima: <span class="float-right">{{ alarm.min }}<span class="fs-6 align-top">&deg;</span></span></label>
                                  <input type="range" v-model="alarm.min" class="form-range" id="min-slider" min="-40" max="40">
                                  <div class="mt-2 fs-6">
                                      <input type="checkbox" v-model="alarm.notify.email.active" id="notification-alarm-checkbox" class="pe-3 ps-3">
                                      <label for="notification-checkbox">Notificaciones email</label>
                                  </div>
                              </div>
                          </div>
                        </div>-->
                        <div v-for="alarm in layout.sonda.alarms" :key="alarm.id">
                          <div class="fs-6 br-5 mb-2" style="width: 90%;border: 3px solid #ffbf01;padding: 8px 15px !important; background-color: #fff;">
                            <div class="inline-block text-left align-top">
                              <div class="fw-bold fs-5">{{ alarm.name }}</div>
                              <div>Máx: {{ alarm.max }}<span class="fs-6 align-top">&deg;</span> / Retardo: 10 min</div>
                              <div>Mín: {{ alarm.min }}<span class="fs-6 align-top">&deg;</span> / Retardo: 10 min</div>
                              <div class="fw-bold mt-2">Notificaciones</div>
                              <div>Emails: Activa</div>
                              <div>Whatsapp: Activa</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    


                  </div>
                  <div class="inline-block text-left" style="width: 35%; padding: 0 !important">
                    <!-- Right menu -->
                    <template v-if="layout.section == 'device'">
                      <div class="friBack1 py-3 px-5 text-white mb-1 friBtnRightDash" :class="layout.section=='device' ? 'fw-bold fs-5': ''" @click="layout.section='device'">Equipo</div>
                      <div class="friBack4 py-3 px-5 text-dark mb-1 friBtnRightDash" @click="layout.section='sondas'">
                        Sondas <span v-if="layout.controller.devices.devices[layout.device.id] && layout.controller.devices.devices[layout.device.id].sondas.count">({{ layout.controller.devices.devices[layout.device.id].sondas.count }})</span>
                      </div>
                      <div class="friBack4 py-4 px-5 text-dark mb-1 friBtnRightDash" @click="menuAction('menu')">Volver</div>
                    </template> 
                    <template v-if="layout.section == 'sondas' || layout.section == 'sondalarms'">
                      <div class="py-3 px-5 mb-1 friBtnRightDash" :class="layout.section=='sondas' ? 'fw-bold fs-5 friBack1 text-white': 'friBack4 text-dark'" @click="layout.section = 'sondas'">Sonda</div>
                      <div class="py-3 px-5 mb-1 friBtnRightDash" :class="layout.section=='sondalarms' ? 'fw-bold fs-5 friBack1 text-white': 'friBack4 text-dark'" @click="layout.section = 'sondalarms'">
                        Alarmas <span v-if="layout.sonda.alarms">({{ layout.sonda.alarms.length }})</span>
                      </div>
                      <template v-if="layout.section == 'sondas'">
                        <div class="friBack4 py-4 px-5 text-dark mb-1 friBtnRightDash" @click="layout.sonda = false; layout.section = 'device'">Volver</div>
                      </template>
                      <template v-if="layout.section == 'sondalarms'">
                        <div class="friBack4 py-4 px-5 text-dark mb-1 friBtnRightDash" @click="layout.sonda = false; layout.section = 'sondas'">Volver</div>
                      </template>
                    </template>
                  </div>
                </div>
              </div>
              <div v-else class="mt-5 text-center"><img src="../../../assets/images/theme/loading.gif" style="width: 80px; margin: 50px auto"></div>
          </div>
        </template>
        <template v-else>
          <!-- SELECT DEVICE -->
          <div class="friSelDeviceBox2">
              <div class="mt-3 px-2">
                  <div v-if="layout.devices && layout.devices.length">
                    <h1>Seleccione un equipo</h1>
                    <div class="friCloseBtn" @click="menuAction('menu')">X</div>
                    <div v-for="device in layout.devices" :key="device.id" :class="layout.devices.length==1 ? 'friSelDevBoxSingle' : 'friSelDevBox'" 
                        @click="loadDevice(device.id);">
                        <template v-if="device?.media?.image"><img :src="device.media.image" :style="layout.devices.length == 1 ? 'width: 120px' : 'width: 80px'" class="mt-2"></template>
                        <template v-else><img src="../../../assets/images/icon/iconDeviceAlba.png" :style="layout.devices.length == 1 ? 'width: 90px' : 'width: 80px'" class="mt-2 mb-5"></template>
                        <div class="fs-3 fw-bold">
                            <template v-if="layout.devices.length==1">{{ device.title.toString() }}</template>
                            <template v-else>{{ device.title.toString().substring(0,10) }}<span v-if="device.title.length > 10">...</span></template>
                        </div>
                        <div class="fs-6">{{ device.model }} {{ layout.deviceId }}</div>
                    </div>
                  </div>  
                  <div v-else class="mt-5 text-center"><img src="../../../assets/images/theme/loading.gif" style="width: 80px; margin: 100px auto"></div>
              </div>
          </div>
        </template>
      </div>
    </div>
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue';
import { useAuthStore } from '@/store';
import { getController, getDevice, updateDeviceParam, updateSondaParam  } from "../../../model/api.js";
import  SimpleKeyboard  from 'simple-keyboard';
import 'simple-keyboard/build/css/index.css';
import router from "@/router";

export default defineComponent({
name: 'DashControllerComponent',
props: {
  isMobile: Boolean,
},
components: {  },
setup() {
    const authStore         = useAuthStore();
    const { user, profile } = authStore;
    const layout            = ref({ 
                                isMobile:     false,
                                controllerId: false,
                                section:      "device",
                                devices:      [],
                                device:       false,
                                keyboard:     false,
                                showKeyboard: false,
                                sonda:        false,
                                minDecimals:  0,
                                maxDecimals:  2,
                              });

  const reloadPage = async () => { 
    router.go(0); 
  }

  const initKeyboard = async () => { 
    document.addEventListener('click', (event) => {
        if (event.target.classList &&event.target.classList.contains('hg-button')) {
          if(event.target.dataset.skbtn){
            const key = event.target.dataset.skbtn;
            processVirtualKeyPress(key)
          }
        }
    });
    layout.value.keyboard = new SimpleKeyboard(".friKeyboard", {
      mergeDisplay: true,
      layoutName: "default",
      layout: {
          default: [
              "q w e r t y u i o p {bksp}",
              "a s d f g h j k l ñ ç",
              "z x c v b n m",
              "á é í ó ú ü à è ò",
              "{cancel} {space} {enter}"
          ],
      },
      buttonTheme: [
          { class: "wide-enter",  buttons: "{enter}"  },
          { class: "wide-cancel", buttons: "{cancel}" },
          { class: "wide-space",  buttons: "{space}"  }
      ],
      display: {
          "{numbers}": "123",
          "{enter}":   "Confirmar",
          "{cancel}":  "Cancelar",
          "{bksp}":    "⌫",
          "{shift}":   "⇧",
          "{abc}":     "ABC",
          "{symbols}": "#+="
      }
    });
  }

  const showKeyboard = async (input) => { 
    if(document.getElementById(input).classList)
      document.getElementById(input).classList.add("friInputEditKeyboard");
    layout.value.showKeyboard = true;
  }

  const hideKeyboard = async () => { 
    var inputs = document.getElementsByClassName("friInputWithKeyboard");
    for (var i = 0; i < inputs.length; i++) { inputs[i].classList.remove("friInputEditKeyboard");}
    layout.value.showKeyboard = false;
  }

  const loadDevice = async (deviceId) => { 
      layout.value.deviceId    = deviceId;
      layout.value.device      = await getDevice(profile.controllerId, deviceId)
  }

  const getDevices = async () => { 
    layout.value.controller = await getController(profile.controllerId, { 'status' : true }); 
    if(layout.value.controller && layout.value.controller.devices?.devices) {
       for (let deviceId in layout.value.controller.devices.devices) {
            let device = layout.value.controller.devices.devices[deviceId];
            layout.value.devices.push(device);
            await loadDevice(deviceId) //temporal
        }
    }
  }

  const processVirtualKeyPress = async (key) => {
      let inputElement = false
      let inputParam   = false
      if(layout.value.section=='device'){ inputElement = layout.value.device; inputParam = "title" }
      if(layout.value.section=='sondas'){ inputElement = layout.value.sonda; inputParam  = "name" }
      if(inputElement){
        if (key === "{bksp}") { if(inputElement && inputElement[inputParam])inputElement[inputParam] = inputElement[inputParam].slice(0, -1); 
        } else if (key === "{enter}") {  
          hideKeyboard();
          if(layout.value.section=='device')await updateDeviceParam(profile.controllerId, layout.value.deviceId, { title: inputElement[inputParam] }) 
          if(layout.value.section=='sondas')await updateSondaParam(profile.controllerId, layout.value.deviceId, layout.value.sonda.id, { name: inputElement[inputParam] }) 
        } else if (key === "{cancel}") { hideKeyboard();
        } else if (key === "{space}") {
          if(inputElement[inputParam])inputElement[inputParam] += " ";
          else inputElement[inputParam] = " ";
        } else {
          if(inputElement[inputParam])inputElement[inputParam] += key;
          else inputElement[inputParam] = key;
        }
        if(inputElement && inputElement[inputParam])inputElement[inputParam] = inputElement[inputParam].charAt(0).toUpperCase() + inputElement[inputParam].slice(1);
        if(layout.value.section=='device')layout.value.device = inputElement
        if(layout.value.section=='sondas')layout.value.sonda  = inputElement
      }
  }

  onMounted(async () => {
    await getDevices()
    await initKeyboard()
  });

  return {user, profile, layout, loadDevice, reloadPage, showKeyboard };
},
methods: {
    menuAction(id) {
        this.$emit('menuAction', id); 
    },
    async increment() {
      if (this.layout.sonda.decimals < this.layout.maxDecimals){
        this.layout.sonda.decimals++;
        await updateSondaParam(this.profile.controllerId, this.layout.deviceId, this.layout.sonda.id, { decimals: this.layout.sonda.decimals }) 
      }
    },
    async decrement() {
      if (this.layout.sonda.decimals > this.layout.minDecimals){
        this.layout.sonda.decimals--;
        await updateSondaParam(this.profile.controllerId, this.layout.deviceId, this.layout.sonda.id, { decimals: this.layout.sonda.decimals }) 
      }
    },
},
});
</script>
<style>
/* Select device*/
.friBtnRightDash{ border-top-left-radius: 5px; border-bottom-left-radius: 5px; cursor: pointer; }
.friSelDeviceBox2{ position: fixed; right: 0; top: 0; width: 100% !important; height: 100vh; color: #fff; background-color: #f6f7fb !important; text-align: center; padding: 0 !important; margin: 0 !important; z-index: 99999999; text-align: center; box-shadow: none;}
.friSelDeviceBox2 h1{ color: #000; font-size: 25px; font-weight: 400; text-align: center; padding: 0 !important; margin: 20px 0 15px 0 !important; }
.friSelDeviceBox2 .friCloseBtn{ position: fixed; top: 2px; right: 8px; color: #000; font-size: 30px; cursor: pointer;}

/* input with keyboard */
.friInputWithKeyboard{font-size: 22px !important; font-weight: 400 !important; margin: 0 !important; padding: 5px !important; background-color: #fdffe8 !important; border: none !important; color: #283252 !important; width: 97% !important; text-align: left; }
.friInputEditKeyboard{position: fixed !important;top: 0 !important;left: 0 !important;z-index: 99 !important; width: 99% !important; padding: 15px !important; background-color: #fff !important; }
.friKeyboard{width: 100%; position: fixed; top: 63px; left: 0; z-index: 99; height: 100vh !important;}
.wide-enter { background-color: #009292 !important; color: #fff;padding: 0 20px !important;}
.wide-cancel { background-color: #545656 !important; color: #fff;padding: 0 20px !important;}
.wide-space { width: 60% !important;}
.friKeyboard .hg-button{ height: 45px !important; }

/*input mim/max*/
.friBtnNumeric{height: 30px; width: 20px; font-size: 30px !important; line-height: 20px !important; background-color: #009292 !important; color: #fff !important;}
.no-spin-arrows{margin: 0 5px !important; height: 30px;}

</style>