
import { defineComponent, onMounted, ref } from 'vue';
import { getPlatforms } from '@ionic/vue'; 
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'LegalPage',
  props: {
    login: Boolean
  },
  components: { }, 
  setup() {
  
    const router     = useRouter();
    const platforms  = getPlatforms(); 
    const layout     = ref({ isMobile: false, section: 'privacy' })

    const goTo = (path: string) => {
      router.push(path);
    }

    onMounted( () => { 
      if(platforms && !platforms.includes('desktop')) layout.value.isMobile = true;
    });

    return { layout, goTo };
  },
  methods: { },
});
