<template>
    <div class="account-box is-form is-footerless p-0 m-0 mb-5" :style="isMobile ? 'border-bottom: none !important;' : ''">
        <template v-if="layout.client">
            <div class="text-center"><h4 :class="isMobile ? 'fs-3 my-5 ms-2' : 'fs-4 my-5'">{{ layout.client.first + " " + layout.client.last }}</h4></div>
            <div>
                <div class="py-2 fs-5 friBack1 text-white text-center"><span class="align-middle ">Aplicaciones</span></div>
                <div class="mx-2">
                    <ion-list v-if="layout.apps && layout.apps.apps && Object.keys(layout.apps.apps).length" style="background-color: #fff !important;"> 
                        <ion-item detail  v-for="(app, appId) in layout.apps.apps" :key="appId" style="--ion-item-border-color: #e4e3e3;" @click="$router.push({ path: '/app/' + appId, query: { section:  'dashboard' } })" class="cursor-pointer">
                        <div v-if="app.logo" class="pe-3"><img :src="app.logo" slot="start" style="height: 55px;"></div>
                        <ion-avatar v-else slot="start" style="width: 80px; height: 70px;"><i class="bi bi-router text-center friOpacity3" style="font-size: 55px;" :class="app.active ? 'friColor1' : 'friColor4'"></i></ion-avatar>
                        <ion-label>
                            <h2 class="fs-5">{{ app.name }}</h2>
                            <div class="text-xs3">Alba Hub</div>
                        </ion-label>
                        </ion-item>
                    </ion-list>
                    <div v-else><div class="text-center mt-5 ps-3" :style="isMobile ? '' : 'padding: 100px 0'">No dispone de aplicaciones asociadas.</div></div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="text-center w-100"  style="margin: 150px 0;">
                <div class="fw-bold fs-4">Aplicaciones</div>
                <div class="mt-2">Espere por favor ...</div>
                <div class="spinner-border friColor1 mt-5" role="status">
                <span class="visually-hidden">Cargando ...</span>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import { useAuthStore } from "@/store";
import { helper as $h } from "../../../utils/helper.js";
import { getClient } from "../../../model/api.js";
import { appsList } from "../../../model/apps.js";
import { IonItem, IonLabel, IonList } from '@ionic/vue'; 

export default defineComponent({
name: 'clientApps',
props: {
    isMobile: Boolean,
    clientId: String
    },
    components: { IonItem, IonLabel, IonList  }, 
setup(props) {

    const authStore     = useAuthStore();
    const layout        = ref({ 
                            client:     null,
                            helper:     $h,
                            })

    const { user, profile } = authStore;


    const initDash = async () => {
        layout.value.client   = await getClient(props.clientId)
        console.log("client", layout.value.client);
        layout.value.apps     = await appsList({ byUser: layout.value.client });
    }

    onMounted( async () => { 
        await initDash();
    });
    
    return { layout, user, props, profile };
}

})
</script>