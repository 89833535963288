
  import { defineComponent, onMounted, ref, onUnmounted } from "vue"; 
  import { useAuthStore } from '@/store';
  import { helper as $h } from "../../utils/helper.js";
  import { getJobs, discardJob, getAppDevice, appsList } from "../../model/apps.js"; 
  import { IonModal, IonContent, alertController, IonGrid, IonRow, IonCol } from '@ionic/vue'; 
  import router from "@/router";

  export default defineComponent({
    name: 'jobsPage',
    components: { IonModal, IonContent, IonGrid, IonRow, IonCol },
    props: {
        isMobile: Boolean,
     },
    setup() {
      
      const authStore = useAuthStore();
      const { user, profile } = authStore;
      
      const layout         = ref({ 
                                    loading:  true,
                                    jobs:     null,
                                    apps:     null,
                                    helper:   $h,
                                    interval: null,
                                    jobBox:   false,
                                    jobEdit:  null,
                                    device:   null
                                  }) 
      
      const getLogs = async () => {
        layout.value.jobBox   = false;
        layout.value.loading  = true; 
        layout.value.jobs     = await getJobs();
        layout.value.apps     = await appsList();
        layout.value.loading  = false;
      }

      const initJobs = async () => {
        layout.value.interval = setInterval(async () => { 
          await getLogs(); }
        , 10000);
      }

      const viewDevice = async () => {
        layout.value.device = null;
        setTimeout(async () => {
          layout.value.device = await getAppDevice(layout.value.jobEdit.device.application_id, layout.value.jobEdit.device.device_id);
        }, 1000);
      }

      const jobDiscard= async () => {
        const alert = await alertController.create({
            header:  "¿Está seguro?",
            cssClass: 'friCustomAlert',
            buttons: [ 
                        {text: 'Cancelar', cssClass: 'alert-button-cancel', role: 'cancel' },  
                        {text: 'Confirmar',cssClass: 'alert-button-confirm', handler: async () => { 
                          layout.value.jobBox  = false;
                          layout.value.loading  = true; 
                          let respDiscard = await discardJob({ "jobId": layout.value.jobEdit.id, "action": "discard", "uid": profile.uid });
                          console.log("respDiscard",respDiscard);
                          layout.value.jobEdit = null;
                          layout.value.jobs    = null
                          await getLogs();
                        }
                        }
                      ],
        });
        await alert.present();
      }

      onMounted(async () => {
        await initJobs();
      })

      onUnmounted(() => {
        if(layout.value.interval)clearInterval(layout.value.interval);
      })
  
      return { authStore, user, profile, router, layout, jobDiscard, viewDevice }
  
    },
  });
  