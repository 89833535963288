import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30ba44e1"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  style: {"padding":"0 0 0 10px !important","text-align":"left"}
}
const _hoisted_2 = { style: {"color":"#000","padding":"0","margin":"5px 0 0 0"} }
const _hoisted_3 = {
  style: {"color":"#333","font-size":"14px"},
  class: "ion-no-margin"
}
const _hoisted_4 = {
  key: 0,
  style: {"color":"#333","font-size":"13px","padding":"0"},
  class: "ion-no-margin"
}
const _hoisted_5 = {
  key: 1,
  style: {"padding":"20px 0 0 10px !important","text-align":"left"}
}
const _hoisted_6 = { style: {"color":"#000","padding":"0","margin":"5px 0 0 0"} }
const _hoisted_7 = {
  style: {"color":"#333","font-size":"14px"},
  class: "ion-no-margin"
}
const _hoisted_8 = {
  key: 0,
  style: {"color":"#333","font-size":"13px","padding":"0 0 10px 0"},
  class: "ion-no-margin"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_item_option = _resolveComponent("ion-item-option")!
  const _component_ion_item_options = _resolveComponent("ion-item-options")!
  const _component_ion_item_sliding = _resolveComponent("ion-item-sliding")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.createButton)
      ? (_openBlock(), _createBlock(_component_ion_fab, {
          key: 0,
          vertical: "top",
          horizontal: "end"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_fab_button, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.createPing()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, { icon: _ctx.add }, null, 8, ["icon"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_ion_list, null, {
      default: _withCtx(() => [
        (_ctx.header)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_1, [
              (_ctx.headerCounter)
                ? (_openBlock(), _createBlock(_component_ion_badge, {
                    key: 0,
                    style: {"vertical-align":"text-top","padding":"7px"},
                    color: "warning"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(Object.keys(_ctx.pingfineList).length), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_ctx.profile.first) + ", your pingfine list ", 1)
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pingfineList, (ping) => {
          return (_openBlock(), _createBlock(_component_ion_item_sliding, {
            key: ping.id
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_text, {
                    "no-margin": "",
                    class: "ion-padding-top",
                    style: {"padding-bottom":"10px"}
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("h3", _hoisted_2, _toDisplayString(this.helper.capitalizeFirstLetter(ping.name)), 1),
                      _createElementVNode("p", _hoisted_3, _toDisplayString(ping.description), 1),
                      (ping.status.active && ping.next)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_4, " Next Checkout: " + _toDisplayString(this.helper.getTimestampDate(ping.next,'full')), 1))
                        : _createCommentVNode("", true),
                      (ping.status.active)
                        ? (_openBlock(), _createBlock(_component_ion_chip, {
                            key: 1,
                            style: {"background-color":"#009292","color":"#fff"}
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("Active")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_ion_chip, {
                            key: 2,
                            style: {"background-color":"#f55073","color":"#fff"}
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("Inactive")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })),
                      (ping.periodicity)
                        ? (_openBlock(), _createBlock(_component_ion_chip, {
                            key: 3,
                            color: "medium"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, { color: "medium" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    icon: _ctx.alarm,
                                    class: "pfIconList"
                                  }, null, 8, ["icon"]),
                                  _createTextVNode(" " + _toDisplayString(ping.periodicity.type), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true),
                      (ping.gps)
                        ? (_openBlock(), _createBlock(_component_ion_chip, {
                            key: 4,
                            color: "medium"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, { color: "medium" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    icon: _ctx.location,
                                    class: "pfIconList"
                                  }, null, 8, ["icon"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (ping.followers.users && ping.followers.length)
                        ? (_openBlock(), _createBlock(_component_ion_chip, {
                            key: 5,
                            color: "medium"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, { color: "medium" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    icon: _ctx.peopleOutline,
                                    class: "pfIconList"
                                  }, null, 8, ["icon"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_ion_item_options, { side: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item_option, {
                    onClick: ($event: any) => (_ctx.editPing(ping))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Edit")
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128)),
        (_ctx.header && _ctx.pingfineShared)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_5, [
              (_ctx.headerCounter)
                ? (_openBlock(), _createBlock(_component_ion_badge, {
                    key: 0,
                    style: {"vertical-align":"text-top","padding":"7px"},
                    color: "warning"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(Object.keys(_ctx.pingfineShared).length), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createTextVNode(" Shared with me ")
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pingfineShared, (ping) => {
          return (_openBlock(), _createBlock(_component_ion_item_sliding, {
            key: ping.id
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_text, {
                    "no-margin": "",
                    class: "ion-padding-top",
                    style: {"padding-bottom":"10px"}
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("h3", _hoisted_6, _toDisplayString(this.helper.capitalizeFirstLetter(ping.name)), 1),
                      _createElementVNode("p", _hoisted_7, _toDisplayString(ping.description), 1),
                      (ping.status.active && ping.next)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
                            _createTextVNode(" Next Checkout: "),
                            _createElementVNode("strong", null, _toDisplayString(this.helper.getTimestampDate(ping.next,'full')), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (ping.status.active)
                        ? (_openBlock(), _createBlock(_component_ion_chip, {
                            key: 1,
                            style: {"background-color":"#009292","color":"#fff"}
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("Active")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_ion_chip, {
                            key: 2,
                            style: {"background-color":"#f55073","color":"#fff"}
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("Inactive")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })),
                      (ping.periodicity)
                        ? (_openBlock(), _createBlock(_component_ion_chip, {
                            key: 3,
                            color: "medium"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, { color: "medium" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    icon: _ctx.alarm,
                                    class: "pfIconList"
                                  }, null, 8, ["icon"]),
                                  _createTextVNode(" " + _toDisplayString(ping.periodicity.type), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true),
                      (ping.gps)
                        ? (_openBlock(), _createBlock(_component_ion_chip, {
                            key: 4,
                            color: "medium"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, { color: "medium" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    icon: _ctx.location,
                                    class: "pfIconList"
                                  }, null, 8, ["icon"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (Object.keys(ping.followers).length)
                        ? (_openBlock(), _createBlock(_component_ion_chip, {
                            key: 5,
                            color: "medium"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, { color: "medium" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    icon: _ctx.peopleOutline,
                                    class: "pfIconList"
                                  }, null, 8, ["icon"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_ion_item_options, { side: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item_option, {
                    onClick: ($event: any) => (_ctx.editPing(ping))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Edit")
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ]))
}