<template>
    <div class="account-box is-form is-footerless m-0 p-0 mb-5" :style="isMobile ? 'border-bottom: none !important;' : ''">
        <template v-if="layout.client">
            <div class="form-head stuck-header" :class="isMobile ? 'py-0 ps-3 pe-3' : 'py-3'">
                <div class="form-head-inner">
                    <div class="left"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5 ms-3' : 'fs-4'">Forma de pago</h4></div>
                    <div class="inline-block align-top me-3 fs-6 mt-0" style="margin-top: 4px;">
                        <div class="friUserCircleMin friBack5">{{ layout.client?.first[0] }}{{ layout.client?.last[0] }}</div>
                    </div>
                </div>
            </div>    
            <div class="px-5" v-if="layout.plan">
                <ion-list  v-if="layout.plan?.plan">
                    <ion-row class="p-0 m-0">
                        <ion-col class="p-0 m-0">
                            <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                <ion-label position="floating" class="friColor1 fs-6">Precio *</ion-label>
                                <ion-input v-model="layout.plan.plan.price" type="number"></ion-input>
                            </ion-item>
                        </ion-col>
                        <ion-col class="p-0 m-0">
                            <ion-label position="floating" class="friColor1 text-xs3 text-left">Total *</ion-label>
                            <div v-if="layout.plan.plan.price" class="fw-bold friColor1 text-center" style="font-size: 32px;">{{ parseFloat(layout.plan.plan.price + (layout.plan.plan.price * 0.21)).toFixed(2) }} &euro;</div>
                        </ion-col>
                    </ion-row>
                    <template v-if="layout.plan.plan.payment && layout.payMethods">
                        <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                            <ion-label position="floating" class="friColor1 fs-6 ">Método de Pago *</ion-label>
                            <ion-select interface="popover" v-model="layout.plan.plan.payment.method" class="fs-5">
                                <ion-select-option v-for="p in layout.payMethods" :key="p.id" :value="p.id">{{ p.name }}</ion-select-option>
                            </ion-select>
                        </ion-item>
                        <template v-if="layout.plan.plan.payment.method == 'bank'">
                            <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                <ion-label position="floating" class="friColor1 fs-6">Titular *</ion-label>
                                <ion-input v-model="layout.plan.plan.payment.bank.holder" type="text" ></ion-input>
                            </ion-item>
                            <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                <ion-label position="floating" class="friColor1 fs-6">Entidad Bancaria *</ion-label>
                                <ion-select interface="popover" v-model="layout.plan.plan.payment.bank.name" class="fs-5">
                                    <ion-select-option v-for="b in layout.banks" :key="b.name" :value="b.name">{{ b.name }} ({{ b.id }})</ion-select-option>
                                </ion-select>
                            </ion-item>
                            <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                <ion-label position="floating" class="friColor1 fs-6">IBAN *</ion-label>
                                <ion-input v-model="layout.plan.plan.payment.bank.IBAN" type="text" ></ion-input>
                            </ion-item>
                        </template>
                    </template>
                    <div class="d-flex justify-content-center align-items-center fs-5" style="margin-top: 30px;">
                        <div class="fw-bold friColor1 text-end float-end cursor-pointer">GUARDAR</div>
                    </div>
                </ion-list>
            </div>
        </template>
        <template v-else>
            <div class="text-center w-100"  style="margin-top: 150px;">
                <div class="fw-bold fs-2">Formas de pago</div>
                <div class="mt-2">Espere por favor ...</div>
                <div class="spinner-border friColor1 mt-5" role="status">
                <span class="visually-hidden">Cargando ...</span>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue"; 
import { useAuthStore } from "@/store";
import { helper as $h } from "../../../utils/helper.js";
import { getClient } from "../../../model/api.js";
import { getPaymentMethods, getBanks, getPlan } from "../../../model/plans.js";
import { IonItem, IonLabel, IonInput, IonList, IonSelect, IonSelectOption } from '@ionic/vue';

export default defineComponent({
name: 'planPayment',
props: {
    isMobile: Boolean,
    clientId: String,
    planId:  String,
    },
    components: { IonItem, IonLabel, IonInput, IonList, IonSelect, IonSelectOption}, 
setup(props) {

    const authStore      = useAuthStore();
    const layout = ref({ 
                        clientId:   null,
                        planId:     null,
                        banks:      null,
                        plan:       null,
                        client:     null,
                        helper:     $h,
                        })

    const { user, profile } = authStore;

    const initPayment = async () => {
        layout.value.banks      = await getBanks()
        layout.value.payMethods = await getPaymentMethods()
        layout.value.client     = await getClient(props.clientId)
        layout.value.plan       = await getPlan(props.clientId, props.planId);
    }

    onMounted( async () => { 
        await initPayment();
    });

    return { layout, user, props, profile };
}

})
</script>