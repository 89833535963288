
  import { defineComponent, onMounted, ref, watch } from "vue"; 
  import { IonItem, IonLabel, IonInput, IonList, IonSelect, IonSelectOption, alertController, IonModal, IonRow, IonCol, IonButton, IonToggle, IonIcon } from '@ionic/vue'; 
  import { getApp, getAppGateways, deleteApp, updateApp, createGateway, updateGateway, deleteGateway , getAppDevices, createDevice, deleteDevice, getAppScenes, getAppUsers, updateScene, createScene, deleteScene, exportStats } from "../../model/apps.js"; 
  import { getTTNApp, createGatewayKeysTTN } from "../../model/ttn.js"; 
  import { add, saveOutline, chevronBack , refreshOutline } from 'ionicons/icons';
  import { helper as $h } from "../../utils/helper.js";
  import { getCities } from "../../model/weather.js"; 
  import { getClientList, getClient, getAgentList  } from "../../model/api.js";
  import { not as $not } from "../../model/not";
  import { useRoute } from 'vue-router'
  import { useAuthStore } from '@/store';
  import router from "@/router";

  export default defineComponent({
    name: 'appsPage',
    props: {
        isMobile: Boolean,
     },
    components: {  IonItem, IonLabel, IonInput, IonSelect, IonSelectOption, IonList, IonModal, IonRow, IonCol, IonButton, IonToggle, IonIcon },
    setup(props) {
      
      const authStore = useAuthStore();
      const { user, profile } = authStore;
      const route = useRoute()

      const layout = ref({ 
                          loading:  true,
                          appId:    router.currentRoute.value.params.appId,
                          app:      null,
                          query:    null,
                          section:  null,
                          sections: [
                            { name: 'dashboard', title: 'Alba Hub', icon: 'bi bi-grid'},
                            { name: 'gateway',   title: 'Gateways', icon: 'bi bi-router'},
                            { name: 'devices',   title: 'Dispositivos', icon: 'bi bi-wifi'},
                            { name: 'scenes',    title: 'Escenas', icon: 'bi bi-record-circle'},
                            { name: 'settings',  title: 'Configuración', icon: 'bi bi-gear'},
                            { name: 'users',     title: 'Usuarios', icon: 'bi bi-people'},
                          ],
                          clients:      null,
                          agents:       null,
                          addGateway:   false,
                          modalLoading: false,
                          newGateway:   null,
                          gateway:      null,
                          newDevice:    null,
                          device:       null,
                          newScene:     null,
                          scene:        null,
                          helper:       $h,
                          intervals:    [0, 1, 5, 10, 15, 30, 60],
                          weatherCities: null,
                          searchTypes: [ 
                                        { value: "today", label: "Hoy" }, 
                                        { value: "yesterday", label: "Ayer" }, 
                                        { value: "3", label: "Últimos 3 días" }, 
                                        { value: "7", label: "Últimos 7 días" }, 
                                       ],
                          exportData:   { type: "today", dateStart: null, dateEnd: null, maxDate: null, minDate: null, deviceId: null },
                          exportLoading: false,
                         })

            
      const removeApp = async () => {
        const alert = await alertController.create({
            header:  "¿Está seguro?",
            cssClass: 'friCustomAlert',
            buttons: [ 
                        {text: 'Cancelar', cssClass: 'alert-button-cancel', role: 'cancel' },  
                        {text: 'Confirmar',cssClass: 'alert-button-confirm', handler: async () => { 
                          await deleteApp(layout.value.appId)
                          setTimeout(() => { 
                            $not.toastApp({ header: layout.value.app.name, message: 'Se ha eliminado la aplicación correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'primary', cssClass: 'text-white mt-2' })
                            router.push('/apps/')
                           }, 1000)
                        }
                        }
                      ],
        });
        await alert.present();
      }

      const saveApp = async () => {
        let validate = true
        let required = ['name', 'description', 'clientId','agentId']
        required.forEach((field) => {  if(!layout.value.app[field])validate = false })
        if(validate){
          const alert = await alertController.create({
                  header: '¿Está seguro?',
                  cssClass: 'friCustomAlert',
                  buttons: [ 
                            {text: 'Cancelar', cssClass: 'alert-button-cancel', role: 'cancel' },  
                            {text: 'Confirmar',cssClass: 'alert-button-confirm', handler: async () => { 
                              await updateApp(layout.value.app)
                              setTimeout(() => { 
                                $not.toastApp({ header: layout.value.app.name, message: 'Se ha actualizado la aplicación correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'primary', cssClass: 'text-white mt-2' })
                              }, 500)
                              }
                            }
                          ],
              });
          await alert.present();
        }else $not.toastApp({ header: layout.value.app.name, message: 'Debe rellenar los parámetros requeridos', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
      }

      const initNewGateway = () => {
        let numGateways = 1
        if(layout.value.app.gateways && layout.value.app.gateways.gateways) numGateways = Object.keys(layout.value.app.gateways.gateways).length + 1
        layout.value.newGateway = {
          gatewayId:    "gw-"+layout.value.appId+"-"+numGateways,
          appId:        layout.value.appId,
          name:         "gw-"+layout.value.appId+"-"+numGateways,
          serverAdress: 'eu1.cloud.thethings.network',
          frequencyPlanId: 'EU_863_870_TTN',
          eui:          '',
          device:     {
            brand:      'Dragino',
            model:      'LPS8N 4G',
            serial:     '', 
          },
          active:       true,
          deleted:      false,
        }
      }

      const addGateway = async () => {
        let validate = true
        let required = ['name', 'eui']
        required.forEach((field) => {  if(!layout.value.newGateway[field])validate = false })
        if(validate){
          layout.value.modalLoading = true
          new Promise((resolve, reject) => {
            createGateway(layout.value.newGateway).then((res) => {
              setTimeout(async () => { resolve(res) 
                resolve(res)
                layout.value.modalLoading = false
                await initApp()
                layout.value.addGateway = false
                router.push({ path: '/app/' + layout.value.appId, query: { section:  'dashboard' } })
              }, 5000)
            }).catch((err) => { reject(err) })
          })
        }else $not.toastApp({ message: 'Debe rellenar los parámetros requeridos', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
      }

      const removeGateway = async () => {
        const alert = await alertController.create({
            header:  "¿Está seguro?",
            cssClass: 'friCustomAlert',
            buttons: [ 
                        {text: 'Cancelar', cssClass: 'alert-button-cancel', role: 'cancel' },  
                        {text: 'Confirmar',cssClass: 'alert-button-confirm', handler: async () => { 
                          deleteGateway(layout.value.appId,layout.value.gateway.gatewayId).then (async () => {
                            layout.value.app.gateways = await getAppGateways(layout.value.appId)
                            setTimeout(() => { 
                              $not.toastApp({ header: layout.value.app.name, message: 'Se ha eliminado la aplicación correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'primary', cssClass: 'text-white mt-2' })
                              reLoad()
                            }, 1000)
                          })
                          
                        }
                        }
                      ],
        });
        await alert.present();
      }

      const saveGateway = async () => {
        let validate = true
        let required = ['name', 'serverAdress', 'frequencyPlanId']
        required.forEach((field) => {  if(!layout.value.gateway[field])validate = false })
        if(validate){
          await updateGateway(layout.value.gateway)
          $not.toastApp({ message: 'Se ha actualizado el gateway correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, cssClass: 'text-white mt-2 friToast1'})
        }else $not.toastApp({ message: 'Debe rellenar los parámetros requeridos', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
      }

      const goBack = () => { router.push('/apps/') }

      const goTTN = () => { window.open('https://eu1.cloud.thethings.network/console/applications/' + layout.value.appId + '/devices') }

      const reLoad = () => { window.location.reload(); }

      const getLogs = async (type = "log", format = "pdfBlob") => {

        layout.value.exportLoading = true
        let subject                = "Reporte de registros"
        subject                   += layout.value.exportData.deviceId ? " - " + layout.value.app.devices.devices[layout.value.exportData.deviceId].name : "";
        let exportFilename         = layout.value.appId + '.pdf'
        if(format == 'csv')exportFilename = layout.value.appId + '.csv'

        if(layout.value.exportData.type == 'today'){
          layout.value.exportData.dateStart = new Date().toISOString().split('T')[0] 
          layout.value.exportData.dateEnd   = new Date().toISOString().split('T')[0] 
        }else if(layout.value.exportData.type == 'yesterday'){
          let yesterday = new Date()
          yesterday.setDate(yesterday.getDate() - 1)
          layout.value.exportData.dateStart = yesterday.toISOString().split('T')[0] 
          layout.value.exportData.dateEnd   = yesterday.toISOString().split('T')[0]
        }else if(layout.value.exportData.type == '3'){
          let yesterday = new Date()
          yesterday.setDate(yesterday.getDate() - 3)
          layout.value.exportData.dateStart = yesterday.toISOString().split('T')[0] 
          layout.value.exportData.dateEnd   = new Date().toISOString().split('T')[0] 
        }else if(layout.value.exportData.type == '7'){
          let yesterday = new Date()
          yesterday.setDate(yesterday.getDate() - 7)
          layout.value.exportData.dateStart = yesterday.toISOString().split('T')[0] 
          layout.value.exportData.dateEnd   = new Date().toISOString().split('T')[0]
        }

        let exportData     = await exportStats({ 
                              type: type, 
                              data: { 
                                      "appId":       layout.value.appId, 
                                      "format":      format, 
                                      "subject":     subject,
                                      "filename":    exportFilename,
                                      "search":      layout.value.exportData
                                    } })
                                    
        if(exportData && !exportData.error){
           if(exportData.pdfBlob || exportData.csv){ 
              let linkSource   = ''
              if(exportData.pdfBlob)linkSource = `data:application/pdf;base64,${exportData.pdfBlob}`;
              if(exportData.csv)linkSource = `data:text/csv;base64,${exportData.csv}`;
              if(linkSource){
                let downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = exportFilename;
                downloadLink.click();
              }
           }
        }  
        layout.value.exportLoading = false
      }

      const initApp = async () => {
        if(router.currentRoute.value.params.appId)layout.value.appId = router.currentRoute.value.params.appId
        layout.value.app           = await getApp(layout.value.appId)
        layout.value.app.ttn       = await getTTNApp(layout.value.appId)
        layout.value.app.gateways  = await getAppGateways(layout.value.appId)
        layout.value.app.devices   = await getAppDevices(layout.value.appId)
        layout.value.app.scenes    = await getAppScenes(layout.value.appId)
        layout.value.app.users     = await getAppUsers(layout.value.appId)
        layout.value.clients       = await getClientList()
        layout.value.weatherCities = await getCities();
        if(layout.value.app?.clientId)
          layout.value.app.client    = await getClient(layout.value.app.clientId)
        if(!layout.value.section)
          if(route.query.section) layout.value.section = route.query.section
          else layout.value.section = 'dashboard'

        layout.value.agents       = await getAgentList()
        
        initNewGateway()
        initNewDevice()
        initNewScene()
      }

      const previewLogo = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          layout.value.app.logo = reader.result.toString();
        };
        reader.readAsDataURL(file);
      };

      const selDevice = async (device) => {
        console.log('selDevice', '/sem/' + layout.value.appId + '/' + device.deviceId)
        router.push({ path: '/sem/' + layout.value.appId + '/' + device.deviceId, query: { section:  'dashboard' } })
        //layout.value.device  = device
        //layout.value.device.ttnData = await getDeviceData(layout.value.appId, layout.value.device.deviceId)
      }

      const selScene = async (scene) => {
        layout.value.scene  = scene;
      }

      const addDevice = async () => {
        let validate = true
        let required = ['name', 'eui', 'key', 'deveui', 'type']
        required.forEach((field) => {  if(!layout.value.newDevice[field])validate = false })
        if(validate){
          layout.value.newDevice.loading = true
          let newDevice = layout.value.newDevice
          delete newDevice.addBox
          delete newDevice.loading
          if(newDevice.type == 'xw265k')newDevice.isSensor = false
          new Promise((resolve, reject) => {
            createDevice(newDevice).then(async (res) => {
              setTimeout(async () => { resolve(res) 
                resolve(res)
                layout.value.app.devices = await getAppDevices(layout.value.appId)
                $not.toastApp({ header: layout.value.newDevice.name, message: 'Se ha creado el dispositivo correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'primary', cssClass: 'text-white mt-2' })
                layout.value.newDevice.loading = false
                layout.value.newDevice.addBox  = false
                initNewDevice()
              }, 2000)
            }).catch((err) => { reject(err) })
          })
        }else $not.toastApp({ message: 'Debe rellenar los parámetros requeridos', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
      }

      const removeDevice = async () => {
        const alert = await alertController.create({
            header:  "¿Está seguro?",
            cssClass: 'friCustomAlert',
            buttons: [ 
                        {text: 'Cancelar', cssClass: 'alert-button-cancel', role: 'cancel' },  
                        {text: 'Confirmar',cssClass: 'alert-button-confirm', handler: async () => { 
                          setTimeout(() => { 
                            deleteDevice(layout.value.appId, layout.value.device.deviceId).then(() => {
                              layout.value.app.devices = getAppDevices(layout.value.appId)
                              $not.toastApp({ header: layout.value.device.name, message: 'Se ha eliminado el dispositivo correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'primary', cssClass: 'text-white mt-2' })
                              layout.value.device = null
                            })
                           }, 1000)
                        }
                        }
                      ],
        });
        await alert.present();
      }

      const previewDeviceLogo = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          layout.value.device.logo = reader.result.toString();
        };
        reader.readAsDataURL(file);
      };

      const initNewDevice = () => {
        let numDevices = 1
        if(layout.value.app.devices && layout.value.app.devices.devices)numDevices = Object.keys(layout.value.app.devices.devices).length + 1
        let countDevices = numDevices + 1
        let newDeviceId = 'd' + layout.value.appId.toString().toLowerCase() + countDevices.toString();
        newDeviceId = newDeviceId.replace(/[A-Z]/g, '').substr(0, 22); 
        layout.value.newDevice = {
          appId:    layout.value.appId,
          addBox:   false,
          deviceId: newDeviceId, 
          name:     'D' + layout.value.appId + numDevices,
          joinServerAddress:    'eu1.cloud.thethings.network',
          networkServerAddress: 'eu1.cloud.thethings.network',
          type: '',
          externalInput: 'none',
          loading: false,
          logo: '',
          active: true,    
          deleted: false,  
          isSensor: true,   
          interval: 5,             
        }
      }

      const initNewScene = () => {
        let numScenes = 1
        if(layout.value.app.scenes && layout.value.app.scenes.scenes)numScenes = Object.keys(layout.value.app.scenes.scenes).length + 1
        layout.value.newScene = {
          appId:    layout.value.appId,
          addBox:   false,
          name:     'S' + layout.value.appId + (numScenes),
          type:     'coldroom',
          typeName: 'Cámara frigorífica',
          loading:  false,
          active:   false,    
          deleted:  false, 
          devices:  [],
          users:    [],
          specs:    {
            compressor: { brand: '', model: '', refrigerant: '' },
            door:       {  width:  0, height: 0 },
            product:    { type: '' },
            room:       {  width:  0, height: 0, length: 0 },
            thickness: 0,
          }               
        }
      }

      const addScene = async () => {
        let validate = true
        let required = ['name', 'type']
        required.forEach((field) => {  if(!layout.value.newScene[field])validate = false })
        if(!layout.value.newScene.specs.product?.type)validate  = false
        if(validate){
          layout.value.newScene.loading = true
          let newScene = layout.value.newScene
          delete newScene.addBox
          delete newScene.loading
          new Promise((resolve, reject) => {
            createScene(layout.value.appId,newScene).then(async (res) => {
              setTimeout(async () => { resolve(res) 
                resolve(res)
                layout.value.app.scenes  = await getAppScenes(layout.value.appId)
                $not.toastApp({ header: layout.value.newScene.name, message: 'Se ha creado la escena correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'primary', cssClass: 'text-white mt-2' })
                layout.value.newScene.loading = false
                layout.value.newScene.addBox  = false
                initNewScene()
              }, 1000)
            }).catch((err) => { reject(err) })
          })          
        }else $not.toastApp({ message: 'Debe rellenar los parámetros requeridos', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
      }

      const backScene = async () => {
        layout.value.scene = null
        layout.value.app.scenes  = await getAppScenes(layout.value.appId)
      }

      const removeScene = async () => {
        const alert = await alertController.create({
            header:  "¿Está seguro?",
            cssClass: 'friCustomAlert',
            buttons: [ 
                        {text: 'Cancelar', cssClass: 'alert-button-cancel', role: 'cancel' },  
                        {text: 'Confirmar',cssClass: 'alert-button-confirm', handler: async () => { 
                          setTimeout(async () => { 
                            await deleteScene(layout.value.appId, layout.value.scene.sceneId).then(async () => {
                              $not.toastApp({ header: layout.value.scene.name, message: 'Se ha eliminado la escena correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'primary', cssClass: 'text-white mt-2' })
                              layout.value.scene = null
                              layout.value.app.scenes  = await getAppScenes(layout.value.appId)
                            })
                           }, 1000)
                        }
                        }
                      ],
        });
        await alert.present();
      }

      const saveScene = async () => {
        let validate = true
        let required = ['name', 'type']
        required.forEach((field) => {  if(!layout.value.scene[field])validate = false })
        
        if(!layout.value.scene.specs.room?.width)validate  = false
        if(!layout.value.scene.specs.room?.height)validate = false
        if(!layout.value.scene.specs.room?.length)validate = false
        if(!layout.value.scene.specs.product?.type)validate  = false
        if (layout.value.scene.type == 'coldroom'){
          if(!layout.value.scene.specs?.compressor?.brand)validate = false
          if(!layout.value.scene.specs?.compressor?.model)validate = false
          if(!layout.value.scene.specs?.compressor?.refrigerant)validate = false
          if(!layout.value.scene.specs?.door?.width)validate = false
          if(!layout.value.scene.specs?.door?.height)validate = false
          if(!layout.value.scene.specs?.thickness)validate = false
        } 
        if(!layout.value.scene?.devices.length)validate = false
        //if(!layout.value.scene?.users.length)validate = false

        if(validate){
          await updateScene(layout.value.appId, layout.value.scene)
          layout.value.app.scenes  = await getAppScenes(layout.value.appId)
          $not.toastApp({ message: 'Se ha actualizado la escena correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, cssClass: 'text-white mt-2 friToast1'})
        }else $not.toastApp({ message: 'Debe rellenar los parámetros requeridos', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
      }

      const createKeysGateway = async () => {
        await createGatewayKeysTTN(layout.value.gateway)
      }

      onMounted(async () => {
        await initApp()
      })
  
      watch(() => route.query, async (query) => {
            if (query){
                layout.value.query   = query; 
                layout.value.section = query.section;
                if(router.currentRoute.value.params.appId)layout.value.appId   = router.currentRoute.value.params.appId
            }
      });
      
      return { authStore, user, profile, router, layout, props, reLoad, add, refreshOutline, saveOutline, chevronBack, removeApp, saveApp, addGateway, goTTN, goBack, removeGateway, saveGateway, previewLogo, addDevice, removeDevice, 
               previewDeviceLogo, createKeysGateway, selDevice, addScene, selScene, saveScene, backScene, removeScene, getLogs }
  
    },
  });
  