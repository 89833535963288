<template>
    <div class="account-box is-form is-footerless mb-5" :style="isMobile ? 'border-bottom: none !important;' : ''">
        <template v-if="layout.client">
            <div class="ps-2" v-if="layout.client">
                <div class="d-flex justify-content-center align-items-center mt-2 pt-3"><div class="friUserCircle p-4" :class="layout.client.role == 'agent' ?  'friBack3' : 'friBack1'" style="font-size: 35px">{{ layout.client.first[0] }}{{ layout.client.last[0] }}</div></div>
                <div class="text-center fs-6 pt-2 px-5" v-if="layout.client?.lastLogin">
                    <div>Último acceso</div>
                    <div class="fw-bold">{{ layout.helper.getDateFormatFb(layout.client.lastLogin) }}</div>
                </div>
            </div>
            <div class="mt-2">
                <div class="py-2 fs-5 text-white text-center" :class="layout.client.role == 'agent' ?  'friBack3' : 'friBack1'">
                    <span class="align-middle">Perfil de usuario</span>
                </div>
                <div class="mx-5 my-4" style="padding-bottom: 100px;">
                    <template v-if="layout.client.role == 'agent'">
                        <table class="table table-borderless table-sm text-left">
                            <tr>
                                <td class="text-xs3 friColor1 pb-4">
                                    <div class="p-0 m-0 fs-5 fw-bold pb-3">Agente</div>
                                    <div class="p-0 m-0" v-if="layout.client.company"><span class="fw-bold">{{ layout.client.company }}</span></div>
                                    <div class="p-0 m-0 ">{{ layout.client.first }} {{ layout.client.last }}</div>
                                    <div class="p-0 m-0">{{ layout.client.email }}</div>
                                    <div class="p-0 m-0">{{ layout.client.document }}</div>
                                    <div class="p-0 m-0" v-if="layout.client.commission">Comisión: {{ layout.client.commission }}%</div>
                                    <div class="p-0 m-0"><span v-if="layout.client.active">Activo</span><span v-else>Inactivo</span></div>
                                    <div class="p-0 m-0 text-xs3 friColor7 cursor-pointer" style="text-decoration: underline;" @click="sendWelcome()">Enviar email de bienvendida con datos de acceso</div>
                                </td>
                                <td class="fs-6 text-right pb-4"><i class="cursor-pointer bi bi-pencil" :class="isMobile ? 'fs-4' : 'fs-5'" @click="layout.editBox = 'user'"></i></td>
                            </tr>
                        </table>
                    </template>
                    <template v-if="layout.client.role == 'client'">
                     <table class="table table-borderless table-sm text-left">
                        <tr>
                            <td class="text-xs3 friColor1 pb-4">
                                <div class="p-0 m-0 fs-5 fw-bold">Cliente</div>
                                <div class="p-0 m-0 pt-3">{{ layout.client.first }} {{ layout.client.last }}</div>
                                <div class="p-0 m-0">{{ layout.client.email }}</div>
                                <div class="p-0 m-0">{{ layout.client.document }}</div>
                                <div class="p-0 m-0"><span v-if="layout.client.active">Activo</span><span v-else>Inactivo</span></div>
                                <div class="p-0 m-0 text-xs3 friColor1 cursor-pointer" style="text-decoration: underline;" @click="sendWelcome()">Enviar email de bienvendida con datos de acceso</div>
                            </td>
                            <td class="fs-6 text-right pb-4"><i class="cursor-pointer bi bi-pencil" :class="isMobile ? 'fs-4' : 'fs-5'" @click="layout.editBox = 'user'"></i></td>
                        </tr>
                        <tr v-if="layout.client.odoo && layout.client.odoo.client">
                            <td class="text-xs3 friColor1 pb-3">
                                <div class="p-0 m-0 fs-5 fw-bold mt-2">Cliente <span class="text-xs3">(ID: {{ layout.client.odoo.client.id }})</span></div>
                                <div class="p-0 m-0 pt-3">{{  layout.client.odoo.client.name }}</div>
                                <div class="p-0 m-0" v-if="layout.client.odoo.client.email">{{  layout.client.odoo.client.email }}</div>
                                <div class="p-0 m-0" v-if="layout.client.odoo.client.vat">{{  layout.client.odoo.client.vat }}</div>
                                <div class="p-0 m-0" v-if="layout.client.odoo.client.street">{{  layout.client.odoo.client.street }} <template v-if="layout.client.odoo.client.zip">({{  layout.client.odoo.client.zip }})</template></div>
                                <div class="p-0 m-0" v-if="layout.client.odoo.client.city">{{  layout.client.odoo.client.city }}</div>
                            </td>
                            <td class="fs-6 pb-3 text-right"><i class="cursor-pointer bi bi-pencil" :class="isMobile ? 'fs-4' : 'fs-5'" @click="layout.editBox = 'client'"></i></td>
                        </tr>
                        <template v-if="layout.client.odooId">
                            <tr>
                                <td colspan="3" style="border: none !important;">
                                    <div class="p-0 m-0 fs-5 fw-bold mt-2 align-middle">
                                        Facturación <span class="text-xs3" v-if="layout.client.odoo && layout.client.odoo.address && layout.client.odoo.address?.invoice && layout.client.odoo.address?.invoice?.addresses && layout.client.odoo.address?.invoice?.addresses.length">
                                        ({{layout.client.odoo.address?.invoice?.addresses.length }})
                                        </span>
                                        <span class="text-end float-end align-middle"><div class="friUserCircleMin2 friBack4 cursor-pointer" @click="layout.addBox = 'invoice'"><i class="bi bi-plus-lg text-dark"></i></div></span>
                                    </div>
                                </td>
                            </tr>
                            <template v-if="layout.client.odoo && layout.client.odoo.address && layout.client.odoo.address?.invoice && layout.client.odoo.address?.invoice?.addresses && layout.client.odoo.address?.invoice?.addresses.length">
                                <tr v-for="(address, addressId) in layout.client.odoo.address.invoice.addresses" :key="addressId">
                                    <td class="text-xs3 friColor1 pb-3">
                                        <div class="p-0 m-0">Id: {{ address.id }}</div>
                                        <div class="p-0 m-0">{{ address.name }}</div>
                                        <div class="p-0 m-0">{{ address.mobile }}</div>
                                        <div class="p-0 m-0" v-if="address.email">{{ address.email }}</div>
                                        <div class="p-0 m-0" v-if="address.vat">{{ address.vat }}</div>
                                        <div class="p-0 m-0">{{ address.street }} ({{ address.zip }})</div>
                                        <div class="p-0 m-0">{{ address.city }}</div>
                                    </td>
                                    <td class="fs-6 pb-3 text-right">
                                        <i class="cursor-pointer bi bi-pencil me-2" :class="isMobile ? 'fs-4' : 'fs-5'" @click="layout.editId = addressId; layout.editBox = 'invoice'"></i>
                                        <i class="cursor-pointer bi bi-trash" :class="isMobile ? 'fs-4' : 'fs-5'"  @click="removeOdooAddress(address.id)"></i>
                                    </td>
                                </tr>
                            </template>
                            <template v-else><tr><td colspan="3">No se han registrado direcciones.</td></tr></template>
                            <tr>
                                <td colspan="3" style="border: none !important;">
                                    <div class="p-0 m-0 fs-5 fw-bold mt-2 align-middle">
                                        Envío <span class="text-xs3" v-if="layout.client.odoo && layout.client.odoo.address && layout.client.odoo.address?.delivery && layout.client.odoo.address?.delivery?.addresses && layout.client.odoo.address?.delivery?.addresses.length">
                                            ({{layout.client.odoo.address?.delivery?.addresses.length }})
                                        </span>
                                        <span class="text-end float-end align-middle"><div class="friUserCircleMin2 friBack4 cursor-pointer" @click="layout.addBox = 'delivery'"><i class="bi bi-plus-lg text-dark"></i></div></span>
                                    </div>
                                </td>
                            </tr>
                            <template v-if="layout.client.odoo && layout.client.odoo.address && layout.client.odoo.address?.delivery && layout.client.odoo.address?.delivery?.addresses && layout.client.odoo.address?.delivery?.addresses.length">
                                <tr v-for="(address, addressId) in layout.client.odoo.address.delivery.addresses" :key="addressId">
                                    <td class="text-xs3 friColor1 pb-3">
                                        <div class="p-0 m-0">Id: {{ address.id }}</div>
                                        <div class="p-0 m-0">{{ address.name }}</div>
                                        <div class="p-0 m-0">{{ address.mobile }}</div>
                                        <div class="p-0 m-0" v-if="address.email">{{ address.email }}</div>
                                        <div class="p-0 m-0">{{ address.street }} ({{ address.zip }})</div>
                                        <div class="p-0 m-0">{{ address.city }}</div>
                                    </td>
                                    <td class="fs-6 pb-3 text-right">
                                        <i class="cursor-pointer bi bi-pencil" :class="isMobile ? 'fs-4' : 'fs-5'" @click="layout.editId = addressId; layout.editBox = 'delivery'"></i>
                                        <i class="cursor-pointer bi bi-trash" :class="isMobile ? 'fs-4' : 'fs-5'" @click="removeOdooAddress(address.id)"></i>
                                    </td>
                                </tr>
                            </template>
                            <template v-else><tr><td colspan="3">No se han registrado direcciones.</td></tr></template>
                        </template>
                        <template v-else>
                            <tr>
                                <td colspan="3" class="py-5 ps-3 text-center">
                                    <div v-if="layout.createBoxLoading">
                                        <div>Espere por favor ...</div>
                                        <div class="spinner-border friColor1 mt-5" role="status"><span class="visually-hidden">Cargando ...</span></div>
                                    </div>
                                    <div v-else>
                                        No se han registrado datos de cliente en Odoo.
                                        <div class="mt-4">
                                            <div class="d-flex justify-content-center">
                                                <button class="button px-5 py-1 fw-bold friBack3 br-8 text-white outline-none" @click="createOdooClient()">Crear cliente Odoo</button>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                     </table>
                    </template>
                </div>

                <ion-modal ref="modal" :is-open="layout.editBox" :initial-breakpoint="0.75" :breakpoints="[0.5, 0.75, 1]" :backdrop-dismiss="false">
                    <div class="py-5">
                        <template v-if="!layout.editBoxLoading">
                            <!-- user -->
                            <template v-if="layout.editBox == 'user' && layout.client">
                                <ion-list class="mx-5">
                                    <div class="p-0 m-0 fs-2 fw-bold mx-5">
                                        <template v-if="layout.client.role == 'agent'">Agente</template>
                                        <template v-if="layout.client.role == 'client'">Cliente</template>
                                    </div>
                                    <ion-row>
                                        <ion-col>
                                            <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                            <ion-label position="floating" class="friColor1 fs-6">Nombre *</ion-label>
                                            <ion-input v-model="layout.client.first" type="text" ></ion-input>
                                            </ion-item>
                                        </ion-col>
                                        <ion-col>
                                            <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                            <ion-label position="floating" class="friColor1 fs-6">Apellidos *</ion-label>
                                            <ion-input v-model="layout.client.last" type="text" ></ion-input>
                                            </ion-item>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col>
                                            <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                                <ion-label position="floating" class="friColor1 fs-6">Email *</ion-label>
                                                <ion-input v-model="layout.client.email" type="text" ></ion-input>
                                            </ion-item>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col>
                                            <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                            <ion-label position="floating" class="friColor1 fs-6">Documento</ion-label>
                                            <ion-input v-model="layout.client.document" type="text" ></ion-input>
                                            </ion-item>
                                        </ion-col>
                                    </ion-row>
                                    <template v-if="layout.client.role == 'agent'">
                                        <ion-row>
                                            <ion-col>
                                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                                    <ion-label position="floating" class="friColor1 fs-6">Empresa</ion-label>
                                                    <ion-input v-model="layout.client.company" type="text" ></ion-input>
                                                </ion-item>
                                            </ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col>
                                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                                    <ion-label position="floating" class="friColor1 fs-6">Comisión</ion-label>
                                                    <ion-select interface="popover" v-model="layout.client.commission" class="border-none">
                                                        <ion-select-option v-for="commission in layout.commissions" :key="commission" :value="commission">{{ commission }} %</ion-select-option>
                                                    </ion-select>
                                                </ion-item>
                                            </ion-col>
                                        </ion-row>
                                    </template>
                                    <ion-row>
                                        <ion-col>
                                            <ion-item class="mt-2 border-none" lines="full" style="--ion-item-border-color: #ccc;">
                                                <ion-label>Activo</ion-label>
                                                <ion-toggle :enable-on-off-labels="true" slot="end" v-model="layout.client.active"></ion-toggle>
                                            </ion-item>
                                            <p v-if="!layout.client.active" class="text-center friColor3 fw-bold mt-3">El cliente no puede visualizar los datos en la App, ni recibir notificaciones de alarma de la plataforma.</p>
                                        </ion-col>
                                    </ion-row>
                                </ion-list>
                                <div class="mt-5">
                                    <div class="d-flex justify-content-center">
                                        <button class="button px-5 py-2 m-0 mt-0 bg-light" @click="cancelEdit()">Cancelar</button>
                                        <button class="button px-5 py-2 ms-3 fw-bold friBack1 text-white outline-none" @click="saveUser()">Guardar</button>
                                    </div>
                                </div>
                            </template>
                            <template v-if="layout.client.odoo">
                                <!-- odoo client -->
                                <template v-if="layout.editBox == 'client' && layout.client.odoo.client">
                                    <ion-list class="mx-5">
                                        <div class="p-0 m-0 fs-2 fw-bold mx-5">Cliente <span class="text-xs3">(ID: {{ layout.client.odoo.client.id }})</span></div>
                                        <ion-row>
                                            <ion-col>
                                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                                <ion-label position="floating" class="friColor1 fs-6">Nombre *</ion-label>
                                                <ion-input v-model="layout.client.odoo.client.name" type="text" ></ion-input>
                                                </ion-item>
                                            </ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col>
                                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                                <ion-label position="floating" class="friColor1 fs-6">Email *</ion-label>
                                                <ion-input v-model="layout.client.odoo.client.email" type="text" ></ion-input>
                                                </ion-item>
                                            </ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col>
                                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                                <ion-label position="floating" class="friColor1 fs-6">Documento *</ion-label>
                                                <ion-input v-model="layout.client.odoo.client.vat" type="text" ></ion-input>
                                                </ion-item>
                                            </ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col>
                                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                                <ion-label position="floating" class="friColor1 fs-6">Dirección *</ion-label>
                                                <ion-input v-model="layout.client.odoo.client.street" type="text" ></ion-input>
                                                </ion-item>
                                            </ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col>
                                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                                <ion-label position="floating" class="friColor1 fs-6">Código postal *</ion-label>
                                                <ion-input v-model="layout.client.odoo.client.zip" type="text" ></ion-input>
                                                </ion-item>
                                            </ion-col>
                                            <ion-col>
                                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                                <ion-label position="floating" class="friColor1 fs-6">Ciudad *</ion-label>
                                                <ion-input v-model="layout.client.odoo.client.city" type="text" ></ion-input>
                                                </ion-item>
                                            </ion-col>
                                        </ion-row>
                                    </ion-list>
                                    <div class="mt-5">
                                        <div class="d-flex justify-content-center">
                                            <button class="button px-5 py-2 m-0 mt-0 bg-light" @click="cancelEdit()">Cancelar</button>
                                            <button class="button px-5 py-2 ms-3 fw-bold friBack1 text-white outline-none" @click="saveOdooClient()">Guardar</button>
                                        </div>
                                    </div>
                                </template>
                                <!-- oddoo invoice address  -->
                                <template v-if="layout.editBox == 'invoice' && layout.client.odoo.address?.invoice && layout.client.odoo.address?.invoice?.addresses">
                                    <ion-list class="mx-5">
                                        <div class="p-0 m-0 fs-2 fw-bold mx-5">Dirección de facturación</div>
                                        <ion-row>
                                            <ion-col>
                                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                                <ion-label position="floating" class="friColor1 fs-6">Nombre *</ion-label>
                                                <ion-input v-model="layout.client.odoo.address.invoice.addresses[layout.editId].name" type="text" ></ion-input>
                                                </ion-item>
                                            </ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col>
                                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                                <ion-label position="floating" class="friColor1 fs-6">Email *</ion-label>
                                                <ion-input v-model="layout.client.odoo.address.invoice.addresses[layout.editId].email" type="text" ></ion-input>
                                                </ion-item>
                                            </ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col>
                                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                                <ion-label position="floating" class="friColor1 fs-6">Documento *</ion-label>
                                                <ion-input v-model="layout.client.odoo.address.invoice.addresses[layout.editId].vat" :disabled="true" type="text" ></ion-input>
                                                </ion-item>
                                            </ion-col>
                                            <ion-col>
                                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                                <ion-label position="floating" class="friColor1 fs-6">Teléfono *</ion-label>
                                                <ion-input v-model="layout.client.odoo.address.invoice.addresses[layout.editId].mobile" type="text"></ion-input>
                                                </ion-item>
                                            </ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col>
                                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                                <ion-label position="floating" class="friColor1 fs-6">Dirección *</ion-label>
                                                <ion-input v-model="layout.client.odoo.address.invoice.addresses[layout.editId].street" type="text" ></ion-input>
                                                </ion-item>
                                            </ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col>
                                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                                <ion-label position="floating" class="friColor1 fs-6">Código postal *</ion-label>
                                                <ion-input v-model="layout.client.odoo.address.invoice.addresses[layout.editId].zip" type="text" ></ion-input>
                                                </ion-item>
                                            </ion-col>
                                            <ion-col>
                                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                                <ion-label position="floating" class="friColor1 fs-6">Ciudad *</ion-label>
                                                <ion-input v-model="layout.client.odoo.address.invoice.addresses[layout.editId].city" type="text" ></ion-input>
                                                </ion-item>
                                            </ion-col>
                                        </ion-row>
                                    </ion-list>
                                    <div class="mt-5">
                                        <div class="d-flex justify-content-center">
                                            <button class="button px-5 py-2 m-0 mt-0 bg-light" @click="cancelEdit()">Cancelar</button>
                                            <button class="button px-5 py-2 ms-3 fw-bold friBack1 text-white outline-none" @click="saveOdooInvAdd()">Guardar</button>
                                        </div>
                                    </div>
                                </template>
                                <!-- oddoo delivery address  -->
                                <template v-if="layout.editBox == 'delivery' && layout.client.odoo.address?.delivery && layout.client.odoo.address?.delivery?.addresses">
                                    <ion-list class="mx-5">
                                        <div class="p-0 m-0 fs-2 fw-bold mx-5">Dirección de envío</div>
                                        <ion-row>
                                            <ion-col>
                                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                                <ion-label position="floating" class="friColor1 fs-6">Nombre *</ion-label>
                                                <ion-input v-model="layout.client.odoo.address.delivery.addresses[layout.editId].name" type="text" ></ion-input>
                                                </ion-item>
                                            </ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col>
                                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                                <ion-label position="floating" class="friColor1 fs-6">Email *</ion-label>
                                                <ion-input v-model="layout.client.odoo.address.delivery.addresses[layout.editId].email" type="text" ></ion-input>
                                                </ion-item>
                                            </ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col>
                                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                                <ion-label position="floating" class="friColor1 fs-6">Documento *</ion-label>
                                                <ion-input v-model="layout.client.odoo.address.delivery.addresses[layout.editId].vat" :disabled="true" type="text" ></ion-input>
                                                </ion-item>
                                            </ion-col>
                                            <ion-col>
                                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                                <ion-label position="floating" class="friColor1 fs-6">Teléfono *</ion-label>
                                                <ion-input v-model="layout.client.odoo.address.delivery.addresses[layout.editId].mobile" type="text"></ion-input>
                                                </ion-item>
                                            </ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col>
                                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                                <ion-label position="floating" class="friColor1 fs-6">Dirección *</ion-label>
                                                <ion-input v-model="layout.client.odoo.address.delivery.addresses[layout.editId].street" type="text" ></ion-input>
                                                </ion-item>
                                            </ion-col>
                                        </ion-row>
                                        <ion-row>
                                            <ion-col>
                                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                                <ion-label position="floating" class="friColor1 fs-6">Código postal *</ion-label>
                                                <ion-input v-model="layout.client.odoo.address.delivery.addresses[layout.editId].zip" type="text" ></ion-input>
                                                </ion-item>
                                            </ion-col>
                                            <ion-col>
                                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                                <ion-label position="floating" class="friColor1 fs-6">Ciudad *</ion-label>
                                                <ion-input v-model="layout.client.odoo.address.delivery.addresses[layout.editId].city" type="text" ></ion-input>
                                                </ion-item>
                                            </ion-col>
                                        </ion-row>
                                    </ion-list>
                                    <div class="mt-5">
                                        <div class="d-flex justify-content-center">
                                            <button class="button px-5 py-2 m-0 mt-0 bg-light" @click="cancelEdit()">Cancelar</button>
                                            <button class="button px-5 py-2 ms-3 fw-bold friBack1 text-white outline-none" @click="saveOdooDelAdd()">Guardar</button>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </template>
                        <template v-else>
                            <div class="text-center w-100"  style="margin-top: 150px;">
                                <div class="fw-bold fs-4">Perfil de cliente</div>
                                <div class="mt-2">Espere por favor ...</div>
                                <div class="spinner-border friColor1 mt-5" role="status">
                                <span class="visually-hidden">Cargando ...</span>
                                </div>
                            </div>
                        </template>
                    </div>
                </ion-modal>

                <ion-modal ref="modal" :is-open="layout.addBox" :initial-breakpoint="0.75" :breakpoints="[0.5, 0.75, 1]" :backdrop-dismiss="false">
                    <div class="py-5">
                        <template v-if="!layout.addBoxLoading">
                            <ion-list class="mx-5">
                                <div class="p-0 m-0 fs-2 fw-bold mx-5">Nueva dirección</div>
                                <span class="p-0 m-0 fs-5 friColor1 mx-5" v-if="layout.addBox"><template v-if="layout.addBox == 'delivery'">Envío</template><template v-else>Facturación</template></span>
                                <ion-row>
                                    <ion-col>
                                        <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                        <ion-label position="floating" class="friColor1 fs-6">Nombre *</ion-label>
                                        <ion-input v-model="layout.addAddress.name" type="text" ></ion-input>
                                        </ion-item>
                                    </ion-col>
                                </ion-row>
                                <ion-row>
                                    <ion-col>
                                        <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                        <ion-label position="floating" class="friColor1 fs-6">Email *</ion-label>
                                        <ion-input v-model="layout.addAddress.email" type="text" ></ion-input>
                                        </ion-item>
                                    </ion-col>
                                </ion-row>
                                <ion-row>
                                    <ion-col>
                                        <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                        <ion-label position="floating" class="friColor1 fs-6">Teléfono *</ion-label>
                                        <ion-input v-model="layout.addAddress.mobile" type="text"></ion-input>
                                        </ion-item>
                                    </ion-col>
                                </ion-row>
                                <ion-row>
                                    <ion-col>
                                        <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                        <ion-label position="floating" class="friColor1 fs-6">Dirección *</ion-label>
                                        <ion-input v-model="layout.addAddress.street" type="text" ></ion-input>
                                        </ion-item>
                                    </ion-col>
                                </ion-row>
                                <ion-row>
                                    <ion-col>
                                        <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                        <ion-label position="floating" class="friColor1 fs-6">Código postal *</ion-label>
                                        <ion-input v-model="layout.addAddress.zip" type="text" ></ion-input>
                                        </ion-item>
                                    </ion-col>
                                    <ion-col>
                                        <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                        <ion-label position="floating" class="friColor1 fs-6">Ciudad *</ion-label>
                                        <ion-input v-model="layout.addAddress.city" type="text" ></ion-input>
                                        </ion-item>
                                    </ion-col>
                                </ion-row>
                            </ion-list>
                            <div class="mt-3">
                                <div class="d-flex justify-content-center">
                                    <button class="button px-5 py-2 m-0 mt-0 bg-light" @click="cancelAdd()">Cancelar</button>
                                    <button class="button px-5 py-2 ms-3 fw-bold friBack1 text-white outline-none" @click="createAddress()">Guardar</button>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="text-center w-100"  style="margin-top: 150px;">
                                <div class="fw-bold fs-4">Creando dirección</div>
                                <div class="mt-2">Espere por favor ...</div>
                                <div class="spinner-border friColor1 mt-5" role="status">
                                <span class="visually-hidden">Cargando ...</span>
                                </div>
                            </div>
                        </template>
                    </div>
                </ion-modal>

            </div>
        </template>
        <template v-else>
            <div class="text-center w-100"  style="margin: 150px 0;">
                <div class="fw-bold fs-4">Clientes</div>
                <div class="mt-2">Espere por favor ...</div>
                <div class="spinner-border friColor1 mt-5" role="status">
                <span class="visually-hidden">Cargando ...</span>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import { useAuthStore } from "@/store";
import { helper as $h } from "../../../utils/helper.js";
import { getClient, updateClient, clientWelcome } from "../../../model/api.js";
import { not as $not } from "../../../model/not";  
import { OdooReq } from "../../../model/odoo.js";
import { IonItem, IonLabel, IonList, IonCol, IonRow, IonInput, IonModal, IonToggle, alertController, IonSelect } from '@ionic/vue'; 

export default defineComponent({
name: 'clientDash',
props: {
    isMobile: Boolean,
    clientId: String
    },
    components: {  IonItem, IonLabel, IonList, IonCol, IonRow, IonInput, IonModal, IonToggle, IonSelect }, 
setup(props) {

    const authStore     = useAuthStore();
    const layout        = ref({ 
                            client:     null,
                            editBox:    false,
                            addBox:     false,
                            addAddress: null,
                            editId:     null,
                            helper:     $h,
                            })

    const { user, profile } = authStore;

    const saveUser = async () => {
        layout.value.editBoxLoading = true
        let validate = true
        let required = ['first', 'last', 'email']
        required.forEach((field) => {  if(!layout.value.client[field])validate = false })
        if(validate){
            await updateClient(layout.value.client)
            $not.toastApp({ message: 'Se ha actualizado el cliente correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, cssClass: 'text-white mt-2 friToast1'})
            await cancelEdit()
        }else $not.toastApp({ message: 'Debe rellenar los parámetros requeridos', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
        layout.value.editBoxLoading = false
    }

    const saveOdooClient = async () => {
        layout.value.editBoxLoading = true
        let validate = true
        let required = ['name', 'email', 'vat', 'street', 'zip', 'city']
        required.forEach((field) => {  if(!layout.value.client.odoo.client[field])validate = false })
        if(validate){
            let UpdOdooClient  = await OdooReq('update-client',layout.value.client.odoo.client)
            if(UpdOdooClient.response && UpdOdooClient.response.data && !UpdOdooClient.response.data?.error){
                $not.toastApp({ message: 'Se ha actualizado el cliente correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, cssClass: 'text-white mt-2 friToast1'})
                await cancelEdit()
            }else $not.toastApp({ message: 'No se ha podido actualizar el cliente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
        }else $not.toastApp({ message: 'Debe rellenar los parámetros requeridos', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
        layout.value.editBoxLoading = false
    }

    const sendWelcome = async () => {
        const alert = await alertController.create({
            header:  "¿Está seguro que desea enviar el email de bienvenida? " + layout.value.client.email,
            cssClass: 'friCustomAlert',
            buttons: [ 
                        {text: 'Cancelar', cssClass: 'alert-button-cancel', role: 'cancel', handler: async () => {  } }, 
                        {text: 'Confirmar',cssClass: 'alert-button-confirm', handler: async () => { 
                            await clientWelcome({ clientId: layout.value.client.id }).then(() => { 
                                $not.toastApp({ message: 'Se ha enviado el email de bienvenida correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, cssClass: 'text-white mt-2 friToast1'})
                            }).catch(() => { 
                                $not.toastApp({ message: 'No se ha podido enviar el email de bienvenida', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
                            })
                         }
                        }
                    ],
        });
        await alert.present();
    }

    const cancelEdit = async () => {
        layout.value.editBox = false
        layout.value.editId  = false
        await initDash()
    }

    const cancelAdd = async () => {
        layout.value.addBox = false
    }

    const initAddAddress = () => {
        layout.value.addAddress = { name: '', email: '', mobile: '', street: '', zip: '', city: '' }
        if(layout.value.client){
            layout.value.addAddress.name  = layout.value.client.first + ' ' + layout.value.client.last
            layout.value.addAddress.email = layout.value.client.email
            layout.value.addAddress.vat   = layout.value.client.document
        }
    }

    const saveOdooInvAdd = async () => {
        layout.value.editBoxLoading = true
        let validate = true
        let required = ['name', 'email', 'vat', 'mobile', 'street', 'zip', 'city']
        required.forEach((field) => {  if(!layout.value.client.odoo.address.invoice.addresses[layout.value.editId][field])validate = false })
        if(validate){
            let UpdOdooAddress = await OdooReq('update-client-address',layout.value.client.odoo.address.invoice.addresses[layout.value.editId])
            if(UpdOdooAddress.response && UpdOdooAddress.response.data && !UpdOdooAddress.response.data?.error){
                $not.toastApp({ message: 'Se ha actualizado la dirección de facturación correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, cssClass: 'text-white mt-2 friToast1'})
                await cancelEdit()
            }else $not.toastApp({ message: 'No se ha podido actualizar la dirección de facturación', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
        }else $not.toastApp({ message: 'Debe rellenar los parámetros requeridos', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
        layout.value.editBoxLoading = false
    }

    const saveOdooDelAdd = async () => {
        layout.value.editBoxLoading = true
        let validate = true
        let required = ['name', 'email', 'vat', 'mobile', 'street', 'zip', 'city']
        required.forEach((field) => {  if(!layout.value.client.odoo.address.delivery.addresses[layout.value.editId][field])validate = false })
        if(validate){
            let UpdOdooAddress = await OdooReq('update-client-address',layout.value.client.odoo.address.delivery.addresses[layout.value.editId])
            if(UpdOdooAddress.response && UpdOdooAddress.response.data && !UpdOdooAddress.response.data?.error){
                $not.toastApp({ message: 'Se ha actualizado la dirección de facturación correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, cssClass: 'text-white mt-2 friToast1'})
                await cancelEdit()
            }else $not.toastApp({ message: 'No se ha podido actualizar la dirección de facturación', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
        }else $not.toastApp({ message: 'Debe rellenar los parámetros requeridos', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
        layout.value.editBoxLoading = false
    }

    const createAddress = async () => {
        layout.value.addBoxLoading = true
        let validate                = true
        let required                = ['name', 'email', 'mobile', 'street', 'zip', 'city']
        required.forEach((field) => {  if(!layout.value.addAddress[field])validate = false })
        if(validate){
            layout.value.addAddress.id   = layout.value.client.odoo.client.id
            layout.value.addAddress.type = 'delivery'
            if(layout.value.addBox == 'invoice')layout.value.addAddress.type = 'invoice'
            layout.value.addAddress.parent_id  = layout.value.client.odoo.client.id
            layout.value.addAddress.country_id = 68 
            let addOdooAddress = await OdooReq('create-client-address',layout.value.addAddress)
            if(addOdooAddress.response && addOdooAddress.response.data && !addOdooAddress.response.data?.error){
                $not.toastApp({ message: 'Se ha creado la dirección correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, cssClass: 'text-white mt-2 friToast1'})
                await cancelAdd()
                await initDash()
            }else $not.toastApp({ message: 'No se ha podido crear la dirección', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
        }else $not.toastApp({ message: 'Debe rellenar los parámetros requeridos', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
        layout.value.addBoxLoading = false
    }

    const removeOdooAddress = async (addressId) => {
        const alert = await alertController.create({
            header:  "¿Está seguro? (" + addressId + ") Esta acción és irreversible",
            cssClass: 'friCustomAlert',
            buttons: [ 
                        {text: 'Cancelar', cssClass: 'alert-button-cancel', role: 'cancel', handler: async () => {  } }, 
                        {text: 'Confirmar',cssClass: 'alert-button-confirm', handler: async () => { 
                            let deleteOdooAddress = await OdooReq('remove-client-address', { addressId: addressId })
                            if(deleteOdooAddress.response && deleteOdooAddress.response.data && !deleteOdooAddress.response.data?.error){
                                $not.toastApp({ message: 'Se ha eliminado la dirección correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, cssClass: 'text-white mt-2 friToast1'})
                                await initDash()
                            }else $not.toastApp({ message: 'No se ha podido eliminar la dirección', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
                        }
                        }
                    ],
        });
        await alert.present();
    }

    const createOdooClient = async () => {
        layout.value.createBoxLoading = true
        let nClient = { name: layout.value.client.first + ' ' + layout.value.client.last, email: layout.value.client.email}
        nClient.is_company = true
        nClient.active     = true
        nClient.comment    = 'Cliente Alba: ' + layout.value.client.id
        nClient.country_id =  68 //Spain
        if (layout.value.client.document)nClient.vat = layout.value.client.document
        let clientOdooCreate = await OdooReq('create-client', nClient)
        if(clientOdooCreate.response && clientOdooCreate.response.data && !clientOdooCreate.response.data?.error && clientOdooCreate.response.data.clientId){
            layout.value.client.odooId = clientOdooCreate.response.data.clientId
            await updateClient(layout.value.client)
            $not.toastApp({ message: 'Se ha creado el cliente correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, cssClass: 'text-white mt-2 friToast1'})
            await initDash()
        }else $not.toastApp({ message: 'No se ha podido crear el cliente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
        layout.value.createBoxLoading = false
    }

    const initDash = async () => {
        layout.value.client        = await getClient(props.clientId)
        layout.value.commissions   = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,25,50,75,100]
        //odoo
        layout.value.client.odoo = {}
        if (layout.value.client.odooId){

          initAddAddress()

          /*let odooClients  = await OdooReq('clients', { id: layout.value.client.odooId })
          if(!odooClients?.error)layout.value.client.odoo['clients'] = odooClients.response.data?.clients*/
          
          let odooProfile  = await OdooReq('client', { id: layout.value.client.odooId })
          if(!odooProfile?.error)layout.value.client.odoo['client'] = odooProfile.response.data?.client
          
          layout.value.client.odoo.address = {}

          let odooDelivery = await OdooReq('client-address-delivery', { id: layout.value.client.odooId })
          if(!odooDelivery?.error)layout.value.client.odoo['address']['delivery'] = odooDelivery.response.data

          let odooInvoiceAddress = await OdooReq('client-address-invoice', { id: layout.value.client.odooId })
          if(!odooInvoiceAddress?.error)layout.value.client.odoo['address']['invoice'] = odooInvoiceAddress.response.data

            /*
          layout.value.client.odoo.products = {}

          let odooProductPriceList = await OdooReq('product-pricelist', { id: layout.value.client.odooId })
          if(!odooProductPriceList?.error)layout.value.client.odoo['products']['pricelist'] = odooProductPriceList.response.data

          
          let odooProductCategories = await OdooReq('product-categories', { id: layout.value.client.odooId })
          if(!odooProductCategories?.error)layout.value.client.odoo['products']['categories'] = odooProductCategories.response.data

          let odooProducts = await OdooReq('products', { categoryId: 5 })
          if(!odooProducts?.error)layout.value.client.odoo['products']['alba-hub'] = odooProducts.response.data

          odooProducts = await OdooReq('products', { categoryId: 6 })
          if(!odooProducts?.error)layout.value.client.odoo['products']['alba-hub-hardware'] = odooProducts.response.data

          odooProducts = await OdooReq('products', { categoryId: 7 })
          if(!odooProducts?.error)layout.value.client.odoo['products']['alba-hub-services'] = odooProducts.response.data

          
          let odooSubscriptions = await OdooReq('client-subscriptions', { id: layout.value.client.odooId })
          if(!odooSubscriptions?.error)layout.value.client.odoo['subscriptions'] = odooSubscriptions.response.data

          
         

          layout.value.client.odoo.orders = {}

          let odooOrdersDraft   = await OdooReq('client-orders', { id: layout.value.client.odooId, state: 'draft' })
          if(!odooOrdersDraft?.error)layout.value.client.odoo['orders']['draft'] = odooOrdersDraft.response.data.orders

          let odooOrdersSale   = await OdooReq('client-orders', { id: layout.value.client.odooId, state: 'sale' })
          if(!odooOrdersSale?.error)layout.value.client.odoo['orders']['sale'] = odooOrdersSale.response.data.orders

          let odooBudget   = await OdooReq('client-budgets', { id: layout.value.client.odooId })
          if(!odooBudget?.error)layout.value.client.odoo['budgets'] = odooBudget.response.data

          let odooInvoices = await OdooReq('client-invoices', { id: layout.value.client.odooId })
          if(!odooInvoices?.error)layout.value.client.odoo['invoices'] = odooInvoices.response.data

          let odooPayments = await OdooReq('client-payments', { id: layout.value.client.odooId })
          if(!odooPayments?.error)layout.value.client.odoo['payments'] = odooPayments.response.data
          */
        }
    }

    onMounted( async () => { 
        await initDash();
    });
    
    return { layout, user, props, profile, saveUser, sendWelcome, cancelEdit, saveOdooClient, saveOdooInvAdd, saveOdooDelAdd, cancelAdd, createAddress, removeOdooAddress, createOdooClient };
}

})
</script>