<template>
     <div class="account-wrapper text-left px-0 mx-0">
        <div class="columns px-0 mx-0">
            <!-- Desktop Navigation -->
            <div class="column is-4 mt-5 px-0 mx-0" v-if="!isMobile">
                <div class="account-box is-navigation p-0 mt-0" style="border: none !important;">
                    <div class="account-menu me-5">
                        <div v-for="(section, index) in layout.sections" :key="index" class="account-menu-item cursor-pointer" 
                            :class="layout.section == section.name ? 'router-link-active router-link-exact-active' : ''" @click="$router.push({ path: '/site/' + layout.appId + '/' + layout.sceneId, query: { section:  section.name } })"><!--layout.section = section.name-->
                            <i aria-hidden="true" class="lnil lnil-user-alt"></i>
                            <span><i :class="section.icon + ' fs-3 align-middle me-3'"></i>{{ section.title }}</span>
                            <span class="end"><i aria-hidden="true" class="fas fa-arrow-right"></i></span>
                        </div>
                        <div class="account-menu-item mt-2 cursor-pointer" @click="goBack()">
                            <i aria-hidden="true" class="lnil lnil-quill"></i>
                            <span><i class="bi bi-chevron-compact-left fs-3 align-middle me-3"></i>Volver</span>
                            <span class="end"><i aria-hidden="true" class="fas fa-arrow-right"></i></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column px-0 mx-0" :class="isMobile ? '' : 'mt-5'" :style="isMobile ? '' : 'padding-bottom: 100px;'">
                <template v-if="layout.section == 'dashboard'"><Dash :appId="layout.appId" :sceneId="layout.sceneId" :isMobile="isMobile"  /></template>
                <template v-if="layout.section == 'settings'"><Settings :appId="layout.appId" :sceneId="layout.sceneId" :isMobile="isMobile"  /></template>
                <template v-if="layout.section == 'devices'"><Devices :appId="layout.appId" :sceneId="layout.sceneId" :isMobile="isMobile"  /></template>
                <template v-if="layout.section == 'place'"><Place :appId="layout.appId" :sceneId="layout.sceneId" :isMobile="isMobile"  /></template>
                <template v-if="layout.section == 'log'"><Log :appId="layout.appId" :sceneId="layout.sceneId" :isMobile="isMobile"  /></template>
            </div>
        </div>
    </div>
  </template>
  <script>
  import { defineComponent, onMounted, ref, watch } from "vue";  //, onUnmounted
  import { useAuthStore } from "@/store";
  import { add } from 'ionicons/icons';
  import router from "@/router";
  import { useRoute } from 'vue-router'
  import { getApp } from "../../model/apps.js";  

  import Dash from '@/components/pages/site/dashboard.vue';
  import Settings from '@/components/pages/site/settings.vue';
  import Devices from '@/components/pages/site/devices.vue';
  import Place from '@/components/pages/site/place.vue';
  import Log from '@/components/pages/site/log.vue';

  export default defineComponent({
    name: 'sitePage',
    props: {
        isMobile: Boolean,
    },
    components: { Dash, Settings, Devices,Place, Log },
    setup(props) {
  
       const authStore      = useAuthStore();
       const route          = useRoute()
       const layout         = ref({ 
                                appId:       null,
                                sceneId:     null,
                                app:         null,
                                scene:       null,
                                section:     null,
                                sections:    [],
                            }) 

       const { user, profile } = authStore;

       const goBack = () => {
        router.push({ path: '/home', query: { section:  'dashboard' } })
       }

       const initScene = async () => {
        if(!layout.value.section)
        if(route.query.section) layout.value.section = route.query.section
        else layout.value.section  = 'dashboard'
        layout.value.appId         = router.currentRoute.value.params.appId;
        layout.value.sceneId       = router.currentRoute.value.params.SceneId;
        layout.value.app           = await getApp(layout.value.appId);
        await initMenu();
       }

       const initMenu = async () => {
        layout.value.sections      =    [{  title: 'Espacio',  name: 'dashboard',    icon: 'bi bi-snow3' }]
        if(layout.value.app && layout.value.app?.limits?.reports?.log)layout.value.sections.push({  title: 'Historial',  name: 'log', icon: 'bi bi-graph-up' })   
       
        if(profile.isClient || profile.isAgent){
            layout.value.sections.push({  title: 'Espacio',     name: 'place',  icon: 'bi bi-box',   client: true})   
            layout.value.sections.push({  title: 'Configuración',   name: 'settings',  icon: 'bi bi-gear',   client: true})   
        }
       }

       onMounted( async () => { 
            await initScene();
       });
  
        watch(() => route.query, async (query) => {
            if (query){
                layout.value.query    = query; 
                layout.value.section  = query.section;
                layout.value.appId    = router.currentRoute.value.params.appId;
                layout.value.sceneId  = router.currentRoute.value.params.SceneId;
                await initMenu();
            }
        });

        return { layout, router, user, profile, add, props, goBack };
    }
    
  })
  </script>