<template>
     <div class="account-wrapper text-left">
        <div class="columns">
            <!-- Desktop Navigation -->
            <div class="column is-4 mt-5" v-if="!isMobile">
                <div class="account-box is-navigation p-0 mt-0" style="border: none !important;">
                    <div class="account-menu me-5">
                        <div v-for="(section, index) in layout.sections" :key="index" class="account-menu-item cursor-pointer" 
                            :class="layout.section == section.name ? 'router-link-active router-link-exact-active' : ''" @click="layout.section = section.name">
                            <i aria-hidden="true" class="lnil lnil-user-alt"></i>
                            <span><i :class="section.icon + ' fs-3 align-middle me-3'"></i>{{ section.title }}</span>
                            <span class="end"><i aria-hidden="true" class="fas fa-arrow-right"></i></span>
                        </div>
                        <div class="account-menu-item mt-2 cursor-pointer" @click="goBack()">
                            <i aria-hidden="true" class="lnil lnil-quill"></i>
                            <span><i class="bi bi-chevron-compact-left fs-3 align-middle me-3"></i>Volver</span>
                            <span class="end"><i aria-hidden="true" class="fas fa-arrow-right"></i></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column ps-0"  :class="isMobile ? 'is-12' : 'is-8 mt-5'" v-if="layout.appId">
                <template v-if="layout.section == 'dashboard'">
                    <Dash :appId="layout.appId" :deviceId="layout.deviceId" :isMobile="isMobile"  />
                </template>
                <template v-if="layout.section == 'settings'">
                    <Settings :appId="layout.appId" :deviceId="layout.deviceId" :isMobile="isMobile"  />
                </template>
                <template v-if="layout.section == 'alarms'">
                    <Alarns :appId="layout.appId" :deviceId="layout.deviceId" :isMobile="isMobile"  />
                </template>
                <template v-if="layout.section == 'stats'">
                    <Stats :appId="layout.appId" :deviceId="layout.deviceId" :isMobile="isMobile"  />
                </template>
                <template v-if="layout.section == 'actions'">
                    <Actions :appId="layout.appId" :deviceId="layout.deviceId" :isMobile="isMobile"  />
                </template>
            </div>
        </div>
    </div>
  </template>
  <script>
  import { defineComponent, onMounted, ref, watch } from "vue";  //, onUnmounted
  import { useAuthStore } from "@/store";
  import { add} from 'ionicons/icons';
  import router from "@/router";
  import { useRoute } from 'vue-router'
  /*
  import { _firebase as $_firebase } from "../../model/firebase";
  import { onSnapshot, doc } from "firebase/firestore"; 
  const db   =  $_firebase.firestore()
  */
  import Dash from '@/components/pages/sensor/dashboard.vue';
  import Settings from '@/components/pages/sensor/settings.vue';
  import Alarns from '@/components/pages/sensor/alarms.vue';
  import Stats from '@/components/pages/sensor/stats.vue';
  import { getApp } from "../../model/apps.js";

  export default defineComponent({
    name: 'SensorPage',
    props: {
        isMobile: Boolean,
     },
     components: { Dash, Alarns, Settings, Stats },
    setup(props) {
  
       const authStore      = useAuthStore();
       const route          = useRoute()
       const layout         = ref({ 
                                appId:       null,
                                deviceId:    null,
                                app:         null,
                                device:      null,
                                section:     null,
                                sceneId:     null,
                                sections:    [],
                            }) 

       const { user, profile } = authStore;

       const goBack = () => {
            if(layout.value.sceneId)
                router.push({ path: '/site/' + layout.value.appId + "/" + layout.value.sceneId, query: { section:  'dashboard' } })  
            else router.push({ path: '/device/' + layout.value.appId, query: { section:  'dashboard' } })  
       }

       const initSensor = async () => {
           
           if(!layout.value.section)
            if(route.query.section) layout.value.section = route.query.section
            else layout.value.section = 'dashboard'

           if (profile && (!profile.isClient && !profile.isAgent)){
            layout.value.sections = layout.value.sections.filter((tab) => !tab.client);
            if (layout.value.section == 'alarms' || layout.value.section == 'settings') layout.value.section = 'dashboard';
           }

           layout.value.appId      = router.currentRoute.value.params.appId;
           layout.value.deviceId   = router.currentRoute.value.params.deviceId;
           layout.value.app        = await getApp(layout.value.appId)
           layout.value.sections   = [{  title: 'Dispositivo',  name: 'dashboard', icon: 'bi bi-wifi', size: "50" }]
           
           if(profile.isClient || profile.isAgent)layout.value.sections.push({   title: 'Alarmas',      name: 'alarms',       icon: 'bi bi-bell', size: "38",   client: true })
           if(layout.value.app && layout.value.app?.limits?.alarms?.log)layout.value.sections.push({  title: 'Historial',  name: 'stats', icon: 'bi bi-graph-up', size: "45" })   
           if(profile.isClient || profile.isAgent)layout.value.sections.push({  title: 'Configuración',   name: 'settings',  icon: 'bi bi-gear',      size: "45",   client: true})   
          
       }

       onMounted( async () => { 
            await initSensor();
            //await detectSensorChanges();
       });
  
        watch(() => route.query, (query) => {
            if (query){
                layout.value.query   = query; 
                layout.value.section = query.section;
                if(query.sceneId)layout.value.sceneId = query.sceneId;
            }
        });

        /*const detectSensorChanges = async () => {
          const collectionPath = "apps/" + layout.value.appId + "/devices/";
          const docRef         = doc(db, collectionPath,  layout.value.deviceId);
          const unsubscribeDetectSensorChanges = onSnapshot(docRef, async (docSnapshot) => {
            if (docSnapshot.exists()) {
                layout.value.section = "dashboard"
                await initSensor();
                console.log("Document data:", docSnapshot.data());
            } else {
                console.log("El documento no existe.");
            }
          }, (error) => { console.log("Error:", error); });
          onUnmounted(() => { unsubscribeDetectSensorChanges(); });
       };*/

        return { layout, user, profile, add, props, goBack };
    }
    
  })
  </script>