<template>
    <div class="account-box is-form is-footerless px-0 mx-0" :style="isMobile ? 'border-bottom: none !important;' : ''">
        <template v-if="layout.app">
            <div class="form-head stuck-header" :class="isMobile ? 'py-0' : 'py-3'">
                <div class="form-head-inner">
                    <div class="left"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5 ms-2' : 'fs-4'">{{  layout.app.name  }} </h4></div>
                    <div class="inline-block align-top fs-6 mt-0" style="margin-top: 4px;">
                        <template v-if="layout.app.logo"><img :src="layout.app.logo" style="height: 50px;" /></template>
                        <template v-else>{{  layout.app.id }}</template>
                    </div>
                </div>
            </div>
            <div class="pt-2 pb-2 fs-5 friBack1 text-white text-center ps-3"><span class="align-middle" v-if="layout.device && layout.device.name">{{ layout.device.name }}</span></div>
            <div class="pt-2 pb-2 fs-6 friBack4 text-center ps-3" ><span class="align-middle" v-if="layout.device && layout.device.typeName">{{ layout.device.typeName }}</span></div>

            <div class="px-3 pt-3" v-if="layout.alarm">
                <div class="d-flex justify-content-center align-items-center fs-5 my-3">
                    <div class="friUserCircle friBack5 p-3 cursor-pointer"><i class="bi bi-bell text-white friColor4" style="font-size: 35px;"></i></div>
                </div>
                <div v-if="layout.alarm" class="text-center fs-6 mt-3">Alarma personalizada</div>
                <div class="text-center fs-3">{{ layout.alarm.name }}</div>
                <div class="text-center fs-6 px-5 mx-5">{{ layout.alarm.description }}.</div>
                <ion-list  v-if="layout.alarm" class="pt-0 mx-5 mt-5">
                    <ion-item class="mt-2 border-none" lines="full" style="--ion-item-border-color: #ccc;">
                        <ion-label>Eliminar alarma</ion-label>
                        <ion-toggle :enable-on-off-labels="true" slot="end" :disabled="true" :checked="true" ></ion-toggle>
                    </ion-item>
                    <ion-item class="mt-2 border-none" lines="full" style="--ion-item-border-color: #ccc;">
                        <ion-label>Eliminar historial de alarmas</ion-label>
                        <ion-toggle :enable-on-off-labels="true" slot="end" :disabled="true" :checked="true" ></ion-toggle>
                    </ion-item>
                </ion-list>
                <div :style="isMobile ? 'margin-top: 40px; margin-bottom: 80px;' : 'margin: 30px 0;'">
                    <div class="d-flex justify-content-center align-items-center fs-5">
                        <div class="fw-bold friColor3 fs-4 text-end float-end cursor-pointer" @click="delAlarm()">ELIMINAR</div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="text-center w-100"  style="margin-top: 150px;">
                <div class="fw-bold fs-1">Notificaciones</div>
                <div class="mt-2">Espere por favor ...</div>
                <div class="spinner-border friColor1 mt-5" role="status">
                <span class="visually-hidden">Cargando ...</span>
                </div>
            </div>
        </template>   
    </div>
  </template>
  <script>
  import { defineComponent, onMounted, ref } from "vue"; 
  import { useAuthStore } from "@/store";
  import { getApp, getAppDevices, getAppUsers  } from "../../../model/apps.js"; 
  import { helper as $h } from "../../../utils/helper.js";
  import { getAlarm, removeAlarm } from "../../../model/alarms.js";
  import { add } from 'ionicons/icons';
  import { IonList, IonLabel, IonItem, IonToggle, alertController } from '@ionic/vue'; //, IonIconalertController
  import { getClient } from "../../../model/api.js";
  import { not as $not } from "../../../model/not";
  import router from "@/router";

  export default defineComponent({
    name: 'DeviceDash',
    props: {
        isMobile: Boolean,
        appId:    String,
        deviceId: String,
        alarmId:  String,
     },
     components: { IonList, IonLabel, IonItem, IonToggle },
    setup(props) {
  
       const authStore      = useAuthStore();
       const layout = ref({ 
                            appId:      null,
                            device:     null,
                            newNotify:  null,
                            addBox:     false,
                            editBox:    false,
                            editLoading:false,
                            notify:     null,
                            helper:     $h
                          }) 

       const { user, profile } = authStore;

      
       const initRemove = async () => {
         layout.value.app      = await getApp(layout.value.appId)
         let deviceData        = await getAppDevices(layout.value.appId, layout.value.deviceId)
         if(deviceData && deviceData?.devices && Object.keys(deviceData.devices).length)layout.value.device = deviceData.devices[Object.keys(deviceData.devices)[0]]
         layout.value.alarm    = await getAlarm(layout.value.appId, layout.value.deviceId, layout.value.alarmId)
         if(layout.value.alarm.users && layout.value.alarm.users.length)
            for (let i = 0; i < layout.value.alarm.users.length; i++) { layout.value.alarm.users[i].user = await getClient(layout.value.alarm.users[i].userId) }
         layout.value.appUsers = await getAppUsers(layout.value.appId)
         layout.value.addUsers = layout.value.appUsers
       }

       const delAlarm = async () => {
            const alert = await alertController.create({
                header:  "¿Está seguro? Esta acción és irreversible",
                cssClass: 'friCustomAlert',
                buttons: [ 
                            {text: 'Cancelar', cssClass: 'alert-button-cancel', role: 'cancel' },  
                            {text: 'Confirmar',cssClass: 'alert-button-confirm', handler: async () => { 
                                let respRemoveAlarm = await removeAlarm(layout.value.appId, layout.value.deviceId, layout.value.alarmId)
                                if (respRemoveAlarm && respRemoveAlarm.success)$not.toastApp({ header: layout.value.app.name, message: 'Se ha eliminado la alarma correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'primary', cssClass: 'text-white mt-2' })
                                else $not.toastApp({ header: layout.value.app.name, message: 'No se ha podido eliminar la alarma', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
                                router.push({ path: '/sensor/' + layout.value.appId + '/' + layout.value.deviceId , query: { section:  'alarms' } })
                            }
                            }
                        ],
            });
            await alert.present();
        }

       onMounted( async () => { 
         layout.value.appId     = props.appId
         layout.value.deviceId  = props.deviceId
         layout.value.alarmId   = props.alarmId
         if(router.currentRoute.value.params.appId)layout.value.appId = router.currentRoute.value.params.appId;
         if(router.currentRoute.value.params.deviceId)layout.value.deviceId = router.currentRoute.value.params.deviceId;
         if(router.currentRoute.value.params.alarmId)layout.value.alarmId = router.currentRoute.value.params.alarmId;
         await initRemove();
       });

        return { layout, user, add, props, profile, delAlarm  };
    }
    
  })
  </script>