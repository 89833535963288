const getCities = async () => {
    //https://openweathermap.org/city/X
    return [
        { "id": 3130131, "name": "Amposta" },
        { "id": 3128760, "name": "Barcelona" },
        { "id": 3127066, "name": "Calafell" },
        { "id": 3121960, "name": "Fuenlabrada" },
        { "id": 3121456, "name": "Girona" },
        { "id": 2516479, "name": "Ibiza" },
        { "id": 3118514, "name": "Lleida" },
        { "id": 3119779, "name": "L'escala" },
        { "id": 3117735, "name": "Madrid"},
        { "id": 3111199, "name": "Sabadell" },
        { "id": 3108288, "name": "Tarragona" },
        { "id": 3108286, "name": "Terrassa" },
        { "id": 2510409, "name": "Toledo" },
        { "id": 3107677, "name": "Tortosa" },
    ]
}

export { getCities };