<template>
    <div class="account-box is-form is-footerless" :style="isMobile ? 'border-bottom: none !important;' : ''">
        <template v-if="layout.app">
            <div class="form-head stuck-header" :class="isMobile ? 'py-0 ps-3 pe-3' : 'py-3'">
                <div class="form-head-inner">
                    <div class="left"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5 ms-2' : 'fs-4'">{{  layout.app.name }} </h4></div>
                    <div class="inline-block align-top me-1 fs-6 mt-0" style="margin-top: 4px;">
                        <template v-if="layout.app.logo"><img :src="layout.app.logo" style="height: 50px;" /></template>
                        <template v-else>{{  layout.app.id }}</template>
                    </div>
                </div>
            </div>
            <div class="px-5 pt-3">
                <div class="text-center fs-1 mt-3">Configuración</div>
                <div class="text-center mt-2 mb-3 mx-5">
                    Administre la configuración de su Alba Hub.
                </div>
            </div>        
            <div class="px-5">
                <ion-list  v-if="layout.app">
                    <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                        <ion-label position="floating" class="friColor1 fs-6">Nombre *</ion-label>
                        <ion-input v-model="layout.app.name" type="text" ></ion-input>
                    </ion-item>
                    <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                        <ion-label position="floating" class="friColor1 fs-6 ">Localización</ion-label>
                        <ion-select interface="popover" v-model="layout.app.location.cityId" class="fs-5 pt-5">
                            <ion-select-option v-for="city in layout.weatherCities" :key="city.id" :value="city.id.toString()">{{ city.name }}</ion-select-option>
                        </ion-select>
                    </ion-item>
                    <!--
                    <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                        <ion-label position="floating" class="friColor1" :class="isMobile ? 'fs-4' : ' fs-5'">Descripción</ion-label>
                        <ion-input v-model="layout.app.description" type="text" ></ion-input>
                    </ion-item>
                    -->
                </ion-list>
            </div>
            <div class="pt-4 text-center text-xs3" v-if="layout.app.createdAt">
                <template v-if="profile.isRoot">
                    <span v-if="layout.app.createdAt" class="pe-3">{{ layout.helper.getDateFormatFb(layout.app.createdAt) }}</span>|
                </template>
                <!--<span v-if="layout.app.updatedAt" class="ps-3"> Actualizado el {{ layout.helper.getDateFormatFb(layout.app.updatedAt) }}</span>-->
            </div>
            <div :style="isMobile ? 'margin-top: 30px; margin-bottom: 80px;' : 'margin: 30px 0;'">
                <div class="d-flex justify-content-center align-items-center fs-5">
                <div class="fw-bold friColor1 text-end float-end cursor-pointer" @click="saveDevice()">GUARDAR</div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="text-center w-100"  style="margin: 150px 0;">
                <div class="fw-bold fs-3">Configuración</div>
                <div class="mt-2">Espere por favor ...</div>
                <div class="spinner-border friColor1 mt-5" role="status">
                <span class="visually-hidden">Cargando ...</span>
                </div>
            </div>
        </template>
    </div>
  </template>
  <script>
  import { defineComponent, onMounted, ref } from "vue"; 
  import { getApp, updateApp } from "../../../model/apps.js"; 
  //import router from "@/router";
  import { useAuthStore } from "@/store";
  //import { getDevice } from "../../../model/alba.js";
  import { IonItem, IonLabel, IonInput, IonList, IonSelect, IonSelectOption, alertController } from '@ionic/vue';
  import { helper as $h } from "../../../utils/helper.js";
  //import QRCode from "qrcode"; 
  import { getCities} from "../../../model/weather.js"; 
  import { not as $not } from "../../../model/not";

  export default defineComponent({
    name: 'DeviceEdit',
    props: {
        isMobile: Boolean,
        appId: String
     },
    components: { IonItem, IonLabel, IonInput, IonList, IonSelect, IonSelectOption }, //
    setup(props) {
  
       const authStore      = useAuthStore();
       const layout = ref({ 
                            deviceId: null,
                            device:   null,
                            helper:   $h
                          }) 

       const { user, profile } = authStore;
    
       const saveDevice = async () => {
        let validate = true
        let required = ['name']
        required.forEach((field) => {
          if (!layout.value.app[field]) validate = false
        })
        if (validate) {
            const alert = await alertController.create({
                  header: '¿Está seguro?',
                  cssClass: 'friCustomAlert',
                  buttons: [ 
                            {text: 'Cancelar', cssClass: 'alert-button-cancel', role: 'cancel' },  
                            {text: 'Confirmar',cssClass: 'alert-button-confirm', handler: async () => { 
                                await updateApp(layout.value.app).then(() => {
                                    $not.toastApp({ message: 'Se ha actualizado la configuración de su Alba Hub correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'primary', cssClass: 'text-white mt-2' })
                                })
                              }
                            }
                          ],
              });
             await alert.present();
        }else $not.toastApp({ message: 'Debe rellenar los parámetros requeridos *', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
       }

       onMounted( async () => { 
         layout.value.appId          = props.appId
         layout.value.app            = await getApp(props.appId)
         layout.value.weatherCities  = await getCities();
       });
  
      return { layout, user, props, profile, saveDevice};
    }
    
  })
  </script>
<style>

</style>