import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "friTopBarAgent"
}
const _hoisted_2 = {
  key: 1,
  id: "container"
}
const _hoisted_3 = { class: "page-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Login = _resolveComponent("Login")!
  const _component_InactiveUser = _resolveComponent("InactiveUser")!
  const _component_Dashboard = _resolveComponent("Dashboard")!
  const _component_UserProfile = _resolveComponent("UserProfile")!
  const _component_CreateUser = _resolveComponent("CreateUser")!
  const _component_PingFine = _resolveComponent("PingFine")!
  const _component_App = _resolveComponent("App")!
  const _component_Device = _resolveComponent("Device")!
  const _component_Devices = _resolveComponent("Devices")!
  const _component_Remote = _resolveComponent("Remote")!
  const _component_Api = _resolveComponent("Api")!
  const _component_Controller = _resolveComponent("Controller")!
  const _component_deviceDash = _resolveComponent("deviceDash")!
  const _component_Hub = _resolveComponent("Hub")!
  const _component_Sensor = _resolveComponent("Sensor")!
  const _component_Apk = _resolveComponent("Apk")!
  const _component_Apps = _resolveComponent("Apps")!
  const _component_Test = _resolveComponent("Test")!
  const _component_Clients = _resolveComponent("Clients")!
  const _component_Client = _resolveComponent("Client")!
  const _component_Alarm = _resolveComponent("Alarm")!
  const _component_User = _resolveComponent("User")!
  const _component_Jobs = _resolveComponent("Jobs")!
  const _component_Alba = _resolveComponent("Alba")!
  const _component_Alert = _resolveComponent("Alert")!
  const _component_Sem = _resolveComponent("Sem")!
  const _component_Site = _resolveComponent("Site")!
  const _component_Plan = _resolveComponent("Plan")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, { style: {"min-height":"100vh","overflow-y":"scroll","scrollbar-width":"none"} }, {
    default: _withCtx(() => [
      (_ctx.profile && _ctx.profile.isAgent)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Agente comercial"))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_content, {
        id: 'friPageContent'+_ctx.tab,
        style: {"overflow-y":"scroll","height":"100%","scrollbar-width":"none","width":"100% !important"}
      }, {
        default: _withCtx(() => [
          (_ctx.tab=='login')
            ? (_openBlock(), _createBlock(_component_Login, {
                key: 0,
                login: true,
                isMobile: _ctx.layout.isMobile
              }, null, 8, ["isMobile"]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (!_ctx.profile.active && _ctx.tab!='profile')
                  ? (_openBlock(), _createBlock(_component_InactiveUser, { key: 0 }))
                  : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["view-wrapper", _ctx.tab=='home' ? _ctx.layout.isMobile ? 'friBackGradiant' : 'friBackGray' : ''])
                      }, [
                        _createElementVNode("div", {
                          class: _normalizeClass(_ctx.tab=='home' ? 'page-content-wrapper-home' : 'page-content-wrapper')
                        }, [
                          _createElementVNode("div", _hoisted_3, [
                            (_ctx.tab=='home')
                              ? (_openBlock(), _createBlock(_component_Dashboard, {
                                  key: 0,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true),
                            (_ctx.tab=='profile')
                              ? (_openBlock(), _createBlock(_component_UserProfile, {
                                  key: 1,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true),
                            (_ctx.tab=='create')
                              ? (_openBlock(), _createBlock(_component_CreateUser, {
                                  key: 2,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true),
                            (_ctx.tab=='pingfine')
                              ? (_openBlock(), _createBlock(_component_PingFine, {
                                  key: 3,
                                  header: true,
                                  headerCounter: true,
                                  createButton: true,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true),
                            (_ctx.tab=='app')
                              ? (_openBlock(), _createBlock(_component_App, {
                                  key: 4,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true),
                            (_ctx.tab=='device')
                              ? (_openBlock(), _createBlock(_component_Device, {
                                  key: 5,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true),
                            (_ctx.tab=='devices')
                              ? (_openBlock(), _createBlock(_component_Devices, {
                                  key: 6,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true),
                            (_ctx.tab=='remote')
                              ? (_openBlock(), _createBlock(_component_Remote, {
                                  key: 7,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true),
                            (_ctx.tab=='api')
                              ? (_openBlock(), _createBlock(_component_Api, {
                                  key: 8,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true),
                            (_ctx.tab=='controller')
                              ? (_openBlock(), _createBlock(_component_Controller, {
                                  key: 9,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true),
                            (_ctx.tab=='deviceDash')
                              ? (_openBlock(), _createBlock(_component_deviceDash, {
                                  key: 10,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true),
                            (_ctx.tab=='hub')
                              ? (_openBlock(), _createBlock(_component_Hub, {
                                  key: 11,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true),
                            (_ctx.tab=='sensor')
                              ? (_openBlock(), _createBlock(_component_Sensor, {
                                  key: 12,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true),
                            (_ctx.tab=='apk')
                              ? (_openBlock(), _createBlock(_component_Apk, {
                                  key: 13,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true),
                            (_ctx.tab=='apps')
                              ? (_openBlock(), _createBlock(_component_Apps, {
                                  key: 14,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true),
                            (_ctx.tab=='test')
                              ? (_openBlock(), _createBlock(_component_Test, {
                                  key: 15,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true),
                            (_ctx.tab=='clients')
                              ? (_openBlock(), _createBlock(_component_Clients, {
                                  key: 16,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true),
                            (_ctx.tab=='client')
                              ? (_openBlock(), _createBlock(_component_Client, {
                                  key: 17,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true),
                            (_ctx.tab=='alarm')
                              ? (_openBlock(), _createBlock(_component_Alarm, {
                                  key: 18,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true),
                            (_ctx.tab=='user')
                              ? (_openBlock(), _createBlock(_component_User, {
                                  key: 19,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true),
                            (_ctx.tab=='jobs')
                              ? (_openBlock(), _createBlock(_component_Jobs, {
                                  key: 20,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true),
                            (_ctx.tab=='alba')
                              ? (_openBlock(), _createBlock(_component_Alba, {
                                  key: 21,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true),
                            (_ctx.tab=='alert')
                              ? (_openBlock(), _createBlock(_component_Alert, {
                                  key: 22,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true),
                            (_ctx.tab=='sem')
                              ? (_openBlock(), _createBlock(_component_Sem, {
                                  key: 23,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true),
                            (_ctx.tab=='site')
                              ? (_openBlock(), _createBlock(_component_Site, {
                                  key: 24,
                                  login: true,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true),
                            (_ctx.tab=='plan')
                              ? (_openBlock(), _createBlock(_component_Plan, {
                                  key: 25,
                                  isMobile: _ctx.layout.isMobile
                                }, null, 8, ["isMobile"]))
                              : _createCommentVNode("", true)
                          ])
                        ], 2)
                      ], 2)
                    ]))
              ], 64))
        ]),
        _: 1
      }, 8, ["id"])
    ]),
    _: 1
  }))
}