<template>
    <div>
        <i class="bi bi-brightness-alt-high fs-1"></i>
        <h2 class="fs-3 friColor1">Sabías que ...</h2>
        <div class="mt-2 mx-auto text-sm" style="width: 350px;">
            <template v-if="layout.randMessage == 1">
                Con <span class="fw-semibold">Alba</span> puedes hacer mucho más de lo que te imaginas...  controlar el estado de tus dispositivos en tiempo real, recibir notificaciones y mucho más. 
                <div><img src="../../../assets/images/theme/about/about-remote.jpg" class="w-100"></div>
            </template>
            <template v-else-if="layout.randMessage == 2">
                <div class="mt-1">El <span class="fw-semibold">asistente de control Copilot</span> te ayudará a gestionar tus dispositivos de forma remota, y a recibir notificaciones en tiempo real de cualquier evento que se produzca en ellos.</div>
                <div class="mt-3"><i class="bi bi-robot friColor1" style="font-size: 100px;"></i></div>
            </template>
            <template v-else-if="layout.randMessage == 3">
                <div class="mt-1">Con <span class="fw-semibold">Alba</span> puedes gestionar tus dispositivos de forma remota, y a recibir notificaciones en tiempo real de cualquier evento que se produzca en ellos.</div>
            </template>
            <template v-else-if="layout.randMessage == 4">
                <div class="mt-1">
                    Reduce el consumo de energía de tus dispositivos con <span class="fw-semibold">Alba</span>. Adapta el consumo a tus necesidades reales y ahorra en tu factura de la luz.
                </div>
                <div><img src="../../../assets/images/theme/about/about-energy.png" class="w-100"></div>
            </template>
       </div>
    </div>
</template>

<script>
import { defineComponent, ref, onMounted} from 'vue';
import { useAuthStore } from '@/store';
import router from "@/router";

export default defineComponent({
name: 'DashboardPage',
props: {
    login: Boolean,
    isMobile: Boolean
},
components: {   },
setup() {

    const authStore         = useAuthStore();
    const { user, profile } = authStore;
    const layout            = ref({
                                    randMessage: 1,
                                 })

    const loadSection = async () => {
        layout.value.randMessage = Math.floor(Math.random() * 4) + 1;
    }

    onMounted(async () => { 
      await loadSection();
    });

    return {user, profile, layout, loadSection, router};
},
methods: { },
});
</script>