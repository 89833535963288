<template>
    <div>
        <div class="account-box is-form is-footerless p-0 m-0" :style="isMobile ? 'border-bottom: none !important;' : ''">
            <template v-if="layout.device">
                <div class="form-head stuck-header" :class="isMobile ? 'py-0' : 'py-3'">
                    <div class="form-head-inner">
                        <div class="left"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5 ms-2' : 'fs-4'">{{  layout.app.name  }} </h4></div>
                        <div class="inline-block align-top fs-6 mt-0" style="margin-top: 4px;">
                            <template v-if="layout.app.logo"><img :src="layout.app.logo" style="height: 50px;" /></template>
                            <template v-else>{{  layout.app.id }}</template>
                        </div>
                    </div>
                </div>
                <div class="pt-2 pb-2 fs-5 friBack1 text-white text-center ps-3"><span class="align-middle" v-if="layout.device && layout.device.typeName">{{ layout.device.typeName }}</span></div>
                <div v-if="layout.device" class="text-center fs-2 mt-4">{{ layout.device.name }}</div>

                <div v-if="layout.device?.isSensor" class="text-center mb-2">{{ layout.device?.brand }} ({{ layout.device?.model }})</div>
                <div class="text-center friColor1" v-if="layout.device.status">{{ layout.helper.getDateFormatFb(layout.device.status.date) }}</div>

                <template v-if="layout.device?.status?.alarm?.status && layout.device?.status?.alarm?.alarms">
                    <template v-if="layout.device.status?.alarm?.status && layout.device?.status?.alarm?.alarms && Object.keys(layout.device.status.alarm.alarms).length">
                        <div class="text-center friColor3 fs-1 pt-3"><i class="bi bi-exclamation-triangle-fill friColor3" style="font-size: 50px;"></i></div>
                        <div v-for="(alarm, index) in layout.device.status.alarm.alarms" :key="index" class="text-center friColor3 fs-6">
                            {{ alarm.title }}
                            <div class="friColor3 text-xs">{{ alarm.date }} <span class="friColor3 text-xs" v-if="alarm.notify"> / Usuarios notificados</span></div>
                        </div>
                    </template>
                </template>


                <div class="me-3 ms-4" v-if="layout.device && layout.device.status" :style="isMobile ? 'padding-bottom: 200px' : 'padding-bottom: 40px;'">
                     <!-- XW265K -->
                    <div v-if="layout.device.status?.msg.stats && Object.keys(layout.device.status.msg.stats).length" style="border-bottom: 1px solid #ddd;">
                        <table class="table table-sm table-borderless mx-auto px-0 py-0 my-0" style="max-width: 400px;">
                            <tbody>
                                <tr>
                                    <td class="text-center py-1 px-0">
                                        <i class="bi bi-power friColor1" style="font-size: 32px;" v-if="layout.device.status.msg.stats.onOff"></i>
                                        <i class="bi bi-power friColor3" style="font-size: 32px;" v-else></i>
                                    </td>
                                    <td class="text-center py-1 px-0">
                                        <i class="bi bi-c-circle friColor1" style="font-size: 25px;"></i>
                                    </td>
                                    <td class="text-center py-1 px-0"><i class="bi bi-snow2" style="font-size: 25px;" :class="layout.device.status.msg.stats.defrost ? 'friColor1': 'friColor6'"></i></td>
                                    <td class="text-center py-1 px-0">
                                        <i class="bi bi-door-open friColor3" style="font-size: 25px;" v-if="layout.device.status.msg?.alarms?.doorOpen"></i>
                                        <i class="bi bi-door-closed friColor1" style="font-size: 25px;" v-else></i>
                                    </td>
                                    <td class="text-center py-1 px-0">
                                        <i class="bi bi-exclamation-triangle-fill friColor3" style="font-size: 50px;" v-if="layout.device.status.msg?.relays?.alarm"></i>
                                        <i class="bi bi-check-circle friColor1" style="font-size: 50px;" v-else></i>
                                    </td>
                                    <td class="text-center py-1 px-0">
                                        <i class="bi bi-fan friColor1" style="font-size: 25px;" v-if="layout.device.status.msg?.relays?.fans"></i>
                                        <i class="bi bi-fan friColor6" style="font-size: 25px;" v-else></i>
                                    </td>
                                    <td class="text-center py-1 px-0">
                                        <i class="bi bi-stars friColor1" style="font-size: 25px;" v-if="layout.device.status.msg?.stats?.energySaving"></i>
                                        <i class="bi bi-stars friColor6" style="font-size: 25px;" v-else></i>
                                    </td>
                                    <td class="text-center py-1 px-0">
                                        <i class="bi bi-file-spreadsheet-fill friColor3" style="font-size: 25px;" v-if="layout.device.status.msg.stats.keyboardLock"></i>
                                        <i class="bi bi-file-spreadsheet-fill friColor1" style="font-size: 25px;" v-else></i>
                                    </td>
                                    <td class="text-center py-1 px-0">
                                        <i class="bi bi-lightbulb-fill friColor2" style="font-size: 26px;" v-if="layout.device.status.msg?.relays?.light"></i>
                                        <i class="bi bi-lightbulb-off-fill friColor6" style="font-size: 25px;" v-else></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="py-3 friBack4 mb-4 text-center friColor1 br-8 mt-3" v-if="layout.device.status.msg.stats && layout.device.status.msg.stats.defrost"><img src="../../../assets/images/theme/loading.gif" style="width: 40px; padding-right: 5px;"> Desescarche en curso ...</div>
                    
                    <div v-if="layout.device.status?.msg?.params?.hasOwnProperty('SEt')" :class="isMobile ? '' : 'mx-5'">
                        <div class="text-dark text-center friBack9 br-5 mt-3 text-xs3 py-3 fs-6">Punto de ajuste (SET): {{ layout.device.status.msg.params.SEt }} ºC</div>
                    </div>
                    
                    <div v-if="layout.device.status.msg.hasOwnProperty('sondas')" class="d-flex flex-row justify-content-center mt-3 gap-3">
                        <div v-if="layout.device.status.msg.sondas.hasOwnProperty('pb1')" class="text-dark text-center friBack9 br-5 w-25" style="padding: 20px">
                            <div class="text-xs3 pb-1">Sonda 1</div><div class="fs-3 pb-1">{{ layout.device.status.msg.sondas.pb1.temp }} ºC</div>
                            <div class="text-center" v-if="layout.device.status.msg.sondas.pb1.hasOwnProperty('status')">
                                <div v-if="layout.device.status.msg.sondas.pb1.status?.error" class="friColor3"><i class="bi bi-exclamation-triangle friColor3"></i> Error</div>
                                <div v-if="layout.device.status.msg.sondas.pb1.status?.ha" class="friColor3"><i class="bi bi-exclamation-triangle friColor3"></i> HA</div>
                                <div v-if="layout.device.status.msg.sondas.pb1.status?.la" class="friColor3"><i class="bi bi-exclamation-triangle friColor3"></i> LA</div>
                            </div>
                        </div>
                        <div v-if="layout.device.status.msg.sondas.hasOwnProperty('pb2')" class="text-dark text-center friBack9 br-5 w-25" style="padding: 20px">
                            <div class="text-xs3 pb-1">Sonda 2</div><div class="fs-3 pb-1">{{ layout.device.status.msg.sondas.pb2.temp }} ºC</div>
                            <div class="text-center" v-if="layout.device.status.msg.sondas.pb2.hasOwnProperty('status')">
                                <div v-if="layout.device.status.msg.sondas.pb2.status?.error" class="friColor3"><i class="bi bi-exclamation-triangle friColor3"></i> Error</div>
                                <div v-if="layout.device.status.msg.sondas.pb2.status?.ha" class="friColor3"><i class="bi bi-exclamation-triangle friColor3"></i> HA</div>
                                <div v-if="layout.device.status.msg.sondas.pb2.status?.la" class="friColor3"><i class="bi bi-exclamation-triangle friColor3"></i> LA</div>
                            </div>
                        </div>
                        <div v-if="layout.device.status.msg.sondas.hasOwnProperty('pb3')" class="text-dark text-center friBack9 br-5 w-25" style="padding: 20px">
                            <div class="text-xs3 pb-1">Sonda 3</div><div class="fs-3 pb-1">{{ layout.device.status.msg.sondas.pb3.temp }} ºC</div>
                        </div>
                    </div>
                    <template v-else>
                        
                        <div v-if="layout.device.status && layout.device.status.msg" class="flex-row justify-content-center mt-5 gap-3">

                            <div v-if="layout.device.status.msg.hasOwnProperty('TempC_SHT')" class="text-dark text-left friBack9 br-5 me-0 mb-2" style="padding: 20px">
                                <div class="fs-5">Temperatura <span class="fs-5 text-end float-end fw-bold">{{ layout.device.status.msg.TempC_SHT }} ºC</span></div>
                            </div>  

                            <div v-if="layout.device.status.msg.hasOwnProperty('Hum_SHT')" class="text-dark text-left friBack9 br-5 me-0 mb-2" style="padding: 20px">
                                <div class="fs-5">Humedad <span class="fs-5 text-end float-end fw-bold">{{ layout.device.status.msg.Hum_SHT }} %</span></div>
                            </div> 

                            <div v-if="layout.device.status.msg.hasOwnProperty('DOOR_OPEN_STATUS')" class="text-dark text-left friBack9 br-5 me-0 mb-2" style="padding: 20px">
                                <div class="fs-5">Puerta <span class="fs-5 text-end float-end fw-bold">{{ layout.device.status.msg.DOOR_OPEN_STATUS ? 'Abierta' : 'Cerrada' }}</span></div>
                            </div> 

                            <template v-if="profile && profile.isAgent && layout.device.hasOwnProperty('externalInput') && layout.device.externalInput!='none'">
                                <div v-if="layout.device.status.msg.hasOwnProperty('TempC_DS')" class="text-dark text-left friBack9 br-5 me-0 mb-2" style="padding: 20px">
                                    <div class="fs-5">Sonda externa <span class="fs-5 text-end float-end fw-bold">{{ layout.device.status.msg.TempC_DS }} ºC</span></div>
                                </div>
                            </template>
                            
                            <!-- CO2 AQS01-L -->
                            <template v-if="layout.device.status.msg.hasOwnProperty('co2')">
                                <div class="text-dark text-left friBack9 br-5 me-0 mb-2" style="padding: 20px"><div class="fs-5">CO2 <span class="fs-5 text-end float-end fw-bold">{{ layout.device.status.msg.co2 }}</span></div></div>
                                <div class="text-dark text-left friBack9 br-5 me-0 mb-2" style="padding: 20px"><div class="fs-5">Presión: <span class="fs-5 text-end float-end fw-bold">{{ layout.device.status.msg.air_pressure }}</span></div></div>
                                <div class="text-dark text-left friBack9 br-5 me-0 mb-2" style="padding: 20px"><div class="fs-5">Temperatura <span class="fs-5 text-end float-end fw-bold">{{ layout.device.status.msg.temperature }} ºC</span></div></div>
                                <div class="text-dark text-left friBack9 br-5 me-0 mb-2" style="padding: 20px"><div class="fs-5">Humedad <span class="fs-5 text-end float-end fw-bold">{{ layout.device.status.msg.humidity }} %</span></div></div>
                            </template>
                            
                            <!-- BATERÍA -->
                            <div v-if="layout.device.status.msg.hasOwnProperty('BAT_V')" class="text-dark text-left friBack9 br-5 me-0 mb-3" style="padding: 20px">
                                <div class="fs-5">Batería <span class="fs-5 text-end float-end fw-bold">{{ layout.device.status.msg.BAT_V }} v</span></div>
                            </div>
                            <div v-if="layout.device.status.msg.hasOwnProperty('BatV')" class="text-dark text-left friBack9 br-5 me-0 mb-3" style="padding: 20px">
                                <div class="fs-5">Batería <span class="fs-5 text-end float-end fw-bold">{{ layout.device.status.msg.BatV }} v</span></div>
                            </div>
                            <div v-if="layout.device.status.msg.hasOwnProperty('BATV')" class="text-dark text-left friBack9 br-5 me-0 mb-3" style="padding: 20px">
                                <div class="fs-5">Batería <span class="fs-5 text-end float-end fw-bold">{{ layout.device.status.msg.BATV }} v</span></div>
                            </div>
                            <div v-if="layout.device.status.msg.hasOwnProperty('Bat_mV')" class="text-dark text-left friBack9 br-5 me-0 mb-3" style="padding: 20px">
                                <div class="fs-5">Batería <span class="fs-5 text-end float-end fw-bold">{{ layout.device.status.msg.Bat_mV }} v</span></div>
                            </div>
                        </div>

                    </template>

                   <!--
                    <pre class="m-5" v-if="layout.device.status.msg.stats">{{ layout.device.status.msg.stats }}</pre>
                    <pre class="m-5" v-if="layout.device.status.msg.relays">{{ layout.device.status.msg.relays }}</pre>
                    <pre class="m-5" v-if="layout.device.status.msg.alarms">{{ layout.device.status.msg.alarms }}</pre>

                    <div class="fs-6 mt-5">
                        <div class="fw-bold friColor1 cursor-pointer" @click="setDownlink(256)">Downlink 256 ( Sonda 1 )</div>
                        <div class="fw-bold friColor1 cursor-pointer" @click="setDownlink(768)">Downlink 768 ( HY )</div>
                    </div>
                    -->
                </div>

            </template>
            <template v-else>
                <div class="text-center w-100"  style="margin: 150px 0;">
                    <div class="fw-bold fs-1">Equipos y sensores</div>
                    <div class="mt-2">Espere por favor ...</div>
                    <div class="spinner-border friColor1 mt-5" role="status">
                    <span class="visually-hidden">Cargando ...</span>
                    </div>
                </div>
            </template>
        </div>
    </div>
  </template>
  <script>
  import { defineComponent, onMounted, ref, watch, onUnmounted } from "vue";
  import { useAuthStore } from "@/store";
  import { getApp, getAppDevices } from "../../../model/apps.js"; 
  import { helper as $h } from "../../../utils/helper.js";
  import { getDeviceData} from "../../../model/ttn.js";
  import { _firebase as $_firebase } from "../../../model/firebase";
  import { onSnapshot, collection } from "firebase/firestore"; 
  import { useRoute } from 'vue-router'
  import router from "@/router";

  const db   =  $_firebase.firestore()

  export default defineComponent({
    name: 'SemDashboard',
    props: {
        isMobile: Boolean,
        appId: String,
        deviceId: String
     },
     components: {  },
    setup(props) {
  
       const authStore      = useAuthStore();
       const layout = ref({ 
                            appId:      null,
                            device:     null,
                            deviceId:   null,
                            helper:     $h
                          }) 

        const { user, profile } = authStore;
        const route = useRoute()      
               
        const initDash = async () => {
         layout.value.app  = await getApp(layout.value.appId)
         let deviceData    = await getAppDevices(layout.value.appId)
         if(deviceData && deviceData?.devices && Object.keys(deviceData.devices).length && layout.value.deviceId)
            layout.value.device         = deviceData.devices[layout.value.deviceId] 
            if(layout.value.device && layout.value.device.deviceId)
                layout.value.device.ttnData = await getDeviceData(layout.value.appId, layout.value.device.deviceId)
        }

        onMounted( async () => { 
         layout.value.appId     = props.appId
         layout.value.deviceId  = router.currentRoute.value.params.deviceId
         await initDash();
         await detectDevicesChanges();
        });

        watch(() => route.query, async (query) => {
            if (query){
                layout.value.deviceId  = router.currentRoute.value.params.deviceId
                await initDash();
            }
        });

        const detectDevicesChanges = async () => {
          const collectionPath = "apps/" + props.appId + "/devices/";
          const collRef        = collection(db, collectionPath);
          const unsubscribeDetectDevicesChanges = onSnapshot(collRef, async (querySnapshot) => {
              querySnapshot.docChanges().forEach(async change => { 
                  if (change.type === "modified"){
                     let changeData = change.doc.data()
                     if (changeData && changeData.deviceId == layout.value.deviceId)
                        await initDash();
                  }
              }); 
          }, (error) => { console.log("Error:", error); });
          onUnmounted(() => { unsubscribeDetectDevicesChanges(); });
        };

        return { layout, user, props, profile }; 
    }
    
  })
  </script>