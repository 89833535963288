
  import { defineComponent, onMounted, ref, watch } from "vue"; 
  import { useAuthStore } from "@/store";
  import { useRoute } from 'vue-router'
  import router from "@/router";
  import { getApp } from "../../model/apps.js"; 

  import Settings from '@/components/pages/device/settings.vue';
  import DeviceLog from '@/components/pages/device/log.vue';
  import DeviceDash from '@/components/pages/device/dashboard.vue';
  import DeviceUsers from '@/components/pages/device/users.vue';
  import DevicesDevices from '@/components/pages/device/devices.vue';

  import appInactive from '@/components/pages/utils/appInactive.vue';

  export default defineComponent({
    name: 'DevicePage',
    props: {
        isMobile: Boolean,
     },
    components: { DeviceDash, Settings, DeviceLog, DeviceUsers, DevicesDevices, appInactive },
    setup() {
  
       const authStore      = useAuthStore();
       const route          = useRoute()
       const layout         = ref({ 
                                appId: route.params.appId,
                                query:    null,
                                section:  null,
                                app:      null,
                                sections: [
                                    { name: 'dashboard', title: 'Alba Hub', icon: 'bi bi-router'},
                                    //{ name: 'devices',   title: 'Dispositivos', icon: 'bi bi-wifi', client: true },
                                    //{ name: 'log',       title: 'Registros', icon: 'bi bi-activity'},
                                    { name: 'users',     title: 'Usuarios', icon: 'bi bi-people', client: true },
                                    { name: 'settings',  title: 'Configuración', icon: 'bi bi-gear', client: true },
                                ],
                            }) 

       const { user, profile } = authStore;
        
       const goTTN = () => { window.open('https://eu1.cloud.thethings.network/console/applications', '_blank') }

       const reLoad = () => { window.location.reload(); }

       const goBack = () => { 
        router.push({ path: '/home/' })
       }

       const initDevice = async () => {
        if(!layout.value.section)
          if(route.query.section) layout.value.section = route.query.section
          else layout.value.section = 'dashboard'
        if (profile && (!profile.isClient && !profile.isAgent)){
            layout.value.sections = layout.value.sections.filter((tab) => !tab.client);
            if (layout.value.section == 'users' || layout.value.section == 'settings') layout.value.section = 'dashboard';
        }
        layout.value.app = await getApp(layout.value.appId)
       } 

       onMounted( async () => {
        await initDevice()
       });
  
       watch(() => route.query, async (query) => {
            if (query){
                layout.value.query   = query; 
                layout.value.section = query.section;
            }
       });

       return { layout, user, profile, reLoad, goBack, goTTN};
    }
    
  })
