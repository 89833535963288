import { _firebase } from "./firebase.js";
import { doc, getDoc, collection, query, orderBy, where, getDocs } from "firebase/firestore"; 
import axios from 'axios';
//import dayjs from 'dayjs';
const db  =  _firebase.firestore() 

const controllerList = async (opt = {}) => {
    let order = { field: "name", type: "asc" }
    if (opt.order)
        switch (opt.order) {
            case "nameAsc": order = { field: "name", type: "asc" }; break;
            case "nameDes": order = { field: "name", type: "desc" }; break;
        }

    var resp = { controllers: {}, count: 0, active: 0, inactive: 0, stats: { amount: 0 } }

    // Cambiar 'doc' por 'collection'
    let deviceRef = collection(db, "alba"); 

    if (opt.visibility && opt.visibility != "all") deviceRef = query(deviceRef, where("active", "==", Boolean(opt.visibility == "active" ? true : false)))
    deviceRef = query(deviceRef, orderBy(order.field, order.type))
    deviceRef = query(deviceRef, where("deleted", "==", false))

    const docsSnap = await getDocs(deviceRef);
    await Promise.all(docsSnap.docs.map(async (doc) => {
        let controller = doc.data();
        resp.controllers[doc.id]         = controller;
        resp.controllers[doc.id].id      = doc.id
        //resp.controllers[doc.id].copilot = true
        resp.count++
        if (controller.active) {
            resp.active++
            //if (controller.subscription.price) resp.stats.amount += parseFloat(controller.subscription.price)
        } else resp.inactive++
    }));
    return resp;
}

const devicesList = async (opt = {}) => {
    let order = { field: "name", type: "asc" }
    if (opt.order)
        switch (opt.order) {
            case "nameAsc": order = { field: "name", type: "asc" }; break;
            case "nameDes": order = { field: "name", type: "desc" }; break;
        }

    var resp = { devices: {}, count: 0, active: 0, inactive: 0, stats: { amount: 0 } }

    // Cambiar 'doc' por 'collection'
    let deviceRef = collection(db, "connect"); 

    if (opt.visibility && opt.visibility != "all") deviceRef = query(deviceRef, where("active", "==", Boolean(opt.visibility == "active" ? true : false)))
    deviceRef = query(deviceRef, orderBy(order.field, order.type))
    deviceRef = query(deviceRef, where("deleted", "==", false))

    const docsSnap = await getDocs(deviceRef);
    await Promise.all(docsSnap.docs.map(async (doc) => {
        let device = doc.data();
        resp.devices[doc.id]         = device;
        resp.devices[doc.id].id      = doc.id
        resp.devices[doc.id].copilot = true
        resp.count++
        if (device.active) {
            resp.devices[doc.id].data = await requestDevice(device, "device.py");
            resp.devices[doc.id].status = resp.devices[doc.id].data ? true : false;
            resp.active++
            if (device.subscription.price) resp.stats.amount += parseFloat(device.subscription.price)
        } else resp.inactive++
    }));
    return resp;
}

/*
const createDevice = async (data) => {
    let timestamp = _firebase.firebase().firestore.Timestamp;
    data.createdAt = timestamp.now();
    data.updatedAt = timestamp.now();
    data.active    = Boolean(false);
    data.deleted   = Boolean(false);
    
    data.title        = data.name ? data.name : "";
    data.description  = "";
    data.image        = "";

    //model
    data.brand        = "";
    data.model        = "";
    data.serialNumber = "";

     //controller
     data.controller = {
        brand: "",
        model: "",                     
       }

    //network
    if(!data.ip)data.ip  = "friconnect.ddns.net";

    //log
    data.log          = {
                         active: Boolean(false),
                         interval: 900,
                         send_mail: Boolean(false),
                         send_to: "",
                         smtp_from: "",
                         smtp_provider: "",
                         smtp_secretkey: "",                     
                        }

    //room
    data.room         = {
                         area:   { decimals: "", meters: "" },
                         volume: { decimals: "", meters: "" },                     
                        }

    //sondas
    data.sondas       = {
                         "1": { name: "Sonda 1", type: "ntc", active: Boolean(true), input: "device"  },
                         "2": { name: "Sonda 2", type: "ntc", active: Boolean(false), input: "device" },
                         "3": { name: "Sonda 3", type: "ntc", active: Boolean(false), input: "device" },
                         "4": { name: "Condensador", type: "ntc", active: Boolean(false), input: "external" },
                         "5": { name: "Exterior", type: "ntc", active: Boolean(false), input: "external" },
                         "6": { name: "Interior", type: "ntc", active: Boolean(false), input: "external" },
                        }

    //subscription
    if(!data.subscription)data.subscription = { user: "" }
    data.subscription.type          = "free"
    data.subscription.status        = "active"
    data.subscription.startAt       = timestamp.now();
    let end = new Date(); end.setDate(end.getDate() + 30); // 30 days
    data.subscription.endAt         = timestamp.fromDate(end);
    data.subscription.bank          = ""
    data.subscription.bankAccount   = ""
    data.subscription.price         = "0"
    //

    await collection("connect").add(data);
    return true;
};
*/

const getDevice = async (id) => {
    let resp = {};
    let docRef  = doc(db, "connect", id);
    let docSnap = await getDoc(docRef);
    if(docSnap.exists) {
        let device  = docSnap.data();
        if (device && !device.deleted) {
            device.id   = docSnap.id;
            resp        = device;
            try {
                resp.data        = await requestDevice(resp, "device.py");
                resp.status      = resp.data ? true : false;
                resp.copilot     = true;
                if(resp.data?.micro_status!=4112)resp.started = true;
                if(resp.data?.alarms)resp.alarmActive = await parseAlarms(resp.data.alarms);
                else resp.started = false;
            } catch (error) {
                console.error('Error with requestDevice: ', error);
                resp.data = null;
                resp.status = false;
                resp.copilot = false;
                resp.started = false;
                resp.alarmActive = false;
            }
        }
        /*
        if(resp.room){
            if(resp.room.density && resp.room.area && resp.room.volume){
                resp.roomSpecs   = { density: resp.room.density, area: `${resp.room.area.meters}.${resp.room.area.decimals.toString().padStart(2, '0')}`, volume: `${resp.room.volume.meters}.${resp.room.volume.decimals.toString().padStart(2, '0')}` };
                resp.roomRequire = await capacidadEnfriamiento(resp.roomSpecs);
            }
        }

        if(resp.subscription){
            if(resp.subscription.startAt){
                let startDate = new Date(resp.subscription.startAt.seconds * 1000);
                let dateString = startDate.toLocaleDateString('es-ES'); 
                let timeString = startDate.toLocaleTimeString('es-ES');  
                resp.subscription.startAtDate = dateString.split('/').map(part => part.padStart(2, '0')).reverse().join('-');
                resp.subscription.startAtTime = timeString;
            }
            if(resp.subscription.endAt){
                let endDate = new Date(resp.subscription.endAt.seconds * 1000);
                let dateString = endDate.toLocaleDateString('es-ES');  
                let timeString = endDate.toLocaleTimeString('es-ES');  
                resp.subscription.endAtDate = dateString.split('/').map(part => part.padStart(2, '0')).reverse().join('-');
                resp.subscription.endAtTime = timeString;
            }
        }
        */
    }
    return resp;
}


const requestDevice = async (device, call) => {
    try {
        let response = await axios.get('https://' + device.ip + '/runscript/' + call, {timeout: 15000}); //+ ':' + device.port 
        //console.log('Response device data: ', response.data);
        return response.data;
    } catch (error) {
        console.error('Error: ', error);
        return false;
    }
} 

const requestApi = async (host, call) => {
    try {
        let response = await axios.get(host + call, {timeout: 15000}); //+ ':' + device.port 
        //console.log('Response device data: ', response.data);
        return response.data;
    } catch (error) {
        console.error('Error: ', error);
        return false;
    }
} 

const parseAlarms = async (regValue) => {
    // Los nombres de las alarmas basados en la documentación
    const alarmNames = [
        "Alarma de carga 1",
        "Alarma de carga 2",
        "Alarma de carga 3",
        "Alarma de carga 4",
        "Alarma de carga 5",
        "Alarma de carga 6",
        "Alarma de carga 7",
        "Alarma de carga 8",
        "Alarma de carga 9",
        "Alarma de carga 10",
        "Alarma de carga 11",
        "Puerta abierta o alarma de nivel de líquido",
        "Alarma genérica de entrada digital",
        "Alarma de reloj"
    ];
    let alarms = [];
    for (let i = 0; i < alarmNames.length; i++) {
        // Verificar si el bit correspondiente en regValue está encendido
        if ((regValue & (1 << i)) !== 0) {
            // Si el bit está encendido, agregar el nombre de la alarma a la lista de alarmas activas
            alarms.push(alarmNames[i]);
        }
    }
    return alarms;
} 
/*

const updateConnect = async (id, data) => { 
    if(data.data)delete data.data
    if(data.started)delete data.started
    if(data.status)delete data.status
    if(data.roomRequire)delete data.roomRequire
    if(data.roomSpecs)delete data.roomSpecs
    if(data.alarmActive)delete data.alarmActive
    if(data.id)delete data.id
    await updateConnectCollection(id, data)
    await updateConfigInDevice(id);
};

const updateConnectCollection = async (id, data) => { 
    data.updatedAt = _firebase.firebase().firestore.FieldValue.serverTimestamp() 
    await collection("connect").doc(id).set(data, { merge: true });
};

const updateConfigInDevice = async (deviceId) => { 
   let device = await getDevice(deviceId);
   if(!device.log)return;
   await requestDevice(device, 'config.py/cron_active/'          + (device.log.active.toString().charAt(0).toUpperCase() + device.log.active.toString().slice(1)));
   await requestDevice(device, 'config.py/cron_interval/'        +  device.log.interval);
   await requestDevice(device, 'config.py/cron_send_email/'      + (device.log.send_mail.toString().charAt(0).toUpperCase() + device.log.send_mail.toString().slice(1)));   
   await requestDevice(device, 'config.py/cron_send_email_to/'   +  device.log.send_to);
   await requestDevice(device, 'config.py/smtp_provider/'        +  device.log.smtp_provider);
   await requestDevice(device, 'config.py/smtp_from/'            +  device.log.smtp_from);
   await requestDevice(device, 'config.py/smtp_secretkey/'       +  device.log.smtp_secretkey);
};

const capacidadEnfriamiento = async (roomSpecs) => {
    const area    = parseFloat(roomSpecs.area);
    const volume  = parseFloat(roomSpecs.volume);
    const density = parseFloat(roomSpecs.density);
    const capacidadKw = area * density;
    const capacidadEnfriamiento = Math.round(capacidadKw * 100) / 100;
    const consumoElectrico      = calcularConsumoElectrico(capacidadKw);
    return {
      area,
      volume,
      density,
      capacidadKw,
      capacidadEnfriamiento,
      consumoElectrico,
    };
  };

  const calcularConsumoElectrico = (capacidadKw) => {
    const consumoElectrico = capacidadKw * 0.8;
    return consumoElectrico;
  };

  const uploadDeviceImage = async (id, event) => {
    const file          = event.target.files[0];
    const reader        = new FileReader();
    reader.onload       = async (e) => {
        const imageBase64   = e.target.result;
        await collection('connect').doc(id).set({ image: imageBase64, updatedAt: _firebase.firebase().firestore.FieldValue.serverTimestamp() }, { merge: true });
        console.log('Imagen guardada correctamente');
    };
    reader.readAsDataURL(file);
 } 

 const parseLog = async (history) => {
    var parseString = require('xml2js').parseString;
    await new Promise((resolve) => {
        parseString(history, function (err, result) {
            if(!result) return resolve( history = false);
            const dates = result.data.date.map((dateObj) => {
            const date = dateObj.$.value;
            const times = dateObj.time.map((timeObj) => {
                const time = timeObj.$.value;
                const sondas = timeObj.sondas[0];
                return {
                    time,
                    sonda_1: sondas.sonda_1[0],
                    sonda_2: sondas.sonda_2[0],
                    sonda_3: sondas.sonda_3[0],
                    sonda_4: sondas.sonda_4 ? sondas.sonda_4[0] : "n/d",
                    sonda_5: sondas.sonda_5 ? sondas.sonda_5[0] : "n/d",
                    sonda_6: sondas.sonda_6 ? sondas.sonda_6[0] : "n/d",
                    send_email:    timeObj.send_email    ? Boolean(timeObj.send_email) : false,
                    //send_whatsapp: timeObj.send_whatsapp ? Boolean(timeObj.send_whatsapp) : false,
                };
            });
            return {
                date,
                times,
            };
            });
            history  = { dates, xml: history };
            resolve();
        });
    });
    //if(history.dates)history.dates.reverse();
    return history;
}

const filterLog = async (history, filters) => {
    
    var xml2js = require('xml2js');
    var builder = new xml2js.Builder();

    let dates       = history.dates;
    let datesFilter = {};
    const today     = dayjs().format('YYYY-MM-DD');
    const filter    = filters.filter;
    if (filter === "0") {
        // Today
        datesFilter = dates.filter((date) => date.date === today);
    } else if (filter === "1") {
        // Ayer
        const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
        datesFilter = dates.filter((date) => date.date === yesterday);
    } else if (filter === "7") {
        // Últimos 7 días
        const lastWeek = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
        datesFilter = dates.filter((date) => date.date >= lastWeek && date.date <= today);
    } else if (filter === "30") {
        // Últimos 30 días
        const lastMonth = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
        datesFilter = dates.filter((date) => date.date >= lastMonth && date.date <= today);
    } else if (filter === "custom") {
        const fromDate = dayjs(filters.custom.fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
        const toDate = dayjs(filters.custom.toDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
        datesFilter = dates.filter((date) => date.date >= fromDate && date.date <= toDate);
        console.log(filters.custom.fromDate, filters.custom.toDate, fromDate, toDate, datesFilter)
    }
    
    let newDatesObj     = { data: { date: datesFilter } };
    history.filteredXML = builder.buildObject(newDatesObj);

    let countTmes = 0;
    history.dates = datesFilter;
    history.count = { dates: 0, times: 0, days: 0};
    try {
        //history       = await checkAlldays(history);
        //if(history){
            history.average = {
                sonda_1: 0,
                sonda_2: 0,
                sonda_3: 0,
                //sonda_4: 0,
                //sonda_5: 0,
                //sonda_6: 0,
            };
            if(history.dates.length){
                const sondas = [1, 2, 3];
                history.dates.forEach((date) => { 
                    countTmes += date.times.length; 
                    date.times.forEach((time) => {
                        sondas.forEach((sonda) => {
                            let sondaStr = `sonda_${sonda}`;
                            history.average[sondaStr] = {
                                times: history.average[sondaStr].times ? history.average[sondaStr].times + 1 : 1,
                                value: history.average[sondaStr].value ? history.average[sondaStr].value + parseFloat(time[sondaStr]): parseFloat(time[sondaStr]),
                                min:   history.average[sondaStr].min   ? (history.average[sondaStr].min > parseFloat(time[sondaStr]) ? parseFloat(time[sondaStr]) : history.average[sondaStr].min) : parseFloat(time[sondaStr]),
                                max:   history.average[sondaStr].max   ? (history.average[sondaStr].max < parseFloat(time[sondaStr]) ? parseFloat(time[sondaStr]) : history.average[sondaStr].max) : parseFloat(time[sondaStr]),
                            }
                        });
                    });
                });
                history.count = { dates: history.dates.length , times: countTmes };
                history.days  = dayjs(history.dates[history.dates.length - 1].date).diff(dayjs(history.dates[0].date), 'day');
                if(!history.days) history.days = 1;
            }
        //}
    } catch (error) { console.log("error",error) }


    return history;
}

const checkAlldays = async (history) => {
    if (history.dates.length === 0) return;
    // Obtener todas las fechas existentes en el historial
    const dates = history.dates.map((date) => date.date);
  
    // Crear un array con todas las fechas desde la más antigua hasta la más reciente
    const startDate = dayjs(dates[0]).startOf('day');
    const endDate = dayjs(dates[dates.length - 1]).startOf('day');
    const allDates = [];
    let currentDate = startDate;
    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
      allDates.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.add(1, 'day');
    }
  
    // Verificar si falta alguna fecha y añadirla con datos vacíos si es necesario
    const missingDates = allDates.filter((date) => !dates.includes(date));
    missingDates.forEach((missingDate) => {
      history.dates.push({
        date: missingDate,
        times: [],
      });
    });
  
    // Ordenar las fechas por orden ascendente
    history.dates.sort((a, b) => {
      return dayjs(a.date).diff(dayjs(b.date));
    });

    return history;
  };*/

/*
export { createDevice, getDevice, devicesList, requestDevice, parseAlarms, updateConnect, uploadDeviceImage, updateConfigInDevice, updateConnectCollection, parseLog, filterLog };
*/
export { devicesList, getDevice, requestDevice, parseAlarms, requestApi, controllerList };