import { Geolocation } from '@capacitor/geolocation';

const Geo = {
    async getCurrentPosition() {
        const pos = await Geolocation.getCurrentPosition();
        return {
            lat: pos.coords.latitude,
            long: pos.coords.longitude,
            date: pos.timestamp
        };
    },
};

const install = app => {
    app.config.globalProperties.$geo = Geo;
};

export { install as default, Geo as geo };
