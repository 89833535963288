import { _firebase } from "./firebase.js";
import { doc, getDoc, collection, query, orderBy, where, getDocs, setDoc, serverTimestamp } from "firebase/firestore";  
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth"; //deleteUser

import axios from 'axios';
const db   =  _firebase.firestore() 
const auth = _firebase.auth() 

const requestDevice = async (host, call) => {
    call += "/" + auth.currentUser.uid;
    if(document.getElementById("topServer"))document.getElementById("topServer").classList.remove("friColor1");
    try {
        let urlCall  = host + "/api/" + call
        let response = await axios.get(urlCall, {timeout: 60000}); //+ ':' + device.port 120000
        if(response?.data?.request)response.data.request["api"] = urlCall
        if(document.getElementById("topServer"))document.getElementById("topServer").classList.add("friColor1");
        return response.data;
    } catch (error) {
        console.error('Error: ', error);
        return false;
    }
} 

const controllerList = async (opt = {}) => {
    let order = { field: "name", type: "asc" }
    if (opt.order)
        switch (opt.order) {
            case "nameAsc": order = { field: "name", type: "asc" }; break;
            case "nameDes": order = { field: "name", type: "desc" }; break;
        }
    var resp = { controllers: {}, count: 0, active: 0, inactive: 0 }
    let deviceRef = collection(db, "alba"); 
    //if (opt.visibility && opt.visibility != "all") deviceRef = query(deviceRef, where("active", "==", Boolean(opt.visibility == "active" ? true : false)))
    deviceRef = query(deviceRef, orderBy(order.field, order.type))
    deviceRef = query(deviceRef, where("deleted", "==", false))
    deviceRef = query(deviceRef, where("active", "==", true))
    const docsSnap = await getDocs(deviceRef);
    await Promise.all(docsSnap.docs.map(async (doc) => {
        let controller = doc.data();
        resp.controllers[doc.id]         = controller;
        resp.controllers[doc.id].id      = doc.id
        resp.controllers[doc.id].devices = await devicesList(doc.id);
        resp.controllers[doc.id].ping    = false //resp.controllers[doc.id]?.net?.ip ? await controllerPing(resp.controllers[doc.id].net.ip) : false
        resp.count++
        if (controller.active) resp.active++
        else resp.inactive++
    }));

    return resp;
}

const gatewayList = async (opt = {}) => {
    let order = { field: "name", type: "asc" }
    if (opt.order)
        switch (opt.order) {
            case "nameAsc": order = { field: "name", type: "asc" }; break;
            case "nameDes": order = { field: "name", type: "desc" }; break;
        }
    var resp = { gateways: {}, count: 0, active: 0, inactive: 0 }
    let gatewayRef = collection(db, "gateway"); 
    gatewayRef = query(gatewayRef, orderBy(order.field, order.type))
    gatewayRef = query(gatewayRef, where("deleted", "==", false))
    //gatewayRef = query(gatewayRef, where("active", "==", true))
    const docsSnap = await getDocs(gatewayRef);
    await Promise.all(docsSnap.docs.map(async (doc) => {
        let gateway = doc.data();
        resp.gateways[doc.id]         = gateway;
        resp.gateways[doc.id].id      = doc.id
        resp.count++
        if (gateway.active) resp.active++
        else resp.inactive++
    }));

    return resp;
}

const controllerListByUser = async (opt = {}) => {
    if(opt.profile && opt.profile.isRoot) return controllerList(opt)
    var resp = { status: "success", controllers: {}, count: 0, active: 0, inactive: 0 }
    if(opt.uid){ 
        const profileRef = doc(db, "profiles", opt.uid);
        const controllersCollectionRef = collection(profileRef, "controllers");
        //where not deleted
        controllersCollectionRef.value = query(controllersCollectionRef, where("deleted", "==", false))
        const querySnapshot            = await getDocs(controllersCollectionRef);
        await Promise.all(querySnapshot.docs.map(async (doc) => {
            let controller = doc.data();
            if (controller.active && !controller.deleted){
                resp.controllers[doc.id]        = await getController(doc.id)
                //resp.controllers[doc.id].ping   = resp.controllers[doc.id]?.net?.ip ? await controllerPing(resp.controllers[doc.id].net.ip) : false
                resp.active++
                resp.count++
            }else resp.inactive++
        }));
    }else{
        resp["status"] = "error" 
        resp["error"]  = "User id is required"
    }
    return resp;
}

/*
const controllerPing = async (ip) => {
    try {
        const response = await axios.get(`${ip}/api/ping/`);
        return response.data && response.data.response == 'pong';
    } catch (error) {
        console.error("Error status:", ip, error);
        return false;
    }
}*/


const getController = async (id) => { //, opt = {}
    let resp = {};
    let docRef  = doc(db, "alba", id);
    let docSnap = await getDoc(docRef);
    if(docSnap.exists) {
        let ctrl  = docSnap.data();
        if (ctrl && !ctrl.deleted) {
            ctrl.id       = docSnap.id;
            resp          = ctrl;
            resp.devices  = await devicesList(id);
            resp.client   = await getControllerUser(ctrl.uid);
            //if(opt.ups)resp.ups = await requestDevice("https://"+resp.id+".fricontrolalba.com", "controller-actions/"+resp.id+"/ups");
            //resp.ping     = resp?.net?.ip ? await controllerPing(resp.net.ip) : false
        }
    }
    return resp;
}

const getDevice = async (controllerId, deviceId) => {
    try {
        let resp = {};
        let docRef = doc(db, "alba", controllerId, "devices", deviceId);
        let docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            let device = docSnap.data();
            if (!device.deleted) {
                device.id = docSnap.id;
                resp = device;
            }
        }
        return resp;
    } catch (error) {
        console.error("Error getting document:", error);
    }
};

const getGateway = async (gatewayId) => {
    try {
        let resp = {};
        let docRef = doc(db, "gateway", gatewayId);
        let docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            let gateway = docSnap.data();
            if (!gateway.deleted) {
                gateway.id = docSnap.id;
                gateway.status = { alarms: false }
                if (gateway.devices) {
                    let alarms = []
                    const devicePromises = Object.values(gateway.devices).map(async (deviceData) => {
                        if (deviceData.status && deviceData.status?.alarm?.status) {
                            deviceData.status.alarm.device = { id: Object.keys(gateway.devices).find(key => gateway.devices[key] === deviceData), name: deviceData.name }
                            if(deviceData.status?.alarm?.param && deviceData.alarm[deviceData.status?.alarm?.param])deviceData.status.alarm.device.config = deviceData.alarm[deviceData.status?.alarm?.param]
                            deviceData.status.alarm.date = deviceData.status.date.toDate().toLocaleString()
                            alarms.push(deviceData.status.alarm)
                            gateway.status.alarms = true;
                        }
                    });
                    await Promise.all(devicePromises);
                    if(gateway.status.alarms){
                        gateway.status.alarmsActive = alarms;
                        gateway.status.alarmsCount  = alarms.length;
                    }
                }
                resp = gateway;
            }
        }
        return resp;
    } catch (error) {
        console.error("Error getting gateway:", error);
    }
};



const updateDeviceParam = async (controllerId, deviceId, param) => {
    try {
        let docRef  = doc(db, "alba", controllerId, "devices", deviceId);
        await setDoc(docRef, param, { merge: true });
        return true;
    } catch (error) {
        console.error("Error status:", error);
        return false;
    }
}

const devicesList = async (controllerId, opt = {}) => {
    let order = { field: "name", type: "asc" }
    if (opt.order)
        switch (opt.order) {
            case "nameAsc": order = { field: "name", type: "asc" }; break;
            case "nameDes": order = { field: "name", type: "desc" }; break;
        }

    var resp = { devices: {}, count: 0, active: 0, inactive: 0 } //stats: { amount: 0 }

    let albaRef = doc(db, "alba", controllerId);
    let deviceRef = collection(albaRef, "devices");

    //if (opt.visibility && opt.visibility != "all") deviceRef = query(deviceRef, where("active", "==", Boolean(opt.visibility == "active" ? true : false)))
    deviceRef = query(deviceRef, orderBy(order.field, order.type))
    deviceRef = query(deviceRef, where("deleted", "==", false))

    const docsSnap = await getDocs(deviceRef);
    await Promise.all(docsSnap.docs.map(async (doc) => {
        let device = doc.data();
        resp.devices[doc.id]               = device;
        resp.devices[doc.id].id            = doc.id
        resp.devices[doc.id].copilot       = true
        resp.devices[doc.id].sondas        = await sondasList(controllerId, doc.id);
        resp.devices[doc.id].subscription  = await getDeviceSubscription(controllerId, doc.id);
        //resp.devices[doc.id].status        = await getDeviceStatus(doc.id)
        resp.count++
        if (device.active) {
            resp.active++
            //resp.devices[doc.id].data = await requestDevice(device, "device.py");
            //resp.devices[doc.id].status = resp.devices[doc.id].data ? true : false;
            //if (device.subscription.price) resp.stats.amount += parseFloat(device.subscription.price)
        } else resp.inactive++
    }));
    return resp;
}

/*
const getDeviceStatus = async (deviceId) => {
    return await requestDevice("https://friconnect.ddns.net", "device-actions/" + deviceId + "/status");
}
*/

const sondasList = async (controllerId, deviceId) => {
    var resp        = { sondas: {}, count: 0, active: 0, inactive: 0 }
    let albaRef     = doc(db, "alba", controllerId);
    let deviceRef   = collection(albaRef, "devices");
    deviceRef       = doc(deviceRef, deviceId);
    let sondaRef    = collection(deviceRef, "sondas");
    //sondaRef        = query(sondaRef, where("active", "==", true))
    const docsSnap  = await getDocs(sondaRef);
    await Promise.all(docsSnap.docs.map(async (doc) => {
        let sonda = doc.data();
        resp.sondas[doc.id]         = sonda;
        resp.sondas[doc.id].id      = doc.id
        resp.count++
        if (sonda.active) resp.active++
        else resp.inactive++
        
        let alarmsRef       = collection(doc.ref, "alarms");
        const alarmsSnap    = await getDocs(alarmsRef);
        resp.sondas[doc.id].alarms = alarmsSnap.docs.map(alarmDoc => {
            let alarm = alarmDoc.data();
            alarm.id = alarmDoc.id; 
            return alarm;
        });
    }
    ));
    return resp;
}

const updateSondaParam = async (controllerId, deviceId, sondaId, param) => {
    try {
        let docRef  = doc(db, "alba", controllerId, "devices", deviceId, "sondas", sondaId);
        await setDoc(docRef, param, { merge: true });
        return true;
    } catch (error) {  console.error("Error update sonda:", error); return false; }
}

const getControllerUser = async (uid) => {
    const user = auth.currentUser;
    if (!user) return { uid: uid, profile: {}, companies: {} };

    const resp = { uid: user?.uid, profile: user?.uid, companies: {} };
    const userDocRef = doc(collection(db, "users"), uid);
    const docSnap = await getDoc(userDocRef);

    if (docSnap.exists()) {
        resp.profile = docSnap.data();
        resp.profile.id = docSnap.id;
        
        if (resp.profile.role == 'business') {
            const companyQuery = query(collection(userDocRef, "company"), where("deleted", "==", false));
            const companyQuerySnap = await getDocs(companyQuery);
            await Promise.all(companyQuerySnap.docs.map(async (d) => {
                let _company = d.data();
                resp.companies[d.id] = _company;
                resp.companies[d.id].id = d.id;
            }));
        }
    }

    return resp;
}

const getControllerClient = async (controllerId) => {
    let resp   = {}; 
    let client = await getController(controllerId);
    if (client.client){
        resp     = client.client
        resp.uid = client.client.profile.id
    }else {
        resp["status"] = "error"
        resp["error"]  = "Client not found"
    }
    return resp; 
}

const getControllerUsers = async (controllerId) => {
    let resp = { "users": {} };
    let client = await getController(controllerId);
    if (client.client){
        resp["status"] = "success";
        resp["count"]  = 0;
        const profilesSnap = await getDocs(collection(db, "profiles"));
        await Promise.all(profilesSnap.docs.map(async (profileDoc) => {
            const controllerDoc = await getDoc(doc(db, "profiles", profileDoc.id, "controllers", controllerId));
            if (controllerDoc.exists()) {
                let _user = profileDoc.data();
                let _controller = controllerDoc.data();
                if(_controller["active"]){
                    resp["users"][profileDoc.id]             = _user;
                    resp["users"][profileDoc.id].id          = profileDoc.id;
                    resp["users"][profileDoc.id].permissions = _controller;
                    if(resp["users"][profileDoc.id].role == "client")      
                        resp["client"] = profileDoc.id
                    resp["count"]++;
                }
            }
        }));
    } else {
        resp["status"] = "error";
        resp["error"] = "Client not found";
    }

    return resp;
}

const getDeviceSubscription = async (controllerId, deviceId) => {
    var resp        = {}
    let albaRef     = doc(db, "alba", controllerId);
    let subRef      = collection(albaRef, "subscriptions");
    let Ref         = query(subRef, where("deviceId", "==", deviceId))
    const docsSnap  = await getDocs(Ref);
    await Promise.all(docsSnap.docs.map(async (doc) => {
        let sub      = doc.data();
        resp[doc.id] = sub;
    }
    ));
    return resp;
}

const getDeviceSubscriptions = async (controllerId, deviceId) => {
    var resp        = { count: 0, active: { "subscriptions": {}, count: 0 }, inactive: { "subscriptions": {}, count: 0 }  };
    let albaRef     = doc(db, "alba", controllerId);
    let subRef      = collection(albaRef, "subscriptions");
    let Ref         = query(subRef, where("deviceId", "==", deviceId))
    const docsSnap  = await getDocs(Ref);
    await Promise.all(docsSnap.docs.map(async (doc) => {
        let sub      = doc.data();
        if (sub.active){ 
            resp["active"]["subscriptions"][doc.id]   = sub;
            resp["active"]["count"]++
        }else{
            resp["inactive"]["subscriptions"][doc.id] = sub;
            resp["inactive"]["count"]++
        } 
        resp.count++
    }
    ));
    return resp;
}

const getControllerSubsciptions = async (controllerId) => {
    var resp        = { count: 0, active: { "subscriptions": {}, count: 0 }, inactive: { "subscriptions": {}, count: 0 }  };
    let albaRef     = doc(db, "alba", controllerId);
    let Ref         = collection(albaRef, "subscriptions");
    const docsSnap  = await getDocs(Ref);
    await Promise.all(docsSnap.docs.map(async (doc) => {
        let sub      = doc.data();
        if (!sub.deleted){ 
            if (sub.active){ 
                resp["active"]["subscriptions"][doc.id]   = sub;
                resp["active"]["count"]++
            }else{
                resp["inactive"]["subscriptions"][doc.id] = sub;
                resp["inactive"]["count"]++
            } 
            resp.count++
        }
    }
    ));
    return resp;
}

const getClient = async (clientId) => {
    let resp = {};
    let docRef = doc(db, "profiles", clientId);
    let docSnap = await getDoc(docRef);
    if(docSnap.exists) {
        let client = docSnap.data();
        if (client && !client.deleted) {
            client.id = docSnap.id;
            resp = client;
        }
    }
    return resp;
}

const getClientList = async () => {
    var resp = { clients: {}, count: 0, active: 0, inactive: 0 }
    let deviceRef = collection(db, "profiles"); 
    deviceRef = query(deviceRef, where("role", "==", 'client'))
    deviceRef = query(deviceRef, where("deleted", "==", false))
    const docsSnap = await getDocs(deviceRef);
    await Promise.all(docsSnap.docs.map(async (doc) => {
        let client = doc.data();
        resp.clients[doc.id]         = client;
        resp.clients[doc.id].id      = doc.id
        resp.count++
        if (client.active) resp.active++
        else resp.inactive++
    }));
    return resp;
}

const getAgentList = async () => {
    var resp = { agents: {}, count: 0, active: 0, inactive: 0 }
    let deviceRef = collection(db, "profiles"); 
    deviceRef = query(deviceRef, where("role", "==", 'agent'))
    deviceRef = query(deviceRef, where("deleted", "==", false))
    const docsSnap = await getDocs(deviceRef);
    await Promise.all(docsSnap.docs.map(async (doc) => {
        let agent = doc.data();
        resp.agents[doc.id]         = agent;
        resp.agents[doc.id].id      = doc.id
        resp.count++
        if (agent.active) resp.active++
        else resp.inactive++
    }));
    return resp;
}

const createUserAuth = async (opt = {}) => {
    let resp = { status: "success" }
    try {
        let authFB = getAuth();
        const userCredential    = await createUserWithEmailAndPassword(authFB, opt.email, opt.password);
        const user              = userCredential.user;
        resp.uid                = user.uid;
    } catch (error) {
        resp.status = "error";
        resp.error  = error.code;
    }
    return resp;
};

const deleteUserAuth = async (userId) => {
    let resp = { status: "success" }
    try {
        console.log("deleteUserAuth", userId)
    } catch (error) {
        resp.status = "error";
        resp.error  = error.code;
    }
    return resp;
}

const createClient = async (opt = {}) => {
    let resp = { status: "success" }
    let profileRef = collection(db, "profiles");
    opt.createdAt = serverTimestamp();
    opt.updatedAt = serverTimestamp();
    try{
        if(opt.email2)delete opt.email2;
        if(opt.password)delete opt.password;
        if(opt.password2)delete opt.password2;
        await setDoc(doc(profileRef, opt.uid), opt);
    } catch (error) {
        resp.status = "error";
        resp.error  = error.code;
    }
    return resp;
};

const updateClient = async (client) => {
    let resp = { success: false };
    client.updatedAt = serverTimestamp();
    if(client.odoo)delete client.odoo;
    let docRef = doc(db, "profiles", client.id);
    await setDoc(docRef, client, { merge: true });
    resp.success = true;
    return resp;
}

const createUser = async (opt = {}) => {
    let resp = { status: "success" }
    let profileRef = collection(db, "profiles");
    opt.createdAt = serverTimestamp();
    opt.updatedAt = serverTimestamp();
    try{
        //if(opt.password)delete opt.password;
        if(opt.addBox)delete opt.addBox;
        await setDoc(doc(profileRef, opt.uid), opt);
    } catch (error) {
        resp.status = "error";
        resp.error  = error.code;
    }
    return resp;
};

const getAppSettings = async (group) => {
    if(!group)group = "ttn"
    var resp = { settings: {} }
    let settingsRef = doc(db, "settings", group);
    let settingsDoc = await getDoc(settingsRef);
    if (settingsDoc.exists()) resp.settings = settingsDoc.data();
    return resp;
}

const registerEmail = async (options) => {
    let resp = { success: false};
    try {
        let queryParams = new URLSearchParams(options).toString();
        let response    = await axios.get(`https://iot.fricontrolalba.com/webhook/register?${queryParams}`, { headers: {"Content-Type": "application/json" } });
        if (response.data)resp.success = true;
    } catch (error) {
        console.error("Error register email:", error);
    }
    return resp;
}

const clientWelcome = async (options) => {
    let resp = { success: false};
    try {
        let queryParams = new URLSearchParams(options).toString();
        let response    = await axios.get(`https://iot.fricontrolalba.com/webhook/welcome?${queryParams}`, { headers: {"Content-Type": "application/json" } });
        if (response.data)resp.success = true;
    }catch (error){ console.error("Error welcome email:", error); }
    return resp;
}

export { controllerList, requestDevice, getController, getDevice, getDeviceSubscriptions, getControllerClient, getControllerUsers, getControllerSubsciptions, controllerListByUser, 
         updateDeviceParam, updateSondaParam, gatewayList, getGateway, getClient, getClientList, getAgentList, getAppSettings, createUserAuth, createClient, createUser, registerEmail, deleteUserAuth,
         updateClient, clientWelcome };