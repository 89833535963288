<template>
    <div>
        <div class="account-box is-form is-footerless px-0 mx-0" :style="isMobile ? 'border-bottom: none !important;' : ''">
            <template v-if="layout.alarms">
                <div class="form-head stuck-header" :class="isMobile ? 'py-0 ps-4 pe-1' : 'py-3'">
                    <div class="form-head-inner">
                        <div class="left"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5' : 'fs-4'">{{  layout.app.name  }} </h4></div>
                        <div class="inline-block align-top me-1 fs-6 mt-0" style="margin-top: 4px;">
                            <template v-if="layout.app.logo"><img :src="layout.app.logo" style="height: 50px;" /></template>
                            <template v-else>{{  layout.app.id }}</template>
                        </div>
                    </div>
                </div>
                <div class="pt-2 pb-2 fs-5 friBack1 text-white text-center ps-3"><span class="align-middle" v-if="layout.device && layout.device.name">{{ layout.device.name }}</span></div>
                <div class="pt-2 pb-2 fs-6 friBack4 text-center ps-3"><span class="align-middle" v-if="layout.device && layout.device.typeName">{{ layout.device.typeName }}</span></div>
                <div class="ps-3 mt-5">
                    <div class="text-left friBackWhite" style="padding: 0 0 10px 0;">
                        <div class="text-center fs-2 mt-3">Alarmas programadas</div>
                        <div class="text-center mt-2 mx-5">Reciba notificaciones ante situaciones inusuales, manteniendo un control eficaz y en tiempo real.</div>
                        <div class="d-flex justify-content-center align-items-center fs-5 mt-3 pt-2" v-if="layout.alarmsNType && layout.alarmsNType.length">
                            <div class="friUserCircle me-2 friBack4 cursor-pointer" @click="layout.addBox = true;"><i class="bi bi-plus-lg text-dark"></i></div>
                        </div>
                        <template v-if="Object.keys(layout.alarms.alarms).length">
                            <ion-list style="padding-bottom: 100px;">
                                <ion-item detail v-for="(alarm, alarmId) in layout.alarms.alarms" :key="alarmId" style="--ion-item-border-color: #e4e3e3;" class="cursor-pointer me-3" 
                                @click="$router.push({ path: '/alarm/' + layout.appId + '/' + layout.deviceId + '/' + alarm.id , query: { section:  'dashboard' } })">
                                <ion-label class="p-2">
                                    <h2 class="fs-5" :class="layout.device?.status?.alarm?.status && layout.device?.status?.alarm?.alarms && layout.device?.status?.alarm?.alarms[alarm.id] ? 'fw-bold friColor3' : ''">
                                        {{ alarm.name }}
                                    </h2>
                                    <div v-if="alarm?.max" class="fs-6">Máxima de {{ alarm.max }} {{ alarm.unit }}.</div>
                                    <div v-if="alarm?.min" class="fs-6">Mínima de {{ alarm.min }} {{ alarm.unit }}.</div>
                                    <div v-if="alarm?.maxH" class="fs-6">Máxima de {{ alarm.maxH }} {{ alarm.unit }}.</div>
                                    <div v-if="alarm?.minH" class="fs-6">Mínima de de {{ alarm.minH }} {{ alarm.unit }}.</div>
                                    <div v-if="alarm?.minB" class="fs-6">Mínima de de {{ alarm.minB }} {{ alarm.unit }}.</div>
                                    <div v-if="alarm?.openDoor" class="fs-6">Máximo de {{ alarm.openDoor }} min.</div>
                                    <div v-if="layout.device?.status?.alarm?.status && layout.device?.status?.alarm?.alarms && layout.device?.status?.alarm?.alarms[alarm.id]">
                                        <i class="bi bi-exclamation-diamond align-middle friColor3" style="font-size: 20px;"></i> <span class="fs-6 ps-1 align-middle friColor3">Activa</span>
                                    </div>
                                    <div class="mt-1">
                                        <template v-if="alarm?.users.length">
                                            <span class="badge friBorder3 text-dark text-xs px-3 fw-normal" v-if="alarm.users.length==1">{{ alarm.users.length }} notificación</span>
                                            <span v-else class="badge friBorder3 text-dark text-xs px-3 fw-normal">{{ alarm.users.length }} notificaciones</span>
                                        </template>
                                        <span v-if="alarm.active" class="badge friBorder3 text-dark text-xs px-3 ms-2 fw-normal">Activa</span>
                                        <span class="badge friBorder3 friColor3 text-dark text-xs px-3 ms-3 fw-normal" v-else>Inactiva</span>
                                    </div>
                                </ion-label>
                                </ion-item>
                            </ion-list>
                        </template>
                        <template v-else>
                            <div class="text-center mt-5">No hay alarmas programadas</div>
                        </template>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="text-center w-100"  style="margin-top: 150px;margin-bottom: 100px">
                    <div class="fw-bold fs-1">Alarmas programadas</div>
                    <div class="mt-2">Espere por favor ...</div>
                    <div class="spinner-border friColor1 mt-5" role="status">
                    <span class="visually-hidden">Cargando ...</span>
                    </div>
                </div>
            </template>

            <!-- Modal Add Alarm -->
            <ion-modal :is-open="layout.newAlarm && layout.addBox" :backdrop-dismiss="false">
                <ion-content>
                    <template v-if="layout.newAlarm && !layout.newAlarm?.loading">
                        <div class="text-left px-4 pt-3" v-if="!isMobile"><span class="fw-bold fs-4 ps-2">Programar Alarma</span><span class="text-end float-end pt-1"><span class="badge friBorder3 text-dark fw-normal text-x2"> {{ layout.newAlarm.alarmId }}</span></span></div>
                        <div class="text-center pt-5 px-5" v-else>
                            <div class="d-flex justify-content-center align-items-center fs-5 my-3">
                                <div class="friUserCircle friBack4 cursor-pointer"><i class="bi bi-bell text-dark fs-1 friColor4"></i></div>
                            </div>
                            <div class="fw-bold" style="font-size: 22px;">Programar Alarma</div>
                            <div class="fs-5"  v-if="layout.device && layout.device.name">{{ layout.device.name }}</div>
                        </div>
                        <div class="fs-3 text-center px-5 mt-3">
                            <template v-if="layout.addStep == 1">
                                <div class="fs-6 pb-2">Seleccione el tipo de alarma:</div>
                                <div class="px-4 py-3">
                                    <div class="mb-2 br-5 py-3 cursor-pointer" v-for="alarmType in layout.alarmsNType" :key="alarmType.code" @click="layout.newAlarm = alarmType"
                                        :class="layout.newAlarm.code == alarmType.code ? 'friBack1 text-white' : 'friBack9'">
                                        <div class="fs-4 fw-bold" :class="layout.newAlarm.code == alarmType.code ? 'text-white' : ''">{{ alarmType.name }}</div>
                                        <div class="fs-6" :class="layout.newAlarm.code == alarmType.code ? 'text-white' : ''">{{ alarmType.description }}</div>
                                    </div>
                                </div>
                                <div class="mt-3" style="padding: 5px 0 50px 0;">
                                    <div class="d-flex justify-content-center">
                                        <button class="button px-5 py-3 m-0 mt-0 bg-light" @click="layout.addBox = false">Cancelar</button>
                                        <button class="button px-5 py-3 ms-3 fw-bold friBack1 text-white" v-if="layout.newAlarm && layout.newAlarm.code" @click="layout.addStep = 2">Continuar</button><!--@click="addAlarm()"-->
                                    </div>
                                </div>
                            </template>
                            <template v-if="layout.addStep == 2">
                                <ion-list class="m-0 p-0">
                                    <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                        <ion-label position="floating" class="friColor1 fs-6">Nombre *</ion-label>
                                        <ion-input v-model="layout.newAlarm.name"  type="text" autocomplete="off"></ion-input>
                                    </ion-item>
                                </ion-list>
                                <div class="mt-3 px-2">
                                    <table class="table table-borderless table-sm" v-if="layout.newAlarm.param">
                                        <tr v-if="layout.newAlarm.param == 'max'">
                                            <td class="fs-6 text-left py-4">Temperatura máxima *</td>
                                            <td class="fs-5 friColor1">{{ layout.newAlarm.max  }}<span class="fs-6 ps-1 friColor1">{{ layout.newAlarm.unit }}</span></td>
                                            <td class="fs-6"><i class="cursor-pointer bi bi-pencil" :class="isMobile ? 'fs-4' : 'fs-5'" @click="layout.modalParam = 'max'"></i></td>
                                        </tr>
                                        <tr v-if="layout.newAlarm.param == 'min'">
                                            <td class="fs-6 text-left py-4">Temperatura mínima *</td>
                                            <td class="fs-5 friColor1">{{ layout.newAlarm.min  }}<span class="fs-6 ps-1 friColor1">{{ layout.newAlarm.unit }}</span></td>
                                            <td class="fs-6"><i class="cursor-pointer bi bi-pencil" :class="isMobile ? 'fs-4' : 'fs-5'" @click="layout.modalParam = 'min'"></i></td>
                                        </tr>
                                        <tr v-if="layout.newAlarm.param == 'maxH'">
                                            <td class="fs-6 text-left py-4">Humedad máxima *</td>
                                            <td class="fs-5 friColor1">{{ layout.newAlarm.maxH  }}<span class="fs-6 ps-1 friColor1">{{ layout.newAlarm.unit }}</span></td>
                                            <td class="fs-6"><i class="cursor-pointer bi bi-pencil" :class="isMobile ? 'fs-4' : 'fs-5'" @click="layout.modalParam = 'maxH'"></i></td>
                                        </tr>
                                        <tr v-if="layout.newAlarm.param == 'minH'">
                                            <td class="fs-6 text-left py-4">Humedad mínima *</td>
                                            <td class="fs-5 friColor1">{{ layout.newAlarm.minH  }}<span class="fs-6 ps-1 friColor1">{{ layout.newAlarm.unit }}</span></td>
                                            <td class="fs-6"><i class="cursor-pointer bi bi-pencil" :class="isMobile ? 'fs-4' : 'fs-5'" @click="layout.modalParam = 'minH'"></i></td>
                                        </tr>
                                        <tr v-if="layout.newAlarm.param == 'max' || layout.newAlarm.param == 'min' || layout.newAlarm.param == 'maxH' || layout.newAlarm.param == 'minH'">
                                            <td class="fs-6 text-left py-4">Diferencial *</td>
                                            <td class="fs-5 friColor1">{{ layout.newAlarm.diff }}<span class="fs-6 ps-1 friColor1">{{ layout.newAlarm.unit }}</span></td>
                                            <td class="fs-6"><i class="cursor-pointer bi bi-pencil" :class="isMobile ? 'fs-4' : 'fs-5'" @click="layout.modalParam = 'diff'"></i></td>
                                        </tr>
                                        <tr v-if="layout.newAlarm.param == 'minB'">
                                            <td class="fs-6 text-left py-4">Batería mínima *</td>
                                            <td class="fs-5 friColor1">{{ layout.newAlarm.minB }}<span class="fs-6 ps-1 friColor1">{{ layout.newAlarm.unit }}</span></td>
                                            <td class="fs-6"><i class="cursor-pointer bi bi-pencil" :class="isMobile ? 'fs-4' : 'fs-5'" @click="layout.modalParam = 'minB'"></i></td>
                                        </tr>
                                        <tr v-if="layout.newAlarm.param == 'openDoor'">
                                            <td class="fs-6 text-left py-4">Máximo de apertura abierta *</td>
                                            <td class="fs-5 friColor1">{{ layout.newAlarm.openDoor  }}<span class="fs-6 ps-2 friColor1 ">min</span></td>
                                            <td class="fs-6"><i class="cursor-pointer  bi bi-pencil" :class="isMobile ? 'fs-4' : 'fs-5'" @click="layout.modalParam = 'openDoor'"></i></td>
                                        </tr>
                                        <tr>
                                            <td class="fs-6 text-left py-4">Retardo para aviso * </td>
                                            <td class="fs-5 friColor1">{{ layout.newAlarm.warning  }}<span class="fs-6 friColor1 s-2">min</span></td>
                                            <td class="fs-6"><i class="cursor-pointer  bi bi-pencil" :class="isMobile ? 'fs-4' : 'fs-5'" @click="layout.modalParam = 'warning'"></i></td>
                                        </tr>
                                        <tr>
                                            <td class="fs-6 text-left py-4">Retardo para notificación * </td>
                                            <td class="friColor1 fs-5">{{ layout.newAlarm.notify  }}<span class="friColor1 fs-6 ps-2">min</span></td>
                                            <td class="fs-6"><i class="cursor-pointer bi bi-pencil" :class="isMobile ? 'fs-4' : 'fs-5'" @click="layout.modalParam = 'notify'"></i></td>
                                        </tr>
                                        <tr>
                                            <td class="fs-6 text-left">Activa</td>
                                            <td class="fs-6 text-right pt-3" colspan="2"><ion-toggle :enable-on-off-labels="true" slot="end" v-model="layout.newAlarm.active"></ion-toggle></td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="mt-3" style="padding: 20px 0 50px 0;">
                                    <div class="d-flex justify-content-center">
                                        <button class="button px-5 py-3 m-0 mt-0 bg-light" @click="layout.addStep = 1">Volver</button>
                                        <button class="button px-5 py-3 ms-3 fw-bold friBack1 text-white" @click="addAlarm()">Crear</button>
                                    </div>
                                </div>
                            </template>
                        </div>
                        
                        <!--
                        <ion-list class="m-0 p-0">
                            <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;" v-if="layout.alarmsNType">
                                <ion-label position="floating" class="friColor1 pt-0 mt-0" :class="isMobile ? 'fs-4' : ' fs-5'">Tipo *</ion-label>
                                <ion-select interface="popover" v-model="layout.newAlarm.code" class="pfInputValue" @ionChange="loadAlarm">
                                    <ion-select-option v-for="alarmType in layout.alarmsNType" :key="alarmType.code" :value="alarmType.code">{{ alarmType.name }}</ion-select-option>
                                </ion-select>
                            </ion-item>
                            <template v-if="layout.newAlarm.code">
                                
                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                    <ion-label position="floating" class="friColor1" :class="isMobile ? 'fs-4' : ' fs-5'">Nombre *</ion-label>
                                    <ion-input v-model="layout.newAlarm.name"  type="text" autocomplete="off"></ion-input>
                                </ion-item>
                                <table class="table table-borderless table-sm mt-5" v-if="layout.newAlarm.param">
                                    <tr v-if="layout.newAlarm.param == 'max'">
                                        <td class="friColor1 fs-6 text-left">Máxima *</td>
                                        <td class="fs-6">{{ layout.newAlarm.max }}<span class="fs-6 ps-2">{{ layout.newAlarm.unit }}</span></td>
                                        <td class="fs-6"><i class="bi bi-pencil"></i></td>
                                    </tr>
                                </table>
                                <div class="mt-5">
                                    <div class="friColor1 fs-6 text-left ps-3">
                                        Tiempo de retardo para aviso *
                                        <div class="text-xs3">Se trata del período de espera desde que se detecta una condición de alarma hasta que el sistema genera un aviso.</div>
                                    </div>
                                    <div class="mt-5 fw-bold" style="font-size: 40px; line-height: 30px; width: 100%;">{{ layout.newAlarm.warning }}<span class="fs-6 ps-1"> min</span></div>
                                    <ion-range v-model="layout.newAlarm.warning" min="1" max="30" step="1" class="p-0 mx-5" @ionChange="layout.newAlarm.notify = layout.newAlarm.warning +1 "></ion-range>
                                </div>
                                <template v-if="layout.newAlarm.param == 'max'">
                                    <div class="friColor1 fs-6 mt-5 text-left ps-3">Máxima *</div>
                                    <div class="mt-5 fw-bold" style="font-size: 40px; line-height: 30px; width: 100%;">{{ layout.newAlarm.max }}<span class="fs-6 ps-2">{{ layout.newAlarm.unit }}</span></div>
                                    <ion-range v-model="layout.newAlarm.max"  min="-50" max="50" step="1" class="p-0 mx-5"></ion-range>
                                </template> 
                                <template v-if="layout.newAlarm.param == 'min'">
                                    <div class="friColor1 fs-6 mt-2 text-left ps-3">Mínima *</div>
                                    <div class="mt-5 fw-bold" style="font-size: 40px; line-height: 30px; width: 100%;">{{ layout.newAlarm.min }}<span class="fs-6 ps-2">{{ layout.newAlarm.unit }}</span></div>
                                    <ion-range v-model="layout.newAlarm.min" min="-50" max="50" step="1" class="p-0 mx-5"></ion-range>
                                </template>
                                <template v-if="layout.newAlarm.param == 'maxH'">
                                    <div class="friColor1 fs-6 mt-5 text-left ps-3">Humedad máxima *</div>
                                    <div class="mt-5 fw-bold" style="font-size: 40px; line-height: 30px; width: 100%;">{{ layout.newAlarm.maxH }}<span class="fs-6 ps-2">{{ layout.newAlarm.unit }}</span></div>
                                    <ion-range v-model="layout.newAlarm.maxH"  min="1" max="100" step="1" class="p-0 mx-5"></ion-range>
                                </template> 
                                <template v-if="layout.newAlarm.param == 'minH'">
                                    <div class="friColor1 fs-6 mt-2 text-left ps-3">Humedad mínima *</div>
                                    <div class="mt-5 fw-bold" style="font-size: 40px; line-height: 30px; width: 100%;">{{ layout.newAlarm.minH }}<span class="fs-6 ps-2">{{ layout.newAlarm.unit }}</span></div>
                                    <ion-range v-model="layout.newAlarm.minH" min="1" max="100" step="1" class="p-0 mx-5"></ion-range>
                                </template>
                                <template v-if="layout.newAlarm.param == 'minB'">
                                    <div class="friColor1 fs-6 mt-2 text-left ps-3">Batería mínima *</div>
                                    <div class="mt-5 fw-bold" style="font-size: 40px; line-height: 30px; width: 100%;">{{ layout.newAlarm.minB }}<span class="fs-6 ps-2">{{ layout.newAlarm.unit }}</span></div>
                                    <ion-range v-model="layout.newAlarm.minB" min="1" max="3" step="0.1" class="p-0 mx-5"></ion-range>
                                </template>
                                <template v-if="layout.newAlarm.param == 'max' || layout.newAlarm.param == 'min' || layout.newAlarm.param == 'maxH' || layout.newAlarm.param == 'minH'">
                                    <div class="friColor1 fs-6 mt-5 text-left ps-3">Diferencial *</div>
                                    <div class="text-xs3 text-left ps-3">Valor que se suma o resta al valor de alarma para evitar el mal funcionamiento del sistema por fluctuaciones.</div>
                                    <div class="mt-5 fw-bold" style="font-size: 40px; line-height: 30px; width: 100%;">{{ layout.newAlarm.diff  }}<span class="fs-6 ps-2">{{ layout.newAlarm.unit }}</span></div>
                                    <ion-range v-model="layout.newAlarm.diff"  min="0" max="10" step="0.5" class="p-0 mx-5"></ion-range>
                                </template> 
                                <template v-if="layout.newAlarm.param == 'openDoor'">
                                    <div class="friColor1 fs-6 mt-2 text-left ps-3">Tiempo máximo de apertura *</div>
                                    <div class="mt-5 fw-bold" style="font-size: 40px; line-height: 30px; width: 100%;">{{ layout.newAlarm.openDoor }}<span class="fs-6 ps-2">{{ layout.newAlarm.unit }}</span></div>
                                    <ion-range v-model="layout.newAlarm.openDoor" min="1" max="60" step="1" class="p-0 mx-5"></ion-range>
                                </template>
                                <div class="mt-5">
                                    <div class="friColor1 fs-6 text-left ps-3">
                                        Tiempo de retardo para aviso *
                                        <div class="text-xs3">Se trata del período de espera desde que se detecta una condición de alarma hasta que el sistema genera un aviso.</div>
                                    </div>
                                    <div class="mt-5 fw-bold" style="font-size: 40px; line-height: 30px; width: 100%;">{{ layout.newAlarm.warning }}<span class="fs-6 ps-1"> min</span></div>
                                    <ion-range v-model="layout.newAlarm.warning" min="1" max="30" step="1" class="p-0 mx-5" @ionChange="layout.newAlarm.notify = layout.newAlarm.warning +1 "></ion-range>
                                </div>
                                <div>
                                    <div class="friColor1 fs-6 text-left ps-3">
                                        Tiempo de retardo para notificación *
                                        <div class="text-xs3">Es el intervalo de tiempo entre la detección y el envío de la notificación a los usuarios configurados.</div>
                                    </div>
                                    <div class="mt-5 fw-bold" style="font-size: 40px; line-height: 30px; width: 100%;">{{ layout.newAlarm.notify }}<span class="fs-6 ps-1"> min</span></div>
                                    <ion-range v-model="layout.newAlarm.notify" :min="layout.newAlarm.warning"  class="p-0 mx-5" max="60" step="1"></ion-range>
                                </div>
                                <ion-item class="border-none p-0 m-0" lines="none" style="--ion-item-border-color: #ccc;">
                                    <ion-label>Activa</ion-label>
                                    <ion-toggle :enable-on-off-labels="true" slot="end" v-model="layout.newAlarm.active"></ion-toggle>
                                </ion-item>
                               
                            </template>
                        </ion-list> -->
                        <!--
                        <pre>{{ layout.newAlarm }}</pre>
                        <pre v-if="layout.alarmsType">{{ layout.alarmsType }}</pre>
                        -->

                    </template>
                    <template v-else>
                        <div class="text-center"  style="margin-top: 150px; ">
                            <div class="mt-5 fs-5">Creando alarma</div>
                            <div class="mt-2">Espere por favor ...</div>
                            <div class="spinner-border friColor1 mt-5" role="status">
                            <span class="visually-hidden">Cargando ...</span>
                            </div>
                        </div>
                    </template>
                </ion-content>  
            </ion-modal>

            <!-- Modal edit param -->
            <ion-modal :is-open="layout.modalParam" ref="modal" :initial-breakpoint="0.5" :breakpoints="[0.5, 0.75, 1]" :backdrop-dismiss="false">
                <ion-content class="ion-padding">
                    <div class="m-5">
                        <div class="mt-3" style="padding: 20px 0 50px 0;">
                            <template v-if="layout.modalParam == 'max'">
                                <div class="fs-3">Temperatura máxima *</div>
                                <div class="fs-6">Valor que se considera como máximo válido para la temperatura del dispositivo.</div>
                                <div class="pt-3 text-center fw-bold" style="font-size: 50px;">{{ layout.newAlarm[layout.modalParam] }} <span class="fs-6 ps-2">{{ layout.newAlarm.unit }}</span></div>
                                <div><ion-range v-model="layout.newAlarm[layout.modalParam]"  min="-50" max="50" step="1" class="p-0 mx-5"></ion-range></div>
                                <!--
                                <ion-row class="px-5 mt-0">
                                    <ion-col><ion-button class="border-none p-5 fs-1 text-white">-</ion-button></ion-col>
                                    <ion-col><div class="pt-5 mt-3 text-center" style="font-size: 25px;">{{ layout.newAlarm[layout.modalParam] }}</div></ion-col>
                                    <ion-col><ion-button class="border-none p-5 fs-1 text-white">+</ion-button></ion-col>
                                </ion-row>
                                -->
                            </template>
                            <template v-if="layout.modalParam == 'min'">
                                <div class="fs-3">Temperatura mínima *</div>
                                <div class="fs-6">Valor que se considera como mínimo válido para la temperatura del dispositivo.</div>
                                <div class="pt-3 text-center fw-bold" style="font-size: 50px;">{{ layout.newAlarm[layout.modalParam] }} <span class="fs-6 ps-2">{{ layout.newAlarm.unit }}</span></div>
                                <div><ion-range v-model="layout.newAlarm[layout.modalParam]"  min="-50" max="50" step="1" class="p-0 mx-5"></ion-range></div>
                            </template>
                            <template v-if="layout.modalParam == 'maxH'">
                                <div class="fs-3">Humedad máxima *</div>
                                <div class="fs-6">Valor que se considera como máximo válido para la humedad del dispositivo.</div>
                                <div class="pt-3 text-center fw-bold" style="font-size: 50px;">{{ layout.newAlarm[layout.modalParam] }} <span class="fs-6 ps-2">{{ layout.newAlarm.unit }}</span></div>
                                <div><ion-range v-model="layout.newAlarm[layout.modalParam]" min="0" max="100" step="1" class="p-0 mx-5"></ion-range></div>
                            </template>
                            <template v-if="layout.modalParam == 'minH'">
                                <div class="fs-3">Humedad mínima *</div>
                                <div class="fs-6">Valor que se considera como mínimo válido para la humedad del dispositivo.</div>
                                <div class="pt-3 text-center fw-bold" style="font-size: 50px;">{{ layout.newAlarm[layout.modalParam] }} <span class="fs-6 ps-2">{{ layout.newAlarm.unit }}</span></div>
                                <div><ion-range v-model="layout.newAlarm[layout.modalParam]" min="0" max="100" step="1" class="p-0 mx-5"></ion-range></div>
                            </template>
                            <template v-if="layout.modalParam == 'minB'">
                                <div class="fs-3">Batería mínima *</div>
                                <div class="fs-6">Valor que se considera como mínimo válido para la batería del dispositivo.</div>
                                <div class="pt-3 text-center fw-bold" style="font-size: 50px;">{{ parseFloat(layout.newAlarm[layout.modalParam]).toFixed(1) }} <span class="fs-6 ps-2">{{ layout.newAlarm.unit }}</span></div>
                                <div><ion-range v-model="layout.newAlarm[layout.modalParam]" min="1" max="3" step="0.1" class="p-0 mx-5"></ion-range></div>
                            </template>
                            <template v-if="layout.modalParam == 'diff'">
                                <div class="fs-3">Diferencial *</div>
                                <div class="fs-6">Valor que se suma o resta al valor de alarma para evitar el mal funcionamiento del sistema por fluctuaciones.</div>
                                <div class="pt-3 text-center fw-bold" style="font-size: 50px;">{{ layout.newAlarm[layout.modalParam] }} <span class="fs-6 ps-2">{{ layout.newAlarm.unit }}</span></div>
                                <div><ion-range v-model="layout.newAlarm[layout.modalParam]" min="0" max="10" step="0.5" class="p-0 mx-5"></ion-range></div>
                            </template>
                            <template v-if="layout.modalParam == 'openDoor'">
                                <div class="fs-3">Tiempo máximo de apertura *</div>
                                <div class="fs-6">Valor que se considera como máximo válido para el tiempo de apertura del dispositivo.</div>
                                <div class="pt-3 text-center fw-bold" style="font-size: 50px;">{{ layout.newAlarm[layout.modalParam] }} <span class="fs-6 ps-2">{{ layout.newAlarm.unit }}</span></div>
                                <div><ion-range v-model="layout.newAlarm[layout.modalParam]" min="1" max="60" step="1" class="p-0 mx-5"></ion-range></div>
                            </template>
                            <template v-if="layout.modalParam == 'warning'">
                                <div class="fs-3">Tiempo de retardo para aviso *</div>
                                <div class="fs-6">Se trata del período de espera desde que se detecta una condición de alarma hasta que el sistema genera un aviso.</div>
                                <div class="pt-3 text-center fw-bold" style="font-size: 50px;">{{ layout.newAlarm[layout.modalParam] }}<span class="fs-5 ps-2" v-if="layout.newAlarm[layout.modalParam] == 1">minuto</span><span class="fs-5 ps-2" v-else>minuntos</span></div>
                                <div><ion-range v-model="layout.newAlarm[layout.modalParam]" min="1" max="30" step="1" class="p-0 mx-5" @ionChange="layout.newAlarm.notify = layout.newAlarm.warning +1 "></ion-range></div>
                            </template>
                            <template v-if="layout.modalParam == 'notify'">
                                <div class="fs-3">Tiempo de retardo para el envío de notificaciones *</div>
                                <div class="fs-6">Es el intervalo de tiempo entre la detección y el envío de la notificación a los destinatarios configurados en la alarma.</div>
                                <div class="pt-0 text-center fw-bold" style="font-size: 50px;">{{ layout.newAlarm[layout.modalParam] }}<span class="fs-5 ps-2" v-if="layout.newAlarm[layout.modalParam] == 1">minuto</span><span class="fs-5 ps-2" v-else>minuntos</span></div>
                                <div><ion-range v-model="layout.newAlarm[layout.modalParam]"  :min="layout.newAlarm.warning"  class="p-0 mx-5" max="60" step="1"></ion-range></div>
                            </template>
                            <div class="d-flex justify-content-center mt-4">
                                <button class="button px-5 py-3 m-0 mt-0 bg-light" @click="layout.modalParam = false">Aceptar</button>
                            </div>
                        </div>
                    </div>
                </ion-content>
            </ion-modal>

        </div>
    </div>
  </template>
  <script>
  import { defineComponent, onMounted, ref, watch } from "vue"; 
  import { useAuthStore } from "@/store";
  import { getApp, getAppDevices, getDeviceAlarms  } from "../../../model/apps.js"; //, requestDevice
  import { helper as $h } from "../../../utils/helper.js";
  import { add } from 'ionicons/icons';
  import { IonItem, IonLabel, IonList, IonToggle, IonInput, IonRange, IonModal, IonContent } from '@ionic/vue';
  import { getAlarmsType, createAlarm } from "../../../model/alarms.js";
  import { not as $not } from "../../../model/not";
  import { useRoute } from 'vue-router'
  import router from "@/router";

  export default defineComponent({
    name: 'SensorAlarms',
    props: {
        isMobile: Boolean,
        appId: String,
        deviceId: String
     },
     components: { IonItem, IonLabel, IonList, IonToggle,  IonInput, IonRange, IonModal, IonContent }, //, IonRange //IonSelect, IonSelectOption, 
    setup(props) {
  
       const authStore      = useAuthStore();
       const layout = ref({ 
                            appId:       null,
                            deviceId:    null,
                            device:      null,
                            alarms:      null,
                            newAlarm:    null,
                            alarmsType:  null,
                            alarmsNType: null,
                            addBox:      false,
                            addStep:     1,
                            helper:      $h
                          }) 

       const { user, profile } = authStore;
       const route = useRoute()      

       const loadAlarm = async () => {
            layout.value.alarmsType  = await getAlarmsType({ type: layout.value.device.type })
            layout.value.alarmsNType = []
            if(layout.value.alarmsType && layout.value.alarmsType.length){
                for (let i = 0; i < layout.value.alarmsType.length; i++){ 
                    if(layout.value.alarms?.alarms){
                        if(!layout.value.alarms.alarms[layout.value.alarmsType[i].code])
                            layout.value.alarmsNType.push(layout.value.alarmsType[i])  
                    }
                }
                if(!layout.value.newAlarm)layout.value.newAlarm = layout.value.alarmsNType[0]
                else layout.value.newAlarm = layout.value.alarmsType.find( alarmType => alarmType.code == layout.value.newAlarm.code)
            }
               
       }

       const addAlarm = async () => {
          let validate = true
          let required = ['name', 'warning', 'notify']
          layout.value.newAlarm.loading  = true;
          required.forEach((field) => { if(!layout.value.newAlarm[field])validate = false })
          if(validate){
            let createAlarmResp  = await createAlarm(layout.value.appId, layout.value.deviceId, layout.value.newAlarm)
            if(createAlarmResp && createAlarmResp?.success) {
                setTimeout(async () => {
                    
                    await initAlarms();
                    layout.value.newAlarm.loading  = false;
                    layout.value.addBox = false;
                    layout.value.addStep = 1
                    $not.toastApp({ message: 'Alarma creada', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'primary', cssClass: 'text-white mt-2' })
                }, 2000);
            }else $not.toastApp({ message: 'Error al crear la alarma', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })        
          }else $not.toastApp({ message: 'Debe rellenar los parámetros requeridos', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
        }

       const initAlarms = async () => {
         initNewAlarm();
         layout.value.app        = await getApp(layout.value.appId)
         let deviceData          = await getAppDevices(layout.value.appId); 
         if(deviceData && deviceData?.devices && Object.keys(deviceData.devices).length)layout.value.device = deviceData.devices[layout.value.deviceId]
         layout.value.alarms     = await getDeviceAlarms(layout.value.appId, layout.value.deviceId) 
         await loadAlarm();
       }

       const initNewAlarm = () => {
            /*let numAlarms         = 1
            if(layout.value.alarms && layout.value.alarms.alarms)
                numAlarms = Object.keys(layout.value.alarms.alarms).length + 1
            let newAlarmId        = 'a' + layout.value.appId.toString().toLowerCase() + numAlarms.toString();
            newAlarmId            = newAlarmId.replace(/[A-Z]/g, '').substr(0, 22); 
            layout.value.newAlarm = {
                                        appId:    layout.value.appId,
                                        addBox:   false,
                                        alarmId:  newAlarmId, 
                                        name:     'Alta Temperatura',
                                        active:   true,    
                                        deleted:  false, 
                                        loading:  false,       
                                        code:     'AH0001',   
                                        max:      5,
                                        min:      -15,
                                        notify:   { warning: 5, notify: 10 }  
                                    }   */  
        }
        
        
       onMounted( async () => { 
         layout.value.appId     = props.appId
         layout.value.deviceId  = router.currentRoute.value.params.deviceId
         await initAlarms();
       });

        watch(() => route.query, async (query) => {
            if (query && router.currentRoute.value.params && router.currentRoute.value.params.deviceId){
                layout.value.deviceId  = router.currentRoute.value.params.deviceId
                await initAlarms();
            }
        });

        return { layout, user, props, profile, add, loadAlarm, addAlarm};
    }
    
  })
  </script>