
  import { defineComponent, onMounted, ref, watch } from "vue"; 
  import { useAuthStore } from '@/store';
  import { useRoute } from 'vue-router'
  import router from "@/router";
  import { add, saveOutline, chevronBack } from 'ionicons/icons';
  import { IonItem, IonLabel, IonInput, IonList, IonToggle, alertController  } from '@ionic/vue'; 
  import { getClient, registerEmail } from "../../model/api.js";
  import { helper as $h } from "../../utils/helper.js";
  import { not as $not } from "../../model/not";
  import { updateUser, deleteUser, getScenesByUser } from "../../model/apps.js";

  export default defineComponent({
    name: 'userPage',
    props: {
        isMobile: Boolean,
     },
     components: {  IonItem, IonLabel, IonInput, IonList, IonToggle },
    setup(props) {
      
      const authStore = useAuthStore();
      const { user, profile } = authStore;

      const route = useRoute()

      const layout = ref({ 
                          loading:  true,
                          appId:    router.currentRoute.value.params.appId,
                          userId:   router.currentRoute.value.params.userId,
                          userOri:  null,
                          user:     null,
                          query:    null,
                          section:  null,
                          sections: [
                            { name: 'dashboard', title: 'Usuario', icon: 'bi bi-grid'},
                          ],
                          helper:   $h,
                         })

      const initUser = async () => {
        layout.value.user        = await getClient(layout.value.userId)
        layout.value.user.scenes = await getScenesByUser(layout.value.appId, layout.value.userId)
        layout.value.userOri = layout.value.user
        if(!layout.value.section)
          if(route.query.section) layout.value.section = route.query.section
          else layout.value.section = 'dashboard'
        layout.value.loading = false;
      }

      const delUser = async () => {
        const alert = await alertController.create({
            header:  "¿Está seguro? Esta accuión és irreversible",
            cssClass: 'friCustomAlert',
            buttons: [ 
                        {text: 'Cancelar', cssClass: 'alert-button-cancel', role: 'cancel' },  
                        {text: 'Confirmar',cssClass: 'alert-button-confirm', handler: async () => { 
                          setTimeout(() => { 
                            deleteUser(layout.value.user.id)
                            router.push({ path: '/device/' + layout.value.appId , query: { section:  'users' } })
                           }, 1000)
                        }
                        }
                      ],
        });
        await alert.present();
      }

      const sendUserWelcome = async () => {
        const alert = await alertController.create({
            header:  "¿Está seguro? " + layout.value.user.email,
            cssClass: 'friCustomAlert',
            buttons: [ 
                        {text: 'Cancelar', cssClass: 'alert-button-cancel', role: 'cancel', handler: async () => {  } }, 
                        {text: 'Confirmar',cssClass: 'alert-button-confirm', handler: async () => { 
                            await registerEmail({ userId: layout.value.user.id })
                            $not.toastApp({ message: 'Se ha enviado el email con los datos de usuario', position: 'top', icon: 'info', duration: 2000, animated: true, cssClass: 'text-white mt-2 friToast1'})
                         }
                        }
                    ],
        });
        await alert.present();
    }

      const updUser = async () => {
        let validate = true
        let required = ['first', 'last']
        required.forEach((field) => {  if(!layout.value.user[field])validate = false })
        if(validate){
          if(layout.value.user)await updateUser(layout.value.user)
          $not.toastApp({ message: 'Se ha actualizado el usuario correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, cssClass: 'text-white mt-2 friToast1'})
        }else $not.toastApp({ message: 'Debe rellenar los parámetros requeridos', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
      }

      watch(() => route.query, async (query) => {
        if (query){
            layout.value.query   = query; 
            layout.value.section = query.section;
        }
      });

      onMounted(async () => {
        await initUser()
      })
  
      return { authStore, user, profile, router, layout, props, add, saveOutline, chevronBack, delUser, updUser, sendUserWelcome }
  
    },
  });
  