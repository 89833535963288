<template>
    <div>
        <!-- Device Log -->
        <div class="account-box is-form is-footerless" :style="isMobile ? 'border-bottom: none !important;' : ''">
          <template v-if="!layout.loading && layout.app && layout.app.logs?.logs?.alarms">
              <div class="form-head stuck-header" :class="isMobile ? 'py-0' : 'py-3'">
                  <div class="form-head-inner">
                      <div class="left"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5 ms-2' : 'fs-4'">{{  layout.app.name }} </h4></div>
                      <div class="inline-block align-top me-1 fs-6 mt-0" style="margin-top: 4px;">
                          <template v-if="layout.app.logo"><img :src="layout.app.logo" style="height: 50px;" /></template>
                          <template v-else>{{  layout.app.id }}</template>
                      </div>
                  </div>
              </div>
              <div class="pt-2 pb-2 fs-5 friBack1 text-white text-center ps-3"><span class="align-middle" v-if="layout.device && layout.device.name">{{ layout.device.name }}</span></div>
              <div class="pt-2 pb-2 fs-6 friBack4 text-center ps-3"><span class="align-middle" v-if="layout.alarm && layout.alarm.name">Historial: {{ layout.alarm.name }}</span></div>
              <div class="ps-4 pe-3 mt-3">
                  <div class="d-flex justify-content-between" id="logFiltersBox">
                    <div class="d-flex mb-3">
                      <div>
                        <div @click="layout.modal.startDate = true" class="friBorder3 br-5 px-3 py-4 text-center cursor-pointer" style="width: 80px;">
                          {{ layout.search.dateStart.substring(8, 10) }}/{{ layout.search.dateStart.substring(7, 5) }}/{{ layout.search.dateStart.substring(0, 4) }}
                        </div>
                        <ion-modal :is-open="layout.modal.startDate" :backdrop-dismiss="false">
                          <div class="p-5 mt-5">
                            <div class="d-flex justify-content-center" v-if="layout.search.dateStart">
                              <ion-datetime locale="es-ES" :first-day-of-week="1" presentation="date" :show-default-title="true" :show-default-buttons="false"  v-model="layout.search.dateStart" @ionChange="layout.modal.startDate = false" color="fricolor" :max="layout.search.maxDate" :min="layout.search.minDate">
                                <span slot="time-label">Hora</span>
                                <span slot="title" class="text-white">Fecha de inicio</span>
                              </ion-datetime> 
                            </div>  
                          </div>
                        </ion-modal>
                      </div>
                      <div class="ms-2">
                        <div @click="layout.modal.endDate = true" class="friBorder3 br-5 px-3 py-4 text-center cursor-pointer" style="width: 80px;">
                          {{ layout.search.dateEnd.substring(8, 10) }}/{{ layout.search.dateEnd.substring(7, 5) }}/{{ layout.search.dateEnd.substring(0, 4) }}
                        </div>
                        <ion-modal :is-open="layout.modal.endDate" :backdrop-dismiss="false">
                          <div class="p-5 mt-5">
                            <div class="d-flex justify-content-center" v-if="layout.search.dateEnd">
                              <ion-datetime locale="es-ES" :first-day-of-week="1" presentation="date" :show-default-title="true" :show-default-buttons="false"  
                                v-model="layout.search.dateEnd" @ionChange="layout.modal.endDate = false" color="fricolor" :max="layout.search.maxDate" :min="layout.search.dateStart">
                                <span slot="time-label">Hora</span>
                                <span slot="title" class="text-white">Fecha fin</span>
                              </ion-datetime> 
                            </div>  
                          </div>
                        </ion-modal>
                        <!-- <input type="date" class="form-control py-2" id="dateEnd" v-model="layout.search.dateEnd"  /> -->
                      </div>
                      <div class="ms-2" v-if="layout.app?.devices?.devices">
                        <ion-item lines="none" class="friBorder3 br-5" style="--ion-item-border-color: #ccc;">
                            <ion-select interface="popover" v-model="layout.search.deviceId" style="width: 120px; font-size: 14px;;" class="p-0 m-0 border-none">
                                <ion-select-option v-for="(device, index) in layout.app.devices.devices" :key="index" :value="device.deviceId">{{ (device.name.length > 10) ? device.name.substring(0, 10) + '...' : device.name  }}</ion-select-option>
                            </ion-select>
                        </ion-item>
                      </div>
                      <div v-if="!isMobile" style="padding-left: 5px; margin-top: 0.5px;"><span class="badge friBack1 fs-6 cursor-pointer" style="padding: 18px 20px" @click="filterLog()">Buscar</span></div>
                    </div>
                  </div>
                  <div v-if="isMobile" style="padding-left: 5px; margin-top: 0.5px;"><span class="badge friBack1 fs-6 cursor-pointer py-4 w-100 px-0 mb-2" @click="filterLog()">Buscar</span></div>
                  <template v-if="layout.app.logs?.logs?.alarms.length">
                    
                    <div style="padding-bottom: 100px;">
                      <div class="py-3">
                        <div class="text-left fw-bold fs-5 px-2 pb-3">
                          <span class="align-top" v-if="layout.search.dateStart==layout.search.dateEnd">Hoy</span>  
                          <span v-else>
                            Resultados
                          </span>
                          <!--<span v-else class="fs-6 align-top">{{ layout.search.dateStart.substring(8, 10) }}/{{ layout.search.dateStart.substring(7, 5) }}/{{ layout.search.dateStart.substring(0, 4) }} a {{ layout.search.dateEnd.substring(8, 10) }}/{{ layout.search.dateEnd.substring(7, 5) }}/{{ layout.search.dateEnd.substring(0, 4) }}</span>-->
                          <span class="mb-3 fs-6 text-end float-end fw-normal align-top friColor1">{{ layout.app.logs?.logs?.total_records }} alarmas</span>
                        </div>
                        <ion-grid class="w-100">
                          <ion-row class="text-xs3">
                            <ion-col style="max-width: 30px;">Nº</ion-col>
                            <ion-col :style="isMobile ? 'max-width: 80px;' : ''" v-if="layout.search.dateStart!=layout.search.dateEnd">Fecha</ion-col>
                            <ion-col :style="isMobile ? 'max-width: 60px;' : ''">Hora</ion-col>
                            <ion-col :style="isMobile ? 'max-width: 100px;' : ''">Alarma</ion-col>
                            <ion-col :style="isMobile ? 'max-width: 50px;' : ''"></ion-col>
                            <ion-col :style="isMobile ? 'max-width: 80px;' : ''" class="text-center">Estado</ion-col>
                          </ion-row>
                          <ion-row class="w-100 py-2 cursor-pointer" v-for="(log, index) in layout.app.logs?.logs?.alarms" :key="index" 
                             :class="index % 2 == 0 ? 'friBack9' : ''" 
                             style="font-size: 12px;" @click="layout.alarmBox = true; layout.alarmEdit = log"
                             :style="!log.status?.end_date ? 'color: #ff0000' : ''">
                            <ion-col style="max-width: 30px;">{{ index + 1 }}</ion-col>
                            <ion-col :style="isMobile ? 'max-width: 80px;' : ''" v-if="layout.search.dateStart!=layout.search.dateEnd">{{ log.date.substring(8, 10) }}/{{ log.date.substring(7, 5) }}/{{ log.date.substring(0, 4) }}</ion-col>
                            <ion-col :style="isMobile ? 'max-width: 60px;' : ''">{{ log.date.substring(11, 19) }}</ion-col>
                            <ion-col :style="isMobile ? 'max-width: 100px;' : ''" v-if="isMobile">{{ log.name.substring(0, 14) }} {{ log.name.length > 14 ? '...' : '' }}</ion-col>
                            <ion-col :style="isMobile ? 'max-width: 100px;' : ''" v-else>{{ log.name }}</ion-col>
                            <ion-col :style="isMobile ? 'max-width: 50px;' : ''">{{ parseFloat(log.alarm.detect).toFixed(2) }} {{ log.alarm.unit }}</ion-col>
                            <ion-col :style="isMobile ? 'max-width: 80px;' : ''" class="text-center">{{  log.status.status != 'solved' ? 'Activa' : 'Finalizada'}}</ion-col>
                          </ion-row>
                        </ion-grid>
                      </div>
                      <!--
                      <pre>{{ layout.search }}</pre>
                      <pre>{{ layout.app }}</pre>
                      -->
                      
                      <!-- Modal Alarm -->
                      <ion-modal :is-open="layout.alarmBox" :backdrop-dismiss="false">
                        <ion-content class="me-5" v-if="layout.alarmEdit">
                          <div class="px-5 py-4 fs-6">
                            <div class="fw-bold fs-1">{{ layout.alarmEdit.name }} <span class="float-end" @click="layout.alarmBox = false"><i class="bi bi-x-circle" style="font-size: 30px;"></i></span></div>
                            <div class="fs-4 mb-3 friColor3" v-if="layout.alarmEdit.status.status != 'solved'">Activa</div>
                            <div class="fs-4 mb-3 friColor1" v-else>Finalizada</div>
                            <div v-if="layout.alarmEdit.status.solved_by">
                              <div v-if="layout.alarmEdit.status.solved_by=='System'" class="text-center p-2 friBack4 br-5">
                                El sistema ha descartado la alarma por el restablecimiento de sus valores.
                              </div>
                              <template v-else>
                                Descartada por el usuario:
                                <span class="float-end">{{ layout.alarmEdit.status.solved_by.toString().substring(0, 10) }}...</span>
                              </template>
                            </div>

                            <div class="line_box" style="margin: 20px 0 20px 0;">
                              <div class="text_circle">
                                  <div class="circle">
                                    <h4 class="fs-5 text-dark">Alarma</h4>
                                    <p class="text-dark">{{ layout.alarmEdit.date.substring(8, 10) }}/{{ layout.alarmEdit.date.substring(5, 7) }}/{{ layout.alarmEdit.date.substring(0, 4) }}</p>
                                    <p class="text-dark">{{ layout.alarmEdit.date.substring(11, 19) }}</p>
                                  </div>
                                  <div class="tvar">
                                    <div class="float-end fw-bold friColor3">
                                      {{ parseFloat(layout.alarmEdit.alarm.detect).toFixed(1) }}
                                      <span class="text-xs">{{ layout.alarmEdit.alarm.unit }}</span>
                                      <p v-if="layout.alarmEdit.alarm.param == 'max'" class="text-dark p-0 m-0">Máx: {{ parseFloat(layout.alarmEdit.alarm.paramValue).toFixed(1) }} <span class="text-xs">{{ layout.alarmEdit.alarm.unit }}</span></p>
                                      <p v-if="layout.alarmEdit.alarm.param == 'min'" class="text-dark p-0 m-0">Mín: {{ parseFloat(layout.alarmEdit.alarm.paramValue).toFixed(1) }} <span class="text-xs">{{ layout.alarmEdit.alarm.unit }}</span></p>
                                    </div>
                                  </div>
                              </div>
                              <template v-if="layout.alarmEdit.status?.detectEnd">
                                <div class="text_circle">
                                    <div class="circle">
                                      <h4 class="fs-5 text-dark">Duración</h4>
                                    </div>
                                    <div class="tvar tvarMin">
                                      <div class="float-center fw-bold text-dark px-0 m-0" style="width: 100%;padding: 30px 0; font-size: 35px; line-height: 10px !important;">
                                        <template v-if="layout.alarmEdit.status.duration">
                                          {{ layout.alarmEdit.status.duration }}
                                          <p class="text-xs mt-5 text-dark">minutos</p>
                                        </template>
                                        <template v-else>
                                          <template v-if="layout.alarmEdit.duration">
                                            {{ layout.alarmEdit.duration.days }}d {{ layout.alarmEdit.duration.hours }}h {{ layout.alarmEdit.duration.minutes }}m
                                          </template>
                                        </template>
                                      </div>
                                    </div>
                                </div>
                                <div class="text_circle">
                                    <div class="circle">
                                      <h4 class="fs-5 text-dark">Finalizada</h4>
                                      <p class="text-dark">{{ layout.alarmEdit.status.end_date.substring(8, 10) }}/{{ layout.alarmEdit.status.end_date.substring(5, 7) }}/{{ layout.alarmEdit.status.end_date.substring(0, 4) }}</p>
                                      <p class="text-dark">{{ layout.alarmEdit.status.end_date.substring(11, 19) }}</p>
                                    </div>
                                    <div class="tvar">
                                      <div class="float-end fw-bold friColor1">
                                        {{ parseFloat(layout.alarmEdit.status.detectEnd).toFixed(1) }}
                                        <span class="text-xs">{{ layout.alarmEdit.alarm.unit }}</span>
                                        <p v-if="layout.alarmEdit.alarm.param == 'max'" class="text-dark p-0 m-0">Máx: {{ parseFloat(layout.alarmEdit.alarm.paramValue).toFixed(1) }} <span class="text-xs">{{ layout.alarmEdit.alarm.unit }}</span></p>
                                        <p v-if="layout.alarmEdit.alarm.param == 'min'" class="text-dark p-0 m-0">Mín: {{ parseFloat(layout.alarmEdit.alarm.paramValue).toFixed(1) }} <span class="text-xs">{{ layout.alarmEdit.alarm.unit }}</span></p>
                                      </div>
                                    </div>
                                </div>
                              </template>
                              <template v-else>
                                <div class="text_circle">
                                    <div class="circle">
                                      
                                    </div>
                                    <div class="tvar">
                                      <div class="float-center fw-bold text-dark px-0 m-0" style="width: 100%;padding: 40px 0; font-size: 35px; line-height: 10px !important;">
                                        <h4 class="fs-6 friColor3">En curso2</h4>
                                      </div>
                                    </div>
                                </div>
                              </template>
                            </div>


                            <!--
                              <div class="mt-5">Alarma: <span class="float-end">{{ layout.alarmEdit.name }}</span></div>
                              <div>Iniciada: <span class="float-end">{{ layout.alarmEdit.date }}</span></div>
                              <div v-if="layout.alarmEdit.status.end_date">Finalizada: <span class="float-end">{{ layout.alarmEdit.status.end_date }}</span></div>
                              <div v-if="layout.alarmEdit.status.duration">Duración: <span class="float-end fw-bold">{{ layout.alarmEdit.status.duration }} minutos</span></div>
                              <div v-if="layout.alarmEdit.duration">
                                Duración:
                                <span class="float-end fw-bold">
                                  <span v-if="layout.alarmEdit.duration.days" class="pe-2">{{ layout.alarmEdit.duration.days }}d</span>
                                  <span v-if="layout.alarmEdit.duration.hours" class="pe-2">{{ layout.alarmEdit.duration.hours }}h</span>
                                  <span v-if="layout.alarmEdit.duration.minutes">{{ layout.alarmEdit.duration.minutes }}m</span>
                                </span>
                              </div>
                              <div v-if="layout.alarmEdit.status.status != 'solved'">Estado: <span class="float-end fw-bold">{{ layout.alarmEdit.status.status }}</span></div>
                              <div class="mt-5 mb-3 fs-6 fw-bold">Alarma</div>
                              <template v-if="layout.alarmEdit.alarm.param == 'max'">
                                  <div>Máxima: <span class="float-end fw-bold">{{ layout.alarmEdit.alarm.paramValue }} <span class="fs-6">{{ layout.alarmEdit.alarm.unit }}</span></span></div>
                                  <div>Dectada: <span class="float-end fw-bold friColor3">{{ layout.alarmEdit.alarm.detect }} <span class="fs-6">{{ layout.alarmEdit.alarm.unit }}</span></span></div>
                                  <template v-if="layout.alarmEdit.status?.detectEnd">
                                    <div>Resuelto: <span class="float-end fw-bold friColor1">{{ parseFloat(layout.alarmEdit.status.detectEnd).toFixed(2) }} <span class="fs-6">{{ layout.alarmEdit.alarm.unit }}</span></span></div>
                                  </template>
                              </template>
                              <template v-if="layout.alarmEdit.alarm.param == 'min'">
                                <div>Mínima: <span class="float-end fw-bold">{{ layout.alarmEdit.alarm.paramValue }} <span class="fs-6">{{ layout.alarmEdit.alarm.unit }}</span></span></div>
                                <div>Dectada: <span class="float-end fw-bold friColor3">{{ layout.alarmEdit.alarm.detect }} <span class="fs-6">{{ layout.alarmEdit.alarm.unit }}</span></span></div>
                                <template v-if="layout.alarmEdit.status?.detectEnd">
                                  <div>Resuelto: <span class="float-end fw-bold friColor1">{{ parseFloat(layout.alarmEdit.status.detectEnd).toFixed(2) }} <span class="fs-6">{{ layout.alarmEdit.alarm.unit }}</span></span></div>
                                </template>
                            </template>

                            <div class="mt-5 mb-3 fs-6 fw-bold">Usuarios</div>
                            <template v-if="layout.alarm?.users.length">
                              <div v-for="(user, index) in layout.alarm?.users" :key="index" class="fs-6"><div v-if="user.notify.app">{{ user.first }} {{ user.last }}</div></div>
                            </template>
                            -->
                            
                           <div class="mt-3 mb-3 fs-6 fw-bold">Notificaciones</div>
                            <div v-if="layout.alarmEdit.notify">
                              <div>App <span class="float-end"><span v-if="layout.alarmEdit.notify.app"><i class="bi bi-check-lg"></i></span><span v-else>No</span></span></div>
                              <div>Whatsapp <span class="float-end"><span v-if="layout.alarmEdit.notify.whatsapp"><i class="bi bi-check-lg"></i></span><span v-else>No</span></span></div>
                              <div>Email <span class="float-end"><span v-if="layout.alarmEdit.notify.email"><i class="bi bi-check-lg"></i></span><span v-else>No</span></span></div>
                              <div>SMS <span class="float-end"><span v-if="layout.alarmEdit.notify.sms"><i class="bi bi-check-lg"></i></span><span v-else>No</span></span></div>
                            </div>
                            <div class="mt-5 fs-6 fw-bold">Acciones</div>
                            <div class="mt-2 text-center mb-5" style="padding: 10px 0;">
                              <button class="button button v-button py-05 friBack4" type="button" @click="layout.alarmBox = false">Cerrar</button>
                              <button v-if="!layout.alarmEdit.status.end_date && ((layout.alarmEdit.users && layout.alarmEdit.users[profile.uid] && layout.alarmEdit.users[profile.uid].discard) || profile.isClient)" 
                              class="button button v-button py-05 friBack7 text-white fw-bold ms-3 " type="button" @click="jobDiscard()">Descartar</button>
                            </div>
                            <pre>{{ layout.alarmEdit }}</pre>
                          </div>
                          </ion-content>
                      </ion-modal>

                    </div>
                  </template>
                  <template v-else>
                    <div class="text-center mt-5 mx-5 px-5">No dispone de registros para las fechas y filtros seleccionados</div>
                  </template>
                
              </div>
          </template>  
          <template v-else>
                <div class="text-center w-100"  style="margin: 150px 0;">
                    <div class="fw-bold fs-3">Historial de alarma</div>
                    <div class="mt-2">Espere por favor ...</div>
                    <div class="spinner-border friColor1 mt-5" role="status">
                    <span class="visually-hidden">Cargando ...</span>
                    </div>
                </div>
            </template>
        </div>
    </div>
  </template>
  <script>
  import { defineComponent, onMounted, ref } from "vue"; 
  import { useAuthStore } from "@/store";
  import { helper as $h } from "../../../utils/helper.js";
  import { getApp, getAppDevices, getDeviceAlarmsLog, discardJob  } from "../../../model/apps.js"; //
  import { IonDatetime, IonModal, IonItem, IonSelect, IonRow, IonCol, IonGrid, alertController } from '@ionic/vue'; 
  import { getAlarm  } from "../../../model/alarms.js";

  export default defineComponent({
    name: 'DeviceLog',
    props: {
        isMobile: Boolean,
        appId:    String,
        deviceId: String,
        alarmId:  String,
     },
    components: { IonDatetime, IonModal, IonItem, IonSelect, IonRow, IonCol, IonGrid },
    setup(props) {

       const authStore      = useAuthStore();
       const layout         = ref({ 
                                    loading:  true,
                                    appId:    null,
                                    app:      null,
                                    helper:   $h,
                                    search:  { dateStart: null, dateEnd: null, maxDate: null, minDate: null, deviceId: null },
                                    modal:   { startDate: false, endDate: false },
                                    alarmBox: false,
                                    alarmEdit: false
                                  }) 

       const { user, profile } = authStore;

       const initStats = async () => {
         layout.value.app         = null; 
         layout.value.appId       = props.appId
         layout.value.deviceId    = props.deviceId
         layout.value.alarmId     = props.alarmId
         layout.value.app         = await getApp(props.appId)

         let deviceData           = await getAppDevices(layout.value.appId, layout.value.deviceId)
         if(deviceData && deviceData?.devices && Object.keys(deviceData.devices).length)layout.value.device = deviceData.devices[Object.keys(deviceData.devices)[0]]
         layout.value.alarm       = await getAlarm(layout.value.appId, layout.value.deviceId, layout.value.alarmId)

         layout.value.app.devices = await getAppDevices(props.appId);
         await initFilters();
         await getAlarms();
         layout.value.loading  = false;
         
       }

       const getAlarms = async () => {
         layout.value.app.logs = await getDeviceAlarmsLog(props.appId, { 
                                                                appId:     props.appId, 
                                                                from_date: layout.value.search.dateStart, 
                                                                to_date:   layout.value.search.dateEnd,
                                                                device_id: layout.value.search.deviceId,
                                                                code:      props.alarmId
                                                               });
         layout.value.loading  = false;
       }

       const initFilters = async () => {

        let currentDate     = new Date();
        let year            = currentDate.getFullYear();
        let month           = String(currentDate.getMonth() + 1).padStart(2, '0');
        let day             = String(currentDate.getDate()).padStart(2, '0'); 
        let localDateString = `${year}-${month}-${day}`;

          layout.value.search.dateStart = layout.value.search.dateEnd = layout.value.search.maxDate = localDateString //new Date().toISOString().substring(0, 10);
          console.log(layout.value.search.dateStart)
          const minDate                 = new Date();
          minDate.setDate(minDate.getDate() - 7); //1 semana de log
          layout.value.search.minDate   = minDate.toISOString().substring(0, 10);
          if (layout.value.app.devices?.devices) {
              const devices = layout.value.app.devices.devices;
              Object.keys(devices).forEach((deviceId) => {
                  if(!layout.value.search.deviceId)layout.value.search.deviceId = deviceId;
              });
          }
        }

        const jobDiscard= async () => {
          const alert = await alertController.create({
              header:  "¿Está seguro?",
              cssClass: 'friCustomAlert',
              buttons: [ 
                          {text: 'Cancelar', cssClass: 'alert-button-cancel', role: 'cancel' },  
                          {text: 'Confirmar',cssClass: 'alert-button-confirm', handler: async () => { 
                            let discardId          = layout.value.alarmEdit.id;
                            //layout.value.loading   = true;
                            layout.value.alarmBox  = false;
                            //layout.value.alarmEdit = null;
                            try{ await discardJob({ "jobId": discardId, "action": "discard", "uid": profile.uid });
                            }catch(e){ console.log("error discard:",e); }
                            setTimeout(async () => {
                              layout.value.alarmEdit = null;
                              await getAlarms();
                            }, 1000);
                          }
                          }
                        ],
          });
          await alert.present();
        }
        
        const filterLog = async () => {
          layout.value.loading = true;
          setTimeout(async () => {
            await getAlarms();
          }, 1000);
        }

       onMounted( async () => { 
         await initStats();
       });


      return { layout, user, profile, props, filterLog, jobDiscard };
    }
    
  })
  </script>
  <style>
    .line_box { display: flex;margin-bottom: 10px; }
    .text_circle {flex: 1;text-align: center;position: relative; }
    .text_circle:after { background-color: grey;bottom: 3.25em;content: "";display: block;height: 3px;position: absolute;right: 0;width: 50%;z-index: -1;}
    .line_box h4 {color: #189599;font-weight: bold;}
    .line_box h4,.line_box p {font-size: 12px;margin-bottom: 0;padding: 0 5px;}
    .timeline {margin: 40px 0;}
    .text_circle.sub:after {background-color: #189599;}
    .text_circle:not(:first-child):before {bottom: 3.25em;content: "";display: block;height: 3px; position: absolute;left: 0;width: 50%;z-index: -1;background-color: grey;}
    .text_circle:last-child:after {width: 0;}
    .circle {height: 100%;}
    .tvar {
        height: 100px;
        width: 100px;
        display: flex;
        position: relative;
        border-radius: 100%;
        top: -100px;
        margin: 3px auto;
        background-color: #f7f8f9;
    }
    .tvar div {
      padding: 30px 0 0 19px;
      text-align: center;
      font-size: 20px;
      line-height: 20px;
    }
    .tvarMin {
      height: 95px;
      width: 95px;
    }
    .tvarMin div { padding: 20px 0 0 10!important;}

  </style>