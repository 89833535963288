
  import { defineComponent, onMounted, ref } from "vue"; 
  import { useAuthStore } from '@/store';
  import router from "@/router";
  import { getStorage, ref as storageRef, getDownloadURL } from 'firebase/storage';
  import QRCode from "qrcode"; 

  export default defineComponent({
    name: 'apkPage',
    components: { },
    setup() {
      
      const authStore = useAuthStore();
      const { user, profile } = authStore;
      
      const layout = ref({ file: "/public/0.0.4.apk", apkStoragePath: null, qr: null })
  
      onMounted(async () => {
        const storage = getStorage();
        const apkRef = storageRef(storage, 'apk/' + layout.value.file);
        await getDownloadURL(apkRef).then((url) => {
            if(url){
                layout.value.apkStoragePath = url; 
                QRCode.toDataURL(url)
                    .then(qrUrl => { layout.value.qr = qrUrl})
                    .catch(error => {console.error(error)})
            }
        })
        .catch((error) => { console.error('Error:', error); });
  
      })
  
      return { authStore, user, profile, router, layout}
  
    },
  });
  