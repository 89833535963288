<template>
    <div>
        <div class="account-box is-form is-footerless" :style="isMobile ? 'border-bottom: none !important;' : ''">
            <template v-if="layout.app">
                <div class="form-head stuck-header" :class="isMobile ? 'py-0' : 'py-3'">
                    <div class="form-head-inner">
                        <div class="left"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5 ms-2' : 'fs-4'">{{  layout.app.name }} </h4></div>
                        <div class="inline-block align-top me-1 fs-6 mt-0" style="margin-top: 4px;">
                            <template v-if="layout.app.logo"><img :src="layout.app.logo" style="height: 50px;" /></template>
                            <template v-else>{{  layout.app.id }}</template>
                        </div>
                    </div>
                </div>
                <div :class="isMobile ? 'vh-100 friBackApp friBackWhite' : ''">
                    <div class="text-left ps-3 pe-4 " style="padding: 10px 0 10px 0;">

                        <!-- Gateways -->
                        <template v-if="profile.isClient || profile.isRoot">
                            <template v-if="profile.isClient && layout.gateways && layout.gateways.gateways && Object.keys(layout.gateways.gateways).length">
                                <div class="fs-5 py-3 friBack4 text-left ps-3 br-5 pb-4 mb-3 cursor-pointer" @click="layout.section != 'gateways' ? layout.section = 'gateways' : layout.section = false"> 
                                    <i v-if="layout.section == 'gateways'" class="bi bi-chevron-compact-down fs-2 align-middle pe-2"></i>
                                    <i v-else class="bi bi-chevron-compact-right fs-2 align-middle pe-2"></i>
                                    <span class="align-middle fw-normal">Alba Hub</span>
                                    <span class="badge friBack1 fs-6 text-end float-end me-3 mt-1 px-3">{{ Object.keys(layout.gateways.gateways).length }}</span>
                                </div>
                                <div class="text-left ps-2" :class="layout.section != 'gateways' ? 'hidden' : ''">
                                    <ion-row class="pt-2 pb-2" v-for="(gateway, index) in layout.gateways.gateways" :key="index">
                                        <ion-col class="text-center" style="max-width: 150px;"><i class="bi bi-router friOpacity3" style="font-size: 90px; line-height: 80px;"></i></ion-col>
                                        <ion-col class="w-50">
                                            <div class="fs-6 fw-bold">{{ gateway.name }}</div>
                                            <div class="text-xs3">{{ gateway.eui }}</div>
                                            <div v-if="gateway.updatedAt" class="text-xs">{{ layout.helper.getDateFormatFb(gateway.updatedAt) }}</div>
                                            <div>
                                                <span v-if="gateway.active" class="badge friBack1 text-xs mt-1 px-3">En línea</span>
                                            </div>
                                        </ion-col>
                                    </ion-row>
                                </div>
                            </template>
                        </template>

                        <!-- Devices -->
                        <div v-if="layout.devices && layout.devices.devices && Object.keys(layout.devices.devices).length" style="padding-bottom: 80px">
                            
                            <div class="fs-5 py-3 friBack4 text-left ps-3 br-5 pb-4 cursor-pointer mt-3" @click="layout.section != 'sensors' ? layout.section = 'sensors' : layout.section = false"> 
                                <i v-if="layout.section == 'devices'" class="bi bi-chevron-compact-down fs-2 align-middle pe-2"></i>
                                <i v-else class="bi bi-chevron-compact-right fs-2 align-middle pe-2"></i>
                                <span class="align-middle fw-normal">Equipos y Sensores</span>
                                <span class="badge friBack1 fs-6 text-end float-end me-3 mt-1 px-3">{{ Object.keys(layout.devices.devices).length }}</span>
                            </div>
                            <div class="my-3" style="padding-bottom: 80px;" v-if="layout.devices && layout.devices.devices && Object.keys(layout.devices.devices).length"  :class="layout.section != 'sensors' ? 'hidden' : ''">
                                <div class="flex justify-content-between" v-if="layout.devices.devices && Object.keys(layout.devices.devices).length">
                                    <div>
                                        <div v-for="(device, index) in layout.devices.devices" :key="index" class="friBack9 br-8 mb-2 " :class="!isMobile ? index % 2 == 0 ? 'w-100' : 'w-100' : ''"> 
                                            <div class="p-5" style="padding-bottom: 40px;">
                                                <div class="fs-6 text-end float-end text-dark">
                                                    <template v-if="device.active">
                                                        <i class="bi bi-sliders2-vertical  cursor-pointer" style="font-size: 25px;"  @click="$router.push({ path: '/sensor/'+ layout.app.id + '/' + device.deviceId, query: { section:  'dashboard' } })"></i>
                                                    </template>
                                                    <span v-else class="badge friBack4 text-dark fs-6 text-end float-end px-5 mb-5">Inactivo</span>
                                                    <div v-if="device?.status?.alarm?.status" class="text-end mt-1 ms-5"><i class="bi bi-exclamation-triangle friColor3" style="font-size: 60px;"></i></div>
                                                </div>
                                                <div class="fs-4 text-dark">{{ device.name }}</div>
                                                <div class="fs-6 text-dark mb-2" v-if="device.typeName">{{ device.typeName }}</div>
                                                <div v-if="device.loading">
                                                    <div class="text-center mt-5 p-5">
                                                        <div class="spinner-border friColor1" role="status">
                                                            <span class="visually-hidden">Cargando ...</span>
                                                        </div>
                                                        <div class="mt-3">Obteniendo nuevos datos</div>
                                                        <div>Espere por favor ...</div>
                                                    </div>
                                                </div>
                                                <template v-else>
                                                    <template v-if="device.status">
                                                        <template v-if="device?.status?.msg && device?.status?.msg.hasOwnProperty('TempC_SHT')"> 
                                                            <div class="mt-2 text-dark fw-bold text-left" v-if="device?.status?.msg" style="font-size: 35px; line-height: 35px;">
                                                                <span :class="{ 'friColor3': device.status.alarm && device.status.alarm.alarms && Object.values(device.status.alarm.alarms).some(alarm => alarm.paramSensor === 'TempC_SHT') }">
                                                                    {{ parseFloat(device.status.msg.TempC_SHT).toFixed(1) }}
                                                                    <span class="fs-3 align-middle">ºC</span>
                                                                </span>
                                                            </div>
                                                            <div class="fs-4 text-dark text-xs text-left" v-if="device.status.msg?.Hum_SHT">
                                                                Humedad: <span class="fw-bold ps-1" :class="{ 'friColor3': device.status.alarm && device.status.alarm.alarms && Object.values(device.status.alarm.alarms).some(alarm => alarm.paramSensor === 'Hum_SHT') }">{{ device.status.msg.Hum_SHT }}%</span>
                                                            </div>
                                                        </template>
                                                        <template v-if="device?.status?.msg && device?.status?.msg.hasOwnProperty('DOOR_OPEN_STATUS')"> 
                                                            <div v-if="device?.status?.msg?.DOOR_OPEN_STATUS" class="fs-1 friColor3 text-xs fw-bold" :class="isMobile ? 'fs-1' : 'fs-3'">ABIERTO</div>
                                                            <div v-else class="text-dark text-xs fw-bold" :class="isMobile ? 'fs-1' : 'fs-3'">CERRADO</div>
                                                        </template>
                                                        <template v-if="device?.status?.alarm?.status && device?.status?.alarm?.alarms">
                                                            <div class="fs-6 friColor3" v-for="(alarm, index) in device.status.alarm.alarms" :key="index">{{ alarm.title }}</div>
                                                        </template>
                                                        <div v-if="device.updatedAt" class="text-left">
                                                            <span class="fw-normal text-dark text-xs3">
                                                                Actualizado: {{ layout.helper.getDateFormatFb(device.status.date) }} <!--<i class="bi bi-arrow-clockwise  cursor-pointer ps-1 align-middle" style="font-size: 12px;" @click="refreshDevice(index)"></i>-->
                                                            </span>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        Todavía no hemos obtenido datos ...
                                                    </template>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </template>
            <template v-else>
                <div class="text-center w-100"  style="margin-top: 150px;">
                    <div class="fw-bold fs-1">Alba Hub</div>
                    <div class="mt-2">Espere por favor ...</div>
                    <div class="spinner-border friColor1 mt-5" role="status">
                    <span class="visually-hidden">Cargando ...</span>
                    </div>
                </div>
            </template>
            
            <!-- Device dashboard
            <div class="account-box is-form is-footerless" :style="isMobile ? 'border-bottom: none !important;' : ''">
                <div class="form-head stuck-header" :class="isMobile ? 'py-0' : 'py-3'" v-if="layout.device">
                    <div class="form-head-inner">
                        <div class="left" v-if="layout?.device?.title">
                            <h4 :class="isMobile ? 'fs-4 my-3' : 'fs-5'">{{ layout.device.title.toString().substring(0,20) }}<span v-if="layout.device.title.length > 20">...</span><span v-if="!isMobile" class="text-sm text-gray ps-1">/ Dispositivo</span></h4>
                        </div>
                        <div v-if="layout.device" class="right" :class="isMobile ? '' : 'mb-2'">
                            <div class="pt-1" :class="isMobile ? 'pe-4' : ''">
                                <template v-if="layout.device.active">
                                    <div v-if="layout.device.status" class="text-sm text-success fs-6"><i class="bi bi-plugin fs-5 align-middle pe-1"></i> Encendido</div>
                                    <div v-else class="text-sm text-danger fs-6"><i class="bi bi-plugin fs-6 align-middle pe-1"></i> Apagado</div>
                                </template>
                                <template v-else><div class="text-sm text-danger fs-5"><i class="bi bi-x-square-fill fs-6 align-middle" :class="isMobile ? '' : 'pe-1'"></i> Suspendido</div></template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-body pb-0 mb-0" :class="isMobile ? 'pt-0' : 'p-2'">
                    <template v-if="layout.device && layout.device.active">
                        <div v-if="layout.device.status">
                            <div v-if="layout.device.data.micro_status" class="text-center">
                                <div class="p-0 m-0 mb-0 w-100 mx-auto text-sm" v-if="layout.device.data.micro_status==4883"><img src="../../../assets/images/theme/loading.gif" style="width: 50px"> En proceso de desescarche manual</div>
                            </div>
                            <div class="row mx-4 mb-2" :class="isMobile ? 'mt-3' : ''">
                                <div class="text-left mt-2 mx-0 mb-1" v-if="layout.device.data"  :style="isMobile ? '' : 'padding-left: 20px !important'" >
                                    <div v-for="(s, index) in layout.device.data.sondas" :key="index" class="inline-block bg-light py-5 px-0 me-2 my-0 br-5 mb-2" style="width: 47%;"
                                        :style="!layout.device.sondas[index] || !layout.device.sondas[index].active || (layout.device.sondas[index] && !layout.device.sondas[index].active)? 'display: none !important' : index> 2 ? '' : ''">
                                        <div v-if="layout.device.sondas[index] && layout.device.sondas[index].active">
                                        <div class="align-top">
                                            <div class="text-center">
                                                <div class="fs-3 text-gray-dark">{{ layout.device.data.sondas[index] % 1 != 0 ? layout.device.data.sondas[index] : layout.device.data.sondas[index] / 10 }} {{ layout.device.data.unit }}</div>
                                                <span class="text-sm">{{layout.device.sondas[index].name }}</span>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="column is-12 text-center"><div class="text-sm">Registros obtenidos el {{ new Date().toLocaleString().replace(',', '') }}</div></div>
                            </div>
                            <div class="px-3">
                                <h1 class="text-center fs-6 mb-4 mt-3">Historial de registros</h1>
                                <div class="text-sm">Registro continuo:<span class="friColor1 mt-2 mx-auto ps-1" v-if="layout.device.data.config?.cron_active">Activo</span><span class="friColor3 mt-2 mx-auto ps-1" v-else>Inactivo</span></div>
                                <div class="text-sm">Servicio:<span class="friColor1 mt-2 mx-auto ps-1" v-if="layout.services && layout.services['cron.py']">Online</span><span class="friColor3 mt-2 mx-auto ps-1" v-else>Offline</span></div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                    <div class="text-center" :style="isMobile ? 'padding-bottom: 50%' : 'padding-bottom: 30px'">
                        <img src="../../../assets/images/theme/loading.gif" style="width: 150px;">
                        <div class="text-sm"><span class="fw-semibold">Adquiriendo información</span><br /> Espere por favor ...</div>
                        <div :class="isMobile ? 'mt-3' : 'mt-5'">
                            <LoadingAbout :isMobile="layout.isMobile" />
                        </div>
                    </div>
                    </template>
                </div>
            </div> -->    
        </div>
    </div>
  </template>
  <script>
  import { defineComponent, onMounted, ref, onUnmounted } from "vue"; 
  import { useAuthStore } from "@/store";
  import { getApp, getAppDevices, getAppGateways } from "../../../model/apps.js"; //, requestDevice
  import { IonRow, IonCol } from '@ionic/vue'; 
  import { helper as $h } from "../../../utils/helper.js";
  import { _firebase as $_firebase } from "../../../model/firebase";
  import { onSnapshot, collection } from "firebase/firestore"; 
  import { setDeviceSamplingIntervalTTN } from "../../../model/ttn.js";
  //import { Capacitor } from '@capacitor/core';
  //import { not as $not } from "../../../model/not";

  const db   =  $_firebase.firestore()

  export default defineComponent({
    name: 'DeviceScenes',
    props: {
        isMobile: Boolean,
        appId: String
     },
     components: { IonRow, IonCol },
    setup(props) {
  
       const authStore      = useAuthStore();
       const layout = ref({ 
                            appId:      null,
                            devices:    null,
                            gateways:   null,
                            services:   null,
                            helper:     $h,
                            section:    "sensors"
                          })

       const { user, profile } = authStore;

       const initDash = async () => {
         layout.value.app       = await getApp(layout.value.appId)
         layout.value.devices   = await getAppDevices(layout.value.appId)
         layout.value.gateways  = await getAppGateways(layout.value.appId)
       }

       /*
       const checkAlarms = async () => {
            if (layout.value.devices && layout.value.devices.devices && Object.keys(layout.value.devices.devices).length) {
                for (const [key, device] of Object.entries(layout.value.devices.devices)) {
                    if (device.status && device.status.alarm && device.status.alarm.status) {
                        if (Capacitor.isNativePlatform()) {
                            $not.sendLocalNot({
                                id: 1,
                                title:    "Alba Hub: "+ device.name,
                                message:  device.status.alarm.msg,
                                type:     'PF_CHECKOUT',
                                color:    "#009292",
                                data: { uid: user.uid }
                            })
                        } else {
                            $not.toastApp({ header: "Alba Hub: "+ device.name + " (" + key + ")", message: device.status.alarm.msg, position: 'top', icon: 'info', duration: 5000, animated: true})
                        }
                    }
                }
            }
        };
        */

       const refreshDevice = async (idxDevice) => {
        if (layout.value.devices.devices && Object.keys(layout.value.devices.devices).length && layout.value.devices.devices[idxDevice]) {
            layout.value.devices.devices[idxDevice].loading = true;
            let originalInterval = layout.value.devices.devices[idxDevice].interval;
            await setDeviceSamplingIntervalTTN(layout.value.appId, layout.value.devices.devices[idxDevice].deviceId, "1").then(async () => {
                console.log('Hemos modificando el intervalo de muestreo del dispositivo a 1 minuto para el dispositivo' + layout.value.devices.devices[idxDevice].deviceId );
                setTimeout(async () => {
                    await setDeviceSamplingIntervalTTN(layout.value.appId, layout.value.devices.devices[idxDevice].deviceId, originalInterval).then( async () => {
                        console.log('Hemos modificando el intervalo de muestreo del dispositivo a ' + originalInterval + ' minutos');
                        layout.value.devices.devices[idxDevice].loading = false;
                        await initDash(); 
                    }).catch((error) => {
                        console.log(error);
                        layout.value.devices.devices[idxDevice].loading = false;
                    });
                }, 120000);
            }).catch((error) => {
                console.log(error);
                layout.value.devices.devices[idxDevice].loading = false;
            });
        }  
       }

       onMounted( async () => { 
         layout.value.appId     = props.appId
         await initDash();
         await detectDevicesChanges();
         //await checkAlarms();
         //layout.value.services  = await requestDevice("https://"+profile.controllerId+".fricontrolalba.com", "device-actions/" + layout.value.deviceId + "/services");
         //layout.value.services  = await requestDevice("https://ielzattaiyoqhtfaewry.fricontrolalba.com", "device-actions/" + layout.value.deviceId + "/services");

       });
  
       const detectDevicesChanges = async () => {
          const collectionPath = "apps/" + props.appId + "/devices/";
          const collRef        = collection(db, collectionPath);
          const unsubscribeDetectDevicesChanges = onSnapshot(collRef, async (querySnapshot) => {
              querySnapshot.docChanges().forEach(async change => { 
                  if (change.type === "modified"){ await initDash(); }
              }); 
          }, (error) => { console.log("Error:", error); });
          onUnmounted(() => { unsubscribeDetectDevicesChanges(); });
        };

        return { layout, user, props, profile, refreshDevice };
    }
    
  })
  </script>