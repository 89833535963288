
    import { IonApp, IonRouterOutlet } from '@ionic/vue'; 
    import { useAuthStore } from '@/store';    
    import { defineComponent, onMounted, ref } from 'vue'; //, onUnmounted
    import { IonSlides, IonSlide, IonContent, getPlatforms } from '@ionic/vue';
    import { getStorage, ref as storageRef, getDownloadURL } from 'firebase/storage';
    import { getAppSettings } from "./model/api.js"; 
    import { updateUser } from "./model/apps.js";
    import packageJson from '../package.json';
    import QRCode from "qrcode"; 


    import { PushNotifications } from '@capacitor/push-notifications';
    //import { getMessaging, getToken } from 'firebase/messaging';

    //import { _firebase as $_firebase } from "./model/firebase";
    //import { onSnapshot, collection } from "firebase/firestore"; 

    //const db   =  $_firebase.firestore()

    //import { Capacitor } from '@capacitor/core';
    //import { not as $not } from "./model/not";
    //import { appsList, getAppMessagesByUser, setViewedMessage } from "./model/apps.js";

    export default defineComponent({
      name: 'App',
      components: {IonApp,IonRouterOutlet,IonSlides, IonSlide, IonContent},
      setup() {

        const authStore         = useAuthStore();
        const { user, profile } = authStore;
        const platforms = getPlatforms();
        const layout    = ref({ 
                               isMobile:           false,
                               lastFile:           null, 
                               packageJsonVersion: null,
                               apkSettings:        null,
                               apkStoragePath:     null, 
                               qr:                 null,
                               userApps:           null, 
                               messages:           null
                              });

        const getAppUpdate = async () => {
            const storage  = getStorage();
            const apkRef   = storageRef(storage, 'apk/public/' + layout.value.lastFile + '.apk');
            await getDownloadURL(apkRef).then((url) => {
                if(url){
                    layout.value.apkStoragePath = url; 
                    QRCode.toDataURL(url)
                        .then(qrUrl => { layout.value.qr = qrUrl})
                        .catch(error => {console.error(error)})
                }
            })
            .catch((error) => { console.error('Error:', error); });
        }

        /*interface Message { title: string; notes: string; color: string; deviceId: string; deviceName: string; description: string; AlarmId: string; }

        const checkAlarms = async (appId) => {
          layout.value.messages = await getAppMessagesByUser(appId, profile.uid)
          if(layout.value.messages?.success && layout.value.messages?.messages && Object.keys(layout.value.messages.messages).length){
            let msgId = 1;
            for (const [messageId, message] of Object.entries(layout.value.messages.messages)) {
              const typedMessage = message as Message;
              if (Capacitor.isNativePlatform()) {
                  let msg = typedMessage.description;
                  if(typedMessage.notes)msg = msg + ". " + typedMessage.notes + ".";
                  if(typedMessage.deviceName)typedMessage.title = typedMessage.deviceName + ": "+ typedMessage.title;
                  if(!typedMessage.color)typedMessage.color = "#009292";
                  $not.sendLocalNot({
                      id:        msgId,
                      title:     typedMessage.title,
                      message:   msg,
                      type:      'ALBA_ALARM',
                      color:     typedMessage.color,
                      data:     { profile: profile.uid, alarmId: typedMessage.AlarmId.toString() } //notId: msgId, messageId: messageId, deviceId: typedMessage.deviceId,  
                  })
              }
              await setViewedMessage(appId, messageId)
              msgId++;
            }
          }
        }

        const messaging = async () => {
            if(layout.value.isMobile && profile){ 
              layout.value.userApps = await appsList({ byUser: profile });
              if(layout.value.userApps?.apps && Object.keys(layout.value.userApps.apps).length){
                for (const [kapp] of Object.entries(layout.value.userApps.apps)) {
                  await checkAlarms(kapp);
                  const collectionPath = "apps/" + kapp + "/messaging/";
                  const collRef        = collection(db, collectionPath);
                  const unsubscribeDetectAlarmsMessaging = onSnapshot(collRef, async (querySnapshot) => {
                      querySnapshot.docChanges().forEach(async change => {  if (change.type === "modified" || change.type === "added") { await checkAlarms(kapp); } });
                  }, (error) => { console.log("Error:", error); });
                  onUnmounted(() => { unsubscribeDetectAlarmsMessaging(); });
                }
              }
            }
          };*/

        const initMessaging = async () => {
           try {
            PushNotifications.requestPermissions().then(result => {
                if (result.receive === 'granted') {
                  PushNotifications.register();
                    PushNotifications.addListener('registration', async (token) => {
                           await updateUser({ id: profile.uid, messageToken: token.value })
                        }
                    );
                    PushNotifications.addListener('pushNotificationReceived',
                        (notification) => {
                            console.log('Notificación recibida: ' + notification);
                        }
                    );

                } else {
                  console.log('Permission not granted for push notifications');
                }
            }).catch(err => console.log('Error requesting push notifications permission:', err));
          } catch (error) {
            console.error('Error:', error);
          }
        }

        onMounted( async () => { 
          if(profile){
            if(platforms && !platforms.includes('desktop'))layout.value.isMobile = true; 
            layout.value.packageJsonVersion = packageJson.version;
            layout.value.apkSettings        = await getAppSettings("apk");
            layout.value.lastFile           = layout.value.apkSettings.settings.public.version
            await getAppUpdate();
            //await messaging();
            if(layout.value.isMobile)await initMessaging();
          }
        });

        return { layout, user, profile };
      }
    });
    /*window.addEventListener('touchmove', function (e) {
        e.preventDefault();
    }, { passive: false });*/
