
  import { defineComponent, onMounted, ref } from "vue"; 
  import { IonInput, IonTextarea, IonImg } from '@ionic/vue';
  import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
  import { helper as $h } from "../../utils/helper";
  import { not as $not } from "../../model/not";
  import { sms as $sms } from "../../model/sms";
  import { geo as $geo } from "../../model/geo";
  import { useAuthStore } from '@/store';
  
  export default defineComponent({
    name: 'androidTools',
    props: {
        isMobile: Boolean
     },
    components: { IonInput, IonTextarea, IonImg,  },
    setup() {
       
      const authStore      = useAuthStore();
      const { logoutUser, user, profile } = authStore;
  
      const loc = ref<{
        lat: null | number;
        long: null | number;
        date: null | number;
      }>({
        lat: null,
        long: null,
        date: null
      });
  
      const smsOptions = ref<{
        num: null | string;
        msg: null | string;
      }>({
        num: null,
        msg: "",
      });
  
      const photo = ref<{
        src: null | string;
      }>({
        src: null,
      });
  
  
       const getCurrentPosition = async () => {
        loc.value = await $geo.getCurrentPosition()
        if(loc.value.lat && loc.value.long)
          smsOptions.value.msg = "My coordinates on "+$h.unixDate(loc.value.date)+" are latitude: "+loc.value.lat+" and longitude: "+loc.value.long 
      };
  
      const sendSms = async () => {
        if(smsOptions.value.num && smsOptions.value.msg){
           await $sms.sendSms({ num: smsOptions.value.num, msg: smsOptions.value.msg})
            $not.alertApp({ header: 'SMS', subHeader: 'Geolocation sent successfully', buttons: ['OK']})
        }else{
          $not.alertApp({
            header:    'SMS',
            subHeader: 'Required fields',
            message:   'Insert your phone number and use the geolocation button',
            buttons:   ['OK']
          })
         }
      };
  
      const shareApp = async () => {  
        $not.shareApp({
          title: 'Pingfine',
          text: 'Everything under control',
          url: 'http://www.pingfine.com/',
          dialogTitle: 'Share',
        })
      };
  
      const toastApp = async () => {  
       $not.toastApp({ header: 'Toast Alert', message: 'Content toast', position: 'top', icon: 'info', duration: 2000, animated: true})
      };
  
      const alertLocal = async () => {  
        $not.sendLocalNot({
            id: 1,
            title: "Alba: "+ "Alta temperatura en cámara",
            message: "Puede ser debido a una carga excesiva de la cámara con producto caliente, demasiadas aperturas de puerta o mal funcionamiento del equipo.",
            type: 'PF_CHECKOUT',
            color: "#009292",
            /*
            title: "Pingfine Checkout: "+ "I'm fine mom!",
            message: "Reminder every 24 hours with active 2 actions and 3 notifications",
            type: 'PF_CHECKOUT',
            color: "#00FF00",*/
            data: { uid: user.uid }
          })
      };    
  
      const alertApp = async () => {
        $not.alertApp({
          header: 'Alert',
          subHeader: 'This is an alert!',
          message: 'Important message',
          buttons: ['OK']
        })
      };
  
      const dialogApp = async () => {
        let responseDialog = await $not.dialogApp({ type: "confirm", title: 'Dialog', message: "Do you like the app?"})
        $not.alertApp({ header: 'Dialog', subHeader: responseDialog && responseDialog.value ? 'Correct answer!' : 'We will continue working'})
      };
  
      const takePicture = async () => {
        const image = await Camera.getPhoto({
          quality: 100,
          allowEditing: false,
          source:  CameraSource.Camera,
          resultType: CameraResultType.Uri
        });
  
        // image.webPath will contain a path that can be set as an image src.
        // You can access the original file using image.path, which can be
        // passed to the Filesystem API to read the raw data of the image,
        // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
        var imageUrl = image.webPath;
        console.log(image)
        // Can be set to the src of an image now
        photo.value.src = imageUrl;
      };
  
       onMounted( async () => { 
          
       });
  
      return { getCurrentPosition, sendSms, alertApp , toastApp, alertLocal, takePicture,
              dialogApp, shareApp,  loc, smsOptions, photo, logoutUser, user, profile 
            };
    }
    
  })
  