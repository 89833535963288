
import { defineComponent, onMounted, ref } from "vue"; 
import { useAuthStore, useImageStore } from '@/store';
import router from "@/router";
import { not as $not } from "../../model/not";
import { helper as $h } from "../../utils/helper.js";


//import { _firebase as $_firebase } from "../../model/firebase";
//import { onSnapshot, query, collectionGroup, where } from "firebase/firestore"; 
//const db   =  $_firebase.firestore()

export default defineComponent({
  name: 'albaAppPage',
  props: { 
      isMobile: Boolean,
  },
  components: {  },
  setup() {
     
    const authStore      = useAuthStore();
    const imageStore     = useImageStore();
    const { logoutUser, user, profile } = authStore;
    const layout         = ref({
                                  apkSettings:        null,
                                  packageJsonVersion: null,
                                  loading:            true,
                                  subscriptions:      false,
                                  lastFile:           null, 
                                  apkStoragePath:     null, 
                                  qr:                 null,
                                  helper:             $h,
                              })

    const logoutApp = async () => {
      await logoutUser();
      router.replace("/login");
    }

    const saveProfile = async () => {
      $not.toastApp({ header: 'Perfil', message: 'Perfil actualizado correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'primary', cssClass: 'text-white mt-2' })
    }

    onMounted( async () => { 
      if(router.currentRoute.value.path == '/profile/logout')await logoutApp();
     });

    return { logoutApp, user, profile, imageStore, saveProfile, layout };
  }
  
})
