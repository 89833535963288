
import { useAuthStore } from "@/store"
import { IonLabel, IonInput, IonButton, IonItem } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRouter } from "vue-router";
import { not as $not } from "../../model/not";
export default defineComponent({
    name: 'CreateAccountPage',
    components: { IonLabel, IonInput, IonButton, IonItem },
    setup() {
        const store = useAuthStore()
        const router = useRouter()
        const email = ref('');
        const password = ref('');
        const first = ref('');
        const last = ref('');

        /**
         * 
         */
        const doCreateAccount = async () => {
            await store.createAccount(email.value, password.value, first.value, last.value).then( (() => {
                if(store.error){
                    $not.toastApp({ header: 'Create Account', message: store.error, position: 'top', icon: 'info', duration: 2000, animated: true })
                }else{
                    router.replace("/home/");
                }
            }));
            
        }
        return { email, password, doCreateAccount, store, first, last, router }
    }
});
