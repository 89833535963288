
import { defineComponent, onMounted, ref } from 'vue'; //, onUnmounted
//import { onSnapshot, collection } from "firebase/firestore"; 
import { IonPage, IonContent } from '@ionic/vue'; 
import { useAuthStore  } from '@/store';
import { getPlatforms } from '@ionic/vue';

//import TopMenu from '@/components/menu/top.vue';
import Login from '@/components/pages/login.vue';
import UserProfile from '@/components/pages/profile.vue';
import Dashboard from '@/components/pages/dashboard.vue';
import CreateUser from '@/components/pages/create.vue';
import PingFine from '@/components/pages/pingfine.vue';
import App from '@/components/pages/app.vue';
import Device from '@/components/pages/device.vue';
import Devices from '@/components/pages/devices.vue';
import Remote from '@/components/pages/remote.vue';
import Api from '@/components/pages/api.vue';
import Controller from '@/components/pages/controller.vue';
import deviceDash from '@/components/pages/deviceDash.vue';
//import Gateway from '@/components/pages/gateway.vue';
import Hub from '@/components/pages/hub.vue';
import Sensor from '@/components/pages/sensor.vue';
import Apk from '@/components/pages/apk.vue';
import Apps from '@/components/pages/apps.vue';
import Test from '@/components/pages/test.vue';
import Clients from '@/components/pages/clients.vue';
import Client from '@/components/pages/client.vue';
import Alarm from '@/components/pages/alarm.vue';
import User from '@/components/pages/user.vue';
import Jobs from '@/components/pages/jobs.vue';
import Alba from '@/components/pages/alba.vue';
import Alert from '@/components/pages/alert.vue';
import Sem from '@/components/pages/sem.vue';
import Site from '@/components/pages/site.vue';
import Plan from '@/components/pages/plan.vue';

import router from "@/router";

import InactiveUser from '@/components/pages/utils/inactive.vue';


export default defineComponent({
  name: 'ExploreContainer',
  props: {
    tab:       String,
    title:     String,
    topmenu:   Boolean,
    contentId: String,
    deviceId:  String,
    gatewayId:  String,
    onlyDesktop: Boolean,
  },
  components: { Dashboard, IonPage, IonContent, UserProfile, Login, CreateUser, PingFine, App, Device, Devices, Remote, Api, Controller, deviceDash, Hub, Sensor, Apk, Apps, Test, Clients, Client, Alarm, User, Jobs, InactiveUser, 
                Alba, Alert, Sem, Site, Plan }, //Pingfine   TopMenu
  setup() {
  
    const authStore      = useAuthStore();
    const { logoutUser, user, profile } = authStore;
    const platforms      = getPlatforms();
    const layout         = ref({
                                isMobile: false,
                                messages: null,
                                userApps: null,
                              }) 

    const logoutApp = async () => {
      await logoutUser();
      router.replace("/login");
    }

    onMounted( async () => { 
      if(platforms && !platforms.includes('desktop')) layout.value.isMobile = true; 
    });

    return { authStore, user, profile, logoutApp, layout };
  },
  methods: { },
});
/*
onIonViewDidEnter(() => { console.log('A entrado en Tab 1'); });
onIonViewDidLeave(() => { console.log('A salido de Tab 1'); });
onIonViewWillEnter(() => { console.log('A punto de entrar a Page Tab 1'); });
onIonViewWillLeave(() => { console.log('A punto de salir de Page Tab 1'); });
*/
