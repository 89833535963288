<template>
    <div>
        <div class="column is-12" v-if="layout.loading">
            <div class="text-center mt-5">
              <img src="../../../assets/images/theme/loading.gif" style="width: 150px">
              <div>Adquiriendo datos del dispositivo<br /> espere por favor ...</div>
              <div :class="isMobile ? 'mt-3' : 'mt-5'">
                <LoadingAbout :isMobile="layout.isMobile" />
              </div>
            </div>
        </div>
        <div v-else>
            <div class="columns is-multiline" style="max-width: 700px; margin: 0 auto;">
            <div class="column is-12 mb-3 text-left" v-if="!isMobile">
            <span class="friColor1 mt-0 text-xs cursor-pointer float-right" @click="init()"><i class="bi bi-arrow-clockwise fs-3 me-2"></i></span>
            </div>
            <div class="column is-12 mb-3" v-if="layout.connect.device.alarmActive && layout.connect.device.alarmActive.length">
                <div class="w-100 p-2 bg-danger text-white mb-1 br-5" v-for="alarm in layout.connect.device.alarmActive" :key="alarm">
                <i class="bi bi-exclamation-circle-fill fs-4 align-middle px-2"></i> {{ alarm }}
                </div>
            </div>
            <div :class="isMobile ? 'col-12 mt-5' : 'col-6'" style="position: relative;">
            <div class="border border-2 border rounded p-3" :class="isMobile ? 'remote-control-mobile' : 'remote-control'"
                v-if="layout.connect.device" style="justify-content: space-between;">
                <div class="text-center"><i class="bi bi-wifi"></i></div>
                <div class="led rounded-circle" :class="layout.led && !layout.ledStatus ? 'bg-danger' : 'bg-dark'"></div>
                <div class="text-center rounded p-3 mt-3 border" style="background-color: #f7f8f9;" v-if="layout.connect.device && layout.connect.device.data && layout.connect.device.data.sondas">
                    <template v-if="layout.connect.device.started">
                    <h2 class="m-0 fs-1">{{ layout.connect.device.data.sondas[1]}} <span class="text-sm">{{ layout.connect.device.data.unit }}</span></h2>
                    <span class="text-xs">SONDA 1</span>
                    </template>
                    <template v-else>
                    <h2 class="m-0">OFF</h2>
                    </template>
                </div>
                <div v-else>loading</div>
                <!--
                {{ layout.connect.device.started }}
                {{ layout.connect.device.data.sondas }}
                -->
                <div class="text-center" v-if="layout.connect.device && layout.connect.device.data && layout.connect.device.started">
                <div class="text-xs pt-3 py-2">PUNTO DE AJUSTE</div>
                <div class="fs-5"><i class="bi bi-thermometer"></i> {{ layout.connect.device.data.set_point /10 }}  {{ layout.connect.device.data.unit }}</div>
                <div class="friRemoteSet" :class="layout.led && !layout.ledStatus ? 'friDisabledBack' : ''"  @click="!layout.led ? upPoint() : false"><img src="../../../assets/images/theme/icon/zoomIn.png" /></div>
                <div class="friRemoteSet" :class="layout.led && !layout.ledStatus ? 'friDisabledBack' : ''"  @click="!layout.led ? downPoint() : false" v-if="layout.connect.device.data.set_point"><img src="../../../assets/images/theme/icon/zoomOut.png" /></div>
                </div>
                <div  style="display: block;position: absolute;bottom: 30px;">
                <button :id="!isMobile ? 'friRemoteOnBtn' : 'friRemoteOnBtn-mobile'" class="btn w-100 mt-5 py-3 px-0" v-if="layout.connect.device.started" @click="!layout.led ? stop(): false " 
                    :class="layout.led && !layout.ledStatus ? 'friDisabledBack' : 'btn-danger'">Apagar</button>
                <button :id="!isMobile ? 'friRemoteOffBtn' : 'friRemoteOffBtn-mobile'" v-else class="btn btn-success w-100 mt-5 py-3 px-0" @click="start()">Encender</button>
                </div>
            </div>
            <div class="text-xs mt-2"><span id="lastDateStatus">{{ new Date().toLocaleString() }}</span></div>
            </div>
            <div class="col-6" v-if="!isMobile && layout.connect.device && layout.connect.device.data">
            <div class="border d-flex align-items-center justify-content-between px-3 py-2 cursor-pointer" @click="!layout.led ? defrost(): false" v-if="layout.connect.device.data.micro_status!=4883" :class="layout.led && !layout.ledStatus ? 'friDisabledBack' : ''">
                <p class="mb-0">Desescarche manual </p><i class="bi bi-play-fill friColor1 fs-2"></i>
            </div>
            <div class="border d-flex align-items-center justify-content-between px-3 py-2" v-else>
                <p class="mb-0 friColor1">Desescarche en curso ... </p><img src="../../../assets/images/theme/loading.gif" style="width: 50px">
            </div>
            <div class="border d-flex align-items-center justify-content-between px-3 py-2 mt-2 cursor-pointer" @click="!layout.led ? layout.connect.device && layout.connect.device.data.led_room ? ledOff(): ledOn(): false"
                :class="layout.led && !layout.ledStatus ? 'friDisabledBack' : ''">
                <p class="mb-0">Luz cámara</p>
                <i class="bi bi-lightbulb-fill friColor2 fs-4 me-1" v-if="layout.connect.device.data.led_room" ></i>
                <i class="bi bi-lightbulb-off friColor1 fs-4 me-1" v-else></i>
            </div>

            <div class="border d-flex align-items-center justify-content-between px-3 py-2 mt-2 friDisabledBack">
                <p class="mb-0">Modo ahorro energético</p><i class="bi bi-toggle-off fs-3" style="color: #999;"></i>
            </div>

            </div>
            </div>
        </div>
        <div v-if="layout?.needReload" id="friReloadMsgBox">
            <div id="friReloadMsg" class="cursor-pointer pb-5" style="padding: 20px 0 !important;" @click="init()">
            <div class="text-white">La sesión de control remoto ha expirado</div>
            <button class="btn friBack2 mt-3 py-2 px-5 text-black">Actualizar</button>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, ref, onMounted  } from 'vue';
import { getDevice, requestDevice, parseAlarms } from "../../../model/alba.js";
import { useAuthStore } from '@/store';
import router from "@/router";

import LoadingAbout from '@/components/pages/loading/about.vue';

export default defineComponent({
name: 'RemotePage',
props: {
  isMobile: Boolean,
  deviceId: String
},
components: {  LoadingAbout },
setup(props) {
    const authStore         = useAuthStore();
    const { user, profile } = authStore;
    const layout            = ref({ 
                                loading:   true,
                                led:       false,
                                ledStatus: false,
                                intervalStatus: false,
                                intervalMax:    0,
                                deviceId: props.deviceId ? props.deviceId : "FEI9eQt3Su15P1g2ldk9",
                                connect: {
                                  device:  false,
                                  devices: false,
                                } 
                            });

  const loadSection = async () => {
    layout.value.loading = true
    layout.value.connect.device = false
    layout.value.connect.device = await getDevice(layout.value.deviceId)
    /*if(layout.value.connect.device && layout.value.connect.device && layout.value.connect.device.data && layout.value.connect.device.data.sondas)layout.value.loading = false
    else if(layout.value.connect.device.status) await loadSection()*/
    layout.value.led = false
    resetStatusInterval()
  }  

  const checkSetPointStatus = async () => {
    if(document.getElementById('friPopMsgBox'))document.getElementById('friPopMsgBox').classList.remove('hidden')
    if(document.getElementById('friPopMsg'))document.getElementById('friPopMsg').innerHTML = "Iniciando sesión de control remoto ..."
    layout.value.intervalStatus = setInterval(async () => {
    //if(!layout.value.led){
        if(document.getElementById('friPopMsgBox'))document.getElementById('friPopMsgBox').classList.add('hidden')
        layout.value.intervalMax += 6;
        if (layout.value.intervalMax >= 120) { await closeSession()
        } else {
        layout.value.led       = true
        layout.value.ledStatus = true
        await getMicroStatus()
        layout.value.led       = false
        layout.value.ledStatus = false
        }
    //}
    }, 6000);
  }

  const resetStatusInterval = () => { layout.value.intervalMax = 0 }

  const upPoint = async () => {
    if(!layout.value.led){
      layout.value.led = true
      let setPoint = layout.value.connect.device.data.set_point;
      if (Number.isInteger(setPoint) && (typeof setPoint === 'number' || /^\d+$/.test(setPoint))) {
        let oldPoint = parseInt(setPoint);
        if (!isNaN(oldPoint)) {
          let newPoint = parseInt(oldPoint + 1);
          if(!isNaN(newPoint) && (typeof newPoint === 'number' || /^\d+$/.test(newPoint))){
            await requestDevice(layout.value.connect.device, "micro.py/832/"+newPoint);
            await getMicroStatus()
          }
        }
      }
      resetStatusInterval()
      layout.value.led = false
    }
  }

  const downPoint = async () => {
    if(!layout.value.led){
      layout.value.led = true
      let setPoint = layout.value.connect.device.data.set_point;
      if (Number.isInteger(setPoint) && (typeof setPoint === 'number' || /^\d+$/.test(setPoint))) {
        let oldPoint = parseInt(setPoint);
        if (!isNaN(oldPoint)) {
          let newPoint = parseInt(oldPoint - 1);
          if(!isNaN(newPoint) && (typeof newPoint === 'number' || /^\d+$/.test(newPoint))){
            await requestDevice(layout.value.connect.device, "micro.py/832/"+newPoint);
            await getMicroStatus()
          }
        }
      }
      resetStatusInterval()
      layout.value.led = false
    }
  }

  const start = async () => {
    if(!layout.value.led){
      layout.value.led = true
      await requestDevice(layout.value.connect.device, "micro.py/1280/4369");
      loadSection()
    }
  }  

  const stop = async () => {
    if(!layout.value.led){
      layout.value.led = true
      await requestDevice(layout.value.connect.device, "micro.py/1280/4113");
      layout.value.loading = false 
      loadSection()
    }
  }  

  const defrost = async () => {
    if(!layout.value.led){
      layout.value.led = true
      await requestDevice(layout.value.connect.device, "micro.py/1280/4883");
      loadSection()
    }
  }  

  const ledOn = async () => {
    if(!layout.value.led){
      layout.value.led = true
      await requestDevice(layout.value.connect.device, "micro.py/1281/32896");
      loadSection()
    }
  }

  const ledOff = async () => {
    if(!layout.value.led){
      layout.value.led = true
      await requestDevice(layout.value.connect.device, "micro.py/1280/4113");
      await requestDevice(layout.value.connect.device, "micro.py/1280/4369");
      loadSection()
    }
  }
  
  const init = async () => {
    layout.value.needReload = false
    await loadSection()
    await checkSetPointStatus() 
  }

  const closeSession = async () => {
    if(document.getElementById('friPopMsgBox'))document.getElementById('friPopMsgBox').classList.remove('hidden')
    if(document.getElementById('friPopMsg'))document.getElementById('friPopMsg').innerHTML = "Cerrando sesión de control remoto ..."
    clearInterval(layout.value.intervalStatus);
    setTimeout(() => {
      if(document.getElementById('friPopMsgBox'))document.getElementById('friPopMsgBox').classList.add('hidden')
      router.push('/home/')
      setTimeout(() => {
        layout.value.needReload = true
      }, 500);
    }, 2000);
  }

  const getMicroStatus = async () => {
    let microStatus = await requestDevice(layout.value.connect.device, "micro.py/get")
    if (typeof microStatus === 'object' && microStatus !== null) {
      layout.value.loading = false
      if(microStatus.set_point != null && Number.isInteger(microStatus.set_point) && (typeof microStatus.set_point === 'number' || /^\d+$/.test(microStatus.set_point))) layout.value.connect.device.data.set_point = microStatus.set_point
      if(microStatus.sondas)if(microStatus.sondas[1] && microStatus.sondas[1] != null){
        if(layout.value.connect.device?.data?.sondas)layout.value.connect.device.data.sondas[1] = microStatus.sondas[1] / 10
      }
      if(Number.isInteger(microStatus.led_room) && microStatus.led_room != null)layout.value.connect.device.data.led_room = microStatus.led_room
      if(Number.isInteger(microStatus.alarms) && microStatus.alarms != null){
        layout.value.connect.device.data.alarms = microStatus.alarms
        layout.value.connect.device.alarmActive = await parseAlarms(microStatus.alarms)
      }
      if(microStatus.micro_status != null){
        layout.value.connect.device.data.micro_status = microStatus.micro_status
        if(microStatus.micro_status!=4112) layout.value.connect.device.started = true;
        else layout.value.connect.device.started = false;
        if(document.getElementById('lastDateStatus'))document.getElementById('lastDateStatus').innerHTML = new Date().toLocaleString().replace(',', '')
      }
    }
  }

  onMounted(async () => {
    await init()
  });

  return {user, profile, loadSection, start, stop, defrost, ledOn, ledOff, checkSetPointStatus, init, upPoint, downPoint, layout  };
},
methods: {},
});
</script>
<style>
    .remote-control {width: 200px;height: 460px;margin: 0 auto;}
    .remote-control-mobile {width: 250px;height: 440px;margin: 0 auto;}
    .led {width: 10px;height: 10px;margin: 0 auto;}
    .friRemoteSet{z-index: 9;width: 60px !important;border: 1px solid #ccc;padding: 5px;border-radius: 10px; background-color: #fff;box-shadow: 0 0 10px rgba(0,0,0,0.1); margin: 15px auto 0 auto;}
    .friRemoteSet img{ width: 30px; margin: 5px 4px;cursor: pointer; opacity: 0.5; }
    .friRemoteSet img:hover{opacity: 1; }
    #friRemoteOnBtn{width: 200px !important; bottom: 30px !important; margin-bottom: 15px !important}
    #friRemoteOnBtn-mobile{width: 250px !important; bottom: 30px !important; margin-bottom: 15px !important}
    #friRemoteOffBtn{width: 200px !important; bottom: 30px !important; margin-bottom: 15px !important}
    #friRemoteOffBtn-mobile{width: 250px !important; bottom: 30px !important; margin-bottom: 15px !important}
</style>