<template>
    <div>
        <div class="account-box is-form is-footerless px-0 mx-0" :style="isMobile ? 'border-bottom: none !important;' : ''">
            <div class="form-head stuck-header" :class="isMobile ? 'py-0' : 'py-3'" v-if="layout.app">
                <div class="form-head-inner">
                    <div class="left"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5' : 'fs-4'">{{  layout.app.name }} </h4></div>
                    <div class="inline-block align-top fs-6 mt-0" style="margin-top: 4px;">
                    <template v-if="layout.app.logo"><img :src="layout.app.logo" style="height: 50px;" /></template>
                    <template v-else>{{  layout.app.id }}</template>
                </div>
                </div>
            </div>
            <div class="pt-2 pb-2 fs-5 friBack1 text-white text-center ps-3" v-if="layout.device?.device && layout.device?.device?.typeName"><span class="align-middle">{{ layout.device?.device.typeName }}</span></div>
            <div v-if="layout.device?.device" class="text-center fs-2 mt-5">{{ layout.device.device.name }}</div>
            <div v-if="layout.device?.device" class="text-center fs-6">Seleccione una imagen para el dispositivo</div>
            <div class="m-5" v-if="layout.app">
                <ion-list class="p-0 mt-0">
                    <div class="text-center">
                        <img v-if="layout?.device?.device" :src="layout.device.device.logo" class="mt-5" style="width: 250px" />
                        <div class="mt-5">
                            <IonButton fill="none" class="mt-1 px-0 mx-0" style=" border: none !important">
                                <input id="file" hidden type="file" accept="image/*" @change="previewDeviceLogo($event)" />
                                <label htmlFor="file"><i class="bi bi-camera-fill friOpacity4" style="font-size: 50px;"></i></label>
                            </IonButton>
                        </div>
                    </div>
                    <div :style="isMobile ? 'margin-top: 50px; margin-bottom: 100px;' : 'margin-top: 60px; margin-bottom: 50px;'" v-if="layout.device && layout.device?.device?.logo">
                        <div class="d-flex justify-content-center align-items-center fs-5">
                            <div class="friBtn1 text-white px-5 br-5 cursor-pointer"  @click="saveImage()">GUARDAR</div>
                        </div>
                    </div>
                </ion-list>
            </div>
            <div v-else class="text-center" style="margin: 100px 0">
                <div class="spinner-border text-primary friColor1 text-center" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    </div>
 </template>
 <script>
 import { defineComponent, onMounted, ref, watch } from "vue";  //, onUnmounted
 import { useAuthStore } from "@/store";
 import { add } from 'ionicons/icons';
 import router from "@/router";
 import { useRoute } from 'vue-router'
 import { getApp, getAppDevice, updateDevice } from "../../../model/apps.js"; 
 import { IonList,  IonButton } from '@ionic/vue'; 
 import { not as $not } from "../../../model/not";

 export default defineComponent({
   name: 'semImages',
   props: {
       isMobile: Boolean,
       appId: String,
       deviceId: String,
    },
   components: { IonList, IonButton },
   setup(props) {
 
      const authStore      = useAuthStore();
      const route          = useRoute()
      const layout         = ref({ 
                               appId:       null,
                               deviceId:    null,
                               device:      null,
                           }) 

      const { user, profile } = authStore;

      onMounted( async () => { 
        layout.value.appId     = router.currentRoute.value.params.appId;
        layout.value.deviceId  = router.currentRoute.value.params.deviceId;
        layout.value.app       = await getApp(layout.value.appId)
        if(layout.value.deviceId)    
            layout.value.device     = await getAppDevice(layout.value.appId, layout.value.deviceId)
      });

      const saveImage = async () => {
        let validate = true
        //let required = ['image']
        if(!layout.value.device.device.logo)validate = false
        //required.forEach((field) => {  if(!layout.value.device.device[field])validate = false })
        if(validate){ await updateDevice(layout.value.device.device).then(async () => { $not.toastApp({ message: 'Se ha actualizado el dispositivo correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, cssClass: 'text-white mt-2 friToast1'}) })     
        }else $not.toastApp({ message: 'Debe rellenar los parámetros requeridos', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
       }
       
       const previewDeviceLogo = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          if(layout.value.device.device)layout.value.device.device.logo = reader.result.toString();
        };
        reader.readAsDataURL(file);
      };

       watch(() => route.query, async (query) => {
            if (query){
                layout.value.query     = query; 
                layout.value.section   = query.section;
                layout.value.appId     = router.currentRoute.value.params.appId;
                layout.value.deviceId  = router.currentRoute.value.params.deviceId;
            }
        });

       return { layout, user, profile, add, props, saveImage, previewDeviceLogo };
   }
   
 })
 </script>