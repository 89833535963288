<template>
    <div class="account-box is-form is-footerless" :style="isMobile ? 'border-bottom: none !important;' : ''">
        <template v-if="layout.app">
            <div class="form-head stuck-header" :class="isMobile ? 'py-0' : 'py-3'">
                <div class="form-head-inner">
                    <div class="left"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5 ms-2' : 'fs-4'">{{  layout.app.name  }} </h4></div>
                    <div class="inline-block align-top fs-6 mt-0" style="margin-top: 4px;">
                        <template v-if="layout.app.logo"><img :src="layout.app.logo" style="height: 50px;" /></template>
                        <template v-else>{{  layout.app.id }}</template>
                    </div>
                </div>
            </div>
            <div class="pt-2 pb-2 fs-5 friBack1 text-white text-center ps-3"><span class="align-middle" v-if="layout.device && layout.device.name">{{ layout.device.name }}</span></div>
            <div class="pt-2 pb-2 fs-6 friBack4 text-center ps-3"><span class="align-middle" v-if="layout.device && layout.device.typeName">{{ layout.device.typeName }}</span></div>

            <div class="text-left" v-if="layout.alarmEdit">

                <div class="d-flex align-items-center" :class="layout.isInCourse ? 'friBack12' : 'friBack1'">
                    <div class="me-4 ms-3 pt-2" align-top><i class="bi bi-exclamation-triangle text-white ps-3" style="font-size: 55px;"></i></div>
                    <div class="align-top me-4">
                        <div class="fs-3 fw-normal text-white" v-if="isMobile">{{ layout.alarmEdit.name.toString().substring(0, 20) }}{{ layout.alarmEdit.name.toString().length > 20 ? '...' : '' }}</div>
                        <div class="fs-3 fw-normal text-white" v-else>{{ layout.alarmEdit.name }}</div>
                        <template v-if="layout.isInCourse">
                            <div v-if="layout.alarmEdit?.duration" class="text-white">Activa: <span class="fw-bold text-white">{{ layout.alarmEdit?.duration }}</span></div>
                        </template>
                        <template v-else>
                            <div class="text-white">Alarma finalizada</div>
                        </template>
                        
                    </div>
                </div>

                <div v-if="layout.alarmEdit" class="text-center ps-2">
                    <!--<div class="text-xs text-center py-3">{{ layout.alarmEdit.alarmId }}</div>-->
                    <div class="bg-light p-5">
                        <div class="mb-3" v-if="layout.isInCourse">
                            <template v-if="layout.alarmEdit.userAlarm && layout.alarmEdit.userAlarm?.notes"><div class="text-dark fw-bold text-center pb-3 fs-5">{{ layout.alarmEdit.userAlarm?.notes }}</div></template>
                            <div v-if="layout.alarmEdit.param">
                                <span v-if="layout.alarmEdit[layout.alarmEdit.param] && layout.alarmEdit.param == 'max'">Temperatura máxima: <span class="fw-bold">{{ layout.alarmEdit[layout.alarmEdit.param] }} {{ layout.alarmEdit?.unit }}</span></span>
                                <span v-if="layout.alarmEdit[layout.alarmEdit.param] && layout.alarmEdit.param == 'min'">Temperatura mínima: <span class="fw-bold">{{ layout.alarmEdit[layout.alarmEdit.param] }} {{ layout.alarmEdit.unit }}</span></span>
                                <span v-if="layout.alarmEdit[layout.alarmEdit.param] && layout.alarmEdit.param == 'maxH'">Humedad máxima: <span class="fw-bold">{{ layout.alarmEdit[layout.alarmEdit.param] }} {{ layout.alarmEdit.unit }}</span></span>
                                <span v-if="layout.alarmEdit[layout.alarmEdit.param] && layout.alarmEdit.param == 'minH'">Humedad mínima: <span class="fw-bold">{{ layout.alarmEdit[layout.alarmEdit.param] }} {{ layout.alarmEdit.unit }}</span></span>
                                <span v-if="layout.alarmEdit[layout.alarmEdit.param] && layout.alarmEdit.param == 'minB'">Batería mínima: <span class="fw-bold">{{ layout.alarmEdit[layout.alarmEdit.param] }} {{ layout.alarmEdit.unit }}</span></span>
                            </div>
                            <div v-if="layout.alarmEdit?.diff">Diferencial: <span class="fw-bold">{{layout.alarmEdit?.diff }} {{ layout.alarmEdit?.unit }}</span></div>
                            <div class="mt-1 mx-5">    
                                <div>Último valor registrado:</div>
                                <div class="mt-2 fw-bold friColor3"  v-if="(layout.alarmEdit.param == 'max' || layout.alarmEdit.param == 'max') && layout.device?.status?.msg" style="font-size: 30px; line-height: 30px;">{{ parseFloat(layout.device.status.msg.TempC_SHT).toFixed(1) }}<span class="text-xs align-middle ps-2">{{ layout.alarmEdit.unit }}</span></div> 
                                <div class="mt-2  fw-bold friColor3" v-if="(layout.alarmEdit.param == 'maxH' || layout.alarmEdit.param == 'minH') && layout.device?.status?.msg" style="font-size: 30px; line-height: 30px;">{{ parseFloat(layout.device.status.msg.Hum_SHT).toFixed(1) }}<span class="text-xs align-middle ps-2">{{ layout.alarmEdit.unit }}</span></div> 
                                <div class="mt-2  fw-bold friColor3" v-if="layout.alarmEdit.param == 'openDoor' && layout.device?.status?.msg" style="font-size: 30px; line-height: 30px;">
                                    <span v-if="layout.device.status.msg.DOOR_OPEN_STATUS">Abierta</span>
                                    <span v-else>Cerrada</span>
                                </div>
                                <div v-if="layout.device.status.date" class="text-center mt-1"><span class="fw-normal text-dark text-xs3">{{ layout.helper.getDateFormatFb(layout.device.status.date) }}</span></div>
                                <!--<div v-if="layout.alarmEdit?.lastValueTime" class="text-center"><span class="fw-normal text-dark text-xs3">Hace {{ layout.alarmEdit?.lastValueTime }}</span></div>-->
                            </div>
                            <template v-if="layout.isInCourse">
                                <template v-if="profile.isClient || (layout.alarmEdit?.userAlarm && layout.alarmEdit?.userAlarm?.discard)">
                                    <button v-if="layout.alarmEdit.alarmId" class="button px-5 py-1 ms-2 mt-3 friBack7 text-white fw-bold" @click="jobDiscard(layout.alarmEdit.alarmId)">DESCARTAR ALARMA</button>
                                    <div class="mt-5 text-xs3 text-center" :class="isMobile ? 'mx-3' : 'mx-5'">
                                        Si descarta la alarma se anulará, se notificará su fin a los destinatarios y no se volverá a mostrar hasta el siguiente registro de datos programado en el dispositivo <span v-if="layout.device?.interval">({{ layout.device?.interval }} minutos)</span>, en caso de que la alarma se mantenga aún activa.
                                    </div>
                                </template>
                            </template>
                        </div>   
                        <div class="ms-1 mb-5" style="padding-bottom: 80px;">
                            <div class="text-left pt-2 ms-3">Historial de eventos:</div>
                            <div class="timeline pt-0 mt-5 me-1" v-if="layout.alarmEdit.events && layout.alarmEdit.events.length">
                                <div v-for="(event, index) in layout.alarmEdit.events" :key="index" class="event">
                                    <div class="date">{{ event.date }} <div class="text-xs3">{{ event.hour }}</div></div>
                                    <template v-if="event.notify && event.notify == 'new'">
                                        <div class="text text-left text-xs3 ps-3">
                                            Creación de alarma
                                            <div class="text-xs text-dark" v-if="event.detect">
                                                Detectado: 
                                                <template v-if="layout.alarmEdit.code =='AH0006'">{{ event.detect ? 'Abierto' : 'Cerrado' }}</template>
                                                <template v-else>{{ event.detect }} {{ layout.alarmEdit.unit }}</template>
                                            </div>
                                            <div class="text-xs text-dark" v-if="event.diff">Diferencial: {{ event.diff }} {{ layout.alarmEdit.unit }}</div>
                                        </div>
                                    </template>
                                    <template v-if="event.notify && event.notify == 'start'">
                                        <template v-if="event.method">
                                            <div class="text text-left text-xs3 ps-3" v-if="event.method == 'email'">
                                                Notificación de nueva alarma por email
                                                <div class="text-xs text-dark">{{ event.userName }} ({{ event.to }})</div>
                                                <div class="text-xs text-dark">{{ event.response }}</div>
                                            </div>
                                            <div class="text text-left text-xs3 ps-3" v-if="event.method == 'sms'">
                                                Notificación de nueva alarma por SMS
                                                <div class="text-xs text-dark">{{ event.userName }} ({{ event.to }})</div>
                                                <div class="text-xs text-dark" v-if="event?.response?.resultadoTxt">{{ event.response.resultadoTxt == 'Aceptado' ? 'OK' : 'KO'  }}</div>
                                            </div>
                                            <div class="text text-left text-xs3 ps-3" v-if="event.method == 'whatsapp'">
                                                Notificación de nueva alarma por whatsapp
                                                <div class="text-xs text-dark">{{ event.userName }} ({{ event.to }})</div>
                                                <div class="text-xs text-dark friColor1" v-if="event?.response?.success">OK</div>
                                                <div v-else>
                                                    <div v-if="event?.response?.response" class="text-xs text-dark friColor3">{{ event?.response?.response.error.message }}</div>
                                                </div>
                                            </div>
                                        </template>
                                    </template>

                                    <template v-if="event.notify && event.notify == 'end'">
                                        <template v-if="event.method">
                                            <div class="text text-left text-xs3 ps-3" v-if="event.method == 'email'">
                                                Notificación de alarma finalizada por email
                                                <div class="text-xs text-dark">{{ event.userName }} ({{ event.to }})</div>
                                                <div class="text-xs text-dark">{{ event.response }}</div>
                                            </div>
                                            <div class="text text-left text-xs3 ps-3" v-if="event.method == 'sms'">
                                                Notificación de alarma finalizada por SMS
                                                <div class="text-xs text-dark">{{ event.userName }} ({{ event.to }})</div>
                                                <div class="text-xs text-dark" v-if="event?.response?.resultadoTxt">{{ event.response.resultadoTxt == 'Aceptado' ? 'OK' : 'KO' }}</div>
                                            </div>
                                            <div class="text text-left text-xs3 ps-3" v-if="event.method == 'whatsapp'">
                                                Notificación de alarma finalizada por whatsapp
                                                <div class="text-xs text-dark">{{ event.userName }} ({{ event.to }})</div>
                                                <div class="text-xs text-dark friColor1" v-if="event?.response?.success">OK</div>
                                                <div v-else>
                                                    <div v-if="event?.response?.response" class="text-xs text-dark friColor3">{{ event?.response?.response.error.message }}</div>
                                                </div>
                                            </div>
                                        </template>
                                    </template>

                                    <template v-if="event.notify && event.notify == 'discard'">
                                        <div class="text text-left text-xs3 ps-3 fw-bold">Alarma descartada 
                                            <div class="text-xs text-dark" v-if="event.userName">{{ event.userName }}</div>
                                            <div v-else>Sistema</div>
                                            <div class="text-xs text-dark" v-if="event.hasOwnProperty('detect')">
                                                Detectado: 
                                                <span v-if="layout.alarmEdit.code =='AH0006'">{{ event.detect ? 'Abierto' : 'Cerrado' }}</span>
                                                <template v-else>
                                                    <template v-if="layout.alarmEdit.code =='AH0006'">{{ event.detect ? 'Abierto' : 'Cerrado' }}</template>
                                                    <template v-else>{{ event.detect }} {{ layout.alarmEdit.unit }}</template>
                                                </template>
                                            </div>
                                        </div>
                                    </template>

                                    <div class="line"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
            <!--
                <pre class="text-left">{{ layout.alarmEdit }}</pre>
                <pre class="text-left">{{ layout.device }}</pre>
            -->

        </template>
        <template v-else>
            <div class="text-center w-100"  style="margin-top: 50px;">
                <div class="fw-bold fs-1">Alarma</div>
                <div class="mt-2">Espere por favor ...</div>
                <div class="spinner-border friColor1 mt-5" role="status">
                <span class="visually-hidden">Cargando ...</span>
                </div>
            </div>
        </template>   
    </div>
  </template>
  <script>
  import { defineComponent, onMounted, watch, ref, onUnmounted } from "vue"; //
  import { useAuthStore } from "@/store";
  import { getApp, getAppDevices, discardJob  } from "../../../model/apps.js";
  import { helper as $h } from "../../../utils/helper.js";
  import { getAlarm, getSQLAlarm } from "../../../model/alarms.js"; 
  import { alertController } from '@ionic/vue'; 
  import router from "@/router";

  import { _firebase as $_firebase } from "../../../model/firebase";
  import { onSnapshot, doc } from "firebase/firestore"; 
  const db   =  $_firebase.firestore()

  export default defineComponent({
    name: 'AlertDash',
    props: {
        isMobile:  Boolean,
        appId:     String,
        deviceId:  String,
        alarmCode: String,
        alarmId:   String,
     },
    components: {  },
    setup(props) {
  
       const authStore         = useAuthStore();
       const { user, profile } = authStore;
       const layout = ref({ 
                            appId:      null,
                            device:     null,
                            deviceId:   null,
                            alarmEdit:  null,
                            isInCourse: false,
                            alarmId:    null,
                            helper:     $h
                          }) 


       const initAlarm = async () => {
         refreshParams();
         layout.value.app           = await getApp(layout.value.appId)
         let deviceData             = await getAppDevices(layout.value.appId)

         if(deviceData && deviceData?.devices && Object.keys(deviceData.devices).length){
            
            layout.value.device     = deviceData.devices[layout.value.deviceId]
            if(!layout.value.device)return
            layout.value.alarmEdit  = await getAlarm(layout.value.appId, layout.value.deviceId, layout.value.alarmCode)

            if(!profile.isClient && layout.value.alarmEdit && layout.value.alarmEdit.users && layout.value.alarmEdit.users.length)layout.value.alarmEdit.userAlarm = layout.value.alarmEdit.users.find( (ua) => ua.userId == profile.uid)
            else layout.value.alarmEdit.userAlarm = profile

            layout.value.alarmEdit.startDate        = false
            layout.value.alarmEdit.lastValueTime    = false
            layout.value.alarmEdit.duration         = false
            layout.value.alarmEdit.alarmId          = false

            if(layout.value.alarmCode && layout.value.device.status.alarm?.alarms && layout.value.device.status.alarm?.alarms[layout.value.alarmCode]){
                if(layout.value.device.status.alarm.alarms[layout.value.alarmCode].alarmId)layout.value.alarmEdit.alarmId = layout.value.device.status.alarm.alarms[layout.value.alarmCode].alarmId
                let sdate = new Date(layout.value.device.status.alarm.alarms[layout.value.alarmCode].date)
                layout.value.alarmEdit.startDate = new Date(sdate).toLocaleString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false })
                if (layout.value.alarmEdit && sdate) {
                    const createdAtDate = sdate
                    const now = new Date();
                    const diffMilliseconds = now - createdAtDate.getTime(); 
                    const diffMinutes = Math.round(diffMilliseconds / (1000 * 60));
                    let duration = '';
                    const days = Math.floor(diffMinutes / (24 * 60));
                    const hours = Math.floor((diffMinutes % (24 * 60)) / 60);
                    const minutes = diffMinutes % 60;
                    if (days > 0)duration += `${days} día${days > 1 ? 's' : ''}`;
                    if (hours > 0)duration += ` ${hours} hora${hours > 1 ? 's' : ''}`;
                    if (minutes > 0 || duration === '')duration += ` ${minutes} minuto${minutes > 1 ? 's' : ''}`;
                    layout.value.alarmEdit.duration = duration.trim();
                }
                if (layout.value.device.status.date) {
                    const createdAtDate = new Date(layout.value.device.status.date.seconds * 1000); 
                    const now = new Date();
                    const diffMilliseconds = now - createdAtDate.getTime(); 
                    const diffMinutes = Math.round(diffMilliseconds / (1000 * 60));
                    let duration = '';
                    const days = Math.floor(diffMinutes / (24 * 60));
                    const hours = Math.floor((diffMinutes % (24 * 60)) / 60);
                    const minutes = diffMinutes % 60;
                    const seconds = Math.floor(diffMilliseconds / 1000);
                    if (days > 0) duration += `${days} día${days > 1 ? 's' : ''}`;
                    if (hours > 0) duration += ` ${hours} hora${hours > 1 ? 's' : ''}`;
                    if (minutes > 0) duration += ` ${minutes} minuto${minutes > 1 ? 's' : ''}`;
                    if (seconds < 60 || duration === '') duration += ` ${seconds} segundo${seconds > 1 ? 's' : ''}`;
                    layout.value.alarmEdit.lastValueTime = duration.trim();
                }
            }else
                layout.value.isInCourse    = false

            if (layout.value.alarmId)layout.value.alarmEdit.alarmId = layout.value.alarmId

            if(layout.value.device.status.alarm?.alarms && layout.value.device.status.alarm?.alarms[layout.value.alarmCode] && layout.value.device.status.alarm?.alarms[layout.value.alarmCode]?.alarmId && 
               layout.value.device.status.alarm?.alarms[layout.value.alarmCode].alarmId==layout.value.alarmEdit.alarmId)layout.value.isInCourse = true

            if(layout.value.alarmEdit.alarmId){
                layout.value.alarmEdit.sqlAlarm = await getSQLAlarm({ "appId": layout.value.appId, "device_id": layout.value.deviceId, "alarmId": layout.value.alarmEdit.alarmId })
                if(layout.value.alarmEdit.sqlAlarm?.success && layout.value.alarmEdit.sqlAlarm?.alarm && layout.value.alarmEdit.sqlAlarm?.alarm?.alarm && layout.value.alarmEdit.sqlAlarm?.alarm?.alarm?.events && Object.keys(layout.value.alarmEdit.sqlAlarm.alarm.alarm.events).length)
                    layout.value.alarmEdit.events = layout.value.alarmEdit.sqlAlarm.alarm.alarm.events
                if(layout.value.alarmEdit.events && layout.value.alarmEdit.events.length)layout.value.alarmEdit.events = layout.value.alarmEdit.events.reverse()
            }
         }

       }

        const jobDiscard= async (alarmId) => {
            const alert = await alertController.create({
                header:  "¿Está seguro?",
                cssClass: 'friCustomAlert',
                buttons: [ 
                            {text: 'Cancelar', cssClass: 'alert-button-cancel', role: 'cancel' },  
                            {text: 'Confirmar',cssClass: 'alert-button-confirm', handler: async () => { 
                                try{ await discardJob({ "jobId": alarmId, "action": "discard", "uid": profile.uid });
                                }catch(e){ console.log("error discard:",e); }
                                window.location.reload();
                            }
                            }
                        ],
            });
            await alert.present();
        }

        const refreshParams =  () => {
            if(router.currentRoute.value.params.appId)layout.value.appId = router.currentRoute.value.params.appId;
            if(router.currentRoute.value.params.deviceId)layout.value.deviceId = router.currentRoute.value.params.deviceId;
            if(router.currentRoute.value.params.alarmCode)layout.value.alarmCode = router.currentRoute.value.params.alarmCode;
            if(router.currentRoute.value.params.alarmId)layout.value.alarmId = router.currentRoute.value.params.alarmId;
        }
        
        onMounted( async () => { 
         layout.value.appId     = props.appId
         layout.value.deviceId  = props.deviceId
         layout.value.alarmCode = props.alarmCode
         layout.value.alarmId   = props.alarmId
         refreshParams();    
         await initAlarm();
         await detectDevicesChanges();
         //refresh every 15 seconds
         let intervalRefresh = setInterval(async () => { await initAlarm(); }, 15000);
         onUnmounted(() => { clearInterval(intervalRefresh); });            
       });

       watch(() => props.alarmCode, async (newVal, oldVal) => {
         if(newVal != oldVal){
           layout.value.alarmCode = newVal
           refreshParams();
           await initAlarm();
         }
       });

       watch(() => props.alarmId, async (newVal, oldVal) => {
         if(newVal != oldVal){
           layout.value.alarmId = newVal
           refreshParams();
           await initAlarm();
         }
       });
       
       const detectDevicesChanges = async () => {
            const collectionPath = `apps/${props.appId}/devices/${router.currentRoute.value.params.deviceId}`;
            const docRef = doc(db, `${collectionPath}`);
            const unsubscribeDetectDevicesChanges = onSnapshot(docRef, async (docSnapshot) => {
                if (docSnapshot.exists()){ await initAlarm();}
            }, (error) => { console.log("Error:", error); });
            onUnmounted(() => { unsubscribeDetectDevicesChanges(); });
        };

       return { layout, user, props, profile, jobDiscard };
    }
    
  })
  </script>
  <style>
  .timeline {position: relative;margin-left: 10px; }
  .event {
    display: flex;
    align-items: center; 
    margin-bottom: 20px;
  }
  
  .date {
    font-weight: bold;
    margin-right: 10px;
    padding-left: 10px;
    color: #5c5a5a;
    text-align: left;
  }
  
  .text {
    flex: 1;
  }
  
  .line {
    border-left: 2px solid #eee;
    height: 100%; /* Ajusta la altura de la línea para que coincida con la altura de los eventos */
    position: absolute;
    left: 0;
    top: 0;
  }
  </style>