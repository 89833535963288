<template>
    <div class="account-box is-form is-footerless px-0 mx-0" :style="isMobile ? 'border-bottom: none !important;' : ''">
        <div class="form-head stuck-header" :class="isMobile ? 'py-0 ps-3 pe-2' : 'py-3'" v-if="layout.app">
            <div class="form-head-inner">
                <div class="left" v-if="layout.app?.name"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5' : 'fs-4'">{{  layout.app.name.toString().substr(0, 24) }} {{ layout.app.name.toString().length > 24 ? '...' : '' }}</h4></div>
                <div class="inline-block align-top fs-6 mt-0" style="margin-top: 4px;">
                    <template v-if="layout.app.logo"><img :src="layout.app.logo" style="height: 50px;" /></template>
                    <template v-else>{{  layout.app.id }}</template>
                </div>
            </div>
        </div>
        <div v-if="layout.scene?.scene" class="text-center fs-1 mt-3 fw-bold">{{ layout.scene.scene.name }}</div>
        <div v-if="layout.scene?.typeName" class="text-center fs-6">{{ layout.scene.scene.typeName }}</div>
        <div class="my-3 mt-2 text-xs3">
            <div v-if="layout.loading">
                <div class="text-center w-100" style="padding: 100px 0;">
                    <div class="mt-2 fw-bold fs-5">Actualizando métricas</div>
                    <div>Espere por favor ...</div>
                    <div class="spinner-border friColor1 mt-5" role="status">
                    <span class="visually-hidden">Cargando ...</span>
                    </div>
                </div>
            </div>
            <template v-else>
                <div v-if="layout.scene && layout.scene?.scene && layout.scene.scene.devices && layout.scene.scene.devices.length"  style="padding: 60px;">
                    <div class="scene_network">
                        <div class="scene_router">Alba Hub</div>
                        <div v-for ="(device, index) in layout.scene.scene.devices" :key="index" class="scene_device cursor-pointer" 
                            @click="$router.push({ path: '/sensor/'+ layout.app.id + '/' + device, query: { section:  'dashboard', sceneId: layout.scene?.scene.sceneId } })"
                            :class="layout.app.devices?.devices[device]?.status?.alarm?.status && layout.app.devices?.devices[device]?.status?.alarm?.alarms ? 'friBack12 text-white' : 
                                    layout.app.devices?.devices[device]?.active ? !layout.app.devices?.devices[device]?.isSensor ? 'friBack3 text-white': 
                                    layout.app.devices?.devices[device]?.interval && layout.app.devices?.devices[device]?.durationTime && layout.app.devices?.devices[device]?.durationTime > layout.app.devices?.devices[device]?.interval ? 'friBack2 text-white' : 'friBack1 text-white' : 
                                    'friBack4 text-dark'">
                            <div class="py-2 px-2">
                                <div class="text-white py-1 fw-normal text-center" v-if="layout.app.devices?.devices[device]?.name" style="font-size: 15px;line-height: 20px; border-bottom: 1px solid #fff;">
                                    {{ layout.app.devices?.devices[device]?.name.toString().substr(0, 14) }} {{ layout.app.devices?.devices[device]?.name.toString().length > 14 ? '...' : '' }}
                                </div>
                                <ion-grid class="w-100 p-0 m-0 pt-2" v-if="layout.app.devices?.devices[device]?.status">
                                    <ion-row class="text-xs3 px-2 pb-0 mb-0">
                                        <ion-col style="max-width: 30px" class="p-0 m-0 text-center align-top" v-if="layout.app.devices?.devices[device]?.status?.msg && layout.app.devices?.devices[device]?.status?.msg.hasOwnProperty('DOOR_OPEN_STATUS')">
                                            <i v-if="layout.app.devices?.devices[device]?.status?.msg?.DOOR_OPEN_STATUS" class="bi bi-door-open text-white align-top friColor3" :class="isMobile ? 'fs-2' : 'fs-3'"></i>
                                            <i v-else class="bi bi-door-closed text-white align-top" :class="isMobile ? 'fs-2' : 'fs-3'"></i>
                                        </ion-col>
                                        <ion-col class="pe-0 ps-2 m-0 align-top">
                                            <template v-if="layout.app.devices?.devices[device]?.status?.msg && layout.app.devices?.devices[device]?.status?.msg.hasOwnProperty('TempC_SHT')">
                                                <div class="mt-1 text-white fw-bold text-center" v-if="layout.app.devices?.devices[device]?.status?.msg" style="font-size: 35px; line-height: 22px;">
                                                    <span :class="{ 'text-white': layout.app.devices?.devices[device]?.status.alarm && layout.app.devices?.devices[device]?.status.alarm.alarms && Object.values(layout.app.devices?.devices[device]?.status.alarm.alarms).some(alarm => alarm.paramSensor === 'TempC_SHT') }">
                                                        {{ parseFloat(layout.app.devices?.devices[device]?.status.msg.TempC_SHT).toFixed(1) }}
                                                        <span class="text-xs align-middle">ºC</span>
                                                    </span>
                                                </div>
                                                <div class="text-white fs-6 text-center pt-1 m-0" v-if="layout.app.devices?.devices[device]?.status.msg?.Hum_SHT">
                                                    <span class="fw-normal" :class="{ 'text-white': layout.app.devices?.devices[device]?.status.alarm && layout.app.devices?.devices[device]?.status.alarm.alarms && Object.values(layout.app.devices?.devices[device]?.status.alarm.alarms).some(alarm => alarm.paramSensor === 'Hum_SHT') }">{{ layout.app.devices?.devices[device]?.status.msg.Hum_SHT }}%</span>
                                                </div>
                                                <template v-if="layout.app.devices?.devices[device].hasOwnProperty('externalInput') && layout.app.devices?.devices[device].externalInput!='none'">
                                                    <div v-if="layout.app.devices?.devices[device]?.status.msg.hasOwnProperty('TempC_DS') && profile.isAgent" class="text-center">
                                                        <span class="fs-6 fw-normal text-white">SE: {{ parseFloat(layout.app.devices?.devices[device]?.status.msg.TempC_DS).toFixed(1) }}ºC</span>
                                                    </div>
                                                </template>
                                            </template>
                                            <div v-if="layout.app.devices?.devices[device]?.status?.msg && layout.app.devices?.devices[device]?.status?.msg.hasOwnProperty('DOOR_OPEN_STATUS')" class="text-left"> 
                                                <span class="px-2 br-5 fw-bold fs-5 align-top fw-bold" v-if="layout.app.devices?.devices[device]?.status?.msg?.DOOR_OPEN_STATUS"
                                                :class="layout.app.devices?.devices[device]?.status?.alarm?.status && layout.app.devices?.devices[device]?.status?.alarm?.alarms ? 'text-white' : 'friColor2'">Abierta</span>
                                                <span v-else class="text-white fs-5 align-top fw-bold">Cerrada</span>
                                            </div>
                                            <template v-if="layout.app.devices?.devices[device]?.status?.msg && layout.app.devices?.devices[device]?.status?.msg.hasOwnProperty('co2')">
                                                <div class="mt-1 text-white fw-bold text-center" v-if="layout.app.devices?.devices[device]?.status?.msg" style="font-size: 35px; line-height: 22px;">
                                                    <span :class="{ 'text-white': layout.app.devices?.devices[device]?.status.alarm && layout.app.devices?.devices[device]?.status.alarm.alarms && Object.values(layout.app.devices?.devices[device]?.status.alarm.alarms).some(alarm => alarm.paramSensor === 'co2') }">
                                                        {{ parseFloat(layout.app.devices?.devices[device]?.status.msg.co2).toFixed(0) }}
                                                        <span class="text-xs align-middle">ppm</span>
                                                    </span>
                                                </div>
                                                <div class="text-white fs-6 text-center pt-1 m-0" v-if="layout.app.devices?.devices[device]?.status.msg?.air_pressure">
                                                    <span class="fw-normal" :class="{ 'text-white': layout.app.devices?.devices[device]?.status.alarm && layout.app.devices?.devices[device]?.status.alarm.alarms && Object.values(layout.app.devices?.devices[device]?.status.alarm.alarms).some(alarm => alarm.paramSensor === 'air_pressure') }">
                                                        {{ layout.app.devices?.devices[device]?.status.msg.air_pressure }} hPa
                                                    </span>
                                                </div>
                                            </template>
                                        </ion-col>
                                    </ion-row>
                                    <div v-if="layout.app.devices?.devices[device]?.updatedAt" class="text-center pb-2 deviceStatusMsg">
                                        <span class="fw-normal text-white text-xs3 boxMsg">
                                            <template v-if="layout.app.devices?.devices[device]?.status?.alarm?.status && layout.app.devices?.devices[device]?.status?.alarm?.alarms">
                                                Requiere atención: <span v-for="(alarm, idx) in layout.app.devices?.devices[device]?.status?.alarm?.alarms" :key="idx" class="text-white pe-5">{{ alarm.title }}</span>
                                            </template>
                                            <template v-else> 
                                                <span v-if="layout.app.devices?.devices[device]?.interval && layout.app.devices?.devices[device]?.durationTime && layout.app.devices?.devices[device]?.durationTime > layout.app.devices?.devices[device]?.interval" class="fw-bold">
                                                    <i class="bi bi-exclamation-circle"></i> Requiere atención: Los valores obtenidos a las {{ layout.helper.getDateFormatFb(layout.app.devices?.devices[device]?.status.date, "time").substring(0, 5) }}h
                                                    <template v-if="layout.app.devices?.devices[device]?.durationTime">, hace {{ layout.app.devices?.devices[device]?.durationTime }} minutos, excede el intervalo de actualización, revise el estado del dispositivo.</template>
                                                </span>
                                                <span v-else>
                                                    No se han detectado alarmas. Valores obtenidos a las {{ layout.helper.getDateFormatFb(layout.app.devices?.devices[device]?.status.date, "time").substring(0, 5) }}h<template v-if="layout.app.devices?.devices[device]?.durationTime">, hace {{ layout.app.devices?.devices[device]?.durationTime }} minutos.</template>
                                                </span>
                                            </template>
                                        </span>
                                    </div>
                                </ion-grid>
                            </div>
                        </div>
                    </div>   
                    
                    
                    <!--
                        <div v-for ="(device, index) in layout.scene.scene.devices" :key="index" class="scene_device cursor-pointer" 
                        @click="$router.push({ path: '/sensor/'+ layout.app.id + '/' + device, query: { section:  'dashboard', sceneId: layout.scene?.scene.sceneId } })"
                        :class="layout.app.devices?.devices[device]?.status?.alarm?.status && layout.app.devices?.devices[device]?.status?.alarm?.alarms ? 'friBack12 text-white' : 
                                layout.app.devices?.devices[device]?.active ? !layout.app.devices?.devices[device]?.isSensor ? 'friBack3 text-white': 'friBack1 text-white' : 'friBack4 text-dark'">
                        <div class="py-1">
                            <div class="text-white px-2 pt-2 fw-bold pb-1 text-xs3">{{ layout.app.devices?.devices[device]?.name.toString().substr(0, 13) }} {{ layout.app.devices?.devices[device]?.name.toString().length > 13 ? '...' : '' }} <i class="bi bi-chevron-right"></i></div>
                            <ion-grid class="w-100 p-0 m-0">
                                <ion-row class="text-xs3 px-2 pb-0 mb-0">
                                    <ion-col style="max-width: 30px" class="p-0 m-0 text-center">
                                        <template v-if="layout.app.devices?.devices[device]?.status?.msg && layout.app.devices?.devices[device]?.status?.msg.hasOwnProperty('DOOR_OPEN_STATUS')"> 
                                            <i v-if="layout.app.devices?.devices[device]?.status?.msg?.DOOR_OPEN_STATUS" class="bi bi-door-open text-white align-top friColor3" :class="isMobile ? 'fs-2' : 'fs-3'"></i>
                                            <i v-else class="bi bi-door-closed text-white align-top" :class="isMobile ? 'fs-2' : 'fs-3'"></i>
                                        </template>
                                        <template v-else>
                                            <i v-if="!layout.app.devices?.devices[device]?.isSensor" class="bi bi-snow3 text-white align-top" :class="isMobile ? 'fs-2' : 'fs-3'"></i>
                                            <i v-else class="bi bi-wifi text-white align-top" :class="isMobile ? 'fs-2' : 'fs-3'"></i>
                                        </template>
                                    </ion-col>
                                    <ion-col class="pe-0 ps-2 m-0 align-top">
                                        <template v-if="layout.app.devices?.devices[device]?.status?.msg && layout.app.devices?.devices[device]?.status?.msg.hasOwnProperty('TempC_SHT')">
                                            <div class="mt-1 text-white fw-bold text-left" v-if="layout.app.devices?.devices[device]?.status?.msg" style="font-size: 24px; line-height: 18px;">
                                                <span :class="{ 'text-white': layout.app.devices?.devices[device]?.status.alarm && layout.app.devices?.devices[device]?.status.alarm.alarms && Object.values(layout.app.devices?.devices[device]?.status.alarm.alarms).some(alarm => alarm.paramSensor === 'TempC_SHT') }">
                                                    {{ parseFloat(layout.app.devices?.devices[device]?.status.msg.TempC_SHT).toFixed(1) }}
                                                    <span class="text-xs align-middle">ºC</span>
                                                </span>
                                            </div>
                                            <div class="fs-6 text-white text-xs text-left pt-1 m-0" v-if="layout.app.devices?.devices[device]?.status.msg?.Hum_SHT">
                                                <span class="fw-bold" :class="{ 'text-white': layout.app.devices?.devices[device]?.status.alarm && layout.app.devices?.devices[device]?.status.alarm.alarms && Object.values(layout.app.devices?.devices[device]?.status.alarm.alarms).some(alarm => alarm.paramSensor === 'Hum_SHT') }">{{ layout.app.devices?.devices[device]?.status.msg.Hum_SHT }}%</span>
                                            </div>
                                        </template>
                                        <div v-if="layout.app.devices?.devices[device]?.status?.msg && layout.app.devices?.devices[device]?.status?.msg.hasOwnProperty('DOOR_OPEN_STATUS')" class="text-left ps-2"> 
                                            <span class="px-2 br-5 fw-bold fs-6" v-if="layout.app.devices?.devices[device]?.status?.msg?.DOOR_OPEN_STATUS"
                                            :class="layout.app.devices?.devices[device]?.status?.alarm?.status && layout.app.devices?.devices[device]?.status?.alarm?.alarms ? 'text-white' : 'friColor2'">Abierta</span>
                                            <span v-else class="text-white fs-6">Cerrada</span>
                                        </div>
                                    </ion-col>
                                </ion-row>
                                <div v-if="layout.app.devices?.devices[device]?.updatedAt" class="text-center pb-2">
                                    <span class="fw-normal text-white text-xs3">{{ layout.helper.getDateFormatFb(layout.app.devices?.devices[device]?.status.date, "time") }}</span>
                                </div>
                            </ion-grid>
                        </div>
                    </div>
                    -->

                </div>
                <template v-else>
                    <div class="text-center w-100"  style="margin-top: 150px;">
                        <div class="fw-bold fs-1">Alba Hub</div>
                        <div class="mt-2">Espere por favor ...</div>
                        <div class="spinner-border friColor1 mt-5" role="status">
                        <span class="visually-hidden">Cargando ...</span>
                        </div>
                    </div>
                </template>
            </template>
        </div>
    </div>
 </template>
 <script>
 import { defineComponent, onMounted, ref, watch, onUnmounted } from "vue";  //, onUnmounted
 import { useAuthStore } from "@/store";
 import { add } from 'ionicons/icons';
 import router from "@/router";
 import { useRoute } from 'vue-router'
 import { _firebase as $_firebase } from "../../../model/firebase";
 import { onSnapshot, collection } from "firebase/firestore"; 
 import { getApp, getScene, getAppDevices, getAppUsers } from "../../../model/apps.js"; 
 import { helper as $h } from "../../../utils/helper.js";
 import { IonRow, IonCol, IonGrid } from '@ionic/vue'; 

 const db   =  $_firebase.firestore()

 export default defineComponent({
   name: 'siteDash',
   props: {
       isMobile: Boolean,
       appId: String,
       sceneId: String,
    },
    components: { IonRow, IonCol, IonGrid },
   setup(props) {
 
      const authStore      = useAuthStore();
      const route          = useRoute()
      const layout         = ref({ 
                               appId:       null,
                               sceneId:     null,
                               app:         null,
                               scene:       null,
                               section:     null,
                               loading:     true,
                               helper:      $h,
                               intervals:   {},
                               unsubscribeDetectDevicesChanges: null,
                               sections: [
                                           { name: 'dashboard', title: 'Cámara', icon: 'bi bi-snow3'},
                                         ],
                           }) 

      const { user, profile } = authStore;

      const goBack = () => {
       router.push({ path: '/device/' + layout.value.appId, query: { section:  'dashboard' } })
      }

      const initScene = async () => {
        unsubscribeIntervals();
        layout.value.appId         = router.currentRoute.value.params.appId;
        layout.value.sceneId       = router.currentRoute.value.params.SceneId;
        layout.value.loading       = true;
        layout.value.app           = await getApp(layout.value.appId)
        layout.value.app.users     = await getAppUsers(layout.value.appId)
        await loadDevices();
        await loadScene();
        layout.value.loading       = false;
      }

      const getDurationTime = (date) => {
        if (!date) return "";
        let now = new Date();
        let diff = now - date.toDate();
        let minutes = Math.floor(diff / 60000);
        return minutes;
      }

      const unsubscribeIntervals = () => {
        if(layout.value.intervals && Object.keys(layout.value.intervals).length){
            for (const intval of Object.values(layout.value.intervals)) { clearInterval(intval); }
            layout.value.intervals = {}
        }
      }

      const loadDevices = async () => {
        layout.value.app.devices  = await getAppDevices(layout.value.appId)
      }

      const loadScene = async () => {

        console.log('intervals', layout.value.intervals)
        if(layout.value.sceneId){
            layout.value.scene     = await getScene(layout.value.appId, layout.value.sceneId)
            if(layout.value.intervals && !Object.keys(layout.value.intervals).length && layout.value.scene && layout.value.scene.scene && layout.value.scene.scene.devices){
                for(let i = 0; i < layout.value.scene.scene.devices.length; i++){
                    layout.value.app.devices.devices[layout.value.scene.scene.devices[i]].durationTime = getDurationTime(layout.value.app.devices.devices[layout.value.scene.scene.devices[i]].status.date);
                    layout.value.intervals[layout.value.scene.scene.devices[i]] = setInterval(() => {
                                                                                                    if(layout.value.app.devices && layout.value.app.devices.devices && layout.value.app.devices.devices[layout.value.scene.scene.devices[i]])
                                                                                                        layout.value.app.devices.devices[layout.value.scene.scene.devices[i]].durationTime = getDurationTime(layout.value.app.devices.devices[layout.value.scene.scene.devices[i]].status.date);
                                                                                                }, 60000);
                }
                onUnmounted(() => { unsubscribeIntervals() });
            }
        }

        console.log('intervals', layout.value.intervals)
      }

      onMounted( async () => { 
           await initScene();
           await detectDevicesChanges();
      });
 
       watch(() => route.query, async (query) => {
            if (query){
                layout.value.query     = query; 
                layout.value.section   = query.section;
                layout.value.appId     = router.currentRoute.value.params.appId;
                layout.value.sceneId   = router.currentRoute.value.params.SceneId;
                await initScene();
            }
        });

        const detectDevicesChanges = async () => {
            if(layout.value.unsubscribeDetectDevicesChanges)layout.value.unsubscribeDetectDevicesChanges();
            const collectionPath = "apps/" + props.appId + "/devices/";
            const collRef        = collection(db, collectionPath);
            layout.value.unsubscribeDetectDevicesChanges = onSnapshot(collRef, async (querySnapshot) => {
                querySnapshot.docChanges().forEach(async change => { 
                    if (change.type === "modified"){ 
                        layout.value.loading       = true;
                        unsubscribeIntervals();
                        await loadDevices();
                        await loadScene();
                        layout.value.loading       = false;
                     }
                }); 
            }, (error) => { console.log("Error:", error); });
            onUnmounted(() => { layout.value.unsubscribeDetectDevicesChanges(); });
        };
       return { layout, user, profile, add, props, goBack };
   },
   methods: {
       timeFromDate: function(date) {
        if (!date) return "";
        let now = new Date();
        let diff = now - date.toDate();
        let minutes = Math.floor(diff / 60000);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);
        let months = Math.floor(days / 30);
        let years = Math.floor(months / 12);
        if (years > 0) return years + " años";
        if (months > 0) return months + " meses";
        if (days > 0) return days + " días";
        if (hours > 0) return hours + " horas";
        if (minutes > 1) return minutes + " minutos";
        return "1 minuto";
       },
       getTimeInMinutsFromDate: function(date) {
        if (!date) return "";
        let now = new Date();
        let diff = now - date.toDate();
        let minutes = Math.floor(diff / 60000);
        return minutes;
       },
   }
   
 })
 </script>
 <style>
    /*scenes*/
    .scene_network { display: flex; justify-content: center; align-items: center; position: relative;height: 320px; margin: 20px 0; perspective: 1000px;}
    .scene_router {  position: absolute;width: 120px;height: 120px;border-radius: 50%;display: flex; justify-content: center;align-items: center;color: #fff;background-color: #666; font-weight: bold;}
    .scene_device {  position: absolute; width: 145px; background-color: #ccc; border-radius: 5px; color: black; font-size: 12px; padding: 0 8px; transform: rotate(var(--angle)) translate(200px) rotate(calc(-1 * var(--angle))); text-align: left;} 
    .scene_device:nth-child(5) {  transform: translate(-115px, 70px);}
    .scene_device:nth-child(4) {  transform: translate(100px, -150px);}
    .scene_device:nth-child(3) {  transform: translate(92px, 134px);}
    .scene_device:nth-child(2) {  transform: translate(-100px, -140px);}
    .scene_network::before {content: '';position: absolute; width: 300px; height: 300px; border-radius: 50%; border: 3px dotted #999;}   
    .deviceStatusMsg{ overflow: hidden; white-space: nowrap; margin: 0 5px;}
    @keyframes marquee { 
        from { transform: translateX(100%);  } 
        to { transform: translateX(-100%); } 
    }
    .deviceStatusMsg .boxMsg {display: inline-block;animation: marquee 16s linear infinite; }
</style>