<template>
    <div class="personal-dashboard personal-dashboard-v1 mt-2 friDashContent">
        <div class="dashboard-header" :class="isMobile ? 'mb-0' : ''">
          <img src="../../assets/images/logo/fricontrolIcon.png" width="45" alt="Alba" class="cursor-pointer mt-2" @click="loadSection()"  />
          <div id="friApiTopHeader" class="start pt-3 ps-2">
              <h3>Alba Restful API</h3>
              <p class="mt-1 text-sm text-dark">Webservice</p><!-- v-if="user" {{ user.uid }} -->
          </div>
          <div class="end" v-if="!isMobile"><div class="cursor-pointer fw-6 pe-2" @click="$router.push('/')">Volver</div></div>
        </div>
        <div class="dashboard-body my-0">
            <div class="text-left mb-5 text-sm">
                <template v-if="layout.controllers?.controllers">
                    <div id="friApiTopBox">
                        <div class="filters">
                            <select class="form-select col cursor-pointer"  v-model="layout.controller">
                                <option :value="false" selected>Seleccione un controlador</option>
                                <option v-for="ctrl in layout.controllers.controllers" :value="ctrl" :key="ctrl.id">{{ ctrl.name }}</option>
                            </select>
                            <template v-if="layout.controller.devices && layout.controller.devices.devices">
                                <select class="form-select col cursor-pointer" v-model="layout.device">
                                    <option :value="false" selected>Seleccione un dispositivo</option>
                                    <option v-for="(dev,idx) in layout.controller.devices.devices" :value="dev" :key="idx">{{ dev.name }} - {{ dev.model }}</option>
                                </select>
                            </template>
                        </div>
                        <div class="previewSelected mt-2">
                            <div v-if="layout.controller" class="col">
                                <div class="inline-block align-top me-3 ms-3">
                                    <img :src="layout.controller.media.qr" width="65" v-if="layout.controller?.media?.qr">
                                    <img src="../../assets/images/logo/authalba2.png" width="70" v-else>
                                </div>
                                <div class="inline-block">
                                    <div class="fs-6 fw-bold">{{ layout.controller.brand }}</div>
                                    <div>{{ layout.controller.model }}</div>
                                    <div>S/N: {{ layout.controller.serialNumber }}</div>
                                </div>
                                <div class="mt-3 text-left p-3 py-0" :class="layout.action ? 'hidden' : ''">
                                    <div class="row">
                                        <div v-for="(action, index) in layout.actions" :key="index" class="col-md-12">
                                            <div class="fs-6 ps-2" v-if="action.type=='controller'">
                                                <template v-if="action?.group">
                                                    <span class="cursor-pointer pt-2 pb-1"  @click="callAction(action)">{{ action.name }}</span>
                                                    <div class="row my-2">
                                                        <div v-for="(_action, _index) in action.items" :key="_index" class="fs-6 ps-2">
                                                            <span class="friColor1 cursor-pointer ps-2"  @click="callAction(_action)">&raquo; {{ _action.name }}</span>
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <span class="friColor1 cursor-pointer"  @click="callAction(action)">&raquo; {{ action.name }}</span>
                                                    <template v-if="action.val && !action.hval">
                                                        <input v-model="action.val" type="text" class="form-control d-inline-block ms-3" style="width: 80px; padding: 2px 10px; vertical-align: middle;" placeholder="Valor">
                                                    </template>
                                                 </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="layout.device" class="col col2">
                                <div class="inline-block align-top me-3 ms-3" v-if="layout.device?.media?.image"><img :src="layout.device.media.image" width="100"></div>
                                <div class="inline-block">
                                    <div class="fs-6 fw-bold">{{ layout.device.name }}</div>
                                    <div>{{ layout.device.model }}</div>
                                    {{ layout.device.brand }}
                                </div>
                                <div class="mt-3 text-left p-3 py-0" :class="layout.action ? 'hidden' : ''">
                                    <div class="row">
                                        <div v-for="(action, index) in layout.actions" :key="index">
                                            <div class="fs-6 ps-2" v-if="action.type=='device'">
                                                <template v-if="action?.group">
                                                    <div :class="action.modbus && !layout.device.modbus ? 'hidden' : ''">
                                                        <span class="cursor-pointer pt-2 pb-1"  @click="callAction(action)">{{ action.name }}</span>
                                                        <div class="row my-2">
                                                            <div v-for="(_action, _index) in action.items" :key="_index" class="fs-6 ps-2" 
                                                                :class="_action.modbus && !layout.device.modbus ? 'hidden' : index == 'deviceActions' || index == 'deviceStatus' ? 'col-md-4' : ''">
                                                                <span class="friColor1 cursor-pointer ps-2"  @click="callAction(_action)">&raquo; {{ _action.name }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <span class="friColor1 cursor-pointer"  @click="callAction(action)">&raquo; {{ action.name }}</span>
                                                    <template v-if="action.val && !action.hval">
                                                        <input v-model="action.val" type="text" class="form-control d-inline-block ms-3" style="width: 80px; padding: 2px 10px; vertical-align: middle;" placeholder="Valor">
                                                    </template>
                                                 </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="layout.action && layout.actionResponse" class="text-center pt-3 cursor-pointer" @click="layout.action = false">Menú principal</div>
                    </div>
                    <template v-if="layout.action">
                        <div v-if="layout.actionResponse" class="friApiResponse">
                            <div class="fs-6 fw-bold">
                                <div v-if="layout.action.type == 'device'">{{ layout.device.name }}</div>
                                <div v-else>Controlador {{ layout.controller.name }}</div>
                            </div>
                            <div>{{ layout.action.name }}</div>
                            <div class="text-sm " v-if="layout.actionResponse?.request?.api"><a :href="layout.actionResponse?.request?.api" target="_blank" class="friColor1">https request</a></div>
                            <vue-json-pretty :data="layout.actionResponse" :deep="layout.action.deep ? undefined : 1" class="mt-3"></vue-json-pretty>
                        </div>
                        <div v-if="layout.action && !layout.actionResponse" class="text-center"><img src="../../assets/images/theme/loading.gif" style="width: 100px"></div>
                    </template>
                </template>
                <template v-else>
                    <div class="text-center"><img src="../../assets/images/theme/loading.gif" style="width: 200px"></div>
                </template>
            </div>
        </div>
        <!-- 
        <pre class="text-left mb-5">{{ layout.devices }}</pre>
        <pre class="text-left mb-5">{{ user }}</pre>
        <pre>{{ profile }}</pre>
        -->
    </div>
</template>

<script>
import { defineComponent, ref, onMounted , watch} from 'vue';
import { getPlatforms } from '@ionic/vue';
import { useAuthStore } from '@/store';
import { controllerList, requestDevice, getController, getDevice, getDeviceSubscriptions, getControllerClient, getControllerUsers, getControllerSubsciptions  } from "../../model/api.js";
import router from "@/router";

import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css';

export default defineComponent({
name: 'DashboardPage',
props: {
    login: Boolean,
    isMobile: Boolean
},
components: { VueJsonPretty },
setup() {

    const authStore         = useAuthStore();
    const { user, profile } = authStore;
    const platforms         = getPlatforms(); 
    const layout            = ref({
                                    isMobile:           false,
                                    controllers:        false,
                                    controller:         false,
                                    device:             false,
                                    method:             false,
                                    action:             false,
                                    actions:            {       
                                                            controller:          {  type: "controller",         name: "Controlador",                            group: true,  
                                                                                    items: [
                                                                                        {  type: "controller",      name: "Ficha técnica",                       api:     "getController",            confirm: false  },
                                                                                        {  type: "controller",      name: "Temperatura",                         action:  "temperature",              confirm: false  },
                                                                                        {  type: "controller",      name: "Procesador",                          action:  "cpu",                      confirm: false  },
                                                                                        {  type: "controller",      name: "Disco",                               action:  "disk",                     confirm: false  },
                                                                                        {  type: "controller",      name: "RAM",                                 action:  "ram",                      confirm: false  },
                                                                                        {  type: "controller",      name: "Sistema operativo",                   action:  "so",                       confirm: false  },
                                                                                        {  type: "controller",      name: "Conexión",                            action:  "network",                  confirm: false  },
                                                                                        //{  type: "controller",      name: "WIFI",                                action:  "wifi",                     confirm: true  },
                                                                                        {  type: "controller",      name: "Batería",                             action:  "ups",                      confirm: false  },
                                                                                    ]
                                                                                 },
                                                            controllerUsers:    {  type: "controller",         name: "Usuarios",                                group: true,  
                                                                                    items: [
                                                                                        {  type: "controller",      name: "Cliente",                             api:     "getControllerClient",      confirm: false  },
                                                                                        {  type: "controller",      name: "Usuarios",                            api:     "getControllerUsers",       confirm: false  },
                                                                                        {  type: "controller",      name: "Roles",                               api:     "roles",                    confirm: false  },
                                                                                    ]
                                                                                 },
                                                            controllerSubs:      {  type: "controller",         name: "Suscripción",                           group: true,  
                                                                                    items: [
                                                                                        {  type: "controller",      name: "Suscripciones",                       api:     "getControllerSubsciptions", confirm: false  },
                                                                                        {  type: "controller",      name: "Suspender",                           api:     "suspendSubscription",       confirm: false  },
                                                                                    ]
                                                                                 },
                                                            controllerStatus:    {  type: "controller",         name: "Estado",                           group: true,  
                                                                                    items: [
                                                                                        {  type: "controller",      name: "Servicios",                           action:  "services",             confirm: false  },
                                                                                        {  type: "controller",      name: "Copiloto",                            action:  "copilot",              confirm: false  },
                                                                                        {  type: "controller",      name: "El tiempo",                           action:  "weather",              confirm: false,  },
                                                                                    ]
                                                                                 },
                                                            controllerLog:       {  type: "controller",      name: "Historial",                           group: true,  
                                                                                    items: [
                                                                                        { type: "controller", name: "Acciones",  action:  "controller-log",       confirm: false  },
                                                                                        { type: "controller", name: "Copias",    action:  "controller-log",       confirm: false,  val: "controller-bak", hval: true },
                                                                                        { type: "controller", name: "Reinicios", action:  "controller-log",       confirm: false,  val: "restart",        hval: true },
                                                                                    ]
                                                                                 },
                                                            controllerAction:    {  type: "controller",      name: "Acciones",                             group: true,  
                                                                                    items: [
                                                                                        {  type: "controller",      name: "Crear copia de seguridad",            path:    "controller-bak",       confirm: true  },
                                                                                        {  type: "controller",      name: "Generar QR",                          action:  "qr",                   confirm: false },
                                                                                        {  type: "controller",      name: "Reiniciar",                           action:  "restart",              confirm: true  },
                                                                                    ]
                                                                                 },
                                                            /*controllerTools:   {  type: "controller",         name: "Herramientas",                         group: true,   
                                                                                    items: [
                                                                                        {  type: "controller",          name: "Copiar MicroSD",                  action:  "copysd",             confirm: true , deep: true , val: "sda", hval: true  }
                                                                                    ]
                                                                                 },   */               
                                                            device:              {  type: "device",         name: "Dispositivo",                              group: true,  
                                                                                    items: [
                                                                                        {  type: "device",         name: "Ficha técnica",                       api:     "getDevice",                    confirm: false  },
                                                                                        {  type: "device",         name: "Suscripciones",                       api:     "getDeviceSubscriptions",       confirm: false  },
                                                                                    ]
                                                                                 },
                                                            deviceStatus:        {  type: "device",         name: "Estado",                              group: true,  
                                                                                    items: [
                                                                                        {  type: "device",          name: "Estado",                            action:  "status",               confirm: false, deep: true },
                                                                                        {  type: "device",          name: "Sondas",                            action:  "sondas",               confirm: false, deep: true  },
                                                                                        {  type: "device",          name: "Sensors",                           action:  "sensors",              confirm: false, deep: true  },
                                                                                        {  type: "device",          name: "Configuración",                     action:  "config",               confirm: false, deep: true, modbus: true  },
                                                                                        {  type: "device",          name: "Alarmas",                           action:  "alarms",               confirm: false, deep: true, modbus: false  },
                                                                                        {  type: "device",          name: "Modo ahorro",                       action:  "seStatus",             confirm: false, deep: true, modbus: true  },
                                                                                        {  type: "device",          name: "Copiloto",                          action:  "copilot",              confirm: false, deep: true, modbus: true  },
                                                                                        {  type: "device",          name: "Modo nocturno",                     action:  "nmStatus",             confirm: false, deep: true, modbus: true  },
                                                                                    ]
                                                                                 },
                                                            deviceLog:           {  type: "device",         name: "Historial",                           group: true,  
                                                                                    items: [
                                                                                        {  type: "device",          name: "Acciones",               action:  "controller-log",       confirm: false,  val: "device-actions", hval: true, modbus: true },
                                                                                        {  type: "device",          name: "Temperaturas",           action:  "log",                  confirm: false  },
                                                                                        { type: "device", name: "Reinicio", action:  "controller-log",       confirm: false,  val: "restart",        hval: true, modbus: true },
                                                                                    ]
                                                                                 },
                                                            deviceNightMode:     {  type: "device",         name: "Modo nocturo",                           group: true, modbus: true ,  
                                                                                    items: [
                                                                                        {  type: "device",          name: "Activar",                            action:  "nmOn",         confirm: false, modbus: true   },
                                                                                        {  type: "device",          name: "Desactivar",                         action:  "nmOff",        confirm: true, modbus: true    },
                                                                                        {  type: "device",          name: "Programación",                       action:  "nmSchedule",   modbus: true },
                                                                                    ]
                                                                                 },      
                                                            deviceEnergy:        {  type: "device",         name: "Modo Ahorro",                           group: true, modbus: true ,  
                                                                                    items: [
                                                                                        {  type: "device",          name: "Activar",                            action:  "seOn",         confirm: false, modbus: true   },
                                                                                        {  type: "device",          name: "Desactivar",                         action:  "seOff",        confirm: true, modbus: true    },
                                                                                        {  type: "device",          name: "Programación",                       action:  "seSchedule", modbus: true  },
                                                                                    ]
                                                                                 },      
                                                            deviceActions:      {  type: "device",         name: "Acciones",                           group: true, modbus: true ,  
                                                                                    items: [
                                                                                        {  type: "device",          name: "Encender",                            action:  "on",                   confirm: true, modbus: true    },
                                                                                        {  type: "device",          name: "Apagar",                              action:  "off",                  confirm: true, modbus: true    },
                                                                                        {  type: "device",          name: "Desescarche manual",                  action:  "defrost",              confirm: true, modbus: true    },
                                                                                        {  type: "device",          name: "Definir punto de ajuste",             action:  "set",                  confirm: true, modbus: true,   val: -5  }, 
                                                                                        {  type: "device",          name: "Temp final desescarche",              action:  "dte",                  confirm: true, modbus: true,   val: -5  }, 
                                                                                        {  type: "device",          name: "Encender luz cámara",                 action:  "ledon",                confirm: false, modbus: true   },
                                                                                        {  type: "device",          name: "Apagar luz cámara",                   action:  "ledoff",               confirm: true, modbus: true    },
                                                                                    ]
                                                                                 },
                                                            deviceTools:        {  type: "device",         name: "Herramientas",                           group: true, modbus: true,   
                                                                                    items: [
                                                                                        {  type: "device",          name: "Scan Modbus",                         action:  "scan",                 confirm: false , deep: true, modbus: true   }
                                                                                    ]
                                                                                 },
                                                        },
                                  })

    const toDevice = async (deviceId) => {
     router.push({ name: 'device', params: { deviceId: deviceId } });
    }  

    const callAction = async (a) => {

        layout.value.action         = a;
        layout.value.actionResponse = false

        let qrtType                 = a.type
        if(layout.value.action?.val == 'device-actions')qrtType = "controller"

        if(layout.value.action?.path){

            if(layout.value.action?.confirm)if(confirm("¿Está seguro de realizar esta acción?") == false){ layout.value.action = false; return; }
            layout.value.actionResponse = await requestDevice(layout.value.controller.net.ip, layout.value.action?.path + "/" + qrtType + "Id/" + layout.value.controller.id);

        }else if(layout.value.action?.action){
           
            let rqPath   =  qrtType + "-actions" 
            if(layout.value.action?.val == 'device-actions')rqPath += "/" + layout.value.controller.id
            else if(layout.value.action?.type == 'controller')rqPath += "/" + layout.value.controller.id
            else rqPath += "/" + layout.value.device.id
            rqPath      += "/" + layout.value.action.action 
            
            if(layout.value.action?.confirm){
                if(confirm("¿Está seguro de realizar esta acción?") == false){ layout.value.action = false; return; }
                else{
                    if(layout.value.action.val && !layout.value.action.hval){
                        let actionValue = prompt("Introduzca el valor:");
                        if (actionValue === null) {  layout.value.action = false; return; 
                        }else rqPath += ":" + actionValue;
                    }else{
                        if(layout.value.action.val)rqPath += ":" + layout.value.action.val.toString();
                    }
                }
            }
            if(layout.value.action?.action=="weather")rqPath += ":3127066" 
            layout.value.actionResponse = await requestDevice(layout.value.controller.net.ip, rqPath);

        }else if(layout.value.action?.api){

            switch (layout.value.action?.api) {
                case "getController":             layout.value.actionResponse = await getController(layout.value.controller.id); break;
                case "getDevice":                 layout.value.actionResponse = await getDevice(layout.value.controller.id, layout.value.device.id); break;
                case "getDeviceSubscriptions":    layout.value.actionResponse = await getDeviceSubscriptions(layout.value.controller.id, layout.value.device.id); break;
                case "getControllerClient":       layout.value.actionResponse = await getControllerClient(layout.value.controller.id); break;
                case "getControllerUsers":        layout.value.actionResponse = await getControllerUsers(layout.value.controller.id); break;
                case "getControllerSubsciptions": layout.value.actionResponse = await getControllerSubsciptions(layout.value.controller.id); break;
                default: break;
            }
        }

        if(layout.value.action?.action == "restart")await loadSection();

    } 

    const restartFilers = () => {
        layout.value.action         = false;
        layout.value.actionResponse = false;
        if(!layout.value.controller)layout.value.device = false;
    }

    const loadSection = async () => {
      if(platforms && !platforms.includes('desktop')) layout.value.isMobile = true;
      layout.value.controller     = false
      layout.value.device         = false;
      restartFilers();
      layout.value.controllers = false
      layout.value.controllers = await controllerList({ uid: user.uid });
    }

    watch(() => layout.value.device, () => { restartFilers(); })
    watch(() => layout.value.controller, () => { restartFilers(); layout.value.device         = false;})

    onMounted(async () => { 
      await loadSection();
    });

    return {user, profile, layout, loadSection, router, toDevice, callAction }; 
},
methods: { },
});
</script>
<style>
.vjs-tree{ font-size: 12px;}
.vjs-value-string{color: #333 !important;}
#friApiTopBox{ margin-bottom: 10px; }
#friApiTopBox .filters{ background-color: #f7f8f9; border-radius: 7px;padding: 15px;}
#friApiTopBox .col{ display: inline-block !important; vertical-align: top !important; width: 25%; margin-left: 1%; padding: 13px 0 13px 15px; }
#friApiTopBox .col2{ width: 50%;}
#friApiTopBox .btn{ vertical-align: top !important; float: right;}

#friApiTopHeader{ text-align: left;}
.friApiResponse{ margin: 0 0 0 44px; border-top: 1px solid #dbd8d8; padding-top: 15px;}

@media only screen and (width <= 990px) {
    #friApiTopBox{ margin: 8px;}
    #friApiTopBox .col{ width: 94% !important; margin-bottom: 5px; }
    #friApiTopBox .btn{ float: none !important;}
    #friApiTopBox .previewSelected{ display: none !important; }
    #friApiTopHeader{ text-align: center;}
    .friApiResponse{ padding: 0;}
  }
</style>