<template>
    <div class="row w-100 mt-0">
      <div class="text-left mx-2 mt-0">
        <div class="w-100">
            <div v-if="layout.controller" style="min-height: 75vh !important;">
              <div class="row">
                <div class="inline-block" style="width: 66%; margin-left: 0; padding-left: 0;">
                  <!-- CONTROLLER INFO-->
                  <div v-if="layout.section == 'status'" class="px-3"><Status :controller="layout.controller" /></div>
                  <!-- DEVICES-->
                  <div v-if="layout.section == 'devices'">
                    <div v-if="layout.controller?.devices?.count">
                      <div class="px-3 mt-5">
                        <div v-for="device in layout.devices" class="mb-1" style="border-radius: 6px;" :key="device.id">
                          <div class="fw-bold text-white fs-5 friBack1 py-1 px-3 mb-1 br-5">{{ device.title }}</div>
                          <div class="mx-2 mt-2">
                            <div>Modelo: {{ device.brand }} ({{ device.model }})</div>
                            <div>Registro continuo: <span v-if="device?.cron?.active" class="friColor1">Activo (<span v-if="layout.controller?.cron?.interval">{{ layout.controller.cron.interval }}s</span>)</span><span v-else class="friColor3">Inactivo</span></div>
                            <div>Sondas: {{ device.sondas.count }}</div>
                            <div v-if="device.sondas.count">
                              <div v-for="sonda in device.sondas.sondas" :key="sonda.id" class="ps-2 text-xs">
                                {{ sonda.name.toString().substring(0,10) }}<span v-if="sonda.name.length > 10">...</span>
                                {{ sonda.input }} ({{ sonda.type }}) <span v-id="sonda.w1Id">({{ sonda.w1Id }})</span>
                              </div>
                            </div>
                            <div v-if="device?.cron?.active && device?.cron?.notify?.email">Notificaciones: 
                              <span class="friColor1">{{ layout.controller?.cron?.sendTo.toString().substring(0,18) }}<span v-if="layout.controller?.cron?.sendTo.length > 18" class="friColor1">...</span></span>
                            </div>
                            <div>Copiloto: <span v-if="device?.copilot" class="friColor1">Activo</span><span v-else class="friColor3">Inactivo</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="text-center pt-3">
                      <div><i class="bi bi-exclamation-triangle" style="font-size: 80px;"></i></div>
                      <div class="fs-3 px-2" style="line-height: 25px;">No dispone de equipos registrados</div>
                    </div>
                  </div>
                  <!-- NETWORK-->
                  <div v-if="layout.section == 'network'">
                    <div v-if="layout.controller?.net">
                      <div class="px-3 mt-5 ms-2">
                        <div class="fw-bold fs-3 mb-2">Red</div>
                        <div v-if="layout.controller?.status?.network">WLAN:
                          <span>
                            {{ layout.controller.status.network?.wifi?.ESSID.toString().substring(0,15) }}<span v-if="layout.controller.status.network?.wifi?.ESSID.length > 15">...</span>
                            ({{ layout.controller.status.network?.speed?.tx_rate }} {{ layout.controller.status.network?.speed?.unit }})
                          </span>
                        </div>
                        <div v-if="layout.controller?.status?.network">LAN: <span>{{ layout.controller.status.network?.ip }}</span></div>
                        <div v-if="layout.controller.net.zerotier">IP: <span class="fw-bold friColor1">{{ layout.controller.net.zerotier }}</span></div>
                      </div>
                    </div>  
                  </div>
                  <!-- BATTERY -->
                  <div v-if="layout.section == 'battery'">
                    <div class="mt-5 ms-3">
                      <div class="fw-bold fs-3 mb-2">Batería</div>
                      
                    </div>
                  </div>
                  <!-- RESTART -->
                  <div v-if="layout.section == 'restart'">
                    <div class="text-center friBack1 friRestartBox" v-if="layout.isRestart"> 
                       <div style="margin-top: 12%;"><i class="bi bi-record-circle text-white" style="font-size: 90px;"></i></div>
                       <div class="fw-bold fs-2 text-white">Reiniciando</div>
                       <div class="fw-bold fs-5 text-white">Espere por favor...</div>
                    </div>
                    <div class="mt-5 ms-3">
                      <div class="fw-bold fs-3">Reiniciar equipo</div>
                      <div class="text-left">
                        <div class="fw-bold">¿Seguro que desea reiniciar?</div>
                        <div class="friBack7 py-2 text-white mb-1 mt-3 w-50 text-center" @click="restartOS()" style="border-radius: 6px;">Si, reiniciar ahora</div>
                        <div class="text-xs mt-4 me-5">
                          Tenga en cuenta que el reinicio puede tardar varios minutos y que durante este tiempo no estará disponible el registro de datos.
                          <br>Utilice esta opción sólo si es estrictamente necesario. Contacte con el servicio técnico si tiene dudas.
                        </div>
                      </div>
                    </div>
                  </div> 
                </div>
                <div class="inline-block text-left" style="width: 30%; padding: 0 !important">
                  <!--<div class="py-2 px-3 mb-1 friBtnRightDash" :class="layout.section=='status' ? 'fw-bold fs-6 friBack1 text-white': 'friBack4 text-dark'" @click="layout.section='status'">Estado</div>
                  <div class="py-2 px-3 mb-1 friBtnRightDash" :class="layout.section=='devices' ? 'fw-bold fs-6 friBack1 text-white': 'friBack4 text-dark'" @click="layout.section='devices'">Equipos <span v-if="layout.controller.devices">({{ layout.controller.devices.count }})</span></div>
                  <div class="py-2 px-3 mb-1 friBtnRightDash" :class="layout.section=='network' ? 'fw-bold fs-5 friBack1 text-white': 'friBack4 text-dark'" @click="layout.section='network'">Red</div>
                  <div class="py-2 px-3 mb-1 friBtnRightDash" :class="layout.section=='battery' ? 'fw-bold fs-5 friBack1 text-white': 'friBack4 text-dark'" @click="layout.section='battery'">Batería</div>-->
                  <div class="py-2 px-3 mb-1 friBtnRightDash"  :class="layout.section=='restart' ? 'fw-bold fs-5 friBack1 text-white': 'friBack4 text-dark'" @click="layout.section='restart'">Reiniciar</div>
                  <div class="friBack4 py-3 px-3 text-dark mb-1 friBtnRightDash" @click="menuAction('menu')">Volver</div>
                </div>
              </div>
            </div>
            <div v-else class="mt-5 text-center"><img src="../../../assets/images/theme/loading.gif" style="width: 80px; margin: 50px auto"></div>
        </div>
      </div>
      
    </div>
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue';
import { useAuthStore } from '@/store';
import { requestDevice, getController,  } from "../../../model/api.js"; 
import { Chart, BarController, BarElement } from 'chart.js';
import { helper as $h } from "../../../utils/helper.js";

Chart.register(BarController, BarElement );

import Status from '@/components/pages/dash/controller/status.vue';

export default defineComponent({
name: 'DashControllerComponent',
props: {
  isMobile: Boolean,
},
components: { Status },
setup() {
    const authStore         = useAuthStore();
    const { user, profile } = authStore;
    const layout            = ref({ 
                                isMobile:     false,
                                controllerId: false,
                                section:      "status",
                                helper:       $h,
                                isRestart: false,
                              });

  const restartOS = async () => {
    layout.value.isRestart = true;
     await requestDevice("https://"+profile.controllerId+".fricontrolalba.com", "controller-actions/"+profile.controllerId+"/restart");
  }   

  const loadDevices = async () => { 
      layout.value.devices            = [];
      layout.value.sondas             = false;
      layout.value.sondaId            = false;
      layout.value.controller         = false;
      layout.value.ControllerDevices  = false;
      let ctrlOpt                     = {}
      if(layout.value.section == 'status')ctrlOpt = { ups: true }
      layout.value.controller         = await getController(profile.controllerId, ctrlOpt); 
      if(layout.value.controller && layout.value.controller.devices?.devices) {
          layout.value.ter = layout.value.controller.devices.devices;
          for (let deviceId in layout.value.ControllerDevices) {
              let device = layout.value.ControllerDevices[deviceId];
              layout.value.sondaId  = device.dash.sondaId
              layout.value.devices.push(device);
          }
      }
  }

  onMounted(async () => {
    await loadDevices();
    //layout.value.controller.client        = await getControllerClient(profile.controllerId)
    //layout.value.controller.users         = await getControllerUsers(profile.controllerId)
    //layout.value.controller.subscriptions = await getControllerSubsciptions(profile.controllerId)
  });

  return {user, profile, layout, restartOS, loadDevices };
},
methods: {
  menuAction(id) {
        this.$emit('menuAction', id); 
    },
},
});
</script>
<style>
.friBtnRightDash{ border-top-left-radius: 5px; border-bottom-left-radius: 5px; cursor: pointer; }
.friRestartBox{width: 100%; height: 100vh; position: fixed; top: 0; left: 0; z-index: 99;}
</style>