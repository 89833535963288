<template>
    <div class="row w-100">
        <i class="bi bi-gear" style="font-size: 80px;"></i>
        <h1 class="fs-1 fw-semibold">Configuración</h1>
        <div class="mt-3">Alba v.0.1</div>
    </div>
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue';
import { useAuthStore } from '@/store';

export default defineComponent({
name: 'DashConfigComponent',
props: {
  isMobile: Boolean,
},
components: {   },
setup() {
    const authStore         = useAuthStore();
    const { user, profile } = authStore;
    const layout            = ref({ 
                                isMobile: false,
                              });

  onMounted(async () => {
  });

  return {user, profile, layout };
},
methods: {},
});
</script>