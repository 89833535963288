<template>
    <div class="account-box is-form is-footerless mb-5" :style="isMobile ? 'border-bottom: none !important;' : ''">
        <template v-if="layout.app">
            <div class="form-head stuck-header" :class="isMobile ? 'py-0 ps-3 pe-3' : 'py-3'">
                <div class="form-head-inner">
                    <div class="left" v-if="layout.app?.name"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5 ms-2' : 'fs-4'">{{  layout.app.name.toString().substr(0, 24) }} {{ layout.app.name.toString().length > 24 ? '...' : '' }}</h4></div>
                    <div class="inline-block align-top me-1 fs-6 mt-0" style="margin-top: 4px;">
                        <template v-if="layout.app.logo"><img :src="layout.app.logo" style="height: 50px;" /></template>
                        <template v-else>{{  layout.app.id }}</template>
                    </div>
                </div>
            </div>
            <div class="mt-5" :class="isMobile ? 'friBackApp friBackWhite pt-0' : ''" v-if="layout.scenes && layout.scenes.scenes && Object.keys(layout.scenes.scenes).length">
                <div class="text-center fs-2 mt-3">Espacios</div>
                <div class="text-center mt-2 mb-5 mx-5 px-5">
                    Administre sus espacios y dispositivos.
                </div>

                <div v-for="(scene, index) in layout.scenes.scenes" :key="index" :class="(profile.isClient || profile.isAgent) || (scene.users && scene.users.includes(profile.uid)) ? '' : 'hidden'" style="margin: 0 32px 0 30px;">
                    <div class="fs-5 py-2 text-dark text-left ps-3 br-5 cursor-pointer mt-5"
                        @click="$router.push({ path: '/site/'+ layout.app.id + '/' + scene.sceneId, query: { section:  'dashboard' } })"  style="box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);"> 
                        <div class="inline-block align-middle fw-bold fs-5 p-0">
                            <ion-grid>
                                <ion-row class="p-0 m-0">
                                    <ion-col class="p-0 m-0 text-left">
                                        <i class="bi bi-record-circle friOpacity3" style="font-size: 50px; opacity: 0.5;" :class="scene.active ? 'friColor1' : 'friColor4'"></i>
                                    </ion-col>
                                    <ion-col class="p-0 m-0 text-left" size="auto"> 
                                        <div class="ms-4 mt-3">
                                            <div class="fs-5">{{ scene.name.toString() }}</div>
                                            <div class="fs-6 fw-normal p-0 m-0">{{ scene.typeName }}</div>
                                            <template v-if="profile && profile.isClient">
                                                <span class="text-xs3 fw-normal pe-2" v-if="scene.devices && scene.devices.length">{{ scene.devices.length }} dispositivos</span>
                                                <span class="text-xs3 fw-normal" v-if="profile.isClient && scene.users && scene.users.length">{{ scene.users.length }} <template v-if="scene.users.length == 1">usuario</template><template v-else>usuarios</template></span>
                                            </template>
                                        </div>
                                    </ion-col>
                                </ion-row>
                            </ion-grid>
                        </div>
                    </div>
                </div>

                 <!--
                <div v-for="(scene, index) in layout.scenes.scenes" :key="index" :class="profile.isClient || (scene.users && scene.users.includes(profile.uid)) ? '' : 'hidden'" style="margin: 0 32px 0 30px;">
                    <div class="fs-5 py-4 friBack9 text-dark text-left ps-3 br-5 pb-4 cursor-pointer mt-5"
                        @click="$router.push({ path: '/site/'+ layout.app.id + '/' + scene.sceneId, query: { section:  'dashboard' } })"  style="box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);"> 
                        <div class="inline-block align-middle fw-bold fs-3 p-0 pt-1">
                            {{ scene.name.toString() }}
                            <div class="fs-6 fw-normal p-0 m-0">{{ scene.typeName }}</div>
                            <div class="p-0 m-0">
                                <span class="text-xs3 fw-normal pe-4" v-if="scene.devices && scene.devices.length">{{ scene.devices.length }} dispositivos</span>
                                <span class="text-xs3 fw-normal" v-if="profile.isClient && scene.users && scene.users.length">{{ scene.users.length }} <template v-if="scene.users.length == 1">usuario</template><template v-else>usuarios</template></span>
                            </div>
                        </div>
                        <span class="badge friColor4 fs-6 text-end float-end"> <i class="bi bi-chevron-right align-middle friOpacity1 friColor4" style="font-size: 80px;"></i></span>
                    </div>
                    <div class="my-3 px-5 mt-2 text-xs3">
                        <div v-if="scene.devices && scene.devices.length" style="margin: 50px 0;">
                            <div class="scene_network">
                                <div class="scene_router">Alba Hub</div>
                                <div v-for ="(device, index) in scene.devices" :key="index" class="scene_device cursor-pointer" 
                                @click="$router.push({ path: '/sensor/'+ layout.app.id + '/' + device, query: { section:  'dashboard' } })"
                                :class="layout.app.devices?.devices[device]?.status?.alarm?.status && layout.app.devices?.devices[device]?.status?.alarm?.alarms ? 'friBack7 text-white' : layout.app.devices?.devices[device]?.active ? 'friBack1 text-white' : 'friBack4 text-dark'">
                                <div class="py-1">
                                    <div class="text-white px-2 pt-2 fw-bold pb-1 text-xs3">{{ layout.app.devices?.devices[device]?.name.toString().substr(0, 14) }} {{ layout.app.devices?.devices[device]?.name.toString().length > 14 ? '...' : '' }} <i class="bi bi-chevron-right"></i></div>
                                        <ion-grid class="w-100 p-0 m-0">
                                            <ion-row class="text-xs3 px-2 pb-0 mb-0">
                                                <ion-col style="max-width: 30px" class="p-0 m-0 text-center">
                                                    <template v-if="layout.app.devices?.devices[device]?.status?.msg && layout.app.devices?.devices[device]?.status?.msg.hasOwnProperty('DOOR_OPEN_STATUS')"> 
                                                        <i v-if="layout.app.devices?.devices[device]?.status?.msg?.DOOR_OPEN_STATUS" class="bi bi-door-open text-white align-top friColor3" :class="isMobile ? 'fs-2' : 'fs-3'"></i>
                                                        <i v-else class="bi bi-door-closed text-white align-top" :class="isMobile ? 'fs-2' : 'fs-3'"></i>
                                                    </template>
                                                    <template v-else>
                                                        <i v-if="!layout.app.devices?.devices[device]?.isSensor" class="bi bi-snow3 text-white align-top" :class="isMobile ? 'fs-2' : 'fs-3'"></i>
                                                        <i v-else class="bi bi-wifi text-white align-top" :class="isMobile ? 'fs-2' : 'fs-3'"></i>
                                                    </template>
                                                </ion-col>
                                                <ion-col class="pe-0 ps-2 m-0 align-top">
                                                    <template v-if="layout.app.devices?.devices[device]?.status?.msg && layout.app.devices?.devices[device]?.status?.msg.hasOwnProperty('TempC_SHT')">
                                                        <div class="mt-1 text-white fw-bold text-left" v-if="layout.app.devices?.devices[device]?.status?.msg" style="font-size: 24px; line-height: 18px;">
                                                            <span :class="{ 'friColor3': layout.app.devices?.devices[device]?.status.alarm && layout.app.devices?.devices[device]?.status.alarm.alarms && Object.values(layout.app.devices?.devices[device]?.status.alarm.alarms).some(alarm => alarm.paramSensor === 'TempC_SHT') }">
                                                                {{ parseFloat(layout.app.devices?.devices[device]?.status.msg.TempC_SHT).toFixed(1) }}
                                                                <span class="text-xs align-middle">ºC</span>
                                                            </span>
                                                        </div>
                                                        <div class="fs-6 friColor2 text-xs text-left" v-if="layout.app.devices?.devices[device]?.status.msg?.Hum_SHT">
                                                            <span class="fw-bold" :class="{ 'friColor3': layout.app.devices?.devices[device]?.status.alarm && layout.app.devices?.devices[device]?.status.alarm.alarms && Object.values(layout.app.devices?.devices[device]?.status.alarm.alarms).some(alarm => alarm.paramSensor === 'Hum_SHT') }">{{ layout.app.devices?.devices[device]?.status.msg.Hum_SHT }}%</span>
                                                        </div>
                                                    </template>
                                                    <div v-if="layout.app.devices?.devices[device]?.status?.msg && layout.app.devices?.devices[device]?.status?.msg.hasOwnProperty('DOOR_OPEN_STATUS')" class="text-left ps-2"> 
                                                        <span class="px-2 br-5 friColor2 fw-bold fs-6" v-if="layout.app.devices?.devices[device]?.status?.msg?.DOOR_OPEN_STATUS">Abierta</span>
                                                        <span v-else class="text-white fs-6">Cerrada</span>
                                                    </div>
                                                </ion-col>
                                            </ion-row>
                                            <div v-if="layout.app.devices?.devices[device]?.updatedAt" class="text-center pb-2">
                                                <span class="fw-normal text-white text-xs">{{ layout.helper.getDateFormatFb(layout.app.devices?.devices[device]?.status.date, "time") }}</span>
                                            </div>
                                        </ion-grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="text-center"><div>No hay dispositivos vinculados</div></div>
                    </div>
                </div>
                -->
            </div>
        </template>
        <template v-else>
            <div class="text-center w-100"  style="margin-top: 150px;">
                <div class="fw-bold fs-1">Alba Hub</div>
                <div class="mt-2">Espere por favor ...</div>
                <div class="spinner-border friColor1 mt-5" role="status">
                <span class="visually-hidden">Cargando ...</span>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue"; //onUnmounted
import { useAuthStore } from "@/store";
import { getApp, getAppScenes, getAppDevices } from "../../../model/apps.js"; //, requestDevice
import { helper as $h } from "../../../utils/helper.js";
import router from "@/router";
import { IonRow, IonCol, IonGrid } from '@ionic/vue'; 
//import { _firebase as $_firebase } from "../../../model/firebase";
//import { onSnapshot, collection } from "firebase/firestore"; 

//const db   =  $_firebase.firestore()

export default defineComponent({
name: 'DeviceDash',
props: {
    isMobile: Boolean,
    appId: String
    },
    components: { IonRow, IonCol, IonGrid }, //
setup(props) {

    const authStore      = useAuthStore();
    const layout = ref({ 
                        appId:      null,
                        scenes:     null,
                        helper:     $h,
                        })

    const { user, profile } = authStore;

    const initDash = async () => {
        layout.value.appId         = router.currentRoute.value.params.appId
        layout.value.app           = await getApp(layout.value.appId)
        layout.value.app.devices   = await getAppDevices(layout.value.appId)
        layout.value.scenes        = await getAppScenes(layout.value.appId)
    }

    onMounted( async () => { 
        layout.value.appId         = router.currentRoute.value.params.appId
        await initDash();
        //await detectDevicesChanges();   
    });

    /*const detectDevicesChanges = async () => {
        const collectionPath = "apps/" + props.appId + "/devices/";
        const collRef        = collection(db, collectionPath);
        const unsubscribeDetectDevicesChanges = onSnapshot(collRef, async (querySnapshot) => {
            querySnapshot.docChanges().forEach(async change => { 
                if (change.type === "modified")await initDash();
            }); 
        }, (error) => { console.log("Error:", error); });
        onUnmounted(() => { unsubscribeDetectDevicesChanges(); });
    };*/
    
    return { layout, user, props, profile };
}

})
</script>