
  import { defineComponent, onMounted, ref, watch } from "vue"; 
  import { useAuthStore } from "@/store";
  import { useRoute } from 'vue-router'
  import router from "@/router";

  import PlanDash from '@/components/pages/plan/dashboard.vue';
  import Payment from '@/components/pages/plan/payment.vue';
  import Docs from '@/components/pages/plan/documents.vue';

  export default defineComponent({
    name: 'PlanPage',
    props: {
        isMobile: Boolean,
     },
    components: { PlanDash, Payment, Docs },
    setup() {
  
       const authStore      = useAuthStore();
       const route          = useRoute()
       const layout         = ref({ 
                                clientId: route.params.clientId,
                                planId: route.params.planId,
                                query:    null,
                                section:  null,
                                app:      null,
                                sections: [
                                    { name: 'dashboard', title: 'Suscripción', icon: 'bi bi-router'},
                                ],
                            }) 

       const { user, profile } = authStore;
        

       const reLoad = () => { window.location.reload(); }

       const goBack = () => { 
        router.push({ path: '/client/' + layout.value.clientId, query: { section:  'plans' } })
       }

       const initPlan= async () => {
        if(!layout.value.section)
          if(route.query.section) layout.value.section = route.query.section
          else layout.value.section = 'dashboard'
       } 

       onMounted( async () => {
        await initPlan()
       });
  
       watch(() => route.query, async (query) => {
            if (query){
                layout.value.query   = query; 
                layout.value.section = query.section;
            }
       });

       return { layout, user, profile, reLoad, goBack };
    }
    
  })
