import { useAuthStore } from "@/store";
import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

import { getPlatforms } from '@ionic/vue';

import PageContainer from '@/components/Page.vue';
import LegalPage     from '@/components/pages/legal.vue';
import TabsTest      from '@/components/pages/tabs/test.vue';
//import EmptyMenu     from '@/components/menu/empty.vue'; 
 /* 
 * @param to 
 * @param from 
 * @param next 
 */

const authCheck = async (to: any, from: any, next: any) => {
  const store          = useAuthStore();
  const cid            = to.query.cid;
  const { logInUser }  = store;
  if (store.isLoggedIn) {
    if (cid) {
      next("/dash");
    }else{
      if (to.name === "login") {
        next({ name: "home" });
      } else {
        next();
      }
    } 
  } else {
    if (cid) {
        try {
          console.log('Autenticando con CID:', cid.toString().toLowerCase())
          await logInUser(cid.toString().toLowerCase()+"@fricontrolalba.com", cid.toString().toLowerCase()).then( (() => {
              if(store.error)console.log('Error autenticando con CID:', store.error)
              else router.replace("/dash/");
          }));
        } catch (error) {
          console.error("Error autenticando con CID:", error);
          next({ name: "login" }); // Si hay un error, redirige a 'login'.
        }
    } else{ 
      if (to.name === "login") {
        next();
      } else {
        next({ name: "login" });
      }
    }
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    beforeEnter: (to, from, next) => {
      const cid = to.query.cid;
      if (cid) {
        next("/dash");
      } else {
        next();
      }
    },
    redirect: "/home/",
  },
  {
    name: "login",
    path: "/login",
    component: PageContainer,
    props: { tab: 'login' },
    //beforeEnter: authCheck,
  },
  {
    name: "legal",
    path: "/legal",
    component: LegalPage,
    props: true,
  },
  {
    name: "apk",
    path: "/apk",
    component: PageContainer,
    props: { tab: 'apk' },
    beforeEnter: authCheck,
  },
  {
    path: "/alba/",
    name: "alba",
    component: () => {
      const platforms = getPlatforms();
      if(platforms && !platforms.includes('desktop')) { return import('@/components/pages/tabs/alba.vue');
      } else { return import('@/components/pages/tabs/alba.vue') }
    },
    children: [
      {
      path: "",
      name: "",
      component: PageContainer,
      props: { tab: 'alba', title: '',  topmenu: false },
      beforeEnter: authCheck,
      },
    ],
  },
  /*{
    name: "apps",
    path: "/apps",
    component: PageContainer,
    props: { tab: 'apps' },
    beforeEnter: authCheck,
  },*/
  {
    path: "/clients/",
    name: "Clientes",
    component: () => {
      const platforms = getPlatforms();
      if(platforms && !platforms.includes('desktop')) { return import('@/components/pages/tabs/clients.vue');
      } else { return import('@/components/pages/tabs/clients.vue') }
    },
    children: [
      {
      path: "",
      name: "",
      component: PageContainer,
      props: { tab: 'clients', title: '',  topmenu: false, onlyDesktop: true },
      beforeEnter: authCheck,
      },
    ],
  },
  {
    path: "/client/",
    component: () => {
      const platforms = getPlatforms();
      if(platforms && !platforms.includes('desktop')) { return import('@/components/pages/tabs/client.vue');
      } else { return import('@/components/menu/desktop.vue') }
    },
    children: [
      {
        path: "/client/:clientId",
        name: "Client",
        component: PageContainer,
        props: { tab: 'client', title: '', topmenu: false, onlyDesktop: true },
        beforeEnter: authCheck,
      },
    ],
  },
  {
    path: "/user/",
    component: () => {
      const platforms = getPlatforms();
      if(platforms && !platforms.includes('desktop')) { return import('@/components/pages/tabs/user.vue');
      } else { return import('@/components/menu/desktop.vue') }
    },
    children: [
      {
        path: "/user/:appId/:userId",
        name: "user",
        component: PageContainer,
        props: { tab: 'user', title: '', topmenu: false },
        beforeEnter: authCheck,
      },
    ],
  },
  {
    path: "/apps/",
    name: "Apps",
    component: () => {
      const platforms = getPlatforms();
      if(platforms && !platforms.includes('desktop')) { return import('@/components/pages/tabs/apps.vue');
      } else { return import('@/components/pages/tabs/apps.vue') }
    },
    children: [
      {
      path: "",
      name: "",
      component: PageContainer,
      props: { tab: 'apps', title: '',  topmenu: false, onlyDesktop: true },
      beforeEnter: authCheck,
      },
    ],
  },
  {
    path: "/app/",
    component: () => {
      const platforms = getPlatforms();
      if(platforms && !platforms.includes('desktop')) { return import('@/components/pages/tabs/app.vue');
      } else { return import('@/components/menu/desktop.vue') }
    },
    children: [
      {
        path: "/app/:appId",
        name: "App",
        component: PageContainer,
        props: { tab: 'app', title: '', topmenu: false, onlyDesktop: true },
        beforeEnter: authCheck,
      },
    ],
  },
  {
    name: "Dash",
    path: "/dash",
    component: PageContainer,
    props: { tab: 'deviceDash'}
  },
  {
    path: "/home/",
    name: "home",
    component: () => {
      const platforms = getPlatforms();
      if(platforms && !platforms.includes('desktop')) { return import('@/components/menu/mobile.vue');
      } else { return import('@/components/menu/desktop.vue') }
    },
    children: [
      {
      path: "",
      name: "",
      component: PageContainer,
      props: { tab: 'home', title: 'Dashboard', topmenu: true },
      beforeEnter: authCheck,
      },
    ],
  },
  {
    path: "/jobs/",
    name: "jobs",
    component: () => {
      const platforms = getPlatforms();
      if(platforms && !platforms.includes('desktop')) { return import('@/components/pages/tabs/jobs.vue');
      } else { return import('@/components/pages/tabs/jobs.vue') }
    },
    children: [
      {
      path: "",
      name: "",
      component: PageContainer,
      props: { tab: 'jobs', title: 'Jobs', topmenu: false },
      beforeEnter: authCheck,
      },
    ],
  },
  {
    path: "/tabs/",
    component: () => {
      const platforms = getPlatforms();
      if(platforms && !platforms.includes('desktop')) { return import('@/components/menu/mobile.vue');
      } else { return import('@/components/menu/desktop.vue') }
    },
    children: [
      {
        path: "",
        redirect: "/home/",
      },
      {
        path: "create",
        name: "create",
        component: PageContainer,
        props: { tab: 'create', title: 'Registro', topmenu: true },
        beforeEnter: authCheck,
      },
      {
        path: "stats",
        name: "Stats",
        component: PageContainer,
        props: { tab: 'stats', title: 'Registros', topmenu: true },
        beforeEnter: authCheck,
      },
      {
        path: "pingfine",
        name: "Pingfine",
        component: PageContainer,
        props: { tab: 'pingfine', title: 'Pingfine', topmenu: true },
        beforeEnter: authCheck,
      },
    ],
  },
  {
    path: "/remote/",
    component: () => {
      const platforms = getPlatforms();
      if(platforms && !platforms.includes('desktop')) { return import('@/components/pages/tabs/remote.vue');
      } else { return import('@/components/menu/desktop.vue') }
    },
    children: [
      {
        path: "",
        name: "Remote Control",
        component: PageContainer,
        props: { tab: 'remote', title: '', topmenu: true },
        beforeEnter: authCheck,
      },
      {
        path: "actions",
        name: "Remote Actions",
        component: PageContainer,
        props: { tab: 'remote', title: '', topmenu: true },
        beforeEnter: authCheck,
      },
      {
        path: "energy",
        name: "Energy saving mode",
        component: PageContainer,
        props: { tab: 'remote', title: '', topmenu: true },
        beforeEnter: authCheck,
      },
    ],
  },
  {
    path: "/profile/",
    component: () => {
      const platforms = getPlatforms();
      if(platforms && !platforms.includes('desktop')) { return import('@/components/pages/tabs/profile.vue');
      } else { return import('@/components/menu/desktop.vue') }
    },
    children: [
      {
        path: "",
        name: "Profile",
        component: PageContainer,
        props: { tab: 'profile', title: '', topmenu: false },
        beforeEnter: authCheck,
      },
      /*
      {
        path: "subscription",
        name: "Subscription",
        component: PageContainer,
        props: { tab: 'profile', title: '', topmenu: true },
        beforeEnter: authCheck,
      },
      {
        path: "logout",
        name: "Logout",
        component: PageContainer,
        props: { tab: 'profile', title: '', topmenu: true },
        beforeEnter: authCheck,
      },*/
    ],
  },
  {
    path: "/test/",
    component: TabsTest,
    children: [
      {
        path: "",
        name: "test",
        component: PageContainer,
        props: { tab: 'test', title: 'test', topmenu: true },
        beforeEnter: authCheck,
      },
    ],
  },
  {
    path: "/devices/",
    name: "Devices",
    component: () => {
      const platforms = getPlatforms();
      if(platforms && !platforms.includes('desktop')) { return import('@/components/pages/tabs/devices.vue');
      } else { return import('@/components/pages/tabs/devices.vue') }
    },
    children: [
      {
      path: "",
      name: "",
      component: PageContainer,
      props: { tab: 'devices', title: '', topmenu: false },
      beforeEnter: authCheck,
      },
    ],
  },
  {
    path: "/sensor/",
    component: () => {
      const platforms = getPlatforms();
      if(platforms && !platforms.includes('desktop')) { return import('@/components/pages/tabs/sensor.vue');
      } else { return import('@/components/menu/desktop.vue') }
    },
    children: [
      {
        path: "/sensor/:appId/:deviceId",
        name: "sensor",
        component: PageContainer,
        props: { tab: 'sensor', title: '', topmenu: false },
        beforeEnter: authCheck,
      },
    ],
  },
  {
    path: "/sem/",
    component: () => {
      const platforms = getPlatforms();
      if(platforms && !platforms.includes('desktop')) { return import('@/components/pages/tabs/sem.vue');
      } else { return import('@/components/menu/desktop.vue') }
    },
    children: [
      {
        path: "/sem/:appId/:deviceId",
        name: "Sensor Management",
        component: PageContainer,
        props: { tab: 'sem', title: '', topmenu: false },
        beforeEnter: authCheck,
      },
    ],
  },
  {
    path: "/site/",
    component: () => {
      const platforms = getPlatforms();
      if(platforms && !platforms.includes('desktop')) { return import('@/components/pages/tabs/site.vue');
      } else { return import('@/components/menu/desktop.vue') }
    },
    children: [
      {
        path: "/site/:appId/:SceneId",
        name: "Site",
        component: PageContainer,
        props: { tab: 'site', title: '', topmenu: false },
        beforeEnter: authCheck,
      },
    ],
  },
  {
    path: "/alarm/",
    component: () => {
      const platforms = getPlatforms();
      if(platforms && !platforms.includes('desktop')) { return import('@/components/pages/tabs/alarm.vue');
    } else { return import('@/components/menu/desktop.vue') } //} else { return EmptyMenu }
    },
    children: [
      {
        path: "/alarm/:appId/:deviceId/:alarmId",
        name: "alarm",
        component: PageContainer,
        props: { tab: 'alarm', title: '', topmenu: false },
        beforeEnter: authCheck,
      },
    ],
  },
  {
    path: "/alert/",
    component: () => {
      const platforms = getPlatforms();
      if(platforms && !platforms.includes('desktop')) { return import('@/components/pages/tabs/alert.vue');
      } else { return import('@/components/menu/desktop.vue') }//} else { return EmptyMenu }
    },
    children: [
      {
        path: "/alert/:appId/:deviceId/:alarmCode",
        name: "alert",
        component: PageContainer,
        props: { tab: 'alert', title: '', topmenu: false },
        beforeEnter: authCheck,
      },
    ],
  },
  {
    path: "/api/",
    name: "Api",
    component: PageContainer,
    props: { tab: 'api', title: '', topmenu: false },
    beforeEnter: authCheck,
  },
  {
    path: "/gateway/",
    name: "AlbaHub",
    component: PageContainer,
    props: { tab: 'gateway', title: 'AlbaHub', topmenu: false },
    beforeEnter: authCheck,
  },
  {
    path: "/controller/",
    component: () => {
      const platforms = getPlatforms();
      if(platforms && !platforms.includes('desktop')) { return import('@/components/pages/tabs/controller.vue');
      } else { return import('@/components/menu/desktop.vue') }
    },
    children: [
      {
        path: "/controller/:controllerId",
        name: "controller",
        component: PageContainer,
        props: { tab: 'controller', title: '', topmenu: true },
        beforeEnter: authCheck,
      },
    ],
  },
  {
    path: "/hub/",
    component: () => {
      const platforms = getPlatforms();
      if(platforms && !platforms.includes('desktop')) { return import('@/components/pages/tabs/hub.vue');
      } else { return import('@/components/menu/desktop.vue') }
    },
    children: [
      {
        path: "/hub/:gatewayId",
        name: "hub",
        component: PageContainer,
        props: { tab: 'hub', title: '', topmenu: true },
        beforeEnter: authCheck,
      },
    ],
  },
  {
    path: "/device/",
    component: () => {
      const platforms = getPlatforms();
      if(platforms && !platforms.includes('desktop')) { return import('@/components/pages/tabs/device.vue');
      } else { return import('@/components/menu/desktop.vue') }
    },
    children: [
      {
        path: "/device/:appId",
        name: "device",
        component: PageContainer,
        props: { tab: 'device', title: '', topmenu: false },
        beforeEnter: authCheck,
      },
    ],
  },
  {
    path: "/plan/",
    component: () => {
      const platforms = getPlatforms();
      if(platforms && !platforms.includes('desktop')) { return import('@/components/pages/tabs/plan.vue');
      } else { return import('@/components/menu/desktop.vue') }
    },
    children: [
      {
        path: "/plan/:clientId/:planId",
        name: "plan",
        component: PageContainer,
        props: { tab: 'plan', title: '', topmenu: false },
        beforeEnter: authCheck,
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
