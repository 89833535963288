import { initializeApp, firebase } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import 'firebase/database';
import 'firebase/functions';

/*
//PINGFINE
const firebaseConfig = {
    apiKey:         'AIzaSyApDb6rjlmSQRWonADhMmghLBTLI1_xc4Q',
    authDomain:     "576298853572-qf8tr99p8f0ad7ig79f7psr6nqgogd7i.apps.googleusercontent.com", 
    databaseURL:    "pingfine-89e26.europe-west3.firebasedatabase.app",
    projectId:      'pingfine-89e26',
    storageBucket:  'pingfine-89e26.appspot.com', 
    messagingSenderId: '576298853572',
    appId:          '1:576298853572:android:bb3f12930803aebef29591'
};

ALBA
const firebaseConfig = {
    apiKey: "AIzaSyAIVOs_SCpynjbDfrVI3XdwPJkpXwScDXY",
    authDomain: "alba-33e43.firebaseapp.com",
    projectId: "alba-33e43",
    storageBucket: "alba-33e43.appspot.com",
    messagingSenderId: "868763549409",
    appId: "1:868763549409:web:29900caba75b18564541a0",
    measurementId: "G-G5E7DXW95B"
  };
  
FRICONTROL APP
*/
const firebaseConfig = {
    apiKey: "AIzaSyCLI_SwtYfAOVzBnMvnaByfiaofP6Zv_Ew",
    authDomain: "fricontrol-app.firebaseapp.com",
    projectId: "fricontrol-app",
    storageBucket: "fricontrol-app.appspot.com",
    messagingSenderId: "395841045098",
    appId: "1:395841045098:web:e060b7ea2164eaacdbc013"
};

const firebaseApp = initializeApp(firebaseConfig);
const analytics   = getAnalytics(firebaseApp);
//firebaseApp.firestore().settings({ timestampsInSnapshots: true })
export const db = getFirestore(firebaseApp);

const _firebase = {
    getConfig() { return firebaseConfig },
    firebase() { return firebase},
    firestore() {
        let db = getFirestore(firebaseApp);
        return db;
    },
    database() {
        let rdb = firebase.database();
        return rdb;
    },
    auth() {
        let auth = getAuth(firebaseApp);
        return auth;
    },
    storage() {
        const storage = firebase.storage();
        return storage;
    },
    functions() {
        const functions = firebase.functions();
        return functions;
    },
    analytics() {
        return analytics;
    }
};

const install = app => {
    app.config.globalProperties.$firebase = _firebase;
};

export { install as default, _firebase as _firebase };