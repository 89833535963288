import axios from 'axios';

const OdooReq = async (opt, data = {}) => {
    let resp = { error: false };
    try {
        let queryParams         = new URLSearchParams(data).toString();
        let encodedQueryParams  = encodeURIComponent(queryParams);
        let url                 = `https://iot.fricontrolalba.com/webhook/odoo?option=${opt}&optionData=${encodedQueryParams}`;
        let response             = await axios.get(url , { headers: {"Content-Type": "application/json" } });
        if (response.data) {
            if (response.data.error)resp.error = true;
            resp.response = response.data; 
        }else resp.error = true;
    } catch (error) { console.error("Error:", error); resp.error = true; }
    return resp;
};


export { OdooReq };
