<template>
    <div>
        <div class="account-box is-form is-footerless px-0 mx-0" :style="isMobile ? 'border-bottom: none !important;' : ''">
            <div class="form-head stuck-header" :class="isMobile ? 'py-0 ps-3 pe-2' : 'py-3'" v-if="layout.app">
                <div class="form-head-inner">
                    <div class="left"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5' : 'fs-4'">{{  layout.app.name }} </h4></div>
                    <div class="inline-block align-top fs-6 mt-0" style="margin-top: 4px;">
                        <template v-if="layout.app.logo"><img :src="layout.app.logo" style="height: 50px;" /></template>
                        <template v-else>{{  layout.app.id }}</template>
                    </div>
                </div>
            </div>
            <div class="px-5 pt-3">
                <div class="text-center fs-1 mt-3">Configuración</div>
                <div class="text-center mt-2 mb-3" v-if="layout.scene?.typeName">{{ layout.scene.scene.typeName }}.</div>
            </div>  
            <div class="px-3" v-if="layout.scene?.scene">
                <ion-list>
                    <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                        <ion-label position="floating" class="friColor1 fs-6">Nombre *</ion-label>
                        <ion-input v-model="layout.scene.scene.name" type="text" ></ion-input>
                    </ion-item>
                    <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                        <ion-label position="floating" class="friColor1 fs-6">Tipología de producto *</ion-label>
                        <ion-input v-model="layout.scene.scene.specs.product.type" type="text"></ion-input>
                    </ion-item>
        
                    <template v-if="layout.app && layout.scene?.scene && profile.isClient">
                        <div class="text-left px-4 pt-5 fs-6 fw-bold">Usuarios</div>
                        <div class="d-flex justify-content-center align-items-center fs-5 mt-1">
                            <template v-if="layout.app.users && layout.app.users.users && Object.keys(layout.app.users.users).length && layout.scene?.scene?.users">
                                <div class="friUserCircleMin me-2 friBack5" v-for="(user, index) in layout.scene.scene.users" :key="index" @click="layout.modalUsers = user">{{ layout.app.users.users[user]?.first[0] }}{{ layout.app.users.users[user]?.last[0] }}</div>
                            </template>
                            <div class="friUserCircleMin friBack4 cursor-pointer" v-if="layout.app.users && layout.app.users.users && (Object.keys(layout.app.users.users).length > layout.scene?.scene?.users.length)"
                            @click="layout.modalUsers = 'add'"><i class="bi bi-plus-lg text-dark"></i></div>
                        </div>
                    </template>

                    <div class="text-left px-4 pt-5 fs-6 fw-bold">Dimensiones</div>
                    <div class="fs-3 text-right mx-2">
                        <table class="table table-borderless table-sm" v-if="layout.scene.scene.specs">
                            <tr>
                                <td class="fs-6 text-left py-3">Ancho *</td>
                                <td class="fs-5 friColor1">{{ parseFloat(layout.scene.scene.specs.room.width).toFixed(2)  }}<span class="fs-6 ps-1 friColor1">m</span></td>
                                <td class="fs-6"><i class="cursor-pointer bi bi-pencil fs-5" @click="layout.modalParam = 'room-width'; "></i></td>
                            </tr>
                            <tr>
                                <td class="fs-6 text-left py-3">Largo *</td>
                                <td class="fs-5 friColor1">{{ parseFloat(layout.scene.scene.specs.room.length).toFixed(2)  }}<span class="fs-6 ps-1 friColor1">m</span></td>
                                <td class="fs-6"><i class="cursor-pointer bi bi-pencil fs-5" @click="layout.modalParam = 'room-length'; "></i></td>
                            </tr>
                            <tr>
                                <td class="fs-6 text-left py-3">Alto *</td>
                                <td class="fs-5 friColor1">{{ parseFloat(layout.scene.scene.specs.room.height).toFixed(2)  }}<span class="fs-6 ps-1 friColor1">m</span></td>
                                <td class="fs-6"><i class="cursor-pointer bi bi-pencil fs-5" @click="layout.modalParam = 'room-height'; "></i></td>
                            </tr>
                        </table>
                    </div>
                    <template v-if="layout.scene.scene.type == 'coldroom'">
                        <div class="text-left px-4 pt-1 fs-6 fw-bold">Puerta</div>
                        <div class="fs-3 text-right mx-2">
                            <table class="table table-borderless table-sm" v-if="layout.scene.scene.specs?.door">
                                <tr>
                                    <td class="fs-6 text-left py-3">Ancho *</td>
                                    <td class="fs-5 friColor1">{{  parseFloat(layout.scene.scene.specs.door.width).toFixed(2)  }}<span class="fs-6 ps-1 friColor1">m</span></td>
                                    <td class="fs-6"><i class="cursor-pointer bi bi-pencil fs-5" @click="layout.modalParam = 'door-width'; "></i></td>
                                </tr>
                                <tr>
                                    <td class="fs-6 text-left py-3">Altura *</td>
                                    <td class="fs-5 friColor1">{{  parseFloat(layout.scene.scene.specs.door.height).toFixed(2)  }}<span class="fs-6 ps-1 friColor1">m</span></td>
                                    <td class="fs-6"><i class="cursor-pointer bi bi-pencil fs-5" @click="layout.modalParam = 'door-height'; "></i></td>
                                </tr>
                                <tr>
                                    <td class="fs-6 text-left py-3">Espesor  *</td>
                                    <td class="fs-5 friColor1">{{  parseFloat(layout.scene.scene.specs.thickness).toFixed(2)  }}<span class="fs-6 ps-1 friColor1">mm</span></td>
                                    <td class="fs-6"><i class="cursor-pointer bi bi-pencil fs-5" @click="layout.modalParam = 'thickness'; "></i></td>
                                </tr>
                            </table>
                        </div>
                        <div class="text-left px-4 pt-1 fs-6 fw-bold">Compresor</div>
                        <ion-row>
                            <ion-col>
                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                    <ion-label position="floating" class="friColor1 fs-6">Marca *</ion-label>
                                    <ion-input v-model="layout.scene.scene.specs.compressor.brand" type="text" ></ion-input>
                                </ion-item>
                            </ion-col>
                            <ion-col>
                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                    <ion-label position="floating" class="friColor1 fs-6">Modelo *</ion-label>
                                    <ion-input v-model="layout.scene.scene.specs.compressor.model" type="text" ></ion-input>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                    <ion-label position="floating" class="friColor1 fs-6">Tipo de refrigerante *</ion-label>
                                    <ion-input v-model="layout.scene.scene.specs.compressor.refrigerant" type="text" ></ion-input>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                    </template>
                    <div :style="isMobile ? 'margin-top: 30px; margin-bottom: 80px;' : 'margin: 30px 0;'">
                        <div class="d-flex justify-content-center align-items-center fs-5">
                        <div class="fw-bold friColor1 text-end float-end cursor-pointer" @click="saveScene()">GUARDAR</div>
                        </div>
                    </div>
                </ion-list>
                
                <!-- Manage Users scene -->
                <ion-modal :is-open="layout.modalUsers" ref="modal" :initial-breakpoint="0.5" :breakpoints="[0.5, 0.75, 1]" :backdrop-dismiss="false">
                    <ion-content class="ion-padding">
                        <div class="my-5 me-5 ms-4">
                            <div class="fs-4">{{ layout.scene.scene.name }}</div>
                            <div>Usuarios</div>
                            <div class="py-4" v-if="layout.modalUsers == 'add'">
                                <div class="fs-6 pb-2">Seleccione un usuario, tendrá acceso al lugar y a la recepción de notificaciones de alarma.</div>
                                <div class="d-flex justify-content-center mt-4">
                                    <div class="friUserCircle cursor-pointer me-3" :class="layout.scene?.scene?.users.includes(index) ? 'hidden' : layout.addSceneUser && index == layout.addSceneUser ? 'friBack1' : 'friBack4 text-dark'"
                                        v-for="(user, index) in layout.app.users.users" :key="index" @click="layout.addSceneUser = index">
                                        {{ layout.app.users.users[index]?.first[0] }}{{ layout.app.users.users[index]?.last[0] }}
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center mt-5 pt-2">
                                    <button class="button px-5 py-3 m-0 mt-0 bg-light" @click="layout.modalUsers = false;layout.addSceneUser = false;">Cancelar</button>
                                    <button class="button px-5 py-3 mt-0 ms-3 friBack1 text-white" v-if="layout.addSceneUser" @click="addSceneUser()">Aceptar</button>
                                </div>
                            </div>
                            <div v-else>
                                <template v-if="layout.app.users.users[layout.modalUsers]">
                                    <div class="pt-5 text-center fs-2 fw-bold">{{ layout.app.users.users[layout.modalUsers]?.first }} {{ layout.app.users.users[layout.modalUsers]?.last }}</div>
                                    <div class="text-center fs-6">{{ layout.app.users.users[layout.modalUsers]?.email }}</div>
                                    <div class="d-flex justify-content-center mt-5 pt-5">
                                        <button class="button px-5 py-2 m-0 mt-0 bg-light" @click="layout.modalUsers = false; layout.addSceneUser = false;">Cancelar</button>
                                        <button class="button px-5 ms-3 py-2 mt-0 friBack12 text-white" @click="delSceneUser()">Eliminar</button>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </ion-content>
                </ion-modal>   

                <!-- Edit Params -->
                <ion-modal :is-open="layout.modalParam" ref="modal" :initial-breakpoint="0.5" :breakpoints="[0.5, 0.75, 1]" :backdrop-dismiss="false">
                    <ion-content class="ion-padding">
                        <div class="m-5">
                            <div v-if="layout.scene?.typeName" class="mb-2">{{ layout.scene.scene.typeName }}.</div>
                            <template v-if="layout.modalParam == 'room-width'">
                                <div class="fs-3">Ancho *</div>
                                <div class="fs-6">Indique el ancho del lugar en metros.</div>
                                <div class="pt-3 text-center fw-bold" style="font-size: 50px;">{{ parseFloat(layout.scene.scene.specs.room.width).toFixed(2)  }}</div>
                                <div><ion-range v-model="layout.scene.scene.specs.room.width" min="1" max="10" step="0.01" class="p-0 mx-5" ></ion-range></div>
                            </template>
                            <template v-if="layout.modalParam == 'room-length'">
                                <div class="fs-3">Largo *</div>
                                <div class="fs-6">Indique el largo del lugar en metros.</div>
                                <div class="pt-3 text-center fw-bold" style="font-size: 50px;">{{ parseFloat(layout.scene.scene.specs.room.length).toFixed(2)  }}</div>
                                <div><ion-range v-model="layout.scene.scene.specs.room.length" min="1" max="10" step="0.01" class="p-0 mx-5"></ion-range></div>
                            </template>
                            <template v-if="layout.modalParam == 'room-height'">
                                <div class="fs-3">Altura *</div>
                                <div class="fs-6">Indique la altura del lugar en metros.</div>
                                <div class="pt-3 text-center fw-bold" style="font-size: 50px;">{{ parseFloat(layout.scene.scene.specs.room.height).toFixed(2)  }}</div>
                                <div><ion-range v-model="layout.scene.scene.specs.room.height" min="1" max="5" step="0.01" class="p-0 mx-5"></ion-range></div>
                            </template>
                            <template v-if="layout.modalParam == 'door-width'">
                                <div class="fs-3">Ancho *</div>
                                <div class="fs-6">Indique el ancho de la puerta en metros.</div>
                                <div class="pt-3 text-center fw-bold" style="font-size: 50px;">{{ parseFloat(layout.scene.scene.specs.door.width).toFixed(2)  }}</div>
                                <div><ion-range v-model="layout.scene.scene.specs.door.width" min="0.5" max="2" step="0.01" class="p-0 mx-5"></ion-range></div>
                            </template>
                            <template v-if="layout.modalParam == 'door-height'">
                                <div class="fs-3">Altura *</div>
                                <div class="fs-6">Indique la altura de la puerta en metros.</div>
                                <div class="pt-3 text-center fw-bold" style="font-size: 50px;">{{ parseFloat(layout.scene.scene.specs.door.height).toFixed(2)  }}</div>
                                <div><ion-range v-model="layout.scene.scene.specs.door.height" min="1" max="2.8" step="0.01" class="p-0 mx-5"></ion-range></div>
                            </template>
                            <template v-if="layout.modalParam == 'thickness'">
                                <div class="fs-3">Espesor *</div>
                                <div class="fs-6">Indique el espesor de la puerta en milímetros.</div>
                                <div class="pt-3 text-center fw-bold" style="font-size: 50px;">{{ layout.scene.scene.specs.thickness }}</div>
                                <div><ion-range v-model="layout.scene.scene.specs.thickness" min="10" max="300" step="1" class="p-0 mx-5"></ion-range></div>
                            </template>
                            <div class="d-flex justify-content-center mt-4"><button class="button px-5 py-3 m-0 mt-0 bg-light" @click="layout.modalParam = false">Aceptar</button></div>
                        </div>
                    </ion-content>
                </ion-modal>
                
            </div>
        </div>
    </div>
 </template>
 <script>
 import { defineComponent, onMounted, ref, watch } from "vue";  //, onUnmounted
 import { useAuthStore } from "@/store";
 import { add } from 'ionicons/icons';
 import router from "@/router";
 import { useRoute } from 'vue-router'
 import { getApp, getScene, updateScene, getAppUsers } from "../../../model/apps.js"; 
 import { IonItem, IonLabel, IonInput, IonList, IonRow, IonCol, IonRange, IonModal, alertController } from '@ionic/vue';
 import { not as $not } from "../../../model/not";

 export default defineComponent({
   name: 'siteSettings',
   props: {
       isMobile: Boolean,
       appId: String,
       sceneId: String,
    },
   components: { IonItem, IonLabel, IonInput, IonList, IonRow, IonCol, IonRange, IonModal  },
   setup(props) {
 
      const authStore      = useAuthStore();
      const route          = useRoute()
      const layout         = ref({ 
                               appId:       null,
                               sceneId:     null,
                               scene:       null,
                               modalParam:  null,
                               modalUsers:  null,
                               addSceneUser: null,
                           }) 

      const { user, profile } = authStore;

      onMounted( async () => { 
        layout.value.appId     = router.currentRoute.value.params.appId;
        layout.value.sceneId   = router.currentRoute.value.params.SceneId;
        layout.value.app       = await getApp(layout.value.appId)
        layout.value.app.users = await getAppUsers(props.appId)
        if(layout.value.sceneId)    
            layout.value.scene     = await getScene(layout.value.appId, layout.value.sceneId)
      });

      const addSceneUser = async () => {
        if(layout.value.addSceneUser){
            layout.value.scene.scene.users.push(layout.value.addSceneUser)
            layout.value.addSceneUser = null 
            await updateScene(layout.value.appId, { sceneId: layout.value.sceneId, users: layout.value.scene.scene.users })
            $not.toastApp({ message: 'Se ha añadido el usuario correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'primary', cssClass: 'text-white mt-2' })
        }
        layout.value.modalUsers = false
      }

      const delSceneUser = async () => {
        const alert = await alertController.create({
            header: '¿Está seguro?',
            cssClass: 'friCustomAlert',
            buttons: [ 
                        {text: 'Cancelar', cssClass: 'alert-button-cancel', role: 'cancel' },  
                        {text: 'Confirmar',cssClass: 'alert-button-confirm', handler: async () => { 
                            if(layout.value.modalUsers){
                                layout.value.scene.scene.users = layout.value.scene.scene.users.filter((user) => user != layout.value.modalUsers)
                                layout.value.modalUsers = false
                                await updateScene(layout.value.appId, { sceneId: layout.value.sceneId, users: layout.value.scene.scene.users })
                                $not.toastApp({ message: 'Se ha desvinculado al usuario correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'primary', cssClass: 'text-white mt-2' })
                            }
                        }
                        }
                        ],
        });
        await alert.present();
      }

      const saveScene = async () => {
        let validate = true
        let required = ['name']
        required.forEach((field) => { if (!layout.value.scene.scene[field])validate = false  })
        if(!layout.value.scene.scene.specs.product.type)validate = false

        if(!layout.value.scene.scene.specs.room.width)validate = false
        if(!layout.value.scene.scene.specs.room.height)validate = false
        if(!layout.value.scene.scene.specs.room.length)validate = false

        if(layout.value.scene.scene.type == 'coldroom'){
            if(!layout.value.scene.scene.specs.door.width)validate = false
            if(!layout.value.scene.scene.specs.door.height)validate = false
            if(!layout.value.scene.scene.specs.room.width)validate = false

            if(!layout.value.scene.scene.specs.compressor.brand)validate = false
            if(!layout.value.scene.scene.specs.compressor.model)validate = false
            if(!layout.value.scene.scene.specs.compressor.refrigerant)validate = false
        }

        if (validate) {
            await updateScene(layout.value.appId, layout.value.scene.scene)
        }else $not.toastApp({ message: 'Debe rellenar los parámetros requeridos *', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
       }
 
       watch(() => route.query, async (query) => {
            if (query){
                layout.value.query     = query; 
                layout.value.section   = query.section;
                layout.value.appId     = router.currentRoute.value.params.appId;
                layout.value.sceneId   = router.currentRoute.value.params.SceneId;
            }
        });

       return { layout, user, profile, add, props, saveScene, addSceneUser, delSceneUser };
    },
   
 })
 </script>