import { LocalNotifications } from '@capacitor/local-notifications';
import { alertController, toastController } from '@ionic/vue';
import { Dialog } from '@capacitor/dialog';
import { Share } from '@capacitor/share';
import { informationCircle } from 'ionicons/icons';
import router from "@/router";

const LocalNot = {
    async alertApp(opt) {
        const alert = await alertController.create(opt);
        await alert.present();
    },
    async dialogApp(opt) {
        switch (opt.type) {
            case 'confirm': return await Dialog.confirm(opt);
            case 'prompt': return await Dialog.prompt(opt);
            default: return false;
        }
    },
    async toastApp(opt) {
        if(opt.icon && opt.icon=='info')opt.icon = informationCircle
        const toast = await toastController.create(opt)
        return toast.present();
    },
    async shareApp(opt) {
        await Share.share(opt);
    },
    async initLocalNotifications() {
        await LocalNotifications.requestPermissions();
        LocalNotifications.registerActionTypes({
            types: [
                {   id: 'PF_CHECKOUT', actions: [ {  id: 'open', title: "Ver alarma",  }]},  /*{ id: 'view', title: "Modificar set (Opcional)", input: true, }, { id: 'remove', title: 'Descartar', destructive: true },*/
                {   id: 'ALBA_ALARM',  actions: [ { id: 'open', title: "Ver alarma" }  ] }
            ],
        })

        LocalNotifications.addListener('localNotificationReceived', (notification) => {
            console.log('Notification received', notification);
            /*this.alertApp({
                header: 'Local Notification',
                subHeader: 'Received: ' + notification.title,
                message: 'Custom data: ' + JSON.stringify(notification.extra),
                buttons: ['OK']
            })*/
        });

        LocalNotifications.addListener('localNotificationActionPerformed', (notification) => {
            if (notification.actionId === 'open') {
                if (notification.notification) {
                    if (notification.notification.extra) {
                        if (notification.notification.extra.alarmId) {
                            let alarmId     = notification.notification.extra.alarmId
                            let alarmData   = alarmId.split('_')
                            let alarm       = { appid: alarmData[0], deviceid: alarmData[1], alarmCode: alarmData[2], date: alarmData[3] }
                            router.push({ path: '/alert/' + alarm.appid + '/' + alarm.deviceid + '/' + alarm.alarmCode , query: { section:  'dashboard', alarmId: alarmId } })
                            /*console.log('Alarm data', alarm)
                            this.alertApp({
                                header:    'Ver Alarma',
                                subHeader:  notification.notification.title,
                                message:   'Custom data: ' + JSON.stringify(notification.notification.extra),
                                buttons:   ['OK']
                            })*/
                        }
                    }
                }
            }
        });

        const result = await LocalNotifications.listChannels();
        if (result.channels.find(ch => ch.id === 'aacheckout') === undefined) {
            const channel1 = { id: 'aacheckout', name: 'Fricontrol Alba checkout', lightColor: '#ff0000', importance: 5, visibility: 1, vibration: true, lights: true }
            await LocalNotifications.createChannel(channel1);
        }
        return true
    },
    async sendLocalNot(opt) {
        const initNot = await this.initLocalNotifications() 
        if (initNot) {
            await LocalNotifications.schedule({
                notifications: [
                    {
                        title:          opt.title,
                        body:           opt.message,
                        id:             opt.id    ? opt.id      : 1,
                        channelId:      'aacheckout',
                        schedule:       { at: new Date(Date.now() + 1000 * 2) },
                        iconColor:      opt.color ? opt.color   : '#00FF00',
                        actionTypeId:   opt.type  ? opt.type    : "ALBA_ALARM",
                        extra:          opt.data  ? opt.data    : null,
                        led:            opt.color ? opt.color   : '#FF0000',
                        /*attachments: [
                            { id: 'face', url: 'res://alertRed.jpg' }
                        ]*/
                    }
                ]
            });
        }
    },
    
};

const install = app => {
    app.config.globalProperties.$not = LocalNot;
};

export { install as default, LocalNot as not };
