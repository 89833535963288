import { _firebase as $_firebase } from "../model/firebase";
import { useAuthStore } from '@/store';
import { getDocs, collection,  } from "firebase/firestore"; //Timestamp
//, addDoc, deleteDoc, doc, setDoc, , , serverTimestamp, collectionGroup, documentId, Timestamp query, collectionGroup, where, documentId


//import { not as $not } from "../model/not";
//import { user as $user } from "../model/user";
//import { format, parseISO } from 'date-fns';
//import { helper as $h } from "../utils/helper";
//import moment from 'moment';

//import { check as $check } from "../model/check";


const Ping = {
    async getUserPings() {
        const authStore = useAuthStore();
        const { user } = authStore;
        let pingfines = []
        const querySnapshot = await getDocs(collection($_firebase.firestore(), "profiles", user.uid , "pingfine"));
        querySnapshot.forEach( async (doc) => {
            let ping = { ...doc.data(), id: doc.id }
            pingfines.push(ping)
        });
        /*for (let p in pingfines) {
            pingfines[p].next = await $check.getNext(pingfines[p].id)
        }*/
        return pingfines
    },
};

const install = app => {
    app.config.globalProperties.$ping = Ping;
};

export { install as default, Ping as ping };
