import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../assets/images/logo/fricontrolIcon.png'


const _hoisted_1 = { class: "text-center mt-5" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = {
  key: 0,
  class: "text-center pt-2"
}
const _hoisted_4 = { class: "text-center pb-5" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "text-center" }
const _hoisted_7 = { class: "text-center mt-5" }
const _hoisted_8 = ["href", "download"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _imports_0,
        width: "80",
        alt: "Alba",
        class: "mb-5 mt-5",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/home/')))
      })
    ]),
    (_ctx.layout.apkStoragePath)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              src: _ctx.layout.qr,
              class: "img-fluid friBorder2 br-5",
              width: "200"
            }, null, 8, _hoisted_5)
          ]),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.layout.file), 1),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("a", {
              href: _ctx.layout.apkStoragePath,
              download: _ctx.layout.file,
              class: "btn friBack1 text-white btn-lg"
            }, "Descargar", 8, _hoisted_8)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}