
  import { defineComponent, onMounted, ref } from "vue"; 
  import { IonModal, IonButtons, IonButton, IonHeader, IonContent, IonList, IonItem, IonLabel, IonInput, IonSelect, IonSelectOption, } from '@ionic/vue';
  import { useAuthStore } from '@/store';
  import router from "@/router";
  import { appsList, createApp } from "../../model/apps.js";
  import { getClientList } from "../../model/api.js";
  import { not as $not } from "../../model/not";

  export default defineComponent({
    name: 'appsPage',
    props: {
        isMobile: Boolean,
     },
     components: { IonModal,  IonButtons, IonButton, IonHeader, IonContent, IonList, IonItem, IonLabel, IonInput, IonSelect, IonSelectOption },
    setup() {
      
      const authStore = useAuthStore();
      const { user, profile } = authStore;
      
      const layout = ref({ 
                          loading:  true,
                          apps:     null,
                          clients:  null,
                          newApp:   null,
                          modalLoading: false,
                         })
      

      const addApp = async () => {
        let validate = true
        let required = ['name', 'description', 'clientId']
        required.forEach((field) => {  if(!layout.value.newApp[field])validate = false })
        if(validate){
          layout.value.modalLoading = true
          new Promise((resolve, reject) => {
            createApp(layout.value.newApp).then((res) => {
              setTimeout(async () => { resolve(res) 
                resolve(res)
                layout.value.modalLoading = false
                initNewApp()
                layout.value.apps = await appsList()
                router.push('/apps/')
              }, 5000)
            }).catch((err) => { reject(err) })
          })
        }else $not.toastApp({ message: 'Debe rellenar los parámetros requeridos', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
      }

      const previewImage = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          layout.value.newApp.logo = reader.result.toString();
        };
        reader.readAsDataURL(file);
      };

      const initNewApp = () => {
        layout.value.newApp = {
          appId:         Date.now(),
          name:         "AH"+Date.now(),
          description:  '',
          clientId:     user.uid,
          active:       true,
          deleted:      false,
          logo:         null,
          location:     { cityId: "3127066" },
          limits:       { devices: 15, users: 10, scenes: 5, alarms: { log: false, share: false }, notify: { email: true, whatsapp: false, sms: false, maxsms: 30 }, reports: { log: false, share: false } }
        }
      }

      const initApps = async () => {
        initNewApp()
        layout.value.apps    = await appsList()
        layout.value.clients = await getClientList()
        layout.value.loading = false
      }

      onMounted(async () => {
        await initApps()
      })
  
      return { authStore, user, profile, router, layout, addApp, previewImage }
  
    },
  });
  