
  import { defineComponent, onMounted, ref, watch } from "vue"; 
  import { useAuthStore } from '@/store';
  import { useRoute } from 'vue-router'
  import router from "@/router";
  import { add, saveOutline, chevronBack } from 'ionicons/icons';
  import { getClient } from "../../model/api.js";
  import { helper as $h } from "../../utils/helper.js";
  
  import clientDash   from '@/components/pages/client/dashboard.vue';
  import clientApps   from '@/components/pages/client/apps.vue';
  import clientPlans  from '@/components/pages/client/plans.vue';
  import clientDocuments from '@/components/pages/client/documents.vue';

  export default defineComponent({
    name: 'clientPage',
    props: {
        isMobile: Boolean,
     },
     components: { clientDash, clientApps, clientPlans, clientDocuments },
    setup(props) {
      
      const authStore = useAuthStore();
      const { user, profile } = authStore;

      const route = useRoute()

      const layout = ref({ 
                          loading:  true,
                          clientId: router.currentRoute.value.params.clientId,
                          client:   null,
                          apps:     null,
                          query:    null,
                          section:  null,
                          sections: [],
                          helper:   $h,
                         })

      const initClient = async () => {
        layout.value.client          = await getClient(layout.value.clientId)
        if(layout.value.client.role == 'agent') layout.value.sections.push( { name: 'dashboard', title: 'Agente', icon: 'bi bi-person'})
        else layout.value.sections.push( { name: 'dashboard', title: 'Cliente', icon: 'bi bi-person'})
        layout.value.sections.push( { name: 'apps',      title: 'Aplicaciones', icon: 'bi bi-grid'})
        if(layout.value.client.role == 'client'){
          layout.value.sections.push( { name: 'plans',     title: 'Suscripciones', icon: 'bi bi-shield'})
          layout.value.sections.push( { name: 'documents', title: 'Documentos', icon: 'bi bi-file-earmark-zip'})
        }
       if(!layout.value.section)
          if(route.query.section) layout.value.section = route.query.section
          else layout.value.section = 'dashboard'
        layout.value.loading     = false;
      }

      watch(() => route.query, async (query) => {
        if (query){
            layout.value.query   = query; 
            layout.value.section = query.section;
        }
      });

      onMounted(async () => {
        await initClient()
      })
  
      return { authStore, user, profile, router, layout, props, add, saveOutline, chevronBack }
  
    },
  });
  