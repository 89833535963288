<template>
    <div>
        <div class="account-box is-form is-footerless" :style="isMobile ? 'border-bottom: none !important;' : ''">
            <template v-if="layout.app">
                <div class="form-head stuck-header" :class="isMobile ? 'py-0' : 'py-3'">
                    <div class="form-head-inner">
                        <div class="left"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5 ms-2' : 'fs-4'">{{  layout.app.name  }} </h4></div>
                        <div class="inline-block align-top fs-6 mt-0" style="margin-top: 4px;">
                            <template v-if="layout.app.logo"><img :src="layout.app.logo" style="height: 50px;" /></template>
                            <template v-else>{{  layout.app.id }}</template>
                        </div>
                    </div>
                </div>
                <div class="pt-2 pb-2 fs-5 friBack1 text-white text-center ps-3"><span class="align-middle" v-if="layout.device && layout.device.name">{{ layout.device.name }}</span></div>
                <!-- <div class="pt-2 pb-2 fs-6 friBack4 text-center ps-3"><span class="align-middle" v-if="layout.device && layout.device.typeName">{{ layout.device.typeName }}</span></div> -->

                <div class="text-center ps-3" v-if="layout.alarm">
                    <!-- search alarm.code in layout.alarmsType -->
                    <div class="text-center fs-2 mt-5" v-if="layout.alarmsType">
                        <div class="d-flex justify-content-center align-items-center fs-5 my-3">
                            <div class="friUserCircle friBack5 p-3 cursor-pointer"><i class="bi bi-bell text-white friColor4" style="font-size: 35px;"></i></div>
                        </div>
                        <div class="mx-5 fs-4">
                            <span class="fw-bold friColor4">{{layout.alarmsType.find(x => x.code === layout.alarm.code)?.name }}</span>
                            <div class="fs-6 px-5 mb-0">{{ layout.alarmsType.find(x => x.code === layout.alarm.code)?.description }}</div>
                        </div> 
                    </div>
                    <div class="fs-3 text-center me-5 ms-4">
                        <ion-list class="m-0 p-0">
                            <!--
                            <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;" v-if="layout.alarmsType">
                                <ion-label position="floating" class="friColor1 pt-0 mt-0" :class="isMobile ? 'fs-4' : ' fs-5'">Tipo *</ion-label>
                                <ion-select interface="popover" v-model="layout.alarm.code" class="pfInputValue">
                                    <ion-select-option v-for="alarmType in layout.alarmsType" :key="alarmType.code" :value="alarmType.code" :disabled="true">{{ alarmType.name }}</ion-select-option>
                                </ion-select>
                            </ion-item>
                            <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;" v-if="layout.alarm.id">
                                <ion-label position="floating" class="friColor1" :class="isMobile ? 'fs-4' : ' fs-5'">Código de alarma *</ion-label>
                                <ion-input v-model="layout.alarm.id"  type="text" autocomplete="off" maxlength="30" :disabled="true"></ion-input>
                            </ion-item>
                            -->
                            <ion-item lines="full" class="border-none pb-2" style="--ion-item-border-color: #ccc;">
                                <ion-label position="floating" class="friColor1" :class="isMobile ? 'fs-4' : ' fs-5'">Nombre *</ion-label>
                                <ion-input v-model="layout.alarm.name"  type="text" autocomplete="off" maxlength="30" required></ion-input>
                            </ion-item>
                            <!-- custon params by device type -->
                            

                            <table class="table table-borderless table-sm" v-if="layout.alarm.param">
                                <tr v-if="layout.alarm.param == 'max'">
                                    <td class="fs-6 text-left py-4">Temperatura máxima *</td>
                                    <td class="fs-5 friColor1">{{ layout.alarm.max  }}<span class="fs-6 ps-1 friColor1">{{ layout.alarm.unit }}</span></td>
                                    <td class="fs-6"><i class="cursor-pointer bi bi-pencil" :class="isMobile ? 'fs-4' : 'fs-5'" @click="layout.modalParam = 'max'"></i></td>
                                </tr>
                                <tr v-if="layout.alarm.param == 'min'">
                                    <td class="fs-6 text-left py-4">Temperatura mínima *</td>
                                    <td class="fs-5 friColor1">{{ layout.alarm.min  }}<span class="fs-6 ps-1 friColor1">{{ layout.alarm.unit }}</span></td>
                                    <td class="fs-6"><i class="cursor-pointer bi bi-pencil" :class="isMobile ? 'fs-4' : 'fs-5'" @click="layout.modalParam = 'min'"></i></td>
                                </tr>
                                <tr v-if="layout.alarm.param == 'maxH'">
                                    <td class="fs-6 text-left py-4">Humedad máxima *</td>
                                    <td class="fs-5 friColor1">{{ layout.alarm.maxH  }}<span class="fs-6 ps-1 friColor1">{{ layout.alarm.unit }}</span></td>
                                    <td class="fs-6"><i class="cursor-pointer bi bi-pencil" :class="isMobile ? 'fs-4' : 'fs-5'" @click="layout.modalParam = 'maxH'"></i></td>
                                </tr>
                                <tr v-if="layout.alarm.param == 'minH'">
                                    <td class="fs-6 text-left py-4">Humedad mínima *</td>
                                    <td class="fs-5 friColor1">{{ layout.alarm.minH  }}<span class="fs-6 ps-1 friColor1">{{ layout.alarm.unit }}</span></td>
                                    <td class="fs-6"><i class="cursor-pointer bi bi-pencil" :class="isMobile ? 'fs-4' : 'fs-5'" @click="layout.modalParam = 'minH'"></i></td>
                                </tr>
                                <tr v-if="layout.alarm.param == 'minB'">
                                    <td class="fs-6 text-left py-4">Batería mínima *</td>
                                    <td class="fs-5 friColor1">{{ layout.alarm.minB  }}<span class="fs-6 ps-1 friColor1">{{ layout.alarm.unit }}</span></td>
                                    <td class="fs-6"><i class="cursor-pointer bi bi-pencil" :class="isMobile ? 'fs-4' : 'fs-5'" @click="layout.modalParam = 'minB'"></i></td>
                                </tr>
                                <tr v-if="layout.alarm.param == 'max' || layout.alarm.param == 'min' || layout.alarm.param == 'maxH' || layout.alarm.param == 'minH'">
                                    <td class="fs-6 text-left py-4">Diferencial *</td>
                                    <td class="fs-5 friColor1">{{ layout.alarm.diff }}<span class="fs-6 ps-1 friColor1">{{ layout.alarm.unit }}</span></td>
                                    <td class="fs-6"><i class="cursor-pointer bi bi-pencil" :class="isMobile ? 'fs-4' : 'fs-5'" @click="layout.modalParam = 'diff'"></i></td>
                                </tr>
                                <tr v-if="layout.alarm.param == 'openDoor'">
                                    <td class="fs-6 text-left py-4">Tiempo máximo de apertura *</td>
                                    <td class="fs-5 friColor1">{{ layout.alarm.openDoor  }}<span class="fs-6 ps-2 friColor1 ">min</span></td>
                                    <td class="fs-6"><i class="cursor-pointer  bi bi-pencil" :class="isMobile ? 'fs-4' : 'fs-5'" @click="layout.modalParam = 'openDoor'"></i></td>
                                </tr>
                                <tr>
                                    <td class="fs-6 text-left py-4">Retardo para aviso * </td>
                                    <td class="fs-5 friColor1">{{ layout.alarm.warning  }}<span class="fs-6 friColor1 s-2">min</span></td>
                                    <td class="fs-6"><i class="cursor-pointer  bi bi-pencil" :class="isMobile ? 'fs-4' : 'fs-5'" @click="layout.modalParam = 'warning'"></i></td>
                                </tr>
                                <tr>
                                    <td class="fs-6 text-left py-4">Retardo para notificación * </td>
                                    <td class="friColor1 fs-5">{{ layout.alarm.notify  }}<span class="friColor1 fs-6 ps-2">min</span></td>
                                    <td class="fs-6"><i class="cursor-pointer bi bi-pencil" :class="isMobile ? 'fs-4' : 'fs-5'" @click="layout.modalParam = 'notify'"></i></td>
                                </tr>
                                <tr>
                                    <td class="fs-6 text-left">Activa</td>
                                     <td class="fs-6 text-right pt-3" colspan="2"><ion-toggle :enable-on-off-labels="true" slot="end" v-model="layout.alarm.active"></ion-toggle></td>
                                </tr>
                            </table>

                            <ion-modal :is-open="layout.modalParam" ref="modal" :initial-breakpoint="0.5" :breakpoints="[0.5, 0.75, 1]" :backdrop-dismiss="false">
                                <ion-content class="ion-padding">
                                    <div class="m-5">
                                        <template v-if="layout.modalParam == 'max'">
                                            <div class="fs-3">Temperatura máxima *</div>
                                            <div class="fs-6">Valor que se considera como máximo válido para la temperatura del dispositivo.</div>
                                            <div class="pt-3 text-center fw-bold" style="font-size: 50px;">{{ layout.alarm[layout.modalParam] }} <span class="fs-6 ps-2">{{ layout.alarm.unit }}</span></div>
                                            <div><ion-range v-model="layout.alarm[layout.modalParam]"  min="-50" max="50" step="1" class="p-0 mx-5"></ion-range></div>
                                            <!--
                                            <ion-row class="px-5 mt-0">
                                                <ion-col><ion-button class="border-none p-5 fs-1 text-white">-</ion-button></ion-col>
                                                <ion-col><div class="pt-5 mt-3 text-center" style="font-size: 25px;">{{ layout.alarm[layout.modalParam] }}</div></ion-col>
                                                <ion-col><ion-button class="border-none p-5 fs-1 text-white">+</ion-button></ion-col>
                                            </ion-row>
                                            -->
                                        </template>
                                        <template v-if="layout.modalParam == 'min'">
                                            <div class="fs-3">Temperatura mínima *</div>
                                            <div class="fs-6">Valor que se considera como mínimo válido para la temperatura del dispositivo.</div>
                                            <div class="pt-3 text-center fw-bold" style="font-size: 50px;">{{ layout.alarm[layout.modalParam] }} <span class="fs-6 ps-2">{{ layout.alarm.unit }}</span></div>
                                            <div><ion-range v-model="layout.alarm[layout.modalParam]"  min="-50" max="50" step="1" class="p-0 mx-5"></ion-range></div>
                                        </template>
                                        <template v-if="layout.modalParam == 'maxH'">
                                            <div class="fs-3">Humedad máxima *</div>
                                            <div class="fs-6">Valor que se considera como máximo válido para la humedad del dispositivo.</div>
                                            <div class="pt-3 text-center fw-bold" style="font-size: 50px;">{{ layout.alarm[layout.modalParam] }} <span class="fs-6 ps-2">{{ layout.alarm.unit }}</span></div>
                                            <div><ion-range v-model="layout.alarm[layout.modalParam]" min="0" max="100" step="1" class="p-0 mx-5"></ion-range></div>
                                        </template>
                                        <template v-if="layout.modalParam == 'minH'">
                                            <div class="fs-3">Humedad mínima *</div>
                                            <div class="fs-6">Valor que se considera como mínimo válido para la humedad del dispositivo.</div>
                                            <div class="pt-3 text-center fw-bold" style="font-size: 50px;">{{ layout.alarm[layout.modalParam] }} <span class="fs-6 ps-2">{{ layout.alarm.unit }}</span></div>
                                            <div><ion-range v-model="layout.alarm[layout.modalParam]" min="0" max="100" step="1" class="p-0 mx-5"></ion-range></div>
                                        </template>
                                        <template v-if="layout.modalParam == 'minB'">
                                            <div class="fs-3">Batería mínima *</div>
                                            <div class="fs-6">Valor que se considera como mínimo válido para la batería del dispositivo.</div>
                                            <div class="pt-3 text-center fw-bold" style="font-size: 50px;">{{ layout.alarm[layout.modalParam] }} <span class="fs-6 ps-2">{{ layout.alarm.unit }}</span></div>
                                            <div><ion-range v-model="layout.alarm[layout.modalParam]" min="1" max="3" step="0.1" class="p-0 mx-5"></ion-range></div>
                                        </template>
                                        <template v-if="layout.modalParam == 'diff'">
                                            <div class="fs-3">Diferencial *</div>
                                            <div class="fs-6">Valor que se suma o resta al valor de alarma para evitar el mal funcionamiento del sistema por fluctuaciones.</div>
                                            <div class="pt-3 text-center fw-bold" style="font-size: 50px;">{{ layout.alarm[layout.modalParam] }} <span class="fs-6 ps-2">{{ layout.alarm.unit }}</span></div>
                                            <div><ion-range v-model="layout.alarm[layout.modalParam]" min="0" max="10" step="0.5" class="p-0 mx-5"></ion-range></div>
                                        </template>
                                        <template v-if="layout.modalParam == 'openDoor'">
                                            <div class="fs-3">Tiempo máximo de apertura *</div>
                                            <div class="fs-6">Valor que se considera como máximo válido para el tiempo de apertura del dispositivo.</div>
                                            <div class="pt-3 text-center fw-bold" style="font-size: 50px;">{{ layout.alarm[layout.modalParam] }} <span class="fs-6 ps-2">{{ layout.alarm.unit }}</span></div>
                                            <div><ion-range v-model="layout.alarm[layout.modalParam]" min="1" max="60" step="1" class="p-0 mx-5"></ion-range></div>
                                        </template>
                                        <template v-if="layout.modalParam == 'warning'">
                                            <div class="fs-3">Tiempo de retardo para aviso *</div>
                                            <div class="fs-6">Se trata del período de espera desde que se detecta una condición de alarma hasta que el sistema genera un aviso.</div>
                                            <div class="pt-3 text-center fw-bold" style="font-size: 50px;">{{ layout.alarm[layout.modalParam] }}<span class="fs-5 ps-2" v-if="layout.alarm[layout.modalParam] == 1">minuto</span><span class="fs-5 ps-2" v-else>minuntos</span></div>
                                            <div><ion-range v-model="layout.alarm[layout.modalParam]" min="1" max="30" step="1" class="p-0 mx-5" @ionChange="layout.alarm.notify = layout.alarm.warning +1 "></ion-range></div>
                                        </template>
                                        <template v-if="layout.modalParam == 'notify'">
                                            <div class="fs-3">Tiempo de retardo para el envío de notificaciones *</div>
                                            <div class="fs-6">Es el intervalo de tiempo entre la detección y el envío de la notificación a los destinatarios configurados en la alarma.</div>
                                            <div class="pt-0 text-center fw-bold" style="font-size: 50px;">{{ layout.alarm[layout.modalParam] }}<span class="fs-5 ps-2" v-if="layout.alarm[layout.modalParam] == 1">minuto</span><span class="fs-5 ps-2" v-else>minuntos</span></div>
                                            <div><ion-range v-model="layout.alarm[layout.modalParam]"  :min="layout.alarm.warning"  class="p-0 mx-5" max="60" step="1"></ion-range></div>
                                        </template>
                                        <div class="d-flex justify-content-center mt-4">
                                            <button class="button px-5 py-3 m-0 mt-0 bg-light" @click="layout.modalParam = false">Aceptar</button>
                                        </div>
                                    </div>
                                </ion-content>
                            </ion-modal>

                            <!--
                            <template v-if="layout.alarm.param == 'minB'">
                                <div class="friColor1 fs-6 mt-5 text-left ps-3">Batería mínima *</div>
                                <div class="mt-5 fw-bold" style="font-size: 40px; line-height: 30px; width: 100%;">{{ layout.alarm.minB }}<span class="fs-6 ps-2">{{ layout.alarm.unit }}</span></div>
                                <ion-range v-model="layout.alarm.minB" min="1" max="3" step="0.1" class="p-0 mx-5"></ion-range>
                            </template> 
                            <template v-if="layout.alarm.param == 'minH'">
                                <div class="friColor1 fs-6 mt-5 text-left ps-3">Humedad mínima *</div>
                                <div class="mt-5 fw-bold" style="font-size: 40px; line-height: 30px; width: 100%;">{{ layout.alarm.minH }}<span class="fs-6 ps-2">{{ layout.alarm.unit }}</span></div>
                                <ion-range v-model="layout.alarm.minH"  min="0" max="100" step="1" class="p-0 mx-5"></ion-range>
                            </template> 
                            <template v-if="layout.alarm.param == 'maxH'">
                                <div class="friColor1 fs-6 mt-5 text-left ps-3">Humedad máxima *</div>
                                <div class="mt-5 fw-bold" style="font-size: 40px; line-height: 30px; width: 100%;">{{ layout.alarm.maxH }}<span class="fs-6 ps-2">{{ layout.alarm.unit }}</span></div>
                                <ion-range v-model="layout.alarm.maxH"  min="0" max="100" step="1" class="p-0 mx-5"></ion-range>
                            </template> 
                            <template v-if="layout.alarm.param == 'min'">
                                <div class="friColor1 fs-6 mt-2 text-left ps-3">Mínima *</div>
                                <div class="mt-5 fw-bold" style="font-size: 40px; line-height: 30px; width: 100%;">{{ layout.alarm.min }}<span class="fs-6 ps-2">{{ layout.alarm.unit }}</span></div>
                                <ion-range v-model="layout.alarm.min" min="-50" max="50" step="1" class="p-0 mx-5"></ion-range>
                            </template>
                            <template v-if="layout.alarm.param == 'max' || layout.alarm.param == 'min' || layout.alarm.param == 'maxH' || layout.alarm.param == 'minH'">
                                <div class="friColor1 fs-6 mt-5 text-left ps-3">Diferencial *</div>
                                <div class="text-xs3 text-left ps-3">Valor que se suma o resta al valor de alarma para evitar el mal funcionamiento del sistema por fluctuaciones.</div>
                                <div class="mt-5 fw-bold" style="font-size: 40px; line-height: 30px; width: 100%;">{{ layout.alarm.diff  }}<span class="fs-6 ps-2">{{ layout.alarm.unit }}</span></div>
                                <ion-range v-model="layout.alarm.diff"  min="0" max="10" step="0.5" class="p-0 mx-5"></ion-range>
                            </template> 
                            <template v-if="layout.alarm.param == 'max'">
                                <div class="friColor1 fs-6 mt-5 text-left ps-3">Máxima *</div>
                                <div class="mt-5 fw-bold" style="font-size: 40px; line-height: 30px; width: 100%;">{{ layout.alarm.max }}<span class="fs-6 ps-2">{{ layout.alarm.unit }}</span></div>
                                <ion-range v-model="layout.alarm.max"  min="-50" max="50" step="1" class="p-0 mx-5"></ion-range>
                            </template> 
                            <template v-if="layout.alarm.param == 'openDoor'">
                                <div class="friColor1 fs-6 mt-2 text-left ps-3">Tiempo máximo de apertura *</div>
                                <div class="mt-5 fw-bold" style="font-size: 40px; line-height: 30px; width: 100%;">{{ layout.alarm.openDoor }}<span class="fs-6 ps-2">{{ layout.alarm.unit }}</span></div>
                                <ion-range v-model="layout.alarm.openDoor" min="1" max="60" step="1" class="p-0 mx-5"></ion-range>
                            </template>
                            <div class="mt-5">
                                <div class="friColor1 fs-6 text-left ps-3">
                                    Tiempo de retardo para aviso *
                                    <div class="text-xs3">Se trata del período de espera desde que se detecta una condición de alarma hasta que el sistema genera un aviso.</div>
                                </div>
                                <div class="mt-5 fw-bold" style="font-size: 40px; line-height: 30px; width: 100%;">{{ layout.alarm.warning }}<span class="fs-6 ps-1"> min</span></div>
                                <ion-range v-model="layout.alarm.warning" min="1" max="30" step="1" class="p-0 mx-5" @ionChange="layout.alarm.notify = layout.alarm.warning +1 "></ion-range>
                            </div>
                            <div>
                                <div class="friColor1 fs-6 text-left ps-3">
                                    Tiempo de retardo para notificación *
                                    <div class="text-xs3">Es el intervalo de tiempo entre la detección y el envío de la notificación a los destinatarios configurados en la alarma.</div>
                                </div>
                                <div class="mt-5 fw-bold" style="font-size: 40px; line-height: 30px; width: 100%;">{{ layout.alarm.notify }}<span class="fs-6 ps-1"> min</span></div>
                                <ion-range v-model="layout.alarm.notify" :min="layout.alarm.warning"  class="p-0 mx-5" max="60" step="1"></ion-range>
                            </div>                           
                                

                            <ion-item class="border-none p-0 m-0 mt-3" lines="none" style="--ion-item-border-color: #ccc;">
                                <ion-label>Activa</ion-label>
                                <ion-toggle :enable-on-off-labels="true" slot="end" v-model="layout.alarm.active"></ion-toggle>
                            </ion-item>

                            <pre class="text-left">{{ layout.alarm }}</pre>
                            -->   
                        </ion-list>

                        <div style="padding-bottom: 100px">
                            <div :style="isMobile ? 'margin-top: 10px;' : 'margin-top: 20px; margin-bottom: 20px;'">
                                <div class="d-flex justify-content-center align-items-center fs-5">
                                    <template v-if="layout.device?.status?.alarm?.status && layout.device?.status?.alarm?.code && layout.alarm?.code && layout.device?.status?.alarm?.code == layout.alarm.code">
                                        <div class="text-gray-light text-end float-end ps-4">GUARDAR</div>
                                    </template>
                                    <template v-else>
                                        <div class="fw-bold friColor1 text-end float-end cursor-pointer" @click="updAlarm()">GUARDAR</div>
                                    </template>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!--
                    <div class="mt-5 text-left" style="padding: 0 0 50px 0;">
                        <pre>{{  layout.alarm }}</pre>
                    </div>
                    -->
                </div>
            </template>
            <template v-else>
                <div class="text-center w-100"  style="margin-top: 150px;">
                    <div class="fw-bold fs-1">Alarma</div>
                    <div class="mt-2">Espere por favor ...</div>
                    <div class="spinner-border friColor1 mt-5" role="status">
                    <span class="visually-hidden">Cargando ...</span>
                    </div>
                </div>
            </template>   
        </div>
    </div>
  </template>
  <script>
  import { defineComponent, onMounted, ref, watch } from "vue"; 
  import { useAuthStore } from "@/store";
  import { getApp, getAppDevices  } from "../../../model/apps.js";
  import { helper as $h } from "../../../utils/helper.js";
  import { getAlarm } from "../../../model/alarms.js"; //
  import { not as $not } from "../../../model/not";
  import router from "@/router";
  import { IonItem, IonLabel, IonList, IonToggle, alertController, IonInput, IonRange, IonModal, IonContent } from '@ionic/vue'; //, IonRow, IonButton, IonCol
  import { getAlarmsType, updateAlarm, discardAlarm } from "../../../model/alarms.js";
  import { useRoute } from 'vue-router'

  export default defineComponent({
    name: 'DeviceDash',
    props: {
        isMobile: Boolean,
        appId:    String,
        deviceId: String,
        alarmId:  String,
     },
     components: { IonItem, IonLabel, IonList,IonToggle, IonInput, IonRange,  IonModal, IonContent, }, //,  IonRow, IonButton, IonCol
    setup(props) {
  
       const authStore      = useAuthStore();
       const layout = ref({ 
                            appId:      null,
                            device:     null,
                            services:   null,
                            alarmsType: null,
                            helper:     $h
                          }) 

       const { user, profile } = authStore;
       const route = useRoute()      

       const updAlarm = async () => {
          let validate = true
          let required = ['name']
          required.forEach((field) => { if(!layout.value.alarm[field])validate = false })
          if(validate){
            let updData = { name: layout.value.alarm.name, active: layout.value.alarm.active, warning: layout.value.alarm.warning, notify: layout.value.alarm.notify, diff: layout.value.alarm.diff }
            if(layout.value.alarm.param)updData[layout.value.alarm.param] = layout.value.alarm[layout.value.alarm.param]
            await updateAlarm(layout.value.appId, layout.value.deviceId, layout.value.alarmId, updData)
            $not.toastApp({ message: 'Alarma actualizada correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'primary', cssClass: 'text-white mt-2' })
          }else $not.toastApp({ message: 'Debe rellenar los parámetros requeridos', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
       }

       const discard = async () => {
        const alert = await alertController.create({
            header:  "¿Está seguro?",
            cssClass: 'friCustomAlert',
            buttons: [ 
                        {text: 'Cancelar', cssClass: 'alert-button-cancel', role: 'cancel' },  
                        {text: 'Confirmar',cssClass: 'alert-button-confirm', handler: async () => { 
                            await discardAlarm(layout.value.appId, layout.value.deviceId)
                            $not.toastApp({ header: layout.value.app.name, message: 'Se ha descartado la alarma correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'primary', cssClass: 'text-white mt-2' })
                            router.push({ path: '/sensor/' + layout.value.appId + '/' + layout.value.deviceId , query: { section:  'alarms' } })
                        }
                        }
                      ],
        });
        await alert.present();
       }

       const initDash = async () => {
         layout.value.app           = await getApp(layout.value.appId)
         let deviceData             = await getAppDevices(layout.value.appId)
         if(deviceData && deviceData?.devices && Object.keys(deviceData.devices).length)layout.value.device = deviceData.devices[layout.value.deviceId]
         layout.value.alarm         = await getAlarm(layout.value.appId, layout.value.deviceId, layout.value.alarmId)
         layout.value.alarmsType    = await getAlarmsType({ type: layout.value.device.type })
       }

       onMounted( async () => { 
         layout.value.appId     = props.appId
         layout.value.deviceId  = props.deviceId
         layout.value.alarmId   = props.alarmId
         if(router.currentRoute.value.params.appId)layout.value.appId = router.currentRoute.value.params.appId;
         if(router.currentRoute.value.params.deviceId)layout.value.deviceId = router.currentRoute.value.params.deviceId;
         if(router.currentRoute.value.params.alarmId)layout.value.alarmId = router.currentRoute.value.params.alarmId;
         await initDash();
       });

        watch(() => route.query, async (query) => {
            if (query && router.currentRoute.value.params){
                if(router.currentRoute.value.params.appId)layout.value.appId = router.currentRoute.value.params.appId;
                if(router.currentRoute.value.params.deviceId)layout.value.deviceId = router.currentRoute.value.params.deviceId;
                if(router.currentRoute.value.params.alarmId)layout.value.alarmId = router.currentRoute.value.params.alarmId;
                await initDash();
            }
        });

        return { layout, user, props, profile, updAlarm, discard };
    }
    
  })
  </script>