<template>
    <div class="account-box is-form is-footerless m-0 p-0 mb-5" :style="isMobile ? 'border-bottom: none !important;' : ''">
        <template v-if="layout.client">
            <div class="form-head stuck-header" :class="isMobile ? 'py-0 ps-3 pe-3' : 'py-3'">
                <div class="form-head-inner">
                    <div class="left"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5 ms-3' : 'fs-4'">Documentación</h4></div>
                    <div class="inline-block align-top me-3 fs-6 mt-0" style="margin-top: 4px;">
                        <div class="friUserCircleMin friBack5">{{ layout.client?.first[0] }}{{ layout.client?.last[0] }}</div>
                    </div>
                </div>
            </div>    
            <div class="pt-5">
                <div class="text-center mt-5">No dispone de documentos asociados</div>
                <div class="d-flex justify-content-center align-items-center fs-5 mt-5 mb-2"><div class="friUserCircle friBack4 cursor-pointer" @click="layout.addDocument = true"><i class="bi bi-plus-lg text-dark"></i></div></div>
            </div>

            <ion-modal :is-open="layout.addDocument" ref="modal" :initial-breakpoint="0.75" :breakpoints="[0.5, 0.75, 1]" :backdrop-dismiss="false">
                <div class="p-5 my-2">
                    <div class="fw-bold fs-2 mt-2">Nuevo documento</div>
                    <div>{{  layout.client.first }} {{  layout.client.last }}</div>
                    <div>{{  layout.plan.plan.name }}</div>
                    <ion-list>
                        <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                            <ion-label position="floating" class="friColor1 fs-6 ">Tipo *</ion-label>
                            <ion-select interface="popover" class="fs-5">
                                <ion-select-option>Contrato</ion-select-option>
                                <ion-select-option>Factura</ion-select-option>
                                <ion-select-option>Factura rectificativa</ion-select-option>
                                <ion-select-option>Resumen de pagos</ion-select-option>
                                <ion-select-option>Formalización de baja</ion-select-option>
                            </ion-select>
                        </ion-item>
                    </ion-list>
                    <div class="d-flex justify-content-center mt-4">
                        <button class="button px-5 py-3 m-0 mt-0 bg-light" @click="layout.addDocument = false">Cancelar</button>
                        <button class="button px-5 py-3 mt-0 friBack1 text-white ms-3" @click="layout.addDocument = false">Guardar</button>
                    </div>
                </div>
            </ion-modal>

        </template>
        <template v-else>
            <div class="text-center w-100"  style="margin-top: 150px;">
                <div class="fw-bold fs-2">Documentación</div>
                <div class="mt-2">Espere por favor ...</div>
                <div class="spinner-border friColor1 mt-5" role="status">
                <span class="visually-hidden">Cargando ...</span>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue"; 
import { useAuthStore } from "@/store";
import { helper as $h } from "../../../utils/helper.js";
import { getClient } from "../../../model/api.js";
import { getPlan } from "../../../model/plans.js";
import { IonModal, IonSelect, IonSelectOption, IonItem, IonLabel, IonList } from '@ionic/vue';

export default defineComponent({
name: 'planDocuments',
props: {
    isMobile: Boolean,
    clientId: String,
    planId:  String,
    },
    components: { IonModal, IonSelect, IonSelectOption, IonItem, IonLabel, IonList }, 
setup(props) {

    const authStore      = useAuthStore();
    const layout = ref({ 
                        clientId:   null,
                        planId:     null,
                        plan:       null,
                        client:     null,
                        addDocument: false,
                        helper:     $h,
                        })

    const { user, profile } = authStore;

    const initDocuments = async () => {
        layout.value.client     = await getClient(props.clientId)
        layout.value.plan       = await getPlan(props.clientId, props.planId);
    }

    onMounted( async () => { 
        await initDocuments();
    });

    return { layout, user, props, profile };
}

})
</script>