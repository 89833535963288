<template>
    <div class="row row-cols-4 g-2" style="margin-left: 20px;">
        <div class="row w-100">
            <div class="inline-block" style="width: 55%; padding: 0 !important; margin: 0 !important;">
                <template v-if="layout.devices && layout.devices.length">
                    <div v-for="device in layout.devices" :key="device.id" class="ms-2">
                        <template v-if="device?.sondas?.sondas">
                            <div v-for="sonda in device.sondas.sondas" :key="sonda.id" class="mt-2">
                                <div class="mt-5" v-if="sonda.id==layout.sondaId && sonda?.status" @click="layout.selDevice=true">
                                    <div class="text-left" style="margin-top: 40px !important;">
                                        <div class="fw-bold" :style="device.title.length < 13 ? 'font-size: 28px;' : 'font-size: 23px;'">
                                            {{ device.title.toString().substring(0,17) }}<span class="fs-6" v-if="device.title.length > 17">...</span>
                                        </div>
                                        <!--<div class="fs-6 p-0 m-0">{{ device.model }} / <span class="text-xs">{{ timeDifference(sonda.status.time) }}</span></div>
                                        <div class="text-left" v-if="sonda.alarms">
                                            <div v-for="alarm in sonda.alarms" :key="alarm.id">
                                                <span class="fs-6">Min: <span class="fw-bold">{{ alarm.min }}<span class="align-top">&deg;</span></span></span>
                                                <span class="fs-6 ps-1"> / Max: <span class="fw-bold">{{ alarm.max }}<span class="align-top">&deg;</span></span></span>
                                                <span class="fs-6 ps-1" v-if="alarm?.notify?.email?.active">/ <i class="bi bi-envelope-at ps-2 friColor1"></i></span>
                                            </div>
                                        </div>-->
                                    </div> 
                                    <h1 style="font-size: 82px; font-weight: 600; margin: 0; padding: 0; text-align: left;">{{ sonda.status.temperature }}<span class="fs-1 align-top">&deg;</span></h1>
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
                <template v-else>
                    <div class="row">
                        <div class="text-left mx-auto mt-5">
                            <img src="../../../assets/images/theme/loading.gif" style="width: 150px; margin: 5px 0 0 10px;">
                        </div>
                    </div>  
                </template>
                <!--
                <div class="text-left ms-1 mt-5" v-if="layout.sondas && layout.sondas.response?.result?.sondas?.sondas && layout.sondas.response?.result?.sondas?.sondas[0]">
                    <h1 style="font-size: 80px; font-weight: 600; margin: 0; padding: 38px 0 0 0;" v-if="layout.sondas.response?.result?.sondas?.sondas[0]?.temperature">
                        {{ layout.sondas.response?.result?.sondas?.sondas[0]?.temperature }}<span class="fs-1 align-top">&deg;</span>
                    </h1>
                    <div v-if="layout.isRequestInProgress"><img src="../../../assets/images/theme/loading.gif" style="width: 50px;"></div>
                </div>
                <div v-else>
                    <div class="row">
                        <div class="text-left mx-auto mt-5">
                            <img src="../../../assets/images/theme/loading.gif" style="width: 150px; margin: 5px 0 0 0;">
                        </div>
                    </div>  
                </div>
                -->
            </div>
            <div class="inline-block text-center" style="width: 40%;">

                <template v-if="layout.devices && layout.devices.length">
                    <div class="friDashDeviceBtn" @click="menuAction('menu')">
                        <i class="bi bi-view-list"></i>
                    </div>
                </template>

                <template v-if="layout.sondaAlarms && layout.sondaAlarms.length">
                    <!--<i class="bi bi-exclamation-triangle-fill align-top friColor3 friTopBell" style="font-size: 150px !important; padding-left: 20px;" ></i>-->
                    <img src="../../../assets/images/icon/htemp.png" style="width: 180px; margin: 15px 0 0 0; padding: 0;"> 
                    <div class="alarms-ticker" v-if="layout.sondaAlarms && layout.sondaAlarms.length">
                        <div class="alarms-ticker-content">
                            <div v-for="alarm in layout.sondaAlarms" :key="alarm.id" class="text-white px-3 py-2 text-center br-5">
                                {{ alarm.name }} de 
                                <span v-if="alarm.alarm.type=='alta'">+ {{ alarm.alarm.diff }}°C respecto a la máxima permitida de {{ alarm.alarm.max }}°C.</span>
                                <span v-else>- {{ alarm.alarm.diff }}° con respecto a la mínima permitida de {{ alarm.alarm.min }}°C.</span>
                            </div>
                        </div>   
                    </div>
                </template>
                <template v-else>

                    <div v-if="layout.showWeather" class="ms-2">
                        <Weather />
                    </div>
                    <div v-else>
                        <DHT />
                    </div>

                </template>

                <!--<div v-if="layout.sondas.response?.result?.sondas?.count" style="margin: 50px 0 0 40px;" class="text-center">
                    <i class="bi bi-exclamation-triangle-fill align-top" style="font-size: 90px !important; color: #ff0000; opacity: 0.8; padding:0 !important; margin: 0 !important;"></i> 
                    <span class="friColor3 align-top pt-2 fs-2">(2)</span>
                    
                    <div class="fw-bold">Sondas ({{ layout.sondas.response?.result?.sondas?.count }})</div>
                    <div v-for="sonda in filteredSondas" :key="sonda.id">
                        <div class="mt-2">{{ sonda.temperature }} <span>°C</span>
                        </div>
                    </div>
                </div>-->    

            </div>


            <div class="friSelDeviceBox" v-if="layout.selDevice && layout.devices && layout.devices.length">
                <div class="mt-3 px-2">
                    <div class="friCloseBtn" @click="layout.selDevice=false">X</div>
                    <h1>Seleccione un equipo</h1>
                    <div class="mt-3">
                        <div v-for="device in layout.devices" :key="device.id" :class="layout.devices.length==1 ? 'friSelDevBoxSingle' : 'friSelDevBox'" 
                            @click="layout.deviceId = device.id; layout.sondas = false; layout.selDevice=false; loadDevices();">
                            <template v-if="device?.media?.image"><img :src="device.media.image" :style="layout.devices.length == 1 ? 'width: 120px' : 'width: 80px'" class="mt-2"></template>
                            <template v-else><img src="../../../assets/images/icon/iconDeviceAlba.png" :style="layout.devices.length == 1 ? 'width: 90px' : 'width: 80px'" class="mt-2 mb-5"></template>
                            <div class="fs-3 fw-bold">
                                <template v-if="layout.devices.length==1">{{ device.title.toString() }}</template>
                                <template v-else>{{ device.title.toString().substring(0,10) }}<span v-if="device.title.length > 10">...</span></template>
                            </div>
                            <div class="fs-6">{{ device.model }}</div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>
<script>
import { defineComponent, ref, onMounted, onUnmounted } from 'vue'; //onBeforeUnmount
import { _firebase as $_firebase } from "../../../model/firebase";
import { onSnapshot, collection } from "firebase/firestore"; 
import { useAuthStore } from '@/store';
import { getController } from "../../../model/api.js"; //requestDevice, 
import moment from "moment";

import Weather from '@/components/pages/dash/weather.vue';
import DHT from '@/components/pages/dash/dht.vue';
import { helper as $h } from "../../../utils/helper.js";
const db   =  $_firebase.firestore()

export default defineComponent({
name: 'DashComponent',
props: {
  isMobile: Boolean,
},
computed: {
    filteredSondas() {
        return this.layout.sondas && this.layout.sondas.response.result.sondas ? this.layout.sondas.response.result.sondas.sondas.slice(1) : [];
    }
},
components: { Weather, DHT }, 
setup() {
    const authStore         = useAuthStore();
    const { user, profile } = authStore;
    const layout            = ref({ 
                                controller:  {},
                                deviceId:    false, 
                                devices:     [],
                                sondas:      false,
                                sondasId:    false,
                                sondaAlarms: [],
                                isRequestInProgress: false, 
                                selDevice:   false,
                                helper:      $h,
                                showWeather: true,
                              });

    const loadDevices = async () => { 
        layout.value.devices            = [];
        layout.value.sondas             = false;
        layout.value.sondaId            = false;
        layout.value.controller         = false;
        layout.value.ControllerDevices  = false;
        layout.value.controller = await getController(profile.controllerId); 
        if(layout.value.controller && layout.value.controller.devices?.devices) {
            layout.value.ControllerDevices = layout.value.controller.devices.devices;
            for (let deviceId in layout.value.ControllerDevices) {
                let device = layout.value.ControllerDevices[deviceId];
                if(!layout.value.deviceId)layout.value.deviceId = deviceId
                layout.value.sondaId  = device.dash.sondaId
                layout.value.devices.push(device);
            }
        }
    }

    const detectSondasChanges = async () => {
        const collectionPath = "alba/" + profile.controllerId + "/devices/" + layout.value.deviceId + "/sondas";
        const collRef        = collection(db, collectionPath);
        const unsubscribeGetDeviceSondas = onSnapshot(collRef, async (querySnapshot) => {
            querySnapshot.docChanges().forEach(async change => { 
                if (change.type === "modified"){ if(layout.value.sondaId == change.doc.id)await loadDevices();} 
            });
        }, (error) => { console.log("Error sondas status:", error); });
        onUnmounted(() => { unsubscribeGetDeviceSondas(); });
    };

    const detectAlarmsChanges = async () => {
        const collectionPath = "alba/" + profile.controllerId + "/devices/" + layout.value.deviceId + "/alarms";
        const collRef = collection(db, collectionPath);
        layout.value.sondaAlarms = [];  
        const unsubscribeGetDeviceSondas = onSnapshot(collRef, async (querySnapshot) => {
            let foundAlarmForSonda = false; // Añade un flag aquí
            querySnapshot.docChanges().forEach(async change => { 
                if (change.type != "removed"){
                    console.log("Alarma detectada:", change.type, change.doc.data());
                    let docData = change.doc.data();
                    if(docData.alarm && layout.value.sondaId == docData.alarm.sonda_id){
                        layout.value.sondaAlarms.push(docData);
                        foundAlarmForSonda = true; 
                    }        
                } 
            });
            if(!foundAlarmForSonda) { // Verifica el flag al final
                layout.value.sondaAlarms = [];
            }
        }, (error) => { console.log("Error sondas status:", error); });

        onUnmounted(() => { unsubscribeGetDeviceSondas(); });
    };


    /*const getSondas = () => {    
        async function fetchSondas() {
            if (!layout.value.isRequestInProgress) { 
                layout.value.isRequestInProgress = true; 
                try { layout.value.sondas = await requestDevice("https://"+ profile.controllerId +".fricontrolalba.com", "device-actions/"+layout.value.deviceId+"/sondas");
                } catch (error) { console.error("Error al realizar la solicitud:", error);
                } finally {  layout.value.isRequestInProgress = false; }                
            }
            setTimeout(fetchSondas, 300000); // 5 minutes
        }
        //fetchSondas();
        onBeforeUnmount(() => {
            clearTimeout(fetchSondas);
        });
    }*/

    onMounted(async () => {
        await loadDevices();
        //await getSondas();
        await detectSondasChanges();
        await detectAlarmsChanges();
        setInterval(() => {
            if(layout.value.showWeather){layout.value.showWeather = false; }else{ layout.value.showWeather = true;} 
        }, 60000);

    });

  return {user, profile, layout, loadDevices };
},
methods: {
    menuAction(id) {
        this.$emit('menuAction', id); 
    },
    timeDifference(time) {
        const now = moment();
        const timeRegistered = moment(time, "HH:mm:ss");
        const minutesDifference = now.diff(timeRegistered, "minutes");
        if (minutesDifference === 0) { return "Hace menos de 1 minuto";
        }else if (minutesDifference === 1) { return "Hace 1 minuto";}
        return `Hace ${minutesDifference} minutos`;
    }
    
},
});
</script>
<style>
.friBtnRightDash2{ border-radius: 5px; cursor: pointer; }
.friDashDeviceBtn{ position: fixed; left: -5px; bottom: -6px; width: 70px; height: 70px; border-radius: 6px; color: #fff; background-color: #009292 !important; cursor: pointer;} /* z-index: 999;*/
.friDashDeviceBtn i{ font-size: 45px !important;}
.friSelDeviceBox{ position: fixed; right: 0; top: 0; width: 100% !important; height: 100vh; color: #fff; background-color: #009292 !important; text-align: center; padding: 0 !important; margin: 0 !important; z-index: 99999999; text-align: center;}
.friSelDeviceBox h1{ color: #fff; font-size: 25px; font-weight: 400; text-align: center; padding: 0 !important; margin: 30px 0 20px 0 !important; }
.friSelDeviceBox .friCloseBtn{ position: fixed; top: 2px; right: 8px; color: #fff; font-size: 30px; cursor: pointer;}
.friSelDevBox {
    display: inline-block;
    width: calc(33.33% - 10px); 
    margin: 5px;
    padding: 10px;
    text-align: center; 
    border-radius: 15px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); 
    transition: all 0.3s ease; 
    background-color: #fff;
    cursor: pointer;
}

.friSelDevBox:hover { box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2); transform: scale(1.05); }

.friSelDevBoxSingle {
    display: inline-block;
    width: 40%; 
    margin: 0;
    padding: 15px;
    text-align: center; /* Centra el texto horizontalmente */
    border-radius: 15px; /* Hace las esquinas redondeadas */
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); /* Sombra opcional para dar profundidad */
    transition: all 0.3s ease; /* Transición suave para hover */
    background-color: #fff;
    cursor: pointer;
}

.friSelDevBoxSingle:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2); /* Sombra más prominente en hover */
    transform: scale(1.05); /* Efecto de zoom en hover */
}

/*ticker*/
.alarms-ticker {
    width: 100%;
    background-color: #fd1c2b; 
    overflow: hidden; 
    height: 40px;
    display: block;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 99;
}

.alarms-ticker-content {
    display: inline-block;
    white-space: nowrap; 
    padding-left: 100%; 
    animation: tickerAlarms 15s linear infinite;
    color: #000;
    line-height: 30px;
    font-size: 18px;
}

.alarms-ticker-alert { color: transparent; }

@keyframes tickerAlarms {
    0% { transform: translateX(0);}
    100% {transform: translateX(-100%); }
}
</style>