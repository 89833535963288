import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './assets/theme/variables.css';

//bootstrap
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap.js'

//import scss
import './assets/scss/main.scss'
import './assets/scss/auth.scss';
import './assets/css/theme.scss';
import './assets/css/base.css';


import { createPinia } from "pinia";
import { useAuthStore } from "./store";

const app = createApp(App);
app.use(createPinia());
app.use(IonicVue)

const store = useAuthStore();

store.initializeAuthListener().then(() => {
  app.use(router).mount("#app");
});