<template>
    <div class="account-box is-form is-footerless px-0 mx-0" :style="isMobile ? 'border-bottom: none !important;' : ''">
        <template v-if="layout.device">
            <div class="form-head stuck-header" :class="isMobile ? 'py-0 ps-4 pe-2' : 'py-3'">
                <div class="form-head-inner">
                    <div class="left" v-if="layout.app?.name"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5' : 'fs-4'">{{  layout.app.name.toString().substr(0, 24) }} {{ layout.app.name.toString().length > 24 ? '...' : '' }}</h4></div>
                    <div class="inline-block align-top fs-6 mt-0" style="margin-top: 4px;">
                        <template v-if="layout.app.logo"><img :src="layout.app.logo" style="height: 50px;" /></template>
                        <template v-else>{{  layout.app.id }}</template>
                    </div>
                </div>
            </div>
            <div class="pt-2 pb-2 fs-5 friBack1 text-white text-center ps-3"><span class="align-middle" v-if="layout.device && layout.device.name">{{ layout.device.name }}</span></div>
            <div class="pt-2 pb-2 fs-6 friBack4 text-center ps-3"><span class="align-middle" v-if="layout.device && layout.device.typeName">{{ layout.device.typeName }}</span></div>
            
            <div v-if="layout.device?.status?.alarm?.alarms" class="pt-4 pb-3">
                <div class="fs-6 friColor3" v-for="(alarm, index) in layout.device.status.alarm.alarms" :key="index" 
                    @click="$router.push({ path: '/alert/' + layout.appId + '/' + layout.deviceId + '/' + alarm.code , query: { section:  'dashboard', alarmId: alarm.alarmId } })">
                    <span class="fw-bold fs-6 pe-2 ps-4">{{ alarm.title }}</span>
                    <span v-if="alarm.param" class="friColor3 text-xs3">
                        <span v-if="alarm?.paramValue && alarm.param == 'max'">Máx: {{ alarm.paramValue }} <span>{{ alarm.unit }}</span></span>
                        <span v-if="alarm?.paramValue && alarm.param == 'min'">Mín: {{ alarm.paramValue }} <span>{{ alarm.unit }}</span></span>
                        <span v-if="alarm?.paramValue && alarm.param == 'maxH'">Máx: {{ alarm.paramValue }} <span>{{ alarm.unit }}</span></span>
                        <span v-if="alarm?.paramValue && alarm.param == 'minH'">Mín: {{ alarm.paramValue }} <span>{{ alarm.unit }}</span></span>
                        <span v-if="alarm?.paramValue && alarm.param == 'minB'">Mín: {{ alarm.paramValue }} <span>{{ alarm.unit }}</span></span>
                    </span>
                    <span class="text-end float-end pe-3">
                        <span v-if="alarm?.alarmId" class="my-2 friColor13 fw-bold text-xs3 cursor-pointer">VER ALARMA <i class="bi bi-chevron-compact-right"></i></span>
                    </span>
                    <div style="height: 1px;" class="friBack4 mx-5 my-2"></div>
                </div>
            </div>
            <div class="ps-3">
                <div class="text-left  friBackWhite" :style="isMobile ? 'padding: 0 0 50px 0' : ''">
                    <div class="flex justify-content-center" v-if="layout.device">
                        <div :class="!isMobile ? 'd-flex' : ''">
                            <div class="br-8 mb-2 text-center w-100 pb-5 mt-2"> 
                                <div class="px-0" style="padding: 20px 0 40px 0;">
                                    <template v-if="layout.device.status">

                                        <!-- XW265K -->
                                        <div v-if="layout.device.status.msg.stats && Object.keys(layout.device.status.msg.stats).length" style="border-bottom: 2px solid #ddd;" class="pb-1">
                                            <table class="table table-sm table-borderless mx-0 px-0 py-0 my-0">
                                                <tbody>
                                                    <tr>
                                                        <td class="text-center py-1"><i class="bi bi-snow" style="font-size: 35px;" :class="layout.device.status.msg.stats.defrost ? 'friColor1': 'text-dark'"></i></td>
                                                        <td class="text-center py-1">
                                                            <i class="bi bi-door-open friColor3" style="font-size: 35px;" v-if="layout.device.status.msg?.alarms?.doorOpen"></i>
                                                            <i class="bi bi-door-closed friColor6" style="font-size: 35px;" v-else></i>
                                                        </td>
                                                        <td class="text-center py-1">
                                                            <i class="bi bi-lightbulb-fill friColor1" style="font-size: 35px;" v-if="layout.device.status.msg?.relays?.light"></i>
                                                            <i class="bi bi-lightbulb-off-fill friColor6" style="font-size: 35px;" v-else></i>
                                                        </td>
                                                        <td class="text-center py-1">
                                                            <i class="bi bi-fan friColor1" style="font-size: 37px;" v-if="layout.device.status.msg?.relays?.fans"></i>
                                                            <i class="bi bi-fan friColor6" style="font-size: 35px;" v-else></i>
                                                        </td>
                                                        <td class="text-center py-1" v-if="!layout.device.status.msg?.relays?.alarm">
                                                            <i class="bi bi-exclamation-triangle-fill friColor3" style="font-size: 37px;" v-if="layout.device.status.msg?.relays?.alarm"></i>
                                                            <i class="bi bi-exclamation-triangle-fill friColor6" style="font-size: 37px;" v-else></i>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <!-- DHT65 / DHT52 -->
                                        <template v-if="layout.device.status.msg && layout.device.status.msg.hasOwnProperty('TempC_SHT')"> 
                                            <div class="mt-2 text-dark fw-bold text-center" v-if="layout.device?.status?.msg" style="font-size: 60px; line-height: 60px; padding-top: 20px;" 
                                                :class="{ 'friColor3': layout.device.status.alarm && layout.device.status.alarm.alarms && Object.values(layout.device.status.alarm.alarms).some(alarm => alarm.paramSensor === 'TempC_SHT') }">
                                                {{ parseFloat(layout.device.status.msg.TempC_SHT).toFixed(1) }}<span class="fs-1 align-middle ps-3">ºC</span>
                                            </div> 
                                            <div class="fs-4 text-dark text-xs text-center" v-if="layout.device?.status?.msg?.Hum_SHT">
                                                Humedad: <span class="fw-bold ps-1" :class="{ 'friColor3': layout.device.status.alarm && layout.device.status.alarm.alarms && Object.values(layout.device.status.alarm.alarms).some(alarm => alarm.paramSensor === 'Hum_SHT') }">{{ layout.device.status.msg.Hum_SHT }}%</span>
                                            </div>
                                        </template>

                                        <!-- DLS02 -->
                                        <template v-if="layout.device.status.msg && layout.device.status.msg.hasOwnProperty('DOOR_OPEN_STATUS')"> 
                                            <div class="mt-2 text-dark fw-bold text-center">
                                                <div v-if="layout.device.status.msg?.DOOR_OPEN_STATUS" class="friColor3 text-xs fw-bold" style="font-size: 25px">
                                                    <div><i class="bi bi-door-open friColor3" style="font-size: 60px"></i></div>
                                                    ABIERTA
                                                </div>
                                                <div v-else class="text-dark text-xs fw-bold" style="font-size: 25px">
                                                    <div><i class="bi bi-door-closed" style="font-size: 60px"></i></div>
                                                    CERRADA
                                                </div>
                                            </div>
                                        </template>

                                        <!-- AQS01-L -->
                                        <template v-if="layout.device.status.msg && layout.device.status.msg.hasOwnProperty('co2')"> 
                                            <div class="text-center"><img src="../../../assets/images/icon/co2.png" class="friOpacity6" width="250"></div>
                                            <div class="text-dark fw-bold text-center">
                                                <div class="friColor4 fw-bold" v-if="layout.device.status?.msg && layout.device.status?.msg.hasOwnProperty('co2')" style="font-size: 55px">
                                                    {{ layout.device.status.msg.co2 }} <span class="fs-5">ppm</span>
                                                </div>
                                                <div class="friColor4 fw-bold mb-5" v-if="layout.device.status?.msg && layout.device.status?.msg.hasOwnProperty('air_pressure')" style="font-size: 20px">
                                                    {{ layout.device.status.msg.air_pressure }} <span class="fs-5">hPa</span>
                                                </div>
                                            </div>
                                        </template>
                                        
                                        <!-- EXTERNAL INPUT -->
                                        <template v-if="profile && profile.isAgent && layout.device.hasOwnProperty('externalInput') && layout.device.externalInput!='none'">
                                            <div v-if="layout.device.status.msg.hasOwnProperty('TempC_DS')">
                                                <div class="fs-5 text-dark">SE: {{ layout.device.status.msg.TempC_DS }} ºC</div>
                                            </div>
                                        </template>

                                        <div v-if="layout.device.status.date" class="text-center"><span class="fw-normal text-dark text-xs3">{{ layout.helper.getDateFormatFb(layout.device.status.date) }}</span></div>

                                    </template>
                                    <template v-else><div class="mb-5">Todavía no hemos obtenido datos ...</div></template>
                                    <template v-if="layout.device.logo"><img :src="layout.device.logo" style="height: 150px;" class="mt-2" /></template>

                                    <!-- BATTERÍA -->
                                    <template v-if="layout.device.status?.msg?.BatV">
                                        <div class="align-center mt-3" style="line-height: 1;"><i class="bi bi-battery-full friColor1 p-0 m-0" style="font-size: 40px;"></i></div>
                                        <div class="text-xs">{{ layout.device.status?.msg?.BatV }} v</div>
                                    </template>
                                    <template v-if="layout.device.status?.msg?.BATV">
                                        <div class="align-center mt-3" style="line-height: 1;"><i class="bi bi-battery-full friColor1 p-0 m-0" style="font-size: 40px;"></i></div>
                                        <div class="text-xs">{{ layout.device.status?.msg?.BATV }} v</div>
                                    </template>
                                    <template v-if="layout.device.status?.msg?.BAT_V">
                                        <div class="align-center mt-3" style="line-height: 1;"><i class="bi bi-battery-full friColor1 p-0 m-0" style="font-size: 40px;"></i></div>
                                        <div class="text-xs">{{ layout.device.status?.msg?.BAT_V }} v</div>
                                    </template>
                                    <template v-if="layout.device.status?.msg?.Bat_mV">
                                        <div class="align-center mt-3" style="line-height: 1;"><i class="bi bi-battery-full friColor1 p-0 m-0" style="font-size: 40px;"></i></div>
                                        <div class="text-xs">{{ layout.device.status?.msg?.Bat_mV /1000 }} v</div>
                                    </template>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="text-center w-100"  style="margin: 150px 0;">
                <div class="fw-bold fs-1">Equipos y sensores</div>
                <div class="mt-2">Espere por favor ...</div>
                <div class="spinner-border friColor1 mt-5" role="status">
                <span class="visually-hidden">Cargando ...</span>
                </div>
            </div>
        </template>
    </div>
  </template>
  <script>
  import { defineComponent, onMounted, ref, onUnmounted, watch } from "vue"; //
  import { useAuthStore } from "@/store";
  import { getApp, getAppDevices  } from "../../../model/apps.js"; 
  import { helper as $h } from "../../../utils/helper.js";
  import { _firebase as $_firebase } from "../../../model/firebase";
  import { onSnapshot, collection } from "firebase/firestore"; 
  import { getAlarm  } from "../../../model/alarms.js";
  import { useRoute } from 'vue-router'
  import router from "@/router";
    
  const db   =  $_firebase.firestore()

  export default defineComponent({
    name: 'SensorDash',
    props: {
        isMobile: Boolean,
        appId: String,
        deviceId: String
     },
     components: {  },
    setup(props) {
  
       const authStore      = useAuthStore();
       const layout = ref({ 
                            appId:      null,
                            device:     null,
                            services:   null,
                            alarmBox:   false,
                            deviceId:   null,
                            helper:     $h
                          }) 

        const { user, profile } = authStore;
        const route = useRoute()             
        const initDash = async () => {
         layout.value.app  = await getApp(layout.value.appId)
         let deviceData    = await getAppDevices(layout.value.appId)
         if(deviceData && deviceData?.devices && Object.keys(deviceData.devices).length){
            layout.value.device = deviceData.devices[layout.value.deviceId] //Object.keys(deviceData.devices)[0]
            if(layout.value.device && layout.value.device.status?.alarm?.status && layout.value.device.status?.alarm?.alarms){
                for (const [key, value] of Object.entries(layout.value.device.status.alarm.alarms)) {
                    let alarmData = await getAlarm(layout.value.appId, layout.value.deviceId, value.code)
                    if(alarmData && alarmData.users && alarmData.users.length)
                        layout.value.device.status.alarm.alarms[key].userAlarm = alarmData.users.find( (userAlarm) => userAlarm.userId == profile.uid)
                }
            }
         }
        }

        const powerOff = async () => {
            alert("powerOff")
        }

        const powerOm = async () => {
            alert("powerOm")
        }

        onMounted( async () => { 
         layout.value.appId     = props.appId
         layout.value.appId     = router.currentRoute.value.params.appId
         layout.value.deviceId  = router.currentRoute.value.params.deviceId
         await initDash();
         await detectDevicesChanges();
        });
   
        watch(() => route.query, async (query) => {
            if (query){
                layout.value.deviceId  = router.currentRoute.value.params.deviceId
                layout.value.appId     = router.currentRoute.value.params.appId
                await initDash();
            }
        });

       const detectDevicesChanges = async () => {
          const collectionPath = "apps/" + props.appId + "/devices/";
          const collRef        = collection(db, collectionPath);
          const unsubscribeDetectDevicesChanges = onSnapshot(collRef, async (querySnapshot) => {
              querySnapshot.docChanges().forEach(async change => { 
                  if (change.type === "modified"){
                     let changeData = change.doc.data()
                     if (changeData && changeData.deviceId == layout.value.deviceId)
                        await initDash();
                  }
              }); 
          }, (error) => { console.log("Error:", error); });
          onUnmounted(() => { unsubscribeDetectDevicesChanges(); });
        };

        return { layout, user, props, profile, powerOff, powerOm};
    }
    
  })
  </script>
  <style>
    .friAlarmBoxList{background: url('../../../assets/back/back-alarm.jpg') no-repeat center center fixed; background-size: cover; background-color: #ebebeb; border: none;  }
  </style>