
import { defineComponent, onMounted, ref } from 'vue';
import { IonButton, IonLabel, IonInput, IonItem } from '@ionic/vue'; //
import { useRouter } from "vue-router";
import { useAuthStore  } from '@/store';
import { not as $not } from "../../model/not";

import { _firebase as $_firebase } from "../../model/firebase";
import { sendPasswordResetEmail } from 'firebase/auth';
const auth = $_firebase.auth() 

export default defineComponent({
  name: 'LoginPage',
  props: {
    login: Boolean
  },
  components: { IonButton, IonLabel, IonInput, IonItem }, 
  setup() {
  
    const authStore      = useAuthStore();
    const { logInUser }  = authStore;
    const { user, profile } = authStore;
    const router   = useRouter();
    const email    = ref("");
    const password = ref("");

    const layout         = ref({ recovery: false, recoveryEmail: '' })

    const doLogin = async () => {
        await logInUser(email.value, password.value).then(( async () => {
            if(authStore.error)
                $not.toastApp({ header: 'Login', message: authStore.error, position: 'top', icon: 'info', duration: 2000, animated: true })
            else{
              router.replace("/home/");
            } 
        }));
    };

    const recoveryPass = async () => {
      let validate = true
      if(!layout.value.recoveryEmail)validate = false
      const re = /\S+@\S+\.\S+/;
      if(!re.test(layout.value.recoveryEmail))validate = false
      if (validate) { 
        sendPasswordResetEmail(auth, layout.value.recoveryEmail)
          .then(() => { 
              $not.toastApp({ header: 'Recuperación de constraseña', message: 'Se ha enviado un email para restablecer la contraseña', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'primary', cssClass: 'text-white mt-2' })
          }) .catch((error) => { 
              if(error.code === 'auth/user-not-found') $not.toastApp({ header: 'Recuperación de constraseña', message: 'No hemos encontrado un usuario con esa dirección de correo electrónico', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
              else $not.toastApp({ header: 'Recuperación de constraseña', message: 'Error al enviar el email para restablecer la contraseña' + error, position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' }) 
          })
      }else { $not.toastApp({ header: 'Recuperación de constraseña', message: 'Introduzca su dirección de correo electrónico y revise su formato.', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' }) }
    }

    onMounted( async () => { });

    return { authStore, user, profile, router, layout,  doLogin, email, password, recoveryPass };
  },
  methods: { },
});
