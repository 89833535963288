<template>
    <div>
        <div class="account-box is-form is-footerless px-0 mx-0" :style="isMobile ? 'border-bottom: none !important;' : ''">
            <template v-if="layout.app">
                <div class="form-head stuck-header" :class="isMobile ? 'py-0 ps-4 pe-1' : 'py-3'">
                    <div class="form-head-inner">
                        <div class="left"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5 ms-2' : 'fs-4'">{{  layout.app.name }} </h4></div>
                        <div class="inline-block align-top fs-6 mt-0" style="margin-top: 4px;">
                            <template v-if="layout.app.logo"><img :src="layout.app.logo" style="height: 50px;" /></template>
                            <template v-else>{{  layout.app.id }}</template>
                        </div>
                    </div>
                </div>
                <div class="pt-2 pb-2 fs-5 friBack1 text-white text-center ps-3"><span class="align-middle" v-if="layout.device && layout.device.name">{{ layout.device.name }}</span></div>
                <div class="pt-2 pb-2 fs-6 friBack4 text-center ps-3"><span class="align-middle" v-if="layout.device && layout.device.typeName">{{ layout.device.typeName }}</span></div>
                <div class="ps-3 mt-5" v-if="layout.device">
                    <div class="text-center fs-2 mt-3">Configuración</div>
                    <div class="text-center mt-2 mb-5 mx-5">Reciba notificaciones ante situaciones inusuales, manteniendo un control eficaz y en tiempo real.</div>
                    <div class="d-flex justify-content-center align-items-center fs-5 mt-3" v-if="layout.alarmsNType && layout.alarmsNType.length">
                        <div class="friUserCircle me-2 friBack4 cursor-pointer" @click="layout.addBox = true;"><i class="bi bi-plus-lg text-dark"></i></div>
                    </div>
                    <ion-list  v-if="layout.app" class="pt-0">
                        <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                            <ion-label position="floating" class="friColor1" :class="isMobile ? 'fs-4' : ' fs-5'">Nombre *</ion-label>
                            <ion-input v-model="layout.device.name"  type="text" autocomplete="off"></ion-input>
                        </ion-item>
                        <template v-if="user.isRoot">
                            <ion-row>
                                <ion-col class="m-0 p-0">
                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                    <ion-label position="floating" class="friColor1" :class="isMobile ? 'fs-4' : ' fs-5'">Marca</ion-label>
                                    <ion-input v-model="layout.device.brand"  type="text" :disabled="true" autocomplete="off"></ion-input>
                                </ion-item>
                                </ion-col>
                                <ion-col class="m-0 p-0">
                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                    <ion-label position="floating" class="friColor1" :class="isMobile ? 'fs-4' : ' fs-5'">Modelo</ion-label>
                                    <ion-input v-model="layout.device.model"  type="text" :disabled="true" autocomplete="off"></ion-input>
                                </ion-item>
                                </ion-col>
                            </ion-row>
                            <ion-item class="mt-2 border-none" lines="full" style="--ion-item-border-color: #ccc;">
                                <ion-label>Activo</ion-label>
                                <ion-toggle :enable-on-off-labels="true" slot="end" v-model="layout.device.active" :disabled="true"></ion-toggle>
                            </ion-item>
                        </template>
                        <!--
                        <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                            <ion-label position="floating" class="friColor1" :class="isMobile ? 'fs-4' : ' fs-5'">Intervalo de actualización *</ion-label>
                            <ion-select interface="popover" v-model="layout.device.interval" class="pfInputValue">
                                <ion-select-option v-for="interval in layout.intervals" :key="interval" :value="interval">{{ interval }} minutos</ion-select-option>
                            </ion-select>
                        </ion-item>
                        -->
                    </ion-list>
                </div>
                <div class="ps-3" :style="isMobile ? 'margin-top: 30px; margin-bottom: 50px;' : 'margin: 30px 0;'">
                    <div class="d-flex justify-content-center align-items-center fs-5"><div class="fw-bold friColor1 text-center cursor-pointer" @click="saveSensor()">GUARDAR</div></div>
                </div>
            </template>
            <template v-else>
                <div class="text-center w-100"  style="margin: 150px 0;">
                    <div class="fw-bold fs-1">Configuración</div>
                    <div class="mt-2">Espere por favor ...</div>
                    <div class="spinner-border friColor1 mt-5" role="status">
                    <span class="visually-hidden">Cargando ...</span>
                    </div>
                </div>
            </template>
            
        </div>
        <!-- Device settings 
        <div class="account-box is-form is-footerless" :style="isMobile ? 'border-bottom: none !important;' : ''">
            <div class="form-head stuck-header" :class="isMobile ? 'py-0' : 'py-3'" v-if="layout.device">
                <div class="form-head-inner">
                    <div class="left" v-if="layout.device.title">
                        <h4 :class="isMobile ? 'fs-4 my-3' : 'fs-5'">{{ layout.device.title.toString().substring(0,20) }}<span v-if="layout.device.title.length > 20">...</span><span v-if="!isMobile" class="text-sm text-gray ps-1">/ Configuración</span></h4>
                    </div>
                </div>
            </div>
            <div class="form-body mt-0 pt-0">
                <template v-if="layout.device">
                    <div class="me-0">
                        <div class="fieldset px-0 p-0 mt-0" :class="isMobile ? '' : 'mx-5'" style="max-width: 95% !important;">
                            <ion-list>
                                <div class="columns mb-0 pt-0" style="width: 100% !important;">
                                    <div class="column is-10 py-0 pt-0">
                                        <div v-if="layout.device && layout.device.image" class="text-left mt-5">
                                            <img :src="layout.device.image" :style="isMobile ? 'width: 70%;' : 'max-width: 300px;'" />
                                        </div>
                                    </div>
                                    <div class="column is-2 py-0 px-0" style="position: fixed; top: 10px; right:0"  :class="isMobile ? 'text-right' : 'text-right'">
                                        <div v-if="layout.device.qr">
                                            <img :src="layout.device.qr" style="width: 80px;">
                                        </div>
                                    </div>
                                </div>
                                <div class="columns mb-0 mt-3 pt-0">
                                    <div class="column is-6 py-0">
                                        <ion-item lines="none" class="border-none ion-no-padding">
                                            <ion-label position="floating" :class="isMobile ? 'fs-4' : ' fs-6'">Modelo</ion-label>
                                            <ion-input v-model="layout.device.model" type="text" autocomplete="off" class="px-0" :disabled="true"></ion-input>
                                        </ion-item>
                                    </div>
                                    <div class="column is-6 py-0">
                                        <ion-item lines="none" class="border-none ion-no-padding">
                                            <ion-label position="floating" :class="isMobile ? 'fs-4' : ' fs-6'">Número de serie</ion-label>
                                            <ion-input v-model="layout.device.serialNumber" type="text" autocomplete="off" class="px-0" :disabled="true"></ion-input>
                                        </ion-item>
                                    </div>
                                </div>
                                <div class="column is-12 py-0 mx-0 px-0 mt-3">
                                    <div class="fs-6 fw-semibold">Configuración general</div>
                                    <div class="text-sm mt-2">Ajuste de los parámetros y opciones disponibles en el dispositivo para adaptarlo a las necesidades específicas.</div>
                                    <div class="text-sm">Estos ajustes permiten controlar y optimizar el rendimiento del sistema de refrigeración de acuerdo con sus requisitos y condiciones particulares.</div>
                                </div>
                                <ion-item lines="none" class="border-none ion-no-padding" style="--ion-item-border-color: #ccc;">
                                    <ion-label position="floating" :class="isMobile ? 'fs-4' : ' fs-6'">Nombre</ion-label>
                                    <ion-input v-model="layout.device.title" type="text" autocomplete="off" class="px-0" :class="'text-gray-dark friInputText'"></ion-input>
                                </ion-item>
                                <ion-item lines="none" class="border-none ion-no-padding" style="--ion-item-border-color: #ccc;">
                                    <ion-label position="floating" :class="isMobile ? 'fs-4' : ' fs-6'">Descripción</ion-label>
                                    <ion-input v-model="layout.device.description" type="text" autocomplete="off" class="px-0" :class="'text-gray-dark friInputText'"></ion-input>
                                </ion-item>
                                <template v-if="layout.device.data">
                                    <ion-item lines="none" class="border-none ion-no-padding" style="--ion-item-border-color: #ccc;">
                                        <ion-label position="floating" :class="isMobile ? 'fs-4' : ' fs-6'">Unidad temperatura</ion-label>
                                        <ion-input v-model="layout.device.data.unit" type="text" autocomplete="off" class="px-0 mt-5" :disabled="true" :class="'text-gray-dark friInputText'"></ion-input>
                                    </ion-item>
                                </template>
                                <div class="column is-12 py-0 mx-0 px-0 mt-5">
                                    <div class="fs-6 fw-bold">Dimensiones de la cámara</div>
                                    <div class="text-sm mt-2">Estas dimensiones influyen en varios aspectos, como la capacidad de enfriamiento requerida, la eficiencia energética y la distribución del aire dentro de la cámara.</div>
                                </div>
                                <template v-if="layout.device.room">
                                    <ion-item lines="none" class="border-none ion-no-padding" style="--ion-item-border-color: #ccc;">
                                        <ion-label class="mb-3" position="floating" :class="isMobile ? 'fs-4' : ' fs-6'">Espesor de panel (mm)</ion-label>
                                        <ion-input v-model="layout.device.room.density" type="number" autocomplete="off" class="px-0" :class="'text-gray-dark friInputNumber'"></ion-input>
                                    </ion-item>
                                    <ion-item lines="none" class="border-none ion-no-padding" style="--ion-item-border-color: #ccc;">
                                        <ion-label position="floating" :class="isMobile ? 'fs-4' : ' fs-6'">Superficie interior (m2)</ion-label>
                                        <ion-row class="mt-2"> 
                                            <ion-col size="4">
                                                <ion-input v-model="layout.device.room.area.meters" min="0" max="20" type="number" autocomplete="off" class="px-0" :class="'text-gray-dark friInputNumber'"></ion-input>
                                            </ion-col>
                                            <ion-col size="4">
                                                <ion-input v-model="layout.device.room.area.decimals" min="0" max="99" type="number" autocomplete="off" class="px-0" :class="'text-gray-dark friInputNumber'"></ion-input>
                                            </ion-col>
                                        </ion-row>
                                    </ion-item>
                                    <ion-item lines="none" class="border-none ion-no-padding" style="--ion-item-border-color: #ccc;">
                                        <ion-label position="floating" :class="isMobile ? 'fs-4' : ' fs-6'">Volumen interior (m2)</ion-label>
                                        <ion-row class="mt-2"> 
                                            <ion-col size="4">
                                                <ion-input v-model="layout.device.room.volume.meters" min="0" max="20" type="number" autocomplete="off" class="px-0" :class="'text-gray-dark friInputNumber'"></ion-input>
                                            </ion-col>
                                            <ion-col size="4">
                                                <ion-input v-model="layout.device.room.volume.decimals" min="0" max="99" type="number" autocomplete="off" class="px-0" :class="'text-gray-dark friInputNumber'"></ion-input>
                                            </ion-col>
                                        </ion-row>
                                    </ion-item>
                                </template>
                                <div class="column is-12 py-0 mx-0 px-0">
                                    <div class="buttons mt-5">
                                        <button class="button friBack1 py-2 m-0 text-white w-100" >Guardar</button>
                                    </div>
                                </div>
                            </ion-list>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="text-center mt-5" :style="isMobile ? 'padding-bottom: 50%' : 'padding-bottom: 30px'">
                        <img src="../../../assets/images/theme/loading.gif" style="width: 150px;">
                        <div class="text-sm">Adquiriendo datos del dispositivo<br /> espere por favor ...</div>
                    </div>
                </template>
            </div>
        </div>
        <div class="mt-3 text-center text-xs" v-if="layout.device?.updatedAt">Actualizado el {{ layout.helper.timestamp(layout.device.updatedAt.seconds) }}h </div>
        -->
    </div>
  </template>
  <script>
  import { defineComponent, onMounted, ref, watch } from "vue"; 
  import { getApp, getAppDevices, updateDevice } from "../../../model/apps.js"; 
  import { useAuthStore } from "@/store";
  import { IonItem, IonLabel, IonInput, IonList,  IonRow, IonCol, IonToggle } from '@ionic/vue'; 
  import { helper as $h } from "../../../utils/helper.js";
  //import { setDeviceSamplingIntervalTTN } from "../../../model/ttn.js";
  import { not as $not } from "../../../model/not";
  import { useRoute } from 'vue-router'
  import router from "@/router";

  export default defineComponent({
    name: 'SensorSettings',
    props: {
        isMobile: Boolean,
        appId: String,
        deviceId: String
     },
    components: { IonItem, IonLabel, IonInput, IonList, IonRow, IonCol, IonToggle }, //,IonSelect, IonSelectOption,
    setup(props) {
  
       const authStore      = useAuthStore();
       const layout = ref({ 
                            appId:      null,
                            deviceId: null,
                            device:   null,
                            helper:   $h,
                            intervals:    [ 1, 5, 10, 15, 30, 60], //0, 
                          }) 

       const { user, profile } = authStore;
       const route = useRoute()      

       const saveSensor = async () => {
        let validate = true
        let required = ['name']
        required.forEach((field) => {  if(!layout.value.device[field])validate = false })
        if(validate){
            //console.log({ name: layout.value.device.name, interval: layout.value.device.interval, deviceId: layout.value.deviceId.toString() })
            let updDev = { appId: layout.value.appId, name: layout.value.device.name, interval: layout.value.device.interval, deviceId: layout.value.deviceId }
            await updateDevice(updDev).then(async () => {
                $not.toastApp({ message: 'Se ha actualizado el dispositivo correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, cssClass: 'text-white mt-2 friToast1'})
                //await setDeviceSamplingIntervalTTN(layout.value.appId, layout.value.deviceId, layout.value.device.interval).then(() => { })            
            })
        }else $not.toastApp({ message: 'Debe rellenar los parámetros requeridos', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
       }

       const initSettings = async () => {
         layout.value.appId    = props.appId
         layout.value.deviceId = router.currentRoute.value.params.deviceId
         layout.value.app    = await getApp(layout.value.appId)
         let deviceData      = await getAppDevices(layout.value.appId)
         if(deviceData && deviceData?.devices && Object.keys(deviceData.devices).length)layout.value.device = deviceData.devices[layout.value.deviceId]
       }

       onMounted( async () => { 
         await initSettings();
       });
  

       watch(() => route.query, async (query) => {
            if (query && router.currentRoute.value.params && router.currentRoute.value.params.deviceId){
                layout.value.deviceId  = router.currentRoute.value.params.deviceId
                await initSettings();
            }
        });

       return { layout, user, props, profile, saveSensor};
    }
    
  })
  </script>
<style>

</style>