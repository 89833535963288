
import { defineComponent, onMounted, ref, watch } from "vue"; 
import { IonModal, IonContent, IonItem, IonLabel, IonInput, IonList, IonCol, IonRow, IonSelect, IonSelectOption  } from '@ionic/vue';
import { useAuthStore, useImageStore } from '@/store';
import { not as $not } from "../../model/not";
import { getClientList, getAgentList, createUserAuth, createClient } from "../../model/api.js";
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'ClientsPage',
  props: { 
      isMobile: Boolean,
  },
  components: { IonModal, IonContent, IonItem, IonLabel, IonInput, IonList, IonCol, IonRow, IonSelect, IonSelectOption },
  setup() {
     
    const authStore      = useAuthStore();
    const imageStore     = useImageStore();
    const route          = useRoute()
    const { user, profile } = authStore;
    const layout         = ref({
                                  loading: true,
                                  clients: null,
                                  newClient: null,
                              })


    const initNewAClient = () => {
        layout.value.newClient = {
          first:         "",
          last:          "",
          document:      "",
          active:        true,
          deleted:       false,
          email:         null,
          email2:        null,
          password:      null,
          password2:     null,
          role:          "client",
          notify:        { email: "", sms: "", whatsapp: "" },
        }
    }

    const saveClient = async () => {
      let validate = true
      let required = ['first', 'last','document','email', 'email2', 'password','password2']
      required.forEach((field) => {  if(!layout.value.newClient[field])validate = false })
      if(layout.value.newClient.password != layout.value.newClient.password2)validate = false
      if(layout.value.newClient.email != layout.value.newClient.email2)validate = false
      if(validate){
        let createClientResp  = await createUserAuth({ email: layout.value.newClient.email, password: layout.value.newClient.password });
        layout.value.loading  = false;
        if(createClientResp && createClientResp.status == "success") {
          layout.value.newClient.uid = createClientResp.uid;
          let client = await createClient(layout.value.newClient);
          if(client && client.status == "success") {
            setTimeout(async () => { 
              await initClients()
              $not.toastApp({ message: 'Cliente creado correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'primary', cssClass: 'text-white mt-2' })
             }, 1000);
          }else $not.toastApp({ message: 'Error al crear el cliente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
        }else $not.toastApp({ message: 'Error al crear el cliente: ' + createClientResp.error, position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })        
      }else $not.toastApp({ message: 'Debe rellenar los parámetros requeridos', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
    }

    const initClients = async () => {
      initNewAClient();
      layout.value.clients = await getClientList();
      let agents  = await getAgentList();
      if (layout.value.clients && layout.value.clients.clients && agents && agents.agents)layout.value.clients.clients = {...layout.value.clients.clients, ...agents.agents}
      layout.value.loading = false;
    }

    onMounted( async () => { 
     await initClients()
    });

    watch(() => route.query, async (query) => {
      if (query)await initClients()
    });

    return { user, profile, imageStore, layout, saveClient };
  }
  
})
