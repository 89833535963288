import { _firebase } from "./firebase.js";
import { collection, query, orderBy, where, getDocs, setDoc, doc, getDoc, serverTimestamp, addDoc, deleteDoc  } from "firebase/firestore";  
import { createAppTTN, deleteAppTTN, createGatewayTTN, updateGatewayTTN, deleteGatewayTTN, createDeviceTTN, deleteDeviceTTN, createGatewayKeysTTN } from "../model/ttn.js"; 
import { deleteUserAuth } from "../model/api.js";
import axios from "axios";

const db  =  _firebase.firestore() 

const appsList = async (opt = {}) => {
    let order = { field: "name", type: "asc" }
    if (opt.order)
        switch (opt.order) {
            case "nameAsc": order = { field: "name", type: "asc" }; break;
            case "nameDes": order = { field: "name", type: "desc" }; break;
        }
    var resp = { apps: {}, count: 0, active: 0, inactive: 0 }
    let deviceRef = collection(db, "apps"); 
    deviceRef = query(deviceRef, orderBy(order.field, order.type))
    deviceRef = query(deviceRef, where("deleted", "==", false))
    //deviceRef = query(deviceRef, where("active", "==", true))
    const docsSnap = await getDocs(deviceRef);
    await Promise.all(docsSnap.docs.map(async (doc) => {
        let isFilter = true
        if(opt.byUser){
            if(opt.byUser?.isUser && (!opt.byUser?.apps || !opt.byUser.apps.includes(doc.id)))isFilter = false;
            if(opt.byUser?.role && opt.byUser.role == 'client')isFilter = true;
        }
        if(isFilter){
            let app = doc.data();
            if(opt.byUser && opt.byUser?.isClient && app.clientId != opt.byUser.id)isFilter = false;
            if(opt.byUser && opt.byUser?.role && opt.byUser.role == 'client' && app.clientId != opt.byUser.id)isFilter = false;
            if(opt.byUser && opt.byUser?.role && opt.byUser.role == 'agent' && app.agentId != opt.byUser.id)isFilter = false;
            if(isFilter){
                resp.apps[doc.id]         = app;
                resp.apps[doc.id].id      = doc.id
                resp.count++
                if (app.active) resp.active++
                else resp.inactive++
            }
        }
    }));
    return resp;
}


const getApp = async (appId) => {
    let resp    = {};
    if (!appId) return resp;
    let docRef  = doc(db, "apps", appId);
    let docSnap = await getDoc(docRef);
    if(docSnap.exists) {
        let app  = docSnap.data();
        if (app && !app.deleted) {
            app.id = docSnap.id;
            resp   = app;
        }
    }
    return resp;
}

const createApp = async (app) => {
    app.createdAt = serverTimestamp();
    app.updatedAt = serverTimestamp();
    await setDoc(doc(db, "apps", app.appId.toString()), app);
    await createAppTTN(app);
    return { success: true };
}

const updateApp = async (app) => {
    let resp = { success: false };
    if(app.gateways) delete app.gateways;
    if(app.ttn) delete app.ttn;
    if(app.devices) delete app.devices;
    if(app.scenes) delete app.scenes;
    app.updatedAt = serverTimestamp();
    let docRef = doc(db, "apps", app.appId.toString());
    await setDoc(docRef, app, { merge: true });
    resp.success = true;
    return resp;
}

const deleteApp = async (appId) => {
    let resp = { success: false };
    let app  = await getApp(appId);
    if (app) {
        app.deleted     = true;
        app.updatedAt   = serverTimestamp();
        let docRef      = doc(db, "apps", appId);
        await setDoc(docRef, app, { merge: true });
        resp.success = true;
        await deleteAppTTN(appId); //remove from ttn
    }
    return resp;
}

const getAppGateways = async (appId) => {
    let resp = { success: false, gateways: {} };
    let gatewaysRef = collection(db, "apps", appId, "gateways");
    gatewaysRef = query(gatewaysRef, where("deleted", "==", false))
    try {
        let querySnapshot = await getDocs(gatewaysRef);
        let gateways = {};
        querySnapshot.forEach((doc) => {
            gateways[doc.id] = doc.data();
        });

        if (Object.keys(gateways).length > 0) {
            resp.success = true;
            resp.gateways = gateways;
        }
    } catch (error) {  console.error("Error al obtener gateways:", error); }
    return resp;
}

const getAppMessagesByUser = async (appId, userId) => {
    let resp = { success: false, messages: {} };
    let messagesRef = collection(db, "apps", appId, "messaging");
    messagesRef = query(messagesRef, where("deleted", "==", false))
    messagesRef = query(messagesRef, where("viewed", "==", false))
    messagesRef = query(messagesRef, where("uid", "==", userId))
    try {
        let querySnapshot = await getDocs(messagesRef);
        let messages = {};
        querySnapshot.forEach((doc) => {
            let message = doc.data();
            messages[doc.id] = message;
                messages[doc.id].messageId = doc.id;
        });
        resp.success = true;
        if (Object.keys(messages).length > 0) {
            resp.messages = messages;
        }
    } catch (error) {
        console.error("Error al obtener messages:", error);
    }
    return resp;
}

const setViewedMessage = async (appId, messageId) => {
    let resp = { success: false };
    let docRef = doc(db, "apps", appId, "messaging", messageId.toString());
    try { await deleteDoc(docRef); resp.success = true;
    } catch (error){ resp.error = error; }
    return resp;
}

const createGateway = async (gateway) => {
    let resp = { success: false };
    gateway.createdAt = serverTimestamp();
    gateway.updatedAt = serverTimestamp();
    let docRef = doc(db, "apps", gateway.appId, "gateways", gateway.gatewayId.toString());
    await setDoc(docRef, gateway);
    resp.success = true;
    await createGatewayTTN(gateway);     //create in ttn
    let gatewaykeys = await createGatewayKeysTTN(gateway); //create keys in ttn
    if (gatewaykeys) {
        await updateGateway(
            {
                appId:          gateway.appId,
                gatewayId:      gateway.gatewayId,
                keys: {
                    cups:       gatewaykeys.cups.response,
                    lns:        gatewaykeys.lns.response,
                },
            }, false
        );
    }
    return resp;
}

const updateGateway = async (gateway, updateTTN = true) => {
    let resp = { success: false };
    gateway.updatedAt = serverTimestamp();
    let docRef = doc(db, "apps", gateway.appId, "gateways", gateway.gatewayId.toString());
    await setDoc(docRef, gateway, { merge: true });
    resp.success = true;
    if(updateTTN)await updateGatewayTTN(gateway); //update in ttn
    return resp;
}

const updateScene = async (appId, scene) => {
    let resp = { success: false };
    scene.updatedAt = serverTimestamp();
    let docRef = doc(db, "apps", appId, "scenes", scene.sceneId.toString());
    await setDoc(docRef, scene, { merge: true });
    resp.success = true;
    return resp;
}

const createScene = async (appId, scene) => {
    let resp = { success: false };
    scene.createdAt = serverTimestamp();
    scene.updatedAt = serverTimestamp();
    let docRef    = collection(db, "apps", appId, "scenes");
    let docSnap   = await addDoc(docRef, scene);
    scene.sceneId = docSnap.id;
    await updateScene(appId, scene);
    return resp;
}

const getAppScenes = async (appId) => {
    let resp = { success: false, gateways: {} };
    if(!appId)return resp;
    let sceneRef = collection(db, "apps", appId, "scenes");
    sceneRef = query(sceneRef, where("deleted", "==", false))
    try {
        let querySnapshot = await getDocs(sceneRef);
        let scenes        = {};
        querySnapshot.forEach(async (doc) => { 
            scenes[doc.id]          = doc.data();
            scenes[doc.id].sceneId = doc.id;
            scenes[doc.id].typeName = await getSceneType(doc.data().type);
        });
        if (Object.keys(scenes).length > 0) {
            resp.success = true;
            resp.scenes  = scenes;
        }
    } catch (error) { console.error("Error al obtener scenes:", error); }
    return resp;
}

const getScene = async (appId, sceneId) => {
    let resp = { success: false, scene: {} };
    let sceneRef = doc(db, "apps", appId.toString(), "scenes", sceneId.toString());
    let docSnap = await getDoc(sceneRef);
    if (docSnap.exists) {
        resp.success  = true;
        resp.scene    = docSnap.data();
        if(resp.scene.type)resp.typeName = getSceneType(resp.scene.type);
    }
    return resp;
}

const deleteScene = async (appId, sceneId) => {
    let resp = { success: false };
    let docRef = doc(db, "apps", appId, "scenes", sceneId.toString());
    await setDoc(docRef, { deleted: true }, { merge: true });
    resp.success = true;
    return resp;
}

const createDevice = async (device) => {
    let resp = { success: false };
    device.createdAt = serverTimestamp();
    device.updatedAt = serverTimestamp();
    let docRef = doc(db, "apps", device.appId, "devices", device.deviceId.toString());
    await setDoc(docRef, device);
    resp.success = true;
    let response = await createDeviceTTN(device); //create in ttn
    console.log("createDevice", response);
    return resp;
}

const getAppDevices = async (appId) => {
    let resp = { success: false, devices: {} };
    if (!appId) return resp;
    let devicesRef = collection(db, "apps", appId, "devices");
    devicesRef = query(devicesRef, where("deleted", "==", false))
    try {
        let querySnapshot = await getDocs(devicesRef);
        let devices = {};
        querySnapshot.forEach(async (doc) => { 
            devices[doc.id]          = doc.data();
            devices[doc.id].typeName = await getDeviceType(doc.data().type);
        });
        if (Object.keys(devices).length > 0) {
            resp.success = true;
            resp.devices = devices;
        }
    } catch (error) {
        console.error("Error al obtener devices:", error);
    }
    return resp;
}

const getScenesByUser = async (appId, userId) => {
    let resp = { success: false, scenes: {} };
    let scenesRef = collection(db, "apps", appId, "scenes");
    scenesRef = query(scenesRef, where("deleted", "==", false))
    try {
        let querySnapshot = await getDocs(scenesRef);
        let scenes = {};
        querySnapshot.forEach((doc) => {
            let scene = doc.data();
            //exist userId in scene.users is []
            if (scene.users && scene.users.includes(userId)) {
                scenes[doc.id] = scene;
                scenes[doc.id].sceneId = doc.id;
            }
        });
        if (Object.keys(scenes).length > 0) {
            resp.success = true;
            resp.scenes = scenes;
        }
    } catch (error) {
        console.error("Error al obtener scenes:", error);
    }
    return resp;
}


const getAppDevice = async (appId, deviceId) => {
    let resp = { success: false, device: {} };
    if (!appId || !deviceId) return resp;
    let deviceRef = doc(db, "apps", appId.toString(), "devices", deviceId.toString());
    let docSnap = await getDoc(deviceRef);
    if (docSnap.exists) {
        resp.success  = true;
        resp.device   = docSnap.data();
        if(resp.device.type)resp.typeName = getDeviceType(resp.device.type); 
    }
    return resp;
}

const getDeviceType = async (type) => {
    let typeName = type;
    if (type === "dht") typeName = "Sensor de humedad y temperatura";
    if (type === "dht52") typeName = "Sensor de humedad y temperatura";
    if (type === "lds02") typeName = "Contacto magnético";
    if (type === "xw265k") typeName = "C15-XW265K";
    if (type === "aqs01l") typeName = "Sensor de calidad de aire";
    return typeName;
}

const getSceneType = async (type) => {
    let typeName = type;
    if (type === "coldroom") typeName = "Cámara frigorífica";
    if (type === "handlingroom") typeName = "Sala de manipulación";
    return typeName;
}

const getScenesByDevice = async (appId, deviceId) => {
    let resp = { success: false, scenes: {} };
    let scenesRef = collection(db, "apps", appId, "scenes");
    scenesRef = query(scenesRef, where("deleted", "==", false))
    try {
        let querySnapshot = await getDocs(scenesRef);
        let scenes = {};
        querySnapshot.forEach((doc) => {
            let scene = doc.data();
            if (scene.devices && scene.devices.includes(deviceId)) {    
                scenes[doc.id] = scene;
                scenes[doc.id].sceneId = doc.id;
            }
        });
        if (Object.keys(scenes).length > 0) {
            resp.success = true;
            resp.scenes = scenes;
        }
    } catch (error) {
        console.error("Error al obtener scenes:", error);
    }
    return resp;
}

const updateDevice = async (device) => {
    let resp = { success: false };
    console.log('in updateDevice', device)
    device.updatedAt = serverTimestamp();
    try {
        let docRef       = doc(db, "apps", device.appId, "devices", device.deviceId.toString());
        await setDoc(docRef, device, { merge: true });
        resp.success = true;
    } catch (error) {
        console.error("Error:", error);
    }
    return resp;
}

const deleteGateway = async (appId, gatewayId) => {
    let resp = { success: false };
    let docRef = doc(db, "apps", appId, "gateways", gatewayId.toString());
    await setDoc(docRef, { deleted: true }, { merge: true });
    resp.success = true;
    await deleteGatewayTTN(gatewayId); //remove from ttn
    return resp;
}

const deleteDevice = async (appId, deviceId) => {
    let resp = { success: false };
    let docRef = doc(db, "apps", appId, "devices", deviceId.toString());
    await setDoc(docRef, { deleted: true }, { merge: true });
    resp.success = true;
    await deleteDeviceTTN(appId, deviceId); //remove from ttn
    return resp;
}

const getAppLogs = async (appId, options = {}) => {
    let resp = { success: false, logs: [] };
    try {
        let queryParams = new URLSearchParams(options).toString();
        let response    = await axios.get(`https://iot.fricontrolalba.com/webhook/log?${queryParams}`, { headers: {"Content-Type": "application/json" } });
        if (response.data) {
            resp.success = true;
            resp.logs    = response.data;
            resp.logs.records.sort((a, b) => {
                if (a.date < b.date) return 1;
                if (a.date > b.date) return -1;
                return 0;
            });
        }
    } catch (error) {
        console.error("Error al obtener logs:", error);
    }
    return resp;
};

const getDeviceAlarmsLog = async (appId, options = {}) => {
    let resp = { success: false, logs: [] };
    try {
        let queryParams = new URLSearchParams(options).toString();
        let response    = await axios.get(`https://iot.fricontrolalba.com/webhook/alarms?${queryParams}`, { headers: {"Content-Type": "application/json" } });
        if (response.data) {
            resp.success = true;
            resp.logs    = response.data;
            if (resp.logs.alarms){
                resp.logs.alarms.sort((a, b) => {
                    if (a.date < b.date) return 1;
                    if (a.date > b.date) return -1;
                    return 0;
                });
            }
        }
    } catch (error) {
        console.error("Error al obtener alarms:", error);
    }
    return resp;
};

const getDeviceAlarms = async (appId, deviceId) => {
    let resp = { success: false, alarms: {} };
    let alarmsRef = collection(db, "apps", appId, "devices", deviceId, "alarms");
    alarmsRef = query(alarmsRef, where("deleted", "==", false))
    try {
        let querySnapshot = await getDocs(alarmsRef);
        let alarms = {};
        querySnapshot.forEach((doc) => {
            alarms[doc.id] = doc.data();
            alarms[doc.id].id = doc.id;
        });

        if (Object.keys(alarms).length > 0) {
            resp.success = true;
            resp.alarms = alarms;
        }
    } catch (error) {
        console.error("Error al obtener alarms:", error);
    }
    return resp;
}

const getAppUsers = async (appId) => {
    let resp = { success: false, users: {} };
    if (!appId) return resp;
    let usersRef = collection(db, "profiles");
    usersRef = query(usersRef, where("apps", "array-contains", appId))
    usersRef = query(usersRef, where("deleted", "==", false))
    try {
        let querySnapshot = await getDocs(usersRef);
        let users = {};
        querySnapshot.forEach((doc) => {
            users[doc.id] = doc.data();
        });

        if (Object.keys(users).length > 0) {
            resp.success = true;
            resp.users = users;
        }
    } catch (error) {
        console.error("Error:", error);
    }
    return resp;
}

const userLastLogin = async (userId) => {
    let resp = { success: false };
    let dateTimestamp = serverTimestamp();
    let docRef = doc(db, "profiles", userId);
    try { await setDoc(docRef, { lastLogin: dateTimestamp }, { merge: true });
    } catch (error) { console.error("Error:", error); }
    resp.success = true;
    return resp;
}

const updateUser = async (user) => {
    let resp = { success: false };
    user.updatedAt = serverTimestamp();
    let docRef = doc(db, "profiles", user.id);
    await setDoc(docRef, user, { merge: true });
    resp.success = true;
    return resp;
}

const deleteUser = async (userId) => {
    let resp = { success: false };
    let docRef = doc(db, "profiles", userId);
    await setDoc(docRef, { deleted: true, active: false }, { merge: true });
    resp.success = true;
    await deleteUserAuth({ uid: userId });
    return resp;
}

const getJobs = async () => {
    let resp = { success: false, logs: [] };
    try {
        let response    = await axios.get(`https://iot.fricontrolalba.com/webhook/jobs`, { headers: {"Content-Type": "application/json" } });
        if (response.data) {
            resp.success = true;
            resp.logs    = response.data;
        }
    } catch (error) {
        console.error("Error al obtener logs:", error);
    }
    return resp;
};

const discardJob = async (options = {}) => {
    let resp = { success: false };
    try {
        let queryParams = new URLSearchParams(options).toString();
        let response    = await axios.get(`https://iot.fricontrolalba.com/webhook/job?${queryParams}`, { headers: {"Content-Type": "application/json" } });
        if (response.data && response.data.success)resp.success = response.data.success;
    } catch (error) {
        console.error("Error discard job:", error);
    }
    return resp;
}

const exportStats = async (options = {}) => {
    try {
        let { type, data } = options;
        let queryParams = new URLSearchParams();
        queryParams.append('type', type);
        queryParams.append('data', JSON.stringify(data)); 
        let url = `https://iot.fricontrolalba.com/webhook/export?${queryParams.toString()}`;
        //console.log("url export", url)
        let pdfResponse = await axios.get(url, {
            headers: {"Content-Type": "application/json" }
        });
        return pdfResponse.data;
    } catch (error) {
        console.error("Error export stats:", error);
        return null;
    }
}

export { userLastLogin, appsList, createApp, getApp, getAppGateways, createGateway, deleteApp, updateApp, updateGateway, deleteGateway, createDevice, getAppDevice, getAppDevices, updateDevice, deleteDevice, getAppLogs, getDeviceAlarms, getDeviceAlarmsLog, getAppUsers, 
         updateUser, deleteUser, getJobs, discardJob, exportStats, getAppScenes, updateScene, createScene, getScene, getScenesByDevice, getScenesByUser, getAppMessagesByUser, setViewedMessage, deleteScene  };