<template>
    <div class="toolbar desktop-toolbar me-2">
        <div>
            <div class="inline-block align-top mt-1">
                
                <!--<i class="bi bi-exclamation-triangle-fill align-top" style="font-size: 26px !important; color: #ff0000; opacity: 0.8;"></i> 
                <span class="me-4 friColor3 align-top pt-2">(2)</span>-->
                
                <!--<div class="inline-block align-top fs-5" style="padding-top:3px;">
                    <div class="py-1 px-5 cursor-pointer friBack1 text-white br-5 me-2" style="font-size: 12px;" @click="menuAction('menu')">
                        Configuración
                    </div>
                </div>-->
                
                <!--<div class="inline-block align-top fs-5" style="padding-top:3px;" v-if="layout.controller?.copilot?.active">
                    <i class="bi bi-robot me-1 align-top friTopLink" style="font-size: 24px !important;margin-left: 5px !important; "></i>
                </div>-->

                <div v-if="layout.doorStatus" class="inline-block align-top fs-5" style="padding-top:2px; ">
                    <i class="bi bi-door-open me-1 align-top friColor3 friTopDoor" style="font-size: 20px !important;margin-left: 7px !important; "></i>
                    <template v-if="layout.doorStatus?.status?.seconds">
                        <!-- <i class="bi bi-door-open align-top friColor3" style="font-size: 170px !important; position: fixed; top:18%; right: 8%; background-color: #fff;transform: rotate(-5deg);"></i>-->
                        <div id="doorAlarmBox" class="friBack7 fs-3 text-white text-center py-4 friTextDoor fw-bold">
                            <div class="mt-5">
                                <svg width='130px' height='130px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 16H11V14H13V16ZM13 12H11V7H13V12Z' fill='white'/>
                                </svg>
                            </div>
                            <div class="text-white mt-3 fs-2">{{ layout.doorStatus.name }}</div>
                            {{ layout.doorStatus?.open }} 
                            <template v-if="layout.doorStatus?.status?.seconds<3600">
                                {{ Math.floor(layout.doorStatus?.status?.seconds / 60) }} minuto<span v-if="layout.doorStatus?.status?.seconds > 60">s</span> <!--<span class="text-white fs-6 fw-normal ps-2">{{ layout.doorStatus?.name }}</span>-->
                            </template>
                            <template v-else>
                                {{ Math.floor(layout.doorStatus?.status?.seconds / 3600) }} hora<span v-if="layout.doorStatus?.status?.seconds > 3600">s</span>
                            </template>
                            <div class="text-white fs-6">Por favor, cierre la puerta lo antes posible.</div>
                            <div v-if="layout.doorStatus?.status?.notify?.date && !layout.doorStatus?.status?.notify?.canceled" class="mt-3 text-white fs-6">Alarma notificada</div>
                        </div>   
                    </template>                 
                </div>

                <div class="me-1 ms-1 inline-block align-top fs-5" style="padding-top: 2px; ">
                    <i class="bi bi-activity me-1 align-top" :class="layout.controller?.cron?.active ? 'friColor1' : 'friColor3'"
                    style="font-size: 25px !important;margin-left: 2px !important;"></i>
                </div>
                <div class="inline-block align-top fs-5">
                    <i id="topServer"  class="bi bi-wifi  align-top me-2" style="font-size: 28px !important; color: #999;" @click="menuAction('wifi')"></i>
                </div>
                
                <!--<i v-if="layout.ac" class="bi bi-battery-charging friTopLink align-top" @click="menuAction('battery')" style="font-size: 30px !important;color: #009292 !important;"></i>
                <i v-else class="bi bi-battery-full friTopLink align-top" @click="menuAction('battery')" style="font-size: 30px !important; color: #ff0000 !important;"></i>-->

                <i v-if="layout.acStatus" class="bi bi-battery-charging friTopLink align-top" @click="menuAction('battery')" style="font-size: 30px !important;color: #009292 !important;"></i>
                <i v-else class="bi bi-battery-full friTopLink align-top" @click="menuAction('battery')" style="font-size: 30px !important; color: #ff0000 !important;"></i>
                
               
                <div class="me-1 ms-2 inline-block align-top fs-5" style="padding-top: 0.5px;" 
                    :class="layout.acStatusPercent.status?.status?.percent < 40 ? 'friColor3' : ''"
                    v-if="!layout.acStatus">
                    <!--{{ parseInt(layout.acStatusPercent.status?.status?.percent) }}%-->
                    <div class="friAlertBattery" v-if="layout.acStatusPercent.status?.status?.percent < 40">
                        <div style="height: 150px;"><i class="bi bi-exclamation-triangle-fill text-white iconAlert"></i></div>
                        <div class="fs-1 text-white">{{ parseInt(layout.acStatusPercent.status?.status?.percent) }}%</div>
                        <div class="fw-bold mt-2 fs-2 text-white">La batería está muy baja</div> 
                        <div class="mt-2 text-white">Conecte ahora a la corriente eléctrica.</div>
                    </div>
                </div>
                
                <!--
                <div class="me-1 ms-2 inline-block align-top fs-5" style="padding-top: 0.5px;" 
                    :class="layout.currentValue < 70 ? 'friColor3' : ''"
                    v-if="layout.currentValue">{{ layout.currentValue }}%
                    <div class="friAlertBattery" v-if="layout.currentValue < 40">
                        <div style="height: 150px;"><i class="bi bi-exclamation-triangle-fill text-white iconAlert"></i></div>
                        <div class="fs-1 text-white">{{ layout.currentValue }}%</div>
                        <div class="fw-bold mt-2 fs-2 text-white">La batería está muy baja</div> 
                        <div class="mt-2 text-white">Conecte ahora a la corriente eléctrica.</div>
                    </div>
                </div>
                -->
            </div>
            <div class="inline-block align-top ms-2 fs-5" style="margin-top: 4px; vertical-align: top !important;">{{ layout.currentTime }}</div>
        </div>
    </div>
</template>
<script>
import { defineComponent, ref, onMounted, onBeforeUnmount, onUnmounted } from 'vue';
import { _firebase as $_firebase } from "../../../model/firebase";
import { onSnapshot, collection } from "firebase/firestore"; 
import { useAuthStore } from '@/store';
import { getController } from "../../../model/api.js"; //requestDevice
const db   =  $_firebase.firestore() 

export default defineComponent({
name: 'TopDashMenuComponent',
props: {
  isMobile: Boolean,
},
components: {   },
setup() {
    const authStore         = useAuthStore();
    const { user, profile } = authStore;
    const layout            = ref({ 
                                controller:   false,
                                currentTime:  new Date().toLocaleTimeString(),
                                intValCheckBattery: 300000, //5 minutes
                                ups:          false,
                                ac:           true,
                                UpsIsRequestInProgress: false, 
                                currentValue: false,
                                doorStatus:   false,
                                acStatus:     true,
                            });

    const checkSensors = () => {
        console.log("Initializing checkSensors...");
        const collectionPath = "alba/" + profile.controllerId + "/sensors";
        const collRef = collection(db, collectionPath);
        const unsubscribeCheckSensors = onSnapshot(collRef, (querySnapshot) => {
            querySnapshot.docChanges().forEach(change => {
                if (change.type === "added" || change.type === "modified") {
                    const docData = change.doc.data();
                    if (Object.prototype.hasOwnProperty.call(docData, 'status')) {
                        const statusAttribute = docData.status;
                        if(docData.type == "micro" && (docData.code && docData.code=="door")){
                          if (statusAttribute && statusAttribute.status === "open")layout.value.doorStatus = docData;
                          else layout.value.doorStatus = false;
                        }
                        if(docData.code && docData.code=="ups"){
                          if (statusAttribute && statusAttribute.ac)layout.value.acStatus = docData;
                          else{
                            layout.value.acStatus = false;
                            layout.value.acStatusPercent = docData;
                          }
                        }
                    } else console.log("No status attribute found in the document.");
                }
            });
        }, (error) => {
            console.log("Error sensors status:", error);
        });

        onUnmounted(() => {
            console.log("Unsubscribing from Firestore listener...");
            unsubscribeCheckSensors();
        });
    };

    onMounted(async () => {
        
        layout.value.controller = await getController(profile.controllerId);

        const TimerInterval = setInterval(() => { 
            layout.value.currentTime = new Date().toLocaleTimeString(); 
        }, 1000);
        
        /*async function checkBatteryStatus() {
            if (!layout.value.UpsIsRequestInProgress) { 
                layout.value.UpsIsRequestInProgress = true; 
                try {  layout.value.ups = await requestDevice("https://"+profile.controllerId.toString().toLowerCase()+".fricontrolalba.com", "controller-actions/"+profile.controllerId+"/ups");
                } catch (error) { console.error("Error UPS:", error);
                } finally {  layout.value.UpsIsRequestInProgress = false; }            
                if(layout.value.ups && layout.value.ups.response?.result?.ups?.estado === "Cargando") {
                    layout.value.ac = true;
                    layout.value.currentValue = false;
                } else {
                    layout.value.ac = false;
                    if(layout.value.ups.response?.result?.ups?.percent)layout.value.currentValue = parseInt(layout.value.ups.response?.result?.ups?.percent); 
                }
            }
            setTimeout(checkBatteryStatus, layout.value.intValCheckBattery);
        }*/
        
        //checkBatteryStatus();
        checkSensors();

        onBeforeUnmount(() => {
            clearInterval(TimerInterval);
        });
    });

  return {user, profile, layout };
},
methods: {
    menuAction(id) { this.$emit('menuAction', id); },
},
});
</script>
<style>
.friAlertBattery{position: fixed; top: 0; left: 0; width: 100%; margin: 0 auto; background-color: #ff0000; text-align: center; color: #fff; height: 100vh; padding: 10px 0;}
.iconAlert{ font-size: 120px; margin: 0; padding: 0;}
@keyframes blink {
    0%   { /*opacity: 1;*/ color: #fff; }
    50%  { /*opacity: 0.5;*/ color: #ffbf00; }
    100% { /*opacity: 1;*/ color: #fff;}
}
.iconAlert {
    animation: blink 2s infinite;
}

@keyframes bellSwing {
    0%   { transform: rotate(-5deg); }
    25%  { transform: rotate(5deg); }
    50%  { transform: rotate(-5deg); }
    75%  { transform: rotate(5deg); }
    100% { transform: rotate(0deg); }
}

.friTopBell {
    animation: bellSwing 1s infinite;
    display: inline-block; /* Esto es necesario para que transform funcione en algunos navegadores */
}

#doorAlarmBox{ z-index: 999999 !important;position: fixed !important; left: 0 !important; top: 0 !important;width: 100% !important; height: 100vh !important; }
</style>