<template>
    <div>
      <div class="account-box is-form is-footerless" :style="isMobile ? 'border-bottom: none !important;' : ''">
            <template v-if="layout.app">
                <div class="form-head stuck-header" :class="isMobile ? 'py-0 ps-3 pe-3' : 'py-3'">
                    <div class="form-head-inner">
                        <div class="left"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5 ms-2' : 'fs-4'">{{  layout.app.name }} </h4></div>
                        <div class="inline-block align-top me-1 fs-6 mt-0" style="margin-top: 4px;">
                            <template v-if="layout.app.logo"><img :src="layout.app.logo" style="height: 50px;" /></template>
                            <template v-else>{{  layout.app.id }}</template>
                        </div>
                    </div>
                </div>
                <div class="px-3 pt-3">
                    <div class="text-center fs-1 mt-3">Usuarios</div>
                    <div class="text-center mt-2 mb-3 mx-5">Administre los usuarios que tienen acceso a la aplicación y a sus dispositivos vinculados.</div>
                    <div class="d-flex justify-content-center align-items-center fs-5 my-5" v-if="layout.appId && layout.app.users && layout.app.users.users && Object.keys(layout.app.users.users).length < layout.app.limits.users">
                        <div class="friUserCircle me-2 friBack4 cursor-pointer" @click="layout.newUser.addBox = true;"><i class="bi bi-plus-lg text-dark"></i></div>
                    </div>
                    <div class="me-4 ms-2">
                      <ion-list v-if="layout.appId && layout.app.users && layout.app.users.users && Object.keys(layout.app.users.users).length" style="background-color: #fff !important;"> 
                        <ion-item detail v-for="(user, userId) in layout.app.users.users" :key="userId" style="--ion-item-border-color: #e4e3e3;" @click="$router.push({ path: '/user/' + layout.appId + '/' + userId, query: { section:  'dashboard' } })" class="cursor-pointer">
                          <ion-avatar slot="start" style="width: 55px; height: 50px;" class="text-center"><div class="friUserCircle friBack1">{{ user.first[0] }}{{ user.last[0] }}</div></ion-avatar>
                          <ion-label>
                            <h2 class="fs-5">{{ user.first }} {{ user.last }}</h2>
                            <div class="text-xs">Usuario</div>
                            <div  v-if="!user.active" class="mt-1"><span class="badge text-dark friBorder3">Inactivo</span></div>
                          </ion-label>
                        </ion-item>
                      </ion-list>
                      <div v-else class="pt-2"><div class="text-center mt-5">No ha creado ningún usuario.</div></div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="text-center w-100"  style="margin: 150px 0;">
                    <div class="fw-bold fs-3">Usuarios</div>
                    <div class="mt-2">Espere por favor ...</div>
                    <div class="spinner-border friColor1 mt-5" role="status">
                    <span class="visually-hidden">Cargando ...</span>
                    </div>
                </div>
            </template>

            <!-- Modal Add User -->
            <ion-modal :is-open="layout.newUser && layout.newUser.addBox" :backdrop-dismiss="false">
              <ion-content>
                  <div :class="!isMobile ? 'px-5' : ''">
                    <div class="d-flex justify-content-center align-items-center mt-5 pt-3 mb-5">
                      <div class="friUserCircle2 friBack1">
                        <template v-if="layout.newUser.first">{{ layout.newUser.first[0] }}<template v-if="layout.newUser.last">{{ layout.newUser.last[0] }}</template></template>
                        <template v-else><i class="bi bi-person-circle pt-1" style="font-size: 50px;"></i></template>
                      </div>
                    </div>
                    <div class="text-center mt-5" style="font-size: 25px;">Nuevo usuario</div>
                    <div class="fs-5 text-center mx-5"> {{ layout.app.name }} </div>
                    <div class="fs-3 text-center pt-0 mt-5">
                      <ion-list class="mt-0 pt-0 mx-5">
                          <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                              <ion-label position="floating" class="friColor1 fs-6">Nombre *</ion-label>
                              <ion-input type="text" v-model="layout.newUser.first" autocomplete="off"></ion-input>
                          </ion-item>
                          <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                              <ion-label position="floating" class="friColor1 fs-6">Apellidos *</ion-label>
                              <ion-input type="text" v-model="layout.newUser.last" autocomplete="off"></ion-input>
                          </ion-item>
                          <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                              <ion-label position="floating" class="friColor1 fs-6'">Email *</ion-label>
                              <ion-input type="text" v-model="layout.newUser.email" autocomplete="off"></ion-input>
                          </ion-item>
                          <div class="text-left fs-6 pt-5 mx-4">El usuario recibirá un correo electrónico con las credenciales de acceso.</div>
                      </ion-list>
                    </div>
                    <div class="mt-5 pt-5">
                        <div class="d-flex justify-content-center">
                          <button class="button px-5 py-2 m-0 mt-0 bg-light" @click="layout.newUser.addBox = false">Cancelar</button>
                          <button class="button px-5 py-2 ms-3 fw-bold friBack1 text-white outline-none" @click="saveUser()">Crear</button>
                        </div>
                    </div>
                  </div>
              </ion-content>
            </ion-modal>

        </div>
    </div>
  </template>
  <script>
  import { defineComponent, onMounted, ref } from "vue"; 
  import { useAuthStore } from "@/store";
  import { helper as $h } from "../../../utils/helper.js";
  import { getApp, getAppUsers } from "../../../model/apps.js"; 
  import { add } from 'ionicons/icons';
  import { IonItem, IonLabel, IonInput, IonList, IonModal } from '@ionic/vue'; 
  import { not as $not } from "../../../model/not";
  import { createUserAuth, createUser, registerEmail } from "../../../model/api.js";


  export default defineComponent({
    name: 'DeviceNot',
    props: {
        isMobile: Boolean,
        appId:    String,
        deviceId: String
     },
    components: { IonItem, IonLabel, IonInput, IonList, IonModal },
    setup(props) {
  
       const authStore      = useAuthStore();
       const layout = ref({ 
                            appId:    null,
                            deviceId: null,
                            device:   null,
                            newUser:  null,
                            helper:   $h
                          }) 

       const { user, profile } = authStore;

       const initNewUser = () => {
          layout.value.newUser = {
            first:         "",
            last:          "",
            active:        true,
            deleted:       false,
            email:         null,
            password:      $h.generateRandomPassword(8),
            role:          "user",
            addBox:        false,
            notify:        {
              email:      "",
              sms:        "",
              whatsapp:   "",
            },
            apps:          [props.appId],
          }
       }
       
       const saveUser = async () => {
          let validate = true
          let required = ['first', 'last', 'email']
          required.forEach((field) => {  if(!layout.value.newUser[field])validate = false })
          if(validate){
            let createClientResp  = await createUserAuth({ email: layout.value.newUser.email, password: layout.value.newUser.password });
            layout.value.loading  = false;
            if(createClientResp && createClientResp.status == "success") {
              layout.value.newUser.uid = createClientResp.uid;
              layout.value.newUser.notify.email = layout.value.newUser.email;
              let client = await createUser(layout.value.newUser);
              if(client && client.status == "success") {
                await registerEmail({ userId: createClientResp.uid }).then(() => { 
                  setTimeout(async () => { 
                    await initUsers()
                    $not.toastApp({ message: 'Usuario creado correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'primary', cssClass: 'text-white mt-2' })
                  }, 1000);
                });
              }else $not.toastApp({ message: 'Error al crear el usuario', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })        
            }else{
              console.log(createClientResp)
              let errorTypeCreate = createClientResp.error ? createClientResp.error : "Error al crear el usuario"
              if(errorTypeCreate == "auth/email-already-in-use")errorTypeCreate = "El email ya está en uso"
              $not.toastApp({ message: errorTypeCreate, position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
            }     
          }else $not.toastApp({ message: 'Debe rellenar los parámetros requeridos', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
        }

        const initUsers = async () => {
          initNewUser();
          layout.value.appId      = props.appId
          layout.value.deviceId   = props.deviceId
          layout.value.app        = await getApp(props.appId)
          layout.value.app.users  = await getAppUsers(props.appId)
        }
      
        onMounted( async () => { 
          await initUsers();
        });
  
      return { layout, user, profile, add, initNewUser, saveUser };
    }
    
  })
  </script>