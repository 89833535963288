import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47d1c072"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "pfCreateAccountPage" }
const _hoisted_2 = {
  class: "login-form",
  autocomplete: "off"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", _hoisted_2, [
      _createVNode(_component_ion_item, { lines: "full" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, { position: "floating" }, {
            default: _withCtx(() => [
              _createTextVNode("First Name")
            ]),
            _: 1
          }),
          _createVNode(_component_ion_input, {
            modelValue: _ctx.first,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.first) = $event)),
            type: "text",
            id: "first",
            name: "first",
            autocomplete: "off"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item, { lines: "full" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, { position: "floating" }, {
            default: _withCtx(() => [
              _createTextVNode("Last Name")
            ]),
            _: 1
          }),
          _createVNode(_component_ion_input, {
            modelValue: _ctx.last,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.last) = $event)),
            type: "text",
            id: "last",
            name: "last",
            autocomplete: "off"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item, { lines: "full" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, { position: "floating" }, {
            default: _withCtx(() => [
              _createTextVNode("Email")
            ]),
            _: 1
          }),
          _createVNode(_component_ion_input, {
            modelValue: _ctx.email,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event)),
            type: "email"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item, { lines: "full" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, { position: "floating" }, {
            default: _withCtx(() => [
              _createTextVNode("Password")
            ]),
            _: 1
          }),
          _createVNode(_component_ion_input, {
            modelValue: _ctx.password,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.password) = $event)),
            type: "password",
            autocomplete: "new-password"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_button, {
        expand: "block",
        onClick: _ctx.doCreateAccount,
        class: "createBtn"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Create Account")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_ion_label, {
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.router.replace('/login'))),
        class: "linkBtn"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Sign In")
        ]),
        _: 1
      })
    ])
  ]))
}