
  import { defineComponent, onMounted, ref } from "vue"; 
  import { useAuthStore } from "@/store";
  import { useRoute } from 'vue-router'
  import router from "@/router";

  import ControllerDash from '@/components/pages/controller/dashboard.vue';

  export default defineComponent({
    name: 'ControllerPage',
    props: {
        isMobile: Boolean,
     },
    components: { ControllerDash },
    setup() {
  
       const authStore      = useAuthStore();
       const route          = useRoute()
       const layout         = ref({ 
                                controllerId: route.params.controllerId,
                                section:  'dashboard',
                                menu: {
                                        dashboard:      { icon: 'bi bi-hdd',                name: 'Controlador',      path: '' },
                                        settings:       { icon: 'bi bi-sliders',                name: 'Configuración',    path: '/settings' },
                                        log:            { icon: 'bi bi-braces',                name: 'Log',        path: '/log' },
                                        notifications:  { icon: 'bi bi-bell',                name: 'Notificaciones',   path: '/notifications' },
                                        restart:        { icon: 'bi bi-arrow-counterclockwise',                name: 'Reiniciar',        path: '/restart' },
                                        shutdown:       { icon: 'bi bi-power',                name: 'Apagar',           path: '/shutdown' },
                                      }
                            }) 

       const { user, profile } = authStore;

       const toSection = async (section) => {
            layout.value.section = section
            let sectionPath = section == 'dashboard' ? '' : section
            router.push('/controller/'+layout.value.controllerId+'/'+sectionPath);
       }
        
       onMounted( async () => {
        layout.value.section = router.currentRoute.value.path.toString().split('/').pop() && router.currentRoute.value.path.toString().split('/').pop()!=layout.value.controllerId ? router.currentRoute.value.path.toString().split('/').pop() : 'dashboard'
       });
  
      return { layout, user, profile, toSection};
    }
    
  })
