<template>
    <div class="row w-100">
        <div class="friLoadBox" v-if="layout.loading">
            <div class="text-center fw-bold" style="margin-top: 59%;">
                Cargando registros. Espere por favor ...
            </div>
        </div>
        <div v-else class="text-left" style="position: fixed; top: 14px; left: 0;">
          <div class="row">
            <div class="inline-block" style="width: 68%;">
              <div v-if="layout.filterLog" class="ms-1">
                <template v-if="layout.filterLog?.response?.result">
                  <div class="fs-2 fw-bold text-left" style="line-height: 20px;">
                    <i class="bi bi-calendar2-week" style="font-size: 20px;"></i> {{ layout.filerData?.title }} 
                    <div class="fw-bold fs-6 mt-2 p-0 m-0 mb-1" style="color: #999;">
                      {{ layout.filerData.dates }} <span v-if="layout.filerData.range">a {{ layout.currentTime.split(',')[0] }} </span>
                    </div>
                    <div v-if="layout.filterLog?.response?.result?.stats && layout.filterLog?.response?.result?.stats[layout.sondaId]" style="padding: 0 !important; margin: 0 !important;line-height: 6px;color: #999;">
                      <span class="fs-6">{{ layout.filterLog?.response?.result?.stats[layout.sondaId].numLogs }} registros</span>
                      <!--<span class="fs-6 ms-1">- Intervalo: {{ layout.filterLog?.response?.result?.stats[layout.sondaId].interval.resume }}</span>-->
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div id="friChartLogRef" style="width: 100%; height: 50vh; "></div>
                    <div v-if="layout.filterLog?.response?.result?.stats && layout.filterLog?.response?.result?.stats[layout.sondaId]">
                      <div class="inline-block align-top py-1 px-3" style="width: 24%; border: 1px solid #ccc; background-color: #fff; border-radius: 8px;">
                        Mínima <span class="fw-bold fs-3 text-center">{{ layout.filterLog?.response?.result?.stats[layout.sondaId]?.minima }}<span class="align-top">&deg;</span></span>
                      </div>
                      <div class="inline-block align-top py-1 ms-1 px-3" style="width: 24%; border: 1px solid #ccc; background-color: #fff; border-radius: 8px;">
                        Media <div class="fw-bold fs-3 text-center">{{ layout.filterLog?.response?.result?.stats[layout.sondaId]?.media }}<span class="align-top">&deg;</span></div>
                      </div>
                      <div class="inline-block align-top py-1 ms-1 px-3" style="width: 24%; border: 1px solid #ccc; background-color: #fff; border-radius: 8px;">
                        Máxima <span class="fw-bold fs-3 text-center">{{ layout.filterLog?.response?.result?.stats[layout.sondaId]?.maxima }}<span class="align-top">&deg;</span></span>
                      </div>
                    </div>
                    <!--
                      v-if="layout.filterLog?.response?.result?.stats && layout.filterLog?.response?.result?.stats[layout.sondaId]"
                      
                      <pre>{{ layout.filterLog?.response?.result?.stats[layout.sondaId] }}</pre> 
                    <div>
                      <div class="inline-block align-top py-3 ms-1 px-3" style="width: 38%; border: 1px solid #ccc; background-color: #fff; border-radius: 8px;">
                        Mínima <div class="fw-bold fs-1 text-center">{{ layout.filterLog?.response?.result?.stats[layout.sondaId]?.minima }}</div>
                      </div>
                      <div class="inline-block align-top py-3 ms-1 px-3" style="width: 38%;border: 1px solid #ccc; background-color: #fff; border-radius: 8px;">
                        Máxima <div class="fw-bold fs-1 text-center">{{ layout.filterLog?.response?.result?.stats[layout.sondaId]?.maxima }}</div>
                      </div>
                      <div class="inline-block text-left align-top mt-2 py-3 ms-1 px-3" style="width: 84%; border: 1px solid #ccc; background-color: #fff; border-radius: 8px;">
                        Media <span class="float-right">{{ layout.filterLog?.response?.result?.stats[layout.sondaId]?.numLogs }} <i class="bi bi-bar-chart-steps"></i></span>
                        <div class="fw-bold text-center" style="font-size: 35px; padding: 0 !important; margin: 0 !important;">{{ layout.filterLog?.response?.result?.stats[layout.sondaId]?.media }}</div>
                      </div>
                    </div>
                    -->
                  </div>
                </template>
              </div>
              <div v-else class="text-left mt-5 ms-5"><img src="../../../assets/images/theme/loading.gif" style="width: 120px; margin-top: 60px;"></div>
            </div>
            <div class="inline-block text-left" style="width: 24%; padding: 0 !important; margin-top: 35px;">
              <div class="friBack1 py-2 ps-3 text-white mb-1 friBtnRightDash" v-for="item in layout.filterTypes" :key="item.id" :class="layout.filter==item.id ? 'fw-bold fs-5': ''" @click="filter(item.id)">{{ item.title }}</div>
              <div class="friBack4 py-3 px-5 text-dark mb-1 friBtnRightDash" @click="menuAction('menu')">Volver</div>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, ref, onMounted, onUnmounted } from 'vue';
import { useAuthStore } from '@/store';
import { requestDevice, getController } from "../../../model/api.js";
import { Chart, LineController, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(LineController, CategoryScale, LinearScale, PointElement, LineElement, annotationPlugin);

export default defineComponent({
name: 'DashLogsComponent',
props: {
  isMobile: Boolean,
},
components: {   },
setup() {
    const authStore         = useAuthStore();
    const { user, profile } = authStore;
    const layout            = ref({ 
                                deviceId:    false, //"dJlAMIdBf9yzvklaX7Q1", //"cuUvFZwvgV8H93PBaUV4",
                                sondaId:     false, //"W8emCGBK6ObtkWDnNLlI", //"7thzou1UYJmcuSUrnM8E",
                                isMobile:    false,
                                loading:     true,
                                currentTime: new Date().toLocaleString(),
                                filter:      "day",
                                filterLog:   false,
                                filterTypes: [
                                              { id: 'day',        title: 'Hoy',         range: false, dates: new Date().toLocaleString().split(',')[0]  },
                                              //{ id: 'yesterday',  title: 'Ayer',        range: false, dates: new Date(new Date().setDate(new Date().getDate()-1)).toLocaleString().split(',')[0]  },
                                              //{ id: 'week',       title: 'Semana',      range: true,  dates: new Date(new Date().setDate(new Date().getDate()-7)).toLocaleString().split(',')[0]  },
                                              //{ id: 'biweekly',   title: 'Quincena',    range: true,  dates: new Date(new Date().setDate(new Date().getDate()-15)).toLocaleString().split(',')[0] },
                                              //{ id: 'month',      title: 'Mes',         range: true,  dates: new Date(new Date().setDate(new Date().getDate()-30)).toLocaleString().split(',')[0] },
                                            ],
                              });
  
  function padTwoDigits(number) {
    return String(number).padStart(2, '0');
  }

  const filter = async (type) => {
    layout.value.filter    = type;
    layout.value.filerData = layout.value.filterTypes.filter(item => item.id === type)[0];
    layout.value.filterLog = false;

    let filDate = layout.value.filerData.dates.replace(/\//g, '-');
    let parts = filDate.split('-');
    let formattedDate = `${parts[2]}-${padTwoDigits(parts[1])}-${padTwoDigits(parts[0])}`; 
    
    if (layout.value.filerData.range) {
      let partsToday = layout.value.currentTime.split(',')[0].replace(/\//g, '-').split('-');
      formattedDate += `--${partsToday[2]}-${padTwoDigits(partsToday[1])}-${padTwoDigits(partsToday[0])}`;
    }

    layout.value.filterLog = await requestDevice(`https://${profile.controllerId}.fricontrolalba.com`, `device-actions/${layout.value.deviceId}/log:${formattedDate}`);
    layout.value.loading = false;
    setTimeout(async () => {
      await initChart();
    }, 10000);
  }

  const initChart = async () => {
    if(document.getElementById("friChartLogRef")){
      document.getElementById("friChartLogRef").innerHTML = "";
      const canvasElement = document.createElement('canvas');
      document.getElementById("friChartLogRef").appendChild(canvasElement);
      const ctx = canvasElement.getContext('2d');
      const logs = layout.value.filterLog?.response?.result?.log[layout.value.sondaId];

      if(logs){
          // Extraer datos
          const labels = [];
          const data = [];
          
          for (const [date, timings] of Object.entries(logs)) {
              for (const [time, temperature] of Object.entries(timings)) {
                  labels.push(`${date} ${time}`);
                  data.push(parseFloat(temperature));
              }
          }
          
          const minima = layout.value.filterLog?.response?.result?.stats[layout.value.sondaId].minima;
          const maxima = layout.value.filterLog?.response?.result?.stats[layout.value.sondaId].maxima;
          const media = layout.value.filterLog?.response?.result?.stats[layout.value.sondaId].media;

          console.log(minima, maxima, media);
          new Chart(ctx, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [{
                    label: 'Temperatura',
                    data: data,
                    fill: true,
                    borderColor: 'blue',
                    borderWidth: 1,
                    tension: 0.3,
                    pointRadius: 0
                }]
            },
            options: {
                scales: {
                    y: {
                      display: false
                    },
                    x: {
                        display: false
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    /*annotation: {
                        annotations: [
                            {
                                type: 'line',
                                yMin: maxima,
                                yMax: maxima,
                                borderColor: 'red',
                                borderWidth: 2,
                                label: {
                                    enabled: false
                                }
                            },
                            {
                                type: 'line',
                                yMin: minima,
                                yMax: minima,
                                borderColor: '#ffbf00',
                                borderWidth: 2,
                                label: {
                                    enabled: false
                                }
                            },
                            {
                                type: 'line',
                                yMin: media,
                                yMax: media,
                                borderColor: 'yellow',
                                borderWidth: 2,
                                label: {
                                    enabled: false
                                }
                            }
                        ]
                    }*/
                }
            }
          });

          
      }
    }
  }

  const loadDevices = async () => { 
      layout.value.controller = await getController(profile.controllerId); 
      if(layout.value.controller && layout.value.controller.devices?.devices) {
          layout.value.ControllerDevices = layout.value.controller.devices.devices;
          for (let deviceId in layout.value.ControllerDevices) {
              if(!layout.value.deviceId){
                let device            = layout.value.ControllerDevices[deviceId];
                layout.value.deviceId = deviceId
                layout.value.sondaId  = device.dash.sondaId
              }
          }
      }
  }

  onMounted(async () => {
    await loadDevices();
    await filter(layout.value.filter);
    if(document.getElementById("friTopMenuNavLogo"))
      document.getElementById("friTopMenuNavLogo").classList.add("hidden");
  });

  onUnmounted(() => {
    if(document.getElementById("friTopMenuNavLogo"))
      document.getElementById("friTopMenuNavLogo").classList.remove("hidden");
  });

  return {user, profile, layout, filter };
},
methods: {
    menuAction(id) {
        this.$emit('menuAction', id); 
    },
},
});
</script>

<style>
.friLoadBox{
    position: fixed;
    top: 0;
    left: 0;
    background: url("~@/assets/images/back/back5.png") no-repeat center center fixed; width: 100%; height: 100vh; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;
    z-index: 99;
}
</style>