
  import { defineComponent, onMounted, onBeforeUnmount, ref, watch } from "vue"; 
  import { _firebase as $_firebase } from "../../model/firebase";
  import { getGateway } from "../../model/api.js";
  import { useAuthStore } from "@/store";
  import { IonItem, IonLabel, IonInput, IonList, IonCol, IonGrid, IonRow, IonHeader, IonModal, IonContent, IonToolbar, IonTitle, IonButton, IonButtons, alertController } from '@ionic/vue'; //, IonSegment, IonSegmentButton
  import { helper as $h } from "../../utils/helper.js";
  import { onSnapshot, doc, updateDoc  } from "firebase/firestore"; 
  import router from "@/router";
  import { add, saveOutline } from 'ionicons/icons';
  import { useRoute } from 'vue-router'
  import { not as $not } from "../../model/not";

  const db =  $_firebase.firestore() 

  export default defineComponent({
    name: 'HubPage',
    props: {
        isMobile: Boolean,
        gatewayId: String
     },
    components: { IonItem, IonLabel, IonInput, IonList, IonCol, IonGrid, IonRow, IonHeader, IonModal, IonContent, IonToolbar, IonTitle, IonButton, IonButtons  }, //, IonSegment, IonSegmentButton
    setup(props) {
  
       const authStore      = useAuthStore();
       const route          = useRoute()
       const layout         = ref({ 
                                section:       null,
                                gatewayId:     null,
                                gateway:       null,
                                currentTime:   new Date().toLocaleTimeString(),
                                timerInterval: null,
                                unsubscribeCheckSensors: null,
                                helper:        $h,
                                query:         null,
                                alarm:         null
                            }) 

       const { user, profile } = authStore;
       const unsubscribeCheckSensors = ref(null);                     
        
       const checkSensors = () => {
            if (unsubscribeCheckSensors.value)unsubscribeCheckSensors.value(); 
            //alert("Initializing check Lora sensors..." + layout.value.section);
            const documentPath = `gateway/${layout.value.gatewayId}`;
            const docRef       = doc(db, documentPath);
            layout.value.unsubscribeCheckSensors = onSnapshot(docRef, async (docSnapshot) => {
                if (docSnapshot.exists()) {
                    layout.value.gateway = await getGateway(layout.value.gatewayId)
                    layout.value.gateway.id = docSnapshot.id;
                    console.log("Document data:", docSnapshot.data());
                } else {
                    console.log("Document not found");
                }
            }, (error) => {
                console.log("Error sensors status:", error);
            });
        };

       const saveDevice = async () => {
            const alert = await alertController.create({
                header: '¿Está seguro?',
                cssClass: 'friCustomAlert',
                buttons: [ 
                           {text: 'Cancelar', cssClass: 'alert-button-cancel', role: 'cancel' },  
                           {text: 'Confirmar',cssClass: 'alert-button-confirm', handler: () => { 
                                const gatewayDocRef = doc(db, "gateway", layout.value.gatewayId);
                                updateDoc(gatewayDocRef, {
                                        name: layout.value.gateway.name
                                    }).then(() => {
                                        $not.toastApp({ header: layout.value.gateway?.name , message: 'Se ha guardado correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, color: '' })
                                    }).catch((error) => { console.error("Error saveDevice: ", error); });
                            }
                           }
                         ],
            });
            await alert.present();
        };

       onMounted( async () => { 
         layout.value.gatewayId     = router.currentRoute.value.params.gatewayId,
         layout.value.timerInterval = setInterval(() => {  layout.value.currentTime = new Date().toLocaleTimeString();  }, 1000);
         layout.value.gateway       = await getGateway(layout.value.gatewayId)
         if(route.query){ layout.value.query   = layout.value.section = route.query.section;
         }else if (layout.value.gateway){ layout.value.section = 'dashboard'; }
         if(!layout.value.section)layout.value.section = 'dashboard';
         if (layout.value.section === 'dashboard')checkSensors();
         //alertLocal();
       });

        watch(() => route.query, (query) => {
            if (query){
                layout.value.query   = query; 
                layout.value.section = query.section;
            }
        });

        onBeforeUnmount(() => {
            if (unsubscribeCheckSensors.value)unsubscribeCheckSensors.value();
            clearInterval(layout.value.timerInterval );
            //alert("Unmounted hub dash");
        });


      return { layout, user, props, profile, add, saveOutline, saveDevice};
    }
    
  })
