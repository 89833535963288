<template>
    <div>
      <div class="column is-12" v-if="!layout.deviceId">
        <div class="text-center mt-5">
          <img src="../../assets/images/theme/loading.gif" style="width: 150px">
          <div>Adquiriendo datos del dispositivo<br /> espere por favor ...</div>
          <div :class="isMobile ? 'mt-3' : 'mt-5'">
            <LoadingAbout :isMobile="layout.isMobile" />
          </div>
        </div>
      </div>
      <div v-else>
        <template v-if="$router.currentRoute.value.path == '/remote/'"><Control :isMobile="isMobile" :deviceId="layout.deviceId" /></template>
        <template v-if="$router.currentRoute.value.path == '/remote/actions'">
          ACTIONS
        </template>
        <template v-if="$router.currentRoute.value.path == '/remote/energy'">
          ENERGY
        </template>
      </div>
      <div id="friPopMsgBox" class="hidden"><div id="friPopMsg"></div></div>
    </div>
</template>

<script>
import { defineComponent, ref, onMounted, onUnmounted } from 'vue';
import { useAuthStore } from '@/store';
import { getDevice } from "../../model/alba.js";
import { onBeforeRouteLeave, onBeforeRouteUpdate } from "vue-router";

import Control from '@/components/pages/remote/control.vue';
import LoadingAbout from '@/components/pages/loading/about.vue';


export default defineComponent({
name: 'RemotePage',
props: {
  isMobile: Boolean,
  deviceId: String
},
components: {  Control, LoadingAbout },
setup(props) {
    const authStore         = useAuthStore();
    const { user, profile } = authStore;
    const layout            = ref({ 
                                loading:   true,
                                led:       false,
                                ledStatus: false,
                                intervalStatus: false,
                                intervalMax:    0,
                                deviceId: props.deviceId ? props.deviceId : "FEI9eQt3Su15P1g2ldk9",
                                connect: {
                                  device:  false,
                                  devices: false,
                                } 
                            });
   
  const loadSection = async () => {
    layout.value.loading = true
    layout.value.connect.device = false
    layout.value.connect.device = await getDevice(layout.value.deviceId)
  }  

  
  onMounted(async () => {
    await loadSection()
  });

  onUnmounted(async() => {
    //if(layout.value.intervalStatus)clearInterval(layout.value.intervalStatus)
    //await closeSession()
  });

  onBeforeRouteLeave(async () => {
    //if(layout.value.intervalStatus)clearInterval(layout.value.intervalStatus)
    //await closeSession()
  });

  onBeforeRouteUpdate(() => {
    //console.log('Componente visitado:', router.currentRoute.value.path);
   // Otra lógica a ejecutar cuando el componente se visita
  });

  return {user, profile, layout, loadSection};
},
methods: {},
});
</script>
<style>
#friPopMsgBox{ position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color: rgba(255,255,255,0.5); z-index: 99;}
#friPopMsg{ display: block; position: fixed; right: calc(50% - 175px); top: calc(40% - 25px); width: 350px; height: 50px;  background-color: #009292; color: #fff; text-align: center; padding-top: 30px; font-weight: bold; z-index: 9999; border-radius: 8px;}
#friReloadMsgBox{ position: fixed; left: 0; top: 50px; width: 100%; height: 100%; background-color: rgba(255,255,255,0.7); z-index: 99;}
#friReloadMsg{ display: block; position: fixed; right: calc(50% - 175px); top: calc(40% - 25px); width: 350px; height: 65px;  background-color: #009292; color: #fff; text-align: center; padding-top: 30px; font-weight: bold; z-index: 9999; border-radius: 8px;}
</style>