<template>
    <div>
        <!-- Controller dashboard -->
        <div class="account-box is-form is-footerless" :style="isMobile ? 'border-bottom: none !important;' : ''">
            <div class="form-head stuck-header" :class="isMobile ? 'py-0' : 'py-3'" v-if="layout.controller">
                <div class="form-head-inner">
                    <div class="left">
                        <h4 :class="isMobile ? 'fs-4 my-3' : 'fs-2'">{{ layout.controller.name.toString().substring(0,20) }}<span v-if="layout.controller.name.length > 20">...</span><span v-if="!isMobile" class="text-sm text-gray ps-1">/ Controlador</span></h4>
                    </div>
                    <div v-if="layout.controller" class="right" :class="isMobile ? '' : 'mb-2'">
                        <div :class="isMobile ? 'pe-4' : 'pt-3'">
                            <template v-if="layout.controller.active">
                                <div v-if="layout.controller.ping" class="text-sm text-success fs-6"><i class="bi bi-plugin fs-5 align-middle pe-1"></i> Encendido</div>
                                <div v-else class="text-sm text-danger fs-6"><i class="bi bi-plugin fs-6 align-middle pe-1"></i> Apagado</div>
                            </template>
                            <template v-else><div class="text-sm text-danger fs-5"><i class="bi bi-x-square-fill fs-6 align-middle" :class="isMobile ? '' : 'pe-1'"></i> Suspendido</div></template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-body pb-0 mb-0" :class="isMobile ? 'pt-0' : 'p-0'">
                <pre v-if="layout.controller">
                    {{ layout.controller }}
                </pre>
                <template v-if="layout.controller && layout.controller.active">
                    <div v-if="layout.controller.status">
                        <div v-if="layout.controller.data.micro_status" class="text-center">
                            <div class="p-0 m-0 mb-0 w-100 mx-auto text-sm" v-if="layout.controller.data.micro_status==4883"><img src="../../../assets/images/theme/loading.gif" style="width: 50px"> En proceso de desescarche manual</div>
                        </div>
                        <div class="row mx-4 mb-2" :class="isMobile ? 'mt-3' : ''">
                            <div class="text-left mt-2 mx-0 mb-1" v-if="layout.controller.data"  :style="isMobile ? '' : 'padding-left: 20px !important'" >
                                <div v-for="(s, index) in layout.controller.data.sondas" :key="index" class="inline-block bg-light py-5 px-0 me-2 my-0 br-5 mb-2" style="width: 47%;"
                                    :style="!layout.controller.sondas[index] || !layout.controller.sondas[index].active || (layout.controller.sondas[index] && !layout.controller.sondas[index].active)? 'display: none !important' : index> 2 ? '' : ''">
                                    <div v-if="layout.controller.sondas[index] && layout.controller.sondas[index].active">
                                    <div class="align-top">
                                        <div class="text-center">
                                            <div class="fs-3 text-gray-dark">{{ layout.controller.data.sondas[index] % 1 != 0 ? layout.controller.data.sondas[index] : layout.controller.data.sondas[index] / 10 }} {{ layout.controller.data.unit }}</div>
                                            <span class="text-sm">{{layout.controller.sondas[index].name }}</span>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="column is-12 text-center"><div class="text-sm">Registros obtenidos el {{ new Date().toLocaleString().replace(',', '') }}</div></div>
                        </div>
                        <div class="px-3">
                            <h1 class="text-center fs-6 mb-4 mt-3">Historial de registros</h1>
                            <div class="text-sm">Registro continuo:<span class="friColor1 mt-2 mx-auto ps-1" v-if="layout.controller.data.config?.cron_active">Activo</span><span class="friColor3 mt-2 mx-auto ps-1" v-else>Inactivo</span></div>
                            <div class="text-sm">Servicio:<span class="friColor1 mt-2 mx-auto ps-1" v-if="layout.services && layout.services['cron.py']">Online</span><span class="friColor3 mt-2 mx-auto ps-1" v-else>Offline</span></div>
                        </div>
                    </div>
                </template>
                <template v-else>
                  <div class="text-center" :style="isMobile ? 'padding-bottom: 50%' : 'padding-bottom: 30px'">
                      <img src="../../../assets/images/theme/loading.gif" style="width: 150px;">
                      <div class="text-sm"><span class="fw-semibold">Adquiriendo información</span><br /> Espere por favor ...</div>
                      <div :class="isMobile ? 'mt-3' : 'mt-5'">
                        <LoadingAbout :isMobile="layout.isMobile" />
                      </div>
                  </div>
                </template>
            </div>
        </div>
    </div>
  </template>
  <script>
  import { defineComponent, onMounted, ref } from "vue"; 
  import { useAuthStore } from "@/store";
  import { getController } from "../../../model/api.js";
  import { helper as $h } from "../../../utils/helper.js";
  
  export default defineComponent({
    name: 'ControllerDash',
    props: {
        isMobile: Boolean,
        controllerId: String
     },
     components: { },
    setup(props) {
  
       const authStore      = useAuthStore();
       const layout = ref({ 
                            controllerId:   null,
                            controller:     null,
                            services:       null,
                            helper:         $h
                          }) 

       const { user, profile } = authStore;

       onMounted( async () => { 
         layout.value.controllerId  = props.controllerId
         layout.value.controller    = await getController(layout.value.controllerId)
       });
  
      return { layout, user, profile};
    }
    
  })
  </script>