<template>
    <div class="account-box is-form is-footerless px-0 mx-0" :style="isMobile ? 'border-bottom: none !important;' : ''">
        <template v-if="layout.app">
            <div class="form-head stuck-header" :class="isMobile ? 'py-0' : 'py-3'">
                <div class="form-head-inner">
                    <div class="left"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5 ms-2' : 'fs-4'">{{  layout.app.name  }} </h4></div>
                    <div class="inline-block align-top fs-6 mt-0" style="margin-top: 4px;">
                        <template v-if="layout.app.logo"><img :src="layout.app.logo" style="height: 50px;" /></template>
                        <template v-else>{{  layout.app.id }}</template>
                    </div>
                </div>
            </div>
            <div class="pt-2 pb-2 fs-5 friBack1 text-white text-center ps-3"><span class="align-middle" v-if="layout.device && layout.device.name">{{ layout.device.name }}</span></div>
            <div class="pt-2 pb-2 fs-6 friBack4 text-center ps-3" ><span class="align-middle" v-if="layout.device && layout.device.typeName">{{ layout.device.typeName }}</span></div>


            <div class="px-3 pt-3">
                <div class="text-center fs-1 mt-3">Notificaciones</div>
                <div v-if="layout.alarm" class="text-center fs-6 fw-bold">{{ layout.alarm.name }}</div>
                <div class="text-center mt-2 mb-3 mx-5">Administre los usuarios que tienen recibirán notificaciones de alarma.</div>
                <div class="d-flex justify-content-center align-items-center fs-5 my-4" v-if="layout.appId && layout.app.users && layout.app.users.users && Object.keys(layout.app.users.users).length < layout.app.limits.users">
                    <div class="friUserCircle me-2 friBack4 cursor-pointer" @click="layout.addBox = true;"><i class="bi bi-plus-lg text-dark"></i></div>
                </div>
            </div>

            <div class="text-center" v-if="layout.alarm">
                <div class="mx-4">
                    <div class="text-left friBackWhite" style="padding: 0 0 10px 0;">
                        <div v-if="layout.alarm?.users && layout.alarm?.users.length" class="fs-5 friBackWhite text-left">
                            <ion-list class="mx-0 px-0">
                                <ion-item detail v-for="(user, userId) in layout.alarm.users" :key="userId" style="--ion-item-border-color: #e4e3e3;" class="cursor-pointer" 
                                    @click="layout.editBox = true; layout.notify = user" >
                                    <ion-avatar slot="start" style="width: 60px; height: 50px;" class="text-center"><div class="friUserCircle friBack1" v-if="user.user?.first">{{ user.user.first[0] }}{{ user.user.last[0] }}</div></ion-avatar>
                                    <ion-label v-if="user.user">
                                        <h2 v-if="user.user && user.user.first" class="fs-5">{{ user.user.first.charAt(0).toUpperCase() + user.user.first.slice(1) }} {{ user.user.last.substr(0, 20) }}{{ user.user.last.length > 20 ? '...' : '' }}</h2>
                                        <div v-if="user?.isClient" class="text-xs">Cliente</div>
                                        <div v-else class="text-xs">Usuario</div>
                                        <!--
                                        <div>
                                            <span v-if="user.notify?.whatsapp" class="pe-2"><i class="bi bi-whatsapp align-middle friOpacity8" style="font-size: 15px;"></i></span>
                                            <span v-if="user.notify?.email" class="pe-2"><i class="bi bi-envelope align-middle friOpacity8" style="font-size: 17px;"></i></span>
                                            <span v-if="user.notify?.sms" class="pe-2" style="font-size: 12px;">SMS</span>
                                        </div>
                                        -->
                                    </ion-label>
                                </ion-item>
                            </ion-list>
                        </div>
                        <div v-else class="text-center mt-5">No dispone de notificaciones programadas</div>
                    </div>
                    
                </div>
                
            </div>

            <!-- Modal Add User Notify -->
            <ion-modal :is-open="layout.newNotify && layout.addBox" ref="modal" :initial-breakpoint="0.85" :breakpoints="[0.5, 0.85, 1]" :backdrop-dismiss="false">
                <ion-content>
                    <template v-if="layout.newNotify && !layout.newNotify?.loading">
                        <div class="text-left px-4 pt-3 mx-5 mt-5" v-if="!isMobile">
                            <div class="fw-bold fs-3 mb-1">Notificación de alarma</div>
                            <div class="fw-bold">Nueva notificación</div>
                            <div class="mt-1">Se notificará la alarma al usuario seleccionado por las vías de comunicación que seleccione.</div>
                        </div>
                        <div class="text-center pt-5 px-5 mt-5" v-else>
                            <div class="fw-bold fs-1" style="font-size: 35px; line-height: 30px;">{{ layout.alarm?.name }}</div>
                            <div class="mt-2">Seleccione el destinatario</div>
                            <!-- <div class="mt-2 mx-5">Se notificará la alarma al usuario seleccionado por las vías de comunicación que seleccione.</div> -->
                        </div>
                        <div class="fs-3 text-center px-5 ">
                            <ion-list class="m-0 p-0">

                                <!--
                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                    <ion-label position="floating" class="friColor1 pt-0 mt-0" :class="isMobile ? 'fs-4' : ' fs-5'">Alarma *</ion-label>
                                    <ion-select interface="popover" v-model="layout.newNotify.alarmId" class="pfInputValue" :disabled="true">
                                        <ion-select-option :value="layout.alarm.id">{{ layout.alarm?.name }}</ion-select-option>
                                    </ion-select>
                                </ion-item>
                                -->
                                
                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;" v-if="layout.addUsers">
                                    <ion-label position="floating" class="friColor1 pt-0 mt-0" :class="isMobile ? 'fs-4' : ' fs-5'">Destinatario *</ion-label>
                                    <ion-select interface="popover" v-model="layout.newNotify.user" class="pfInputValue">
                                        <ion-select-option v-for="(user, userId) in layout.addUsers.users" :key="userId" :value="user">{{ user.first }} {{ user.last }}</ion-select-option>
                                    </ion-select>
                                </ion-item>

                                <template v-if="layout.newNotify.user">
                                    <div class="friColor1 mt-2 fs-6 text-center  pt-3">Canales de comunicación</div>
                                    <ion-item class="border-none p-0 m-0" lines="none" style="--ion-item-border-color: #ccc;">
                                        <ion-label>Aplicación</ion-label>
                                        <ion-toggle :enable-on-off-labels="true" slot="end" v-model="layout.newNotify.app" :disabled="true"></ion-toggle>
                                    </ion-item>
                                    <template v-if="layout.newNotify.user?.notify">
                                        <ion-item class="border-none p-0 m-0" lines="none" style="--ion-item-border-color: #ccc;">
                                            <template v-if="layout.newNotify.user?.notify.whatsapp">
                                                <ion-label>Whatsapp<div class="text-xs3">******{{ layout.newNotify.user?.notify.whatsapp.substr(-2) }}</div></ion-label>
                                                <ion-toggle :enable-on-off-labels="true" slot="end" v-model="layout.newNotify.whatsapp"></ion-toggle>
                                            </template>
                                            <template v-else>
                                                <ion-label>Whatsapp</ion-label>
                                                <div class="text-xs">No disponible</div>
                                            </template>
                                        </ion-item>
                                        <ion-item class="border-none p-0 m-0" lines="none" style="--ion-item-border-color: #ccc;">
                                            <template v-if="layout.newNotify.user?.notify.email">
                                                <ion-label>Email<div class="text-xs3">{{ layout.newNotify.user?.notify.email }}</div></ion-label>
                                                <ion-toggle :enable-on-off-labels="true" slot="end" v-model="layout.newNotify.email"></ion-toggle>
                                            </template>
                                            <template v-else>
                                                <ion-label>Email</ion-label>
                                                <div class="text-xs">No disponible</div>
                                            </template>
                                        </ion-item>
                                        <ion-item class="border-none p-0 m-0" lines="none" style="--ion-item-border-color: #ccc;">
                                            <template v-if="layout.newNotify.user?.notify.sms">
                                                <ion-label>SMS<div class="text-xs3">******{{ layout.newNotify.user?.notify.sms.substr(-2) }}</div></ion-label>
                                                <ion-toggle :enable-on-off-labels="true" slot="end" v-model="layout.newNotify.sms"></ion-toggle>
                                            </template>
                                            <template v-else>
                                                <ion-label>SMS</ion-label>
                                                <div class="text-xs">No disponible</div>
                                            </template>
                                        </ion-item>
                                        <ion-item class="border-none p-0 m-0" lines="none" style="--ion-item-border-color: #ccc;">
                                            <ion-label>
                                                Permitir el descarte
                                                <div class="text-xs3">
                                                    Si se activa, el usuario podrá descartar la alarma desde la notificación.
                                                </div>
                                            </ion-label>
                                            <ion-toggle :enable-on-off-labels="true" slot="end" v-model="layout.newNotify.discard"></ion-toggle>
                                        </ion-item>
                                        <ion-item class="border-none p-0 m-0" lines="none" style="--ion-item-border-color: #ccc;">
                                            <div class="text-left mt-2">
                                                <ion-label>
                                                    Notas de alarma personalizadas
                                                    <div class="text-xs3">Máximo 50 caracteres</div>
                                                </ion-label>
                                            </div>
                                        </ion-item>
                                        <ion-item class="border-none p-0 mt-3" lines="none" style="--ion-item-border-color: #ccc;">
                                            <div class="friBack4 br-5 w-100"><ion-textarea v-model="layout.newNotify.notes" class="pfInputValue text-dark px-5 py-1 m-0 friBack4" maxlength="50"></ion-textarea></div>
                                        </ion-item>
                                    </template>
                                </template>

                            </ion-list>
                        </div>
                        <div class="mt-5" style="padding: 10px 0 50px 0;">
                            <div class="d-flex justify-content-center">
                                <button class="button px-5 py-2 m-0 mt-0 bg-light" @click="layout.addBox = false">Cancelar</button>
                                <template v-if="layout.newNotify.user">
                                    <button class="button px-5 py-2 ms-3 fw-bold friBack1 text-white" @click="addAlarmUser()">Crear</button>
                                </template>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="text-center"  style="margin-top: 150px; ">
                            <div class="mt-5 fs-5">Añadiedo notificación</div>
                            <div class="mt-2">Espere por favor ...</div>
                            <div class="spinner-border friColor1 mt-5" role="status">
                            <span class="visually-hidden">Cargando ...</span>
                            </div>
                        </div>
                    </template>
                </ion-content>  
            </ion-modal>

            <!-- Modal Edit User Notify -->
            <ion-modal :is-open="layout.notify && layout.editBox" ref="modal" :initial-breakpoint="0.85" :breakpoints="[0.5, 0.85, 1]" :backdrop-dismiss="false">
                <ion-content>
                    <template v-if="!layout.editLoading">
                        <div class="text-left px-4 mx-5 mt-5" v-if="!isMobile" style="padding-top: 50px">
                            <div class="fw-bold fs-3 mb-2">Notificación de alarma</div>
                            <div>Se notificará la alarma al usuario seleccionado por las vías de comunicación que seleccione.</div>
                            <div class="mt-3" v-if="layout.alarm?.name">Alarma: <span class="fw-bold">{{ layout.alarm?.name }}</span></div>
                            <div>Destinatario: <span class="fw-bold">{{ layout.notify.user.first }} {{ layout.notify.user.last }}</span></div>
                            <div class="friColor1 fs-6 pt-2" :class="isMobile ? 'text-center' : 'text-left'">Canales de comunicación</div>
                        </div>
                        <div class="text-center pt-5 px-5 mt-2" v-else>
                            <!--<div class="fw-bold" style="font-size: 20px;" v-if="layout.alarm?.name">{{ layout.alarm.name }}</div>-->
                            <div>Destinatario</div>
                            <div class="fw-bold fs-1">{{ layout.notify.user.first }} {{ layout.notify.user.last }}</div>
                            <div class="mx-5">Seleccione las vías de comunicación.</div>
                        </div>
                        <div class="fs-3 text-center px-3 mx-5">
                            <template v-if="layout.notify?.notify">

                                <ion-item class="border-none p-0 m-0" lines="none" style="--ion-item-border-color: #ccc;">
                                    <ion-label>Aplicación</ion-label>
                                    <ion-toggle :enable-on-off-labels="true" slot="end" v-model="layout.notify.notify.app" :disabled="true"></ion-toggle>
                                </ion-item>

                                <!-- Email -->
                                <template v-if="layout.app?.limits?.notify?.email">
                                    <ion-item class="border-none p-0 m-0" lines="none" style="--ion-item-border-color: #ccc;">
                                        <template v-if="layout.notify.user.notify.email">
                                            <ion-label class="m-0 p-0">Email<div class="text-xs3">{{ layout.notify.user?.notify.email }}</div></ion-label>
                                            <ion-toggle :enable-on-off-labels="true" slot="end" v-model="layout.notify.notify.email"></ion-toggle>
                                        </template>
                                        <template v-else>
                                            <ion-label>Email</ion-label>
                                            <div class="text-xs">No disponible</div>
                                        </template>
                                    </ion-item>
                                </template>

                                <!-- Whatsapp -->
                                <template v-if="layout.app?.limits?.notify?.whatsapp">
                                    <ion-item class="border-none p-0 m-0" lines="none" style="--ion-item-border-color: #ccc;">
                                        <template v-if="layout.notify.user.notify.whatsapp">
                                            <ion-label>Whatsapp<div class="text-xs3">******{{ layout.notify.user?.notify.whatsapp.substr(-2) }}</div></ion-label>
                                            <ion-toggle :enable-on-off-labels="true" slot="end" v-model="layout.notify.notify.whatsapp"></ion-toggle>
                                        </template>
                                        <template v-else>
                                            <ion-label>Whatsapp</ion-label>
                                            <div class="text-xs">No disponible</div>
                                        </template>
                                    </ion-item>
                                </template>

                                <!-- SMS -->
                                <template v-if="layout.app?.limits?.notify?.sms">
                                    <ion-item class="border-none p-0 m-0" lines="none" style="--ion-item-border-color: #ccc;">
                                        <template v-if="layout.notify.user.notify.sms">
                                            <ion-label>SMS<div class="text-xs3">******{{ layout.notify.user?.notify.sms.substr(-2) }}</div></ion-label>
                                            <ion-toggle :enable-on-off-labels="true" slot="end" v-model="layout.notify.notify.sms"></ion-toggle>
                                        </template>
                                        <template v-else>
                                            <ion-label>SMS</ion-label>
                                            <div class="text-xs">No disponible</div>
                                        </template>
                                    </ion-item>
                                </template>
                                
                                <ion-item class="border-none p-0 m-0" lines="none" style="--ion-item-border-color: #ccc;">
                                    <ion-label>
                                        Permitir el descarte
                                        <div class="text-xs3">
                                            Si se activa, el usuario podrá descartar la alarma.
                                        </div>
                                    </ion-label>
                                    <ion-toggle :enable-on-off-labels="true" slot="end" v-model="layout.notify.discard" :disabled="layout.notify.isClient ? true : false"></ion-toggle>
                                </ion-item>

                                <ion-item class="border-none p-0 m-0" lines="none" style="--ion-item-border-color: #ccc;">
                                    <div class="text-left mt-2">
                                        <ion-label>
                                            Notas de alarma personalizadas
                                            <div class="text-xs3">Máximo 50 caracteres</div>
                                        </ion-label>
                                    </div>
                                </ion-item>

                                <ion-item class="border-none p-0 mt-3" lines="none" style="--ion-item-border-color: #ccc;">
                                    <div class="friBack4 br-5 w-100"><ion-textarea v-model="layout.notify.notes" class="pfInputValue text-dark px-5 py-1 m-0 friBack4" maxlength="50"></ion-textarea></div>
                                </ion-item>

                            </template>
                            <div class="mt-5 pt-3">
                                <div class="d-flex justify-content-center">
                                    <template v-if="!layout.notify.isClient"><button class="button px-5 py-2 mt-0 bg-danger text-white" @click="removeUserNotify()">Eliminar</button></template>
                                    <button class="button px-5 py-2 mt-0 bg-light ms-3" @click="layout.editBox = false">Cancelar</button>
                                    <button class="button px-5 py-2 ms-3 fw-bold friBack1 text-white" @click="updAlarmUser()">Guardar</button>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="text-center"  style="margin-top: 150px; ">
                            <div class="mt-5 fs-2 fw-bold">Notificación</div>
                            <div class="mt-2">Espere por favor ...</div>
                            <div class="spinner-border friColor1 mt-5" role="status">
                            <span class="visually-hidden">Cargando ...</span>
                            </div>
                        </div>
                    </template>
                </ion-content>
            </ion-modal>

        </template>
        <template v-else>
            <div class="text-center w-100"  style="margin-top: 150px;">
                <div class="fw-bold fs-1">Notificaciones</div>
                <div class="mt-2">Espere por favor ...</div>
                <div class="spinner-border friColor1 mt-5" role="status">
                <span class="visually-hidden">Cargando ...</span>
                </div>
            </div>
        </template>   
    </div>
  </template>
  <script>
  import { defineComponent, onMounted, ref } from "vue"; 
  import { useAuthStore } from "@/store";
  import { getApp, getAppDevices, getAppUsers  } from "../../../model/apps.js"; 
  import { getClient } from "../../../model/api.js";
  import { helper as $h } from "../../../utils/helper.js";
  import { getAlarm, updateAlarm  } from "../../../model/alarms.js";
  import { add } from 'ionicons/icons';
  import { IonItem, IonLabel,IonList, IonSelect, IonSelectOption, IonModal, IonToggle, alertController, IonTextarea } from '@ionic/vue'; //, IonIcon
  //import { getClient } from "../../../model/api.js";
  import { not as $not } from "../../../model/not";
  import router from "@/router";

  export default defineComponent({
    name: 'DeviceDash',
    props: {
        isMobile: Boolean,
        appId:    String,
        deviceId: String,
        alarmId:  String,
     },
     components: { IonItem, IonLabel, IonList, IonSelect, IonSelectOption, IonModal, IonToggle, IonTextarea },
    setup(props) {
  
       const authStore      = useAuthStore();
       const layout = ref({ 
                            appId:      null,
                            device:     null,
                            newNotify:  null,
                            addBox:     false,
                            editBox:    false,
                            editLoading:false,
                            notify:     null,
                            helper:     $h
                          }) 

       const { user, profile } = authStore;

       const addAlarmUser = async () => {
            layout.value.newNotify.loading  = true;
            layout.value.alarm.users.push({
                                            userId:     layout.value.newNotify.user.id,
                                            discard:    layout.value.newNotify.discard,
                                            notify:     {
                                                            app:      layout.value.newNotify.app,
                                                            whatsapp: layout.value.newNotify.whatsapp,
                                                            email:    layout.value.newNotify.email,
                                                            sms:      layout.value.newNotify.sms,
                                                        }
                                          })
            await updateAlarm(layout.value.appId, layout.value.deviceId, layout.value.alarmId, { users: layout.value.alarm.users })
            layout.value.addBox = false;
            setTimeout(async () => {
                layout.value.newNotify.loading  = false;
                initNewNotify();
                await initNotify();
            }, 1000);
       }

       const updAlarmUser = async () => {
            layout.value.editLoading = true
            setTimeout(async () => {
                for (let i = 0; i < layout.value.alarm.users.length; i++) {
                    if(layout.value.alarm.users[i].userId == layout.value.notify.user.id){
                        layout.value.alarm.users[i].notify = layout.value.notify.notify
                    }
                    if(layout.value.alarm.users[i].user){ 
                        layout.value.alarm.users[i].user = {
                                                            first:  layout.value.notify.user.first,
                                                            last:   layout.value.notify.user.last,
                                                            notify: layout.value.notify.user.notify                    
                                                           }
                        if(!layout.value.notify.user.notify?.whatsapp)layout.value.alarm.users[i].notify.whatsapp = false
                        if(!layout.value.notify.user.notify?.email)layout.value.alarm.users[i].notify.email = false
                        if(!layout.value.notify.user.notify?.sms)layout.value.alarm.users[i].notify.sms = false
                    }
                }
                console.log(layout.value.alarm.users)
                await updateAlarm(layout.value.appId, layout.value.deviceId, layout.value.alarmId, { users: layout.value.alarm.users })
                layout.value.editLoading = false
                layout.value.editBox     = false
                $not.toastApp({ header: "Notificación", message: 'Se ha actualizado la notificación correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'primary', cssClass: 'text-white mt-2' })
                await initNotify();
            }, 1000);
       }

       const initNotify = async () => {

         layout.value.app           = await getApp(layout.value.appId)
         layout.value.app.users     = await getAppUsers(layout.value.appId)

         let deviceData        = await getAppDevices(layout.value.appId, layout.value.deviceId)
         if(deviceData && deviceData?.devices && Object.keys(deviceData.devices).length)layout.value.device = deviceData.devices[Object.keys(deviceData.devices)[0]]
         layout.value.alarm    = await getAlarm(layout.value.appId, layout.value.deviceId, layout.value.alarmId)
         if(layout.value.alarm.users && layout.value.alarm.users.length)
            for (let i = 0; i < layout.value.alarm.users.length; i++) { layout.value.alarm.users[i].user = await getClient(layout.value.alarm.users[i].userId) }
         layout.value.appUsers = await getAppUsers(layout.value.appId)
         layout.value.addUsers = layout.value.appUsers

         //check if exist alarm user 
        if(layout.value.alarm?.users && layout.value.alarm?.users.length){
            for (let i = 0; i < layout.value.alarm.users.length; i++) {
                for (const [key, user] of Object.entries(layout.value.addUsers.users)) {
                    if(user.id == layout.value.alarm.users[i].userId)delete layout.value.addUsers.users[key]
                }
            }
        }

       }

       const initNewNotify = () => {
            layout.value.newNotify = {
                loading:    false,
                alarmId:    props.alarmId,
                user:       null,
                app:        true,
                whatsapp:   false,
                email:      true,
                sms:        false,
                discard:    false,
            }
       }
       
       const removeUserNotify = async () => {
        const alert = await alertController.create({
            header:  "¿Está seguro?",
            cssClass: 'friCustomAlert',
            buttons: [ 
                        {text: 'Cancelar', cssClass: 'alert-button-cancel', role: 'cancel' },  
                        {text: 'Confirmar',cssClass: 'alert-button-confirm', handler: async () => { 
                            if(layout.value.alarm?.users && layout.value.alarm?.users.length){
                                for (let i = 0; i < layout.value.alarm.users.length; i++) { if(layout.value.alarm.users[i].userId == layout.value.notify.user.id)layout.value.alarm.users.splice(i, 1)}
                                await updateAlarm(layout.value.appId, layout.value.deviceId, layout.value.alarmId, { users: layout.value.alarm.users })
                                layout.value.editBox = false
                                $not.toastApp({ header: "Notificación", message: 'Se ha eliminado la notificación correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'primary', cssClass: 'text-white mt-2' })
                                await initNotify();
                            }
                        }
                        }
                      ],
        });
        await alert.present();
       }

       onMounted( async () => { 
         layout.value.appId     = props.appId
         layout.value.deviceId  = props.deviceId
         layout.value.alarmId   = props.alarmId
         if(router.currentRoute.value.params.appId)layout.value.appId = router.currentRoute.value.params.appId;
         if(router.currentRoute.value.params.deviceId)layout.value.deviceId = router.currentRoute.value.params.deviceId;
         if(router.currentRoute.value.params.alarmId)layout.value.alarmId = router.currentRoute.value.params.alarmId;
         initNewNotify();
         await initNotify();
       });

        return { layout, user, add, props, profile, addAlarmUser, updAlarmUser, removeUserNotify };
    }
    
  })
  </script>