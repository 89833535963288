<template>
   <div>
        <!-- Alarms Log -->
        <div class="account-box is-form is-footerless w-full border-none" :style="isMobile ? 'border-bottom: none !important;' : ''" id="main-content">

          <div class="form-head stuck-header" :class="isMobile ? 'py-0' : 'py-3'">
              <div class="form-head-inner" v-if="layout.app">
                <div class="left"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5' : 'fs-4'">{{  layout.app.name.toString().substr(0, 24) }} {{ layout.app.name.toString().length > 24 ? '...' : '' }}</h4></div>
                  <div class="inline-block align-top fs-6 mt-0" style="margin-top: 4px;">
                      <template v-if="layout.app.logo"><img :src="layout.app.logo" style="height: 50px;" class="px-0 mx-0" /></template>
                      <template v-else>{{  layout.app.id }}</template>
                  </div>
              </div>
          </div>
          <div v-if="layout.device && layout.device.name" class="pt-2 pb-2 fs-5 friBack1 text-white text-center ps-3"><span class="align-middle">{{ layout.device.name }}</span></div>
            
          <div v-if="!layout.loading && layout.app" class="mt-3">
            <div class="mx-3">
              <div class="text-left fw-bold fs-1 px-3 pb-3">
                <div class="align-top text-start float-start">
                  <template v-if="layout.search.type == 'today'">Hoy</template>
                  <template v-else-if="layout.search.type == 'yesterday'">Ayer</template>
                  <template v-else-if="layout.search.type == '3'">Últimos 3 días</template>
                  <template v-else-if="layout.search.type == '30'">Últimos 30 días</template>
                  <template v-else-if="layout.search.type == 'custom'">Personalizado</template> 
                  <div class="fs-6 fw-normal" v-if="layout.search.type == 'custom'">{{ layout.search.dateStart.substring(8, 10) }}/{{ layout.search.dateStart.substring(7, 5) }}/{{ layout.search.dateStart.substring(0, 4) }} a {{ layout.search.dateEnd.substring(8, 10) }}/{{ layout.search.dateEnd.substring(7, 5) }}/{{ layout.search.dateEnd.substring(0, 4) }}</div>
                  <div class="fs-6 fw-bold">{{ layout.app.logs?.logs?.total_records }} alarma(s)</div>
                  <div class="text-xs3 fw-normal mt-2" v-if="layout.device.name">{{ layout.device.name }}</div>
                  <div v-if="layout.search.alarmId">
                    <div class="text-xs3 fw-normal" v-for="(alarm, index) in filteredAlarms" :key="index">
                      {{ alarm.name }}
                    </div>
                  </div>
                  <div v-else class="text-xs3 fw-bold">Todas las alarmas</div>
                </div> 
                <div class="mb-3 fs-6 text-end float-end fw-normal align-top friColor1">
                  <ion-menu-toggle class="cursor-pointer"><i class="bi bi-search  " :class="isMobile ? 'fs-1' : 'fs-3'"></i></ion-menu-toggle>

                  <template v-if="layout.app?.limits?.alarms?.share">
                    <template v-if="!layout.loading && layout.app && layout.app.logs?.logs?.alarms && layout.app.logs?.logs?.alarms.length">
                      <i @click="layout.share.modal = true" class="bi bi-share cursor-pointer ps-3" style="font-size: 25px;"></i>
                    </template>
                  </template>

                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>

          <!--
          <div v-if="Object.keys(layout.search).length" class="mb-5 mt-2">
              <pre>{{ layout.search }}</pre>
          </div>
          -->
          <template v-if="!layout.loading && layout.app && layout.app.logs?.logs?.alarms">
              <div class="ps-4 pe-3">
                  <template v-if="layout.app.logs?.logs?.alarms.length">
                    <div style="padding-bottom: 100px;">
                      <div>
                        <ion-grid class="w-100">
                          <div v-for="(dateLogs, date) in groupedLogs" :key="date">
                            <ion-row class="w-100 py-1 cursor-pointer" @click="!layout.currentResultDate ? layout.currentResultDate = date : layout.currentResultDate = false">
                              <ion-col class="fs-5 fw-bold">
                                <i class="bi bi-chevron-compact-down" v-if="date == layout.currentResultDate"></i>
                                <i class="bi bi-chevron-compact-right" v-else></i> 
                                {{ formatDate(date) }} <span class="text-end float-end fw-normal fs-6">{{ dateLogs.length }} alarmas</span></ion-col>
                            </ion-row>
                            <div :class="date == layout.currentResultDate ||  Object.keys(groupedLogs).length < 2 ? '' : 'hidden'">
                              <ion-row class="w-100 py-1 cursor-pointer" v-for="(log, index) in dateLogs" :key="index" 
                                @click="$router.push({ path: '/alert/' + layout.appId + '/' + layout.deviceId + '/' + log.code , query: { section:  'dashboard', alarmId: log.id } })"
                                :class="index % 2 == 0 ? 'friBack9' : ''" >
                                <ion-col style="max-width: 70px !important" class="text-center" :class="log.status.status == 'solved' ? '' : 'friColor3'">{{ log.date.substring(11, 19) }}</ion-col>
                                <ion-col :class="log.status.status == 'solved' ? '' : 'friColor3'">
                                  {{ log.name.substr(0, 18) }} {{ log.name.length > 18 ? '...' : '' }}
                                </ion-col>
                                <ion-col class="text-end float-end pe-3" :class="log.status.status == 'solved' ? '' : 'friColor3'">
                                  <i class="bi bi-check2" v-if="log.status.status == 'solved'"></i>
                                  <i class="bi bi-exclamation-triangle" v-else></i>
                                </ion-col>
                              </ion-row>
                            </div>
                          </div>
                        </ion-grid>
                      </div>

                      <!-- Modal Alarm 
                      <ion-modal :is-open="layout.alarmBox" :backdrop-dismiss="false">
                        <ion-content class="me-5" v-if="layout.alarmEdit">
                          <div class="px-5 py-4 fs-6">
                            <div class="fw-bold fs-1">{{ layout.alarmEdit.name }} <span class="float-end" @click="layout.alarmBox = false"><i class="bi bi-x-circle" style="font-size: 30px;"></i></span></div>
                            <div class="fs-4 mb-3 friColor3" v-if="layout.alarmEdit.status.status != 'solved'">Activa</div>
                            <div class="fs-4 mb-3 friColor1" v-else>Finalizada</div>
                            <div v-if="layout.alarmEdit.status.solved_by">
                              <div v-if="layout.alarmEdit.status.solved_by=='System'" class="text-center p-2 friBack4 br-5">
                                El sistema ha descartado la alarma por el restablecimiento de sus valores.
                              </div>
                              <template v-else>
                                Descartada por el usuario:
                                <span class="float-end">{{ layout.alarmEdit.status.solved_by.toString().substring(0, 10) }}...</span>
                              </template>
                            </div>

                            <div class="line_box" style="margin: 20px 0 20px 0;">
                              <div class="text_circle">
                                  <div class="circle">
                                    <h4 class="fs-5 text-dark">Alarma</h4>
                                    <p class="text-dark">{{ layout.alarmEdit.date.substring(8, 10) }}/{{ layout.alarmEdit.date.substring(5, 7) }}/{{ layout.alarmEdit.date.substring(0, 4) }}</p>
                                    <p class="text-dark">{{ layout.alarmEdit.date.substring(11, 19) }}</p>
                                  </div>
                                  <div class="tvar">
                                    <div class="float-end fw-bold friColor3">
                                      {{ parseFloat(layout.alarmEdit.alarm.detect).toFixed(1) }}
                                      <span class="text-xs">{{ layout.alarmEdit.alarm.unit }}</span>
                                      <p v-if="layout.alarmEdit.alarm.param == 'max'" class="text-dark p-0 m-0">Máx: {{ parseFloat(layout.alarmEdit.alarm.paramValue).toFixed(1) }} <span class="text-xs">{{ layout.alarmEdit.alarm.unit }}</span></p>
                                      <p v-if="layout.alarmEdit.alarm.param == 'min'" class="text-dark p-0 m-0">Mín: {{ parseFloat(layout.alarmEdit.alarm.paramValue).toFixed(1) }} <span class="text-xs">{{ layout.alarmEdit.alarm.unit }}</span></p>
                                    </div>
                                  </div>
                              </div>
                              <template v-if="layout.alarmEdit.status?.detectEnd">
                                <div class="text_circle">
                                    <div class="circle">
                                      <h4 class="fs-5 text-dark">Duración</h4>
                                    </div>
                                    <div class="tvar tvarMin">
                                      <div class="float-center fw-bold text-dark px-0 m-0" style="width: 100%;padding: 30px 0; font-size: 35px; line-height: 10px !important;">
                                        <template v-if="layout.alarmEdit.status.duration">
                                          {{ layout.alarmEdit.status.duration }}
                                          <p class="text-xs mt-5 text-dark">minutos</p>
                                        </template>
                                        <template v-else>
                                          <template v-if="layout.alarmEdit.duration">
                                            {{ layout.alarmEdit.duration.days }}d {{ layout.alarmEdit.duration.hours }}h {{ layout.alarmEdit.duration.minutes }}m
                                          </template>
                                        </template>
                                      </div>
                                    </div>
                                </div>
                                <div class="text_circle">
                                    <div class="circle">
                                      <h4 class="fs-5 text-dark">Finalizada</h4>
                                      <p class="text-dark">{{ layout.alarmEdit.status.end_date.substring(8, 10) }}/{{ layout.alarmEdit.status.end_date.substring(5, 7) }}/{{ layout.alarmEdit.status.end_date.substring(0, 4) }}</p>
                                      <p class="text-dark">{{ layout.alarmEdit.status.end_date.substring(11, 19) }}</p>
                                    </div>
                                    <div class="tvar">
                                      <div class="float-end fw-bold friColor1">
                                        {{ parseFloat(layout.alarmEdit.status.detectEnd).toFixed(1) }}
                                        <span class="text-xs">{{ layout.alarmEdit.alarm.unit }}</span>
                                        <p v-if="layout.alarmEdit.alarm.param == 'max'" class="text-dark p-0 m-0">Máx: {{ parseFloat(layout.alarmEdit.alarm.paramValue).toFixed(1) }} <span class="text-xs">{{ layout.alarmEdit.alarm.unit }}</span></p>
                                        <p v-if="layout.alarmEdit.alarm.param == 'min'" class="text-dark p-0 m-0">Mín: {{ parseFloat(layout.alarmEdit.alarm.paramValue).toFixed(1) }} <span class="text-xs">{{ layout.alarmEdit.alarm.unit }}</span></p>
                                      </div>
                                    </div>
                                </div>
                              </template>
                              <template v-else>
                                <div class="text_circle">
                                    <div class="circle">
                                      
                                    </div>
                                    <div class="tvar">
                                      <div class="float-center fw-bold text-dark px-0 m-0" style="width: 100%;padding: 40px 0; font-size: 35px; line-height: 10px !important;">
                                        <h4 class="fs-6 friColor3">En curso</h4>
                                      </div>
                                    </div>
                                </div>
                              </template>
                            </div>
                            
                           <div class="mt-3 mb-3 fs-6 fw-bold">Notificaciones</div>
                            <div v-if="layout.alarmEdit.notify">
                              <div>App <span class="float-end"><span v-if="layout.alarmEdit.notify.app"><i class="bi bi-check-lg"></i></span><span v-else>No</span></span></div>
                              <div>Whatsapp <span class="float-end"><span v-if="layout.alarmEdit.notify.whatsapp"><i class="bi bi-check-lg"></i></span><span v-else>No</span></span></div>
                              <div>Email <span class="float-end"><span v-if="layout.alarmEdit.notify.email"><i class="bi bi-check-lg"></i></span><span v-else>No</span></span></div>
                              <div>SMS <span class="float-end"><span v-if="layout.alarmEdit.notify.sms"><i class="bi bi-check-lg"></i></span><span v-else>No</span></span></div>
                            </div>
                            <div class="mt-5 fs-6 fw-bold">Acciones</div>
                            <div class="mt-2 text-center mb-5" style="padding: 10px 0;">
                              <button class="button button v-button py-05 friBack4" type="button" @click="layout.alarmBox = false">Cerrar</button>
                              <button v-if="!layout.alarmEdit.status.end_date && ((layout.alarmEdit.users && layout.alarmEdit.users[profile.uid] && layout.alarmEdit.users[profile.uid].discard) || profile.isClient)" 
                              class="button button v-button py-05 friBack7 text-white fw-bold ms-3 " type="button" @click="jobDiscard()">Descartar</button>
                            </div>


                            <pre>{{ layout.alarmEdit }}</pre>

                            
                          </div>
                          </ion-content>
                      </ion-modal>
                      -->


                    </div>
                  </template>
                  <template v-else>
                    <div class="text-center mt-5 mx-5 px-5">No se han encontado registros para las fechas y filtros seleccionados</div>
                  </template>
              </div>
          </template>  
          <template v-else>
                <div class="text-center w-100"  style="margin: 150px 0;">
                    <div class="fw-bold fs-3">Historial de alarmas</div>
                    <div class="mt-2">Espere por favor ...</div>
                    <div class="spinner-border friColor1 mt-5" role="status">
                    <span class="visually-hidden">Cargando ...</span>
                    </div>
                </div>
            </template>
        </div>

        <ion-modal :is-open="layout.share.modal" :backdrop-dismiss="false">
          <ion-content>
            <div class="text-center pt-5">
              <div style="padding-top: 50px;"><i class="bi bi-share-fill" style="font-size: 90px;"></i></div>
              <div class="fs-3">Compartir reporte</div>
              <div class="mt-2" v-if="!layout.share.loading">
                  <div class="fs-6">Introduzca los datos del destinatario</div>
                  <ion-list class="mt-5 pt-0 mx-5">
                      <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                          <ion-label position="floating" class="friColor1 fs-6">Reporte</ion-label>
                          <ion-input v-model="layout.share.subject" type="text" :disabled="true" class="fs-6"></ion-input>
                      </ion-item>
                      <ion-item lines="full" style="--ion-item-border-color: #ccc;">
                          <ion-label position="floating" class="friColor1 fs-6" >Email *</ion-label>
                          <ion-input v-model="layout.share.email" type="email" class="fs-6"></ion-input>
                      </ion-item>
                  </ion-list>
                  <div class="mt-3" style="padding: 25px 0 50px 0;">
                      <div class="d-flex justify-content-center">
                          <button class="button px-5 py-1 m-0 mt-0 bg-light" @click="layout.share.modal = false">Cancelar</button>
                          <button class="button px-5 py-1 mt-0 friBack1 text-white ms-3" @click="exportReport()">Enviar</button>
                      </div>
                  </div>
              </div>
              <div v-else class="mt-5">
                <div class="spinner-border friColor1 mt-5" role="status">
                  <span class="visually-hidden">Enviando reporte ...</span>
                </div>
              </div>
            </div>
          </ion-content>
        </ion-modal>

        <!-- Filter Panel -->
        <ion-menu content-id="main-content">
          <ion-header>
            <ion-toolbar>
              <ion-title>
                <span class="align-middle">Filtros de búsqueda</span>
                <ion-menu-toggle class="text-end float-end fs-2 align-top">X</ion-menu-toggle>
              </ion-title>
            </ion-toolbar>
          </ion-header>
          <ion-content class="ion-padding">
            <div class="mb-3 ms-1" v-if="layout.search.dateStart" :class="isMobile ? 'me-4' : 'me-4'">
              <div class="mb-3">
                <div class="mb-3">Fecha:</div>
                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                    <ion-select interface="popover" v-model="layout.search.type" class="border-none">
                      <ion-select-option v-for="(type, index) in layout.searchTypes" :key="index" :value="type.value">{{ type.label }}</ion-select-option>     
                    </ion-select>
                </ion-item>
              </div>
              <template v-if="layout.search.type && layout.search.type == 'custom'">
                <div class="mb-3">
                  <div class="mb-3">Fecha de inicio:</div>
                  <div @click="layout.modal.startDate = true" class="friBorder3 br-5 px-3 py-4 text-center cursor-pointer">
                    {{ layout.search.dateStart.substring(8, 10) }}/{{ layout.search.dateStart.substring(7, 5) }}/{{ layout.search.dateStart.substring(0, 4) }}
                  </div>
                  <ion-modal :is-open="layout.modal.startDate" :backdrop-dismiss="false">
                    <div class="p-5 mt-5">
                      <div class="d-flex justify-content-center" v-if="layout.search.dateStart">
                        <ion-datetime locale="es-ES" :first-day-of-week="1" presentation="date" :show-default-title="true" :show-default-buttons="false"  v-model="layout.search.dateStart" @ionChange="layout.modal.startDate = false" color="fricolor" :max="layout.search.maxDate" :min="layout.search.minDate">
                          <span slot="time-label">Hora</span>
                          <span slot="title" class="text-white">Fecha de inicio</span>
                        </ion-datetime> 
                      </div>  
                    </div>
                  </ion-modal>
                </div>
                <div class="mb-3">
                  <div class="mb-3">Fecha fin:</div>
                  <div @click="layout.modal.endDate = true" class="friBorder3 br-5 px-3 py-4 text-center cursor-pointer">
                    {{ layout.search.dateEnd.substring(8, 10) }}/{{ layout.search.dateEnd.substring(7, 5) }}/{{ layout.search.dateEnd.substring(0, 4) }}
                  </div>
                  <ion-modal :is-open="layout.modal.endDate" :backdrop-dismiss="false">
                    <div class="p-5 mt-5">
                      <div class="d-flex justify-content-center" v-if="layout.search.dateEnd">
                        <ion-datetime locale="es-ES" :first-day-of-week="1" presentation="date" :show-default-title="true" :show-default-buttons="false"  
                          v-model="layout.search.dateEnd" @ionChange="layout.modal.endDate = false" color="fricolor" :max="layout.search.maxDate" :min="layout.search.dateStart">
                          <span slot="time-label">Hora</span>
                          <span slot="title" class="text-white">Fecha fin</span>
                        </ion-datetime> 
                      </div>  
                    </div>
                  </ion-modal>
                </div>
              </template>
              <div class="mb-3">
                <div class="mb-3">Tipo de alarma:</div>
                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                    <ion-select interface="popover" v-model="layout.search.alarmId" class="border-none">
                        <ion-select-option :value="null">Todas las alarmas</ion-select-option>
                        <ion-select-option v-for="(alarm, index) in layout.alarmTypes" :key="index" :value="alarm.code">{{ alarm.name }}</ion-select-option>
                    </ion-select>
                </ion-item>
              </div>
              <div class="mt-5"><span class="badge friBack1 fs-6 cursor-pointer py-4 w-100 px-0 mb-2" @click="filterLog()">Buscar</span></div>
              <div class="mt-3 text-center"><ion-menu-toggle class="friColor4 cursor-pointer" id="closeFilters">CERRAR</ion-menu-toggle></div>
            </div>
          </ion-content>
        </ion-menu>  

          
    </div>
  </template>
  <script>
  import { defineComponent, onMounted, ref } from "vue"; 
  import { useAuthStore } from "@/store";
  import { helper as $h } from "../../../utils/helper.js";
  import { getApp, getAppDevices, getDeviceAlarmsLog, discardJob, exportStats  } from "../../../model/apps.js"; //
  import { IonDatetime, IonModal, IonItem, IonSelect, IonRow, IonCol, IonGrid, IonContent, IonHeader, IonMenu, IonMenuToggle, IonTitle, IonToolbar, IonList, IonInput, alertController } from '@ionic/vue'; 
  import { getAlarmsType  } from "../../../model/alarms.js"; //getAlarm, 

  import { Chart, LineController, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';
  import annotationPlugin from 'chartjs-plugin-annotation';
  Chart.register(LineController, CategoryScale, LinearScale, PointElement, LineElement, annotationPlugin);
  import { not as $not } from "../../../model/not";

  export default defineComponent({
    name: 'DeviceLog',
    props: {
        isMobile: Boolean,
        appId:    String,
        deviceId: String,
     },
     computed: {
      groupedLogs() {
        // Agrupar los registros por fecha
        const grouped = {};
        this.layout.app.logs?.logs?.alarms.forEach((log) => {
          const date = log.date.substring(0, 10);
          if (!grouped[date]) {
            grouped[date] = [];
          }
          grouped[date].push(log);
        });
        return grouped;
      },
      filteredAlarms() {
        if (!this.layout.search.alarmId) return [];
        return this.layout.alarmTypes.filter(alarm => alarm.code == this.layout.search.alarmId);
      }
    },
    components: { IonDatetime, IonModal, IonItem, IonSelect, IonRow, IonCol, IonGrid, IonContent, IonHeader, IonMenu, IonMenuToggle, IonTitle, IonToolbar, IonList, IonInput },
    setup(props) {

       const authStore      = useAuthStore();
       const layout         = ref({ 
                                    loading:  true,
                                    appId:    null,
                                    app:      null,
                                    helper:   $h,
                                    search:  { type: "today", dateStart: null, dateEnd: null, maxDate: null, minDate: null, alarmId: null, deviceId: null },
                                    searchTypes: [ 
                                                  { value: "today", label: "Hoy" }, 
                                                  { value: "yesterday", label: "Ayer" }, 
                                                  { value: "3", label: "Últimos 3 días" }, 
                                                  //{ value: "30", label: "Últimos 30 días" },
                                                  { value: "custom", label: "Personalizado" } 
                                                ],
                                    modal:   { startDate: false, endDate: false },
                                    alarm:   null,
                                    alarmEdit: false,
                                    alarmTypes: null,
                                    alarmId: null,
                                    currentResultDate: null,
                                    share:     {
                                                modal:    false,
                                                email:    null,
                                                loading:  false,
                                                subject:  "",
                                                filename: "",
                                               },
                                  }) 

       const { user, profile } = authStore;

       const initStats = async () => {
         layout.value.app         = null; 
         layout.value.appId       = props.appId
         layout.value.deviceId    = props.deviceId

         layout.value.app         = await getApp(props.appId)
         
         let deviceData           = await getAppDevices(layout.value.appId, layout.value.deviceId)
         if(deviceData && deviceData?.devices && Object.keys(deviceData.devices).length)layout.value.device = deviceData.devices[layout.value.deviceId]

         layout.value.alarmTypes  = await getAlarmsType({ type: layout.value.device.type})

         //layout.value.alarm       = await getAlarm(layout.value.appId, layout.value.deviceId, layout.value.alarmId)
         //layout.value.app.devices = await getAppDevices(props.appId);
         if(profile?.email)layout.value.share.email =  profile.email;
         await initFilters();
         await getAlarms();
         
         layout.value.loading  = false;
         
       }

       const getAlarms = async () => {

        layout.value.share.subject   = "Reporte de alarmas"
        layout.value.share.filename  = "alba_hub_alarm_report"
        layout.value.share.subject  += " - " + layout.value.device.name;
        layout.value.share.filename += " " + layout.value.device.name;

        let currentDate     = new Date();
        let localDateString = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;
        if(layout.value.search.type == "today"){
        
          layout.value.search.dateStart = layout.value.search.dateEnd = localDateString;
          layout.value.share.subject   += " - " + layout.value.search.dateStart.substring(8, 10) + "/" + layout.value.search.dateStart.substring(5, 7) + "/" + layout.value.search.dateStart.substring(0, 4);
          layout.value.share.filename  += "_" + layout.value.search.dateStart.substring(8, 10) + layout.value.search.dateStart.substring(5, 7) + layout.value.search.dateStart.substring(0, 4);

        }else if (layout.value.search.type == "yesterday") {

          let yesterday = new Date();
          yesterday.setDate(yesterday.getDate() - 1);
          layout.value.search.dateStart = layout.value.search.dateEnd = yesterday.toISOString().substring(0, 10);
          layout.value.share.subject   += " - " + layout.value.search.dateStart.substring(8, 10) + "/" + layout.value.search.dateStart.substring(5, 7) + "/" + layout.value.search.dateStart.substring(0, 4);
          layout.value.share.filename  += "_" + layout.value.search.dateStart.substring(8, 10) + layout.value.search.dateStart.substring(5, 7) + layout.value.search.dateStart.substring(0, 4);

        }else if(layout.value.search.type == "3"){

          let threeDays = new Date();
          threeDays.setDate(threeDays.getDate() - 3);
          layout.value.search.dateStart = threeDays.toISOString().substring(0, 10);
          layout.value.search.dateEnd   = localDateString;
          layout.value.share.subject   += " - " + layout.value.search.dateStart.substring(8, 10) + "/" + layout.value.search.dateStart.substring(5, 7) + "/" + layout.value.search.dateStart.substring(0, 4);
          layout.value.share.subject   += " a " + layout.value.search.dateEnd.substring(8, 10) + "/" + layout.value.search.dateEnd.substring(5, 7) + "/" + layout.value.search.dateEnd.substring(0, 4);
          layout.value.share.filename  += "_" + layout.value.search.dateStart.substring(8, 10) + layout.value.search.dateStart.substring(5, 7) + layout.value.search.dateStart.substring(0, 4);
          layout.value.share.filename  += "_" + layout.value.search.dateEnd.substring(8, 10) + layout.value.search.dateEnd.substring(5, 7) + layout.value.search.dateEnd.substring(0, 4);    

        }else if(layout.value.search.type == "custom"){

          layout.value.share.subject   += " - " + layout.value.search.dateStart.substring(8, 10) + "/" + layout.value.search.dateStart.substring(5, 7) + "/" + layout.value.search.dateStart.substring(0, 4);
          layout.value.share.subject   += " a " + layout.value.search.dateEnd != layout.value.search.dateStart ? " a " + layout.value.search.dateEnd.substring(8, 10) + "/" + layout.value.search.dateEnd.substring(5, 7) + "/" + layout.value.search.dateEnd.substring(0, 4) : "";
          layout.value.share.filename  += "_" + layout.value.search.dateStart.substring(8, 10) + layout.value.search.dateStart.substring(5, 7) + layout.value.search.dateStart.substring(0, 4);
          layout.value.share.filename  += "_" + layout.value.search.dateEnd.substring(8, 10) + layout.value.search.dateEnd.substring(5, 7) + layout.value.search.dateEnd.substring(0, 4);

        }

        let filtAlarms =  { 
          appId:     props.appId, 
          from_date: layout.value.search.dateStart, 
          to_date:   layout.value.search.dateEnd,
          device_id: layout.value.search.deviceId,
        }
        if(layout.value.search.alarmId)filtAlarms.code = layout.value.search.alarmId;                  
        layout.value.app.logs = await getDeviceAlarmsLog(props.appId,filtAlarms);
        layout.value.share.filename = layout.value.share.filename.replace(/[^a-zA-Z0-9]/g, "_");
        layout.value.loading  = false;
       }

       const initFilters = async () => {
          let currentDate     = new Date();
          let year            = currentDate.getFullYear();
          let month           = String(currentDate.getMonth() + 1).padStart(2, '0');
          let day             = String(currentDate.getDate()).padStart(2, '0'); 
          let localDateString = `${year}-${month}-${day}`;

          layout.value.search.dateStart = layout.value.search.dateEnd = layout.value.search.maxDate = localDateString //new Date().toISOString().substring(0, 10);
          
          const minDate                 = new Date();
          minDate.setDate(minDate.getDate() - 7); //1 semana de log
          layout.value.search.minDate   = minDate.toISOString().substring(0, 10);
          if(!layout.value.search.deviceId)layout.value.search.deviceId = layout.value.deviceId
        }

        const createPDF = async () => {
          let pdfBlob = await exportStats({ type: "pdf" });
          let pdfUrl = URL.createObjectURL(pdfBlob);
          window.open(pdfUrl, '_blank');
        }

        const jobDiscard= async () => {
          const alert = await alertController.create({
              header:  "¿Está seguro?",
              cssClass: 'friCustomAlert',
              buttons: [ 
                          {text: 'Cancelar', cssClass: 'alert-button-cancel', role: 'cancel' },  
                          {text: 'Confirmar',cssClass: 'alert-button-confirm', handler: async () => { 
                            let discardId          = layout.value.alarmEdit.id;
                            //layout.value.loading   = true;
                            //layout.value.alarmEdit = null;
                            try{ await discardJob({ "jobId": discardId, "action": "discard", "uid": profile.uid });
                            }catch(e){ console.log("error discard:",e); }
                            setTimeout(async () => {
                              layout.value.alarmEdit = null;
                              await getAlarms();
                            }, 1000);
                          }
                          }
                        ],
          });
          await alert.present();
        }
        
        const filterLog = async () => {
          layout.value.loading = true;
          if(document.getElementById('closeFilters'))document.getElementById('closeFilters').click();
          setTimeout(async () => {
            await getAlarms();
          }, 1000);
        }

        const exportReport = async () => {
          let validate = true
          if(layout.value.share.email == null || layout.value.share.email == ""){ validate = false; }
          if(validate && !layout.value.share.email.includes('@'))validate = false; 
          if(validate){
            layout.value.share.loading = true;
            let sendReport = await exportStats({ 
                                              type: "alarm", 
                                              data: { 
                                                     "appId":       props.appId, 
                                                     "format":      "pdf", 
                                                     "to":          layout.value.share.email, 
                                                     "subject":     layout.value.share.subject,   
                                                     "filename":    layout.value.share.filename, 
                                                     "search":      layout.value.search,
                                                    } }); 

            if(!sendReport || sendReport?.error){
              $not.toastApp({ message: 'Error al enviar el reporte, inténtelo de nuevo', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
              console.log("error sendReport:",sendReport);
            }else{
              layout.value.share.loading  = false;
              layout.value.share.modal    = false;
              $not.toastApp({ message: 'El reporte se ha enviado correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'primary', cssClass: 'text-white mt-2' })
            }
            layout.value.share.loading = false;
          }else $not.toastApp({ message: 'Debe completar todos los campos requeridos *', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
        }

       onMounted( async () => { 
         await initStats();
       });


      return { layout, user, profile, props, filterLog, jobDiscard, createPDF, exportReport};
    },
    methods: {
      formatDate(date) {
        // Formatear la fecha (por ejemplo: DD/MM/YYYY)
        return date.substring(8, 10) + '/' + date.substring(5, 7) + '/' + date.substring(0, 4);
      },
    },
  })
  </script>
  <style>
    .line_box { display: flex;margin-bottom: 10px; }
    .text_circle {flex: 1;text-align: center;position: relative; }
    .text_circle:after { background-color: grey;bottom: 3.25em;content: "";display: block;height: 3px;position: absolute;right: 0;width: 50%;z-index: -1;}
    .line_box h4 {color: #189599;font-weight: bold;}
    .line_box h4,.line_box p {font-size: 12px;margin-bottom: 0;padding: 0 5px;}
    .timeline {margin: 40px 0;}
    .text_circle.sub:after {background-color: #189599;}
    .text_circle:not(:first-child):before {bottom: 3.25em;content: "";display: block;height: 3px; position: absolute;left: 0;width: 50%;z-index: -1;background-color: grey;}
    .text_circle:last-child:after {width: 0;}
    .circle {height: 100%;}
    .tvar {
        height: 100px;
        width: 100px;
        display: flex;
        position: relative;
        border-radius: 100%;
        top: -100px;
        margin: 3px auto;
        background-color: #f7f8f9;
    }
    .tvar div {
      padding: 30px 0 0 19px;
      text-align: center;
      font-size: 20px;
      line-height: 20px;
    }
    .tvarMin {
      height: 95px;
      width: 95px;
    }
    .tvarMin div { padding: 20px 0 0 10!important;}

  </style>