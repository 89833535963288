import axios from 'axios';
import { getAppSettings } from "../model/api.js"; 
import { Buffer } from 'buffer';

var apiKey = "";

const ttnClient = axios.create({
  baseURL: 'https://eu1.cloud.thethings.network/api/v3/',
  headers: { 'Content-Type': 'application/json' }
});

const setApiKey = (newApiKey) => {
  apiKey = newApiKey;
  ttnClient.defaults.headers.Authorization = `Bearer ${apiKey}`;
}

const getTTNApp = async (appId) => {
    let app = {};
    let appSettings = await getAppSettings('ttn');
    setApiKey(appSettings.settings.fricontrolKey);
    const url = `applications/${appId}`;
    try { 
        const response = await ttnClient.get(url);  
        app = response.data;
        app.devices  = await getAppDevices(appId);
        app.webhooks = await getAppWebhooks(appId);
        app.stats    = await getStorage(appId);
    } catch (error) {
        console.error(`Error: ${error}`);
        throw error;
    }
    return app;
}

const getAppDevices = async (appId) => {
    try { 
        const response = await ttnClient.get(`applications/${appId}/devices`);  
        return response.data;
    } catch (error) { console.error(`Error: ${error}`); throw error;}
};

const getDeviceData = async (appId, deviceId) => {
    try { 
        let appSettings = await getAppSettings('ttn');
        setApiKey(appSettings.settings.fricontrolKey);
        const response = await ttnClient.get(`as/applications/${appId}/devices/${deviceId}`);  
        return response.data;
    } catch (error) { console.error(`Error: ${error}`); throw error;}
};

const getAppWebhooks = async (appId) => {
    try { const response = await ttnClient.get(`as/webhooks/${appId}`);  return response.data;
    } catch (error) { console.error(`Error: ${error}`); throw error;}
}

const getStorage = async (appId) => {
    try { const response = await ttnClient.get(`as/applications/${appId}/packages/storage/uplink_message`);  return response.data;
    } catch (error) { console.error(`Error: ${error}`); throw error;}
}

const createAppTTN = async (app) => {
    let appSettings = await getAppSettings('ttn');
    setApiKey(appSettings.settings.fricontrolKey);
    const url = `users/fricontrol/applications`;
    const resp = {}
    try { 
        const response = await ttnClient.post(url,  {
            application: {
                ids: { application_id: app.appId.toString() },
                name: app.name,
                description: app.description
            },
        });  
        resp.create   = response.data;

        //Create webhooks
        const webhook = await createAppWebhookTNT(app);
        resp.webhook  = webhook.data;

        return resp ;
    } catch (error) {
        console.error(`Error: ${error}`);
        throw error;
    }
}

const deleteAppTTN = async (appId) => {
    let appSettings = await getAppSettings('ttn');
    setApiKey(appSettings.settings.fricontrolKey);
    const url = `applications/${appId}`;
    try {  const response = await ttnClient.delete(url); return response.data;
    } catch (error) {
        console.error(`Error: ${error}`);
        throw error;
    }
}

const createGatewayTTN = async (gateway) => {
    let appSettings = await getAppSettings('ttn');
    setApiKey(appSettings.settings.fricontrolKey);
    const url = `users/fricontrol/gateways`;
    try { 
        const response = await ttnClient.post(url,  {
            gateway: {
                ids: { 
                    gateway_id:     gateway.gatewayId.toString(),
                    eui:            gateway.eui.toString(),
                 },
                enforce_duty_cycle:                 true,
                frequency_plan_ids:                 [gateway.frequencyPlanId],
                name:                               gateway.gatewayId.toString(),
                location_public:                    false,
                require_authenticated_connection:   true,
                status_public:                      true,
                gateway_server_address:             gateway.serverAdress,
                //schedule_anytime_delay:             "0,53s"
            },
        });  
        return response.data;
    } catch (error) {
        console.error(`Error: ${error}`);
        throw error;
    }
}


const createGatewayKeysTTN = async (gateway) => {
    let appSettings = await getAppSettings('ttn');
    setApiKey(appSettings.settings.fricontrolKey);
    const url = `gateways/${gateway.gatewayId.toString()}/api-keys`;
    const keys = { 
                    cups: { 
                            api_key: {
                                name: "cups-api-key-" + gateway.gatewayId.toString(),
                                rights: ['RIGHT_GATEWAY_INFO', 'RIGHT_GATEWAY_SETTINGS_BASIC', 'RIGHT_GATEWAY_READ_SECRETS'],
                            }
                          }, 
                    lns: {
                            api_key: {
                                name: "lns-api-key-" + gateway.gatewayId.toString(),
                                rights: ['RIGHT_GATEWAY_INFO', 'RIGHT_GATEWAY_LINK'],
                            }  
                          }
                 }
    try {
        //cups
        const cupskey      = await ttnClient.post(url, keys.cups.api_key);
        keys.cups.response = cupskey.data;
        //lns
        const lnskey       = await ttnClient.post(url, keys.lns.api_key);
        keys.lns.response  = lnskey.data;
        return keys
    } catch (error) {
        console.error(`Error: ${error}`);
        throw error;
    }
}


const updateGatewayTTN = async (gateway) => {
    let appSettings = await getAppSettings('ttn');
    setApiKey(appSettings.settings.fricontrolKey);
    const url = `gateways/${gateway.gatewayId.toString()}`;
    try { 
        const response = await ttnClient.put(url,  {
            paths: [ "name", "eui", "description", "gateway_server_address", "frequency_plan_id"],
            gateway: { 
                ids: { gateway_id: gateway.gatewayId.toString() },
                name:  gateway.name.toString(),
                eui:   gateway.eui.toString(),
                description: gateway.name.toString(),
                gateway_server_address: gateway.serverAdress,
                frequency_plan_id: gateway.frequencyPlanId,
            },
        });  
        return response.data;
    } catch (error) {
        console.error(`Error: ${error}`);
        throw error;
    }
}

const deleteGatewayTTN = async (gatewayId) => {
    let appSettings = await getAppSettings('ttn');
    setApiKey(appSettings.settings.fricontrolKey);
    const url = `gateways/${gatewayId}`;
    try {  const response = await ttnClient.delete(url); return response.data;
    } catch (error) {
        console.error(`Error: ${error}`);
        throw error;
    }
}

const createDeviceTTN = async (device) => {
    let appSettings = await getAppSettings('ttn');
    setApiKey(appSettings.settings.fricontrolKey);
    
    let settingsByModel = {
        "dht": {
            "brand_id": "dragino",
            "model_id": "lht65",
            "hardware_version": "_unknown_hw_version_",
            "firmware_version": "1.8",
        },
        "dht52": {
            "brand_id": "dragino",
            "model_id": "lht52",
            "hardware_version": "_unknown_hw_version_",
            "firmware_version": "1.0",
        },
        "lds02": {
            "brand_id": "dragino",
            "model_id": "lds02",
            "hardware_version": "_unknown_hw_version_",
            "firmware_version": "1.5",
        },
        "xw265k": {
            "brand_id": "dragino",
            "model_id": "rs485-ln",
            "hardware_version": "_unknown_hw_version_",
            "firmware_version": "1.3",
        },
        "aqs01l": {
            "brand_id": "dragino",
            "model_id": "aqs01-l",
            "hardware_version": "_unknown_hw_version_",
            "firmware_version": "1.3",
        },
        "ct101": {
            "brand_id": "milesight",
            "model_id": "ct101",
            "hardware_version": "_unknown_hw_version_",
            "firmware_version": "1.0.2",
        }
    }

    const resp = {}
    try { 

        //EndDeviceRegistry Create
        const responseCreate = await ttnClient.post(`applications/${device.appId}/devices`,  {
            end_device: {
                         application_server_address:	"eu1.cloud.thethings.network",
                         ids: { 
                                    application_ids: {
                                        application_id: device.appId.toString()
                                    },
                                    dev_eui:	device.deveui.toString(),
                                    device_id:	device.deviceId.toString(),
                                    join_eui:	device.eui.toString(),
                                },
                         join_server_address:	    device.joinServerAddress.toString(),
                         network_server_address:	device.networkServerAddress.toString(),
                         version_ids: {
                            band_id:	        "EU_863_870",
                            brand_id:	        settingsByModel[device.type].brand_id,
                            firmware_version:	settingsByModel[device.type].firmware_version,
                            hardware_version:	settingsByModel[device.type].hardware_version,
                            model_id:	        settingsByModel[device.type].model_id,
                         },
                        },
            field_mask: {
                        paths: [
                            "version_ids.brand_id",
                            "version_ids.model_id",
                            "version_ids.hardware_version",
                            "version_ids.firmware_version",
                            "version_ids.band_id",
                            "network_server_address",
                            "application_server_address",
                            "join_server_address",
                        ]
                    }
            }
        );  
        resp.create = responseCreate.data;     
        
        //JSEndDeviceRegistry Set
        const responseJS = await ttnClient.put(`js/applications/${device.appId}/devices/${device.deviceId.toString()}`,  {
            end_device: {
                        application_server_address:    "eu1.cloud.thethings.network",
                        ids: { 
                                application_ids: {
                                    application_id: device.appId.toString()
                                },
                                dev_eui:	device.deveui.toString(),
                                device_id:	device.deviceId.toString(),
                                join_eui:	device.eui.toString(),
                             },
                        network_server_address:	    "eu1.cloud.thethings.network",
                        root_keys: {
                            app_key: {
                                key:	device.key.toString(),
                             },
                             nwk_key: {
                                key:	device.key.toString(),
                             }
                        },
            },
            field_mask: {
                paths: [
                    "network_server_address",
                    "application_server_address",
                    "ids.join_eui",
                    "ids.dev_eui",
                    "ids.device_id",
                    "ids.application_ids.application_id",
                    "root_keys.app_key.key",
                ]
            }
        });   
        
        resp.js = responseJS.data;
        //NsEndDeviceRegistry Set
        const responseNS = await ttnClient.put(`ns/applications/${device.appId}/devices/${device.deviceId.toString()}`,  {
            end_device: {
                         frequency_plan_id:	            "EU_863_870_TTN",
                         ids: { 
                                    application_ids: {
                                        application_id: device.appId.toString()
                                    },
                                    dev_eui:	device.deveui.toString(),
                                    device_id:	device.deviceId.toString(),
                                    join_eui:	device.eui.toString(),
                                },
                         lorawan_phy_version:	    "PHY_V1_0_3_REV_A",
                         lorawan_version:	        "MAC_V1_0_3",
                         mac_settings: {      
                            class_c_timeout:	"60s",
                            supports_32_bit_f_cnt:	true,
                         },
                         supports_join:	        true,
                         version_ids: {
                            band_id:	        "EU_863_870",
                            brand_id:	        settingsByModel[device.type].brand_id,
                            firmware_version:	settingsByModel[device.type].firmware_version,
                            hardware_version:   settingsByModel[device.type].hardware_version,	
                            model_id:           settingsByModel[device.type].model_id,	        
                         },
                        },
            field_mask: {
                        paths: [
                            "version_ids.brand_id",
                            "version_ids.model_id",
                            "version_ids.hardware_version",
                            "version_ids.firmware_version",
                            "version_ids.band_id",
                            "frequency_plan_id",
                            "supports_join",
                            "supports_class_b",
                            "supports_class_c",
                            "lorawan_version",
                            "lorawan_phy_version",
                            "mac_settings.class_c_timeout",
                            "mac_settings.supports_32_bit_f_cnt",
                            "ids.join_eui",
                            "ids.dev_eui",
                            "ids.device_id",
                            "ids.application_ids.application_id"
                        ]
                        }
            }
        ); 
        resp.ns = responseNS.data;     

        //AsEndDeviceRegistry Set
        const responseAS = await ttnClient.put(`as/applications/${device.appId}/devices/${device.deviceId.toString()}`,  {
            end_device: {
                        formatters: {
                            down_formatter: "FORMATTER_REPOSITORY",
                            up_formatter:	"FORMATTER_REPOSITORY",
                        },
                        ids: { 
                                application_ids: {
                                    application_id: device.appId.toString()
                                },
                                dev_eui:	device.deveui.toString(),
                                device_id:	device.deviceId.toString(),
                                join_eui:	device.eui.toString(),
                             },
                        version_ids: {
                                band_id:	        "EU_863_870",
                                brand_id:           settingsByModel[device.type].brand_id,	        
                                firmware_version:   settingsByModel[device.type].firmware_version,	
                                hardware_version:   settingsByModel[device.type].hardware_version,	
                                model_id:           settingsByModel[device.type].model_id,	        
                             },
                        },
            field_mask: {
                        paths: [
                            "version_ids.brand_id",
                            "version_ids.model_id",
                            "version_ids.hardware_version",
                            "version_ids.firmware_version",
                            "version_ids.band_id",
                            "formatters.up_formatter",
                            "formatters.down_formatter",
                            "ids.join_eui",
                            "ids.dev_eui",
                            "ids.device_id",
                            "ids.application_ids.application_id",
                        ]
                        }
            }
        );
        resp.as = responseAS.data;

        if (device.type == "xw265k"){  
            const responseDownlink = await setDownlinkDeviceTTN(device.appId, device.deviceId, "256"); //Por defecto que devuelva siempre la temperatura sonda 1
            resp.downlink          = responseDownlink;
        } 

        return resp

    } catch (error) {
        console.error(`Error: ${error}`);
        throw error;
    }
}

const createAppWebhookTNT = async (app) => {
    let appSettings = await getAppSettings('ttn');
    setApiKey(appSettings.settings.fricontrolKey);
    const url = `as/webhooks/${app.appId}`;
    try { 
        const response = await ttnClient.post(url,  {
            webhook: {
                ids: { 
                    webhook_id: "wh"+app.appId.toString().toLowerCase(), 
                },
                base_url:           "https://iot.fricontrolalba.com/webhook/",
                downlink_ack:       null,
                downlink_api_key:   "",
                downlink_failed:    null,
                downlink_nack:      null,
                downlink_queue_invalidated: null,
                downlink_queued:    null,
                downlink_sent:      null,
                field_mask:         { paths: [] },
                format:             "json",
                headers:            {},
                join_accept:        { path: "" },
                location_solved:    null,
                service_data:       null,
                uplink_message:     { path: "" },
                uplink_normalized:  { path: "" },
            },
            field_mask: {
                paths: [
                    "service_data",
                    "location_solved",
                    "downlink_queue_invalidated",
                    "downlink_queued",
                    "downlink_failed",
                    "downlink_sent",
                    "downlink_nack",
                    "downlink_ack",
                    "join_accept.path",
                    "uplink_normalized.path",
                    "uplink_message.path",
                    "downlink_api_key",
                    "base_url",
                    "field_mask",
                    "format",
                    "ids.webhook_id",
                    "headers",
                ]
            }
        });  
        return response.data;
    } catch (error) {
        console.error(`Error: ${error}`);
        throw error;
    }
}


const deleteDeviceTTN = async (appId, deviceId) => {
    let appSettings = await getAppSettings('ttn');
    setApiKey(appSettings.settings.fricontrolKey);
    const url = `applications/${appId}/devices/${deviceId}`;
    try {  const response = await ttnClient.delete(url); return response.data;
    } catch (error) {
        console.error(`Error: ${error}`);
        throw error;
    }
}

const setDeviceSamplingIntervalTTN = async (appId, deviceId, interval) => {
    let resp    = { success: false, alarm: {} };
    let options = { appId: appId, deviceId: deviceId, interval: interval };
    try {
        let queryParams = new URLSearchParams(options).toString();
        let response    = await axios.get(`https://iot.fricontrolalba.com/webhook/interval?${queryParams}`, { headers: {"Content-Type": "application/json" } });
        if (response.data) {
            resp.success = true;
            resp.alarm    = response.data;
        }
    } catch (error) { console.error("Error al obtener logs:", error); }
    return resp;
};

/*
const setDownlinkDeviceTTN = async (appId, deviceId, code) => {

    let appSettings = await getAppSettings('ttn');
    setApiKey(appSettings.settings.fricontrolKey);
    const url = `as/applications/${appId}/devices/${deviceId}/down/replace`;
    
    let frm_payload_down = "";
    let formatter        = ""

    if(!code)code = "256";

    if(code=="256"){  // Temperatura sonda 1
        frm_payload_down = "rwEBBgEDAQAAAQE=" //AF 01 01 06 01 03 01 00 00 01 01
        formatter        = "function Decoder(bytes, port){\n if(port==0x02){\n var decode = {};\n decode.TempC_S1 = (bytes[1] << 8 | bytes[2]) / 10;\n decode.TempC_S2 = (bytes[1] << 8 | bytes[2]) / 10;\n decode.set = (bytes[4]) / 10;\n decode.HY = bytes[6];\n decode.bytes = bytes;\n return decode;\n }\n }" 
    }

    if(code=="768"){ // HY - Histéresis o diferencial temperatura
        frm_payload_down = "rwEBBgEDAwAAAQE=" //AF 01 01 06 01 03 03 00 00 01 01
        formatter        = "function Decoder(bytes, port){\n if(port==0x02){\n var decode = {};\n decode.TempC_S1 = (bytes[1] << 8 | bytes[2]) / 10;\n decode.TempC_S2 = (bytes[1] << 8 | bytes[2]) / 10;\n decode.set = (bytes[4]) / 10;\n decode.HY = bytes[6];\n decode.bytes = bytes;\n return decode;\n }\n }" 
    } 

    const downlink = { downlinks: [ { f_port: 2, frm_payload: frm_payload_down, confirmed: true } ] }

    try { 
        const response = await ttnClient.post(url,  downlink);
        console.log('upload downlink', response.data);
        if (formatter && response && response.data) {
            const responseAS = await ttnClient.put(`as/applications/${appId}/devices/${deviceId.toString()}`,  { //UPLOAD DOWNLINK ACORDING TO CODE
                end_device: {
                            formatters: {
                                    down_formatter: "FORMATTER_NONE",
                                    up_formatter:	"FORMATTER_JAVASCRIPT",
                                    up_formatter_parameter: formatter,
                                },
                            },
                field_mask: {
                            paths: [
                                    "formatters.down_formatter",
                                    "formatters.down_formatter_parameter",
                                    "formatters.up_formatter",
                                    "formatters.up_formatter_parameter",
                                   ]
                            }
                }
            );
            response.uplinkDecoder = responseAS.data;
            return response.data;
        }else return {error: "Error al procesar el downlink"};
    } catch (error) {
        console.error(`Error: ${error}`);
        throw error;
    }
}
*/

const setDownlinkDeviceTTN = async (appId, deviceId, codes) => {
    console.log("setDownlinkDeviceTTN", appId, deviceId, codes);
    let appSettings = await getAppSettings('ttn');
    setApiKey(appSettings.settings.fricontrolKey);
    const url = `as/applications/${appId}/devices/${deviceId}/down/replace`;
    
    const commandsHex = [
        "AF01010601030100000101",  // Comando para el registro 256 (Pb1)
        "AF01010601030102000101",  // (Pb2)
        "AF01010601030104000101",  // (Pb3))
        "AF01010601030300000101",  // Comando para el registro 768 (Histéresis o diferencial temperatura)
        "AF01010601030301000101",  // Comando para el registro 769 (Punto de intervención mínimo del set)
        "AF01010601030302000101",  // Comando para el registro 770 (Punto de intervención máximo del set)
        "AF01010601030400000101"   //832
    ];
    
    const payloadBase64 =  Buffer.from(commandsHex[0] + commandsHex[1] + commandsHex[2] + commandsHex[3] + commandsHex[4] + commandsHex[5] + commandsHex[6], 'hex').toString('base64');

    const downlink = {
        downlinks: [{
            f_port: 2,
            frm_payload: payloadBase64,
            confirmed: true
        }]
    };

    try { 
        const response = await ttnClient.post(url, downlink);
        console.log('upload downlink', response.data);
    }
    catch (error) {
        console.error(`Error: ${error}`);
        throw error;
    }
}

/*
const setDownlinkDeviceTTN = async (appId, deviceId, codes) => {
    console.log("setDownlinkDeviceTTN", appId, deviceId, codes);
    let appSettings = await getAppSettings('ttn');
    setApiKey(appSettings.settings.fricontrolKey);
    const url = `as/applications/${appId}/devices/${deviceId}/down/replace`;
    
    const commandsHex = [
        "AF01010601030100000101",  // Comando para el registro 256 (Pb1)
        "AF01010601030102000101",  // (Pb2)
        "AF01010601030104000101",  // (Pb3))
        "AF01010601030300000101",  // Comando para el registro 768 (Histéresis o diferencial temperatura)
        "AF01010601030301000101",  // Comando para el registro 769 (Punto de intervención mínimo del set)
        "AF01010601030302000101",  // Comando para el registro 770 (Punto de intervención máximo del set)
        "AF01010601030400000101"   //832
    ];
    
    const payloadBase64 = commandsHex.map(cmdHex => Buffer.from(cmdHex, 'hex')).join('');

    const downlink = {
        downlinks: [{
            f_port: 2,
            frm_payload: Buffer.from(payloadBase64).toString('base64'), // Convertir a base64
            confirmed: true
        }]
    };

    try { 
        const response = await ttnClient.post(url, downlink);
        console.log('upload downlink', response.data);
        
        const combinedFormatter = "function Decoder(bytes, port){\n if(port==0x02){\n var decode = {};\n decode.TempC_S1 = (bytes[1] << 8 | bytes[2]) / 10;\n decode.TempC_S2 = (bytes[1] << 8 | bytes[2]) / 10;\n decode.set = (bytes[4]) / 10;\n decode.HY = bytes[6];\n decode.bytes = bytes;\n return decode;\n }\n }" 
        
        // Subir el decodificador si es necesario
        if (response && response.data) {
            const responseAS = await ttnClient.put(`as/applications/${appId}/devices/${deviceId.toString()}`, {
                end_device: {
                    formatters: {
                        down_formatter: "FORMATTER_NONE",
                        up_formatter: "FORMATTER_JAVASCRIPT",
                        up_formatter_parameter: combinedFormatter,
                    },
                },
                field_mask: {
                    paths: [
                        "formatters.down_formatter",
                        "formatters.down_formatter_parameter",
                        "formatters.up_formatter",
                        "formatters.up_formatter_parameter",
                    ]
                }
            });
            response.uplinkDecoder = responseAS.data;
            return response.data;
        } else {
            return { error: "Error al procesar el downlink" };
        }
    } catch (error) {
        console.error(`Error: ${error}`);
        throw error;
    }
}

const setDownlinkDeviceTTN = async (appId, deviceId, codes) => {
    console.log("setDownlinkDeviceTTN", appId, deviceId, codes);
    const commandsHex = [
        "AF01010601030100000101",  // (Pb1)
        "AF01010601030102000101",  // (Pb2)
        "AF01010601030104000101",  // (Pb3)
        "AF01010601030800000101",  // (FAN)
        "AF01010601030801000101",  // (COOLING)
        "AF01010601030300000101",  // Registro 768 (Histéresis/diferencial de temperatura)
        "AF01010601030301000101",  // Registro 769 (Punto de intervención mínimo)
        "AF01010601030302000101",  // Registro 770 (Punto de intervención máximo)
        "AF01010601030400000101"   // Registro 832
    ];

    enviarDownlinksSecuenciales(appId, deviceId, commandsHex)
        .then(responses => {
            console.log('Todas las respuestas de downlink:', responses);
        })
        .catch(error => {
            console.error('Error enviando downlinks secuenciales:', error);
        });
  
    try {
        const combinedFormatter = "function Decoder(bytes, port){\n if(port==0x02){\n var decode = {};\n decode.TempC_S1 = (bytes[1] << 8 | bytes[2]) / 10;\n decode.TempC_S2 = (bytes[1] << 8 | bytes[2]) / 10;\n decode.set = (bytes[4]) / 10;\n decode.HY = bytes[6];\n decode.bytes = bytes;\n return decode;\n }\n }" 
        await ttnClient.put(`as/applications/${appId}/devices/${deviceId}/formatters`, {
            up_formatter: "FORMATTER_JAVASCRIPT",
            up_formatter_parameter: combinedFormatter
        });
    } catch (error) {
        console.error('Error:', error);
        return { error: "Error processing the downlink" };
    }
};

async function enviarDownlinksSecuenciales(appId, deviceId, commandsHex) {
    let appSettings = await getAppSettings('ttn');
    setApiKey(appSettings.settings.fricontrolKey);
    const url = `as/applications/${appId}/devices/${deviceId}/down/replace`;
    let responses = []; 
    for (const cmdHex of commandsHex) {
        console.log('cmdHex', cmdHex);
        const payloadBase64 = Buffer.from(cmdHex, 'hex').toString('base64');
        const downlink = {
            downlinks: [{
                f_port: 2,
                frm_payload: payloadBase64,
                confirmed: true
            }]
        };
        try {
            const response = await ttnClient.post(url, downlink);
            console.log('Upload downlink', response.data);
            responses.push(response.data);
        } catch (error) {
            console.error(`Error: ${error}`);
            responses.push({error: `Error: ${error}`}); 
        }
    }
    return responses;
}

*/

/*
const setDeviceSamplingIntervalTTN = async (appId, deviceId, interval) => {
    let appSettings = await getAppSettings('ttn');
    setApiKey(appSettings.settings.fricontrolKey);
    const url = `as/applications/${appId}/devices/${deviceId}/down/replace`;

    let segundos   = interval * 60;
    let hexString  = `0100${segundos.toString(16).padStart(4, '0')}`;
    let interval64 = Buffer.from(hexString, 'hex').toString('base64');

    try { 
        const response = await ttnClient.post(url,  {
            downlinks: [
                {
                    f_port: 2,
                    frm_payload: interval64,
                    confirmed: true,
                }
            ]
        });
        console.log('setDeviceSamplingIntervalTTN', response.data);
        return response.data;
    } catch (error) {
        console.error(`Error: ${error}`);
        throw error;
    }
}
*/

const sendDeviceActionTTN = async (appId, deviceId, action, value = false) => { 
    let appSettings = await getAppSettings('ttn');
    setApiKey(appSettings.settings.fricontrolKey);
    let resp = { success: false } ;
    if(!action)return resp;
    const url = `as/applications/${appId}/devices/${deviceId}/down/replace`;
    let cmdHex   = false

    if (action == "On")cmdHex                   = "A8010901100500000102010100"
    if (action == "Off")cmdHex                  = "A8010901100500000102000100"
    if (action == "defrost")cmdHex              = "A8010901100500000102020200"
    if (action == "keylock")cmdHex              = "A8010901100500000102080800"
    if (action == "keyUnlock")cmdHex            = "A8010901100500000102000800"
    if (action == "activateSaveMode")cmdHex     = "A8010901100500000102404000"
    if (action == "deactivateSaveMode")cmdHex   = "A8010901100500000102004000"
    if (action == "lightOff")cmdHex             = "A8010901100501000102008000"
    if (action == "lightOn")cmdHex              = "A8010901100501000102808000"
    if (action == "muteAlarm")cmdHex            = "A8010901100500000102101000"
    if (action == "uplink")cmdHex               = "0x08 FF"

    if (action == "set")cmdHex                  = "A8010901100340000102" + value + "00"
    
    alert(cmdHex);
    if(cmdHex){
        const payloadBase64 =  Buffer.from(cmdHex, 'hex').toString('base64');
        const downlink = {
            downlinks: [{
                f_port: 2,
                frm_payload: payloadBase64,
                confirmed: true
            }]
        };

        try { 
            const response = await ttnClient.post(url, downlink);
            console.log('send downlink action:', response.data);
            resp.success = true;
            return resp;
        }
        catch (error) {
            console.error(`Error: ${error}`);
            resp.error = error;
            return resp;
        }

    }else return resp; 
}


export { getTTNApp, getAppDevices, getDeviceData, createAppTTN, createGatewayTTN, deleteGatewayTTN, deleteAppTTN, updateGatewayTTN, 
         createDeviceTTN, deleteDeviceTTN, createGatewayKeysTTN, setDeviceSamplingIntervalTTN, setDownlinkDeviceTTN, sendDeviceActionTTN  };
