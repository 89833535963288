import moment from 'moment';
import { loadingController} from '@ionic/vue';

const helpers = {
  async showLoading(opt) {
    const loading = await loadingController.create(opt);
    loading.present();
  },
  timestamp(t){
    const date = new Date(t * 1000);
    const options = { 
      year:    'numeric', 
      month:   'short', 
      day:     '2-digit',
      hour:    '2-digit',
      minute:  '2-digit',
    };
    const timestamp = date.toLocaleString('es-ES', options);
    return timestamp;
  },
  capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return "";
    }
  },
  unixDate(d, type = false) {
    let date = new Date(d);
    if (type) {
      if (type == 'full') return moment(date).format("DD/MM/YYYY HH:mm:ss");
      else if (type == 'date') {
        return moment(date).format("DD/MM/YYYY")
      }
      else if (type == 'time') return moment(date).format("HH:mm:ss")
      else return moment(date).format("DD/MM/YYYY HH:mm:ss");
    } else { return moment(date).format("DD/MM/YYYY HH:mm:ss"); }
  },
  getTimestampDate(d, type = false) {
    let date = new Date(d);
    if (type) {
      if (type == 'full') return moment(date).format("DD/MM/YYYY HH:mm:ss");
      else if (type == 'date') {
        return moment(date).format("DD/MM/YYYY")
      }
      else if (type == 'time') return moment(date).format("HH:mm:ss")
      else return moment(date).format("DD/MM/YYYY HH:mm:ss");
    } else { return moment(date).format("DD/MM/YYYY HH:mm:ss"); }
  },
  getDateFormatFb(d, type = false) {
    if (!d) return "";
    let date = new Date(d.seconds * 1000); // <- Aquí está el cambio
    if (type) {
      if (type == 'full') return moment(date).format("DD/MM/YYYY HH:mm:ss");
      else if (type == 'date') {
        return moment(date).format("DD/MM/YYYY")
      }
      else if (type == 'time') return moment(date).format("HH:mm:ss")
      else return moment(date).format("DD/MM/YYYY HH:mm:ss");
    } else { return moment(date).format("DD/MM/YYYY HH:mm:ss"); }
  },
  toRaw(obj) {
    return JSON.parse(JSON.stringify(obj));
  },
  isBooleanString(string) {
    if (typeof string === 'string' || string instanceof String) {
      switch (string.toLowerCase().trim()) {
        case "true": return true;
        case "false": return true;
        default: return false;
      }
    }
  },
  generateRandomPassword(length = 8) {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  },
  getUnitByParam(param, value) {
    let unit    = "";
    let celcius = ["HY", "LS", "uS", "SdF", "dtE", "FSt", "AFH", "AU2", "AH2","HES", "ALL", "ALu", "SEt", "dP1", "dP2", "dP3", "o3", "oE", "ot"]
    let minutes = ["odS","Ac", "con" , "coF" , "MdF", "dAd", "dSd", "Fdt", "Fnd", "ALd", "EdA", "dot", "doA", "dAo", "nPn", "Ad2", "did"]
    let hours   = ["IdF"]
    if (celcius.includes(param))unit = "°C";
    if (minutes.includes(param))unit = "min";
    if (hours.includes(param))unit = "h";
    //custom
    if (param == "ALc"){ if(value == 0)unit = "(rE)"; else unit = "(Ab)"; }
    if (param == "AoP"){ if(value == 0)unit = "(CL)"; else unit = "(OP)"; }
    if (param == "dPo"){ if(value == 0)unit = "(N)"; else unit = "(Y)"; }
    if (param == "Fnc"){ if(value == 0)unit = "(C_n)"; else if(value == 1)unit = "(C_Y)"; else if(value == 2)unit = "(O_n)"; else if(value == 3)unit = "(O_Y)"; }
    if (param == "Lod"){ if(value == 0)unit = "(P1)"; else if(value == 1)unit = "(P2)"; else if(value == 2)unit = "(P3)"; }
    if (param == "P2P"){ if(value == 0)unit = "(N)"; else unit = "(Y)"; }
    if (param == "P3P"){ if(value == 0)unit = "(N)"; else unit = "(Y)"; }
    if (param == "Pbc"){ unit = "(EEPROM)"; }
    if (param == "rEL"){ unit = "(Firmware)"; }
    if (param == "cF"){ if(value == 0)unit = "ºC"; else unit = "ºF"; }
    return  unit;
  }
};

const install = app => {
  app.config.globalProperties.$h = helpers;
};

export { install as default, helpers as helper };

