<template>
    <div class="p-0 m-0"> 
        <div v-if="layout.weather?.response?.result" class="p-0 ms-3">
            <div>
                <img :src="layout.weather.response?.result?.icon ? require(`../../../assets/images/icon/weather/${layout.weather.response?.result?.icon}.png`) : ''" style="width:125px;" class="weather-icon">
            </div>
            <!--
            <template v-if="layout.weather.response?.result?.iconUrl">
                <img :src="layout.weather.response?.result?.iconUrl" style="width:110px;">
            </template>
            -->
            <div class="fs-1 fw-bold">{{ layout.weather.response?.result?.temp }}<span class="fs-1 align-top">&deg;</span></div>
            <div class="fs-6">{{ layout.weather.response?.result?.description }}</div>
            <div class="fs-4">{{ layout.weather.response?.result?.city }}</div>
        </div>
        <div v-else>
            <div class="text-center mx-auto mt-5">
                <img src="../../../assets/images/theme/loading.gif" style="width: 60px; margin-top: 40px; margin-left: 15px;">
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';
import { useAuthStore } from '@/store';
import { requestDevice, getController } from "../../../model/api.js";

export default defineComponent({
name: 'WeatherComponent',
props: {
  isMobile: Boolean,
},
computed: {
    filteredSondas() {
        return this.layout.sondas.response.result.sondas.sondas.slice(1);
    }
},
components: {},
setup() {
    const authStore         = useAuthStore();
    const { user, profile } = authStore;
    const layout            = ref({ 
                                controllerId : "cuUvFZwvgV8H93PBaUV4",
                                controller: false,
                                weather: false,
                                isRequestInProgress: false, 
                              });

    onMounted(async () => {
        layout.value.controller = await getController(profile.controllerId);

        async function fetchWeather() {
            if (!layout.value.isRequestInProgress) { 
                layout.value.isRequestInProgress = true; 
                layout.value.weather = false;
                try { 
                    layout.value.weather = await requestDevice("https://"+ profile.controllerId +".fricontrolalba.com", "controller-actions/"+profile.controllerId+"/weather:"+layout.value.controller.location.cityId);
                } catch (error) { console.error("Error al realizar la solicitud:", error);
                } finally {  layout.value.isRequestInProgress = false; }            
            }
            setTimeout(fetchWeather, 600000); // 10 minutes
        }
        
        fetchWeather();
        
        onBeforeUnmount(() => {
            clearTimeout(fetchWeather);
        });
    });

  return {user, profile, layout };
},
methods: {},
});
</script>
<style>
/*
@keyframes pulsate {
    0% {transform: scale(1); }
    50% { transform: scale(1.1);}
    100% {transform: scale(1);}
}

.weather-icon {
    animation: pulsate 60s infinite;
}*/
</style>