<template>
    <div class="text-left mt-5 ms-2" v-if="layout.controller">
        <div class="fw-bold fs-3">{{ layout.controller.name }}</div>
        <div v-if="layout.controller.status">
            <div>S/N: <span class="fw-bold friItalic">{{ layout.controller.serialNumber}} </span></div>
            <div class="mt-3">
                Registro continuo: Activo
            </div>
            <div>Intervalo: 5 minutos</div>


            <div class="mt-3">Subscripción: Activa</div>
            <div>Válido hasta 23/12/2024</div>
             <!--template v-if="layout.controller?.status?.disk?.session">
                <div>Sesión: <span class="fw-bold friItalic">{{ layout.controller.status.disk.session.resume }}</span></div>
                <div>Espacio libre: <span class="fw-bold friItalic">
                {{ layout.controller.status.disk.disk.free_space }} ({{ parseInt(100 - parseInt(layout.controller.status.disk.disk.percentage_used)) }}%) </span>
                </div>
                <template v-if="layout.controller.status?.ups?.response?.result?.ups">
                    <div>Batería: <span class="fw-bold">{{ layout.controller.ups.response.result.ups.estado }}... ({{ parseInt(layout.controller.ups.response.result.ups.percent) }}%)</span></div>
                </template>
            </template>-->
            <!--<div>Modelo: <span class="fw-bold friItalic">{{ layout.controller.brand }}</span> (<span class="fw-bold">{{ layout.controller.model }}</span>)</div>-->
            <!--<div v-if="layout.controller?.status?.cpu" style="padding: 0; margin: 0;">
                <div class="inline-block friw-30 text-center br-5 align-top me-1">
                <div class="py-2">
                    <div id="friChartCpuTemp" style="width: 100%; height: 15vh;"></div>
                    <div class="mt-2"><span class="fw-bold fs-4">{{ layout.controller.status.cpu.temperature }}<span class="align-top">&deg;</span></span></div>
                    <div class="fs-6">Temp</div>
                </div>
                </div>
                <div class="inline-block friw-30 text-center br-5  align-top me-1">
                <div class="py-2">
                    <div id="friChartCpu" style="width: 100%; height: 15vh;"></div>
                    <div class="mt-2"><span class="fw-bold fs-4">{{ layout.controller.status.cpu.usage_percentage }}%</span></div>
                    <div class="fs-6">CPU</div>
                </div>
                </div>
                <div class="inline-block friw-30 text-center br-5  align-top" v-if="layout.controller?.status?.ram">
                <div class="py-2">
                    <div id="friChartRam" style="width: 100%; height: 15vh;"></div>
                    <div class="mt-2"><span class="fw-bold fs-4">{{ layout.controller.status.ram.percentage_used }}%</span></div>
                    <div class="fs-6">RAM</div>
                </div>
                </div>
            </div>-->
            <div v-if="layout.controller?.status?.date" class="text-xs friItalic text-left mt-3">Alba v0.2 - {{ layout.helper.getDateFormatFb(layout.controller?.status.date) }}</div>
        </div>
        <div v-else class="mt-5 text-center"><img src="../../../../assets/images/theme/loading.gif" style="width: 80px; margin: 50px auto"></div>
    </div>
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue';
import { useAuthStore } from '@/store';
import { Chart, BarController, BarElement } from 'chart.js';
import { helper as $h } from "../../../../utils/helper.js";

Chart.register(BarController, BarElement );

export default defineComponent({
name: 'ControllerStatusComponent',
props: {
  isMobile:   Boolean,
  controller: Object,
},
components: {   },
setup(props) {
    const authStore         = useAuthStore();
    const { user, profile } = authStore;
    const layout            = ref({ 
                                isMobile:     false,
                                helper:       $h
                              });

  const initTemperatureChart = async () => {
    if(!document.getElementById("friChartCpuTemp"))return;
    document.getElementById("friChartCpuTemp").innerHTML = "";
    const canvasElement = document.createElement('canvas');
    document.getElementById("friChartCpuTemp").appendChild(canvasElement);
    const ctx = canvasElement.getContext('2d');
    const temperature = layout.value.controller.status.cpu.temperature; 
    let backgroundColor;
    if (temperature <= 55) { backgroundColor = 'green';
    } else if (temperature > 55 && temperature <= 75) { backgroundColor = 'orange';
    } else { backgroundColor = 'red'; }
    new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: ['Temperatura CPU'],  // Añade más etiquetas si tienes más datos
                    datasets: [{
                        label: 'Temperatura en ºC',
                        data: [temperature],
                        backgroundColor: backgroundColor,
                    }]
                },
                options: {
                    scales: {
                        y: { display: false, max: 100},
                        x: { display: false}
                    },
                }
            });
  }

  const initCpuChart = async () => {
    if(!document.getElementById("friChartCpu"))return;
    document.getElementById("friChartCpu").innerHTML = "";
    const canvasElement = document.createElement('canvas');
    document.getElementById("friChartCpu").appendChild(canvasElement);
    const ctx = canvasElement.getContext('2d');
    const use = layout.value.controller.status.cpu.usage_percentage;
    let backgroundColor;
    if (use <= 60) { backgroundColor = 'green';
    } else if (use > 60 && use <= 85) { backgroundColor = 'orange';
    } else { backgroundColor = 'red'; }
    new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: ['Uso CPU'],  // Añade más etiquetas si tienes más datos
                    datasets: [{
                        label: 'Uso en %',
                        data: [use],
                        backgroundColor: backgroundColor,
                    }]
                },
                options: {
                    scales: {
                        y: { display: false, max: 100},
                        x: { display: false}
                    },
                }
            });
  }

  const initRamChart = async () => {
    if(!document.getElementById("friChartRam"))return;
    document.getElementById("friChartRam").innerHTML = "";
    const canvasElement = document.createElement('canvas');
    document.getElementById("friChartRam").appendChild(canvasElement);
    const ctx = canvasElement.getContext('2d');
    const use = layout.value.controller.status.ram.percentage_used;
    let backgroundColor;
    if (use <= 60) { backgroundColor = 'green';
    } else if (use > 60 && use <= 85) { backgroundColor = 'orange';
    } else { backgroundColor = 'red'; }
    new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: ['Uso RAM'],  
                    datasets: [{
                        label: 'Uso en %',
                        data: [use],
                        backgroundColor: backgroundColor,
                    }]
                },
                options: {
                    scales: {
                        y: { display: false, max: 100},
                        x: { display: false}
                    },
                }
            });
  }

  onMounted(async () => {
    if(!props.controller || !props.controller.status)return;
    layout.value.controller = props.controller
    setTimeout(async () => {
        await initTemperatureChart();
        await initCpuChart();
        await initRamChart();
    }, 3000);
  });

  return {user, profile, layout};
},
methods: {},
});
</script>