<template>
    <div class="row row-cols-4 g-2 mt-2" style="margin-left: 70px !important;">
        <div class="col px-2 my-0 mb-2" v-for="item in menuItems" :key="item.title" @click="menuAction(item.id); layout.showMenu = false;">
            <div class="card">
                <div class="card-body d-flex flex-column align-items-center friColor1">
                    <i :class="item.icon" style="font-size: 45px;"></i>
                    <h5 class="card-title">{{ item.title }}</h5>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue';
import { useAuthStore } from '@/store';

export default defineComponent({
name: 'MenuConfigComponent',
props: {
  isMobile: Boolean,
},
components: {   },
setup() {
    const authStore         = useAuthStore();
    const { user, profile } = authStore;
    const layout            = ref({ 
                                showMenu:    false,
                                isMobile:    false,
                              });

    const menuItems         = ref([
                                    { id: 'dash',       icon: 'bi bi-house', title: 'Inicio' },
                                    { id: 'controller', icon: 'bi bi-cpu', title: 'Controlador' },
                                    { id: 'devices',    icon: 'bi bi-view-list', title: 'Equipos' },
                                    { id: 'log',        icon: 'bi bi-snow', title: 'Historial' },
                                    //{ id: 'config',     icon: 'bi bi-gear', title: 'Configuración' },
                                    //{ id: 'alerts',     icon: 'bi bi-bell', title: 'Alertas' },
                                    //{ id: 'restart',    icon: 'bi bi-power', title: 'Reiniciar' },
                                ])   
    
    onMounted(async () => {
   
    });

    return {user, profile, layout, menuItems };
},
methods: {
    menuAction(id) {
        if(id === "restart")this.restartOS();
        else this.$emit('menuAction', id);
    },
},
});
</script>
<style>
.card {cursor: pointer;transition: transform 0.2s;width: 100%;max-width: 120px;margin: 0 auto; height: 112px !important; padding-top: 0;border-radius: 12px !important;font-size: 14px; border-color: #c1c0bf !important;}
.card:hover {transform: scale(1.05);}
.card .card-title{font-size: 13px !important;}
</style>