<template>
    <div>
        <div class="account-box is-form is-footerless p-0 m-0" :style="isMobile ? 'border-bottom: none !important;' : ''">
            <template v-if="layout.device">
                <div class="form-head stuck-header" :class="isMobile ? 'py-0' : 'py-3'">
                    <div class="form-head-inner">
                        <div class="left"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5 ms-2' : 'fs-4'">{{  layout.app.name  }} </h4></div>
                        <div class="inline-block align-top fs-6 mt-0" style="margin-top: 4px;">
                            <template v-if="layout.app.logo"><img :src="layout.app.logo" style="height: 50px;" /></template>
                            <template v-else>{{  layout.app.id }}</template>
                        </div>
                    </div>
                </div>
                <div class="pt-2 pb-2 fs-5 friBack1 text-white text-center ps-3"><span class="align-middle" v-if="layout.device && layout.device.typeName">{{ layout.device.typeName }}</span></div>
                <div class="me-3 ms-4" v-if="layout.device">

                    <!--
                    <div v-if="layout.device.status && layout.device.status.msg" class="py-5 friBack4 br-5 text-left ps-3 text-dark mt-2">
                        <div v-if="layout.device.status.msg.hasOwnProperty('TempC_SHT')" class="text-dark">Temperatura: {{ layout.device.status.msg.TempC_SHT }} ºC</div>
                        <div v-if="layout.device.status.msg.hasOwnProperty('Hum_SHT')" class="text-dark">Humedad: {{ layout.device.status.msg.Hum_SHT }} ºC</div>
                        <div v-if="layout.device.status.msg.hasOwnProperty('DOOR_OPEN_STATUS')" class="text-whdarkite">Puerta: {{ layout.device.status.msg.DOOR_OPEN_STATUS ? 'Abierta' : 'Cerrada' }}</div>
                        <div v-if="layout.device.status.msg.hasOwnProperty('BAT_V')" class="text-dark">Batería: {{ layout.device.status.msg.BAT_V }} v</div>
                        <div v-if="layout.device.status.date" class="text-dark text-xs mt-2">{{ layout.helper.getDateFormatFb(layout.device.status.date) }}</div>
                        <pre class="m-5">{{  layout.device.status.msg }}</pre>
                    </div>


                    <div class="fs-6 mt-5">
                        <div class="fw-bold friColor1 cursor-pointer" @click="setDownlink(256)">Downlink 256 ( Sonda 1 )</div>
                        <div class="fw-bold friColor1 cursor-pointer" @click="setDownlink(768)">Downlink 768 ( HY )</div>
                    </div>
                    -->

                    <ion-list class="p-0 mt-0">
                        <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                            <ion-label position="floating" class="friColor1" :class="isMobile ? 'fs-4' : ' fs-5'">ID *</ion-label>
                            <ion-input v-model="layout.device.deviceId" type="text" autocomplete="off" :disabled="true"></ion-input>
                        </ion-item>
                        <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                            <ion-label position="floating" class="friColor1" :class="isMobile ? 'fs-4' : ' fs-5'">Nombre *</ion-label>
                            <ion-input v-model="layout.device.name"  type="text" autocomplete="off"></ion-input>
                        </ion-item>
                        <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                            <ion-label position="floating" class="friColor1" :class="isMobile ? 'fs-4' : ' fs-5'">Dispositivo *</ion-label>
                            <ion-select interface="popover" v-model="layout.device.type" class="pfInputValue" :disabled="true">
                                <ion-select-option value="dht">Sensor de humedad y temperatura (LHT65N)</ion-select-option>
                                <ion-select-option value="dht52">Sensor de humedad y temperatura (LHT52)</ion-select-option>
                                <ion-select-option value="lds02">Contacto magnético para puerta (LDS02)</ion-select-option>
                                <ion-select-option value="aqs01l">Sensor CO2 (AQS01-L)</ion-select-option>
                                <ion-select-option value="xw265k">C15-XW265K</ion-select-option>
                            </ion-select>
                        </ion-item>
                        <template v-if="layout.device.type == 'dht' || layout.device.type  == 'dht52'">
                            <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                <ion-label position="floating" class="friColor1" :class="isMobile ? 'fs-4' : ' fs-5'">Sonda externa *</ion-label>
                                <ion-select interface="popover" v-model="layout.device.externalInput" class="pfInputValue" >
                                    <ion-select-option :value="'none'">Ninguna</ion-select-option>
                                    <ion-select-option :value="'tempProbe'">Sonda temperatura externa (ºC)</ion-select-option>
                                    <!--<ion-select-option :value="'tempLux'">Sonda luminosidad (Lux)</ion-select-option>-->
                                </ion-select>
                            </ion-item>
                        </template>
                        
                        
                        <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                            <ion-label position="floating" class="friColor1" :class="isMobile ? 'fs-4' : ' fs-5'">Intervalo de actualización *</ion-label>
                            <ion-select interface="popover" v-model="layout.device.interval" class="pfInputValue">
                                <ion-select-option v-for="interval in layout.intervals" :key="interval" :value="interval">{{ interval }} minutos</ion-select-option>
                            </ion-select>
                        </ion-item>

                        <ion-row>
                            <ion-col class="m-0 p-0">
                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                    <ion-label position="floating" class="friColor1" :class="isMobile ? 'fs-4' : ' fs-5'">AppEUI *</ion-label>
                                    <ion-input v-model="layout.device.eui"  type="text" autocomplete="off" :disabled="true"></ion-input>
                                </ion-item>
                            </ion-col>
                            <ion-col class="m-0 p-0">
                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                    <ion-label position="floating" class="friColor1" :class="isMobile ? 'fs-4' : ' fs-5'">DevEUI *</ion-label>
                                    <ion-input v-model="layout.device.deveui"  type="text" autocomplete="off" :disabled="true"></ion-input>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                            <ion-label position="floating" class="friColor1" :class="isMobile ? 'fs-4' : ' fs-5'">AppKey *</ion-label>
                            <ion-input v-model="layout.device.key"  type="text" autocomplete="off" :disabled="true"></ion-input>
                        </ion-item>
                        <ion-row>
                        <ion-col class="m-0 p-0">
                            <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                <ion-label position="floating" class="friColor1" :class="isMobile ? 'fs-4' : ' fs-5'">Marca</ion-label>
                                <ion-input v-model="layout.device.brand"  type="text" autocomplete="off"></ion-input>
                            </ion-item>
                        </ion-col>
                        <ion-col class="m-0 p-0">
                            <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                            <ion-label position="floating" class="friColor1" :class="isMobile ? 'fs-4' : ' fs-5'">Modelo</ion-label>
                            <ion-input v-model="layout.device.model"  type="text" autocomplete="off"></ion-input>
                        </ion-item>
                        </ion-col>
                        </ion-row>
                        <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                            <ion-label position="floating" class="friColor1" :class="isMobile ? 'fs-4' : ' fs-5'">Nº de serie</ion-label>
                            <ion-input v-model="layout.device.serial"  type="text" autocomplete="off"></ion-input>
                        </ion-item>
                        <ion-item class="mt-2 border-none" lines="full" style="--ion-item-border-color: #ccc;">
                            <ion-label>Activo</ion-label>
                            <ion-toggle :enable-on-off-labels="true" slot="end" v-model="layout.device.active"></ion-toggle>
                        </ion-item>
                    </ion-list>
                    <div class="pt-5 text-center text-xs3" v-if="layout.device.createdAt">
                        <span v-if="layout.device.createdAt">Creado el {{ layout.helper.getDateFormatFb(layout.device.createdAt) }}</span>
                    </div>
                    <div :style="isMobile ? 'margin-top: 30px; margin-bottom: 100px;' : 'margin-top: 60px; margin-bottom: 50px;'">
                        <div class="d-flex justify-content-center align-items-center fs-5">
                            <!--<div class="pe-4 fw-bold text-danger friOpacity6 cursor-pointer pe-4" @click="removeDevice()">ELIMINAR</div>-->
                            <div class="fw-bold friColor1 text-end float-end cursor-pointer"  @click="saveDevice()">GUARDAR</div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="text-center w-100"  style="margin: 150px 0;">
                    <div class="fw-bold fs-1">Equipos y sensores</div>
                    <div class="mt-2">Espere por favor ...</div>
                    <div class="spinner-border friColor1 mt-5" role="status">
                    <span class="visually-hidden">Cargando ...</span>
                    </div>
                </div>
            </template>
        </div>
    </div>
  </template>
  <script>
  import { defineComponent, onMounted, ref, watch } from "vue";
  import { useAuthStore } from "@/store";
  import { getApp, getAppDevices, updateDevice } from "../../../model/apps.js"; 
  import { helper as $h } from "../../../utils/helper.js";
  import { getDeviceData, setDeviceSamplingIntervalTTN} from "../../../model/ttn.js"; //
  import { useRoute } from 'vue-router'
  import router from "@/router";
  import { IonItem, IonLabel, IonInput, IonList, IonSelect, IonSelectOption, IonRow, IonCol, IonToggle, } from '@ionic/vue'; 
  import { not as $not } from "../../../model/not";
  import { setDownlinkDeviceTTN } from "../../../model/ttn.js";

  export default defineComponent({
    name: 'SemSettings',
    props: {
        isMobile: Boolean,
        appId: String,
        deviceId: String
     },
     components: { IonItem, IonLabel, IonInput, IonList, IonSelect, IonSelectOption, IonRow, IonCol, IonToggle },
    setup(props) {
  
       const authStore      = useAuthStore();
       const layout = ref({ 
                            appId:      null,
                            device:     null,
                            services:   null,
                            alarmBox:   false,
                            deviceId:   null,
                            intervals:    [1, 5, 10, 15, 30, 60], //0, 
                            helper:     $h
                          }) 

        const { user, profile } = authStore;
        const route = useRoute()             
        const initDash = async () => {
         layout.value.app  = await getApp(layout.value.appId)
         let deviceData    = await getAppDevices(layout.value.appId)
         if(deviceData && deviceData?.devices && Object.keys(deviceData.devices).length && layout.value.deviceId)
            layout.value.device         = deviceData.devices[layout.value.deviceId] 
            if(layout.value.device && layout.value.device.deviceId)
                layout.value.device.ttnData = await getDeviceData(layout.value.appId, layout.value.device.deviceId)
        }

        const saveDevice = async () => {
            let validate = true
            let required = ['name', 'eui', 'key', 'deveui', 'type','externalInput']
            required.forEach((field) => {  if(!layout.value.device[field])validate = false })
            if(validate){
            await updateDevice(layout.value.device).then(async () => {
                await setDeviceSamplingIntervalTTN(layout.value.appId, layout.value.device.deviceId, layout.value.device.interval).then(() => {
                    $not.toastApp({ message: 'Se ha actualizado el dispositivo correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, cssClass: 'text-white mt-2 friToast1'})
                })           
                /*io
                if(layout.value.device.type == 'dht' || layout.value.device.type == 'dht52'){
                }else $not.toastApp({ message: 'Se ha actualizado el dispositivo correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, cssClass: 'text-white mt-2 friToast1'})
                */
            })     
            }else $not.toastApp({ message: 'Debe rellenar los parámetros requeridos', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
        }
        
        onMounted( async () => { 
         layout.value.appId     = props.appId
         layout.value.deviceId  = router.currentRoute.value.params.deviceId
         await initDash();
        });

        const setDownlink = async (code) => {
            await setDownlinkDeviceTTN(layout.value.appId, layout.value.deviceId, code).then((response) => {
                alert('Hemos enviado un downlink al dispositivo'+layout.value.deviceId);
                console.log(response);
            }).catch((error) => { console.log(error); });
        }
        
        watch(() => route.query, async (query) => {
            if (query){
                layout.value.deviceId  = router.currentRoute.value.params.deviceId
                await initDash();
            }
        });

        return { layout, user, props, profile, saveDevice, setDownlink }; 
    }
    
  })
  </script>
  <style>
    .friAlarmBoxList{background: url('../../../assets/back/back-alarm.jpg') no-repeat center center fixed; background-size: cover; background-color: #ebebeb; border: none;  }
  </style>