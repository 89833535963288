<template>
    <div class="account-box is-form is-footerless px-0 mx-0" :style="isMobile ? 'border-bottom: none !important;' : ''">
        <div class="form-head stuck-header" :class="isMobile ? 'py-0 ps-3 pe-2' : 'py-3'" v-if="layout.app">
            <div class="form-head-inner">
                <div class="left"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5' : 'fs-4'">{{  layout.app.name }} </h4></div>
                <div class="inline-block align-top fs-6 mt-0" style="margin-top: 4px;">
                <template v-if="layout.app.logo"><img :src="layout.app.logo" style="height: 50px;" /></template>
                <template v-else>{{  layout.app.id }}</template>
            </div>
            </div>
        </div>
        <div v-if="layout.scene?.scene" class="text-center fs-2 mt-5">{{ layout.scene.scene.name }}</div>
        <div v-if="layout.scene?.typeName" class="text-center fs-6">{{ layout.scene.scene.typeName }}</div>

        <div class="d-flex justify-content-center align-items-center fs-5 mt-3" v-if="layout.scene && layout.scene.scene && layout.scene.scene.sceneId">
            <div class="friUserCircleMin me-2 friBack4 cursor-pointer" @click="$router.push({ path: '/site/' + layout.app.id + '/' + layout.scene.scene.sceneId, query: { section:  'settings' } })"><i class="bi bi-sliders text-dark"></i></div>
        </div>


        

        <div class="mt-3" v-if="layout.scene?.scene" >
            <div class="fridge-chamber">
                <div class="camera">
                    <div class="text-center fs-5 pt-5 fw-bold" v-if="layout.scene?.scene && layout.scene.scene.specs.product.type">{{ layout.scene.scene.specs.product.type }}</div>
                    <div class="h">{{ layout.scene.scene.specs.room.height }} m</div>
                    <div class="w">{{ layout.scene.scene.specs.room.width }} m</div>
                    <div class="dw">{{ layout.scene.scene.specs.door.width }} m</div>
                    <div class="dh">{{ layout.scene.scene.specs.door.height }} m</div>
                    <div class="door"></div>
                </div>
            </div>
        </div>

        <div v-if="layout.scene?.scene" class="mx-5 px-5 mb-5 mx-auto" style="max-width: 350px;">
            <div>Dimensiones: <span class="text-end float-end fw-bold">{{ layout.scene.scene.specs.room.width }} x {{ layout.scene.scene.specs.room.height }} x {{ layout.scene.scene.specs.room.length }} m</span></div>
            <div>m2: <span class="text-end float-end fw-bold">{{ (layout.scene.scene.specs.room.width * layout.scene.scene.specs.room.length).toFixed(2) }} m2</span></div>
            <div>m3: <span class="text-end float-end fw-bold">{{ (layout.scene.scene.specs.room.width * layout.scene.scene.specs.room.height * layout.scene.scene.specs.room.length).toFixed(2) }} m3</span></div> 
            <div>Puerta: <span class="text-end float-end fw-bold">{{ layout.scene.scene.specs.door.width }} x {{ layout.scene.scene.specs.door.height }} mm</span></div>
            <div>Espesor: <span class="text-end float-end fw-bold">{{ layout.scene.scene.specs.thickness }} mm</span></div>
        </div>
    </div>
 </template>
 <script>
 import { defineComponent, onMounted, ref, watch } from "vue";  //, onUnmounted
 import { useAuthStore } from "@/store";
 import { add } from 'ionicons/icons';
 import router from "@/router";
 import { useRoute } from 'vue-router'
 import { getApp, getScene } from "../../../model/apps.js"; 

 export default defineComponent({
   name: 'sitePlace',
   props: {
       isMobile: Boolean,
       appId: String,
       sceneId: String,
    },
    components: { },
   setup(props) {
 
      const authStore      = useAuthStore();
      const route          = useRoute()
      const layout         = ref({ 
                               appId:       null,
                               sceneId:     null,
                               scene:       null,
                           }) 

      const { user, profile } = authStore;

      onMounted( async () => { 
        layout.value.appId     = router.currentRoute.value.params.appId;
        layout.value.sceneId   = router.currentRoute.value.params.SceneId;
        layout.value.app       = await getApp(layout.value.appId)
        if(layout.value.sceneId)    
            layout.value.scene     = await getScene(layout.value.appId, layout.value.sceneId)
      });
 
       watch(() => route.query, async (query) => {
            if (query){
                layout.value.query     = query; 
                layout.value.section   = query.section;
                layout.value.appId     = router.currentRoute.value.params.appId;
                layout.value.sceneId   = router.currentRoute.value.params.SceneId;
            }
        });

       return { layout, user, profile, add, props };
   }
   
 })
 </script>
 <style scoped>
 .fridge-chamber { display: flex; flex-direction: column; align-items: center; }
 .camera { margin: 20px; position: relative; background: #f0f0f0; width: 80%; height: 200px; border: 5px solid black; max-width: 390px; }
 .camera .door { position: absolute; top: 30px; width: 20px; height: 50%; background: darkgray; right: 0; }
 .camera .dw { position: absolute; bottom: 49px; right: 6px; text-align: right; font-size: 12px; font-weight: bold; }
 .camera .dh { position: absolute; bottom: 85px; right: 7px; text-align: right; font-size: 12px; font-weight: bold; transform: rotate(-90deg); transform-origin: 0 0; }
 .camera .h { position: absolute; top: 36%; left: 30px; text-align: left; font-size: 20px; font-weight: bold; transform: rotate(90deg); transform-origin: 0 0; } 
 .camera .w { position: absolute; bottom: 0; left: 45%; text-align: left; font-size: 20px; font-weight: bold; }
 </style>