<template>
    <div class="news-ticker" v-if="layout.showTicker">
        <div class="news-ticker-content">
            <span v-for="(news, index) in layout.newsContent.alerts" :key="index" class="pe-3" :class="news.type=='alert' ? 'news-ticker-alert': ''" >
                <i class="bi me-1 ticker-icon" :class="news.icon"></i>
                 {{ news.alert }} <span v-if="index < layout.newsContent.alerts.length - 1" class="ps-3 fw-bold">&middot;</span>
            </span>  
        </div>    
    </div>     
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue'; //onBeforeUnmount
import { useAuthStore } from '@/store';


export default defineComponent({
name: 'TickerComponent',
props: {
  isMobile: Boolean,
},
components: {   },
setup() {
    const authStore         = useAuthStore();
    const { user, profile } = authStore;
    const layout            = ref({ 
                                showTicker:  false,
                                newsContent: {
                                    alerts: [
                                        { type: "alert", icon: "bi-exclamation-triangle-fill", alert: "Alarma por alta temperatura" },
                                        { type: "alert", icon: "bi-door-open", alert: "Puerta abierta" },
                                        { type: "info",  icon: "bi-thermometer-snow", alert: "Desescarche en curso ..." },
                                    ],
                                },
                            });

  onMounted(async () => {
    /*const TickerInterval   = setInterval(() => { 
        if (layout.value.showTicker)layout.value.showTicker = false;
        else layout.value.showTicker = true;
    }, 600000);
    onBeforeUnmount(() => {
        clearInterval(TickerInterval);
    });*/
  });

  return {user, profile, layout  };
},
methods: {},
});
</script>
<style>
/* ticker */
.news-ticker {
    width: 100%;
    background-color: #f7f8f9; 
    overflow: hidden; 
    height: 40px;
    display: block;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 9999;
}

.ticker-icon {
    font-size: 22px;
    opacity: 0.7;
}

.news-ticker-content {
    display: inline-block;
    white-space: nowrap; 
    padding-left: 100%; 
    animation: ticker 15s linear infinite;
    color: #000;
    line-height: 40px;
    font-size: 18px;
}

.news-ticker-alert {
    color: #ef0505; 
}

@keyframes ticker {
    0% { transform: translateX(0);}
    100% {transform: translateX(-100%); }
}
</style>