<template>
    <div class="account-box is-form is-footerless p-0 m-0 mb-5" :style="isMobile ? 'border-bottom: none !important;' : ''">
        <template v-if="layout.client">
            <div class="text-center"><h4 :class="isMobile ? 'fs-3 my-5 ms-2' : 'fs-4 my-5'">{{ layout.client.first + " " + layout.client.last }}</h4></div>
            <div>
                <div class="py-2 fs-5 friBack1 text-white text-center"><span class="align-middle ">Documentos</span></div>
                <!--<div class="d-flex justify-content-center align-items-center fs-5 mt-5 mb-2"><div class="friUserCircle friBack4 cursor-pointer"><i class="bi bi-plus-lg text-dark"></i></div></div>-->
            </div>
            <div class="py-5">

                <template v-if="layout.client.odoo?.orders && layout.client.odoo?.orders?.orders">
                    <ion-list class="mx-5" v-if="layout.client.odoo?.orders.orders.length">
                        <ion-item style="--ion-item-border-color: #ccc;" v-for="(order, orderId) in layout.client.odoo.orders.orders" :key="orderId" class="cursor-pointer">
                            <ion-label>
                                <h2 class="fs-5">{{ order.name }} <span class="text-xs3">(ID:{{ order.id  }})</span></h2>
                                <template v-if="order?.lines && order?.lines.length">
                                    <div v-for="(line, lineId) in order.lines" :key="lineId" class="text-xs3">{{ line.name }}</div>
                                </template>
                                <div class="text-xs3">Orden de venta (<template v-if="order.state && order.state == 'sale'">Confirmada</template><template v-else>Borrador</template>)</div>
                                <div class="text-xs3" v-if="order?.date_order">{{ order?.date_order }}</div>
                            </ion-label>
                            <span class="text-end float-end friColor1">
                                <div class="text-xs3">{{ parseFloat(order.amount_untaxed).toFixed(2) }} &euro;</div>
                                <div class="text-xs3">{{ parseFloat(order.amount_tax).toFixed(2) }} &euro;</div>
                                {{ parseFloat(order.amount_total).toFixed(2) }} &euro;
                            </span>
                        </ion-item>
                    </ion-list>
                    <!--<div v-else class="text-center mt-5">No dispone de pedidos asociados.</div>-->
                </template>

                <template v-if="layout.client.odoo?.invoices && layout.client.odoo?.invoices?.invoices">
                    <ion-list class="mx-5" v-if="layout.client.odoo?.invoices.invoices.length">
                        <ion-item style="--ion-item-border-color: #ccc;" v-for="(invoice, invoiceId) in layout.client.odoo.invoices.invoices" :key="invoiceId" class="cursor-pointer">
                            <ion-label>
                                <h2 class="fs-5">{{ invoice.name }} <span class="text-xs3">(ID:{{ invoice.id  }})</span></h2>
                                <template v-if="invoice?.lines && invoice?.lines.length">
                                    <div v-for="(line, lineId) in invoice.lines" :key="lineId" class="text-xs3">{{ line.name }}</div>
                                </template>
                                <div class="text-xs3" v-if="invoice?.partner_id">{{ invoice?.partner_id[1] }}</div>
                                <div class="text-xs3">Factura (<template v-if="invoice.state && invoice.state == 'posted'">Confirmada</template><template v-else>Borrador</template>)</div>
                                <div class="text-xs3" v-if="invoice?.invoice_date">{{ invoice?.invoice_date }}</div>
                                <div class="text-xs3" v-if="invoice?.invoice_date_due">Vencimiento: {{ invoice?.invoice_date_due }}</div>
                                <div class="text-xs3" v-if="invoice?.invoice_origin">Origen: {{ invoice?.invoice_origin }}</div>
                                <div class="text-xs3" v-if="invoice?.journal_id">Cuenta: {{ invoice?.journal_id[1] }}</div>
                                <div class="text-xs3" v-if="invoice?.payment_state">
                                    <template v-if="invoice.payment_state == 'not_paid'"><span class="friColor3">No pagada</span></template>
                                    <template v-else>{{ invoice?.payment_state }}</template> 
                                </div>

                            </ion-label>
                            <span class="text-end float-end friColor1">
                                <div class="text-xs3">{{ parseFloat(invoice.amount_untaxed).toFixed(2) }} &euro;</div>
                                <div class="text-xs3">{{ parseFloat(invoice.amount_tax).toFixed(2) }} &euro;</div>
                                {{ parseFloat(invoice.amount_total).toFixed(2) }} &euro;
                                <div class="text-xs3 friColor3">{{ parseFloat(invoice.amount_residual).toFixed(2) }} &euro;</div>
                            </span>
                        </ion-item>
                    </ion-list>
                    <!--<div v-else class="text-center mt-5">No dispone de facturas asociadas.</div>-->
                </template>


                <template v-if="layout.client.odoo?.payments && layout.client.odoo?.payments?.payments">
                    <ion-list class="mx-5" v-if="layout.client.odoo?.payments.payments.length">
                        <ion-item style="--ion-item-border-color: #ccc;" v-for="(payment, paymentId) in layout.client.odoo.payments.payments" :key="paymentId" class="cursor-pointer">
                            <ion-label>
                                <h2 class="fs-5">{{ payment.name }} <span class="text-xs3">(ID:{{ payment.id  }})</span></h2>
                                <div class="text-xs3" v-if="payment.state">Pago (<template v-if="payment.state == 'cancel'">Cancelado</template><template v-else-if="payment.state == 'draft'">Borrador</template><template v-else-if="payment.state == 'posted'">Publicado</template><template v-else>{{ payment.state }}</template>)</div>
                                <div class="text-xs3" v-if="payment?.date">Fecha: {{ payment?.date }}</div>
                                <div class="text-xs3" v-if="payment?.partner_id">{{ payment?.partner_id[1] }}</div>
                                <div class="text-xs3" v-if="payment?.payment_date">{{ payment?.payment_date }}</div>
                                <div class="text-xs3" v-if="payment?.payment_method_id">Método de pago: {{ payment?.payment_method_id[1] }} <span class="text-xs">({{ payment?.payment_method_id[0] }})</span></div>
                                <div class="text-xs3" v-if="payment?.journal_id">Cuenta: {{ payment?.journal_id[1] }}</div>
                                <div class="text-xs3" v-if="payment?.state">
                                    <template v-if="payment.payment_state == 'not_paid'"><span class="friColor3">NO PAGADO</span></template>
                                    <template v-else-if="payment.payment_state == 'in_payment'"><span class="friColor2">EN PROCESO DE PAGO</span></template>
                                    <template v-else>{{ payment.payment_state }}</template> 
                                </div>
                            </ion-label>
                            <span class="text-end float-end friColor1 fs-1">
                                <div class="text-xs3"></div>
                                {{ parseFloat(payment.amount).toFixed(2) }} &euro;
                            </span>
                        </ion-item>
                    </ion-list>
                    <!--<div v-else class="text-center mt-5">No dispone de pagos asociados.</div>-->
                </template>


                <div v-if="!layout.client.odoo?.invoices || !layout.client.odoo?.invoices?.invoices || !layout.client.odoo?.orders || !layout.client.odoo?.orders?.orders || !layout.client.odoo?.payments || !layout.client.odoo?.payments?.payments"
                    class="text-center mt-5"><div class="spinner-border friColor1 mt-5" role="status"><span class="visually-hidden">Cargando ...</span></div></div>

                <!---->
                <pre v-if="layout.client.odoo?.payments && layout.client.odoo?.payments?.payments">{{ layout.client.odoo.payments.payments }}</pre>

            </div>
        </template>
        <template v-else>
            <div class="text-center w-100"  style="margin: 150px 0;">
                <div class="fw-bold fs-4">Documentos</div>
                <div class="mt-2">Espere por favor ...</div>
                <div class="spinner-border friColor1 mt-5" role="status">
                <span class="visually-hidden">Cargando ...</span>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import { useAuthStore } from "@/store";
import { helper as $h } from "../../../utils/helper.js";
import { getClient } from "../../../model/api.js"; 
import { OdooReq } from "../../../model/odoo.js";

export default defineComponent({
name: 'clientDocuments',
props: {
    isMobile: Boolean,
    clientId: String
},
components: {  }, 
setup(props) {

    const authStore     = useAuthStore();
    const layout        = ref({ 
                            client:    null,
                            helper:    $h,
                            })

    const { user, profile } = authStore;

    const initDocuments = async () => {
        layout.value.client      = await getClient(props.clientId)

        layout.value.client.odoo = {}
        if (layout.value.client?.odooId){

          let odooOrders = await OdooReq('client-orders', { id: layout.value.client.odooId })
          if(!odooOrders?.error)layout.value.client.odoo['orders'] = odooOrders.response.data

          let odooInvoices = await OdooReq('client-invoices', { id: layout.value.client.odooId })
          if(!odooInvoices?.error)layout.value.client.odoo['invoices'] = odooInvoices.response.data
           
          let odooPayments = await OdooReq('client-payments', { id: layout.value.client.odooId })
          if(!odooPayments?.error)layout.value.client.odoo['payments'] = odooPayments.response.data

        }

    }

    onMounted( async () => { 
        await initDocuments();
    });
    
    return { layout, user, props, profile };
}

})
</script>