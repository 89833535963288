import { _firebase } from "./firebase.js";
import { setDoc, doc, serverTimestamp, getDoc, deleteDoc  } from "firebase/firestore";  
import { getApp } from "../model/apps.js";
import axios from "axios";
const db   =  _firebase.firestore() 

const getAlarmsType = async (opt = {}) => {
    let alarmsType = [
        { code: 'AH0001', name: 'Alta Temperatura', description: 'Temperatura alta detectada', param: "max",  unit: "°C", icon: "bi-graph-up-arrow",  deviceType: ["dht", "dht52"] ,  max: "-5",  warning: "10", notify: "15", active: true, paramSensor: "TempC_SHT", diff: 0 },
        { code: 'AH0002', name: 'Baja Temperatura', description: 'Temperatura baja detectada', param: "min",  unit: "°C", icon: "bi-graph-down-arrow",  deviceType: ["dht", "dht52"] ,  min: "-25", warning: "10", notify: "15", active: true, paramSensor: "TempC_SHT", diff: 0 },
        { code: 'AH0003', name: 'Alta Humedad',     description: 'Humedad alta detectada',     param: "maxH", unit: "%", icon: "bi-graph-up-arrow",   deviceType: ["dht", "dht52"] ,  maxH: "80",  warning: "10", notify: "15", active: true, paramSensor: "Hum_SHT" , diff: 0 },
        { code: 'AH0004', name: 'Baja Humedad',     description: 'Humedad baja detectada',     param: "minH", unit: "%", icon: "bi-graph-down-arrow",   deviceType: ["dht", "dht52"] ,  minH: "60",  warning: "10", notify: "15", active: true, paramSensor: "Hum_SHT", diff: 0 },
        { code: 'AH0005', name: 'Batería Baja',     description: 'Batería baja detectada',     param: "minB", unit: "v", icon: "bi-battery-half",   deviceType: ["dht", "dht52","lds02"] ,  minB: "2",  warning: "10", notify: "15", active: true, paramSensor: "BatV" , diff: 0 },
        { code: 'AH0006', name: 'Puerta abierta de forma prolongada',   description: 'Puerta abierta detectada durante un período de tiempo prolongado', param: "openDoor", unit: "m", icon: "bi-door-open", deviceType: ["lds02"] , openDoor: "5", warning: "5", notify: "10", active: true, paramSensor: "DOOR_OPEN_STATUS", diff: false },
    ];
    if (opt.type)alarmsType = alarmsType.filter((alarm) => alarm.deviceType.includes(opt.type));
    return alarmsType;
}

const createAlarm = async (appId, deviceId, alarm) => {
    alarm.createdAt = serverTimestamp();
    alarm.updatedAt = serverTimestamp();
    alarm.deleted   = false;
    alarm.users     = []
    let deleteParams = ["deviceType", "loading"];
    deleteParams.forEach((param) => delete alarm[param]);
    /* format param */
    if(alarm[alarm.param] && alarm.param == 'max' || alarm.param == 'min' || alarm.param == 'maxH' || alarm.param == 'minH' || alarm.param == 'minB')alarm[alarm.param] = parseFloat(alarm[alarm.param]).toFixed(1);
    await setDoc(doc(db, "apps", appId.toString(), "devices", deviceId.toString(), "alarms", alarm.code), alarm);

    //create client notification
    let appData = await getApp(appId);
    if(appData && appData.clientId){
        let users = [{
            userId:     appData.clientId,
            discard:    true,
            isClient:   true,
            notify:     {
                            app:      true,
                            whatsapp: true,
                            email:    true,
                            sms:      true,
                        }
        }]
        await updateAlarm(appId, deviceId, alarm.code, { users: users })
    }
    //
    return { success: true };
}

const getAlarm = async (appId, deviceId, alarmId) => {
    let resp      = {};
    let alarmRef  = doc(db, "apps", appId.toString(), "devices", deviceId.toString(), "alarms", alarmId.toString());
    let alarmSnap = await getDoc(alarmRef);
    if (alarmSnap.exists()) {
        resp = alarmSnap.data();
        resp.id = alarmSnap.id;
    }
    return resp;
}

const removeAlarm = async (appId, deviceId, alarmId) => {
    const alarmRef = doc(db, "apps", appId.toString(), "devices", deviceId.toString(), "alarms", alarmId.toString());
    try {
        await deleteDoc(alarmRef);
        return { success: true };
    } catch (error) {
        console.error("Error removing document: ", error);
        return { success: false, error: error };
    }
}

const updateAlarm = async (appId, deviceId, alarmId, alarm) => {
    let resp = { success: false };
    alarm.createdAt = serverTimestamp();
    alarm.updatedAt = serverTimestamp();
    let docRef = doc(db, "apps", appId.toString(), "devices", deviceId.toString(), "alarms", alarmId.toString());
    try {
        await setDoc(docRef, alarm, { merge: true });
        resp.success = true;
    } catch (error) { resp.error = error; console.log('error', error) }
    return resp;
 }

 const discardAlarm = async (appId, deviceId) => {
    let resp = { success: false };
    let alarmRef = doc(db, "apps", appId.toString(), "devices", deviceId.toString());
    try { await setDoc(alarmRef, { status: { alarm: { discard: true, status: false } } }, { merge: true });
    } catch (error) { console.error("Error discard alarm:", error); }
    return resp;
}
    

const getSQLAlarm = async (options = {}) => {
    let resp = { success: false, alarm: {} };
    try {
        let queryParams = new URLSearchParams(options).toString();
        let response    = await axios.get(`https://iot.fricontrolalba.com/webhook/alarm?${queryParams}`, { headers: {"Content-Type": "application/json" } });
        if (response.data) {
            resp.success = true;
            resp.alarm    = response.data;
        }
    } catch (error) {
        console.error("Error al obtener logs:", error);
    }
    return resp;
};


export { getAlarmsType, createAlarm, getAlarm, removeAlarm, updateAlarm, discardAlarm, getSQLAlarm };