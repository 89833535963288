 <template>
    <div class="frilockPage ps-2">
        <div><i class="bi bi-exclamation-circle-fill text-white"></i></div>
        <div v-if="profile.isClient" class="text-white fs-2">Dispositivo inactivo<span class="text-white">Contacte con Fricontrol</span></div>
        <div v-else class="text-white">Dispositivo inactivo<span class="text-white">Contacte con su administrador</span></div>
    </div>
  </template>
  <script>
  import { defineComponent, onMounted, ref  } from "vue"; 
  import { useAuthStore } from "@/store";
  import { helper as $h } from "../../../utils/helper.js";

  export default defineComponent({
    name: 'inactiveUtils',
    props: {},
    components: { },
    setup(props) {
  
       const authStore  = useAuthStore();
       const layout = ref({ 
                            helper:     $h
                          }) 

       const { user, profile } = authStore;

       onMounted( async () => { 
         
       });

        return { layout, user, props, profile };
    }
    
  })
  </script>