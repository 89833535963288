<template>
    <div class="account-box is-form is-footerless p-0 m-0 mb-5" :style="isMobile ? 'border-bottom: none !important;' : ''">
        <template v-if="layout.client">
            <div class="text-center"><h4 :class="isMobile ? 'fs-3 my-5 ms-2' : 'fs-4 my-5'">{{ layout.client.first + " " + layout.client.last }}</h4></div>
            <div :style="isMobile ? '' : 'padding-bottom: 100px;'">
                <div class="py-2 fs-5 friBack1 text-white text-center"><span class="align-middle ">Suscripciones</span></div>
                <template v-if="layout.client.stripeId">
                    <div class="mx-5 my-4" style="padding-bottom: 100px;">
                        <template v-if="layout.stripe.client">
                            <table class="table table-borderless table-sm text-left">
                                <tr>
                                    <td class="text-xs3 friColor1 pb-4" colspan="2">
                                        <div class="p-0 m-0 fs-5 fw-bold">Cliente <span class="text-xs3">({{ layout.stripe.client.id }})</span></div>
                                        <div class="p-0 m-0">{{ layout.stripe.client.name }}</div>
                                        <div class="p-0 m-0">{{ layout.stripe.client.email }}</div>
                                        <div class="p-0 m-0">{{ layout.stripe.client.phone }}</div>
                                        <div class="p-0 m-0">{{ layout.stripe.client.address?.line1 }}</div>
                                        <div class="p-0 m-0">{{ layout.stripe.client.address?.city }} ({{ layout.stripe.client.address?.postal_code }})</div>
                                        <div class="p-0 m-0">{{ layout.stripe.client.address?.state }} ({{ layout.stripe.client.address?.country }})</div>
                                    </td>
                                    <td class="fs-6 text-right pb-4 pt-5"><i class="cursor-pointer bi bi-pencil" :class="isMobile ? 'fs-4' : 'fs-5'"></i></td>
                                </tr>
                                <tr>
                                    <td class="text-xs3 friColor1 pb-4" colspan="2">
                                        <div class="p-0 m-0 fs-5 fw-bold">Facturación</div>
                                        <div class="p-0 m-0 pt-2">{{ layout.stripe.client.shipping.name }}</div>
                                        <div class="p-0 m-0">{{ layout.stripe.client.shipping.phone }}</div>
                                        <div class="p-0 m-0">{{ layout.stripe.client.shipping.address?.line1 }}</div>
                                        <div class="p-0 m-0">{{ layout.stripe.client.shipping.address?.city }} ({{ layout.stripe.client.shipping.address?.postal_code }})</div>
                                        <div class="p-0 m-0">{{ layout.stripe.client.shipping.address?.state }} ({{ layout.stripe.client.shipping.address?.country }})</div>
                                    </td>
                                    <td class="fs-6 text-right pb-4 pt-5"><i class="cursor-pointer bi bi-pencil" :class="isMobile ? 'fs-4' : 'fs-5'"></i></td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="border: none !important;">
                                        <div class="p-0 m-0 fs-5 fw-bold mt-2 align-middle">
                                            Métodos de pago  <span class="text-xs3" v-if="layout.stripe.methods && layout.stripe.methods?.payment_methods">({{ Object.keys(layout.stripe.methods.payment_methods).length }})</span>
                                            <span class="text-end float-end align-middle" v-if="layout.stripe.createMethodLink && !layout.stripe.methods?.payment_methods">
                                                <div class="friUserCircleMin2 friBack4 cursor-pointer" @click="goToUrl(layout.stripe.createMethodLink)"><i class="bi bi-plus-lg text-dark"></i></div>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <template v-if="layout.stripe.methods">
                                    <template v-if="layout.stripe.methods?.payment_methods">
                                        <tr v-for="(method, methodId) in layout.stripe.methods.payment_methods" :key="methodId">
                                            <td colspan="2" class="text-xs3 friColor1 pb-3">
                                                <div class="p-0 m-0">{{ method?.card.display_brand }}</div>
                                                <div class="p-0 m-0">**** **** **** {{ method?.card.last4 }}</div>
                                                <div class="p-0 m-0">{{ method?.card.exp_month }} / {{ method?.card.exp_year }}</div>
                                            </td>
                                            <td class="fs-6 pb-3 text-right">
                                                <i class="cursor-pointer bi bi-pencil me-2" :class="isMobile ? 'fs-4' : 'fs-5'"></i>
                                                <i class="cursor-pointer bi bi-trash" :class="isMobile ? 'fs-4' : 'fs-5'"></i>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else><tr><td colspan="3" class="pt-0 mt-0">No hay métodos de pago</td></tr></template>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td colspan="3" class="pt-0 mt-0">
                                            <div class="text-center w-100 pb-2">
                                                <div class="spinner-border friColor1 mt-2" role="status">
                                                    <span class="visually-hidden">Cargando ...</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                                <tr>
                                    <td colspan="3" style="border: none !important;">
                                        <div class="p-0 m-0 fs-5 fw-bold mt-2 align-middle">
                                            Suscripciones <span class="text-xs3" v-if="layout.stripe.subscriptions?.suscriptions && layout.stripe.subscriptions?.suscriptions.data">({{ layout.stripe.subscriptions.suscriptions.data.length }})</span>
                                            <span class="text-end float-end align-middle">
                                                <div class="friUserCircleMin2 friBack4 cursor-pointer"><i class="bi bi-plus-lg text-dark" @click="layout.addPlan = true"></i></div>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <template v-if="layout.stripe.subscriptions">
                                    <template v-if="layout.stripe.subscriptions?.suscriptions && layout.stripe.subscriptions?.suscriptions.data">
                                        <tr v-for="(subscription, subscriptionId) in layout.stripe.subscriptions.suscriptions.data" :key="subscriptionId">
                                            <td colspan="3" class="text-xs3 friColor1 pb-3">
                                                <div class="p-5 friBack4 br-5">
                                                    <div class="p-0 m-0" v-if="subscription?.description">{{ subscription?.description }}</div>
                                                    <div class="p-0 m-0" v-if="subscription.start_date_txt">Iniciada el {{ subscription.start_date_txt }}</div>
                                                    <div class="p-0 m-0" v-if="subscription?.ended_at">Finaliza el {{ subscription?.ended_at}}</div>
                                                    <div class="p-0 m-0" v-if="subscription?.plan.interval">
                                                        <template v-if="subscription?.plan.interval == 'month'">Mensual</template>
                                                        <template v-else>{{ subscription?.plan.interval }}</template>
                                                    </div>
                                                    <div class="p-0 m-0" v-if="subscription?.current_period_start_txt">
                                                        <div class="p-0 m-0 fw-bold text-xs3">Periodo actual:</div>
                                                        <div class="p-0 m-0">{{ subscription?.current_period_start_txt }} / <span v-if="subscription?.current_period_end_txt">{{ subscription?.current_period_end_txt }}</span></div>
                                                    </div>
                                                    <template v-if="subscription?.items?.data">
                                                        <div class="p-0 m-0 fw-bold text-xs3">Productos:</div>
                                                        <div class="p-0 m-0" v-for="(item, itemId) in subscription.items.data" :key="itemId">
                                                            {{ item.quantity }} x <template v-if="item.price.product_data">{{ item.price.product_data.name }}</template> {{ parseFloat(item?.price?.unit_amount_decimal/100).toFixed(2) }} {{ item.price.currency }}
                                                        </div>
                                                    </template>
                                                    <div class="p-0 m-0">
                                                        <div class="p-0 m-0 fw-bold text-xs3">Total: <span class="text-xs3 friColor1">{{ parseFloat(subscription?.plan.amount_decimal/100).toFixed(2) }} {{ subscription.plan.currency }}</span></div>
                                                    </div>
                                                    <div class="p-0 m-0" v-if="subscription?.default_payment_method_data">
                                                        <div class="p-0 m-0 fw-bold text-xs3">Método de pago:</div>
                                                        {{ subscription?.default_payment_method_data.card.brand }}  ****{{ subscription?.default_payment_method_data.card.last4 }}
                                                    </div>
                                                    <div class="p-0 m-0" v-if="subscription?.collection_method && subscription?.collection_method == 'charge_automatically'">Recurrente automático</div>
                                                    <!--<pre>{{ subscription }}</pre>-->
                                                </div>
                                                <div class="p-0 m-0 mt-3" v-if="subscription?.latest_invoice_data">
                                                    <div class="p-0 m-0 fw-bold text-xs3">Última factura: <span class="friColor1">{{ subscription?.latest_invoice_data.number }}</span></div>
                                                    <div class="p-0 m-0" v-if="subscription?.latest_invoice_data.created_txt">Fecha: {{ subscription?.latest_invoice_data.created_txt }}</div>
                                                    <div class="p-0 m-0" v-if="subscription?.latest_invoice_data.due_date">Vencimiento: {{ subscription?.latest_invoice_data.due_date }}</div>
                                                    <div class="p-0 m-0" v-if="subscription?.latest_invoice_data.total">
                                                        {{ parseFloat(subscription?.latest_invoice_data.total/100).toFixed(2) }} {{ subscription?.latest_invoice_data.currency }}
                                                        <span class="fw-bold" v-if="subscription?.latest_invoice_data.status == 'paid'">Pagada</span>
                                                        <span v-else>{{ subscription?.latest_invoice_data.status }}</span>
                                                    </div>
                                                    <div v-if="subscription.latest_invoice_data.invoice_pdf" class="cursor-pointer" @click="goToUrl(subscription.latest_invoice_data.invoice_pdf)">
                                                        <span class="friColor1 fw-bold">Descargar</span>
                                                    </div>
                                                    <!--<pre>{{ subscription?.latest_invoice_data  }}</pre>-->
                                                </div>
                                            </td>
                                            <!--
                                            <td class="fs-6 pb-3 text-right align-top mt-5 pt-5">
                                                <i class="cursor-pointer bi bi-pencil me-2" :class="isMobile ? 'fs-4' : 'fs-5'"></i>
                                                <i class="cursor-pointer bi bi-trash" :class="isMobile ? 'fs-4' : 'fs-5'"></i>
                                            </td>
                                            -->
                                        </tr>
                                    </template>
                                    <template v-else><tr><td colspan="3" class="pt-0 mt-0">No hay suscripciones</td></tr></template>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td colspan="3" class="pt-0 mt-0">
                                            <div class="text-center w-100 pb-2">
                                                <div class="spinner-border friColor1 mt-2" role="status">
                                                    <span class="visually-hidden">Cargando ...</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                                <tr>
                                    <td colspan="3" style="border: none !important;">
                                        <div class="p-0 m-0 fs-5 fw-bold mt-2 align-middle">
                                            Facturas <span class="text-xs3" v-if="layout.stripe.invoices && layout.stripe.invoices.length">({{ layout.stripe.invoices.length }})</span>
                                        </div>
                                    </td>
                                </tr>
                                <template v-if="layout.stripe.invoices">
                                    <template v-if="layout.stripe.invoices && layout.stripe.invoices.length">
                                        <tr v-for="(invoice, invoiceId) in layout.stripe.invoices" :key="invoiceId">
                                            <td colspan="2" class="text-xs3 friColor1 pb-3">
                                                <div class="p-0 m-0 fw-bold">{{ invoice.number }}</div>
                                                <div class="p-0 m-0">{{ invoice.created_txt }}</div>
                                                <div class="p-0 m-0">{{ invoice.due_date }}</div>
                                                <div class="p-0 m-0">{{ parseFloat(invoice.total/100).toFixed(2) }} {{ invoice.currency }}</div>
                                                <div class="p-0 m-0" v-if="invoice.status">
                                                    <span class="friColor1 fw-bold" v-if="invoice.status == 'paid'">Pagada</span>
                                                    <span v-else>{{ invoice.status }}</span>
                                                </div>
                                            </td>
                                            <td class="fs-6 pb-3 text-right align-top mt-5 pt-5">
                                                <i class="cursor-pointer bi bi-file-earmark-pdf me-2" :class="isMobile ? 'fs-3' : 'fs-4'" v-if="invoice.invoice_pdf" @click="goToUrl(invoice.invoice_pdf)"></i>
                                            </td>
                                        </tr>
                                        <!--<pre>{{ layout.stripe.invoices }}</pre>-->
                                    </template>
                                    <template v-else><tr><td colspan="3" class="pt-0 mt-0">No hay facturas</td></tr></template>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td colspan="3" class="pt-0 mt-0">
                                            <div class="text-center w-100 pb-2">
                                                <div class="spinner-border friColor1 mt-2" role="status">
                                                    <span class="visually-hidden">Cargando ...</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </table>
                        </template>
                        <template v-else>
                            <div class="text-center w-100"  style="margin: 150px 0;">
                                <div class="spinner-border friColor1 mt-5" role="status">
                                    <span class="visually-hidden">Cargando ...</span>
                                </div>
                            </div>
                        </template>
                    </div>

                    <!--
                    <template v-if="layout.plans && layout.plans?.plans.length">
                    <ion-list class="mx-5">
                        <ion-item  style="--ion-item-border-color: #ccc;" v-for="(plan, planId) in layout.plans.plans" :key="planId" class="cursor-pointer" @click="$router.push({ path: '/plan/' + layout.client.id + '/' + plan.id, query: { section:  'dashboard' } })">
                            <ion-label>
                                <h2 class="fs-5">{{ plan.name }}</h2>
                                <div class="text-xs3">{{ plan?.description }}</div>
                                <div class="text-xs3">Finaliza el {{ plan?.period.endAt.date }}</div>
                            </ion-label>
                            <span class="text-end float-end friColor1">Activa</span>
                        </ion-item>
                    </ion-list>
                    </template>
                    <div v-else class="text-center mt-5">No dispone de suscripciones asociadas.</div>
                    <template v-if="layout.client.odoo?.subscriptions && layout.client.odoo?.subscriptions?.orders">
                        <template v-if="layout.client.odoo?.subscriptions.orders.length">
                            <ion-list class="mx-5 mt-3" v-for="(subscription, subscriptionId) in layout.client.odoo.subscriptions.orders" :key="subscriptionId">
                                <div class="px-5">
                                    <h2 class="fs-4">{{ subscription.name }} <span class="text-xs3">(ID:{{ subscription.id  }})</span></h2>
                                    <template v-if="subscription?.lines && subscription?.lines.length">
                                        <div v-for="(line, lineId) in subscription.lines" :key="lineId" class="fs-5 fw-bold">
                                            <img v-if="line.product[0].image_1920" :src="'data:image/png;base64,' + line.product[0].image_1920" class="img-fluid my-2 ms-1 me-2" style="max-height: 40px;"/>
                                            {{ line.product[0].name }}
                                        </div>
                                    </template>
                                </div>
                                <ion-item style="--ion-item-border-color: #ccc;" class="p-0 mx-0 mb-2">
                                    <ion-label>
                                        <div class="text-xs3">Plan recurrente: <template v-if="subscription?.plan_id[1] == 'Monthly'">Mensual</template><template v-else>{{ subscription?.plan_id[1] }}</template></div>
                                        <div class="text-xs3" v-if="subscription?.invoice_status">
                                            Estado: 
                                            <template v-if="subscription.invoice_status == 'invoiced'">Facturado</template>
                                            <template v-else>{{ subscription?.invoice_status }}</template>
                                        </div>
                                        <div  class="text-xs3 pt-3" v-if="subscription?.start_date">Inicio {{ subscription?.start_date }}</div>
                                        <div class="text-xs3" v-if="subscription?.end_date">Finaliza {{ subscription?.end_date }}</div>
                                        <div v-else class="text-xs3">Sin fecha de finalización</div>
                                        <div class="text-xs3" v-if="subscription?.next_invoice_date">Próxima factura: <span class="fw-bold">{{ subscription?.next_invoice_date }}</span></div>

                                    </ion-label>
                                    <div class="text-end float-end friColor1 fs-1 mt-5 align-top">
                                        <div class="text-xs3">{{ parseFloat(subscription.amount_untaxed).toFixed(2) }} &euro;</div>
                                        <div class="text-xs3">{{ parseFloat(subscription.amount_tax).toFixed(2) }} &euro;</div>
                                        <span class="fw-bold">{{ parseFloat(subscription.amount_total).toFixed(2) }} &euro;</span>
                                        <div class="text-xs3">{{ subscription.payment_term_id[1] }}</div>
                                    </div>
                                </ion-item>
                                <template v-if="subscription?.invoices && subscription.invoices.length">
                                    <div class="pt-3 text-xs3 mx-5">Facturas emitidas ({{ subscription.invoices.length }}):</div>
                                    <ion-item style="--ion-item-border-color: #ccc;" >
                                        <div v-for="(invoice, invoiceId) in subscription.invoices" :key="invoiceId" class="py-2 w-100">
                                            <ion-row>
                                                <ion-col>
                                                    <div class="friColor1 text-xs3">{{ invoice.name }}</div> 
                                                    <div class="text-xs3">Fecha: {{ invoice.invoice_date }}</div> 
                                                    <div class="text-xs3" v-if="invoice.invoice_date_due">Vencimiento: {{ invoice.invoice_date_due }}</div> 
                                                    <div class="text-xs3" v-if="invoice?.partner_id">{{ invoice?.partner_id[1] }}</div>
                                                    <div class="text-xs3">
                                                        <template v-if="invoice.state == 'posted'"><span>Publicada</span></template>
                                                        <template v-else>{{ invoice?.state }}</template> 
                                                    </div> 
                                                    <div class="text-xs3 mt-5" v-if="invoice.payment_reference">Ref pago: {{ invoice.payment_reference }}</div> 
                                                    <div class="text-xs3" v-if="invoice?.payment_state">
                                                        <template v-if="invoice.payment_state == 'not_paid'"><span class="friColor3">NO PAGADA</span></template>
                                                        <template v-else-if="invoice.payment_state == 'in_payment'"><span class="friColor2">EN PROCESO DE PAGO</span></template>
                                                        <template v-else>{{ invoice?.payment_state }}</template> 
                                                    </div>
                                                </ion-col>
                                                <ion-col>
                                                    <span class="text-end float-end friColor1 align-top">
                                                        <div class="text-xs3">{{ parseFloat(invoice.amount_untaxed).toFixed(2) }} &euro;</div>
                                                        <div class="text-xs3">{{ parseFloat(invoice.amount_tax).toFixed(2) }} &euro;</div>
                                                        <span class="fw-bold fs-5">{{ parseFloat(invoice.amount_total).toFixed(2) }} &euro;</span>
                                                        <div class="text-xs3 friColor3" v-if="invoice.amount_residual">{{  parseFloat(invoice.amount_residual).toFixed(2)  }}</div>
                                                    </span>
                                                </ion-col>
                                            </ion-row>
                                        </div>
                                    </ion-item>
                                </template>
                            </ion-list>
                        </template>
                        <div v-else class="text-center mt-5">No dispone de suscripciones asociadas.</div>
                    </template>
                    <div v-else class="text-center mt-5"><div class="spinner-border friColor1 mt-5" role="status"><span class="visually-hidden">Cargando ...</span></div></div>
                    <pre v-if="layout.client.odoo?.subscriptions">{{ layout.client.odoo.subscriptions }}</pre>
                    -->
               
                </template>
                <template v-else>
                    <div class="text-center mt-5">
                        <div v-if="layout.createBoxLoading">
                            <div>Espere por favor ...</div>
                            <div class="spinner-border friColor1 mt-5" role="status"><span class="visually-hidden">Cargando ...</span></div>
                        </div>
                        <div v-else>
                            No se han registrado datos de cliente en Stripe.
                            <div class="mt-4">
                                <div class="d-flex justify-content-center">
                                    <button class="button px-5 py-1 fw-bold friBack3 br-8 text-white outline-none" @click="layout.createClientBox = true">Crear cliente en Stripe</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </template>
        <template v-else>
            <div class="text-center w-100"  style="margin: 150px 0;">
                <div class="fw-bold fs-4">Suscripciones</div>
                <div class="mt-2">Espere por favor ...</div>
                <div class="spinner-border friColor1 mt-5" role="status">
                <span class="visually-hidden">Cargando ...</span>
                </div>
            </div>
        </template>

        <ion-modal ref="modal" :is-open="layout.createClientBox" :initial-breakpoint="0.75" :breakpoints="[0.5, 0.75, 1]" :backdrop-dismiss="false">
            <div class="py-5">
                <ion-list class="mx-5">
                    <div class="p-0 m-0 fs-3 fw-bold mx-5">Stripe</div>
                    <div class="p-0 m-0 fs-5 mx-5">Dirección de facturación</div>
                    <div class="p-0 m-0 text-xs3 mx-5" v-if="layout.client">{{ layout.client.email }}</div>
                    <ion-row>
                        <ion-col>
                            <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                <ion-label position="floating" class="friColor1 fs-6">Teléfono *</ion-label>
                                <ion-input v-model="layout.newClientAddress.phone" type="text" ></ion-input>
                            </ion-item>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                <ion-label position="floating" class="friColor1 fs-6">Dirección *</ion-label>
                                <ion-input v-model="layout.newClientAddress.address" type="text" ></ion-input>
                            </ion-item>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                <ion-label position="floating" class="friColor1 fs-6">Código postal *</ion-label>
                                <ion-input v-model="layout.newClientAddress.zip" type="text" ></ion-input>
                            </ion-item>
                        </ion-col>
                        <ion-col>
                            <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                <ion-label position="floating" class="friColor1 fs-6">Ciudad *</ion-label>
                                <ion-input v-model="layout.newClientAddress.city" type="text" ></ion-input>
                            </ion-item>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                <ion-label position="floating" class="friColor1 fs-6">Provincia *</ion-label>
                                <ion-input v-model="layout.newClientAddress.state" type="text" ></ion-input>
                            </ion-item>
                        </ion-col>
                        <ion-col>
                            <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                <ion-label position="floating" class="friColor1 fs-6">País *</ion-label>
                                <ion-input v-model="layout.newClientAddress.country" type="text"></ion-input>
                            </ion-item>
                        </ion-col>
                    </ion-row>
                </ion-list>
                <div class="mt-5">
                    <div class="d-flex justify-content-center">
                        <button class="button px-5 py-2 m-0 mt-0 bg-light" @click="cancelCreate()">Cancelar</button>
                        <button class="button px-5 py-2 ms-3 fw-bold friBack1 text-white outline-none" @click="createStripeClient()">Guardar</button>
                    </div>
                </div>
            </div>
        </ion-modal>

        <ion-modal :is-open="layout.addPlan" ref="modal" :initial-breakpoint="0.75" :breakpoints="[0.5, 0.75, 1]" :backdrop-dismiss="false">
            <div class="p-5">
                <div class="mx-2">
                    <h2 class="fs-2 mt-2 fw-bold">Nueva suscripción</h2>
                    <div v-if="layout.client">{{layout.client.first}} {{layout.client.last}}</div>
                </div>
                <!--
                <template v-if="layout.newPlan.code && layout.showPlanInfo">
                    <div class="px-4 py-2">
                        <div class="fw-bold friColor1 fs-5 mt-2">{{ layout.plansType.find(p => p.code == layout.newPlan.code).name }}</div>
                        <div class="text-xs3">{{ layout.plansType.find(p => p.code == layout.newPlan.code).description }}</div>
                        <div class="pt-3 text-xs3" v-if="layout.plansType.find(p => p.code == layout.newPlan.code).limits">
                            <div class="fw-bold fs-6 pb-2">Límites de uso por defecto</div>
                            <div>Dispositivos: <span class="float-end text-end">{{ layout.plansType.find(p => p.code == layout.newPlan.code).limits.devices }}</span></div>
                            <div>Usuarios: <span class="float-end text-end">{{ layout.plansType.find(p => p.code == layout.newPlan.code).limits.users }}</span></div>
                            <div>Escenas: <span class="float-end text-end">{{ layout.plansType.find(p => p.code == layout.newPlan.code).limits.scenes }}</span></div>
                            <div>Alarmas</div>
                            <div class="ps-2">Historial: <span class="float-end text-end">{{ layout.plansType.find(p => p.code == layout.newPlan.code).limits.alarms.log ? 'Activo' : 'Inactivo' }}</span></div>
                            <div class="ps-2">Exportación: <span class="float-end text-end">{{ layout.plansType.find(p => p.code == layout.newPlan.code).limits.alarms.share ? 'Activo' : 'Inactivo' }}</span></div>
                            <div>Notificaciones</div>
                            <div class="ps-2">App: <span class="float-end text-end">Activo</span></div>
                            <div class="ps-2">Email: <span class="float-end text-end">{{ layout.plansType.find(p => p.code == layout.newPlan.code).limits.notify.email ? 'Activo' : 'Inactivo' }}</span></div>
                            <div class="ps-2">Whatsapp: <span class="float-end text-end">{{ layout.plansType.find(p => p.code == layout.newPlan.code).limits.notify.whatsapp ? 'Activo' : 'Inactivo' }}</span></div>
                            <div class="ps-2">SMS: <span class="float-end text-end">{{ layout.plansType.find(p => p.code == layout.newPlan.code).limits.notify.sns ? 'Activo' : 'Inactivo' }}</span></div>
                            <div>Reportes</div>
                            <div class="ps-2">Historial: <span class="float-end text-end">{{ layout.plansType.find(p => p.code == layout.newPlan.code).limits.notify.log ? 'Activo' : 'Inactivo' }}</span></div>
                            <div class="ps-2">Exportación: <span class="float-end text-end">{{ layout.plansType.find(p => p.code == layout.newPlan.code).limits.notify.share ? 'Activo' : 'Inactivo' }}</span></div>
                            <div>Precio (Sin IVA): <span class="float-end text-end">{{ layout.plansType.find(p => p.code == layout.newPlan.code).price }} &euro;</span></div>
                        </div>
                        <div class="d-flex justify-content-center mt-4">
                            <button class="button px-5 py-3 m-0 mt-0 bg-light" @click="layout.showPlanInfo = false">Volver</button>
                        </div>
                    </div>
                </template>
                <template v-else>
                -->
                    <ion-list>
                        <!--
                        <template v-if="layout.plansType">
                            <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                <ion-label position="floating" class="friColor1 fs-6 ">Tipo *</ion-label>
                                <ion-select interface="popover" class="fs-5" v-model="layout.newPlan" >
                                    <ion-select-option v-for="(type) in layout.plansType" :key="type" :value="type">{{ type.name }}</ion-select-option>
                                </ion-select>
                            </ion-item>
                            <div class="px-3 pt-3 friColor1 cursor-pointer" style="text-decoration: underline;" v-if="layout.newPlan.code" @click="layout.showPlanInfo = true">Ver más información del plan</div> 
                        </template>
                        <template v-if="layout.client.odoo && layout.client.odoo.products">
                            <template v-if="layout.client.odoo.products.categories">
                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                    <ion-label position="floating" class="friColor1 fs-6 ">Categoría *</ion-label>
                                    <ion-select interface="popover" class="fs-5" v-model="layout.newPlan.category_id">
                                        <ion-select-option v-for="(category) in layout.client.odoo.products.categories.categories" :disabled="true" :key="category" :value="category.id">{{ category.name }}</ion-select-option>
                                    </ion-select>
                                </ion-item>
                            </template>
                            <template v-if="layout.client.odoo.products.albahub">
                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                    <ion-label position="floating" class="friColor1 fs-6 ">Suscripción *</ion-label>
                                    <ion-select interface="popover" class="fs-5" v-model="layout.newPlan.productId">
                                        <ion-select-option v-for="(product) in layout.client.odoo.products.albahub.products" :key="product" :value="product.id">{{ product.name }}</ion-select-option>
                                    </ion-select>
                                </ion-item>
                            </template>
                            <template v-if="layout.client.odoo.products.pricelist && layout.newPlan.productId">
                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                    <ion-label position="floating" class="friColor1 fs-6 ">Lista de precios *</ion-label>
                                    <ion-select interface="popover" class="fs-5" v-model="layout.newPlan.priceList" >
                                        <ion-select-option v-for="(priceList) in layout.client.odoo.products.pricelist.pricelist" :key="priceList" :value="priceList.id">{{ priceList.name }}</ion-select-option>
                                    </ion-select>
                                </ion-item>
                            </template>
                        </template>
                        -->
                        <template v-if="layout.stripe.methods && layout.stripe.methods?.payment_methods && layout.stripe.priceList && Object.keys(layout.stripe.priceList).length">
                            <template v-if="layout.stripe.priceList && Object.keys(layout.stripe.priceList).length">
                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                    <ion-label position="floating" class="friColor1 fs-6 ">Plan *</ion-label>
                                    <ion-select interface="popover" class="fs-5" v-model="layout.newPlan.price_id" >
                                        <ion-select-option v-for="(plan, planId) in layout.stripe.priceList" :key="planId" :value="plan.id">{{ plan.product_data.name }} ({{ parseFloat(plan.unit_amount/100).toFixed(2) }} {{ plan.currency }})</ion-select-option>
                                    </ion-select>
                                </ion-item>
                            </template>
                            <template v-if="layout.stripe.methods && layout.stripe.methods?.payment_methods">
                                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                    <ion-label position="floating" class="friColor1 fs-6 ">Método de pago *</ion-label>
                                    <ion-select interface="popover" class="fs-5" v-model="layout.newPlan.payment_method_id" >
                                        <ion-select-option v-for="(method, methodId) in layout.stripe.methods.payment_methods" :key="methodId" :value="method.id">{{ method.card.display_brand }} **** **** **** {{ method.card.last4 }}</ion-select-option>
                                    </ion-select>   
                                </ion-item>
                            </template>
                            <div class="d-flex justify-content-center mt-5 pt-3">
                                <button class="button px-5 py-3 m-0 mt-0 bg-light" @click="layout.addPlan = false; initNewPlan()">Cancelar</button>
                                <button class="button px-5 py-3 ms-3 mt-0 friBack1 text-white" @click="createSusription()">Crear</button>
                            </div>
                            <pre>{{ layout.newPlan }}</pre>
                        </template>
                        <template v-else>
                            <div class="text-center w-100"  style="margin: 10px 0;">
                                <div class="spinner-border friColor1 mt-5" role="status">
                                    <span class="visually-hidden">Cargando ...</span>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center mt-5 pt-3">
                                <button class="button px-5 py-3 m-0 mt-0 bg-light" @click="layout.addPlan = false; initNewPlan()">Cancelar</button>
                            </div>
                        </template>
                    </ion-list>
                <!--</template>-->
            </div>
        </ion-modal>
        
    </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import { useAuthStore } from "@/store";
import { helper as $h } from "../../../utils/helper.js";
import { getClient, updateClient } from "../../../model/api.js";
import { IonItem, IonLabel, IonList, IonModal, IonRow, IonSelect, IonSelectOption, IonCol, IonInput } from '@ionic/vue'; 
import { getPlansType, getClientPlans } from "../../../model/plans.js";
import { appsList } from "../../../model/apps.js";
//import { OdooReq } from "../../../model/odoo.js";
import { PayReq } from "../../../model/pay.js";
import { not as $not } from "../../../model/not";  

export default defineComponent({
name: 'clientPlans',
props: {
    isMobile: Boolean,
    clientId: String
    },
    components: { IonItem, IonLabel, IonList, IonModal, IonRow, IonSelect, IonSelectOption,  IonCol, IonInput },
setup(props) {

    const authStore     = useAuthStore();
    const layout        = ref({ 
                            client:             null,
                            plans:              null,
                            plansType:          null,
                            addPlan:            false,
                            newPlan:            null,
                            showPlanInfo:       false,
                            createClientBox:    false,
                            newClientAddress:   null,
                            stripe:             {
                                                    client:  null,
                                                    createMethodLink: null,
                                                },
                            helper:             $h,
                            })

    const { user, profile } = authStore;

    const initNewPlan = async () => {
        layout.value.newPlan = {
            customer_id:       null,
            price_id:          null,
            payment_method_id: null,
            profile_id:        null
        }
    }

    const initNewClientAddress = async () => {
        layout.value.newClientAddress = {
            address:    '',
            city:       '',
            zip:        '',
            state:      '',
            phone:      '',
            country:    'ES',
        }
    }

    const createStripeClient = async () => {
        layout.value.createBoxLoading = true;
        let validate = true;
        let fields = ['address', 'city', 'zip', 'state', 'country', 'phone']
        fields.forEach(field => { if (!layout.value.newClientAddress[field]) validate = false; })
        if (!validate) {
            $not.toastApp({ message: 'Debe completar todos los campos requeridos *', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
            layout.value.createBoxLoading = false;
            return;
        }
        let nClient = { 
                        "email":        layout.value.client.email,
                        "name":         layout.value.client.first + " " + layout.value.client.last,
                        "address":      layout.value.newClientAddress.address,
                        "city":         layout.value.newClientAddress.city,
                        "zip":          layout.value.newClientAddress.zip,
                        "state":        layout.value.newClientAddress.state,
                        "country":      layout.value.newClientAddress.country,
                        "phone":        layout.value.newClientAddress.phone,
                        "profileId":    layout.value.client.id
                      }

        let clientStripeCreate = await PayReq('create-client', nClient)
        console.log('create client stripe', clientStripeCreate)
        if(clientStripeCreate.response && clientStripeCreate.response.data && !clientStripeCreate.response.data?.error && clientStripeCreate.response.data.id){
            layout.value.client.stripeId = clientStripeCreate.response.data.id
            await updateClient(layout.value.client)
            $not.toastApp({ message: 'Se ha creado el cliente correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, cssClass: 'text-white mt-2 friToast1'})
            await cancelCreate()
            await initPlans()
        }else $not.toastApp({ message: 'No se ha podido crear el cliente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
        layout.value.createBoxLoading = false;
    }

    const cancelCreate = async () => {
        layout.value.createClientBox = false
    }

    const createSusription = async () => {
        let validate = true;
        let fields = ['customer_id', 'price_id', 'payment_method_id', 'profile_id']
        fields.forEach(field => { if (!layout.value.newPlan[field]) validate = false; })
        if (!validate) {
            $not.toastApp({ message: 'Debe completar todos los campos requeridos *', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
            return;
        }
        let createSubscription = await PayReq('create-subscription', layout.value.newPlan)
        if(createSubscription.response && createSubscription.response.data && !createSubscription.response.data.error){
            $not.toastApp({ message: 'Se ha creado la suscripción correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, cssClass: 'text-white mt-2 friToast1'})
            layout.value.addPlan = false;
            await initPlans()
        }else $not.toastApp({ message: 'No se ha podido crear la suscripción', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
    }
    
    const goToUrl = async (url) => {
        window.location.href = url
    }

    const initPlans = async () => {
        layout.value.client      = await getClient(props.clientId)
        layout.value.apps        = await appsList({ byUser: layout.value.client });
        layout.value.plansType   = await getPlansType()
        layout.value.plans       = await getClientPlans(props.clientId)

        await initNewPlan()
        await initNewClientAddress()

        //stripe
        if (layout.value.client?.stripeId){

            layout.value.newPlan.customer_id = layout.value.client.stripeId
            layout.value.newPlan.profile_id  = layout.value.client.id

            let clientData = await PayReq('client', { customerId: layout.value.client.stripeId })
            if(!clientData?.error && clientData.response.data && clientData.response.data?.customer)layout.value.stripe.client = clientData.response.data.customer

            let payMethods = await PayReq('client-methods', { customerId: layout.value.client.stripeId })
            if(!payMethods?.error)layout.value.stripe.methods = payMethods.response.data

            let createPayMethodLink = await PayReq('create-method-link', {  'success_url': window.location.href,
                                                                            'cancel_url':  window.location.href,
                                                                            'customer_id': layout.value.client?.stripeId,
                                                                            'profile_id':  layout.value.client?.id })
            if(createPayMethodLink?.response?.data?.url)layout.value.stripe.createMethodLink = createPayMethodLink.response.data.url
            
            let clientSubscriptions = await PayReq('client-subscriptions', { customerId: layout.value.client.stripeId })
            if(!clientSubscriptions?.error)layout.value.stripe.subscriptions = clientSubscriptions.response.data

            let priceList = await PayReq('price-list',{ customerId: layout.value.client.stripeId })
            if(!priceList?.error && priceList.response.data?.prices && priceList.response.data?.prices?.data)layout.value.stripe.priceList = priceList.response.data.prices.data

            let invoices = await PayReq('client-invoices',{ customerId: layout.value.client.stripeId })
            if(!invoices?.error)layout.value.stripe.invoices = invoices.response.data.invoices.data
        }

        //odoo
        /*
        layout.value.client.odoo = {}
        if (layout.value.client?.odooId){

          let odooSubscriptions = await OdooReq('client-subscriptions', { id: layout.value.client.odooId })
          if(!odooSubscriptions?.error)layout.value.client.odoo['subscriptions'] = odooSubscriptions.response.data

          layout.value.client.odoo.products = {}
          
          let odooProductPriceList = await OdooReq('product-pricelist', { id: layout.value.client.odooId })
          if(!odooProductPriceList?.error)layout.value.client.odoo['products']['pricelist'] = odooProductPriceList.response.data

          let odooProductCategories = await OdooReq('product-categories', { id: layout.value.client.odooId })
          if(!odooProductCategories?.error)layout.value.client.odoo['products']['categories'] = odooProductCategories.response.data

          let odooProducts = await OdooReq('products', { categoryId: 5 })
          if(!odooProducts?.error)layout.value.client.odoo['products']['albahub'] = odooProducts.response.data
        }
        */

       
    }

    onMounted( async () => { 
        await initPlans();
    });
    
    return { layout, user, props, profile, initNewPlan, createStripeClient, cancelCreate, goToUrl, createSusription };
}

})
</script>