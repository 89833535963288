<template>
    <div class="bg-dash-image">
        <div id="friTopMenuNav" class="page-title has-text-centered" @click="loadMenu()">
            <div id="friTopMenuNavLogo" class="title-wrap mt-3" style="margin-inline-start: 0">
                <img src="../../assets/images/logo/fricontrolIcon.png" width="65" alt="Alba" class="cursor-pointer mt-5 ms-2"  />
            </div>
            <TopMenu :isMobile="layout.isMobile" @menuAction="menuAction" />
        </div>
        <div class="personal-dashboard personal-dashboard-v1" style="font-family: var(--font-alt);">
            <div class="dashboard-body my-0 py-2" style="min-height: 82vh; width: 100%;max-width: 490px !important;overflow: hidden;">
                <MenuConfig v-if="layout.section=='menu'" @menuAction="menuAction" />
                <Dash v-if="layout.section=='dash'" @menuAction="menuAction" />
                <Config v-if="layout.section=='config'" />
                <Alerts v-if="layout.section=='alerts'" />
                <Controller v-if="layout.section=='controller'" @menuAction="menuAction" />
                <Devices v-if="layout.section=='devices'" @menuAction="menuAction" />
                <Log v-if="layout.section=='log'" @menuAction="menuAction" />
                <Battery v-if="layout.section=='battery'" />
                <Wifi v-if="layout.section=='wifi'" />
            </div>   
            <Ticker />
            <div class="dashFriFooter" v-if="layout.section=='dash'">&REG; 2023 Fricontrol Sistemas de Refrigeración, S.L. </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { useAuthStore } from '@/store';
import router from "@/router";

import Ticker  from '@/components/pages/dash/ticker.vue';
import TopMenu from '@/components/pages/dash/top.vue';
import Dash    from '@/components/pages/dash/dash.vue';
import MenuConfig  from '@/components/pages/dash/menu.vue';
import Config    from '@/components/pages/dash/config.vue';
import Alerts    from '@/components/pages/dash/alerts.vue';
import Controller from '@/components/pages/dash/controller.vue';
import Log from '@/components/pages/dash/log.vue';
import Battery from '@/components/pages/dash/battery.vue';
import Wifi from '@/components/pages/dash/wifi.vue';
import Devices from '@/components/pages/dash/devices.vue';

export default defineComponent({
name: 'DeviceDashPage',
props: {
    cid:      String,
    isMobile: Boolean
},

components: { Ticker, TopMenu, Dash, MenuConfig, Config, Alerts, Controller, Log, Battery, Wifi, Devices },
setup() {

    const authStore         = useAuthStore();
    const { user, profile } = authStore;
    
    const layout            = ref({
                                    isMobile:    false,
                                    controller:  false,
                                    section:     "dash",
                                  })

    const loadMenu = async () => {
        layout.value.section = "menu";
    }

    onMounted(async () => {
        
    });

    return {user, profile, layout, router, loadMenu };
},
methods: {
    
    menuAction(id) {
        this.layout.section = id;
    },
},
});
</script>
<style>
.bg-dash-image { 
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    max-width: 480px !important;
    overflow-y: hidden !important; background: url("~@/assets/images/logo/back-config-wifi.png") no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;
}
.dashFriFooter{display: block; position: fixed; bottom: 8px; left: 21%; font-size: 12px;}
body {
  overflow: hidden; 
  touch-action: none;
}
</style>