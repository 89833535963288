import { _firebase } from "./firebase.js";
import { collection, getDocs, doc, getDoc, query, where } from "firebase/firestore"; 
const db   =  _firebase.firestore() 


const getPaymentMethods = async () => {
    return [  { id: "bank",     name: "Remesa bancaria (SEPA)" }, 
              { id: "card",     name: "Tarjeta de crédito" }];
}

const getBanks = async () => {
    return [
        { id: "0049", name: "Banco Santander" },
        { id: "0182", name: "BBVA" },
        { id: "2100", name: "CaixaBank" },
        { id: "0081", name: "Banco Sabadell" },
        { id: "0128", name: "Bankinter" },
        { id: "3058", name: "Cajamar Caja Rural" },
        { id: "2095", name: "Kutxabank" },
        { id: "2080", name: "Abanca" },
        { id: "2103", name: "Unicaja Banco" },
        { id: "2048", name: "Liberbank" },
        { id: "2085", name: "Ibercaja Banco" },
        { id: "3070", name: "Caja Rural del Sur" },
        { id: "0234", name: "Banco Alcalá" },
        { id: "0186", name: "Banco Mediolanum" },
        { id: "0237", name: "Cajasur Banco" },
        { id: "0061", name: "Banca March" }
    ].sort((a, b) => a.name.localeCompare(b.name));
};

const getPlansType = async () => {
    let plansType = [ 
        { 
            code:        "AHS0001",
            type:        "hub",
            appId:       "",
            name:        "Alba Hub Basic mensual",
            description: "Plan básico para la visualización de la temperatura, humedad y estado de la puerta en momento actual.",
            deleted:     false,
            active:      true,
            payment: {
                method: "bank",
                bank: { IBAN: "ES0000000000000000000000", holder: "", name: "" },
            },
            period: {
                type:    "monthly",
                startAt: { date: "", time: "00:00:00" },
                endAt:   { date:  "", time: "23:59:59"  },
            }, 
            limits: { devices: 5, users: 2, scenes: 2, notify: { email: true, whatsapp: false, sms: false, maxsms: 30 },  alarms: { log: false, share: false }, reports: { log: false, share: false } },
            price: 26,
        } 
    ];
    return plansType;
}

const getClientPlans = async (clientId) => {
    let resp        = { success: false, plans: [] };
    let clientRef   = collection(db, "profiles");
    let queryRef    = query(clientRef, where("id", "==", clientId));
    try {
        let querySnapshot = await getDocs(queryRef);
        for (const doc of querySnapshot.docs) {
            let plansRef        = collection(doc.ref, "plans");
            let plansSnapshot   = await getDocs(plansRef);
            plansSnapshot.forEach((planDoc) => {  
                let planData    = planDoc.data();
                planData.id     = planDoc.id; 
                resp.plans.push(planData); 
            });
        }
        resp.success = true;
    } catch (error) { console.error("Error:", error); }
    return resp;
};

const getPlan = async (clientId, planId) => {
    let resp                = { success: false, plan: [] };
    let clientDocRef        = doc(db, "profiles", clientId.toString());
    let planCollectionRef   = collection(clientDocRef, "plans");
    let planDocRef          = doc(planCollectionRef, planId.toString());
    let planSnap            = await getDoc(planDocRef);
    if (planSnap.exists()) {
        resp.plan = planSnap.data();
        resp.plan.id = planSnap.id;
        resp.success = true;
    }
    return resp;
}


export { getPaymentMethods, getPlansType, getBanks, getClientPlans, getPlan };