<template>
    <div class="account-box is-form is-footerless px-0 mx-0" :style="isMobile ? 'border-bottom: none !important;' : ''">
        <div class="form-head stuck-header" :class="isMobile ? 'py-0' : 'py-3'" v-if="layout.app">
            <div class="form-head-inner">
                <div class="left"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5' : 'fs-4'">{{  layout.app.name }} </h4></div>
                <div class="inline-block align-top fs-6 mt-0" style="margin-top: 4px;">
                <template v-if="layout.app.logo"><img :src="layout.app.logo" style="height: 50px;" /></template>
                <template v-else>{{  layout.app.id }}</template>
            </div>
            </div>
        </div>
        <div class="pt-2 pb-2 fs-5 friBack1 text-white text-center ps-3" v-if="layout.device?.device && layout.device?.device?.typeName"><span class="align-middle">{{ layout.device?.device.typeName }}</span></div>
        <div class="ms-3" v-if="layout.app && layout.device?.device">
            <template v-if="layout.device.device.status">
                <div class="text-center" style="margin-top: 50px;" v-if="layout.actionLoading">
                    <div class="text-center mt-5">
                        <img src="../../../assets/images/theme/loading.gif" style="width: 150px">
                    </div>
                </div>
                <template v-else>
                    <!-- XW265K -->
                    <div class="mx-5 text-center" v-if="layout.device.device.status.msg.hasOwnProperty('deviceModel') && layout.device.device.status.msg.deviceModel == 'xw265k'" :style="isMobile ? 'padding-bottom: 150px;' : ''">
                        <div  v-if="layout.device.device.status.msg.stats?.onOff" class="pt-3">
                            <div @click="sendAction('uplink')" class="friBack3 text-white  br-5 mb-2 py-4 fs-6">ACTUALIZAR DATOS</div>
                            <div @click="sendAction('lightOff')" class="friBack12 text-white  br-5 mb-2 py-4 fs-6" v-if="layout.device.device.status.msg?.relays?.light">APAGAR LUZ</div>
                            <div @click="sendAction('lightOn')" class="friBack3 text-white  br-5 mb-2 py-4 fs-6" v-else>ENCENDER LUZ</div>
                            <div @click="sendAction('defrost')" class="friBack3 text-white br-5 mb-2 py-4 fs-6"  v-if="!layout.device.device.status.msg.stats.defrost">ACTIVAR DESESCARCHE</div>
                            <div class="friBack1 text-white br-5 mb-2 py-4 fs-6"  v-else>DESESCARCHE EN CURSO ...</div>
                            <div @click="sendAction('deactivateSaveMode')" class="friBack12 text-white  br-5 mb-2 py-4 fs-6" v-if="layout.device.device.status.msg?.stats?.energySaving">DESACTIVAR MODO AHORRO</div>
                            <div @click="sendAction('activateSaveMode')" class="friBack3 text-white br-5 mb-2 py-4 fs-6" v-else>ACTIVAR MODO AHORRO</div>
                            <div @click="sendAction('keyUnlock')" class="friBack12 text-white  br-5 mb-2 py-4 fs-6" v-if="layout.device.device.status.msg.stats.keyboardLock">DESBLOQUEAR TECLADO</div>
                            <div @click="sendAction('keylock')" class="friBack3 text-white  br-5 mb-2 py-4 fs-6" v-else>BLOQUEAR TECLADO</div>
                            <div @click="sendAction('muteAlarm')" class="friBack4 text-dark  br-5 mb-2 py-4 fs-6">SILENCIAR ALARMA</div>
                            <template v-if="layout.device.device.status?.msg?.params?.hasOwnProperty('SEt')">
                                <div @click="changeSet()" class="friBack4 text-dark  br-5 mb-2 py-4 fs-6">SET {{ layout.device.device.status.msg.params.SEt }}°C</div>
                            </template>
                        </div>
                        <div @click="sendAction('Off')" class="friBack12 text-white br-8 py-5 mx-0 fs-6 cursor-pointer" v-if="layout.device.device.status.msg.stats?.onOff" style="margin: 15px 0;"><div><i class="bi bi-power text-white" style="font-size: 50px;"></i></div>APAGAR</div>
                        <div @click="sendAction('On')" class="friBack1 text-white br-8 mb-2 py-5 mx-0 fs-6 cursor-pointer" v-else style="margin-top: 20px;"><div><i class="bi bi-power text-white" style="font-size: 50px;"></i></div>ENCENDER</div>
                    </div>
                </template>
            </template>
        </div>
        <div v-else class="text-center" style="margin-top: 50px;">
            <div class="spinner-border text-primary friColor1 text-center" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
 </template>
 <script>
 import { defineComponent, onMounted, ref, watch, onUnmounted } from "vue"; 
 import { useAuthStore } from "@/store";
 import { add } from 'ionicons/icons';
 import router from "@/router";
 import { useRoute } from 'vue-router'
 import { getApp, getAppDevice } from "../../../model/apps.js"; 
 import { sendDeviceActionTTN } from "../../../model/ttn.js"; 
 import { helper as $h } from "../../../utils/helper.js";
 import { _firebase as $_firebase } from "../../../model/firebase";
 import { onSnapshot, collection } from "firebase/firestore"; 
 import { alertController } from '@ionic/vue'; //, IonSegment, IonSegmentButton

 const db   =  $_firebase.firestore()

 export default defineComponent({
   name: 'semRemote',
   props: {
       isMobile: Boolean,
       appId: String,
       deviceId: String,
    },
   components: {  },
   setup(props) {
 
      const authStore      = useAuthStore();
      const route          = useRoute()
      const layout         = ref({ 
                               appId:       null,
                               deviceId:    null,
                               device:      null,
                               helper:     $h
                           }) 

      const { user, profile } = authStore;

      const initRemote = async () => {
        layout.value.app       = await getApp(layout.value.appId)
        if(layout.value.deviceId)    
            layout.value.device     = await getAppDevice(layout.value.appId, layout.value.deviceId)
      }

      onMounted( async () => { 
        layout.value.appId     = router.currentRoute.value.params.appId;
        layout.value.deviceId  = router.currentRoute.value.params.deviceId;
        await initRemote();
        await detectDevicesChanges();
      });

      const changeSet  = async () => {
        const query = await alertController.create({
            header: 'Ingrese el valor',
            cssClass: 'friCustomAlert',
            inputs: [ { name: 'set', type: 'number', placeholder: 'Valor', value: layout.value.device.device.status.msg.params.SEt } ],
            buttons: [
                { text: 'Cancelar', role: 'cancel' },
                { text: 'Aceptar', handler: async (alertData) => {
                    //si el valores es negativo
                    if(alertData.set < 0){
                        //alert('El valor es negativo!');
                        let decimalValueString = alertData.set.toString().replace(',', '.');
                        let decimalValue = parseFloat(decimalValueString);
                        let intValue = Math.round(decimalValue * 10);
                        let hex = (0xFFFF + intValue + 1).toString(16).slice(-4).toUpperCase();
                        await sendDeviceActionTTN(layout.value.appId, layout.value.deviceId, "set", "0001").then( async () => {
                            alert('es ' + decimalValue + ' en hex es ' + hex);
                        }).catch((error) => { console.log(error); });
                    }else{
                        let decimalValueString = alertData.set.toString().replace(',', '.'); 
                        let decimalValue = parseFloat(decimalValueString);
                        let intValue = Math.round(decimalValue * 10); 
                        let hex = intValue.toString(16).padStart(4, '0').toUpperCase();
                        await sendDeviceActionTTN(layout.value.appId, layout.value.deviceId, "set", hex ).then( async () => {
                            //alert('es ' + decimalValue + ' en hex es ' + hex);
                        }).catch((error) => { console.log(error); });
                    }
                }}
            ]
        });
        await query.present();
      }

      const sendAction = async (action) => {
        const alert = await alertController.create({
                                                header: '¿Está seguro?',
                                                cssClass: 'friCustomAlert',
                                                buttons: [ 
                                                        {text: 'Cancelar', cssClass: 'alert-button-cancel', role: 'cancel' },  
                                                        {text: 'Confirmar',cssClass: 'alert-button-confirm', handler: async () => { 
                                                                layout.value.actionLoading = true;
                                                                await sendDeviceActionTTN(layout.value.appId, layout.value.deviceId, action).then( async (response) => {
                                                                    console.log("response action: ", response);
                                                                    await initRemote();
                                                                    if(action != 'uplink'){
                                                                        await sendDeviceActionTTN(layout.value.appId, layout.value.deviceId, 'uplink')
                                                                        setTimeout(() => { layout.value.actionLoading = false; },  20000); 
                                                                    }else setTimeout(() => { layout.value.actionLoading = false; }, 3000);
                                                                }).catch((error) => { console.log(error); });
                                                            }
                                                        }
                                                        ],
                                            });
        await alert.present();
      }

       watch(() => route.query, async (query) => {
            if (query){
                layout.value.query     = query; 
                layout.value.section   = query.section;
                layout.value.appId     = router.currentRoute.value.params.appId;
                layout.value.deviceId  = router.currentRoute.value.params.deviceId;
            }
        });

        const detectDevicesChanges = async () => {
          const collectionPath = "apps/" + props.appId + "/devices/";
          const collRef        = collection(db, collectionPath);
          const unsubscribeDetectDevicesChanges = onSnapshot(collRef, async (querySnapshot) => {
              querySnapshot.docChanges().forEach(async change => { 
                  if (change.type === "modified"){
                     let changeData = change.doc.data()
                     if (changeData && changeData.deviceId == layout.value.deviceId)
                        await initRemote();
                  }
              }); 
          }, (error) => { console.log("Error:", error); });
          onUnmounted(() => { unsubscribeDetectDevicesChanges(); });
        };

       return { layout, user, profile, add, props, sendAction, changeSet };
   }
   
 })
 </script>