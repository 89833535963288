<template>
     <div class="account-wrapper text-left px-0 mx-0">
        <div class="columns">
            <!-- Desktop Navigation -->
            <div class="column is-4 mt-5" v-if="!isMobile">
                <div class="account-box is-navigation p-0 mt-0" style="border: none !important;">
                    <div class="account-menu me-5">
                        <div v-for="(section, index) in layout.sections" :key="index" class="account-menu-item cursor-pointer" 
                            :class="layout.section == section.name ? 'router-link-active router-link-exact-active' : ''" @click="layout.section = section.name">
                            <i aria-hidden="true" class="lnil lnil-user-alt"></i>
                            <span><i :class="section.icon + ' fs-3 align-middle me-3'"></i>{{ section.title }}</span>
                            <span class="end"><i aria-hidden="true" class="fas fa-arrow-right"></i></span>
                        </div>
                        <div class="account-menu-item mt-2 cursor-pointer" @click="goBack()">
                            <i aria-hidden="true" class="lnil lnil-quill"></i>
                            <span><i class="bi bi-chevron-compact-left fs-3 align-middle me-3"></i>Volver</span>
                            <span class="end"><i aria-hidden="true" class="fas fa-arrow-right"></i></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column px-0 mx-0"  :class="isMobile ? 'is-12' : 'is-8 mt-5'" v-if="layout.appId">
                <template v-if="layout.section == 'dashboard'"><Dash :appId="layout.appId" :deviceId="layout.deviceId" :alarmId="layout.alarmId" :isMobile="isMobile" /></template>
                <template v-else-if="layout.section == 'notify'"><Notify :appId="layout.appId" :deviceId="layout.deviceId" :alarmId="layout.alarmId" :isMobile="isMobile" /></template>
                <template v-else-if="layout.section == 'stats'"><Stats :appId="layout.appId" :deviceId="layout.deviceId" :alarmId="layout.alarmId" :isMobile="isMobile" /></template>
                <template v-else-if="layout.section == 'remove'"><Remove :appId="layout.appId" :deviceId="layout.deviceId" :alarmId="layout.alarmId" :isMobile="isMobile" /></template>
            </div>
        </div>
    </div>
  </template>
  <script>
  import { defineComponent, onMounted, ref, watch } from "vue"; 
  import { useAuthStore } from "@/store";
  import { add} from 'ionicons/icons';
  import router from "@/router";
  import { useRoute } from 'vue-router'
  
  import Dash from '@/components/pages/alarm/dashboard.vue';
  import Notify from '@/components/pages/alarm/notify.vue';
  import Stats from '@/components/pages/alarm/stats.vue';
  import Remove from '@/components/pages/alarm/remove.vue';

  export default defineComponent({
    name: 'AlarmPage',
    props: {
        isMobile: Boolean,
     },
     components: { Dash, Notify, Stats, Remove  },
    setup(props) {
  
       const authStore      = useAuthStore();
       const route          = useRoute()
       const layout         = ref({ 
                                appId:       null,
                                deviceId:    null,
                                alarm:       null,
                                section:     null,
                                sections: [
                                            { name: 'dashboard', title: 'Alarma', icon: 'bi bi-wifi'},
                                            { name: 'notify',    title: 'Notificaciones', icon: 'bi bi-bell'},
                                            { name: 'remove',    title: 'Eliminar', icon: 'bi bi-trash'},
                                            //{ name: 'stats',     title: 'Historial', icon: 'bi bi-graph-up'},
                                          ],
                            }) 

       const { user, profile } = authStore;

       const goBack = () => {
        router.push({ path: '/sensor/' + layout.value.appId + '/' +  layout.value.deviceId, query: { section:  'alarms' } })
       }

       const initAlarm = async () => {
           if(!layout.value.section)
           if(route.query.section) layout.value.section = route.query.section
           else layout.value.section = 'dashboard'
           layout.value.appId      = router.currentRoute.value.params.appId;
           layout.value.deviceId   = router.currentRoute.value.params.deviceId;
           layout.value.alarmId    = router.currentRoute.value.params.alarmId;
       }

       onMounted( async () => { 
            await initAlarm();
       });
  
        watch(() => route.query, (query) => {
            if (query){
                layout.value.query   = query; 
                layout.value.section = query.section;
            }
        });

        return { layout, user, profile, add, props, goBack };
    }
    
  })
  </script>