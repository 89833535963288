<template>
    <div class="account-box is-form is-footerless m-0 p-0 mb-5" :style="isMobile ? 'border-bottom: none !important;' : ''">
        <template v-if="layout.client">
            <div class="form-head stuck-header" :class="isMobile ? 'py-0 ps-3 pe-3' : 'py-3'">
                <div class="form-head-inner">
                    <div class="left"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5 ms-3' : 'fs-4'">Suscripción</h4></div>
                    <div class="inline-block align-top me-3 fs-6 mt-0" style="margin-top: 4px;">
                        <div class="friUserCircleMin friBack5">{{ layout.client?.first[0] }}{{ layout.client?.last[0] }}</div>
                    </div>
                </div>
            </div>    
            <div class="px-5" v-if="layout.plan">
                <ion-list  v-if="layout.plan?.plan">
                    <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                        <ion-label position="floating" class="friColor1 fs-6 ">Tipo *</ion-label>
                        <ion-select interface="popover" v-model="layout.plan.plan.code" class="fs-5">
                            <ion-select-option v-for="p in layout.plans" :key="p.code" :value="p.code.toString()" :disabled="true">{{ p.name }}</ion-select-option>
                        </ion-select>
                    </ion-item>

                    <div class="px-3 pt-3 friColor1 cursor-pointer" style="text-decoration: underline;" v-if="!layout.showPlanInfo" @click="layout.showPlanInfo = true">Ver más información del plan</div> 
                    
                    <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                        <ion-label position="floating" class="friColor1 fs-6">Nombre *</ion-label>
                        <ion-input v-model="layout.plan.plan.name" type="text" ></ion-input>
                    </ion-item>
                    <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                        <ion-label position="floating" class="friColor1 fs-6">Descripción *</ion-label>
                        <ion-input v-model="layout.plan.plan.description" type="text" ></ion-input>
                    </ion-item>

                    <template v-if="layout.plan.plan.type = 'hub' && layout.apps && layout.apps?.apps">
                        <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                            <ion-label position="floating" class="friColor1 fs-6">Aplicación *</ion-label>
                            <ion-select interface="popover" v-model="layout.plan.plan.appId" class="fs-5">
                                <ion-select-option v-for="(app, index) in layout.apps.apps" :key="index" :value="app.id">{{ index }}: {{ app.name }}</ion-select-option>
                            </ion-select>
                        </ion-item>
                    </template>


                    <ion-row class="p-0 m-0">
                        <ion-col class="p-0 m-0">
                            <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                <ion-label position="floating" class="friColor1 fs-6">Fecha de Inicio *</ion-label>
                                <ion-input v-model="layout.plan.plan.period.startAt.date" type="text"></ion-input>
                            </ion-item>
                        </ion-col>
                        <ion-col class="p-0 m-0">
                            <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                <ion-label position="floating" class="friColor1 fs-6">Hora *</ion-label>
                                <ion-input v-model="layout.plan.plan.period.startAt.time" type="text"></ion-input>
                            </ion-item>
                        </ion-col>
                    </ion-row>
                    <ion-row class="p-0 m-0">
                        <ion-col class="p-0 m-0">
                            <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                <ion-label position="floating" class="friColor1 fs-6">Fecha final *</ion-label>
                                <ion-input v-model="layout.plan.plan.period.endAt.date" type="text"></ion-input>
                            </ion-item>
                        </ion-col>
                        <ion-col class="p-0 m-0">
                            <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                                <ion-label position="floating" class="friColor1 fs-6">Hora *</ion-label>
                                <ion-input v-model="layout.plan.plan.period.endAt.time" type="text"></ion-input>
                            </ion-item>
                        </ion-col>
                    </ion-row>
                    <ion-item class="mt-2 border-none" lines="full" style="--ion-item-border-color: #ccc;">
                        <ion-label>Activa</ion-label>
                        <ion-toggle :enable-on-off-labels="true" slot="end" v-model="layout.plan.plan.active"></ion-toggle>
                    </ion-item>
                    <p v-if="!layout.plan.plan.active" class="text-center friColor3 fw-bold mt-3 mx-2">El cliente no podrá acceder a los servicios asociados.</p>
                    <div class="d-flex justify-content-center align-items-center fs-5" style="margin-top: 30px;">
                        <div class="fw-bold friColor1 text-end float-end cursor-pointer">GUARDAR</div>
                    </div>
                </ion-list>

                <ion-modal :is-open="layout.plan && layout.plan?.plan && layout.showPlanInfo" ref="modal" :initial-breakpoint="0.75" :breakpoints="[0.5, 0.75, 1]" :backdrop-dismiss="false">
                    <div class="p-5 my-2">
                        <div class="fw-bold friColor1 fs-2 mt-2">{{ layout.plans.find(p => p.code == layout.plan.plan.code).name }} <span class="text-xs3">({{ layout.plans.find(p => p.code == layout.plan.plan.code).code }})</span></div>
                        <div class="text-xs3">{{ layout.plans.find(p => p.code == layout.plan.plan.code).description }}</div>
                        <div class="pt-3 text-xs3" v-if="layout.plans.find(p => p.code == layout.plan.plan.code).limits">
                            <div class="fw-bold fs-6 pb-2">Límites de uso por defecto</div>
                            <div>Dispositivos: <span class="float-end text-end">{{ layout.plans.find(p => p.code == layout.plan.plan.code).limits.devices }}</span></div>
                            <div>Usuarios: <span class="float-end text-end">{{ layout.plans.find(p => p.code == layout.plan.plan.code).limits.users }}</span></div>
                            <div>Escenas: <span class="float-end text-end">{{ layout.plans.find(p => p.code == layout.plan.plan.code).limits.scenes }}</span></div>
                            <div>Alarmas</div>
                            <div class="ps-2">Historial: <span class="float-end text-end">{{ layout.plans.find(p => p.code == layout.plan.plan.code).limits.alarms.log ? 'Activo' : 'Inactivo' }}</span></div>
                            <div class="ps-2">Exportación: <span class="float-end text-end">{{ layout.plans.find(p => p.code == layout.plan.plan.code).limits.alarms.share ? 'Activo' : 'Inactivo' }}</span></div>
                            <div>Notificaciones</div>
                            <div class="ps-2">App: <span class="float-end text-end">Activo</span></div>
                            <div class="ps-2">Email: <span class="float-end text-end">{{ layout.plans.find(p => p.code == layout.plan.plan.code).limits.notify.email ? 'Activo' : 'Inactivo' }}</span></div>
                            <div class="ps-2">Whatsapp: <span class="float-end text-end">{{ layout.plans.find(p => p.code == layout.plan.plan.code).limits.notify.whatsapp ? 'Activo' : 'Inactivo' }}</span></div>
                            <div class="ps-2">SMS: <span class="float-end text-end">{{ layout.plans.find(p => p.code == layout.plan.plan.code).limits.notify.sns ? 'Activo' : 'Inactivo' }}</span></div>
                            <div>Reportes</div>
                            <div class="ps-2">Historial: <span class="float-end text-end">{{ layout.plans.find(p => p.code == layout.plan.plan.code).limits.notify.log ? 'Activo' : 'Inactivo' }}</span></div>
                            <div class="ps-2">Exportación: <span class="float-end text-end">{{ layout.plans.find(p => p.code == layout.plan.plan.code).limits.notify.share ? 'Activo' : 'Inactivo' }}</span></div>
                            <div>Precio (Sin IVA): <span class="float-end text-end">{{ layout.plans.find(p => p.code == layout.plan.plan.code).price }} &euro;</span></div>
                        </div>
                        <div class="d-flex justify-content-center mt-4">
                            <button class="button px-5 py-3 m-0 mt-0 bg-light" @click="layout.showPlanInfo = false">Aceptar</button>
                        </div>
                    </div>
                </ion-modal>

            </div>
            <!--
            <pre v-if="layout.plans">{{ layout.plans }}</pre>
            <pre v-if="layout.plan?.plan">{{ layout.plan.plan }}</pre>
            <pre v-if="layout.client">{{ layout.client }}</pre>
            -->
        </template>
        <template v-else>
            <div class="text-center w-100"  style="margin-top: 150px;">
                <div class="fw-bold fs-2">Suscripción</div>
                <div class="mt-2">Espere por favor ...</div>
                <div class="spinner-border friColor1 mt-5" role="status">
                <span class="visually-hidden">Cargando ...</span>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue"; 
import { useAuthStore } from "@/store";
import { helper as $h } from "../../../utils/helper.js";
import { getClient } from "../../../model/api.js";
import { appsList } from "../../../model/apps.js";
import { getPlansType, getPlan } from "../../../model/plans.js";
import { IonItem, IonLabel, IonInput, IonList, IonSelect, IonSelectOption, IonToggle, IonRow, IonCol, IonModal } from '@ionic/vue';

export default defineComponent({
name: 'planDash',
props: {
    isMobile: Boolean,
    clientId: String,
    planId:  String,
    },
    components: { IonItem, IonLabel, IonInput, IonList, IonSelect, IonSelectOption, IonToggle, IonRow, IonCol, IonModal }, 
setup(props) {

    const authStore      = useAuthStore();
    const layout = ref({ 
                        clientId:   null,
                        planId:     null,
                        plans:      null,
                        apps:       null,
                        plan:       null,
                        client:     null,
                        showPlanInfo: false,
                        helper:     $h,
                        })

    const { user, profile } = authStore;

    const initDash = async () => {
        layout.value.plans      = await getPlansType()
        layout.value.client     = await getClient(props.clientId)
        layout.value.plan       = await getPlan(props.clientId, props.planId);
        layout.value.apps       = await appsList({ byUser: layout.value.client });
    }

    onMounted( async () => { 
        await initDash();
        //layout.value.clientId         = router.currentRoute.value.params.appId
        //layout.value.planId           = router.currentRoute.value.params.planId
    });

    return { layout, user, props, profile };
}

})
</script>