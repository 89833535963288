<template>
    <div>
        <div class="account-box is-form is-footerless px-0 mx-0" :style="isMobile ? 'border-bottom: none !important;' : ''">
            <div class="form-head stuck-header" :class="isMobile ? 'py-0 ps-3 pe-2' : 'py-3'" v-if="layout.app">
                <div class="form-head-inner">
                    <div class="left"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5' : 'fs-4'">{{  layout.app.name }} </h4></div>
                    <div class="inline-block align-top fs-6 mt-0" style="margin-top: 4px;">
                    <template v-if="layout.app.logo"><img :src="layout.app.logo" style="height: 50px;" /></template>
                    <template v-else>{{  layout.app.id }}</template>
                </div>
                </div>
            </div>
            <div v-if="layout.scene?.scene" class="text-center fs-2 mt-5">{{ layout.scene.scene.name }}</div>
            <div v-if="layout.scene?.typeName" class="text-center fs-6">{{ layout.scene.scene.typeName }}</div>
            <div class="m-5">
                Devices
                <div>App: {{ layout.appId }}</div>
                <div>Escena: {{ layout.sceneId }}</div>
            </div>
        </div>
    </div>
 </template>
 <script>
 import { defineComponent, onMounted, ref, watch } from "vue";  //, onUnmounted
 import { useAuthStore } from "@/store";
 import { add } from 'ionicons/icons';
 import router from "@/router";
 import { useRoute } from 'vue-router'
 import { getApp, getScene } from "../../../model/apps.js"; 

 export default defineComponent({
   name: 'siteDevices',
   props: {
       isMobile: Boolean,
       appId: String,
       sceneId: String,
    },
    components: { },
   setup(props) {
 
      const authStore      = useAuthStore();
      const route          = useRoute()
      const layout         = ref({ 
                               appId:       null,
                               sceneId:     null,
                               scene:       null,
                           }) 

      const { user, profile } = authStore;

      onMounted( async () => { 
        layout.value.appId     = router.currentRoute.value.params.appId;
        layout.value.sceneId   = router.currentRoute.value.params.SceneId;
        layout.value.app       = await getApp(layout.value.appId)
        if(layout.value.sceneId)    
            layout.value.scene     = await getScene(layout.value.appId, layout.value.sceneId)
      });
 
       watch(() => route.query, async (query) => {
            if (query){
                layout.value.query     = query; 
                layout.value.section   = query.section;
                layout.value.appId     = router.currentRoute.value.params.appId;
                layout.value.sceneId   = router.currentRoute.value.params.SceneId;
            }
        });

       return { layout, user, profile, add, props };
   }
   
 })
 </script>