<template>
    <div>
        <!-- Device Log -->
        <div class="account-box is-form is-footerless w-full border-none" :style="isMobile ? 'border-bottom: none !important;' : ''" id="main-content">

          <div class="form-head stuck-header" :class="isMobile ? 'py-0' : 'py-2'">
              <div class="form-head-inner" v-if="layout.app">
                  <div class="left"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5 ms-2' : 'fs-4'">{{  layout.app.name }} </h4></div>
                  <div class="inline-block align-top me-1 fs-6 mt-0" style="margin-top: 4px;">
                      <template v-if="layout.app.logo"><img :src="layout.app.logo" style="height: 50px;" /></template>
                      <template v-else>{{  layout.app.id }}</template>
                  </div>
              </div>
          </div>

          <div v-if="!layout.loading && layout.app && layout.app.logs?.logs?.records" class="mt-3">
            <div class="mx-5">
              <div class="text-left fw-bold fs-1 px-3">
                <div class="align-top text-start float-start">
                  <template v-if="layout.search.type == 'today'">Hoy </template>
                  <template v-else-if="layout.search.type == 'yesterday'">Ayer <div class="fs-6">{{ layout.search.dateStart.substring(8, 10) }}/{{ layout.search.dateStart.substring(7, 5) }}/{{ layout.search.dateStart.substring(0, 4) }}</div></template>
                  <template v-else-if="layout.search.type == '3'">Últimos 3 días</template>
                  <template v-else-if="layout.search.type == '30'">Últimos 30 días</template>
                  <template v-else-if="layout.search.type == 'custom'">Personalizado</template> 
                  <div class="fs-6 fw-normal" v-if="layout.search.type == 'custom'">{{ layout.search.dateStart.substring(8, 10) }}/{{ layout.search.dateStart.substring(7, 5) }}/{{ layout.search.dateStart.substring(0, 4) }} a {{ layout.search.dateEnd.substring(8, 10) }}/{{ layout.search.dateEnd.substring(7, 5) }}/{{ layout.search.dateEnd.substring(0, 4) }}</div>
                  
                  <div class="fs-6 fw-normal">{{ layout.app.logs?.logs?.total_records }} registros</div>

                  <div class="text-xs fw-normal" v-if="layout.search.deviceId && layout.app.devices?.devices">{{ layout.app.devices.devices[layout.search.deviceId].name }}</div>
                </div> 
                <div class="mb-3 fs-6 text-end float-end fw-normal align-top friColor1">
                  <ion-menu-toggle class="cursor-pointer"><i class="bi bi-search  pe-3" :class="isMobile ? 'fs-1' : 'fs-3'"></i></ion-menu-toggle>
                  <i @click="layout.share.modal = true" class="bi bi-share cursor-pointer" style="font-size: 25px;"></i>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>

          <div v-if="!layout.loading && layout.app && layout.search.deviceId && layout.app.devices?.devices && layout.app.devices.devices[layout.search.deviceId]">

            <!-- DHT-->
            <template v-if="layout.app.devices.devices[layout.search.deviceId].type == 'dht' || layout.app.devices.devices[layout.search.deviceId].type == 'dht52'" >
              
              <div v-if="layout.search.stats" class="mb-5 mt-2">
                <ion-segment v-model="layout.section">
                  <ion-segment-button value="default" @click="layout.section = 'default'"><ion-label class="text-dark" :class="layout.section == 'default' ? ' friColor1 fw-bold': ''">Temperatura</ion-label></ion-segment-button>
                  <ion-segment-button value="hum" @click="layout.section = 'hum'"><ion-label class="text-dark" :class="layout.section == 'hum' ? ' friColor1 fw-bold': ''">Humedad</ion-label></ion-segment-button>
                </ion-segment>
              </div>
              
              <ion-row class="ion-align-items-center ion-justify-content-center" v-if="layout.search.stats">
                <ion-col size="3" class="text-center">
                  <div class="friBack6 p-4 br-8">
                    <div class="fs-6 fw-normal">Mínima</div>
                    <div class="fs-4 fw-bold" v-if="layout.section == 'default'">{{ layout.search.stats.minTemp }} <span class="fs-6">ºC</span></div>
                    <div class="fs-4 fw-bold" v-if="layout.section == 'hum'">{{ layout.search.stats.minHum }} <span class="fs-6">%</span></div>
                  </div>
                </ion-col>
                <ion-col size="3" class="text-center">
                  <div class="friBack6 p-4 br-8">
                    <div class="fs-6 fw-normal">Media</div>
                    <div class="fs-4 fw-bold" v-if="layout.section == 'default'">{{ layout.search.stats.avgTemp }} <span class="fs-6">ºC</span></div>
                    <div class="fs-4 fw-bold" v-if="layout.section == 'hum'">{{ layout.search.stats.avgHum }} <span class="fs-6">%</span></div>
                  </div>
                </ion-col>
                <ion-col size="3" class="text-center">
                  <div class="friBack6 p-4 br-8">
                    <div class="fs-6 fw-normal">Máxima</div>
                    <div class="fs-4 fw-bold" v-if="layout.section == 'default'">{{ layout.search.stats.maxTemp }} <span class="fs-6">ºC</span></div>
                    <div class="fs-4 fw-bold" v-if="layout.section == 'hum'">{{ layout.search.stats.maxHum }} <span class="fs-6">%</span></div>
                  </div>
                </ion-col>
              </ion-row>
              
            </template>

            <!-- DOOR -->
            <template v-else-if="layout.app.devices.devices[layout.search.deviceId].type == 'lds02'">

              <div v-if="layout.search.stats" class="mx-5 mt-3">
                <div class="friBack6 p-5 br-8 mx-2">
                  <div class="fs-6 fw-normal" v-if="layout.search.stats.hasOwnProperty('open')">Total aperturas: <span class="fw-bold">{{ layout.search.stats.open }}</span></div>
                  <div class="fs-6 fw-normal" v-if="layout.search.stats.hasOwnProperty('maxOpenDuration') && (layout.search.stats?.open && layout.search.stats.open > 1)">
                    Apertura máxima: 
                    <span class="fw-bold">
                      {{ layout.search.stats.maxOpenDuration.duration.days > 0 ? layout.search.stats.maxOpenDuration.duration.days + ' d ' : '' }}
                      {{ layout.search.stats.maxOpenDuration.duration.hours > 0 ? layout.search.stats.maxOpenDuration.duration.hours + ' h ' : '' }}
                      {{ layout.search.stats.maxOpenDuration.duration.minutes > 0 ? layout.search.stats.maxOpenDuration.duration.minutes + ' m ' : '' }}
                      {{ layout.search.stats.maxOpenDuration.duration.seconds }} s
                    </span>
                    <div class="text-xs ps-3 pt-1">Desde {{ formatDateHour(layout.search.stats.maxOpenDuration.start) }} hasta {{ formatDateHour(layout.search.stats.maxOpenDuration.end) }}</div>
                  </div>
                  <div class="fs-6 fw-normal" v-if="layout.search.stats.hasOwnProperty('avgOpenDuration')">
                    Total abierto: 
                    <span class="fw-bold">
                      {{ layout.search.stats.avgOpenDuration.days > 0 ? layout.search.stats.avgOpenDuration.days + ' d ' : '' }}
                      {{ layout.search.stats.avgOpenDuration.hours > 0 ? layout.search.stats.avgOpenDuration.hours + ' h ' : '' }}
                      {{ layout.search.stats.avgOpenDuration.minutes > 0 ? layout.search.stats.avgOpenDuration.minutes + ' m ' : '' }}
                      {{ layout.search.stats.avgOpenDuration.seconds }} s
                    </span>
                  </div>
                </div>
              </div>
              
              <!--
                <div v-if="Object.keys(layout.search.stats).length" class="mb-5 mt-2">
                  <pre>{{ layout.search }}</pre>
              </div>
              -->
            </template>

            <!-- GRAPHS -->
            <div class="mx-5" v-if="!layout.loading && layout.app && layout.app.logs?.logs?.records && layout.app.logs?.logs?.records.length">
              <div class="ms-1" id="friChartLog" style="height: 30vh;width: 95%;" :class="!layout.loading && layout.app && layout.app.logs?.logs?.records && layout.section == 'default' ? '' : 'hidden'"></div>
              <div class="ms-1" id="friChartLogHum" style="height: 30vh;width: 95%;" :class="!layout.loading && layout.app && layout.app.logs?.logs?.records && layout.section == 'hum' ? '' : 'hidden'"></div>
            </div>

          </div>

          <template v-if="!layout.loading && layout.app && layout.app.logs?.logs?.records">
              <div class="my-5 mx-3" v-if="!layout.showTable && layout.app.logs?.logs?.records.length" @click="layout.showTable = true">
                <div class="py-3 px-5 friBack4 text-dark mx-5 br-8 text-center cursor-pointer"> <i class="bi bi-text-right"></i> Ver los {{  layout.app.logs?.logs?.total_records }} registros</div>
              </div>
              <div v-else style="padding-bottom: 150px;">
                  <template v-if="layout.app.logs?.logs?.records.length">
                    <div class="mt-2 mx-2">
                      <ion-grid class="px-5">

                        <div v-for="(dateLogs, date) in groupedLogs" :key="date">
                          <ion-row class="w-100 py-1" @click="!layout.currentResultDate ? layout.currentResultDate = date : layout.currentResultDate = false">
                            <ion-col class="fs-6 fw-bold cursor-pointer" style="border-bottom: 2px solid #e6e6e6;">
                              <i class="bi bi-chevron-compact-down fs-6 align-middle" v-if="date == layout.currentResultDate"></i>
                              <i class="bi bi-chevron-compact-right fs-6 align-middle" v-else></i> 
                              {{ formatDate(date) }} <span class="badge friBackWhite text-dark friBorder3 text-end float-end me-2 px-3 align-top fw-normal text-xs" v-if="Object.keys(groupedLogs).length > 1">{{ dateLogs.length }} registros</span>
                            </ion-col>
                          </ion-row>
                          <div :class="date == layout.currentResultDate ||  Object.keys(groupedLogs).length < 2 ? '' : 'hidden'">

                                <ion-row class="fs-6  mx-5" v-if="dateLogs[0] && dateLogs[0]?.msg && dateLogs[0]?.msg?.TempC_SHT">
                                  <ion-col style="max-width: 120px !important" class="text-left ps-2"></ion-col>
                                  <ion-col class="text-center">Temperatura</ion-col>
                                  <ion-col class="text-center pe-2" v-if="dateLogs[0]?.msg?.Hum_SHT">Humedad</ion-col>
                                </ion-row>

                                <ion-row class="w-100 cursor-pointer text-xs3" v-for="(log, index) in dateLogs" :key="index" @click="layout.alarmBox = true; layout.alarmEdit = log" :class="index % 2 == 0 ? 'friBack9' : ''" >
                                  <template v-if="log && log.msg">
                                    <template v-if="log.msg.hasOwnProperty('TempC_SHT')">
                                      <ion-col style="max-width: 120px !important" class="text-center ps-2">{{ log.date.substring(11, 19) }}</ion-col>
                                      <ion-col class="text-center">
                                        <i class="bi bi-sort-down-alt friColor1 pe-2" v-if="layout.search.stats && layout.search.stats.minTemp && layout.search.stats.minTemp == parseFloat(log.msg.TempC_SHT).toFixed(1)"></i> 
                                        <i class="bi bi-sort-up-alt pe-2" v-if="layout.search.stats && layout.search.stats.minTemp && layout.search.stats.maxTemp == parseFloat(log.msg.TempC_SHT).toFixed(1)"></i> 
                                        <span>{{ log.msg.TempC_SHT ? parseFloat(log.msg.TempC_SHT).toFixed(1) + 'ºc': '-' }}</span>
                                      </ion-col>
                                      <ion-col class="text-center pe-2" v-if="log.msg.hasOwnProperty('Hum_SHT')">
                                        <i class="bi bi-sort-down-alt friColor1 pe-2" v-if="layout.search.stats && layout.search.stats.minTemp && layout.search.stats.minHum == parseFloat(log.msg.Hum_SHT).toFixed(1)"></i> 
                                        <i class="bi bi-sort-up-alt pe-2" v-if="layout.search.stats && layout.search.stats.minTemp && layout.search.stats.maxHum == parseFloat(log.msg.Hum_SHT).toFixed(1)"></i> 
                                        {{ log.msg.Hum_SHT ? parseFloat(log.msg.Hum_SHT).toFixed(1) + '%': '-' }}
                                      </ion-col>
                                    </template>
                                    <template v-else-if="log.msg.hasOwnProperty('DOOR_OPEN_TIMES')">
                                      <ion-col style="max-width: 70px !important" class="text-center ps-2">{{ log.date.substring(11, 19) }}</ion-col>
                                      <ion-col class="text-center"><span>{{ log.msg.DOOR_OPEN_STATUS ? "Abierto" : 'Cerrado' }}</span></ion-col>
                                    </template>
                                    <template v-else>
                                    <pre>{{ log }}</pre>
                                    </template>

                                  </template>
                                </ion-row>

                              
                              <div class="mt-5 text-left text-xs3 mx-5" v-if="dateLogs[0] && dateLogs[0]?.msg && dateLogs[0]?.msg?.TempC_SHT">
                                <i class="bi bi-sort-down-alt friColor1"></i> Mínima
                                <i class="bi bi-sort-up-alt ps-5"></i> Máxima
                              </div>

                          </div>
                        </div>

                      </ion-grid>
                    </div>

                  </template>
                  <template v-else>
                    <div class="text-center mt-5"><i class="bi bi-wind friOpacity3" style="font-size: 80px;"></i></div>
                    <div class="text-center">No dispone de registros</div>
                  </template>
              </div>
          </template>  
          <template v-else>
            <div class="text-center w-100"  style="margin: 150px 0;">
                <div class="fw-bold fs-3">Registros</div>
                <div class="mt-2">Espere por favor ...</div>
                <div class="spinner-border friColor1 mt-5" role="status">
                  <span class="visually-hidden">Cargando ...</span>
                </div>
            </div>
          </template>

        </div>

        <ion-modal :is-open="layout.share.modal" :backdrop-dismiss="false">
          <ion-content>
            <div class="text-center pt-5">
              <div style="padding-top: 50px;"><i class="bi bi-share-fill" style="font-size: 90px;"></i></div>
              <div class="fs-3">Compartir reporte</div>
              <div class="mt-2" v-if="!layout.share.loading">
                  <div class="fs-6">Introduzca los datos del destinatario</div>
                  <ion-list class="mt-5 pt-0 mx-5">
                      <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                          <ion-label position="floating" class="friColor1 fs-6">Reporte</ion-label>
                          <ion-input v-model="layout.share.subject" type="text" :disabled="true" class="fs-6"></ion-input>
                      </ion-item>
                      <ion-item lines="full" style="--ion-item-border-color: #ccc;">
                          <ion-label position="floating" class="friColor1 fs-6" >Email *</ion-label>
                          <ion-input v-model="layout.share.email" type="email" class="fs-6"></ion-input>
                      </ion-item>
                  </ion-list>
                  <div class="mt-3" style="padding: 25px 0 50px 0;">
                      <div class="d-flex justify-content-center">
                          <button class="button px-5 py-1 m-0 mt-0 bg-light" @click="layout.share.modal = false">Cancelar</button>
                          <button class="button px-5 py-1 mt-0 friBack1 text-white ms-3" @click="exportReport()">Enviar</button>
                      </div>
                  </div>
              </div>
              <div v-else class="mt-5">
                <div class="spinner-border friColor1 mt-5" role="status">
                  <span class="visually-hidden">Enviando reporte ...</span>
                </div>
              </div>
            </div>
          </ion-content>
        </ion-modal>

        <!-- Filter Panel -->
        <ion-menu content-id="main-content">
          <ion-header>
            <ion-toolbar>
              <ion-title>
                <span class="align-middle">Filtros de búsqueda</span>
                <ion-menu-toggle class="text-end float-end fs-2 align-top">X</ion-menu-toggle>
              </ion-title>
            </ion-toolbar>
          </ion-header>
          <ion-content class="ion-padding">
            <div class="mb-3 ms-1" v-if="layout.search.dateStart" :class="isMobile ? 'me-4' : 'me-4'">
              <div class="mb-3">
                <div class="mb-3">Fecha:</div>
                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                    <ion-select interface="popover" v-model="layout.search.type" class="border-none">
                      <ion-select-option v-for="(type, index) in layout.searchTypes" :key="index" :value="type.value">{{ type.label }}</ion-select-option>     
                    </ion-select>
                </ion-item>
              </div>
              <template v-if="layout.search.type && layout.search.type == 'custom'">
                <div class="mb-3">
                  <div class="mb-3">Fecha de inicio:</div>
                  <div @click="layout.modal.startDate = true" class="friBorder3 br-5 px-3 py-4 text-center cursor-pointer">
                    {{ layout.search.dateStart.substring(8, 10) }}/{{ layout.search.dateStart.substring(7, 5) }}/{{ layout.search.dateStart.substring(0, 4) }}
                  </div>
                  <ion-modal :is-open="layout.modal.startDate" :backdrop-dismiss="false">
                    <div class="p-5 mt-5">
                      <div class="d-flex justify-content-center" v-if="layout.search.dateStart">
                        <ion-datetime locale="es-ES" :first-day-of-week="1" presentation="date" :show-default-title="true" :show-default-buttons="false"  v-model="layout.search.dateStart" @ionChange="layout.modal.startDate = false" color="fricolor" :max="layout.search.maxDate" :min="layout.search.minDate">
                          <span slot="time-label">Hora</span>
                          <span slot="title" class="text-white">Fecha de inicio</span>
                        </ion-datetime> 
                      </div>  
                    </div>
                  </ion-modal>
                </div>
                <div class="mb-3">
                  <div class="mb-3">Fecha fin:</div>
                  <div @click="layout.modal.endDate = true" class="friBorder3 br-5 px-3 py-4 text-center cursor-pointer">
                    {{ layout.search.dateEnd.substring(8, 10) }}/{{ layout.search.dateEnd.substring(7, 5) }}/{{ layout.search.dateEnd.substring(0, 4) }}
                  </div>
                  <ion-modal :is-open="layout.modal.endDate" :backdrop-dismiss="false">
                    <div class="p-5 mt-5">
                      <div class="d-flex justify-content-center" v-if="layout.search.dateEnd">
                        <ion-datetime locale="es-ES" :first-day-of-week="1" presentation="date" :show-default-title="true" :show-default-buttons="false"  
                          v-model="layout.search.dateEnd" @ionChange="layout.modal.endDate = false" color="fricolor" :max="layout.search.maxDate" :min="layout.search.dateStart">
                          <span slot="time-label">Hora</span>
                          <span slot="title" class="text-white">Fecha fin</span>
                        </ion-datetime> 
                      </div>  
                    </div>
                  </ion-modal>
                  <!-- <input type="date" class="form-control py-2" id="dateEnd" v-model="layout.search.dateEnd"  /> -->
                </div>
              </template>
              <div class="mb-3" v-if="layout.app?.devices?.devices">
                <div class="mb-3">Equipo/Sensor:</div>
                <ion-item lines="full" class="border-none" style="--ion-item-border-color: #ccc;">
                    <ion-select interface="popover" v-model="layout.search.deviceId" class="border-none">
                        <ion-select-option v-for="(device, index) in layout.app.devices.devices" :key="index" :value="device.deviceId">{{ (device.name.length > 22) ? device.name.substring(0, 22) + '...' : device.name  }}</ion-select-option>
                    </ion-select>
                </ion-item>
              </div>
              <div class="mt-5"><span class="badge friBack1 fs-6 cursor-pointer py-4 w-100 px-0 mb-2" @click="filterLog()">Buscar</span></div>
              <div class="mt-3 text-center"><ion-menu-toggle class="friColor4 cursor-pointer" id="closeFilters">CERRAR</ion-menu-toggle></div>
            </div>
          </ion-content>
        </ion-menu>  

        <!--
        <pre v-if="layout.search">{{ layout.search  }}</pre>
        <pre v-if="layout.search">{{ layout.app  }}</pre>
        -->

    </div>
  </template>
  <script>
  import { defineComponent, onMounted, ref,  } from "vue"; //onUnmounted
  import { useAuthStore } from "@/store";
  import { helper as $h } from "../../../utils/helper.js";
  import { getApp, getAppLogs, getAppDevices, exportStats  } from "../../../model/apps.js"; //
  //import { _firebase as $_firebase } from "../../../model/firebase";
  //import { onSnapshot, collection } from "firebase/firestore"; 
  import { IonDatetime, IonModal, IonItem, IonSelect, IonRow, IonCol, IonGrid, IonContent, IonHeader, IonMenu, IonMenuToggle, IonTitle, IonToolbar, IonSegment, IonSegmentButton, IonList, IonInput } from '@ionic/vue'; 
  import { Chart, LineController, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';
  import annotationPlugin from 'chartjs-plugin-annotation';
  Chart.register(LineController, CategoryScale, LinearScale, PointElement, LineElement, annotationPlugin);
  import { not as $not } from "../../../model/not";

  //const db   =  $_firebase.firestore()
  export default defineComponent({
    name: 'DeviceLog',
    props: {
        isMobile: Boolean,
        appId:    String
     },
    computed: {
      groupedLogs() {
        const grouped = {};
        const logs    = this.layout.app.logs?.logs?.records;
        if (!logs) return grouped;
        this.layout.app.logs?.logs?.records.forEach((log = {}) => {
          const date = log.date.substring(0, 10);
          if (!grouped[date])grouped[date] = [];
          grouped[date].push(log);
        });
        return grouped;
      },
    },
    components: { IonDatetime, IonModal, IonItem, IonSelect, IonRow, IonCol, IonGrid, IonContent, IonHeader, IonMenu, IonMenuToggle, IonTitle, IonToolbar, IonSegment, IonSegmentButton, IonList, IonInput },
    setup(props) {

       const authStore      = useAuthStore();
       const layout         = ref({ 
                                    loading:  true,
                                    appId:    null,
                                    app:      null,
                                    helper:   $h,
                                    search:  { type: "today", dateStart: null, dateEnd: null, maxDate: null, minDate: null, deviceId: null },
                                    searchTypes: [ 
                                                  { value: "today", label: "Hoy" }, 
                                                  { value: "yesterday", label: "Ayer" }, 
                                                  { value: "3", label: "Últimos 3 días" }, 
                                                  { value: "custom", label: "Personalizado" } 
                                                ],
                                    modal:     { startDate: false, endDate: false },
                                    section:   "default",
                                    showTable: false,
                                    colors:    { "red" : "rgb(255, 99, 132)", "blue" : "rgb(75, 192, 192)", "yellow" : "rgb(255, 205, 86)" },
                                    share:     {
                                                modal:    false,
                                                email:    null,
                                                loading:  false,
                                                subject:  "",
                                                filename: "",
                                               },
                                    currentResultDate: null,
                                  }) 

      const { user, profile } = authStore;

      const initLogs = async () => {
         layout.value.app         = null; 
         layout.value.appId       = props.appId
         layout.value.app         = await getApp(props.appId)
         layout.value.app.devices = await getAppDevices(props.appId);
         if(profile?.email)layout.value.share.email =  profile.email;
         if(document.getElementById('closeFilters'))document.getElementById('closeFilters').click();
         await initFilters();
         await getLogs();
         layout.value.loading  = false;
      }

      const getLogs = async () => {
          layout.value.share.subject   = "Reporte de registros"
          layout.value.share.filename  = "alba_hub_report"
          layout.value.share.subject  += layout.value.search.deviceId ? " - " + layout.value.app.devices.devices[layout.value.search.deviceId].name : "";
          layout.value.share.filename += layout.value.search.deviceId ? " " + layout.value.app.devices.devices[layout.value.search.deviceId].name : "";
          if(layout.value.search.type == "today"){  
            const currentDate = new Date();
            currentDate.setHours(currentDate.getHours() + 1);
            layout.value.search.dateStart = layout.value.search.dateEnd = currentDate.toISOString().substring(0, 10);
            layout.value.share.subject   += " - " + layout.value.search.dateStart.substring(8, 10) + "/" + layout.value.search.dateStart.substring(5, 7) + "/" + layout.value.search.dateStart.substring(0, 4);
            layout.value.share.filename  += "_" + layout.value.search.dateStart.substring(8, 10) + layout.value.search.dateStart.substring(5, 7) + layout.value.search.dateStart.substring(0, 4);
          }else if(layout.value.search.type == "yesterday"){
            const yesterday = new Date();
            yesterday.setHours(yesterday.getHours() + 1);
            yesterday.setDate(yesterday.getDate() - 1);
            layout.value.search.dateStart = layout.value.search.dateEnd = yesterday.toISOString().substring(0, 10);
            layout.value.share.subject   += " - " + layout.value.search.dateStart.substring(8, 10) + "/" + layout.value.search.dateStart.substring(5, 7) + "/" + layout.value.search.dateStart.substring(0, 4);
            layout.value.share.filename  += "_" + layout.value.search.dateStart.substring(8, 10) + layout.value.search.dateStart.substring(5, 7) + layout.value.search.dateStart.substring(0, 4);
          }else if(layout.value.search.type == "3"){
            const dateStart = new Date();
            dateStart.setHours(dateStart.getHours() + 1);
            dateStart.setDate(dateStart.getDate() - 2);
            layout.value.search.dateStart = dateStart.toISOString().substring(0, 10);
            layout.value.search.dateEnd   = new Date().toISOString().substring(0, 10);
            layout.value.share.subject   += " - " + layout.value.search.dateStart.substring(8, 10) + "/" + layout.value.search.dateStart.substring(5, 7) + "/" + layout.value.search.dateStart.substring(0, 4);
            layout.value.share.subject   += " a " + layout.value.search.dateEnd != layout.value.search.dateStart ? " a " + layout.value.search.dateEnd.substring(8, 10) + "/" + layout.value.search.dateEnd.substring(5, 7) + "/" + layout.value.search.dateEnd.substring(0, 4) : "";
            layout.value.share.filename  += "_" + layout.value.search.dateStart.substring(8, 10) + layout.value.search.dateStart.substring(5, 7) + layout.value.search.dateStart.substring(0, 4);
            layout.value.share.filename  += "_" + layout.value.search.dateEnd.substring(8, 10) + layout.value.search.dateEnd.substring(5, 7) + layout.value.search.dateEnd.substring(0, 4);
          }else if(layout.value.search.type == "custom"){
            layout.value.share.subject   += " - " + layout.value.search.dateStart.substring(8, 10) + "/" + layout.value.search.dateStart.substring(5, 7) + "/" + layout.value.search.dateStart.substring(0, 4);
            layout.value.share.subject   += " a " + layout.value.search.dateEnd != layout.value.search.dateStart ? " a " + layout.value.search.dateEnd.substring(8, 10) + "/" + layout.value.search.dateEnd.substring(5, 7) + "/" + layout.value.search.dateEnd.substring(0, 4) : "";
            layout.value.share.filename  += "_" + layout.value.search.dateStart.substring(8, 10) + layout.value.search.dateStart.substring(5, 7) + layout.value.search.dateStart.substring(0, 4);
            layout.value.share.filename  += "_" + layout.value.search.dateEnd.substring(8, 10) + layout.value.search.dateEnd.substring(5, 7) + layout.value.search.dateEnd.substring(0, 4);
          }
          layout.value.share.filename = layout.value.share.filename.replace(/[^a-zA-Z0-9]/g, "_");
          layout.value.app.logs       = await getAppLogs(props.appId, { appId: props.appId, from_date: layout.value.search.dateStart, to_date: layout.value.search.dateEnd, device_id: layout.value.search.deviceId });
          if(layout.value.app && layout.value.app.devices && layout.value.app.devices.devices && layout.value.search.deviceId && layout.value.app.devices.devices[layout.value.search.deviceId] && layout.value.app.devices.devices[layout.value.search.deviceId].type == 'lds02'){
            layout.value.search.stats = {};
          }
          layout.value.loading        = false;
          setTimeout(async () => { await initChart(); }, 500);
      }

      const initFilters = async () => {
        let currentDate     = new Date();
        let year            = currentDate.getFullYear();
        let month           = String(currentDate.getMonth() + 1).padStart(2, '0');
        let day             = String(currentDate.getDate()).padStart(2, '0'); 
        let localDateString = `${year}-${month}-${day}`;
        layout.value.search.dateStart = layout.value.search.dateEnd = layout.value.search.maxDate = localDateString // new Date().toISOString().substring(0, 10);
        const minDate       = new Date();
        minDate.setDate(minDate.getDate() - 7);
        layout.value.search.minDate   = minDate.toISOString().substring(0, 10);
        if (layout.value.app.devices?.devices) {
            const devices = layout.value.app.devices.devices;
            Object.keys(devices).forEach((deviceId) => {
                if(!layout.value.search.deviceId)layout.value.search.deviceId = deviceId;
            });
        }
      }

      const filterLog = async () => {
          layout.value.loading   = true;
          layout.value.section   = "default";
          layout.value.showTable = false;
          if(document.getElementById('closeFilters'))document.getElementById('closeFilters').click();
          setTimeout(async () => { await getLogs(); }, 1000);
      }

      const exportReport = async () => {
        let validate = true
        if(layout.value.share.email == null || layout.value.share.email == ""){ validate = false; }
        if(validate && !layout.value.share.email.includes('@'))validate = false; 
        if(validate){
          layout.value.share.loading = true;
          let currentSearch = layout.value.search;
          if(currentSearch?.stats?.openDurations) delete currentSearch.stats.openDurations;
          let exportData    = { 
                              type: "log", 
                              data: { 
                                      "appId":       props.appId, 
                                      "format":      "pdf", 
                                      "to":          layout.value.share.email, 
                                      "subject":     layout.value.share.subject, 
                                      "filename":    layout.value.share.filename, 
                                      "search":      layout.value.search,
                                    } };
                         
          let sendReport = await exportStats(exportData); 

          if(!sendReport || sendReport?.error) $not.toastApp({ message: 'Error al enviar el reporte, inténtelo de nuevo', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
          else{
            layout.value.share.loading  = false;
            layout.value.share.modal    = false;
            $not.toastApp({ message: 'El reporte se ha enviado correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'primary', cssClass: 'text-white mt-2' })
          }
          layout.value.share.loading = false;
        }else $not.toastApp({ message: 'Debe completar todos los campos requeridos *', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
      }
        
      const initChart = async () => {
        const logs = layout.value.app.logs?.logs?.records;
        layout.value.search.stats = {};
        if(!logs || logs.length == 0){
          layout.value.loading = false;
          return;
        }
        if(layout.value.app && layout.value.app.devices && layout.value.app.devices.devices && layout.value.search.deviceId && layout.value.app.devices.devices[layout.value.search.deviceId]){

          //DHT ________________________________________________________________________________________________
          if(logs && logs[0] && logs[0].msg?.TempC_SHT){// if(layout.value.app.devices.devices[layout.value.search.deviceId].type == 'dht' || layout.value.app.devices.devices[layout.value.search.deviceId].type == 'dht52'){
            
            const temperatureData = logs.map(log => parseFloat(log.msg.TempC_SHT).toFixed(2));
            layout.value.search.stats = {
                maxTemp: parseFloat(Math.max(...temperatureData)).toFixed(1),
                minTemp: parseFloat(Math.min(...temperatureData)).toFixed(1),
                avgTemp: (temperatureData.reduce((acc, curr) => acc + parseFloat(curr), 0) / temperatureData.length).toFixed(1),
            };
            
            // TEMPERATURE CHART
            if (document.getElementById("friChartLog")) { 
              document.getElementById("friChartLog").innerHTML = "";
              const canvasElement = document.createElement('canvas');
              document.getElementById("friChartLog").appendChild(canvasElement);
              const ctx  = canvasElement.getContext('2d');
              const data = { labels: logs.map(log => log.date.substring(11, 19)), datasets: [ { label: 'Temperatura', data: temperatureData, backgroundColor: layout.value.colors['red']} ] };
              data.datasets.push({ label: 'Máxima', data: Array(data.labels.length).fill(layout.value.search.stats.maxTemp), borderColor: layout.value.colors['red'], fill: false, borderWidth: 2, borderDash: [5, 5] });
              data.datasets.push({ label: 'Mínima', data: Array(data.labels.length).fill(layout.value.search.stats.minTemp), borderColor: layout.value.colors['blue'], fill: false, borderWidth: 2, borderDash: [5, 5] });
              data.datasets.push({ label: 'Media', data: Array(data.labels.length).fill(layout.value.search.stats.avgTemp), borderColor:  layout.value.colors['yellow'], fill: false, borderWidth: 2, borderDash: [5, 5] });
              new Chart(ctx, {
                type: 'line', data: data,
                options: {
                  maintainAspectRatio: false,
                  scales:  {  x: { display: false }, y: { display: true, title: { display: false }, grid: { color: 'transparent' }, ticks: { callback: function(value) { return value.toString().replace('-', '−') + '°C'; }, stepSize: 1, maxTicksLimit: 10 } } },
                  plugins: {
                    legend:  { display: false, position: 'bottom' }, title:   { display: false, text: 'Temperatura' },
                    tooltip: { enabled: true, mode: 'index', intersect: false, callbacks: { label: function(context) { return context.dataset.label + ': ' + context.parsed.y + '°C'; } }}
                  },
                  elements: { point: { radius: 0 } },
                },
              });
            }

            if(logs && logs[0] && logs[0].msg?.Hum_SHT){
              const humidityData    = logs.map(log => parseFloat(log.msg.Hum_SHT).toFixed(2));
              layout.value.search.stats.maxHum = parseFloat(Math.max(...humidityData)).toFixed(1)
              layout.value.search.stats.minHum = parseFloat(Math.min(...humidityData)).toFixed(1)
              layout.value.search.stats.avgHum = (humidityData.reduce((acc, curr) => acc + parseFloat(curr), 0) / humidityData.length).toFixed(1)
            
              // HUMIDITY CHART
              if (document.getElementById("friChartLogHum")) {
                  document.getElementById("friChartLogHum").innerHTML = "";
                  const canvasElementHum = document.createElement('canvas');
                  document.getElementById("friChartLogHum").appendChild(canvasElementHum);
                  const ctxHum           = canvasElementHum.getContext('2d');
                  const dataHum          = { labels: logs.map(log => log.date.substring(11, 19)), datasets: [ { label: 'Humedad', data: humidityData, backgroundColor: layout.value.colors['red']} ] };
                  dataHum.datasets.push({ label: 'Máxima', data: Array(dataHum.labels.length).fill(layout.value.search.stats.maxHum), borderColor: layout.value.colors['red'], fill: false, borderWidth: 2, borderDash: [5, 5] });
                  dataHum.datasets.push({ label: 'Mínima', data: Array(dataHum.labels.length).fill(layout.value.search.stats.minHum), borderColor: layout.value.colors['blue'], fill: false, borderWidth: 2, borderDash: [5, 5] });
                  dataHum.datasets.push({ label: 'Media', data: Array(dataHum.labels.length).fill(layout.value.search.stats.avgHum), borderColor:  layout.value.colors['yellow'], fill: false, borderWidth: 2, borderDash: [5, 5] });
                  new Chart(ctxHum, {
                    type: 'line', data: dataHum,
                    options: {
                      maintainAspectRatio: false,
                      scales:  {  x: { display: false }, y: { display: true, title: { display: false }, grid: { color: 'transparent' }, ticks: { callback: function(value) { return value.toString().replace('-', '−') + '%'; }, stepSize: 1, maxTicksLimit: 10 } } },
                      plugins: {
                        legend:  { display: false, position: 'bottom' }, title:   { display: false, text: 'Humedad' },
                        tooltip: { enabled: true, mode: 'index', intersect: false, callbacks: { label: function(context) { return context.dataset.label + ': ' + context.parsed.y + '%'; } }}
                      },
                      elements: { point: { radius: 0 } },
                    },
                  });

              }
            }
          }
          
          // DOOR ________________________________________________________________________________________________
          if (layout.value.app.devices.devices[layout.value.search.deviceId].type === 'lds02') {
              logs.reverse();
              const openData = logs.map(log => log.msg.DOOR_OPEN_STATUS);
              const openDurations = [];
              for (let i = 0; i < logs.length; i++) {
                  if (logs[i].msg?.DOOR_OPEN_STATUS) {
                      let end = false;
                      for (let j = i + 1; j < logs.length; j++) {
                          if (!logs[j].msg?.DOOR_OPEN_STATUS) {
                              end = logs[j].date;
                              break;
                          }
                      }
                      if(end){
                        let durationInSeconds = end ? (new Date(end) - new Date(logs[i].date)) / 1000 : 0;
                        openDurations.push({
                            tipo: "apertura",
                            start: logs[i].date,
                            end: end,
                            duration: {
                                days:     Math.floor(durationInSeconds / (3600 * 24)),
                                hours:    Math.floor((durationInSeconds % (3600 * 24)) / 3600),
                                minutes:  Math.floor((durationInSeconds % 3600) / 60),
                                seconds:  durationInSeconds % 60
                            }
                        });
                      }
                  }
              }

              layout.value.search.stats = {
                  open: openData.filter(value => value).length,
                  close: openData.filter(value => !value).length,
                  total: openData.length,
                  openDurations: openDurations
              };

              if (openDurations.length > 0) {
                
                  const durationData = openDurations.map(log => log.duration.hours + (log.duration.minutes / 60) + (log.duration.seconds / 3600));
                  const maxOpenDuration = Math.max(...durationData);
                  const maxOpenDurationLog = openDurations.find(log => log.duration.hours + (log.duration.minutes / 60) + (log.duration.seconds / 3600) === maxOpenDuration);
                  layout.value.search.stats["maxOpenDuration"] = maxOpenDurationLog;

                  const totalDurationInSeconds = openDurations.reduce((total, log) => {
                      const durationInSeconds = log.duration.hours * 3600 + log.duration.minutes * 60 + log.duration.seconds;
                      return total + durationInSeconds;
                  }, 0);

                  const totalDays = Math.floor(totalDurationInSeconds / (24 * 3600));
                  const remainingSecondsAfterDays = totalDurationInSeconds % (24 * 3600);
                  const totalHours = Math.floor(remainingSecondsAfterDays / 3600);
                  const remainingSecondsAfterHours = remainingSecondsAfterDays % 3600;
                  const totalMinutes = Math.floor(remainingSecondsAfterHours / 60);
                  const totalSeconds = remainingSecondsAfterHours % 60;

                  layout.value.search.stats["avgOpenDuration"] = {
                    days: totalDays,
                    hours: totalHours,
                    minutes: totalMinutes,
                    seconds: totalSeconds
                  };
              }

              logs.reverse();
          }

        } 
      }

      onMounted( async () => { 
         await initLogs();
         //await detectChanges();
      });


       /*
       const detectChanges = async () => {
          const collectionPath = "apps/" + props.appId + "/devices/";
          const collRef        = collection(db, collectionPath);
          const unsubscribeDetectChanges = onSnapshot(collRef, async (querySnapshot) => {
              querySnapshot.docChanges().forEach(async change => { 
                  if (change.type === "modified"){ await getLogs();} 
              }); 
          }, (error) => { console.log("Error:", error); });
          onUnmounted(() => { unsubscribeDetectChanges(); });
       };*/

      return { layout, user, profile, filterLog, exportReport };
    },
    methods: {
      formatDate(date) {
        return date.substring(8, 10) + '/' + date.substring(5, 7) + '/' + date.substring(0, 4);
      },
      formatDateHour(date) {
        return date.substring(8, 10) + '/' + date.substring(5, 7) + '/' + date.substring(0, 4) + ' ' + date.substring(11, 19);
      },
    },
  })
  </script>
  <style>
  :root {
    --ion-color-fricolor: #009292;
    --ion-color-fricolor-rgb: 0, 146, 146;
    --ion-color-fricolor-contrast: #ffffff;
    --ion-color-fricolor-contrast-rgb: 255, 255, 255;
    --ion-color-fricolor-shade: #73153b;
    --ion-color-fricolor-tint: #8f2f56;
    /*--ion-text-color: #881337;
    --ion-text-color-rgb: 136, 19, 55;*/

  }

  .ion-color-fricolor {
    --ion-color-base: var(--ion-color-fricolor);
    --ion-color-base-rgb: var(--ion-color-fricolor-rgb);
    --ion-color-contrast: var(--ion-color-fricolor-contrast);
    --ion-color-contrast-rgb: var(--ion-color-fricolor-contrast-rgb);
    --ion-color-shade: var(--ion-color-fricolor-shade);
    --ion-color-tint: var(--ion-color-fricolor-tint);
  }

  ion-datetime {
    --background: #fff;
    --background-rgb: 255, 255, 255;
    border-radius: 16px;
    box-shadow:  none;
  }

 
</style>