<template>
    <div v-if="props.app">
        <div class="text-left friBackWhite mx-4 ps-2 br-8 mt-3" v-if="app.weather">
            <ion-row>
                <ion-col style="max-width: 220px;" class="text-left pt-2 ps-2">
                    <div class="fs-5">{{ app.weather.city }}</div>
                    <div class="fw-bold" style="font-size: 22px; line-height: 30px;">{{ app.weather.temp }}<span class="fs-1 align-top"> &deg;</span></div>
                    <div class="text-xs3">Min: {{ app.weather.temp_min }}<span class="fs-6 align-top">&deg;</span> / Max: {{ app.weather.temp_max }}<span class="fs-6 align-top">&deg;</span></div>
                    <!--<div class="fs-6">{{ app.weather.description }}</div>-->
                </ion-col>
                <ion-col style="max-width: 90px;" class="px-0 py-2m-0" v-if="app.weather">
                    <img :src="app.weather.icon ? require(`../../../assets/images/icon/weather/${app.weather.icon}.png`) : ''" style="width:80px;" class="weather-icon friOpacity6">
                </ion-col>
            </ion-row>
        </div>
    </div>
</template>
<script>
import { defineComponent, ref, onMounted, onUnmounted } from 'vue';
import { useAuthStore } from '@/store';
import { IonCol, IonRow  } from '@ionic/vue';
import { helper as $h } from "../../../utils/helper.js"; 
import axios from 'axios';

export default defineComponent({
name: 'WeatherComponent',
props: { 
    isMobile: Boolean,
    app: Object
 },
computed: {},
components: { IonCol,IonRow },
setup(props) {
    const authStore         = useAuthStore();
    const { user, profile } = authStore;
    const layout            = ref({ 
                                app:                 false,
                                isRequestInProgress: false, 
                                timeoutWeather:      false,
                                apiKey:              "1b20fdc81c976af642ae3904e3d8a7cb",
                                helper:              $h
                              });

    const getWeather = async (cityId) => {
        layout.value.isRequestInProgress = true;
        layout.value.timeoutWeather = false;
        try {
            let UrlRequest = "https://api.openweathermap.org/data/2.5/weather?id=" + cityId + "&APPID=" + layout.value.apiKey + "&units=metric&lang=es";
            const response = await axios.get(UrlRequest);
            const data     = response.data;
            return {
                temp: data.main.temp,
                description: data.weather[0].description,
                temp_min: data.main.temp_min,
                temp_max: data.main.temp_max,
                wind_speed: data.wind.speed,
                wind_deg: data.wind.deg,
                clouds: data.clouds.all,
                sunrise: data.sys.sunrise,
                sunset: data.sys.sunset,
                country: data.sys.country,
                city: data.name,
                id: data.id,
                icon: data.weather[0].icon,
                iconUrl: "https://openweathermap.org/img/w/" + data.weather[0].icon + ".png",
                time: data.dt
            };
        }
        catch (error) {
            console.log(error);
        }
        layout.value.isRequestInProgress = false;
    }
  
    onMounted(async () => {
        await updateWeather();
        await fetchWeather();  
    });

    const updateWeather = async () => {
        if(!props.app) return;
        layout.value.app = props.app;
        if (props.app?.location?.cityId)layout.value.app.weather = await getWeather(props.app.location.cityId);
    };

    const fetchWeather = async () => {
        const unsubscribeWeatherChanges = setInterval(updateWeather, 30000);
        onUnmounted(() => { clearInterval(unsubscribeWeatherChanges); });
    };

  return {user, profile, layout, props };
},
methods: {},
});
</script>