
import { defineComponent, onMounted, ref, onUnmounted } from "vue"; 
import { useAuthStore, useImageStore } from '@/store';
import router from "@/router";
import Weather from '@/components/pages/utils/weather.vue';
import { appsList, getAppScenes, getAppDevices } from "../../model/apps.js";
import { _firebase as $_firebase } from "../../model/firebase";
import { onSnapshot, collection } from "firebase/firestore"; 

const db   =  $_firebase.firestore()

export default defineComponent({
  name: 'UserProfile',
  props: { 
      isMobile: Boolean,
  },
  components: { Weather }, 
  setup() {
     
    const authStore      = useAuthStore();
    const imageStore     = useImageStore();
    const { logoutUser, user, profile } = authStore;
    const layout         = ref({ 
                                  loading:       true,
                                  apps:          null,
                                  appId:         null,
                                  subscriptions: false,
                                  terms:         false,
                                  slideOpts:     {
                                    initialSlide: 1,
                                    speed: 400,
                                  },
                                  unsubscribeDetectDevicesChanges: null,
                              })

    const logoutApp = async () => {
      await logoutUser();
      router.replace("/login");
    }

    const getDurationTime = (date) => {
      if (!date) return "";
      let nowDate = new Date();
      let diff = nowDate.getTime() - date.toDate().getTime();
      let minutes = Math.floor(diff / 60000);
      return minutes;
    }

    const initDashboard = async () => {
      layout.value.apps    = await appsList({ byUser: profile });
      if (layout.value.apps && layout.value.apps.apps && Object.keys(layout.value.apps.apps).length) {
        for (const [appId] of Object.entries(layout.value.apps.apps)) {
          layout.value.appId = appId;
          layout.value.apps.apps[appId].devicesStatus = await getAppDevices(appId);
          layout.value.apps.apps[appId].scenes        = await getAppScenes(appId)
          if(layout.value.apps.apps[appId].scenes && layout.value.apps.apps[appId].scenes.scenes && Object.keys(layout.value.apps.apps[appId].scenes.scenes).length){
            for (const [sceneId] of Object.entries(layout.value.apps.apps[appId].scenes.scenes)) {
              layout.value.apps.apps[appId].scenes.scenes[sceneId].isAlarm = false;
              
              let includeScenes = true;
              
              if(!profile.isAgent){
                if(!profile.isClient && (!layout.value.apps.apps[appId].scenes.scenes[sceneId].users.includes(profile.uid))){
                  delete layout.value.apps.apps[appId].scenes.scenes[sceneId];
                  includeScenes = false;
                }
              }

              if(includeScenes){
                if (layout.value.apps.apps[appId].scenes.scenes[sceneId].devices && layout.value.apps.apps[appId].scenes.scenes[sceneId].devices.length) {
                  for (const deviceId of Object.values(layout.value.apps.apps[appId].scenes.scenes[sceneId].devices)) {
                    const deviceIdStr = deviceId as string;
                     if (layout.value.apps.apps[appId].devicesStatus && layout.value.apps.apps[appId].devicesStatus?.devices){
                      if (layout.value.apps.apps[appId].devicesStatus.devices[deviceIdStr] && layout.value.apps.apps[appId].devicesStatus.devices[deviceIdStr].status)
                      layout.value.apps.apps[appId].devicesStatus.devices[deviceIdStr].durationTime = getDurationTime(layout.value.apps.apps[appId].devicesStatus.devices[deviceIdStr].status.date);
                       if (layout.value.apps.apps[appId].devicesStatus?.devices[deviceIdStr] && layout.value.apps.apps[appId].devicesStatus.devices[deviceIdStr].status?.alarm?.status)layout.value.apps.apps[appId].scenes.scenes[sceneId].isAlarm = true
                       if (layout.value.apps.apps[appId].devicesStatus?.devices[deviceIdStr] && layout.value.apps.apps[appId].devicesStatus?.devices[deviceIdStr]?.interval && 
                           layout.value.apps.apps[appId].devicesStatus?.devices[deviceIdStr]?.durationTime && 
                           layout.value.apps.apps[appId].devicesStatus?.devices[deviceIdStr]?.durationTime > layout.value.apps.apps[appId].devicesStatus?.devices[deviceIdStr]?.interval){
                            layout.value.apps.apps[appId].scenes.scenes[sceneId].isAlarm       = false 
                            layout.value.apps.apps[appId].scenes.scenes[sceneId].isOffInterval = true 
                           }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    onMounted( async () => { 
      if(router.currentRoute.value.path == '/profile/logout')await logoutApp();
      if(!profile.isRoot){
        await initDashboard();
        await detectDevicesChanges();
      }
    });

    const detectDevicesChanges = async () => {
        if (layout.value.appId){
          if(layout.value.unsubscribeDetectDevicesChanges)layout.value.unsubscribeDetectDevicesChanges();
          const collectionPath = "apps/" + layout.value.appId + "/devices/";
          const collRef        = collection(db, collectionPath);
          layout.value.unsubscribeDetectDevicesChanges = onSnapshot(collRef, async (querySnapshot) => {
              querySnapshot.docChanges().forEach(async change => { 
                  if (change.type === "modified"){ 
                      layout.value.loading       = true;
                      await initDashboard();
                      layout.value.loading       = false;
                  }
              }); 
          }, (error) => { console.log("Error:", error); });
          onUnmounted(() => { layout.value.unsubscribeDetectDevicesChanges(); });
        }
    };
    
    return { logoutApp, user, profile, imageStore, layout };
  }
  
})
