import { SMS } from '@awesome-cordova-plugins/sms';

const sms = {
    async sendSms(sms) {
        sms.options = {
            replaceLineBreaks: false,
            android: { intent: '' }
        };
        SMS.send(sms.num, sms.msg, sms.options);
    }
};

const install = app => {
    app.config.globalProperties.$sms = sms;
};

export { install as default, sms as sms };
