<template>
    <div class="text-center ms-4">
        <i class="bi bi-thermometer-half" style="font-size: 32px;"></i>
        <div class="fs-1 fw-bold">{{ layout.dhtStatus?.temperature }}<span class="fs-6 align-top">&deg;</span></div>
        <div class="mb-2">Temp local</div> 
        <i class="bi bi-droplet-fill" style="font-size: 30px;"></i>
        <div class="fs-1 fw-bold">{{ layout.dhtStatus?.humidity }}%</div>
        <div>HR local</div>
    </div>
</template>
<script>
import { defineComponent, ref, onMounted, onUnmounted } from 'vue';
import { _firebase as $_firebase } from "../../../model/firebase";
import { onSnapshot, collection } from "firebase/firestore"; 
import { useAuthStore } from '@/store';
const db   =  $_firebase.firestore()


export default defineComponent({
name: 'WeatherComponent',
props: {
  isMobile: Boolean,
},
components: {},
setup() {
    const authStore         = useAuthStore();
    const { user, profile } = authStore;
    const layout            = ref({ 
                                dhtStatus:   false,
                              });

    const detectDHTChanges = () => {
        console.log("Initializing DHT...");
        const collectionPath = "alba/" + profile.controllerId + "/sensors/" 
        const collRef = collection(db, collectionPath);
        const unsubscribeDetectDht = onSnapshot(collRef, (querySnapshot) => {
            querySnapshot.docChanges().forEach(change => {
                if (change.type === "added" || change.type === "modified") {
                    const docData = change.doc.data();
                    if (Object.prototype.hasOwnProperty.call(docData, 'status')) {
                        if(docData.type == "dht")layout.value.dhtStatus = docData.status;
                    } else console.log("No status attribute found in the document.");
                }
            });
        }, (error) => {
            console.log("Error sensors status:", error);
        });

        onUnmounted(() => {
            console.log("Unsubscribing from DHT listener...");
            unsubscribeDetectDht();
        });
    };
    
    onMounted(async () => {
        await detectDHTChanges();
    });

  return {user, profile, layout };
},
methods: {},
});
</script>