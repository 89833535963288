<template>
    <div class="account-wrapper text-left friDashContent">
      <div class="account-box is-form is-footerless pb-0 pl-0 ml-0" :style="isMobile ? 'border-bottom: none !important;' : ''" style=" border: none !important;" :id="isMobile ? 'friAppsPage' : ''">
        <div v-if="isMobile" class="fs-1 text-center mb-5 mt-0 ps-3">
          <div class="text-left"><i class="bi bi-router text-center friOpacity1" style="font-size: 80px; line-height: 80px;"></i></div>
          <div class="text-left fw-bold" style="font-size: 30px; color: #999;">Dispositivos</div>
          <div class="text-left mb-4 fs-6" v-if="layout.apps && Object.keys(layout.apps.apps).length" style="margin-top: 4px;">{{ layout.apps.active }} activos de {{ Object.keys(layout.apps.apps).length }}</div>
        </div>
        <div v-else>
          <div class="form-head stuck-header" style="border: 0 !important;">
            <div class="form-head-inner">
              <div class="py-2 fs-3">Dispositivos</div>
              <div class="inline-block align-top me-2 fs-6 mt-0" v-if="layout.apps &&  Object.keys(layout.apps.apps).length" style="margin-top: 4px;">{{ layout.apps.active }} activos de {{ Object.keys(layout.apps.apps).length }}</div>
            </div>
          </div>
        </div>
        <div class="form-body friBackWhite vh-100 pt-0 px-0 mx-0">
          <template v-if="layout.loading">
            <div class="text-center mt-5">
              <img src="../../assets/images/theme/loading.gif" style="width: 150px; margin-top: 50px;">
              <div class="text-sm">Adquiriendo listado de dispositivos<br /> Espere por favor ...</div>
            </div>
          </template>
          <template v-else>
            <div>
              <ion-list v-if="layout.apps && layout.apps.apps && Object.keys(layout.apps.apps).length" style="background-color: #fff !important;"> 
                <ion-item detail  v-for="(app, appId) in layout.apps.apps" :key="appId" style="--ion-item-border-color: #e4e3e3;" 
                  @click="$router.push({ path: '/device/' + appId, query: { section:  'dashboard' } })" class="cursor-pointer px-2">
                  <div v-if="app.logo" class="pe-3"><img :src="app.logo" slot="start" style="width: 65px; height: 50px;"></div>
                  <ion-avatar v-else slot="start" style="width: 80px; height: 70px;"><i class="bi bi-router text-center friOpacity3" style="font-size: 55px;" :class="app.active ? 'friColor1' : 'friColor4'"></i></ion-avatar>
                  <ion-label class="pb-2">
                    <h2 class="fs-5">{{ app.name }}</h2>
                    <p>Alba Hub</p>
                    <!--
                    <template v-if="app.devices.devices && Object.keys(app.devices.devices).length">
                      <p>Equipos y sensores: {{ Object.keys(app.devices.devices).length }}</p>
                    </template>
                    -->
                  </ion-label>
                </ion-item>
              </ion-list>
              <div v-else class="pt-5">
                <div class="text-center mt-5">No dispone de dispositivos asociados.</div>
              </div>

              <!--
              <ion-card color="light"  class="text-left mt-0 mx-0 mb-1 p-2 cursor-pointer ms-5" v-for="(gatewayData, gatewayId) in layout.gateways.gateways" :key="gatewayId" style="box-shadow: none !important;" @click="toGateway(gatewayId)" :class="isMobile ? 'friBackWhite' : 'friBack9 mt-5'">
                <ion-card-header>
                  <ion-card-subtitle class="text-dark">
                    <div class="d-flex justify-content-left align-middle">
                      <div class="align-middle" style="width: 200px;" :class="isMobile ? 'mt-1' : ''">
                        <ion-card-title class="text-dark" :class="isMobile ? 'fs-1' : 'fs-2'">{{ gatewayData.name.toString().substring(0,12) }} <span v-if="gatewayData.name.length > 12">...</span></ion-card-title>
                        <div>Alba hub</div>
                      </div>
                      
                      <div class="align-middle ms-4 " v-for="(deviceData, deviceId) in gatewayData.devices" :key="deviceId">
                        <div class="d-flex justify-content-center ">
                            <div class="circle-icon-wrapper-list text-center">
                              <i :class="'bi bi-' + deviceData.icon +' align-middle'" style="font-size: 20px; color: #ddd;"></i>
                            </div>
                        </div>
                        
                        <pre>{{ deviceId }}</pre>
                        <pre>{{ deviceData?.status?.msg }}</pre>
                        
                      </div>
                    </div>
                  </ion-card-subtitle>
                </ion-card-header>
              </ion-card> -->
            </div>
          </template>
          
          <!--<div v-if="layout.controllers && layout.controllers.controllers">
            <template v-if="Object.keys(layout.controllers.controllers).length">
              <ion-card color="light"  class="text-left mt-0 mx-0 mb-3 p-3 friBack9" v-for="(controllerData, controllerId) in layout.controllers.controllers" :key="controllerId" style="box-shadow: none !important;">
                <ion-card-header v-for="(deviceData, deviceId) in controllerData.devices.devices" :key="deviceId" @click="toDevice(deviceId)">
                  <ion-card-title class="text-dark fs-3">{{ deviceData.name.toString().substring(0,20) }} <span v-if="deviceData.name.length > 20">...</span></ion-card-title>
                  <ion-card-subtitle v-if="controllerData.active && deviceData.active" class="right">
                      <div v-if="controllerData.ping" class="me-2 align-middle"> <span class="fw-semibold align-top text-dark"><i class="bi bi-plugin fs-5 align-middle pe-1"></i> Encendido</span></div>
                      <div v-else><i class="bi bi-plugin fs-5 align-middle pe-1"></i> <span class="fw-semibold">Apagado</span></div>
                  </ion-card-subtitle>
                </ion-card-header>
              </ion-card>
            </template>
            <template v-else>
              <div class="text-center mt-3">No dispone de dispositivos activos.</div>
            </template>
          </div>-->
          

        </div>
        <!--
        <div class="form-head stuck-header border-none m-0 p-0 mx-5">
          <button class="button friBack1 px-0 py-3 m-0 text-white w-100 mt-0"><i class="bi bi-node-plus-fill fs-1"></i> <span class="ps-2">Añadir dispositivo</span></button>
        </div>
        -->
      </div>
      <ion-modal  :is-open="$router.currentRoute.value.path == '/devices/add'">
          <ion-header>
            <ion-buttons slot="end">
                    <ion-button @click="$router.push('/devices/')" class="border-none">Cerrar</ion-button>
                </ion-buttons>
          </ion-header>
          <ion-content>
              <div class="fs-1 text-center mt-5 fw-bold friColor1" style="padding-top: 100px;">Añadir Dispositivo</div>
              <div class="mx-5 fs-3 mt-5 text-center" >
                  Escanee el código QR <div class="py-2 fs-6">Lo encontrará en la parte inferior del dispositivo.</div>
              </div>
              <div class="d-flex justify-content-center py-5">
                  <div class="align-middle mt-2 ms-5 me-5 cursor-pointer text-center">
                      <div class="d-flex justify-content-center">
                          <div class="circle-icon-wrapper text-center friBack1 text-white" style="font-size: 60px;">
                            <i class="bi bi-qr-code-scan"></i>
                          </div>
                      </div>
                  </div>
              </div>   
              <div class="mt-5">
                  <!-- cancel button-->
                  <div class="d-flex justify-content-center">
                      <button class="button px-5 py-1 m-0  mt-0" @click="$router.push('/devices/')">Cancelar</button>
                  </div>
              </div>
          </ion-content>
      </ion-modal>
    </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { getPlatforms, IonModal,  IonButtons,IonButton,IonHeader,IonContent} from '@ionic/vue'; 
import { useAuthStore } from '@/store';
import { appsList } from "../../model/apps.js";
import router from "@/router";

export default defineComponent({
name: 'DevicesPage',
props: {
    login: Boolean,
    isMobile: Boolean
},
components: { IonModal, IonButtons, IonButton, IonHeader, IonContent }, 
setup() {

    const authStore         = useAuthStore();
    const { user, profile } = authStore;
    const platforms         = getPlatforms(); 
    const layout            = ref({
                                    loading:   true,
                                    isMobile:  false,
                                    devices:   false,
                                    addDevice: null,
                                  })

    const toDevice = async (deviceId) => {
      router.push({ name: 'device', params: { deviceId: deviceId } });
    }

    const toGateway = async (gatewayId) => {
      router.push({ name: 'hub', params: { gatewayId: gatewayId }, query: { section:  "dashboard" } });
    }

    onMounted(async () => { 
      if(platforms && !platforms.includes('desktop')) layout.value.isMobile = true;
      layout.value.apps        = await appsList({ byUser: profile });
      layout.value.loading     = false;
    });

    return {user, profile, layout, toDevice, toGateway};
},
methods: { },
});
</script>
<style>
.circle-icon-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 120px; /* Tamaño del círculo */
  height: 120px; /* Tamaño del círculo */
  background-color: white; /* Fondo blanco */
  border-radius: 50%; /* Hace el círculo redondo */
  /* Opcionales: sombra, bordes, etc. */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.circle-alarm{
  box-shadow: 0px 0px 5px #ff0000;
  background-color: rgba(255, 0, 0, 0.1);
}

.circle-icon-wrapper-list {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* Tamaño del círculo */
  height: 40px; /* Tamaño del círculo */
  background-color: white; /* Fondo blanco */
  border-radius: 50%; /* Hace el círculo redondo */
  /* Opcionales: sombra, bordes, etc. */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.circle-icon-wrapper-add{
    position: relative; /* Asegúrate de que sea relativo para que el símbolo de exclamación se posicione correctamente */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 140px; /* Tamaño del círculo */
    height: 140px; /* Tamaño del círculo */
    background-color: white; /* Fondo blanco */
    border-radius: 50%; /* Hace el círculo redondo */
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.alarm-exclamation {
    position: absolute;
    top: 10px; /* Posición vertical: en la parte superior */
    right: 10px; /* Posición horizontal: a la derecha */
    background-color: red; /* Color de fondo para el símbolo de exclamación */
    color: white; /* Color del texto */
    border-radius: 50%; /* Redondea los bordes para hacerlo circular */
    width: 30px; /* Tamaño del círculo del símbolo */
    height: 30px; /* Tamaño del círculo del símbolo */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px; /* Tamaño del texto del símbolo de exclamación */
    transform: translate(50%, -50%); /* Ajusta la posición para que esté más centrada en la esquina */
}

</style>