<template>
    <div>
        <div class="account-box is-form is-footerless px-0 mx-0" :style="isMobile ? 'border-bottom: none !important;' : ''">
            <div class="form-head stuck-header" :class="isMobile ? 'py-0' : 'py-3'" v-if="layout.app">
                <div class="form-head-inner">
                    <div class="left"><h4 class="fw-bold" :class="isMobile ? 'fs-3 my-5' : 'fs-4'">{{  layout.app.name }} </h4></div>
                    <div class="inline-block align-top fs-6 mt-0" style="margin-top: 4px;">
                    <template v-if="layout.app.logo"><img :src="layout.app.logo" style="height: 50px;" /></template>
                    <template v-else>{{  layout.app.id }}</template>
                </div>
                </div>
            </div>
            <div class="pt-2 pb-2 fs-5 friBack1 text-white text-center ps-3" v-if="layout.device?.device && layout.device?.device?.typeName"><span class="align-middle">{{ layout.device?.device.typeName }}</span></div>
            <div class="mx-5" v-if="layout.app && layout.device?.device" style="padding-bottom: 150px;">
                <template v-if="layout.device.device.status">
                    <!-- DEVICES COLD ROOM -->
                    <div class="flex justify-content-center" v-if="layout.device.device.status.msg">
                        <div class="d-flex">
                            <div class="br-8 mb-2 text-center w-100 pb-5"> 
                                <div v-if="layout.device.device.status.msg.hasOwnProperty('deviceModel')"> 
                                    <!-- XW265K -->
                                    <div v-if="layout.device.device.status.msg.deviceModel == 'xw265k'">
                                        <div v-if="layout.device.device.status.msg.params && Object.keys(layout.device.device.status.msg.params).length" class="pt-3">
                                            <table class="table table-sm table-borderless">
                                                <tbody>
                                                    <th class="text-dark text-left ps-1">Parámetros ({{ Object.keys(layout.device.device.status.msg.params).length }})</th>
                                                    <th class="text-dark text-right fw-normal text-xs3">{{ layout.helper.getDateFormatFb(layout.device.device.status.date, 'full') }}</th>
                                                    <tr v-for="(param, index) in layout.device.device.status.msg.params" :key="index">
                                                        <td class="text-white text-right ps-3 py-1 friBack5" style="width: 100px;">{{ index }}</td>
                                                        <td class="text-dark text-left py-1">{{ param }} {{ layout.helper.getUnitByParam(index, param) }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <!-- <pre class="text-left">{{ layout.device.device.status.msg }}</pre>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div v-else class="text-center" style="margin-top: 50px;">
                <div class="spinner-border text-primary friColor1 text-center" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    </div>
 </template>
 <script>
 import { defineComponent, onMounted, ref, watch } from "vue"; 
 import { useAuthStore } from "@/store";
 import { add } from 'ionicons/icons';
 import router from "@/router";
 import { useRoute } from 'vue-router'
 import { getApp, getAppDevice, updateDevice } from "../../../model/apps.js"; 
 import { helper as $h } from "../../../utils/helper.js";
 import { not as $not } from "../../../model/not";

 export default defineComponent({
   name: 'semRegisters',
   props: {
       isMobile: Boolean,
       appId: String,
       deviceId: String,
    },
   components: {},
   setup(props) {
 
      const authStore      = useAuthStore();
      const route          = useRoute()
      const layout         = ref({ 
                               appId:       null,
                               deviceId:    null,
                               device:      null,
                               helper:     $h
                           }) 

      const { user, profile } = authStore;

      onMounted( async () => { 
        layout.value.appId     = router.currentRoute.value.params.appId;
        layout.value.deviceId  = router.currentRoute.value.params.deviceId;
        layout.value.app       = await getApp(layout.value.appId)
        if(layout.value.deviceId)    
            layout.value.device     = await getAppDevice(layout.value.appId, layout.value.deviceId)
            if (layout.value.device?.device && layout.value.device.device.deviceId && layout.value.device.device?.status?.msg?.params)
                layout.value.device.device.status.msg.params = Object.keys(layout.value.device.device.status.msg.params)
                                                                      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                                                                      .reduce((obj, key) => {
                                                                        obj[key] = layout.value.device.device.status.msg.params[key];
                                                                        return obj;
                                                                      }, {});
                
      });

      const saveImage = async () => {
        let validate = true
        let required = ['image']
        if(!layout.value.device.device.image)validate = false
        required.forEach((field) => {  if(!layout.value.device.device[field])validate = false })
        if(validate){ await updateDevice(layout.value.device.device).then(async () => { $not.toastApp({ message: 'Se ha actualizado el dispositivo correctamente', position: 'top', icon: 'info', duration: 2000, animated: true, cssClass: 'text-white mt-2 friToast1'}) })     
        }else $not.toastApp({ message: 'Debe rellenar los parámetros requeridos', position: 'top', icon: 'info', duration: 2000, animated: true, color: 'danger', cssClass: 'text-white mt-2' })
       }
       
       const previewDeviceLogo = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          if(layout.value.device.device)layout.value.device.device.logo = reader.result.toString();
        };
        reader.readAsDataURL(file);
      };

       watch(() => route.query, async (query) => {
            if (query){
                layout.value.query     = query; 
                layout.value.section   = query.section;
                layout.value.appId     = router.currentRoute.value.params.appId;
                layout.value.deviceId  = router.currentRoute.value.params.deviceId;
            }
        });

       return { layout, user, profile, add, props, saveImage, previewDeviceLogo };
   }
   
 })
 </script>