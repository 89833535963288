<template>
     <div class="account-wrapper text-left mx-0 px-0" :class="isMobile ? '' : 'mt-5'">
        <div class="columns">
            <!-- Desktop Navigation -->
            <div class="column is-4 mt-5 ms-3" v-if="!isMobile">
                <div class="account-box is-navigation p-0 mt-0" style="border: none !important;">
                    <div class="account-menu me-5">
                        <div v-for="(section, index) in layout.sections" :key="index" class="account-menu-item cursor-pointer" 
                            :class="layout.section == section.name ? 'router-link-active router-link-exact-active' : ''" @click="layout.section = section.name">
                            <i aria-hidden="true" class="lnil lnil-user-alt"></i>
                            <span><i :class="section.icon + ' fs-3 align-middle me-3'"></i>{{ section.title }}</span>
                            <span class="end"><i aria-hidden="true" class="fas fa-arrow-right"></i></span>
                        </div>
                        <div class="account-menu-item mt-2 cursor-pointer" @click="goBack()">
                            <i aria-hidden="true" class="lnil lnil-quill"></i>
                            <span><i class="bi bi-chevron-compact-left fs-3 align-middle me-3"></i>Volver</span>
                            <span class="end"><i aria-hidden="true" class="fas fa-arrow-right"></i></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column ps-0"  :class="isMobile ? 'is-12' : 'is-8 mt-5'" v-if="layout.appId">
                <template v-if="layout.section == 'dashboard'"><Dash :appId="layout.appId" :deviceId="layout.deviceId" :isMobile="isMobile"  /></template>
                <template v-else-if="layout.section == 'images'"><Images :appId="layout.appId" :deviceId="layout.deviceId" :isMobile="isMobile"  /></template>
                <template v-else-if="layout.section == 'settings'"><Settings :appId="layout.appId" :deviceId="layout.deviceId" :isMobile="isMobile"  /></template>
                <template v-else-if="layout.section == 'registers'"><Registers :appId="layout.appId" :deviceId="layout.deviceId" :isMobile="isMobile"  /></template>
                <template v-else-if="layout.section == 'remote'"><Remote :appId="layout.appId" :deviceId="layout.deviceId" :isMobile="isMobile"  /></template>
            </div>
        </div>
    </div>
  </template>
  <script>
  import { defineComponent, onMounted, ref, watch } from "vue";  //, onUnmounted
  import { useAuthStore } from "@/store";
  import { add} from 'ionicons/icons';
  import router from "@/router";
  import { useRoute } from 'vue-router'
  import { getAppDevice } from "../../model/apps.js"; 
  
  import Dash from '@/components/pages/sem/dashboard.vue';
  import Images from '@/components/pages/sem/images.vue';
  import Settings from '@/components/pages/sem/settings.vue';
  import Registers from '@/components/pages/sem/registers.vue';
  import Remote from '@/components/pages/sem/remote.vue';

  export default defineComponent({
    name: 'SemPage',
    props: {
        isMobile: Boolean,
     },
     components: { Dash, Images, Settings, Registers, Remote },
    setup(props) {
  
       const authStore      = useAuthStore();
       const route          = useRoute()
       const layout         = ref({ 
                                appId:       null,
                                deviceId:    null,
                                app:         null,
                                device:      null,
                                section:     null,
                                sections:    [],
                            }) 

       const { user, profile } = authStore;

       const goBack = () => {
        router.push({ path: '/app/' + layout.value.appId, query: { section:  'devices' } })
       }

       const initSensor = async () => {

           if(!layout.value.section)
            if(route.query.section) layout.value.section = route.query.section
            else layout.value.section = 'dashboard'

           if (profile && !profile.isClient){
            layout.value.sections = layout.value.sections.filter((tab) => !tab.client);
            if (layout.value.section == 'alarms' || layout.value.section == 'settings') layout.value.section = 'dashboard';
           }
            
           layout.value.sections = [ { name: 'dashboard', title: 'Dispositivo', icon: 'bi bi-wifi'}, { name: 'images', title: 'Imágenes', icon: 'bi bi-images' }]
           layout.value.device = await getAppDevice(layout.value.appId, layout.value.deviceId);
           
           if(layout.value?.device && layout.value?.device?.device?.type == 'xw265k'){
              layout.value.sections.push({ name: 'registers', title: 'Registros', icon: 'bi bi-qr-code' })
              layout.value.sections.push({ name: 'remote', title: 'Control remoto', icon: 'bi bi-gear' })
           }

           layout.value.sections.push({ name: 'settings',  title: 'Configuración', icon: 'bi bi-sliders2'})

       }

       onMounted( async () => { 
            layout.value.appId     =  router.currentRoute.value.params.appId;
            layout.value.deviceId  =  router.currentRoute.value.params.deviceId;
            await initSensor();
       });
  
        watch(() => route.query, (query) => {
            if (query){
                layout.value.query   = query; 
                layout.value.section = query.section;
                layout.value.appId     =  router.currentRoute.value.params.appId;
                layout.value.deviceId  =  router.currentRoute.value.params.deviceId;
            }
        });

        return { layout, user, profile, add, props, goBack };
    }
    
  })
  </script>