
    import { defineComponent, ref } from 'vue';
    import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon,  IonRouterOutlet, IonPage } from '@ionic/vue';
    import { settings, pulseOutline, readerOutline,  arrowUndo , optionsOutline, people} from 'ionicons/icons';
    import router from "@/router";
    
    export default defineComponent({
      name: 'AppTabs',
      components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon,  IonRouterOutlet, IonPage },
      setup() {
    
        const PingTabs = ref([
                              { tab: 'Settings',  path: '/app/',    icon: optionsOutline },
                              { tab: 'Actions',   path: '/app/',   icon: pulseOutline },
                              { tab: 'Members',   path: '/app/',   icon: people },
                              { tab: 'History',   path: '/app/',   icon: readerOutline },
                              { tab: 'Back',      path: '/tabs/',   icon: arrowUndo}
                            ]) 
                            
        const curTab   = ref(1) 
        const toTab    = async (tab,item) => {
          curTab.value = item;
          router.replace(tab);
        };
    
        return {
          router, 
          settings,
          PingTabs,
          curTab, 
          toTab,
          pulseOutline,
          readerOutline,
          arrowUndo,
          optionsOutline,
          people,
        }
      }
    });
    