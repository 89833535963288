
import { defineComponent, ref, onMounted  } from "vue"; 
import { useAuthStore } from '@/store';
import { ping as $ping } from "../../model/ping";
import { helper as $h } from "../../utils/helper";
import { IonList, IonFab, IonFabButton, IonBadge, IonItemOption,  IonItemOptions, IonText, IonLabel, IonChip, IonItemSliding } from '@ionic/vue'; //
import { add, pin, alarm, send, location, notificationsCircleOutline, notificationsOutline, notificationsCircle, caretForwardCircle, close, heart, 
         trash, share , toggleOutline, peopleOutline } from 'ionicons/icons';
import { _firebase as $_firebase } from "../../model/firebase";
import {  onSnapshot, query, collection } from "firebase/firestore";
import router from "@/router";


export default defineComponent({
  name: 'PingList',
  created () {
    this.helper = $h,
    this.ping   = $ping
  },  
  props: {
    header:  Boolean,
    headerCounter: Boolean,
    createButton: Boolean
  },
  components: {  IonList, IonFab, IonFabButton, IonBadge, IonLabel, IonChip, IonItemOption, IonItemOptions, IonText, IonItemSliding },
  setup() {
     
    const pingfineList     = ref([]);
    const pingfineShared   = ref([]);
    const authStore        = useAuthStore();
    const { user, profile }         = authStore;
    
    const editPing = async (ping) => {
      $h.showLoading( {  message: 'Loading pingfine data, Please wait', duration: 2000 });
      router.replace("/ping/?ping="+ping.id);
    }

    const createPing = async () => {
      //let randomPing =  await $ping.randomPing();
      //await $ping.create(randomPing)
    }

    const PingListener = async () => {
      const q = query(collection($_firebase.firestore(), "profiles", user.uid, "pingfine"));
      onSnapshot(q, async () =>  { 
        $h.showLoading( { message: 'Changes detection, updating..', duration: 1000 }); 
        pingfineList.value   = await $ping.getUserPings() 
        //pingfineShared.value = await $ping.getUserPingsShared()
      });
    }

    onMounted( async () => { 
        await PingListener() //Pingfine Listener
     });
    
    return { 
             profile, add, pin, user, alarm, send, location, notificationsCircleOutline, caretForwardCircle, close, heart, trash, share, toggleOutline, pingfineShared,
             notificationsOutline, notificationsCircle, pingfineList, createPing, peopleOutline, editPing,
          };
  }
  
})
